<div class="dark" *ngIf="loading">
  <mat-spinner
    diameter="70"
    style="
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -35px;
      margin-top: -35px;
    "
  ></mat-spinner>
</div>
<div class="title" *ngIf="!loading">
  <div class="inner-title-container">
    <button mat-fab class="confirm-dialog-button blue-button-icon">
      <mat-icon class="action-button-icon">calendar_month</mat-icon>
      <mat-icon class="action-button-icon-add">add</mat-icon>
    </button>
    <label class="dialog-title" i18n>{{ loc.workingDays.define }}</label>
  </div>
  <button class="exit-button" (click)="exit('not created')">
    <mat-icon matSuffix class="exit-icon">close</mat-icon>
  </button>
</div>

<mat-horizontal-stepper
  class="horizontal-stepper actions-stepper"
  [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex"
  labelPosition="bottom"
  [linear]="true"
  #stepper
  appStepper
  [step]="stepper.selectedIndex"
>
  <ng-template matStepperIcon="done" let-index="index">
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 0"
      >description</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 1"
      >check_box</mat-icon
    >
  </ng-template>

  <ng-template matStepperIcon="edit" let-index="index">
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 0"
      >description</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 1"
      >check_box</mat-icon
    >
  </ng-template>

  <ng-template matStepperIcon="number" let-index="index">
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 0"
      >description</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 1"
      >check_box</mat-icon
    >
  </ng-template>

  <mat-step [editable]="true">
    <ng-template matStepLabel i18n>{{ loc.stepTitle.state }}</ng-template>

    <form class="form-step">
      <ng-container>
        <table
          mat-table
          [dataSource]="workingDaysDataSource"
          class="days-table"
        >
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ loc.tableHeaders.status }}
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (change)="changeStatus(row)"
                [checked]="row.status"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="dayName">
            <th mat-header-cell *matHeaderCellDef>
              {{ loc.tableHeaders.day }}
            </th>
            <td
              style="text-align: left; width: 100px"
              (click)="changeStatus(element)"
              mat-cell
              *matCellDef="let element"
            >
              {{ getDayName(element.dayName) }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </ng-container>

      <div *ngIf="showErrorMessage">
        <div class="error-msg">
          <label>{{ errorMsg }}</label>
        </div>
      </div>
      <div class="dialog-nav-buttons">
        <button
          mat-fab
          class="button green-button"
          [disabled]="showErrorMessage"
          (click)="next(stepper)"
        >
          <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [editable]="true">
    <ng-template matStepLabel i18n>{{ loc.stepTitle.enable }}</ng-template>
    <div class="form-step">
      <div class="verify-large">
        <div class="flex-container-vertical flex-container-vertical-modal">
          <div>
            <label class="card-name" i18n>{{
              this.loc.workingDays.title
            }}</label>
          </div>

          <div class="details-package-large details-flex">
            <table
              mat-table
              [dataSource]="workingDaysDataSource"
            >
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef i18n>
                  {{ loc.tableHeaders.status }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.status ? loc.status.active : loc.status.inactive }}
                </td>
              </ng-container>
              <ng-container matColumnDef="dayName">
                <th mat-header-cell *matHeaderCellDef i18n>
                  {{ loc.tableHeaders.day }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getDayName(element.dayName) }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsVerify"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsVerify"
              ></tr>
            </table>
          </div>
        </div>
      </div>

      <div class="dialog-nav-buttons">
        <button mat-fab class="button green-button" matStepperPrevious>
          <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-fab class="button green-button" (click)="editWorkingDays()">
          <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon"
            >save</mat-icon
          >
        </button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>

<form class="filter-wrapper">
    <ng-container *ngFor="let filter of filters">
        <mat-form-field appearance="fill" [class]="filter.class ? filter.class : 'large-filter'">
            <mat-label i18n>{{ filter.placeholder }}</mat-label>
            <mat-icon matSuffix style="font-size: 20px; color: grey">
              search
            </mat-icon>
            <input
              type="text"
              aria-label="Number"
              matInput
              
            />
        </mat-form-field>
    </ng-container>
</form>
<div [class]="tableStyle">
    <mat-card style="padding: 0px; border-radius: 10px">
      <mat-card
        style="
          min-height: 300px;
          overflow-y: auto;
          padding: 0px;
          box-shadow: none !important;
        "
      >
        <table
          mat-table
          scrollY="true"
          [dataSource]="loadingDataSource"
          matSort
          [class]="'table ' + title">
        <ng-container *ngFor="let column of columns;">
            <ng-container [matColumnDef]="column.key">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  class="hide-arrow"
                  i18n
                >
                  {{ column.title }}
                </th>
                <td mat-cell *matCellDef="let element" [style]="cellStyle">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{
                      background: 'no-repeat rgb(227 228 230 / 33%)',
                      height: '15px',
                      width: '95%'
                    }"
                  >
                  </ngx-skeleton-loader>
                </td>
              </ng-container>
        </ng-container>
          
          <ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
              [style]="headerStyle"
            ></tr>
            <tr
              class="row"
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              [style]="rowStyle"
            ></tr>
          </ng-container>

        </table>
      </mat-card>
    </mat-card>
  </div>
  <div class="paginator-wrapper-loader">
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        background: 'no-repeat rgb(227 228 230 / 33%)',
        height: '70%',
        width: '250px'
      }"
      style="
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      "
    >
    </ngx-skeleton-loader>

    <ngx-skeleton-loader
      count="1"
      [theme]="{
        background: 'no-repeat rgb(227 228 230 / 33%)',
        height: '70%',
        width: '250px'
      }"
      style="
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
      "
    >
    </ngx-skeleton-loader>
  </div>
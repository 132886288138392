import { Component, HostListener, OnInit } from '@angular/core';
import { LocalizationService } from '../services/localization/localization.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css'],
  host: { "class": "component" }
})
export class CampaignsComponent implements OnInit {

  constructor(public loc: LocalizationService) { }

  ngOnInit(): void {
  }
}

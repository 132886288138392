import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Country } from 'src/app/models/country/country.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CountriesService } from '../../services/countries/countries.service';

@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class AddCountryComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  currencies: any[] =[];
  flags: string[] = [
    "assets/img/argentina.svg",
    "assets/img/bolivia.svg",
    "assets/img/brazil.svg",
    "assets/img/chile.svg",
    "assets/img/colombia.svg",
    "assets/img/ecuador.svg",
    "assets/img/guatemala.svg",
    "assets/img/honduras.svg",
    "assets/img/mexico.svg",
    "assets/img/panama.svg",
    "assets/img/paraguay.svg",
    "assets/img/peru.svg",
    "assets/img/united-states.svg",
    "assets/img/uruguay.svg",
    "assets/img/venezuela.svg"
  ];


  constructor(
    public dialogRef: MatDialogRef<AddCountryComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Country[],
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private countriesService: CountriesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      countryName:['', Validators.required],
      countryId:['', Validators.required],
      countryIsoCode: ['', Validators.required],
      phoneCode: ['', Validators.required],
      image: ['', Validators.required],
    });
  }
  create (){
    this.loading = true;
    let newCountry: Country = {
      countryName: this.firstFormGroup.get('countryName')!.value,
      countryId: this.firstFormGroup.get('countryId')!.value,
      countryIsoCode: this.firstFormGroup.get('countryIsoCode')!.value,
      phoneCode: this.firstFormGroup.get('phoneCode')!.value,
      image: this.firstFormGroup.get('image')!.value,
      currency: '',
    }
    
    this.countriesService.createCountry(newCountry).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }
  exit(created: string){
    this.dialogRef.close(created);
  }
  emptyField (text : string | undefined): string {
    if (text === '' || text === undefined)
      return "-";
    return text;
  }
}

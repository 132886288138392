<div *ngIf="metadataField" class="general-operations-div">
    <div style="display: flex">
      <button mat-icon-button class="back" (click)="goBack()">
        <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>
      <ng-container *ngIf="!loading">
        <div class="title-container">
          <label class="title-mid-text"> {{ metadataField.description }}</label>
        </div>
      </ng-container>
    </div>
    <div *ngIf="(!loading)" style="display: flex; flex-direction: row-reverse;">
      <ng-container>
        <button
          mat-fab
          class="button green-color"
          (click)="showDatasourceData()"
          i18n-matTooltip
          [matTooltip]="loc.fields.datasourceData"
        >
          <mat-icon
            class="action-button-icon margin-icon"
            fontSet="material-icons-outlined"
          >
            code
          </mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
  
  <mat-card class="details-card">
    <div class="flex-container">
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.id
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadataField.id }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.type
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadataField.type }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.controlType
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadataField.controlType }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.defaultValue
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadataField.defaultValue }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.tableHeaders.isMandatory
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{(metadataField.isMandatory == '0' ? loc.common.no : loc.common.yes) }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.tableHeaders.isEditable
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{(metadataField.isEditable == '0' ? loc.common.no : loc.common.yes) }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.tableHeaders.isHidden
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ (metadataField.isHidden == '0' ? loc.common.no : loc.common.yes)}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.tableHeaders.validation
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadataField.validation}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.fields.checkout
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ (metadataField.checkout == '0' ? loc.common.no : loc.common.yes)}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
        
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.fields.datasource
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadataField.datasource}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.tableHeaders.creationDate
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadataField.creationDate}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.tableHeaders.updateDate
            }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadataField.changeDate}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader count="1" [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

<app-list-metadata-fields-datasource-data  [metadataField]="metadataField" [datasourceData]="metadataField.datasourceStruct!"
(datasourceDataChanged)="manageDatasourceDataModification($event)"
></app-list-metadata-fields-datasource-data>
  
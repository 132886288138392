<div class="general-operations-div">
    <div style="display: flex; margin-right: 15px;align-items: center;">
        <button  mat-fab class="page-icon-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">signpost</mat-icon>
        </button>
            <label class="page-title"  i18n>{{loc.routes.transactionTemplates}}</label>
    </div>
</div>

<mat-tab-group class="tab-bar"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChange()">
    <mat-tab [label]="loc.tabNames.disaggregationStepTemplates" i18n-label>
        <app-list-disaggregation-step-templates tableStyle = "tableResponsive"></app-list-disaggregation-step-templates>
    </mat-tab>
    <mat-tab [label]="loc.tabNames.postingStepTemplates" i18n-label>
        <app-list-posting-step-templates tableStyle = "tableResponsive"></app-list-posting-step-templates>
    </mat-tab>
</mat-tab-group>
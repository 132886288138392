
<div class="dark" *ngIf="loading">
    <mat-spinner diameter="70" style="
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -35px;
        margin-top: -35px;
      "></mat-spinner>
  </div>
  <div class="title">
    <div class="inner-title-container">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon class="action-button-icon" 
   fontSet="material-icons-outlined" >psychology</mat-icon>
        <mat-icon class="action-button-icon-add">add</mat-icon>
      </button>
      <div class="dialog-title"><label i18n>{{loc.data.create}}</label></div>
    </div>
    <button class="exit-button" (click)="exit('not created')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
  
  

    <mat-horizontal-stepper class="horizontal-stepper field-create-stepper"  [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
      [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex" class="horizontal-stepper very-short-stepper">
      
      <ng-template matStepperIcon="done" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">check_box</mat-icon>
      </ng-template>
  
      <ng-template matStepperIcon="edit" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">check_box</mat-icon>    
      </ng-template>>
  
      <ng-template matStepperIcon="number" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">check_box</mat-icon>
      </ng-template>

      
    <div class="all">
      <mat-step [stepControl]="firstFormGroup" [editable]="true">
        <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
        <form [formGroup]="firstFormGroup" class="form-step">
          <div class="data-box">
          <div class="create-row">
            <mat-form-field appearance="fill">
                <mat-label i18n>{{loc.fields.id}}</mat-label>
                <input matInput name="id" formControlName="id" required />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label i18n>{{loc.fields.description}}</mat-label>
              <input matInput name="name" formControlName="name" required />
          </mat-form-field>
            
          </div>

          <div class="create-row" style="justify-content: flex-start;">
            <mat-form-field appearance="fill">
              <mat-label i18n>{{loc.fields.isHidden}}</mat-label>
              <mat-select formControlName="isHidden">
                <mat-option *ngFor="let op of options" [value]="op">
                  {{ op }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" >
                <mat-label i18n>{{loc.fields.checkout}}</mat-label>
                <mat-select formControlName="checkout">
                  <mat-option *ngFor="let op of options" [value]="op">
                    {{ op }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="dialog-nav-buttons nav-buttons-form" >
            <button mat-fab class="button green-button" (click)="next(stepper)"  >
              <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
            </button>
        </div>
        </form>
      </mat-step>
  
      <mat-step [editable]="true" class="step-verify">
        <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
        <div class="form-step">
          <div class="verify-large">
            <div class="flex-container-vertical flex-container-vertical-modal">
              <div>
                <label class="card-name" i18n>{{loc.data.new}}</label>
              </div>
              <div>
                <div class="details-package-large details-flex">
                  <div class="verify-vertical verify1">
                    <div class="card-header" i18n>{{loc.fields.id}}</div>
                    <div class="card-value api-verify">{{ id }}</div>
                  </div>
                  <div class="verify-vertical">
                    <div class="card-header" i18n>{{loc.fields.name}}</div>
                    <div class="card-value">{{ name }}</div>
                  </div>
                  <div class="verify-vertical">
                    <div class="card-header" i18n>{{loc.fields.isHidden}}</div>
                    <div class="card-value">{{ (isHidden == '1' ? loc.common.yes : loc.common.no) }}</div>
                  </div>
                  <div class="verify-vertical">
                    <div class="card-header" i18n>{{loc.fields.checkout}}</div>
                    <div class="card-value api-verify">{{ (checkout == '1' ? loc.common.yes : loc.common.no) }}</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="dialog-nav-buttons nav-buttons-verify" >
          <button mat-fab class="button green-button" matStepperPrevious>
            <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
          </button>
          <button mat-fab class="button green-button" (click)="create()">
            <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon">save</mat-icon>
          </button>
          </div>
        </div>
     
      </mat-step>  
    </div>
</mat-horizontal-stepper>

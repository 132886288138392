import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { IdentificationType } from 'src/app/models/country/identification-type.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CountriesService } from '../../../services/countries/countries.service';

@Component({
  selector: 'app-create-identification-type',
  templateUrl: './create-identification-type.component.html',
  styleUrls: ['./create-identification-type.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class CreateIdentificationTypeComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  currencies: any[] =[];


  constructor(
    public dialogRef: MatDialogRef<CreateIdentificationTypeComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private countriesService: CountriesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      identificationTypeId:['', Validators.required],
      description:['', Validators.required],
    });
  }
  create (){
    this.loading = true;
    let newIdentificationType: IdentificationType = {  
      countryId: this.data,
      identificationTypeId: this.firstFormGroup.get('identificationTypeId')!.value,
      description: this.firstFormGroup.get('description')!.value
    }
    
    this.countriesService.createIdentificationType(newIdentificationType).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }
  exit(created: string){
    this.dialogRef.close(created);
  }
  emptyField (text : string | undefined): string {
    if (text === '' || text === undefined)
      return "-";
    return text;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { Step } from 'src/app/models/stepper/step-modal';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { StepsService } from 'src/app/services/steps/steps.service';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { TableData, EmptyState, Column, PaginatorInfo, TableFilter, CreateButton, TableTransformer, Action } from 'src/app/utils/table/table.model';
import { CreateStepComponent } from '../create-step/create-step.component';
import { EditStepComponent } from '../../edit-step/edit-step.component';

@Component({
  selector: 'app-list-steps',
  templateUrl: './list-steps.component.html',
  styleUrls: ['./list-steps.component.css'],
  host: {
    class: 'component',
  }
})
export class ListStepsComponent implements OnInit {
  loading = false;

  boolOptions: any[] = [{
    key: '1',
    value: this.loc.common.yes
  },
  {
    key: '0',
    value: this.loc.common.no
  }
];

steps: Step[] = [];

  constructor(
    public loc: LocalizationService,
    private stepsService: StepsService,
    private htmlGenerator: SafeHTMLGeneratorService,
    public router: Router,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.listSteps();
  }

  data: TableData[] = [];

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',//TODO: CHANGE
    message: this.loc.step.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.createStep(),
      },
    ],
  };

  columns: Column[] = [
    { title: '', 
      key: 'icon', 
      sortable: true 
    },
    { title: this.loc.tableHeaders.id, 
      key: 'stepId', 
      sortable: true 
    },
    {
      title: this.loc.tableHeaders.name,
      key: 'name',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.type,
      key: 'type',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.api,
      key: 'api',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.status,
      key: 'status',
      sortable: true,
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.step;

  filters: TableFilter[] = [
    {
      key: 'name',
      label: this.loc.tableHeaders.name,
      placeholder: this.loc.tableHeaders.name,
      type: 'text',
    },
    {
      key: 'type',
      label: this.loc.tableHeaders.type,
      placeholder: this.loc.tableHeaders.type,
      type: 'text',
    },
    {
      key: 'api',
      label: this.loc.tableHeaders.api,
      placeholder: this.loc.tableHeaders.api,
      type: 'text',
    },
  ];

  actions: Action[] = [
    {
      icon: 'more_horiz',
      action: (row: Step) => this.goToDetails(row),
      tooltip: this.loc.common.details,
    },
    {
      icon: 'edit',
      tooltip: this.loc.step.edit,
      action: (row: Step) => {
        this.editStep(row);
      },
    },
    {
      icon: 'delete',
      tooltip: this.loc.step.delete,
      action: (row: Step) => {
        this.deleteStep(row);
      },
    },
  ];

  goToDetails(row: any): void {
    this.router.navigate(['step-details'], {
      queryParams: {
        stepId: row.stepId,
      },
      queryParamsHandling: 'merge',
    });
}

onRowClick = {
  action: (row:Step) => {this.goToDetails(row)}
}

  createButton: CreateButton = {
    tooltip: this.loc.step.create,
    action: () => this.createStep(),
  };

  transformers: TableTransformer = {
    status: (value: number) =>
    this.htmlGenerator.statusIcon(value == 1),
    icon: (value: any) => {
      return this.htmlGenerator.imageOrDefaultIcon(value, 'psychology', 'psychology');
    }
  };

  listSteps(){
    this.loading = true
    this.stepsService.listSteps().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.steps =  res.messageRS as any as Step[];
          this.data = res.messageRS as any as TableData[];
          this.data.sort((a, b) => (a.stepId > b.stepId) ? 1 : -1);
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  createStep(){
    const dialogRef = this.dialogService.openDialog(
      CreateStepComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.step, 'f')
        );
        this.listSteps();
      } else if(result === 'error'){
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.step)
        );
      }
    });
  }

  editStep(row: any){
    const dialogRef = this.dialogService.openDialog(
      EditStepComponent,
      {
        api: row.api,
        stepId: row.stepId
      },
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'updated') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.step, 'f')
        );
        this.listSteps();
      } else if(result === 'error'){
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.step)
        );
      }
    });
  }

  deleteStep(row: any){
    let data: DeleteModalData = {
      title: this.loc.step.delete,
      icon: 'psychology',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.step),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === 'delete') {
        this.loading = true;
        this.stepsService.deleteStep(row.stepId).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.step)
              );
              this.listSteps();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.step)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.step)
            );
          },
        });
      }
    });
  }

}

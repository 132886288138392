import { Component, OnInit } from '@angular/core';
import { DialogService } from '../services/dialog/dialog.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../services/snackbar/snackbar.service';
import { LocalizationService } from '../services/localization/localization.service';
import { CountriesService } from '../services/countries/countries.service';
import { LogoutDialogService } from '../services/logout-confirmation/logout-dialog.service';
import { Country } from '../models/country/country.model';
import { DeleteModalData } from '../models/delete-modal/delete-modal-data.model';
import { AddCountryComponent } from './add-country/add-country.component';
import { BaseResponse } from '../models/responses.model';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from '../utils/table/table.model';
import { DeleteModalComponent } from '../utils/delete-modal/delete-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from '../models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from '../utils/edit-modal/edit-modal.component';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css'],
  host: {
    class: 'component',
  },
})
export class CountriesComponent implements OnInit {
  loading = true;
  constructor(
    public dialogService: DialogService,
    public router: Router,
    public snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService,
    public countriesService: CountriesService
  ) {}

 

  ngOnInit(): void {
    this.loadCountries();
  }

  data : TableData[] = []
  columns : Column[] = [
    { 
      key: 'countryName',
      title: this.loc.tableHeaders.name,
    },
    {
      key: 'countryId',
      title: this.loc.tableHeaders.id,
    },
    {
      key: 'countryIsoCode',
      title: this.loc.tableHeaders.isoCode,
    },
  ]

  emptyState: EmptyState = {
    image: 'assets/img/empty-countries.png',
    message: this.loc.countries.empty,
    buttons: [
      {
        text: this.loc.countries.create,
        action: () => this.create(),
      }
    ]
  }

  paginatorInfo = this.loc.countries

  filters: TableFilter[] = [
    {
      key: 'countryName',
      label: this.loc.tableHeaders.name,
      type: 'text',
      placeholder: this.loc.tableHeaders.name,
    }
  ]

  actions: Action[] = [
    {
      icon: 'more_horiz',
      action: (row: Country) => this.onRowClicked(row),
      tooltip: this.loc.common.details,
    },
    {
      icon: 'edit',
      action: (row: Country) => this.edit(row),
      tooltip: this.loc.countries.edit,
    },
    {
      icon: 'delete',
      action: (row: Country) => this.delete(row),
      tooltip: this.loc.countries.delete,
    }
  ]

  createButton: CreateButton = {
    tooltip: this.loc.countries.create,
    action: () => this.create(),
  }

  onRowClick = {
    action: (row: Country) => this.onRowClicked(row),
  }

  loadCountries(): void {
    this.countriesService.getCountries().subscribe({
      next: (res: BaseResponse<Country[]>) => {
        this.data = res.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  }

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      AddCountryComponent,
      this.data,
      'large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.countries)
        );
        this.loadCountries();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.countries)
        );
        this.loadCountries();
      }
    });
  }

  edit(row: Country): void {
    let data: EditModalData = {
      title: this.loc.countries.edit,
      icon: 'language',
      fields: [
        {label: this.loc.fields.name, 
          value: row.countryName, 
          errorMsg: this.loc.countries.errorEditName, 
          validators: [Validators.required]
        },
        {label: this.loc.fields.IsoCode, 
          value: row.countryIsoCode, 
          errorMsg: this.loc.countries.errorEditIsoCode, 
          validators: [Validators.required]
        },
        {label: this.loc.fields.phoneCode, 
          value: row.phoneCode, 
          errorMsg: this.loc.countries.errorEditPhone, 
          validators: [Validators.required]
        },
        {label: this.loc.fields.image, 
          value: row.image, 
          errorMsg: this.loc.countries.errorEditImage, 
          validators: [Validators.required]
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyAction(row, result)
      }
    });
  }

  modifyAction(row: any, result: any){
    let newCountryName = result.find((x: any) => x.label === this.loc.fields.name).value
    let newCountryIsoCode = result.find((x: any) => x.label === this.loc.fields.IsoCode).value
    let newImage = result.find((x: any) => x.label === this.loc.fields.image).value
    let newPhoneCode = result.find((x: any) => x.label === this.loc.fields.phoneCode).value

    let newCountry : Country = {
      countryId: row.countryId,
      countryName: newCountryName,
      countryIsoCode: newCountryIsoCode,
      image: newImage,
      phoneCode: newPhoneCode,
      currency: ''
    }

    this.countriesService.editCountry(newCountry).subscribe(
     { 
      next: (res: any) => {
        if(res.statusRS?.code === "0"){
          this.loading = false;
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('update', this.loc.countries)
          );
          this.loadCountries();
        } else {
          this.loading = false;
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.countries)
          );
        }
      },error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.countries)
          );
        }
      }
    }
    )
  }

  delete(row: Country): void {
    let data: DeleteModalData = {
      title: this.loc.countries.delete,
      icon: 'language',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.countries),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.countriesService.deleteCountry(row.countryId).subscribe({
          next: (res: BaseResponse<void>) => {
            this.loading = true;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.countries)
              );
              this.loadCountries();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.countries)
              );
            }
            this.loading = false;
          },
          error: (err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.countries)
            );
          },
        });
      }
    })
  }

  onRowClicked(row: any): void {
      this.router.navigate(['country-details'], {
        queryParams: {
          countryId: row.countryId,
        },
        queryParamsHandling: 'merge',
      });
  }
}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import { Country } from 'src/app/models/country/country.model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';

@Component({
  selector: 'app-country-details',
  templateUrl: './country-details.component.html',
  styleUrls: ['./country-details.component.css'],
  host: {
    class: 'component'
  }
})
export class CountryDetailsComponent implements OnInit {
  loading = true;
  name = '';
  unfilteredCountries: any[] = [];
  country: Country | undefined;
  dataSource = new MatTableDataSource();
  countryId = '';
  action: any;
  loadingDataSource = new MatTableDataSource<string>();
  selectedIndex = 0;

  showIcon = false;

  constructor(
    private dateFormatter: DateFormatterService,
    public route: ActivatedRoute,
    public location: Location,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    private countriesService: CountriesService,
    public loc : LocalizationService
  ) {}

  ngOnInit(): void {
    this.loadingDataSource.data = ['', '', '', '', ''];
    this.route.queryParams.subscribe((params) => {
      this.countryId = params['countryId'];

    });
    this.getCountries();
  }

  dontShowIcon(){
    this.showIcon = true
  }


  getCountries(): void {
    this.countriesService.getCountries()
      .subscribe(
        {
          next: (res ) => {
            this.unfilteredCountries = res.messageRS;
            this.country = this.unfilteredCountries.find(x => x.countryId == this.countryId);
            this.loading = false;

          },
          error: (err) => {
            this.logoutDialogService.openLogoutDialog(err);
          }

        }

      );
  }

  emptyField (text : string | undefined): string {
    if (text === '' || text === undefined)
      return "-";
    return text;
  }


  formatDate(date?: string): string {
    if (date) return this.dateFormatter.formatDate(date.substring(0, 9), 'dd/MM/yyyy');
    else return '';
  }
  goBack(): void {

    this.location.back();
    let state = JSON.parse(localStorage.getItem('state')!);
    state.mediums = {};
    localStorage.setItem('state', JSON.stringify(state));
  }

}

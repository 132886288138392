import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor() {}

  invalidToken(): Observable<object> {
    const json = {
      statusCode: 401,
      message: 'Token expired',
    };
    return new Observable<object>((observer) => {
      observer.error(json);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Campaign } from 'src/app/models/campaign/campaign.model';
import { CampaignsService } from 'src/app/services/campaigns/campaigns.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class CreateCampaignComponent implements OnInit {
  loading = false;
  campaign: Campaign = new Campaign();
  percentageDiscountNumber: UntypedFormControl = new UntypedFormControl();
  invalidCampaign = false;

  firstFormGroup:UntypedFormGroup = this.formBuilder.group({
    id: ['CAMPAIGN-', [Validators.required,Validators.pattern('^CAMPAIGN-[0-9]{3}$')]],
    description: ['', Validators.required],
  });

  secondFormGroup:UntypedFormGroup = this.formBuilder.group({
    percentageDiscount: [
      '',
      [Validators.required, Validators.min(0), Validators.max(100)],
    ],
    agentDistribution: [
      '',
      [Validators.required, Validators.min(0), Validators.max(100)],
    ],
    financialEntityDistribution: [
      '',
      [Validators.required, Validators.min(0), Validators.max(100)],
    ],
  });

  constructor(
    public dialogRef: MatDialogRef<CreateCampaignComponent>,
    private formBuilder: UntypedFormBuilder,
    private campaignService: CampaignsService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

  toVerify(stepper: MatStepper): void {
    this.campaign.campaignId = this.firstFormGroup.get('id')?.value;
    this.campaign.description = this.firstFormGroup.get('description')?.value;
    this.campaign.percentage =
      this.secondFormGroup.get('percentageDiscount')?.value;
    this.campaign.agentShare = this.secondFormGroup.get(
      'agentDistribution'
    )?.value;
    this.campaign.financialInstitutionShare = this.secondFormGroup.get(
      'financialEntityDistribution'
    )?.value;

    if (
      this.campaign.percentage !== '' &&
      this.campaign.agentShare !== '' &&
      this.campaign.financialInstitutionShare !== ''
    ) {
      const sum =
        this.campaign.agentShare! +
        this.campaign.financialInstitutionShare!;
      if (parseInt(sum) === 100) {
        this.secondFormGroup!.get('agentDistribution')!.setErrors(null);
        this.secondFormGroup!.get('financialEntityDistribution')!.setErrors(null)
        stepper.next();
      }else{
        this.secondFormGroup!.get('agentDistribution')!.setErrors({ sum100:false});
        this.secondFormGroup!.get('financialEntityDistribution')!.setErrors({ sum100:false})
        
      }
    }
  }

  setRemaining(control1:string, control2:string){
    if(this.secondFormGroup.get(control1)?.value > 100){
      this.secondFormGroup.get(control1)?.setValue(100);
    }
    if(this.secondFormGroup.get(control1)?.value < 0){
      this.secondFormGroup.get(control1)?.setValue(0);
    }
    this.secondFormGroup.get(control2)?.setValue(100 - this.secondFormGroup.get(control1)?.value);
  }

  createCampaign(): void {
    this.loading = true;
    this.campaignService.createCampaign(this.campaign).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          this.loading = false;
          if (res.statusRS?.code === '0') {
            this.exit('created');
          } else {
            this.exit('invalid');
          }
        }, 200);
      },
      (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        console.log(err);
        if (err.status !== 401) {
          this.exit('error');
        }
      }
    );
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { Calendar } from 'src/app/models/calendar/calendar.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarsService {
  readonly rootURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getAll(): Observable<BaseResponse<Calendar[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      })
    };
    return this.http.get<BaseResponse<Calendar[]>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/calendar/calendar/x/list-calendar',
      HTTP_OPTIONS
    );
  }

  create(calendar: Calendar): Observable<BaseResponse<Calendar>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = calendar;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.post<BaseResponse<Calendar>>(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/calendar/calendar/x/create-calendar',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  edit(id: string, description: string): Observable<BaseResponse<Calendar>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { calendarDescription: description };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<Calendar>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/calendar/calendar/${id}/update-calendar`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  delete(id: string): Observable<BaseResponse<Calendar>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.delete<BaseResponse<Calendar>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/calendar/calendar/${id}/delete-calendar`,
      HTTP_OPTIONS
    );
  }
}

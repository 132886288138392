import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import {
  Action,
  Column,
  CreateButton,
  EmptyState,
  TableData,
  TableFilter,
  TableTransformer
} from 'src/app/utils/table/table.model';
import { DataSourceData } from 'src/app/models/step/step.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { CreateDataComponent } from '../create-data/create-data.component';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { StepsService } from 'src/app/services/steps/steps.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Validators } from '@angular/forms';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';

@Component({
  selector: 'app-list-metadata-details',
  templateUrl: './list-metadata-details.component.html',
  styleUrls: ['./list-metadata-details.component.css'],
  host: {
    class: 'component'
  }
})
export class ListMetadataDetailsComponent implements OnInit, OnChanges {
  loading = false;
  @Input() metadataId?: string;
  @Input() stepId?: string;
  @Input() updateDataSourceData? = '';
  @Input() controlType = '';
  @Output() notifyParent: EventEmitter<string> = new EventEmitter<string>();

  notifyParentMethod() {
    this.notifyParent.emit(JSON.stringify(this.data));
  }

  async receiveUpdateFromParent() {
    this.loading = true;
    await this.listData();
    this.loading = false;
  }

  dataSourceDataStruct?: DataSourceData;

  boolOptions: any[] = [
    {
      key: '1',
      value: this.loc.common.yes
    },
    {
      key: '0',
      value: this.loc.common.no
    }
  ];

  constructor(
    private stepsService: StepsService,
    public loc: LocalizationService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.updateDataSourceData &&
      changes.updateDataSourceData.currentValue
    ) {
      this.listData();
    }
  }

  ngOnInit(): void {
    this.listData();
  }

  data: TableData[] = [];

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.errors.noData,
    buttons:
      this.controlType == 'LIST' || this.controlType == 'COMBOBOX'
        ? [
            {
              text: this.loc.common.create,

              action: () => this.createData()
            }
          ]
        : []
  };

  columns: Column[] = [
    {
      title: this.loc.tableHeaders.identification,
      key: 'key',
      sortable: true
    },
    {
      title: this.loc.tableHeaders.name,
      key: 'label',
      sortable: true
    },
    {
      title: this.loc.tableHeaders.isHidden,
      key: 'isHidden',
      sortable: true
    },
    {
      title: this.loc.fields.checkout,
      key: 'isHiddenCheckout',
      sortable: true
    }
  ];

  filters: TableFilter[] = [
    {
      key: 'key',
      label: this.loc.tableHeaders.identification,
      placeholder: this.loc.tableHeaders.identification,
      type: 'text'
    },
    {
      key: 'label',
      label: this.loc.tableHeaders.name,
      placeholder: this.loc.tableHeaders.name,
      type: 'text'
    }
  ];

  transformers: TableTransformer = {
    isHidden: (value: string) =>
      value === '1' ? this.loc.common.yes : this.loc.common.no,
    isHiddenCheckout: (value: string) =>
      value === '1' ? this.loc.common.yes : this.loc.common.no
  };

  actions: Action[] = [
    {
      icon: 'edit',
      tooltip: this.loc.inputMetadata.edit,
      action: (row: any) => this.updateData(row)
    },
    {
      icon: 'delete',
      tooltip: this.loc.inputMetadata.delete,
      action: (row: any) => this.deleteData(row)
    }
  ];

  createButton: CreateButton = {
    action: () => this.createData(),
    tooltip: this.loc.data.create
  };

  async createData() {
    const dialogRef = this.dialogService.openDialog(
      CreateDataComponent,
      {
        stepId: this.stepId,
        metadataId: this.metadataId
      },
      'medium-large'
    );
    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined) {
        if (result === 'created') {
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('create', this.loc.step, 'f')
          );
          await this.listData();
          this.notifyParentMethod();
        } else if (result === 'error') {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('create', this.loc.step)
          );
        }
      }
    });
  }

  async listData() {
    this.loading = true;
    try {
      const res: any = await this.stepsService
        .getStepMetadataKeyValue(this.stepId!, this.metadataId!)
        .toPromise();
      if (res && res.statusRS && res.statusRS.code === '0') {
        this.loading = false;
        this.data = (res.messageRS!.data as any) as TableData[];
      }
    } catch (err) {
      this.loading = false;
      console.log(err);
    }
  }

  updateData(row: any) {
    let data: EditModalData = {
      title: this.loc.data.edit,
      icon: 'psychology',
      fields: [
        {
          label: this.loc.tableHeaders.identification,
          value: row.key,
          validators: [Validators.required]
        },
        {
          label: this.loc.tableHeaders.name,
          value: row.label,
          validators: [Validators.required]
        },
        {
          label: this.loc.tableHeaders.isHidden,
          value: row.isHidden,
          validators: [Validators.required],
          comboValues: this.boolOptions,
          isCombo: true
        },
        {
          label: this.loc.fields.checkout,
          value: row.isHiddenCheckout,
          comboValues: this.boolOptions,
          validators: [Validators.required],
          isCombo: true
        }
      ]
    };
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'small',
      false,
      'edit-metadata-data'
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'not updated') {
        this.loading = true;
        let newKey = result.find(
          (x: any) => x.label === this.loc.tableHeaders.identification
        ).value;
        let newValue = result.find(
          (x: any) => x.label === this.loc.tableHeaders.name
        ).value;
        let newHidden = result.find(
          (x: any) => x.label === this.loc.tableHeaders.isHidden
        ).value;
        let newCheckout = result.find(
          (x: any) => x.label === this.loc.fields.checkout
        ).value;

        this.stepsService
          .updateStepMetadataKeyValue(
            this.stepId!,
            this.metadataId!,
            row.key,
            newKey,
            newValue,
            newHidden,
            newCheckout
          )
          .subscribe(
            (res: BaseResponse) => {
              setTimeout(async () => {
                if (res.statusRS?.code === '0') {
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage('update', this.loc.data)
                  );
                  await this.listData();
                  this.notifyParentMethod();
                } else {
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError('update', this.loc.data)
                  );
                }
                this.loading = false;
              }, 200);
            },
            err => {
              setTimeout(() => {}, 200);
              if (err.statusCode !== 401) {
                this.loading = false;
                console.log(err);
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('update', this.loc.data)
                );
              }
            }
          );
      }
    });
  }

  deleteData(row: any) {
    let data: DeleteModalData = {
      title: this.loc.data.delete,
      icon: 'psychology',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.data)
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'delete') {
        this.loading = true;
        this.stepsService
          .deleteStepMetadataKeyValue(this.stepId!, this.metadataId!, row.key)
          .subscribe({
            next: async (res: any) => {
              this.loading = false;
              if (res.statusRS?.code == '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.data)
                );
                await this.listData();
                this.notifyParentMethod();
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.data)
                );
              }
            },
            error: err => {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.data)
              );
            }
          });
      }
    });
  }
}

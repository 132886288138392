import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { IntegrationParameters } from 'src/app/models/integration/integration.model';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-create-parameter',
  templateUrl: './create-parameter.component.html',
  styleUrls: ['./create-parameter.component.css'],
  host: {
    class: 'dialog-component',
  },
})
export class CreateParameterComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  newParameter: IntegrationParameters = {};
  integrationId = '';
  categoriesArray: any = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private integrationService: IntegrationsService,
    public dialogRef: MatDialogRef<CreateParameterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      category: ['', Validators.required],
      description: ['', Validators.required],
      id: ['', [Validators.required, Validators.pattern('^[A-ZÀ-Ÿ_]+$')]],
      value: ['', [Validators.required]],
    });
    this.integrationId = this.data.integrationId;
    this.listCategories();
  }

  next(stepper: MatStepper): void {
    this.newParameter = {
      categoryId: this.firstFormGroup.get('category')!!.value.id,
      key: this.firstFormGroup.get('id')!!.value,
      description: this.firstFormGroup.get('description')!!.value,
      value: this.firstFormGroup.get('value')!!.value,
      categoryDescription:
        this.firstFormGroup.get('category')!!.value.description,
    };
    stepper.next();
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

  listCategories() {
    this.integrationService.getParametersCategories().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.categoriesArray = res.messageRS as unknown as Array<any>;
        } else {
          //TODO HANDLE ERROR
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  createParameter() {
    this.loading = true;
    this.integrationService
      .createIntegrationParameter(this.newParameter, this.integrationId)
      .subscribe(
        (res: BaseResponse) => {
          setTimeout(() => {
            this.loading = false;
            if (res.statusRS?.code === '0') {
              this.exit('created');
            }
          }, 200);
        },
        (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          console.log(err);
          if (err.status !== 401) {
            this.exit('error');
          }
        }
      );
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { AttributeGroupAllowed } from 'src/app/models/attributes/attributeAllowed';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateAttributeGroupAllowedComponent } from '../create-attribute-group-allowed/create-attribute-group-allowed.component';
import { AssociateServiceModeAttributeGroupComponent } from '../associate-service-mode-attribute-group/associate-service-mode-attribute-group.component';
import { BaseResponse } from 'src/app/models/responses.model';
import { EmptyState, TableData, Column, PaginatorInfo, TableTransformer, TableFilter, CreateButton, Action } from 'src/app/utils/table/table.model';
@Component({
  selector: 'app-attribute-group-allowed-list',
  templateUrl: './attribute-group-allowed-list.component.html',
  styleUrls: ['./attribute-group-allowed-list.component.css'],
  host:{
    class: 'component'
  }
})
export class AttributeGroupAllowedListComponent implements OnInit, OnChanges {

  loading: boolean = true;

  attributesAllowed: AttributeGroupAllowed[] = [];
  serviceTemplates: any[] = [];

  stateObjectName = ''

  @Input()
  serviceModeAttributes: boolean = false

  @Input()
  serviceMode: string = ''

  @Input()
  serviceModeDescription: string = ''

  @Input()
  serviceModesServiceTemplate: string = ''

  @Input() tableStyle = 'tableResponsive';

  emptyState: EmptyState = {
    image: "assets/img/tipos-agentes.png",
    message: this.loc.attributesGroup.empty,
    buttons: [{
      text: this.loc.common.create,
      action: () => this.createAttributeGroup()
    }]
  };
  
  data: TableData[] = []
  columns: Column[] = [
    {
       key : 'description',
       title : this.loc.tableHeaders.name,
       sortable : true,
    },
    {
      key : 'attributeCategoryDescription',
      title : this.loc.tableHeaders.category,
      sortable : true,
    },
    {
      key : 'attributeGroup',
      title : this.loc.tableHeaders.group,
      sortable : true,
    },
    {
      key : 'serviceTemplateId',
      title : this.loc.tableHeaders.service,
      sortable : true,
    },
    {
      key : 'operationId',
      title : this.loc.tableHeaders.operation,
      sortable : true,
    }
  ];
  paginatorInfo: PaginatorInfo = this.loc.attributesGroup
  transformers: TableTransformer = {}
  filters: TableFilter[] = [
    {
       label: this.loc.tableHeaders.name,
       key: 'description',
       type: 'text',
       placeholder: this.loc.tableHeaders.name,
    },
    
    {
      label: this.loc.tableHeaders.operation,
      key: 'operationId',
      type: 'text',
      placeholder: this.loc.tableHeaders.operation,
    },
  ];


  actions: Action[] = [
    {
      tooltip: this.loc.common.details,
      icon: 'more_horiz',
      action: (row) => this.details(row)
    },
    {
      tooltip: this.loc.attributesGroup.delete,
      icon: 'delete',
      action: (row) => this.delete(row)
    }
  ];
  createButton: CreateButton = {
    tooltip: this.loc.attributesGroup.create,
    action: () => this.createAttributeGroup()
  };


  constructor(
    public loc: LocalizationService,
    public extensionPointsService: ExtensionPointsService,
    public attributesService: AttributesService,
    private logoutDialogService: LogoutDialogService,
    private dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.serviceMode?.currentValue && this.serviceModeAttributes){
      this.loadServiceModeAttributes();
    }
  }

  ngOnInit(): void {
    this.stateObjectName = this.serviceModeAttributes ? "serviceModeAttributeGroup" : "attributeGroup"
    this.loadServiceTemplates();
    if(!this.serviceModeAttributes){
      this.loadAttributesGroupAllowed();
    }
  }

  loadGroups(){
    if(!this.serviceModeAttributes){
      this.loadAttributesGroupAllowed();
    } else {
      this.loadServiceModeAttributes();
    }
  }

  loadServiceModeAttributes(){
    this.loading = true;
    this.attributesService.getServiceModeAttributeGroupsAllowed(this.serviceMode).subscribe({
      next: (res: BaseResponse<AttributeGroupAllowed[]>) => {
        if(res.statusRS?.code == '0'){
          this.attributesAllowed = res.messageRS as unknown as AttributeGroupAllowed[];
          this.data = this.attributesAllowed as any as TableData[]
        } else {
          this.snackbarService.openErrorSnackbar(this.loc.genericError("list", this.loc.attributesGroup))
        }
        this.loading = false;
      }, error: (err) => {
        this.snackbarService.openErrorSnackbar(this.loc.genericError("list", this.loc.attributesGroup))
      }
    })
  }

  loadAttributesGroupAllowed(){
    this.loading = true;
    this.attributesService.getAttributesGroupAllowed().subscribe(
      (res: BaseResponse<AttributeGroupAllowed[]>) => {
        this.attributesAllowed = res.messageRS as unknown as AttributeGroupAllowed[];
        this.data = this.attributesAllowed as any as TableData[]
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  createAttributeGroup(){
    let dialogRef;
    if(this.serviceModeAttributes){
      let dialogData = {
        serviceTemplateId: this.serviceModesServiceTemplate,
        serviceModeId: this.serviceMode,
        serviceModeDescription: this.serviceModeDescription,
        attributeGroups: this.attributesAllowed
      }
      dialogRef = this.dialogService.openDialog(AssociateServiceModeAttributeGroupComponent, dialogData, "medium-large");
    } else {
      dialogRef = this.dialogService.openDialog(CreateAttributeGroupAllowedComponent,{},"medium-large");
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
          let genericMessageType: "associate" | "create" = this.serviceModeAttributes ? "associate" : "create"
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage(genericMessageType,this.loc.attributesGroup)
          );
          this.loadGroups();
         } else if (result === 'duplicate') {
          this.snackbarService.openErrorSnackbar(this.loc.errors.serviceModeAttributeGroupAlreadyExist);
         } else if (result === 'error') {
          this.snackbarService.openErrorSnackbar(this.loc.genericError("associate", this.loc.attributesGroup));
         }
      });
  }

  loadServiceTemplates(){
    this.extensionPointsService.getServiceTemplates()
      .subscribe({
        next: (res: BaseResponse<any>) => {
          console.log()
          this.serviceTemplates = (res.messageRS as any as any[]).map((servTempl) => {
            return {
              value: servTempl.DSTemp,
              label: servTempl.description
            }
          });
          this.serviceTemplates.unshift(
            {
              value: '',
              label: ''
            }
          )

          this.filters.push(
            {
              label: this.loc.tableHeaders.serviceTemplate,
              key: 'serviceTemplateId',
              type: 'select',
              placeholder: this.loc.tableHeaders.serviceTemplate,
              options: this.serviceTemplates
            },
          )
          
        }
      })
  }

  details(row: AttributeGroupAllowed): void {
    this.router.navigate(['attribute-group-details']
      , {
        queryParams: { 
          groupId: row.id,
          categoryId: row.attributeCategoryId,
          groupDescription: row.attributeGroup,
          serviceTemplateId: row.serviceTemplateId,
          operationId: row.operationId
        },
        queryParamsHandling: 'merge',
      });
    }


  delete(attributeGroup: AttributeGroupAllowed){
    let data: DeleteModalData = {
      title: this.serviceModeAttributes ? this.loc.attributesGroup.dissociate : this.loc.attributesGroup.delete,
      icon: 'paid',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.attributesGroup),
    }
    const dialogRef = this.dialogService.openDialog(DeleteModalComponent, data,"x-small");

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {

        if(this.serviceModeAttributes){
          this.attributesService.deleteServiceModeAttributeGroupAllowed(this.serviceMode, attributeGroup.id).subscribe({
            next: (res: BaseResponse<void>) => {
              if(res.statusRS?.code == '0'){
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage("dissociate",this.loc.attributesGroup)
                );
                this.loadServiceModeAttributes();
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError("dissociate",this.loc.attributesGroup)
                );
              }
              this.loading = false;
        }, error: (err: any) => {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError("dissociate",this.loc.attributesGroup)
          );
        }
          })
        } else {
          this.attributesService.deleteAttributeGroupAllowed(attributeGroup.id).subscribe(
            {
              next: (res: BaseResponse<void>) => {
                    if(res.statusRS?.code == '0'){
                      this.snackbarService.openSuccessSnackbarWithNoReload(
                        this.loc.successMessage("delete",this.loc.attributesGroup)
                      );
                      this.loadAttributesGroupAllowed();
                    } else {
                      this.snackbarService.openErrorSnackbar(
                        this.loc.genericError("delete",this.loc.attributesGroup)
                      );
                    }
                    this.loading = false;
              }, error: (err: any) => {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError("delete",this.loc.attributesGroup)
                );
              }
            }
          )

        }
      }
    });
  }

}

<div class="general-operations-div">
    <div style="display: flex; margin-right: 15px;align-items: center;">
        <button  mat-fab class="page-icon-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">domain</mat-icon>
        </button>
            <label class="page-title"  i18n>{{loc.routes.agents}}</label>
    </div>
</div>

<mat-tab-group
    class="tab-bar"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChange();"
  >
  <mat-tab [label] = loc.tabNames.agentTypes i18n>
        <app-agent-types-list></app-agent-types-list>
    </mat-tab>
    <mat-tab [label] = loc.tabNames.accountIds i18n>
       <app-account-ids></app-account-ids>
    </mat-tab>
</mat-tab-group>

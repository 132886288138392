import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { LogoutDialogService } from '../services/logout-confirmation/logout-dialog.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public logoutService:LogoutDialogService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let tokenStr = localStorage.getItem('access_token')
      if(tokenStr){
        let token:{exp:number} = jwtDecode(tokenStr)
        if(!(token.exp > (Date.now() / 1000))){
          this.logoutService.openLogoutDialog({status:401})
          throw Promise.reject({status:401})
        }
        else{
          const requestWithToken = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${tokenStr}`)
          });
          return next.handle(requestWithToken);
        }
      }
      return next.handle(request);
  }
}

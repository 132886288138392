import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Bank } from 'src/app/models/Banks/bank.model';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Country } from 'src/app/models/country/country.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { BanksService } from '../../../services/banks/banks.service';

@Component({
  selector: 'app-create-bank',
  templateUrl: './create-bank.component.html',
  styleUrls: ['./create-bank.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateBankComponent implements OnInit {
  loading = true;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  countries: Country[] =[];
  actions: any[] = [];
  stageId = '';

  constructor(
    public dialogRef: MatDialogRef<CreateBankComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Bank[],
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private banksService: BanksService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService) { }

  ngOnInit(): void {
    this.loadCountries();
    this.firstFormGroup = this._formBuilder.group({
      abaNumber:[''],
      bankAddress:[''],
      bankId: ['', Validators.required],
      bankName: ['', Validators.required],
      swiftCode: ['', Validators.required],
      country: ['', Validators.required],
    });
  }
  create (){
    this.loading = true;
    let newBank: Bank = {
      bankId: this.firstFormGroup.get('bankId')!.value,
      bankName: this.firstFormGroup.get('bankName')!.value,
      bankAddress: this.firstFormGroup.get('bankAddress')!.value,
      bankCtryId: this.firstFormGroup.get('country')!.value.countryId,
      abaNumber: this.firstFormGroup.get('abaNumber')!.value,
      swiftCode: this.firstFormGroup.get('swiftCode')!.value,
    }
    this.banksService.createBank(newBank).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
   // this.banksService.createBank()
  }
  exit(created: string){
    this.dialogRef.close(created);
  }
  emptyField (text : string | undefined): string {
    if (text === '' || text === undefined)
      return "-";
    return text;
  }

  loadCountries (){
    this.banksService.getCountries().subscribe(
      {
        next: (res: BaseResponse) => {
          this.countries = res.messageRS as unknown as Country [];
          this.loading = false;


        }, error: (err: any) => {
          this.logoutDialogService.openLogoutDialog(err);

        }
      }
    )
  }
  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutConfirmationComponent } from 'src/app/logout-confirmation/logout-confirmation.component';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutDialogService {
  dialogIsOpen = false;
  constructor(public dialogService: DialogService) {
  }
  openLogoutDialog(err: any): void {
    if(!this.dialogIsOpen){
      this.dialogIsOpen = true;
      if (err.statusCode === 401) {
        const dialogRef = this.dialogService.openDialog(LogoutConfirmationComponent, { exp: true }, 'x-small', true);       
        dialogRef.afterClosed().subscribe((result) => {
          this.dialogIsOpen = false;
        });
      } else {
        const dialogRef = this.dialogService.openDialog(LogoutConfirmationComponent, { exp: false }, 'x-small', false);       
        dialogRef.afterClosed().subscribe((result) => {
          this.dialogIsOpen = false;
        });
      }
    }
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { CurrencyDenomination } from 'src/app/models/denominations/currency-denomination.model';
import { DenominationType } from 'src/app/models/denominations/denomination-type.model';
import { Denomination } from 'src/app/models/denominations/denomination.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class DenominationsService {
  readonly rootURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getCurrencyDenominations(
    currencyCode: string
  ): Observable<BaseResponse<CurrencyDenomination[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get<BaseResponse<CurrencyDenomination[]>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/currency/currency/${currencyCode}/list-currency-denominations`,
      HTTP_OPTIONS
    );
  }

  getDenominationTypes(): Observable<BaseResponse<DenominationType[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get<BaseResponse<DenominationType[]>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/shifts/denomination-type/0/get-denominations-types`,
      HTTP_OPTIONS
    );
  }

  editName(
    currencyCode: string,
    denomination: Denomination
  ): Observable<BaseResponse<Denomination>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = denomination;

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };

    const url =
      this.rootURL +
      `RSAdaptorFE/api/v1/paycorr/currency/currency/${currencyCode}/update-denomination-name`;

    return this.http.put<BaseResponse<Denomination>>(
      url,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  toggleState(
    currencyCode: string,
    denomination: Denomination
  ): Observable<BaseResponse<Denomination>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = denomination;

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };

    const url =
      this.rootURL +
      `RSAdaptorFE/api/v1/paycorr/currency/currency/${currencyCode}/update-currency-status`

    return this.http.put<BaseResponse<Denomination>>(
      url,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  createDenomination(
    denomination: CurrencyDenomination
  ): Observable<BaseResponse<CurrencyDenomination>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = denomination;

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };

    const url =
      this.rootURL +
      `RSAdaptorFE/api/v1/paycorr/currency/currency/x/create-currency-denomination`;

    return this.http.post<BaseResponse<CurrencyDenomination>>(
      url,
      baseRequest,
      HTTP_OPTIONS
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { ServiceTemplate } from 'src/app/models/digital-services/ServiceTemplate.model';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { BaseResponse } from 'src/app/models/responses.model';

@Component({
  selector: 'app-create-service-mode',
  templateUrl: './create-service-mode.component.html',
  styleUrls: ['./create-service-mode.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateServiceModeComponent implements OnInit {

  loading = false;
  firstFormGroup!: UntypedFormGroup;
  templates: ServiceTemplate[] = [];


  constructor(
    public dialogRef: MatDialogRef<CreateServiceModeComponent>,
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public snackbarService: SnackbarService,
    private extensionPointsService: ExtensionPointsService,
    private attributesService: AttributesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {

    this.firstFormGroup = this._formBuilder.group({
      id: ['', Validators.required],
      template: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.getServiceTemplates();
  }

  getServiceTemplates(){
    this.loading = true;
    this.extensionPointsService.getServiceTemplates().subscribe(
      (res: BaseResponse<ServiceTemplate[]>) => {
        this.loading = false;
        if(res.statusRS?.code == "0"){
          this.templates = res.messageRS as ServiceTemplate[];
        } else {
          this.snackbarService.openErrorSnackbar(this.loc.errors.loadingServiceTemplate)
        }

      }, (err) => {
        this.snackbarService.openErrorSnackbar(this.loc.errors.loadingServiceTemplate)
      }
    )
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  create() {
    this.loading = true;

    this.attributesService.createServiceMode(this.firstFormGroup.get('id')?.value, this.firstFormGroup.get('description')?.value, this.firstFormGroup.get('template')?.value.DSTemp).subscribe(
      (res: BaseResponse<void>) => {
        setTimeout(() => {
          this.loading = false;
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else if(res.statusRS?.code == "-91855"){
            this.exit("duplicate")
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }


}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IntegrationTypeParameters } from 'src/app/models/integration/integrationTypeParameters.model';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { MatStepper } from '@angular/material/stepper';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-type-parameter',
  templateUrl: './create-type-parameter.component.html',
  styleUrls: ['./create-type-parameter.component.css'],
  host: {
    class: 'dialog-component',
  },
})
export class CreateTypeParameterComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  newParameter: IntegrationTypeParameters = {};
  integrationTypeId = '';

  categoriesArray: any = [];
  _isMandatory: string[] = [this.loc.common.yes, this.loc.common.no];
  isMandat = '';
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loc: LocalizationService,
    public dialogRef: MatDialogRef<CreateTypeParameterComponent>,
    private formBuilder: UntypedFormBuilder,
    private integrationService: IntegrationsService,
    private snackbarService: SnackbarService,
    
  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      categoryId: ['', Validators.required],
      parameterId: ['', Validators.required],
      defaultValue: [''],
      description: ['', Validators.required],
      _isMandatory: ['', Validators.required],
    });
    this.integrationTypeId = this.data.integrationTypeId;
    this.listCategories();

  }

  next(stepper: MatStepper): void {
    this.isMandat = this.firstFormGroup.get('_isMandatory')!!.value;
    let isMandatory = '';
    if(this.isMandat === this.loc.common.yes){
      isMandatory = 'true';
    } else {
      isMandatory = 'false';
    }
    this.newParameter = {
      categoryId: this.firstFormGroup.get('categoryId')!!.value.id,
      parameterId: this.firstFormGroup.get('parameterId')!!.value,
      defaultValue: this.firstFormGroup.get('defaultValue')!!.value,
      parameterDescription: this.firstFormGroup.get('description')!!.value,
      isMandatory: isMandatory,
    };
    stepper.next();
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

  createTypeParameter() {
    this.loading = true;
    this.integrationService
      .createIntegrationTypeParameter(this.newParameter, this.integrationTypeId)
      .subscribe(
        (res: BaseResponse) => {
          setTimeout(() => {
            this.loading = false;
            if (res.statusRS?.code === '0') {
              this.exit('created');
            } else if(res.statusRS?.code === '-91954'){
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.typeParameterAlreadyExists
              );
              this.dialogRef.close();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('create', this.loc.parameters)
              );
              this.dialogRef.close();
            }
          }, 200);
        },
        (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          console.log(err);
          if (err.status !== 401) {
            this.exit('error');
          }
        }
      );
  }

  listCategories() {
    this.integrationService.getParametersCategories().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.categoriesArray = res.messageRS as unknown as Array<any>;
        } else {
          //TODO HANDLE ERROR
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VerifyGroup } from 'src/app/models/stepper/step-modal';


@Component({
  selector: 'app-verify-step',
  templateUrl: './verify-step.component.html',
  styleUrls: ['./verify-step.component.css']
})
export class VerifyStepComponent implements OnInit {

  @Input() verifiedGroup! : VerifyGroup[];
  @Input() verifiedItemsDictionary!: { [key: string]: any };
  @Output() saveEvent = new EventEmitter();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  save(){
    this.saveEvent.emit();
  }

  displayInfo(element : string): string{
    return this.verifiedItemsDictionary[element] && this.verifiedItemsDictionary[element].description? this.verifiedItemsDictionary[element].description : this.verifiedItemsDictionary[element] 
  }

  capitalizeFirstLetter(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  }

}

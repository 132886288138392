import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DisaggregationStep } from 'src/app/models/posting-schemes/DisaggregationStep.model';

import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateDisaggregationStepComponent } from '../create-disaggregation-step/create-disaggregation-step.component';
import {
  addFilter,
  customFilterPredicate,
  filter,
  pipeForFilters,
} from '../../../utils/Filters/Filters';
import { stringSort } from '../../../utils/Filters/Sorts';

@Component({
  selector: 'app-list-disaggregation-steps',
  templateUrl: './list-disaggregation-steps.component.html',
  styleUrls: ['./list-disaggregation-steps.component.css'],
  host: {
    class: 'component',
  },
})
export class ListDisaggregationStepsComponent implements OnInit {
  @Input() tableStyle = 'tableResponsive';
  @Input() id = '';

  fieldsDataSource = new MatTableDataSource();
  loadingDataSource = new MatTableDataSource<string>();
  fieldsDisplayedColumns: string[] = [
    'ordinal',
    'stepTemplateId',
    'subject',
    'operation',
    'suboperation',
    'baseOrdinal',
    'settlementMode',
    'actions',
  ];
  filteredOptions: Observable<string[]> = new Observable<string[]>();
  filteredOptionsDesc: Observable<string[]> = new Observable<string[]>();
  myControl = new UntypedFormControl();
  myControlDesc = new UntypedFormControl();
  filters: any = {
    description:
      JSON.parse(localStorage.getItem('state')!)?.transDisagregation
        ?.description || '',
    id:
      JSON.parse(localStorage.getItem('state')!)?.transDisagregation?.id || '',
  };
  loading = true;
  fieldsNames: string[] = [];
  fieldsDescriptions: string[] = [];

  steps: DisaggregationStep[] = [];

  pageSize = 5;
  pageIndex = 0;

  constructor(
    public postingSchemesService: PostingSchemesService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  @ViewChild(MatPaginator, { static: false }) set paginator(
    value: MatPaginator
  ) {
    this.loc.translateMatPaginator(value);
    if (this.fieldsDataSource) {
      this.fieldsDataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false }) set sort(value: MatSort) {
    if (this.fieldsDataSource) {
      this.fieldsDataSource.sort = value;
    }
  }

  ngOnInit(): void {
    this.loadingDataSource.data = ['', '', '', '', ''];
    let state = JSON.parse(localStorage.getItem('state')!);
    this.myControl.setValue(state?.transDisagregation?.id || '');
    this.filters['id'] = state?.transDisagregation?.id || '';
    this.myControlDesc.setValue(state?.transDisagregation?.description || '');
    this.filters['description'] = state?.transDisagregation?.description || '';
    this.loadDisaggregationSteps();
  }

  pageEvents(event: any) {
    let state = JSON.parse(localStorage.getItem('state')!);
    if (!state.transDisagregation) {
      state.transDisagregation = {};
    }
    state.transDisagregation['pageIndex'] = event.pageIndex;
    state.transDisagregation['pageSize'] = event.pageSize;
    localStorage.setItem('state', JSON.stringify(state));
  }

  loadDisaggregationSteps(): void {
    this.loading = true;
    this.postingSchemesService.getDisaggregationSteps(this.id).subscribe(
      (res: BaseResponse) => {
        let response = res.messageRS as any as DisaggregationStep[];

        this.steps = response;

        this.fieldsDataSource.data = this.steps;
        this.fieldsNames = this.steps.map((step) => step.stepTemplateId!);
        this.fieldsDescriptions = this.steps.map(
          (step) => step.subjectDescription!
        );
        this.filteredOptions = this.myControl.valueChanges.pipe(
          pipeForFilters(filter(this.fieldsNames).bind(this), stringSort)
        );
        this.filteredOptionsDesc = this.myControlDesc.valueChanges.pipe(
          pipeForFilters(filter(this.fieldsNames).bind(this), stringSort)
        );
        this.loading = false;
        this.setSortAndPaginator();
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  addFilter(event: any, column: string) {
    addFilter(
      event,
      column,
      'transDisagregation',
      this.filters,
      this.fieldsDataSource
    );
  }

  onActionClicked(row: any): void {}

  setSortAndPaginator(): void {
    this.fieldsDataSource.sort = this.sort;
    this.fieldsDataSource.filterPredicate = customFilterPredicate;
    this.fieldsDataSource.paginator = this.paginator;
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateDisaggregationStepComponent,
      {
        disaggregationId: this.id,
        steps: this.steps,
      },
      'large'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.step)
        );
        this.loadDisaggregationSteps();
      } else if (result === '-91691') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.disaggregationUsed
        );
      } else if (result === '-91632' || result === '-91692') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.step)
        );
      }
    });
  }

  delete(element: DisaggregationStep) {
    let dataModal: DeleteModalData = {
      title: this.loc.step.delete,
      icon: 'signpost',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.step),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      dataModal,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        let stepToDelete = this.steps.find((x) => x.ordinal == element.ordinal);
        let stepIndex = this.steps.indexOf(stepToDelete!);

        let newSteps: any[] = [];
        this.steps.forEach((x: any, index: number) => {
          if (index < stepIndex) {
            newSteps.push(x);
          } else if (index > stepIndex) {
            x.ordinal = (parseInt(x.ordinal) - 1).toString();
            newSteps.push(x);
          }
        });
        let message = {
          disaggregationId: this.id,
          steps: newSteps.map((step: DisaggregationStep) => {
            return {
              ordinal: parseInt(step.ordinal!),
              stepTemplateId: step.stepTemplateId!,
              baseOrdinal: step.baseOrdinal!,
            };
          }),
        };
        this.postingSchemesService.createDisaggregationSteps(message).subscribe(
          (res: BaseResponse) => {
            if (res.statusRS?.code === '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.step)
              );
              this.loadDisaggregationSteps();
            } else if (res.statusRS?.code === '-91691') {
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.disaggregationUsed
              );
              this.loading = false;
            } else if (
              res.statusRS?.code === '-91632' ||
              res.statusRS?.code === '-91692'
            ) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('create', this.loc.step)
              );
              this.loading = false;
            }

            //this.loadPostings();
          },
          (err) => {
            this.logoutDialogService.openLogoutDialog(err);
          }
        );
      }
    });
  }

  moveUp(element: DisaggregationStep): void {
    this.loading = true;
    const index = this.steps.indexOf(element);
    if (index === 0) {
      // can not move higher
      return;
    }

    const swap = this.steps[index - 1];
    swap.ordinal = (parseInt(swap.ordinal!) + 1).toString();
    element.ordinal = (parseInt(element.ordinal!) - 1).toString();
    this.steps[index - 1] = element;
    this.steps[index] = swap;
    this.steps.sort((a: any, b: any) => (a.ordinal!! < b.ordinal!! ? -1 : 1));
    let messageToSend = {
      disaggregationId: this.id,
      steps: this.steps.map((step) => {
        return {
          ordinal: parseInt(step.ordinal!)!,
          stepTemplateId: step.stepTemplateId!,
          baseOrdinal: step.baseOrdinal!,
        };
      }),
    };
    this.postingSchemesService
      .createDisaggregationSteps(messageToSend)
      .subscribe(
        (res: BaseResponse) => {
          if (res.statusRS?.code === '0') {
            this.loadDisaggregationSteps();
          } else if (res.statusRS?.code === '-91691') {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.disaggregationUsed
            );
            this.loading = false;
          } else if (
            res.statusRS?.code === '-91632' ||
            res.statusRS?.code === '-91692'
          ) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('create', this.loc.step)
            );
            this.loading = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  moveDown(element: any): void {
    this.loading = true;
    const index = this.steps.indexOf(element);
    if (index === this.steps.length - 1) {
      // can not move down
      return;
    }

    const swap = this.steps[index + 1];
    swap.ordinal = (parseInt(swap.ordinal!) - 1).toString();
    element.ordinal = (parseInt(element.ordinal!) + 1).toString();
    this.steps[index + 1] = element;
    this.steps[index] = swap;
    this.steps.sort((a: any, b: any) => (a.ordinal!! < b.ordinal!! ? -1 : 1));
    let messageToSend = {
      disaggregationId: this.id,
      steps: this.steps.map((step) => {
        return {
          ordinal: parseInt(step.ordinal!)!,
          stepTemplateId: step.stepTemplateId!,
          baseOrdinal: step.baseOrdinal!,
        };
      }),
    };
    this.postingSchemesService
      .createDisaggregationSteps(messageToSend)
      .subscribe(
        (res: BaseResponse) => {
          if (res.statusRS?.code === '0') {
            this.loadDisaggregationSteps();
          } else if (res.statusRS?.code === '-91691') {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.disaggregationUsed
            );
            this.loading = false;
          } else if (
            res.statusRS?.code === '-91632' ||
            res.statusRS?.code === '-91692'
          ) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('create', this.loc.step)
            );
            this.loading = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  showUpArrow(element: any) {
    const index = this.steps.indexOf(element);
    return index >= 1;
  }

  showDownArrow(element: any) {
    const index = this.steps.indexOf(element);
    return this.steps.length !== index + 1;
  }
}

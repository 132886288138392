<app-page-title [withBackButton]="false" icon="paid" [title]="loc.routes.services"></app-page-title>

<mat-tab-group
    class="tab-bar"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChange();"
>
    <mat-tab [label]="loc.tabNames.attributesGroupAllowed" i18n>
        <app-attribute-group-allowed-list></app-attribute-group-allowed-list>
    </mat-tab>

    <mat-tab [label] ="loc.tabNames.serviceModes" i18n>
        <app-list-service-modes></app-list-service-modes>
    </mat-tab>

    <mat-tab [label] ="loc.tabNames.serviceOperationMediums" i18n>
        <app-digital-service-operation-mediums></app-digital-service-operation-mediums> 
    </mat-tab>

    <mat-tab [label] ="loc.tabNames.settlementModes" i18n>
        <app-settlement-modes></app-settlement-modes>
    </mat-tab>

    <mat-tab [label]="loc.tabNames.serviceTemplates" i18n>
        <app-service-templates></app-service-templates>
    </mat-tab>

</mat-tab-group>
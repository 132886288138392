<div class="changeLanguage">
  <mat-select [(ngModel)]="filteredLanguage"
              (selectionChange)="changeLanguage()">
    <mat-select-trigger>
      <div class="country-div">
        <mat-label class="selected-country-lbl" i18n>{{filteredLanguage.name}}</mat-label>
      </div>
      <img [src]="filteredLanguage.img"
           class="selected-country-flag"/>
    </mat-select-trigger>
    <mat-option [value]="language" *ngFor="let language of
            languages" class="language-option">{{language.name}}
      <img
        [src]="language.img" class="country-flag"/>
    </mat-option>
  </mat-select>
</div>
<div class="new-background">
  <div class="left-component">
    <div style="display: flex; position: relative; justify-content: center">
      <img class="logo-img" src="assets/img/logo.svg"/>
      <div style="margin-left: 9px;">
        <p style="color: white;font-size:
                    30px; line-height: normal">
          PAYCORR
          <br>
          <span style="color: #00baac">
          SETTINGS
        </span>
        </p>

      </div>
    </div>
    <img class="login-image" src="assets/img/login-console.png"/>
  </div>

  <div class="right-component">
    <div class="description-box">
      <div class="description-cont"><label class="description-highlight"
                                           i18n> {{loc.login.configuration}}</label></div>
      <div class="description-cont"><label class="description"
                                           i18n>{{loc.login.agents}}</label></div>
      <div class="description-cont"><label class="description"
                                           i18n>{{loc.login.notifications}}</label></div>
      <div class="description-cont"><label class="description"
                                           i18n>{{loc.login.extensionPointsAndPostings}}</label></div>
    </div>
    <form class="sign-in-form">
      <p style="margin-bottom: 0 !important; font-family:
                Montserrat, sans-serif; color: white; font-size: 22px;">{{loc.login.login}}</p>
      <div class="separator">

        <style class="separator"></style>
      </div>
      <mat-form-field appearance="fill" class="username
                login-input">
        <div class="location">
          <input matInput class="form-control"
                 [placeholder]="loc.login.username"
                 type="text"
                 name="Username"
                 [(ngModel)]="loginModel.username"
                 (input)="onSearchChange()">
        </div>
      </mat-form-field>
      <div>
        <style class="separator"></style>
      </div>

      <mat-form-field appearance="fill" class="password
                login-input">
        <div class="location">
          <input class="form-control" type="password"
                 matInput
                 [placeholder]="loc.login.password"
                 name="Password"
                 [(ngModel)]="loginModel.password"
                 (input)="onSearchChange()">
        </div>
      </mat-form-field>

      <div class="sign-in">
        <button mat-raised-button type="submit"
                class="sign-in-button button-text"
                (click)="onLoginButtonClicked()" i18n>{{loc.login.login}}</button>
      </div>

      <div style="display: flex; justify-content: flex-end">
        <button
          mat-button
          (click)="forgotPassword()"
          class="forgot-password-button button-text"

          i18n>
          {{ loc.login.forgotPassword }}
        </button>
      </div>

      <div class="error-message" [ngStyle]="{'visibility': showErrorMessage ? 'visible' : 'hidden'}">
        <div class="error-icon">
          <mat-icon fontSet="material-symbols-outlined">
            error
          </mat-icon>
        </div>
        <label i18n style="color: #E93030">
          {{errorMessage}}
        </label>
      </div>

    </form>
  </div>
</div>

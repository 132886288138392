<div class="dark" *ngIf="loading"> <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;"></mat-spinner></div>

<div class="title">
    <div class="inner-title-container">
    <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon class="action-button-icon" fontSet="material-icons-outlined">vpn_key</mat-icon>
    </button>
    <div class="dialog-title"><label>{{loc.login.forgotPassword}}</label></div>
    </div>
    <button class="exit-button" (click)="exit('')"  >
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>

<mat-horizontal-stepper class="horizontal-stepper" style="width: 60%;" ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}" labelPosition="bottom" [linear]="isLinear" #stepper appStepper [step] = "stepper.selectedIndex">
    <ng-template matStepperIcon="done" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon" *ngIf="index == 0">person</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon" *ngIf="index == 1">password</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon" *ngIf="index == 2">check_box</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="edit" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon" *ngIf="index == 0">person</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon" *ngIf="index == 1">password</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon" *ngIf="index == 2">check_box</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="number" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon" *ngIf="index == 0">person</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon" *ngIf="index == 1">password</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon" *ngIf="index == 2">check_box</mat-icon>
    </ng-template>

    <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
        <ng-template matStepLabel i18n>{{loc.stepTitle.username}}</ng-template>
        <form [formGroup]="firstFormGroup" class="form-step">
            <div class="data-box">
            <mat-form-field appearance="fill" style="width: 225px;">
                <mat-label>{{loc.fields.user}}</mat-label>
                <input matInput name="email" formControlName="emailForm" required #email>
            </mat-form-field>
            </div>
            <ng-container *ngIf="showError">
                <div class="error-msg"><label>{{errorMessage}}</label></div>
            </ng-container>
            <div class="dialog-nav-buttons">
                <button mat-fab class="button green-button" (click)="generateOTP(stepper)">
                    <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
        <ng-template matStepLabel>{{loc.stepTitle.code}}</ng-template>
        <form [formGroup]="secondFormGroup" class="form-step">
            <div>
                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]=config></ng-otp-input>
            </div>
            <div class="dialog-nav-buttons">
                <button mat-fab class="button green-button" matStepperPrevious   >
                    <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                </button>
                <button mat-fab class="button green-button" (click)="validateOTP()"  >
                    <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </form>
    </mat-step>

    <mat-step [editable]="isEditable">
        <ng-template matStepLabel>{{loc.stepTitle.password}}</ng-template>
        <form class="form-step">
            <div class="data-box">
                <div class="create-row">
                    <mat-form-field appearance="fill" class="password-input">
                        <mat-label>{{loc.fields.newPassword}}</mat-label>
                        <input matInput name="password" type="password" required #password [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}" (input)="hideError()"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="password-input">
                        <mat-label>{{loc.fields.confirmPassword}}</mat-label>
                        <input matInput name="password" type="password" required #password [(ngModel)]="newPasswordRep" (input)="hideError()"/>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="showError">
                    <div class="error-msg">
                        <div class="error-icon">
                            <mat-icon fontSet="material-symbols-outlined">
                                error
                            </mat-icon>
                        </div>
                        <label i18n>
                            {{errorMessage}}
                        </label>
                    </div>
                </ng-container>
            </div>
            <div class="dialog-nav-buttons">
                <button mat-fab class="button green-button" matStepperPrevious (click)="hideError()"  >
                    <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                </button>
                <button mat-fab class="button green-butto" (click)="changePassword()"  >
                    <mat-icon class="stepper-item-icon" fontSet="material-icons-outlined">save</mat-icon>
                </button>
            </div>
        </form>
    </mat-step>
</mat-horizontal-stepper>
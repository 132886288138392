import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditFieldData } from 'src/app/models/edit-modal/edit-field-data';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.css'],
  host: {
    "class" : "confirmation-dialog-component"
  }
})
export class EditModalComponent implements OnInit {
  loading: boolean = false;
  firstFormGroup!: UntypedFormGroup;
  group: any = {};

  constructor(
    public dialogRef: MatDialogRef<EditModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loc : LocalizationService,
    private _formBuilder: UntypedFormBuilder,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
  }

  ngOnInit(): void {
    const host = this.el.nativeElement
    this.renderer.addClass(host, this.data.class)
    this.data.fields?.forEach((item: EditFieldData) => {
      this.group[item.label] = [item.value, item.validators]
    });
    this.firstFormGroup = this._formBuilder.group(this.group);
  }

  disable(): boolean{
    let result = false
    this.data.fields?.forEach((item: EditFieldData) => {
      if(this.firstFormGroup.controls[item.label].invalid){
        result = true 
      }
    });
    return result
  }

  modify() {
    this.data.fields.forEach((item: EditFieldData) => {
      item.value = this.firstFormGroup.get(item.label)!.value
    })
    this.exit(this.data.fields);
  }

  exit(message: any) {
    this.dialogRef.close(message);
  }

  handleAction(field: EditFieldData){
    if(field.inputAction){
      field.inputAction(this.firstFormGroup)
    }
  }
}

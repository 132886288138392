import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../services/localization/localization.service';

@Component({
  selector: 'app-notificationss',
  templateUrl: './notificationss.component.html',
  styleUrls: ['./notificationss.component.css'],
  host: {
    class: 'component'
  }
})
export class NotificationssComponent implements OnInit {
  selectedIndex = 0;
  constructor(public loc: LocalizationService) {}

  ngOnInit(): void {
    let state = JSON.parse(localStorage.getItem('state')!);
    if (state.notification) {
      this.selectedIndex = state.notification.tab;
    }
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state.notification = {
      tab: this.selectedIndex
    };
    localStorage.setItem('state', JSON.stringify(state));
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ShowStructureComponent } from 'src/app/apis/showStructure/show-structure/show-structure.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Step, StepMetadata } from 'src/app/models/step/step.model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { StepsService } from 'src/app/services/steps/steps.service';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { Action, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { CreateInputOutputMetadataComponent } from '../create-input-output-metadata/create-input-output-metadata.component';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Validators } from '@angular/forms';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { MetadataType } from 'src/app/models/metadata/metadata-type/metadataType.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-input-output-metadata',
  templateUrl: './input-output-metadata.component.html',
  styleUrls: ['./input-output-metadata.component.css'],
  host: {
    class: 'component',
  },
})

export class InputOutputMetadataComponent implements OnInit {
  loading = false;

  @Input() input?: boolean;
  @Input() stepId?: string;
  @Input() apiName?: string;
  @Input() apiId?: string;

  step?: Step;

  metadatas: any[] = [];

  metadataTypes: any[] = [];
  controlTypes: any[] = [{key: 'TEXT', value: 'Text'},
                          {key: 'COMBOBOX', value: 'Combobox'},
                          {key: 'CHECKBOX', value: 'Checkbox'}, 
                          {key: 'SLIDER', value: 'Slider'}, 
                          {key: 'OTP', value: 'OTP'},
                          {key: 'LIST', value: 'List'}];

  datasource: any[] = [{key: 'STATIC', value: this.loc.common.static},
                       {key: 'DYNAMIC', value: this.loc.common.dynamic}];
  boolOptions: any[] = [{
      key: '1',
      value: this.loc.common.yes
    },
    {
      key: '0',
      value: this.loc.common.no
    }
  ];

  constructor(
    public loc: LocalizationService,
    public snackbarService: SnackbarService,
    public router: Router,
    private dateFormatter: DateFormatterService,
    private htmlGenerator: SafeHTMLGeneratorService,
    private stepsService: StepsService,
    private dialogService: DialogService,
    private metadataService: MetadataService,
  ) { }

  ngOnInit(): void {
    if(!this.input){
      this.emptyState.message = this.loc.errors.emptyMetadataOutputs
      this.createButton.tooltip =  this.loc.outputMetadata.create
    }
    this.getData();
  }

  data: TableData[] = [];

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.errors.emptyMetadataInputs,
    buttons:  [
      {
        text: this.loc.common.create,
        action: () => this.createMetadata(),
      },
    ]
  };

  columns: Column[] = [
    { title: this.loc.tableHeaders.ordinal, 
      key: 'ordinal', 
      sortable: true 
    },
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.type,
      key: 'type',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.controlType,
      key: 'controlType',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.defaultValue,
      key: 'defaultValue',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.validation,
      key: 'validation',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.isHidden,
      key: 'isHidden',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.isMandatory,
      key: 'isMandatory',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.isEditable,
      key: 'isEditable',
      sortable: true,
    },
    {
      title: this.loc.fields.datasource,
      key: 'dataSource',
      sortable: true,
    },
    {
      title: this.loc.fields.checkout,
      key: 'checkout',
      sortable: true,
    },
  ];
  
  filters: TableFilter[] = [
    {
      key: 'id',
      label: this.loc.tableHeaders.id,
      placeholder: this.loc.tableHeaders.id,
      type: 'text',
    },
    {
      key: 'type',
      label: this.loc.tableHeaders.type,
      placeholder: this.loc.tableHeaders.type,
      type: 'text',
    },
    {
      key: 'controlType',
      label: this.loc.tableHeaders.controlType,
      placeholder: this.loc.tableHeaders.controlType,
      type: 'text',
    },
    {
      key: 'description',
      label: this.loc.tableHeaders.description,
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    },
  ];

  transformers: TableTransformer = {
    creationDate: (value: string) => this.formatDate(value),
    isHidden: (value: string) =>
      this.htmlGenerator.statusIcon(value === '1'),
    isMandatory: (value: string) =>
      this.htmlGenerator.statusIcon(value === '1'),
    isEditable: (value: string) =>
      this.htmlGenerator.statusIcon(value === '1'),
    checkout: (value: string) =>
      this.htmlGenerator.statusIcon(value === '1'),
    dataSource: (value: string) => this.formatDatasource(value)
  };

  showDatasourceData(row: StepMetadata){
    const dialogRef = this.dialogService.openDialog(
      ShowStructureComponent,
      {
        body: JSON.parse(row.dataSourceData!),
        title: this.loc.fields.datasourceData
      },
      'large'
    );
  }

  actions : Action[] = [
    {
      icon: 'swap_horizontal_circle',
      tooltip: this.loc.common.view+ this.loc.tabNames.outputMetadataMap,
      action: (row: StepMetadata) => {
        this.goToOutputDetails(row);
      },
      condition: () => !this.input!
    },
    {
      icon: 'more_horiz',
      action: (row: StepMetadata) => this.onRowClicked(row),
      tooltip: this.loc.common.details,
    },
    {
      icon: 'delete',
      tooltip: this.loc.inputMetadata.delete,
      action: (row: any) => this.deleteMetadata(row),
    }
  ]

  onRowClicked(row: StepMetadata): void {
    this.router.navigate(['step-details/metadata-details'], {
      queryParams: {
        stepId: this.stepId,
        id: row.id,
        type: row.type,
        controlType: row.controlType,
        description: row.description,
        defaultValue: row.defaultValue,
        validation: row.validation,
        isHidden: row.isHidden,
        isMandatory: row.isMandatory,
        isEditable: row.isEditable,
        dataSource: row.dataSource,
        dataSourceData: JSON.stringify(row.dataSourceData),
        checkout: row.checkout,
        ordinal: row.ordinal,
        isInput: this.input
      },
      queryParamsHandling: 'merge',
    });
}

  actionColumnName : string = this.loc.fields.datasourceData;

  formatDate(date: string, format?: string): string {
    return this.dateFormatter.formatDate(date, format ? format : 'dd/MM/yyyy');
  }

  getData(){
    this.loading = true;
    this.stepsService.getStepDetails(this.stepId!).subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.step = res.messageRS as any as Step;
          if(this.input){
            this.data = this.step.inputMetadata as any as TableData[];
            this.metadatas = this.data;
          } else {
            this.data = this.step.outputMetadata as any as TableData[];
            this.metadatas = this.data;
          }
          this.data.sort((a, b) => (a.ordinal > b.ordinal) ? 1 : -1);
          this.loading = false;
          this.metadatas = this.metadatas.map(item => ({
            metadataId: item.id,
            metadataDescription: item.description,
            metadataType: item.type,
            metadataControlType: item.controlType,
            metadataDefaultValue: item.defaultValue,
            metadataIsMandatory: item.isMandatory,
            metadataIsEditable: item.isEditable,
            metadataIsHidden: item.isHidden,
            metadataDataSource: item.dataSource,
            metadataDataSourceData:  item.dataSourceData,
            metadataValidation: item.validation,
            ordinal: item.ordinal+'',
            metadataCheckout :item.checkout
          }));
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );

    this.metadataService.listMetadatTypes().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          let metaTypes = res.messageRS as unknown as MetadataType[];
          this.metadataTypes = metaTypes.map(item =>( {
            key: item.id,
            value: item.metadataName,
          }));
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  createButton: CreateButton = {
    action: () => this.createMetadata(),
    tooltip: this.loc.inputMetadata.create,
  }

  createMetadata(){
    const dialogRef = this.dialogService.openDialog(
      CreateInputOutputMetadataComponent,
      {isInput: this.input , ordinal: this.data.length+1, stepId: this.stepId, metadata: this.step},
      'large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  deleteMetadata(row: any){
    let data: DeleteModalData = {
      title: this.input? this.loc.inputMetadata.delete : this.loc.outputMetadata.delete,
      icon: 'psychology',
      confirmDeleteMessage: this.loc.confirmDelete(this.input? this.loc.inputMetadata : this.loc.outputMetadata),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    let meta: any[] = this.data as any[];
    let newMeta: any[] = [];

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        let i = 1;
        for(let d of meta){
          if(d.ordinal != row.ordinal){
            d.ordinal = i;
            i++;
            newMeta.push(d);
          }
        }        
          newMeta = newMeta.map(item => ({
          
          metadataId: item.id,
          metadataDescription: item.description,
          metadataType: item.type,
          metadataControlType: item.controlType,
          metadataDefaultValue: item.defaultValue,
          metadataIsMandatory: item.isMandatory,
          metadataIsEditable: item.isEditable,
          metadataIsHidden: item.isHidden,
          metadataDatasource: item.dataSource,
          metadataDatasourceData: item.dataSourceData,     
          metadataValidation: item.validation,
          ordinal: item.ordinal,
          metadataCheckout :item.checkout
        }));

       this.stepsService.updateStepMetadata(this.stepId!, newMeta, this.input!).subscribe(
        (res: BaseResponse) => {
          setTimeout(() => {
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.input? this.loc.inputMetadata : this.loc.outputMetadata)
              );
              this.getData();
              this.loading = false;
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.input? this.loc.inputMetadata : this.loc.outputMetadata)
              );
            this.loading = false;

            }
          }, 200);
        }, (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.input? this.loc.inputMetadata : this.loc.outputMetadata)
            );
          }
        }
        
      )
      }
    })
  }

  editMetadata(row: any){
    let data: EditModalData = {
      title: this.input? this.loc.inputMetadata.edit : this.loc.outputMetadata.edit,
      icon: 'psychology',
      fields: [
        {label: this.loc.tableHeaders.description, 
          value: row.description, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required]
        },
        {label: this.loc.tableHeaders.type, 
          value: row.type, 
          validators: [Validators.required],
          comboValues: this.metadataTypes,
          isCombo: true
        },
        {label: this.loc.tableHeaders.controlType, 
          value: row.controlType, 
          validators: [Validators.required],
          comboValues: this.controlTypes,
          isCombo: true
        },
        {label: this.loc.tableHeaders.defaultValue, 
          value: row.defaultValue, 
        },
        {label: this.loc.tableHeaders.isMandatory, 
          value: row.isMandatory, 
          validators: [Validators.required],
          comboValues: this.boolOptions,
          isCombo: true
        },
        {label: this.loc.tableHeaders.isHidden, 
          value: row.isHidden, 
          validators: [Validators.required],
          comboValues: this.boolOptions,
          isCombo: true
        },
        {label: this.loc.tableHeaders.isEditable, 
          value: row.isEditable, 
          validators: [Validators.required],
          comboValues: this.boolOptions,
          isCombo: true
        },
        {label: this.loc.fields.datasource, 
          value: row.dataSource, 
          validators: [Validators.required],
          comboValues: this.datasource,
          isCombo: true
        },
        {label: this.loc.tableHeaders.validation, 
          value: row.validation, 
        },
        {label: this.loc.fields.datasourceData, 
          value: row.dataSourceData, 
          validators: [Validators.required]
        },
        {label: this.loc.fields.checkout, 
          value: row.checkout, 
          validators: [Validators.required],
          comboValues: this.boolOptions,
          isCombo: true
        },
      ],
      class: 'edit-input-output'
    }
    const dialogRef = this.dialogService.openCustomDialog(
      EditModalComponent,
      data,
      [600,650]
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value;
        let newType = result.find((x: any) => x.label === this.loc.tableHeaders.type).value;
        let newControlType = result.find((x: any) => x.label === this.loc.tableHeaders.controlType).value;
        let newDefValue = result.find((x: any) => x.label === this.loc.tableHeaders.defaultValue).value;
        let newMandatory = result.find((x: any) => x.label === this.loc.tableHeaders.isMandatory).value;
        let newEditable = result.find((x: any) => x.label === this.loc.tableHeaders.isEditable).value;
        let newHidden = result.find((x: any) => x.label === this.loc.tableHeaders.isHidden).value;
        let newDatasource = result.find((x: any) => x.label === this.loc.fields.datasource).value;
        let newDatasourceData: JSON = JSON.parse(result.find((x: any) => x.label === this.loc.fields.datasourceData).value);
        let newValidation = result.find((x: any) => x.label === this.loc.tableHeaders.validation).value;
        let newCheckout = result.find((x: any) => x.label === this.loc.fields.checkout).value;
        
        let newMetadata = {
          metadataId: row.id,
          metadataDescription: newDescription,
          metadataType: newType,
          metadataControlType: newControlType,
          metadataDefaultValue: newDefValue,
          metadataIsMandatory: newMandatory,
          metadataIsEditable: newEditable,
          metadataIsHidden: newHidden,
          metadataDatasource: newDatasource,
          metadataDatasourceData: newDatasourceData,
          metadataValidation: newValidation,
          ordinal: row.ordinal,
          metadataCheckout: newCheckout
        }        

        let indexToUpdate = this.metadatas.findIndex((m) => m.ordinal === row.ordinal);
        if (indexToUpdate !== -1) {
          this.metadatas[indexToUpdate] = newMetadata;
        }
        this.stepsService.updateStepMetadata(this.stepId!, this.metadatas, this.input!).subscribe(
          (res: BaseResponse) => {
            setTimeout(() => {
              if(res.statusRS?.code === "0"){
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('update', this.input? this.loc.inputMetadata : this.loc.outputMetadata)
                );
                this.getData();
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('update', this.input? this.loc.inputMetadata : this.loc.outputMetadata)
                );
              }
              this.loading = false;
            }, 200);
          }, (err) => {
            setTimeout(() => {
            }, 200);
            if (err.statusCode !== 401) {
              this.loading = false;
              console.log(err)
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update',  this.input? this.loc.inputMetadata : this.loc.outputMetadata)
              );
            }
          }
        )

      }
    });
  }

  goToOutputDetails(row: any){
    this.router.navigate(['step-details/output-details'], {
      queryParams: {
        stepId: this.stepId,
        stepName: this.step?.name,
        outputId: row.id,
        apiName: this.apiName,
        apiId: this.apiId,
      },
      queryParamsHandling: 'merge',
    });
  }

  onRowClick = {
    action: (row:StepMetadata) => {this.onRowClicked(row)}
  }
  
  formatDatasource(datasource: string): string {
    return datasource == 'STATIC'? this.loc.common.static : this.loc.common.dynamic
  }
}

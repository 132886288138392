<div style="display: flex; height: 100%;" >
    <div class="sidebar" >
        <app-sidebar></app-sidebar>
    </div>
    <div class="container"  id="container">
        <div class="user-details-wrapper">
            <div class="user-details-menu">
                <app-user-preview></app-user-preview>
            </div>
        </div>
        <div class="content-container" style="width:95%;height: calc(100% - 65px);">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { MetadataField } from 'src/app/models/metadata/metadata-field/metadataField.model';
import { MetadataType } from 'src/app/models/metadata/metadata-type/metadataType.model';
import { StepMetadata } from 'src/app/models/step/step.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { StepsService } from 'src/app/services/steps/steps.service';

@Component({
  selector: 'app-create-input-output-metadata',
  templateUrl: './create-input-output-metadata.component.html',
  styleUrls: ['./create-input-output-metadata.component.css'],
  host: {
    class: 'dialog-component create-meta'
  }
})
export class CreateInputOutputMetadataComponent implements OnInit {
  loading = false;
  isInput = false;
  stepId = '';
  metadata: any[] = [];
  metadataFields: any[] = [];

  controlTypes: string[] = ['TEXT', 'COMBOBOX', 'CHECKBOX', 'SLIDER', 'OTP', 'LIST'];

  metadataTypes: any[] = [];

  selectedMetadataField?: MetadataField;

  isLinear = true;
  firstFormGroup!: UntypedFormGroup;
  isEditable = true;
  datasource: string[] = [this.loc.common.static, this.loc.common.dynamic];
  options: string[] = [this.loc.common.no, this.loc.common.yes];

  datasourceData = '';

  newMetadata: any = {};

  constructor(
    public loc: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public metadataService: MetadataService,
    public stepService: StepsService,
    public snackbarService: SnackbarService,
    public dialogRef: DialogRef,
    private formBuilder: UntypedFormBuilder,

  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      id: ['', Validators.required],
      description: ['', Validators.required],
      type: ['', Validators.required],
      controlType: ['', Validators.required],
      isMandatory: ['', Validators.required],
      isEditable: ['', Validators.required],
      isHidden: ['', Validators.required],
      dataSource: ['', Validators.required],
      validation: [''],
      dataSourceData: [''],
      defaultValue: [''],
      checkout: ['', Validators.required],
    });
    this.isInput = this.data.isInput;
    this.stepId = this.data.metadata.stepId;
    this.metadata = this.isInput ? this.data.metadata.inputMetadata: this.data.metadata.outputMetadata;
    this.setCombos();
  }

  setCombos(): void {
    console.log(JSON.parse("[]"))
    this.loading = true;
    this.metadataService.listMetadatTypes().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.metadataTypes =(res.messageRS!) as unknown as MetadataType[];
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
    this.loading = true;
    this.metadataService.listMetadataFields().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.metadataFields =(res.messageRS!) as unknown as any[];
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
    
    this.firstFormGroup.valueChanges.subscribe((value) => {
      if (value) {
        const currentMetadataField = this.metadataFields.find(item => item.id === value.id);
        if (this.selectedMetadataField !== currentMetadataField) {
          this.selectedMetadataField = currentMetadataField;

          this.firstFormGroup.get('description')?.setValue(this.selectedMetadataField ? this.selectedMetadataField.description : '');
          this.firstFormGroup.get('type')?.setValue(this.selectedMetadataField ? this.metadataTypes.find(item => item.id === this.selectedMetadataField!.type) : '');
          this.firstFormGroup.get('controlType')?.setValue(this.selectedMetadataField ? this.controlTypes.find(item => item === this.selectedMetadataField!.controlType) : '');
          this.firstFormGroup.get('isMandatory')?.setValue( (this.selectedMetadataField?.isMandatory === '0') ? this.options[0] :  this.options[1]);
          this.firstFormGroup.get('isEditable')?.setValue( (this.selectedMetadataField?.isEditable === '0') ? this.options[0] :  this.options[1]);
          this.firstFormGroup.get('isHidden')?.setValue( (this.selectedMetadataField?.isHidden === '0') ? this.options[0] :  this.options[1]);
          this.firstFormGroup.get('dataSource')?.setValue(this.selectedMetadataField?.datasource === 'STATIC' ? this.loc.common.static : this.loc.common.dynamic);
          this.firstFormGroup.get('validation')?.setValue(this.selectedMetadataField ? this.selectedMetadataField.validation : '');
          this.firstFormGroup.get('defaultValue')?.setValue(this.selectedMetadataField ? this.selectedMetadataField.defaultValue : '');
          this.firstFormGroup.get('checkout')?.setValue( (this.selectedMetadataField?.checkout === '0') ? this.options[0] :  this.options[1]);

        }
      }
    });
    

  }


  exit(created: string) {
    this.dialogRef.close(created);
  }

  createMetadata(){
    var datasourceDataAux =  this.findDataSourceData(this.firstFormGroup.get('id')!.value);
    datasourceDataAux = datasourceDataAux ? (JSON.parse(datasourceDataAux)).titles : [];
    let newMetadata: any =  {
      id: this.firstFormGroup.get('id')!.value,
      description: this.firstFormGroup.get('description')!.value,
      type: this.firstFormGroup.get('type')!.value.id,
      controlType:  this.firstFormGroup.get('controlType')!.value,
      defaultValue:  this.firstFormGroup.get('defaultValue')!.value,
      isMandatory:  (this.firstFormGroup.get('isMandatory')!.value === this.loc.common.no)? '0' : '1',
      isEditable:  (this.firstFormGroup.get('isEditable')!.value === this.loc.common.no)? '0' : '1',
      isHidden:  (this.firstFormGroup.get('isHidden')!.value === this.loc.common.no)? '0' : '1',
      dataSource: ((this.firstFormGroup.get('dataSource')!.value === this.loc.common.static || this.firstFormGroup.get('dataSource')!.value === 'STATIC') ? 'STATIC' : 'DYNAMIC'),
      dataSourceData: datasourceDataAux,
      validation:  this.firstFormGroup.get('validation')!.value,
      ordinal: this.metadata.length+1,
      checkout:  (this.firstFormGroup.get('checkout')!.value === this.loc.common.no)? '0' : '1',

    }
    this.metadata.push(newMetadata)
    
    const newArray = this.metadata.map(item => ({
      metadataId: item.id,
      metadataDescription: item.description,
      metadataType: item.type,
      metadataControlType: item.controlType,
      metadataDefaultValue: item.defaultValue,
      metadataIsMandatory: item.isMandatory,
      metadataIsEditable: item.isEditable,
      metadataIsHidden: item.isHidden,
      metadataDataSource: item.dataSource,
      metadataDataSourceData:  item.dataSourceData,
      metadataValidation: item.validation,
      ordinal: item.ordinal+'',
      metadataCheckout: item.checkout
    }));
    
    this.stepService.updateStepMetadata(this.stepId, newArray, this.isInput).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('create', this.isInput? this.loc.inputMetadata : this.loc.outputMetadata, 'f')
            );
            this.exit('created');
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('create', this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
            );
            this.exit('error');
          }
          this.loading = false;
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (err.statusCode !== 401) {
          this.loading = false;
          console.log(err)
          this.exit('error');
        }
      }
    )
  }

  next(){
    this.newMetadata = {
      id: this.firstFormGroup.get('id')!.value,
      description: this.firstFormGroup.get('description')!.value,
      type: this.firstFormGroup.get('type')!.value.id,
      controlType:  this.firstFormGroup.get('controlType')!.value,
      defaultValue:  this.firstFormGroup.get('defaultValue')!.value,

      isMandatory:  (this.firstFormGroup.get('isMandatory')!.value === this.loc.common.no)? '0' : '1',
      isEditable:  (this.firstFormGroup.get('isEditable')!.value === this.loc.common.no)? '0' : '1',
      isHidden:  (this.firstFormGroup.get('isHidden')!.value === this.loc.common.no)? '0' : '1',

      dataSource: ((this.firstFormGroup.get('dataSource')!.value === this.loc.common.static || this.firstFormGroup.get('dataSource')!.value === 'STATIC') ? 'STATIC' : 'DYNAMIC'),
      // dataSourceData: this.firstFormGroup.get('dataSourceData')!.value,
      validation:  this.firstFormGroup.get('validation')!.value,
      ordinal: this.metadata.length+1,
      checkout:  (this.firstFormGroup.get('checkout')!.value === this.loc.common.no)? '0' : '1',

    }
    // let a = JSON.parse(this.newMetadata?.dataSourceData || "[]")
    // this.datasourceData = JSON.stringify(a, null, 2);
  }

  findDataSourceData(metadataId: string): string {
    return (this.metadataFields.find(x => x.id === metadataId)).dataSourceData;
  }
}

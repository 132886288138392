import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CreateBankComponent } from 'src/app/banks/create-bank/create-bank/create-bank.component';
import { NonWorkingDay } from 'src/app/models/calendar/nonWorkingDay.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { NonWorkingDaysService } from 'src/app/services/nonWorkinDays/non-working-days.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-non-working-day',
  templateUrl: './create-non-working-day.component.html',
  styleUrls: ['./create-non-working-day.component.css'],
  host: {
    class: 'dialog-component',
  },
})
export class CreateNonWorkingDayComponent implements OnInit {
  loading = true;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  actions: any[] = [];
  stageId = '';
  ids = [];

  constructor(
    public dialogRef: MatDialogRef<CreateBankComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public calendarId: string,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private nonWorkingDaysService: NonWorkingDaysService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      description: ['', Validators.required],
      dayNumber: ['', Validators.required],
      monthId: ['', Validators.required],
    });
    this.loading = false;
  }
  create() {
    this.loading = true;

    let nonWorkingDay: NonWorkingDay = {
      calendarId: this.calendarId,
      monthId: this.firstFormGroup.get('monthId')!.value.value,
      dayNumber: this.firstFormGroup.get('dayNumber')!.value,
      description: this.firstFormGroup.get('description')!.value,
    };

    this.nonWorkingDaysService.create(nonWorkingDay).subscribe({
      next: (res: BaseResponse<NonWorkingDay>) => {
        this.loading = false;
        switch (res.statusRS?.code) {
          case '0':
            this.exit('created');
            break;
          case '-91767':
            this.exit('duplicate');
            break;
          default:
            this.exit('error');
            break;
        }
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      },
    });
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }
  emptyField(text: string | undefined): string {
    if (text === '' || text === undefined) return '-';
    return text;
  }
}

  <div class="title">
    <div class="inner-title-container">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <span class="material-icons">
          code
        </span>
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon-edit">mode_edit</mat-icon>
      </button>
      <p *ngIf="!title" class="dialog-title" i18n>{{loc.apis.showBody}}</p>
      <p *ngIf="title" class="dialog-title" i18n>{{title}}</p>

    </div>
    <button class="exit-button" (click)="exit('')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
  
  
  <div *ngIf="!editable" class="form-step">
    <mat-form-field style="width: 450px;">
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="25"
        cdkAutosizeMaxRows="25" style="font-size: 12px;"  readonly="true">{{body}}</textarea>
    </mat-form-field>
  </div>

  <div *ngIf="editable" class="form-step">
    <mat-form-field style="width: 450px;">
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="25"
        cdkAutosizeMaxRows="25" style="font-size: 12px;"  [(ngModel)]="body">{{body}}</textarea>
    </mat-form-field>
  </div>
  
  <div *ngIf="editable" class="confirm-buttons">
    <button mat-fab class="button green-color" (click)="save()">
      <mat-icon fontSet="material-icons-outlined">save</mat-icon>
    </button>
  </div>
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AccountIds } from 'src/app/models/accountIds/account-ids.model';
import { AdjustmentReasons } from 'src/app/models/adjustmentReasons/adjustmentReasons.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { AdjustmentReasonsService } from 'src/app/services/adjustmentReasons/adjustment-reasons.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-adjustment-reason',
  templateUrl: './create-adjustment-reason.component.html',
  styleUrls: ['./create-adjustment-reason.component.css'],
  host: { class: 'dialog-component' },
})
export class CreateAdjustmentReasonComponent implements OnInit {
  loading = true;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  actions: any[] = [];
  ids = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdjustmentReasons[],
    public dialogRef: MatDialogRef<AdjustmentReasons>,
    public dialog: MatDialog,
    public loc: LocalizationService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    private adjustmentReasonsService: AdjustmentReasonsService,
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      description: ['', Validators.required],
    });
    this.loading = false;
  }
  create() {
    this.loading = true;
    let description = this.firstFormGroup.get('description')!.value;

    this.adjustmentReasonsService.create(description).subscribe({
      next: (res: BaseResponse<AdjustmentReasons>) => {
        this.loading = false;
        switch (res.statusRS?.code) {
          case '0':
            this.exit('created');
            break;
          case '-91609':
            this.exit('duplicate');
            break;
          default:
            this.exit('error');
            break;
        }
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      },
    });
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }
  emptyField(text: string | undefined): string {
    if (text === '' || text === undefined) return '-';
    return text;
  }
}

import { Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AgentType } from 'src/app/models/agents/agent-types.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { UsersService } from 'src/app/services/users/users.service';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Action, Column, EmptyState, PaginatorInfo, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agent-types-list',
  templateUrl: './agent-types-list.component.html',
  styleUrls: ['./agent-types-list.component.css'],
  host: { class: 'component' },
})
export class AgentTypesListComponent implements OnInit {
  loading: boolean = true;
  data : TableData[] = [];
  constructor(
    public loc: LocalizationService,
    private userService: UsersService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private router: Router,

  ) {}

  ngOnInit(): void {
    this.loadAgentTypes();
  }

  loadAgentTypes() {
    this.loading = true;
    this.userService.getAgentTypes().subscribe({
      next: (data: BaseResponse<AgentType[]>) => {
        this.data = data.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (_err: any) => {
        console.log(_err);
      },
    });
  }

  editAgentTypeAction(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value

    this.userService.editAgentType(row.id, newDescription).subscribe({
      next: (res) => {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.agentTypes)
        );
        this.loadAgentTypes();
      },
      error: (err) => {
        this.loading = false;
        console.log(err);
        if (err.status !== 401) {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.agentTypes)
          );
        }
      }
    })
  }

  editAgentType(row: AgentType) {
    let data: EditModalData = {
      title: this.loc.agentTypes.edit,
      icon: 'domain',
      fields: [
        {label: this.loc.tableHeaders.description, 
          value: row.description, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required]}]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAgentTypeAction(row, result)
      }
    });
  }

  actions : Action[] = [
    {
      icon: 'more_horiz',
      tooltip: this.loc.common.details,
      action: (row: AgentType) => {
        this.details(row);
      },
    },
    {
      tooltip: this.loc.agentTypes.edit,
      icon: 'edit',
      action: (row: AgentType) => {this.editAgentType(row)}
    }
  ]

 


  emptyState : EmptyState = {
    image: 'assets/img/tipos-agentes.png',
    message: this.loc.agentTypes.empty,
    buttons: []
  }

  columns : Column[] = [
    {
      key: 'id',
      title: this.loc.tableHeaders.id,
      sortable: true,
      bold: true,
    },
    {
      key: 'description',
      title: this.loc.tableHeaders.description,
      sortable: true,
    }
  ]

  transformers = {};

  paginatorInfo: PaginatorInfo = {
    singular: this.loc.agentTypes.singular,
    plural: this.loc.agentTypes.plural
  }

  filters : TableFilter[] = [
    {
      key: 'id',
      label: this.loc.tableHeaders.id,
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      key: 'description',
      label: this.loc.tableHeaders.description,
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    }
  ]

  details(row: any): void {
    this.router.navigate(['agent/details'], {
      queryParams: {
        id: row.id,
        description: row.description
      },
    });
  }

  onRowClick = {
    action: (row:AgentType) => {this.details(row)}
  }
  
}

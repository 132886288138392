import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { PaymentButtonTypes } from 'src/app/models/payment-buttons/paymentButtonTypes.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { PaymentButtonService } from 'src/app/services/payment-button/payment-button.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreatePaymentButtonTypeComponent } from './create-payment-button-type/create-payment-button-type.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';


@Component({
  selector: 'app-payment-button-types',
  templateUrl: './payment-button-types.component.html',
  styleUrls: ['./payment-button-types.component.css'],
  host: {
    class: 'component',
  },
})
export class PaymentButtonTypesComponent implements OnInit {
  loading: boolean = true;
  constructor(
    public loc: LocalizationService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private paymentButtonService: PaymentButtonService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loadTypes();
  }

  data : TableData[] = []


  loadTypes() {
    this.loading = true;
    this.paymentButtonService.getTypes().subscribe({
      next: (data: BaseResponse<PaymentButtonTypes[]>) => {
        this.data = data.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (_err: any) => {},
    });
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-payment-button.png',
    message: this.loc.paymentButtonType.empty,
    buttons: [
      {
        text: this.loc.paymentButtonType.create,
        action: () => { 
          this.create()
        }
      }
    ]
  }

  columns : Column[] = [
    {
      key: "id",
      title: this.loc.tableHeaders.id,
    },
    {
      key: "description",
      title: this.loc.tableHeaders.description,
    }
  ]


    paginatorInfo = this.loc.paymentButtonType

    filters: TableFilter[] = [
      {
        key: "id",
        label: this.loc.tableHeaders.id,
        type: "text",
        placeholder: this.loc.tableHeaders.id,
      },
      {
        key: "description",
        label: this.loc.tableHeaders.description,
        type: "text",
        placeholder: this.loc.tableHeaders.description,
      }
    ]


    actions : Action[]=[
      {
        icon: 'more_horiz',
        action: (row: PaymentButtonTypes) => {
          this.details(row)
        },
        tooltip: this.loc.common.details
      },
      {
        icon: "edit",
        action: (row: any) => {
          this.edit(row);
        },
        tooltip: this.loc.paymentButtonType.edit,
      },
      {
        icon: "delete",
        action: (row: any) => {
          this.delete(row);
        },
        tooltip: this.loc.paymentButtonType.delete,
      }
    ]

    createButton: CreateButton = {
      tooltip: this.loc.paymentButtonType.create,
      action: () => {
        this.create();
      }
    }

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreatePaymentButtonTypeComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage(
            'create',
            this.loc.paymentButtonType,
          )
        );
        this.loadTypes();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.paymentButtonType)
        );
        this.loadTypes();
      }
      if (result === 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.paymentButtonTypeAlreadyExists
        );
      }
    });
  }

  edit(row: any) {
    let data: EditModalData = {
      title: this.loc.paymentButtonType.edit,
      icon: 'smart_button',
      fields: [
        { label: this.loc.fields.description, 
          value: row.description, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAction(row, result)
      }
    });
  }

  editAction(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value

    this.paymentButtonService.editType(row.id, newDescription).subscribe({
      next: (_response: BaseResponse<PaymentButtonTypes>) => {
        this.loading = false;
        if(_response.statusRS.code === '0'){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage(
              'update',
              this.loc.paymentButtonType,
            )
          );
          this.loadTypes();
        }else{
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.paymentButtonType)
          );
        }
      },
      error: (_error: any) => {
        this.loading = false;
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.paymentButtonType)
        );
      },
    });
  }

  delete(row: any) {
    let data: DeleteModalData = {
      title: this.loc.paymentButtonType.delete,
      icon: 'smart_button',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.paymentButtonType),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.paymentButtonService.deleteType(row.id).subscribe({
          next: (res: BaseResponse<any>) => {
            this.loading = true;
            switch (res.statusRS?.code) {
              case '0':
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage(
                    'delete',
                    this.loc.paymentButtonType,
                  )
                );
                this.loadTypes();
                break;
              case '-91787':
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.paymentButtonTypeHasRelationships
                );
                this.loadTypes();
                break;
              default:
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.paymentButtonType)
                );
                break;
            }
            this.loading = false;
          },
          error: (_err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.paymentButtonType)
            );
          },
        });
      }
    });
  }

  onRowClick =  {
    action: (row: any) => {
      this.details(row)
    }
  }

  details(row: any){
    this.router.navigate(['payment-button-details'], {
      queryParams: {
        buttonType: row.id,
        buttonTypeDesc: row.description,
      },
      queryParamsHandling: 'merge',
    });
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Disaggregation } from 'src/app/models/posting-schemes/Disaggregation.model';
import { Posting } from 'src/app/models/posting-schemes/Posting.model';

import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateDisaggregationComponent } from './create-disaggregation/create-disaggregation.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';

@Component({
  selector: 'app-disaggregation',
  templateUrl: './disaggregation.component.html',
  styleUrls: ['./disaggregation.component.css'],
  host: {
    class: 'component'
  }
})
export class DisaggregationComponent implements OnInit {

  constructor(
    private postingSchemesService: PostingSchemesService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    private loc : LocalizationService
  ) {}

  loading = false;
  data: TableData[] = [];

  columns: Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
    }
  ]

  actions: Action[] = [
    {
      icon: 'more_horiz',
      action: (row: Posting) => {
        this.toDetails(row);
      },
      tooltip: this.loc.common.details,
    },
    {
      icon: 'edit',
      action: (element: Disaggregation) => this.edit(element),
      tooltip: this.loc.disaggregation.edit
    },
    {
      icon: 'delete',
      action: (element: Disaggregation) => this.delete(element),
      tooltip: this.loc.disaggregation.delete
    }
  ]

  createButton: CreateButton = {
    tooltip: this.loc.disaggregation.create,
    action: () => this.create()
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.disaggregation.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create()
      }
    ]
  }

  paginatorInfo = this.loc.disaggregation

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    }
  ]


  ngOnInit(): void {
    this.loadDisaggregations();
  }

  loadDisaggregations(): void {
    this.loading = true;
    this.postingSchemesService
      .getDisaggregations()
      .subscribe(
        (res: BaseResponse) => {
          this.data = res.messageRS as any as TableData[];
          this.loading = false;
        },
        (err) => {
          this.logoutDialogService.openLogoutDialog(err);
        }
      );
  }


  toDetails(element: Disaggregation): void {
        this.router.navigate(['disaggregation-details'], {
          queryParams: {
            id: element.id,
            description: element.description
          },
          queryParamsHandling: 'merge',
        });
  }


  create(){
      const dialogRef = this.dialogService.openDialog(CreateDisaggregationComponent,
        {},
        "medium");

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'created') {
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage("create",this.loc.disaggregation, "f")
            );
            this.loadDisaggregations();
          } else if (result === '-91633') {
            this.snackbarService.openErrorSnackbar(this.loc.errors.disaggregationAlreadyExists);
          } else if (result === '-91632') {
            this.snackbarService.openErrorSnackbar(this.loc.genericError("create", this.loc.disaggregation));
          }
        });
  }

  edit(element: Disaggregation): void {
    let data: EditModalData = {
      title: this.loc.disaggregation.edit,
      icon: 'signpost',
      fields: [
        { label: this.loc.tableHeaders.description,
          value: element.description!!,
          errorMsg: this.loc.errors.emptyDescription,
          validators: [Validators.required],
        }]
    }
      const dialogRef = this.dialogService.openDialog(EditModalComponent,
        data
        ,
        "x-small");

      dialogRef.afterClosed().subscribe((result) => {
        if (result !== 'not updated' && result) {
          this.loading = true;
          this.editAction(element, result)
        }
      });
  }

  editAction(row: any, result: any){
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
    this.postingSchemesService.editDisaggregation(row.id!, newDescription).subscribe({
      next: (res: BaseResponse) => {
        this.loading = false;
        if(res.statusRS?.code === '0'){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage("update",this.loc.disaggregation, "f")
          );
          this.loadDisaggregations();
        }else{
          this.snackbarService.openErrorSnackbar(this.loc.genericError("update", this.loc.disaggregation));
        }
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
      }
    })
  }

  delete(element: Disaggregation): void {
    let dataModal : DeleteModalData = {
      title: this.loc.disaggregation.delete,
      icon: 'signpost',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.disaggregation)
    }
    const dialogRef = this.dialogService.openDialog(DeleteModalComponent,
      dataModal,
      "x-small");

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'delete') {

            this.postingSchemesService.deleteDisaggregation(element.id!)
            .subscribe(
             (res: BaseResponse) => {
                  if(res.statusRS?.code === "0"){

                    this.snackbarService.openSuccessSnackbarWithNoReload(
                     this.loc.successMessage("delete",this.loc.disaggregation, "f")
                   );
                  } else if(res.statusRS?.code === "-91691"){
                   this.snackbarService.openErrorSnackbar(this.loc.errors.disaggregationUsed)
                  } else if(res.statusRS?.code === "-91837"){
                    this.snackbarService.openErrorSnackbar(this.loc.errors.disaggregationUsedByPostingScheme)
                   } else {
                   this.snackbarService.openErrorSnackbar(this.loc.genericError("delete", this.loc.disaggregation))
                  }


                 this.loadDisaggregations();
             }, (err) => {
               this.logoutDialogService.openLogoutDialog(err);
             }
            )

        }
  })
}


}

<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
</div>

<div class="title">
    <div class="inner-title-container">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">notifications</mat-icon>
            <mat-icon class="action-button-icon-add">add</mat-icon>
        </button>
        <label class="dialog-title" i18n>{{loc.types.create}}</label>
    </div>
    <button class="exit-button" (click)="exit('not created')">
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>
    <mat-horizontal-stepper class="horizontal-stepper types-stepper" [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
        [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex">
        <ng-template matStepperIcon="done" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">settings</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 2">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="edit" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">settings</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 2">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="number" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">settings</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 2">check_box</mat-icon>
        </ng-template>
        <mat-step [stepControl]="firstFormGroup" [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
            <form [formGroup]="firstFormGroup" class="form-step">
                <div class="data-box">
                    <div class="create-row">
                        <mat-form-field style="width: 100%;" appearance="fill"
                        [matTooltip]="loc.errors.applicationIdFormat"
                        matTooltipPosition="above" 
                        matTooltipClass="error-tooltip" 
                        [matTooltipDisabled]="!firstFormGroup.get('id')?.hasError('pattern')">
                            <mat-label i18n>{{loc.fields.id}}</mat-label>
                            <input matInput name="id" formControlName="id" required>
                        </mat-form-field>
                        <mat-form-field style="width: 100%;" appearance="fill">
                            <mat-label i18n>{{loc.fields.description}}</mat-label>
                            <input matInput name="description" formControlName="description" required>
                        </mat-form-field>
                    </div>
                    <div class="create-row-align-start">
                        <mat-form-field appearance="fill">
                            <mat-label i18n>{{loc.fields.status}}</mat-label>
                            <mat-select name="status" formControlName="status" required>
                              <mat-option [value]="true">{{loc.common.active}}</mat-option>
                              <mat-option [value]="false">{{loc.common.inactive}}</mat-option>
                            </mat-select>
                         </mat-form-field>
                    </div>
                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperNext [disabled]="!isFormValid()">
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.configuration}}</ng-template>
            <form [formGroup]="secondFormGroup" class="form-step">
                <div class="data-box">
                    <div class="create-row">
                        <mat-form-field appearance="fill" >
                            <mat-label i18n>{{loc.tabNames.applications}}</mat-label>
                            <mat-select name="application" formControlName="application" required>
                              <mat-option *ngFor="let application of applications" [value]="application">{{application.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" >
                            <mat-label i18n>{{loc.tabNames.platforms}}</mat-label>
                            <mat-select name="platform" formControlName="platform" required>
                              <mat-option *ngFor="let platform of platforms" [value]="platform">{{platform.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="create-row">
                        <mat-form-field appearance="fill" >
                            <mat-label i18n>{{loc.tabNames.entity}}</mat-label>
                            <mat-select name="entity" formControlName="entity" required>
                              <mat-option *ngFor="let entity of entities" [value]="entity">{{entity.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" >
                            <mat-label i18n>{{loc.tabNames.methods}}</mat-label>
                            <mat-select name="method" formControlName="method" required>
                              <mat-option *ngFor="let method of methods" [value]="method">{{method.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious  >
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-fab class="button green-button" matStepperNext [disabled]="!isFormValid()">
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>
        <mat-step [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
            <div class="form-step">
                <div class="verify-large">
                    <div class="flex-container-vertical flex-container-vertical-modal">
                        <div>
                            <label class="card-name" i18n>{{loc.types.new}}</label>
                        </div>
                        <div class="details-package-large details-flex">
                            <div class="verify-vertical">
                                <div class="card-header" i18n>{{loc.fields.id}}</div>
                                <div class="card-value">{{firstFormGroup.get('id')?.value}}</div>
                            </div>
                            <div class="verify-vertical">
                                <div class="card-header" i18n>{{loc.fields.description}}</div>
                                <div class="card-value">{{firstFormGroup.get('description')?.value}}</div>
                            </div>
                            <div class="verify-vertical">
                                <div class="card-header" i18n>{{loc.fields.status}}</div>
                                <div class="card-value">{{firstFormGroup.get('status')?.value ? 'Activo' : 'Inactivo'}}</div>
                            </div>
                        </div>
                        <div>
                            <label class="card-name" i18n>{{loc.stepTitle.configuration | titlecase}}</label>
                        </div>
                        <div class="details-package-large details-flex">
                            <div class="verify-vertical">
                                <div class="card-header" i18n>{{loc.tabNames.applications}}</div>
                                <div class="card-value">{{secondFormGroup.get('application')?.value.description}}</div>
                            </div>
                            <div class="verify-vertical">
                                <div class="card-header" i18n>{{loc.tabNames.platforms}}</div>
                                <div class="card-value">{{secondFormGroup.get('platform')?.value.description}}</div>
                            </div>
                            <div class="verify-vertical">
                                <div class="card-header" i18n>{{loc.tabNames.entity}}</div>
                                <div class="card-value">{{secondFormGroup.get('entity')?.value.description}}</div>
                            </div>
                            <div class="verify-vertical">
                                <div class="card-header" i18n>{{loc.tabNames.methods}}</div>
                                <div class="card-value">{{secondFormGroup.get('method')?.value.description}}</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious  >
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-fab class="button green-button" (click)="create()"  >
                        <mat-icon class="stepper-item-icon" fontSet="material-icons-outlined">save</mat-icon>
                    </button>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalizationService } from '../services/localization/localization.service';
import { SessionsService } from '../services/sessions/sessions.service';
import { TokenService } from '../services/token/token.service';

@Component({
  selector: 'app-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.css'],
  host:{
    "class":"confirmation-dialog-component"
  }
})
export class LogoutConfirmationComponent implements OnInit {
  expired: boolean = false;
  constructor(
    private sessionsService: SessionsService,
    private tokenService: TokenService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.expired = this.data.exp;
  }

  logout() {
    this.sessionsService.logout().subscribe(
      (res) => {
        this.tokenService.removeToken();
        this.tokenService.removeRefreshToken();
        this.exit();
        this.router.navigate(['login']);
      },
      (err) => {
        this.tokenService.removeToken();
        this.tokenService.removeRefreshToken();
        this.exit();
        this.router.navigate(['login']);
      }
    );
  }

  exit() {
    this.dialog.closeAll();
  }
}

<div class="delete-body">
<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
  </div>
  
  <div class="confirm-title">
    <div class="confirm-title-inner-div">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon">{{data.icon}}</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon-delete">delete</mat-icon>
      </button>
      <div class="dialog-title"><label i18n>{{data.title}}</label></div>
    </div>
  
    <button class="exit-button" (click)="exit('not deleted')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
  
  
  <div class="delete-paragraph">
      <ng-container><label i18n>{{data.confirmDeleteMessage}}</label></ng-container>
  </div>
  
  <div class="confirm-buttons">
      <button mat-fab class="button confirm-delete-button" (click)="delete()" i18n-matTooltip >
        <mat-icon matSuffix fontSet="material-icons-outlined" style="font-size: 24px; color: white;">delete</mat-icon>
      </button>
  </div>
</div>
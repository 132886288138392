import { Component, OnInit } from '@angular/core';
import { AdjustmentReasons } from 'src/app/models/adjustmentReasons/adjustmentReasons.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { AdjustmentReasonsService } from 'src/app/services/adjustmentReasons/adjustment-reasons.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateAdjustmentReasonComponent } from './create-adjustment-reason/create-adjustment-reason.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';

@Component({
  selector: 'app-adjustment-reasons',
  templateUrl: './adjustment-reasons.component.html',
  styleUrls: ['./adjustment-reasons.component.css'],
  host: {
    class: 'component',
  },
})
export class AdjustmentReasonsComponent implements OnInit {
  loading: boolean = true;
  data: TableData[] = [];
  constructor(
    public loc: LocalizationService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private adjustmentReasonsService: AdjustmentReasonsService
  ) {}


  ngOnInit(): void {
    this.loadAdjustmentReasons();
  }


  columns: Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
    }
  ]

  actions: Action[] = [
    {
      icon: 'edit',
      action: (element: AdjustmentReasons) => this.edit(element),
      tooltip: this.loc.adjustmentReasons.edit
    },
    {
      icon: 'delete',
      action: (element: AdjustmentReasons) => this.delete(element),
      tooltip: this.loc.adjustmentReasons.delete
    }
  ]

  createButton: CreateButton = {
    tooltip: this.loc.adjustmentReasons.create,
    action: () => this.create()
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.adjustmentReasons.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create()
      }
    ]
  }

  paginatorInfo = this.loc.adjustmentReasons

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    }
  ]

  loadAdjustmentReasons() {
    this.loading = true;
    this.adjustmentReasonsService.getAdjustmentReasons().subscribe({
      next: (data: BaseResponse<AdjustmentReasons[]>) => {
        this.data = data.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (_err: any) => {},
    });
  }

  // CRUD OPERATIONS

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateAdjustmentReasonComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage(
            'create',
            this.loc.adjustmentReasons,
            'femenine'
          )
        );
        this.loadAdjustmentReasons();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.adjustmentReasons)
        );
        this.loadAdjustmentReasons();
      }
      if (result === 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.accountIdAlreadyExists
        );
      }
    });
  }

  edit(row: AdjustmentReasons) {
    let data: EditModalData = {
      title: this.loc.adjustmentReasons.edit,
      icon: 'signpost',
      fields: [
        { label: this.loc.tableHeaders.description,
          value: row.description!!,
          errorMsg: this.loc.errors.emptyDescription,
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if(result && result !== 'not updated'){
        this.loading = true;
        this.editAccountId(row, result)
      }
    });
  }

  editAccountId(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
    this.adjustmentReasonsService.edit(row.id, newDescription).subscribe({
      next: (response: BaseResponse<AdjustmentReasons>) => {
        this.loading = false;
        switch (response.statusRS.code) {
          case '0':
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage(
                'update',
                this.loc.adjustmentReasons,
                'femenine'
              )
            );
            this.loadAdjustmentReasons();
            break;
          case '-91789':
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.adjustmentReasonAlreadyExists
            );
            break;
          default:
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.adjustmentReasons)
            );
            break;
        }
      },
      error: (_error: any) => {
        this.loading = false;
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.adjustmentReasons)
        );
      },
    });
  }

  delete(row: AdjustmentReasons) {
    let data: DeleteModalData = {
      title: this.loc.adjustmentReasons.delete,
      icon: 'list_alt',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.adjustmentReasons),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.adjustmentReasonsService.delete(row.id!).subscribe({
          next: (res: BaseResponse<AdjustmentReasons>) => {
            this.loading = true;
            switch (res.statusRS?.code) {
              case '0':
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage(
                    'delete',
                    this.loc.adjustmentReasons,
                    'femenine'
                  )
                );
                this.loadAdjustmentReasons();
                break;
              default:
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.adjustmentReasons)
                );
                this.loading = false;
                break;
            }
          },
          error: (err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.adjustmentReasons)
            );
          },
        });
      }
    });
  }

  // FILTERS
}

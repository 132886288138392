import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, startWith, map } from 'rxjs';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { NonWorkingDay } from 'src/app/models/calendar/nonWorkingDay.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { NonWorkingDaysService } from 'src/app/services/nonWorkinDays/non-working-days.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateNonWorkingDayComponent } from './create-non-working-day/create-non-working-day.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';

@Component({
  selector: 'app-non-working-days',
  templateUrl: './non-working-days.component.html',
  styleUrls: ['./non-working-days.component.css'],
  host: {
    class: 'component',
  },
})
export class NonWorkingDaysComponent implements OnInit {
  loading: boolean = true;
  nonWorkingDaysDataSource = new MatTableDataSource<NonWorkingDay>([]);

  myControl = new UntypedFormControl();
  myControlDayNumber = new UntypedFormControl();
  myControlDescription = new UntypedFormControl();

  filters: any = {
    monthName:
      JSON.parse(localStorage.getItem('state')!)?.nonWorkingDay?.monthName
        ?.fieldFk || '',
    dayNumber:
      JSON.parse(localStorage.getItem('state')!)?.nonWorkingDay?.dayNumber
        ?.fieldFk || '',
    description:
      JSON.parse(localStorage.getItem('state')!)?.nonWorkingDay?.description
        ?.fieldFk || '',
  };

  filteredNames: Observable<string[]> = new Observable<string[]>();
  filteredNumbers: Observable<string[]> = new Observable<string[]>();
  filteredDescriptions: Observable<string[]> = new Observable<string[]>();

  monthName: string[] = [];
  dayNumber: string[] = [];
  descrition: string[] = [];

  displayedColumns: string[] = [
    'monthName',
    'dayNumber',
    'description',
    'status',
    'actions',
  ];
  loadingDataSource = new MatTableDataSource<string>();
  pageIndex: number = 0;
  pageSize: number = 5;
  @Input() tableStyle = 'tableResponsive';
  @Input() calendarId!: string;

  @ViewChild(MatPaginator, { static: false }) set paginator(
    value: MatPaginator
  ) {
    this.loc.translateMatPaginator(value);
    if (this.nonWorkingDaysDataSource) {
      this.nonWorkingDaysDataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false }) set sort(value: MatSort) {
    if (this.nonWorkingDaysDataSource) {
      this.nonWorkingDaysDataSource.sort = value;
    }
  }

  constructor(
    public loc: LocalizationService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private nonWorkingDaysService: NonWorkingDaysService
  ) {}

  ngOnInit(): void {
    this.loadingDataSource.data = ['', '', '', '', ''];
    let state = JSON.parse(localStorage.getItem('state')!);
    this.pageIndex = state?.nonWorkingDay?.pageIndex || 0;
    this.pageSize = state?.nonWorkingDay?.pageSize || 5;
    this.myControl.setValue(state?.nonWorkingDay?.monthName || '');
    this.filters['monthName'] = state?.nonWorkingDay?.monthName || '';
    this.myControlDayNumber.setValue(state?.nonWorkingDay?.dayNumber || '');
    this.filters['dayNumber'] = state?.nonWorkingDay?.dayNumber || '';
    this.myControlDescription.setValue(state?.nonWorkingDay?.description || '');
    this.filters['description'] = state?.nonWorkingDay?.description || '';
    this.nonWorkingDaysDataSource.filter = JSON.stringify(this.filters);
    this.loadTypeFieldsId();
  }

  applyFilters(data: BaseResponse<NonWorkingDay[]>) {
    this.filteredNames = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterMonthName(value)),
      map((value) => {
        return value.filter((v, i, a) => a.indexOf(v) === i);
      }),
      map((value) => {
        return value.sort((a, b) => {
          return a.localeCompare(b);
        });
      })
    );
    this.monthName = data.messageRS.map((field) => field.monthName!);

    this.filteredDescriptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterDescription(value)),
      map((value) => {
        return value.filter((v, i, a) => a.indexOf(v) === i);
      }),
      map((value) => {
        return value.sort((a, b) => {
          return a.localeCompare(b);
        });
      })
    );
    this.descrition = data.messageRS.map((field) => field.description);

    this.filteredNumbers = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterDayNumber(value)),
      map((value) => {
        return value.filter((v, i, a) => a.indexOf(v) === i);
      }),
      map((value) => {
        return value.sort((a:any, b:any) => {
          return a - b;
        });
      })
    );
    this.dayNumber = data.messageRS.map((field) => field.dayNumber);
  }

  getMonthName(monthName: string): string {
    return (this.loc.months as any)[monthName.toLocaleLowerCase()];
  }

  loadTypeFieldsId() {
    this.loading = true;
    this.nonWorkingDaysService.get(this.calendarId).subscribe({
      next: (data: BaseResponse<NonWorkingDay[]>) => {
        if (data.statusRS.code === '-91769') {
          data.messageRS = [];
        }
        this.nonWorkingDaysDataSource.data = data.messageRS;
        //This is for the filter to work in spanish as well
        this.nonWorkingDaysDataSource.data.map((element) => {
          return (element.monthName = this.getMonthName(element.monthName!));
        });
        this.applyFilters(data);
        this.setSortAndPaginator();
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
      },
    });
    this.setSortAndPaginator();
  }

  // CRUD OPERATIONS

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateNonWorkingDayComponent,
      this.calendarId,
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage(
            'create',
            this.loc.nonWorkingDays,
            'femenine'
          )
        );
        this.loadTypeFieldsId();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.nonWorkingDays)
        );
        this.loadTypeFieldsId();
      }
      if (result === 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.nonWorkingDayAlreadyExist
        );
      }
    });
  }

  edit(row: any) {
    let data: EditModalData = {
      title: this.loc.calendars.edit,
      icon: 'calendar_month',
      fields: [
        { label: this.loc.fields.description, 
          value: row.description, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        },
        { label: this.loc.fields.status, 
          value: row.status, 
          isCombo: true, 
          comboValues: [{key: "1", value: this.loc.common.active},{key: "0", value: this.loc.common.inactive}]
        }]
    }
    
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAction(row, result)
      }
    });
  }

  editAction(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value
    let newStatus = result.find((x: any) => x.label === this.loc.fields.status).value 

    this.nonWorkingDaysService
      .editDescription(
        row.calendarId,
        row.monthId,
        row.dayNumber,
        newDescription
      )
      .subscribe({
        next: (_response: BaseResponse<{}>) => {
          this.nonWorkingDaysService
            .editStatus(
              row.calendarId,
              row.monthId,
              row.dayNumber,
              newStatus
            )
            .subscribe({
              next: (_response: BaseResponse<{}>) => {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage(
                    'update',
                    this.loc.nonWorkingDays)
                );
                this.loadTypeFieldsId();
              },
              error: (_error: any) => {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('update', this.loc.paymentButtonTypeFields)
                );
              },
            });
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage(
                'update',
                this.loc.paymentButtonTypeFields)
            );
            this.loadTypeFieldsId();
        },
        error: (_error: any) => {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.paymentButtonTypeFields)
          );
        },
      });
  }

  delete(row: any) {
    let data: DeleteModalData = {
      title: this.loc.nonWorkingDays.delete,
      icon: 'calendar_month',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.nonWorkingDays),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.nonWorkingDaysService
          .delete(this.calendarId, row.monthId, row.dayNumber)
          .subscribe({
            next: (res: BaseResponse<any>) => {
              this.loading = true;
              switch (res.statusRS?.code) {
                case '0':
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage(
                      'delete',
                      this.loc.nonWorkingDays,
                      'm'
                    )
                  );
                  this.loadTypeFieldsId();
                  break;
                default:
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError('delete', this.loc.nonWorkingDays)
                  );
                  break;
              }
              this.loading = false;
            },
            error: (err: any) => {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError(
                  'delete',
                  this.loc.paymentButtonTypeFields
                )
              );
            },
          });
      }
    });
  }

  // FILTERS

  addFilter(event: any, column: string): void {
    const filterValue = (
      event.target
        ? event.target.value
        : event.value || event.value === ''
        ? event.value
        : event
    )!
      .trim()
      .toLocaleLowerCase();
    this.filters[column] = filterValue;
    this.nonWorkingDaysDataSource.filter = JSON.stringify(this.filters);

    let state = JSON.parse(localStorage.getItem('state')!);
    if (!state.typesId) {
      state.typesId = {};
    }
    state.typesId[column] = (
      event.target
        ? event.target.value
        : event.value || event.value === ''
        ? event.value
        : event
    )!;
    localStorage.setItem('state', JSON.stringify(state));
  }

  private _filterMonthName(value: string): string[] {
    const filterValue = value.toLowerCase();
    let list = this.monthName.filter((option) =>
      option?.toLowerCase().includes(filterValue)
    );
    return list;
  }

  private _filterDayNumber(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.dayNumber.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private _filterDescription(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.descrition.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  customFilterPredicate(data: any, filter: string): boolean {
    const filtersS = JSON.parse(filter);
    let fitsThisFilter = true;
    for (const key in filtersS) {
      const text = data[key];
      fitsThisFilter =
        fitsThisFilter &&
        text
          .toLocaleLowerCase()
          .includes(filtersS[key].trim().toLocaleLowerCase());
    }
    return fitsThisFilter;
  }

  setSortAndPaginator(): void {
    this.nonWorkingDaysDataSource.sort = this.sort;
    this.nonWorkingDaysDataSource.filterPredicate = this.customFilterPredicate;
    this.nonWorkingDaysDataSource.paginator = this.paginator;
  }

  pageEvents(event: any) {
    let state = JSON.parse(localStorage.getItem('state')!);
    if (!state.typesId) {
      state.typesId = {};
    }
    state.typesId['pageIndex'] = event.pageIndex;
    state.typesId['pageSize'] = event.pageSize;
    localStorage.setItem('state', JSON.stringify(state));
  }
}

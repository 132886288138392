import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AttributeCategorie } from 'src/app/models/attributes/attributeCategorie';
import { BaseResponse } from 'src/app/models/responses.model';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';

@Component({
  selector: 'app-create-attribute-group-allowed',
  templateUrl: './create-attribute-group-allowed.component.html',
  styleUrls: ['./create-attribute-group-allowed.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateAttributeGroupAllowedComponent implements OnInit {
  loading: boolean = true;
  templates: any[] = [];
  operations: any[] = [];
  groups: any[] = [];
  firstFormGroup!: UntypedFormGroup;
  secondFormGroup!: UntypedFormGroup;
  thirdFormGroup!: UntypedFormGroup;

  attributeCategories: AttributeCategorie[] = [];

  constructor(
    public loc: LocalizationService,
    private attributesService: AttributesService,
    private logoutDialogService: LogoutDialogService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateAttributeGroupAllowedComponent>,
    private extensionPointsService: ExtensionPointsService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.firstFormGroup = this.formBuilder.group({
      attributeCategoryId: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      serviceTemplateId: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      groupId: ['', Validators.required],
      description: ['', Validators.required]
    });
    this.loadAttributeCategories();
    this.loadServiceTemplate();
  }

  loadServiceFormGroup() {
    if (
      this.firstFormGroup.get('attributeCategoryId')?.value.id === 'OPERATION'
    ) {
      this.secondFormGroup = this.formBuilder.group({
        serviceTemplateId: ['', Validators.required],
        operationId: ['', Validators.required]
      });
    } else {
      this.secondFormGroup = this.formBuilder.group({
        serviceTemplateId: ['', Validators.required]
      });
    }
  }

  loadServiceTemplate() {
    this.extensionPointsService.getServiceTemplates().subscribe(
      (res: BaseResponse<any>) => {
        this.templates = res.messageRS as any[];
      },
      err => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    );
  }

  loadOperations(event: MatSelectChange) {
    this.extensionPointsService.getOperationsByTemplate(event.value).subscribe({
      next: (res: BaseResponse<any>) => {
        let templateOperations = ((res.messageRS as any) as any[]).map(
          elem => elem.serviceTemp
        );

        this.operations = templateOperations;
      }
    });
  }

  loadGroups() {
    this.thirdFormGroup.get('groupId')?.setValue('');
    this.attributesService
      .getGroupsPTS(
        this.firstFormGroup.get('attributeCategoryId')?.value.id,
        this.secondFormGroup.get('serviceTemplateId')?.value,
        this.secondFormGroup.get('operationId')?.value
      )
      .subscribe(
        (res: BaseResponse<any>) => {
          this.groups = (res.messageRS as unknown) as any[];
        },
        err => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          this.logoutDialogService.openLogoutDialog(err);
          if (err.statusCode !== 401) {
            this.exit('error');
          }
        }
      );
  }

  loadAttributeCategories() {
    this.attributesService.getAttributeCategories().subscribe(
      (res: BaseResponse<AttributeCategorie[]>) => {
        this.attributeCategories = (res.messageRS as unknown) as AttributeCategorie[];

        this.loading = false;
      },
      err => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  createAttributeGroup() {
    let category = this.firstFormGroup.get('attributeCategoryId')?.value.id;
    let serviceTemplate = this.secondFormGroup.get('serviceTemplateId')?.value;
    let operation = this.secondFormGroup.get('operationId')?.value;
    let description = this.thirdFormGroup.get('description')?.value;
    let group = this.thirdFormGroup.get('groupId')?.value.id;
    this.loading = true;
    this.attributesService
      .createAttributeGroupAllowed(
        category,
        group,
        serviceTemplate,
        operation,
        description
      )
      .subscribe(
        (res: BaseResponse<void>) => {
          if (res.statusRS?.code === '0') {
            setTimeout(() => {
              this.loading = false;
              this.exit('created');
            }, 200);
          } else if (res.statusRS?.code === '-91786') {
            this.exit('exist');
          } else {
            this.exit('error');
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  exit(message: any): void {
    this.dialogRef.close(message);
  }

  /*TENGO UN GET GROUPS QUE TRAE TODOS LOS GRUPOS DE PTS */

  /*TENGO UN GET SERVICE TEMPLATE Y OPERACION QUE RECIBE POR PARAMETRO la categoria y el grupo y retorne service template y la operacion*/
}

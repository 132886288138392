import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-show-structure',
  templateUrl: './show-structure.component.html',
  styleUrls: ['./show-structure.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class ShowStructureComponent implements OnInit {
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShowStructureComponent>,
    public loc : LocalizationService
  ) { }

  body = '';
  title?: string;
  editable = false;

  ngOnInit(): void {
    this.body = JSON.stringify(this.data.body,undefined,4);
    this.editable = this.data.editable ?? false;
    this.title = this.data.title;
  }

  exit(result: string){
    this.dialogRef.close('not updated');
  }

  save(){
    let result = !this.body  ? '[]':  this.body 
    this.dialogRef.close(result);
  }
 

}

<div class="dark" *ngIf="loading">
    <mat-spinner
      diameter="70"
      style="
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -35px;
        margin-top: -35px;
      "
    ></mat-spinner>
  </div>
  <div class="title">
    <div class="inner-title-container">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon class="action-button-icon" fontSet="material-icons-outlined"
          >people</mat-icon
        >
        <mat-icon class="action-button-icon-add">add</mat-icon>
      </button>
      <div class="dialog-title">
        <label i18n> {{ loc.attributesGroup.create }} </label>
      </div>
    </div>

    <button class="exit-button" (click)="exit('not created')">
      <mat-icon class="exit-icon">close</mat-icon>
    </button>
  </div>

  <mat-horizontal-stepper
    class="horizontal-stepper stepper"
    [ngClass]="'five-step last-edited-step-' + stepper.selectedIndex"
    labelPosition="bottom"
    [linear]="true"
    #stepper
    appStepper
    [step]="stepper.selectedIndex"
  >
    <ng-template matStepperIcon="done" let-index="index">
      <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 0"
      >category</mat-icon>
    <mat-icon
    fontSet="material-icons-outlined"
    class="stepper-icon"
    *ngIf="index == 1"
    >paid</mat-icon>
    <mat-icon
    fontSet="material-icons-outlined"
    class="stepper-icon"
    *ngIf="index == 2"
    >group</mat-icon>
    <mat-icon
    fontSet="material-icons-outlined"
    class="stepper-icon"
    *ngIf="index == 3"
    >check_box</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="edit" let-index="index">
      <mat-icon
        fontSet="material-icons-outlined"
        class="stepper-icon"
        *ngIf="index == 0"
        >category</mat-icon>
      <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 1"
      >paid</mat-icon>
      <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 2"
      >group</mat-icon>
      <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 3"
      >check_box</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="number" let-index="index">
      <mat-icon
        fontSet="material-icons-outlined"
        class="stepper-icon"
        *ngIf="index == 0"
        >category</mat-icon>
      <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 1"
      >paid</mat-icon>
      <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 2"
      >group</mat-icon>
      <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 3"
      >check_box</mat-icon>
    </ng-template>

    <mat-step [stepControl]="firstFormGroup" [editable]="true">
      <ng-template matStepLabel i18n>{{ loc.stepTitle.category }}</ng-template>
      <form [formGroup]="firstFormGroup" class="form-step">
        <div class="data-box">
          <div class="create-row">
            <mat-form-field appearance="fill" class="attributes">
              <mat-label i18n>{{ loc.fields.category }}</mat-label>
              <mat-select formControlName="attributeCategoryId" (selectionChange)="loadServiceFormGroup()">
                <mat-option [value]="category" *ngFor="let category of attributeCategories"
                  >{{ category.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="dialog-nav-buttons">
          <button
            mat-fab
            class="button green-button"
            style="margin-top: 60px"
            matStepperNext
          >
            <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup" [editable]="true">
      <ng-template matStepLabel i18n>{{ loc.stepTitle.operations }}</ng-template>
      <form [formGroup]="secondFormGroup" class="form-step">
        <div class="data-box">
          <div class="create-row" style="justify-content: flex-start">
            <mat-form-field appearance="fill" class="attributes">
              <mat-label i18n>{{loc.extensionPoints.serviceTemplate}}</mat-label>
              <mat-select formControlName="serviceTemplateId" (selectionChange)="loadOperations($event)">
                <mat-option [value]="service.frameBankingTemplateId" *ngFor="let service of templates"
                  >{{ service.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          <mat-form-field appearance="fill" class="attributes" *ngIf="this.firstFormGroup.get('attributeCategoryId')?.value.id === 'OPERATION'">
            <mat-label i18n>{{loc.tableHeaders.operation}}</mat-label>
            <mat-select formControlName="operationId">
              <mat-option [value]="operation" *ngFor="let operation of operations"
                >{{ operation }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        </div>
        <div class="dialog-nav-buttons">
          <button
          mat-fab
          class="button green-button"
          style="margin-top: 60px"
          matStepperPrevious
        >
          <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
          <button
            mat-fab
            class="button green-button"
            style="margin-top: 60px"
            (click)="loadGroups()"
            matStepperNext
          >
            <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="thirdFormGroup" [editable]="true">
      <ng-template matStepLabel i18n>{{ loc.stepTitle.group }}</ng-template>
      <form [formGroup]="thirdFormGroup" class="form-step">
        <div class="data-box">
          <div class="create-row" *ngIf="groups.length > 0">
            <mat-form-field appearance="fill" class="attributes">
              <mat-label i18n>{{loc.tableHeaders.group}}</mat-label>
              <mat-select formControlName="groupId">
                <mat-option [value]="group" *ngFor="let group of groups"
                  >{{ group.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label i18n>{{loc.fields.description}}</mat-label>
              <input matInput formControlName="description" required>
          </mat-form-field>
          </div>
        </div>

        <div *ngIf="groups.length === 0"><img class="empty-img" src="assets/img/empty-services.png" /></div>
        <div *ngIf="groups.length === 0" class="empty-lbl">
            <label i18n>
                {{loc.attributesGroup.empty}}
            </label>
        </div>

        <div class="dialog-nav-buttons">
          <button
          mat-fab
          class="button green-button"
          style="margin-top: 40px"
          matStepperPrevious
        >
          <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
          <button
            mat-fab
            class="button green-button"
            style="margin-top: 40px"
            matStepperNext
            *ngIf="groups.length > 0"
          >
            <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [editable]="true">
      <ng-template matStepLabel i18n>{{ loc.stepTitle.verify }}</ng-template>
      <div class="form-step">
        <div class="verify-large">
          <div class="flex-container-vertical flex-container-vertical-modal">
            <div>
              <label class="card-name" i18n>{{ loc.attributesGroup.new }}</label>
            </div>
            <div class="details-package-large details-flex">
              <div class="verify-vertical">
                <div class="card-header" i18n>{{ loc.fields.category }}</div>
                <div class="card-value">
                  {{ firstFormGroup.get("attributeCategoryId")?.value.description }}
                </div>
              </div>
              <div class="verify-vertical">
                <div class="card-header" i18n>{{ loc.fields.serviceTemplate }}</div>
                <div class="card-value">
                  {{ secondFormGroup.get("serviceTemplateId")?.value }}
                </div>
              </div>
              <div class="verify-vertical" *ngIf="firstFormGroup.get('attributeCategoryId')?.value.id === 'OPERATION'">
                <div class="card-header" i18n>{{ loc.fields.operation }}</div>
                <div class="card-value">
                  {{ secondFormGroup.get("operationId")?.value }}
                </div>
              </div>
              <div class="verify-vertical">
                <div class="card-header" i18n>{{ loc.fields.group }}</div>
                <div class="card-value">
                  {{ thirdFormGroup.get("groupId")?.value.description }}
                </div>
              </div>
              <div class="verify-vertical">
                <div class="card-header" i18n>{{ loc.fields.description }}</div>
                <div class="card-value">
                  {{ thirdFormGroup.get("description")?.value }}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="dialog-nav-buttons">
          <button mat-fab class="button green-button" matStepperPrevious>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button mat-fab class="button green-button" (click)="createAttributeGroup()">
            <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon"
              >save</mat-icon
            >
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Country } from 'src/app/models/country/country.model';
import { BanksService } from 'src/app/services/banks/banks.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { BaseResponse } from '../../models/base/base-response.model';
import { Bank } from '../../models/Banks/bank.model';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-edit-bank',
  templateUrl: './edit-bank.component.html',
  styleUrls: ['./edit-bank.component.css'],
  host: {
    class: 'confirmation-dialog-component'
  }
})
export class EditBankComponent implements OnInit {
  loading = false;
  newDescription = '';
  countries: Country [] = [];
  firstFormGroup!: UntypedFormGroup;
  bankCountry : Country | undefined;


  constructor(
    public dialogRef: MatDialogRef<EditBankComponent>,
    private logoutDialogService :LogoutDialogService,
    private _formBuilder: UntypedFormBuilder,
    public loc: LocalizationService,
    public bankService: BanksService,
    @Inject(MAT_DIALOG_DATA) public data: Bank,
    public snackbarService: SnackbarService
  ) {

  }

  ngOnInit(): void {

    this.loadCountries();
    this.firstFormGroup = this._formBuilder.group({
      abaNumber:[''],
      bankAddress:[''],
      bankId: [''],
      bankName: ['',Validators.required],
      swiftCode: ['',Validators.required],
      country: ['',Validators.required],


    })


  }
  exit(message: string) {
    this.dialogRef.close(message);
  }
  loadCountries(){
    this.bankService.getCountries().subscribe(
      {
        next: (res:BaseResponse) =>{
          this.countries = res.messageRS as unknown as Country [];
          this.bankCountry = this.countries.find(bankCountry => bankCountry.countryName === this.data.bankCountry);

        }, error: (err: any) => {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError("delete",this.loc.banks)
          );
      }
    }
    );

  }
  modify(){
    this.loading = true;
    let newBank : Bank = {
      bankId: this.firstFormGroup.get('bankId')!.value,
      bankName: this.firstFormGroup.get('bankName')!.value,
      bankAddress: this.firstFormGroup.get('bankAddress')!.value,
      bankCountry: this.firstFormGroup.get('country')!.value.countryName,
      abaNumber: this.firstFormGroup.get('abaNumber')!.value,
      swiftCode: this.firstFormGroup.get('swiftCode')!.value,
    }

    this.bankService.updateBank(newBank).subscribe(
     {
      next: (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.dialogRef.close(this.data);
          } else {
            this.exit("error");
          }
        }, 200);
      },error: (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    }
    )

  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CountriesService } from '../../services/countries/countries.service';
import { LocalizationService } from '../../services/localization/localization.service';
import { AddCountryCurrencyComponent } from './add-country-currency/add-country-currency.component';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { CountryCurrency } from 'src/app/models/countryCurrency/countryCurrency';
import { BaseResponse } from 'src/app/models/responses.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
@Component({
  selector: 'app-country-currency',
  templateUrl: './country-currency.component.html',
  styleUrls: ['./country-currency.component.css'],
  host: {
    class: 'component'
  }
})
export class CountryCurrencyComponent implements OnInit {
  @Input() tableStyle = "tableResponsive";
  @Input() countryId = '';

  loading = true;
  data: TableData[] = [];

  constructor(
    public countriesService: CountriesService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) { }

  ngOnInit(): void {
    this.loadCountryCurrencies();
  }

  loadCountryCurrencies() {
    this.loading = true;
    this.countriesService.listCountryCurrencies(this.countryId).subscribe({
      next: (res: BaseResponse<CountryCurrency[]>) => {
        this.data = res.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    });
  }

  emptyState : EmptyState = {
    image: 'assets/img/empty-commissions.png',
    message: this.loc.currency.emptyState,
    buttons: [
      {
        text: this.loc.currency.create,
        action: () => this.create(),
      }
    ]
  }

  columns : Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'countryId',
    },
    {
      title: this.loc.tableHeaders.separator,
      key: 'decimalSeparator',
    },
    {
      title: this.loc.tableHeaders.decimalNumber,
      key: 'decimalNumber',
    },
  ]

  paginatorInfo = this.loc.currency

  filters : TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'countryId',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    }
  ]

  actions: Action[] = [
    {
      icon: 'edit',
      action: (row: CountryCurrency) => this.edit(row),
      tooltip: this.loc.currency.edit
    },
    {
      icon: 'delete',
      action: (row: CountryCurrency) => this.delete(row),
      tooltip: this.loc.currency.delete
    }
  ]

  createButton: CreateButton = {
    tooltip: this.loc.currency.create,
    action: () => {
      this.create();
    }
  }

  edit(currency: CountryCurrency) {
    let data: EditModalData = {
      title: this.loc.currency.edit,
      icon: 'language',
      fields: [
        { label: this.loc.currency.separator, 
          value: currency.decimalSeparator, 
          errorMsg: this.loc.currency.editErrorDecimalSeparator, 
          validators: [Validators.required],
        },
        { label: this.loc.currency.decimalNumber, 
          value: currency.decimalNumber, 
          errorMsg: this.loc.currency.editErrorDecimalNumber, 
          validators: [Validators.required]
        }]
    }
    const dialogRef = this.dialogService.openDialog(EditModalComponent, data, 'small');
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyAction(currency, result)
      }
    });
  }

  modifyAction(row: any, result: any){
    let newSeparator = result.find((x: any) => x.label === this.loc.currency.separator).value
    let newDecimalNumber = result.find((x: any) => x.label === this.loc.currency.decimalNumber).value

    let newCountryCurrency : CountryCurrency = {
      countryId: row.countryId,
      decimalSeparator: newSeparator,
      decimalNumber: newDecimalNumber,
      code: '',
      isoCode: '',
      description: '',
      symbol: '',
      creationDate: '',
      changeDate: ''
    }

    this.countriesService.updateCountryCurrency(row.code,newCountryCurrency).subscribe({
      next: (res: any) => {
        this.loading = false;
        if(res.statusRS?.code === "0"){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage("update", this.loc.currency)
          );
          this.loadCountryCurrencies();
        } else {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError("update", this.loc.currency)
          );
        }
      },error: (err: any) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError("update", this.loc.currency)
          );
        }
      }
    })
  }

  create() {
    const dialogRef = this.dialogService.openDialog(AddCountryCurrencyComponent, {
      countryId: this.countryId,
      existingCurrencies: this.data.map((currency) => currency.currencyId)
    }, 'medium-large');
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage("create", this.loc.currency)
        );
        this.loadCountryCurrencies();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError("create", this.loc.currency)
        );
        this.loadCountryCurrencies();
      }
    });
  }

  delete(currency: CountryCurrency) {
    let dataModal: DeleteModalData = {
      title: this.loc.currency.deleteCurrency,
      icon: 'language',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.currency)
    }
    const dialogRef = this.dialogService.openDialog(DeleteModalComponent,
      dataModal,
      "x-small");

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {

        this.countriesService.deleteCountryCurrency(currency.code!, this.countryId)
          .subscribe({
            next: (res: BaseResponse<void>) => {
              if (res.statusRS?.code === "0") {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage("delete", this.loc.currency)
                );
                this.loadCountryCurrencies();
              } else {
                this.snackbarService.openErrorSnackbar(this.loc.genericError("delete", this.loc.currency))
              }
            }, error: (err) => {
              this.logoutDialogService.openLogoutDialog(err);
            }
          })

      }

    });

  }

}

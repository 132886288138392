import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServiceOperationMedium } from 'src/app/models/digital-services/ServiceOperationMedium';
import { BaseResponse } from 'src/app/models/responses.model';
import { BaseService } from '../base.service';
import { BaseRequest } from 'src/app/models/base/base-request.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceOperationMediumsService {
  readonly baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private baseService: BaseService
  ) { }

  getServiceOperationMediums() {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    if (this.tokenService.validToken()) {
      return this.http.get(
        this.baseUrl + 
        'RSAdaptorFE/api/v1/paycorr/services/medium-id/x/get-service-operation-by-medium',
        HTTP_OPTIONS
      );
    } else {
      return this.baseService.invalidToken();
    }
  }

  createServiceOperationMedium(serviceOperationMedium: ServiceOperationMedium) {
    console.log(serviceOperationMedium);
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      serviceId: serviceOperationMedium.serviceId,
      operationId: serviceOperationMedium.operationId,
      mediumId: serviceOperationMedium.mediumId
    }
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json',
      }),
    };
    if (this.tokenService.validToken()) {
      return this.http.post(
        this.baseUrl +
        'RSAdaptorFE/api/v1/paycorr/services/service/x/create-service-operation-medium', baseRequest,
        HTTP_OPTIONS
      );
    } else {
      return this.baseService.invalidToken();
    }
  }

  deleteServiceOperationMedium(serviceOperationMedium: ServiceOperationMedium) {
    var params = new HttpParams();
    params = params.set('operationId', serviceOperationMedium.operationId);
    params = params.set('mediumId', serviceOperationMedium.mediumId);
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: params
    };
    if (this.tokenService.validToken()) {
      return this.http.delete(
        this.baseUrl +
        'RSAdaptorFE/api/v1/paycorr/services/service/'+serviceOperationMedium.serviceId+'/delete-service-operation-medium',
        HTTP_OPTIONS
      );
    } else {
      return this.baseService.invalidToken();
    }
  }

}


<div class="dark" *ngIf="loading">
    <mat-spinner diameter="70" style="
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -35px;
        margin-top: -35px;
      "></mat-spinner>
  </div>
  <div class="title">
    <div class="inner-title-container">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon class="action-button-icon"
   fontSet="material-icons-outlined" >psychology</mat-icon>
        <mat-icon class="action-button-icon-add">add</mat-icon>
      </button>
      <div class="dialog-title"><label i18n>{{loc.step.create}}</label></div>
    </div>
    <button class="exit-button" (click)="exit('not created')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>



    <mat-horizontal-stepper [ngClass]="'three-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
      [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex" class="horizontal-stepper very-short-stepper">

      <ng-template matStepperIcon="done" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">add_a_photo</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 2">check_box</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="edit" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">add_a_photo</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 2">check_box</mat-icon>
      </ng-template>>

      <ng-template matStepperIcon="number" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">add_a_photo</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 2">check_box</mat-icon>
      </ng-template>


    <div class="all">
      <mat-step [stepControl]="firstFormGroup" [editable]="true">
        <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
        <form [formGroup]="firstFormGroup" class="form-step">
          <div class="data-box">
          <div class="create-row">
            <mat-form-field appearance="fill">
                <mat-label i18n>{{loc.fields.id}}</mat-label>
                <input matInput name="id" formControlName="id" required />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label i18n>{{loc.fields.name}}</mat-label>
              <input matInput name="name" formControlName="name" required />
          </mat-form-field>

          </div>

          <div class="create-row" style="justify-content: flex-start;">

            <mat-form-field appearance="fill" >
              <mat-label i18n>{{loc.fields.type}}</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let type of types" [value]="type.id">
                  {{ type.description }}
                </mat-option>
              </mat-select>
          </mat-form-field>

            <mat-form-field appearance="fill" >
                <mat-label i18n>{{loc.apis.singular}}</mat-label>
                <mat-select formControlName="api">
                  <mat-option *ngFor="let api of apis" [value]="api.apiId">
                    {{ api.name }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="dialog-nav-buttons nav-buttons-form" >
            <button mat-fab class="button green-button" (click)="next(stepper)" [disabled]="!this.firstFormGroup.valid"  >
              <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
            </button>
        </div>
        </form>
      </mat-step>


      <mat-step [editable]="true">
        <ng-template matStepLabel i18n>{{ loc.stepTitle.icon }}</ng-template>
        <div class="form-step">
          <div class="data-box">
            <div style="display: inline-block">
              <div class="image-box">
                <div>
                  <mat-icon *ngIf="!stepIcon" class="default-img">add_a_photo</mat-icon>

                  <img
                    *ngIf="stepIcon"
                    [src]="stepIcon"
                    alt="img_user"
                    class="image"
                    (error)="stepIcon = ''"
                  />
                </div>
              </div>

              <button
                mat-fab
                class="image-button button green-color"
                (click)="hiddenfileinput.click()"
                i18n-matTooltip
                [matTooltip]="loc.common.addPhoto"
              >
                <mat-icon fontSet="material-icons-outlined"> camera_alt </mat-icon>
              </button>

              <input
                name="image"
                type="file"
                style="display: none"
                accept="image/*"
                (change)="onFileSelect($event)"
                #hiddenfileinput
              />
            </div>
          </div>
          <div class="dialog-nav-buttons nav-buttons-photo">
            <button mat-fab class="button green-button" matStepperPrevious>
              <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
            </button>
            <button
              mat-fab
              class="button green-button"
              matStepperNext
            >
              <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </mat-step>


      <mat-step [editable]="true" class="step-verify">
        <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
        <div class="form-step">
          <div class="verify-large">
            <div class="flex-container-vertical flex-container-vertical-modal">
              <div>
                <label class="card-name" i18n>{{loc.step.new}}</label>
              </div>
              <div>
                <div class="details-package-large details-flex">
                  <div class="verify-vertical verify1">
                    <div class="card-header" i18n>{{loc.fields.id}}</div>
                    <div class="card-value api-verify">{{ id }}</div>
                  </div>
                  <div class="verify-vertical">
                    <div class="card-header" i18n>{{loc.fields.name}}</div>
                    <div class="card-value">{{ name }}</div>
                  </div>
                  <div class="verify-vertical">
                    <div class="card-header" i18n>{{loc.fields.type}}</div>
                    <div class="card-value">{{ this.firstFormGroup.get('type')!!.value.description}}</div>
                  </div>
                  <div class="verify-vertical">
                    <div class="card-header" i18n>{{loc.apis.singular}}</div>
                    <div class="card-value api-verify">{{ this.firstFormGroup.get('api')!!.value.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="dialog-nav-buttons nav-buttons-verify" >
          <button mat-fab class="button green-button" matStepperPrevious   >
            <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
          </button>
          <button mat-fab class="button green-button" (click)="saveImage()"  >
            <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon">save</mat-icon>
          </button>
          </div>
      </div>

      </mat-step>
    </div>
    </mat-horizontal-stepper>

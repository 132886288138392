import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { Action } from 'src/app/models/extension-points/Action.model';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { TokenService } from '../token/token.service';
import { BaseResponse } from 'src/app/models/responses.model';
import { ServiceTemplate } from 'src/app/models/digital-services/ServiceTemplate.model';

@Injectable({
  providedIn: 'root'
})
export class ExtensionPointsService {
  readonly baseURL = environment.apiUrl;
  constructor(
    private tokenService: TokenService,
    private http: HttpClient,
    private baseService: BaseService
  ) {}

  getActions(operationId: string = '') {
    const HTTP_OPTIONS = {
      params: {
        operationId
      }
    };
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/operation/X/get-actions',
      HTTP_OPTIONS
    ) as Observable<BaseResponse<Action>>;
  }

  getServiceTemplates() {
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/services/service-template/x/list-service-templates'
    ) as Observable<BaseResponse<ServiceTemplate[]>>;
  }

  getOperationsByTemplate(serviceTemplateId: string) {
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v2/own-channels/administratives/digital-service-template/' +
        serviceTemplateId +
        '/operation-list'
    ) as Observable<BaseResponse<any[]>>;
  }

  getExtensionPoints(
    operationId: string,
    touchPointId: string,
    actionId: string,
    actionEntity: string
  ): Observable<BaseResponse<any>> {
    const HTTP_OPTIONS = {
      params: {
        operationId,
        touchPointId,
        actionId,
        actionEntity
      }
    };
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/operation/x/get-operation-extension-points-template',
      HTTP_OPTIONS
    ) as Observable<BaseResponse<any>>; //TODO change any to typu
  }

  updateExtensionPointsTemplate(action: Action) {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = action;
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/extension-point/x/update-extension-points-template',
      baseRequest
    );
  }

  createExtensionPoint(extensionPoint: any) {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = extensionPoint;
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/extension-point/x/create-extension-points-template',
      baseRequest
    );
  }

  getLabels(): Observable<object> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    if (this.tokenService.validToken()) {
      return this.http.get(
        this.baseURL +
          'RSAdaptorFE/api/v1/paycorr/extensions-points/labels/x/get-labels',
        HTTP_OPTIONS
      );
    } else {
      return this.baseService.invalidToken();
    }
  }

  getOperationActions(operationId: string) {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/extensions-points/operation/${operationId}/get-operation-actions`
    ) as Observable<BaseResponse<Action[]>>;
  }

  createAction(action: Action) {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = action;
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/action/x/create-action',
      baseRequest
    ) as Observable<BaseResponse<void>>;
  }

  updateAction(action: Action) {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = action;
    return this.http.put(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/action/x/edit-action',
      baseRequest
    );
  }

  deleteAction(action: Action) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders(),
      params: {
        digitalJourney: action.digitalJourney,
        touchPointId: action.touchPointId,
        actionId: action.actionId,
        actionEntity: action.actionEntity,
        stageId: action.stageId
      },
      withCredentials: false
    };

    return this.http.delete(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/extensions-points/action/x/delete-action?digitalJourney=${action.digitalJourney}&touchPointId=${action.touchPointId}&actionId=${action.actionId}&actionEntity=${action.actionEntity}&stageId=${action.stageId}`
    ) as Observable<BaseResponse<void>>;
  }

  updateExtensionPoint(action: Action) {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = action;
    return this.http.put(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/extension-point/x/edit-extension-point-template',
      baseRequest
    );
  }

  getFields() {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/extensions-points/fields/x/get-fields-by-action`
    );
  }

  getActionTemplateFields(action: Action) {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/extensions-points/fields/x/get-fields-by-action?digitalJourney=${action.digitalJourney}&touchPoint=${action.touchPointId}&actionId=${action.actionId}&actionEntity=${action.actionEntity}&stageId=${action.stageId}`
    );
  }

  deleteParameter(fieldId: string) {
    return this.http.delete(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/extensions-points/fields/${fieldId}/delete-field`
    );
  }

  createField(fieldName: string, description: string) {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      fieldName,
      description
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/fields/x/create-field',
      baseRequest
    );
  }

  setFieldsToTemplate(message: any) {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = message;
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/fields/x/create-fields-template',
      baseRequest
    );
  }

  updateField(action: any) {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = action;
    return this.http.put(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/fields/x/edit-field',
      baseRequest
    );
  }

  addFlow(
    flowId: any,
    flowEntity: string,
    flowVrs: string,
    labelId: string = flowId
  ): Observable<object> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      flowId,
      labelId,
      flowEntity,
      flowVrs
    };

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json'
      }),
      withCredentials: false
    };

    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/labels/x/create',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  getFlows(): Observable<object> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/extensions-points/flows/x/get-flows?extEntity=PayCorr',
      HTTP_OPTIONS
    );
  }
}

import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {ChangePasswordComponent} from 'src/app/change-password/change-password.component';
import {DialogService} from 'src/app/services/dialog/dialog.service';
import {LocalizationService} from 'src/app/services/localization/localization.service';
import {SessionsService} from 'src/app/services/sessions/sessions.service';
import {SnackbarService} from 'src/app/services/snackbar/snackbar.service';
import {TokenService} from 'src/app/services/token/token.service';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  host: {
    class: 'login-container'
  }
})
export class LoginComponent implements OnInit {
  selectedValue = 'English';
  username = 'Usuario';
  loginSpinner = false;
  showErrorMessage = false;
  errorMessage = '';
  loginModel = {
    username: '',
    password: '',
  };
  showError = false;


  constructor(
    public router: Router,
    public sessionsService: SessionsService,
    private tokenService: TokenService,
    public dialogService: DialogService,
    public loc: LocalizationService,
    private snackbarService: SnackbarService,
  ) {
  }

  languages: { name: string, img: string, locale: "es" | "en" }[] = [
    {
      name: "Español",
      img: 'assets/img/uruguay.svg',
      locale: 'es',
    },
    {
      name: "English",
      img: 'assets/img/united-states.svg',
      locale: 'en',
    }
  ]
  filteredLanguage = this.languages.find(language => language.locale === this.loc.currentLocale) || this.languages[0];

  ngOnInit(): void {
    this.resetForm();
    this.tokenService.removeToken();
  }

  resetForm(form?: NgForm): void {
  }


  onLoginButtonClicked(): void {
    this.showErrorMessage = false;
    this.loginSpinner = true;
    this.sessionsService.login(this.loginModel).subscribe({
      next: (res: HttpResponse<any>) => {
        this.tokenService.saveLastLogInDate(res.headers.get('lastlogin')!);
        this.tokenService.saveToken(JSON.parse(res.body).access_token);
        this.tokenService.saveRefreshToken(JSON.parse(res.body).refresh_token);
        let validations: any = res.headers.get('validations');
        let needChangePassword = JSON.parse(validations)?.needChangePassword;
        if (!needChangePassword) {
          this.errorMessage = this.loc.errors.errorLogin
          this.showErrorMessage = true;
          this.loginSpinner = false;
          return;
        }
        if (needChangePassword === 'true') {
          const dialogRef = this.dialogService.openDialog(ChangePasswordComponent,
            {
              exp: false,
              needChangePassword: true
            },
            "medium-large");
          dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined) {
              this.loginModel.password = result;
              this.onLoginButtonClicked();
            }
          });
          this.loginSpinner = false;
          return;
        }
        const extendedoutput = res.headers.get('extendedoutput');

        const userType = this.tokenService.isAdmin(extendedoutput ? extendedoutput : '{}');
        if (userType == 0) {
          localStorage.setItem('state', JSON.stringify({})); //initialize state
          this.router.navigate(['agent']);
        } else if (userType == 1) {
          this.errorMessage = this.loc.errors.userIsNotAdmin
          this.showErrorMessage = true;
        } else if (userType == 2) {
          this.errorMessage = this.loc.errors.errorLogin
          this.showErrorMessage = true;
        }

        this.loginSpinner = false;

      },
      error: (err: HttpErrorResponse) => {
        if (!err.ok) {
          console.log(this.loc.errors.errorLogin)
          if (JSON.stringify(err.error).includes("TEMPORARILY_BLOCKED")) {
            this.errorMessage = this.loc.errors.maxRetriesExceeded;
          } else if (err.status == 401) {
            this.errorMessage = this.loc.errors.incorrectUsersAndPasswords;
          } else {
            this.errorMessage = this.loc.errors.errorLogin;
          }
          this.showErrorMessage = true;

        }
        this.loginSpinner = false;
      }
    });
  }

  onSearchChange(): void {
    this.showErrorMessage = false;
  }


  changeLanguage(): void {
    this.loc.setLocale(this.filteredLanguage.locale);
  }

  forgotPassword(): void {
    const dialogRef = this.dialogService.openDialog(ForgotPasswordComponent, {}, "medium").afterClosed().subscribe((result) => {
      if (result) {
        switch (result) {
          case "not admin":
            // this.snackbarService.openErrorSnackbar(this.loc.errors.passwordUpdatedButUserIsNotAdmin);
            break;
          case "error":
            this.errorMessage = this.loc.errors.errorLogin;
            //this.showErrorMessage();
            break;
          default:
            this.loginModel = result;
            this.onLoginButtonClicked();
        }
      }
    });
  }


}

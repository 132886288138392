<div class="general-operations-div">
  <div style="display: flex">
    <button mat-icon-button class="back" (click)="goBack()">
      <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
    </button>
    <button mat-fab class="page-icon-button blue-button-icon">
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon"
        >calendar_month</mat-icon
      >
    </button>
    <ng-container *ngIf="!loading">
      <div class="title-container">
        <label class="title-mid-text"> {{ calendarId }}</label>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!loading" class="general-operations-buttons-div">
    <button
      mat-fab
      class="button green-color"
      i18n-matTooltip
      [matTooltip]="loc.workingDays.goTo"
      (click)="createWorkingDays()"
    >
      <mat-icon class="" style="font-weight: 100"> work </mat-icon>
    </button>
  </div>
</div>

<mat-card class="details-card">
  <div class="flex-container">
    <div class="flex-container-vertical">
      <div>
        <label class="card-header" i18n>{{
          loc.detailsHeaders.calendarDescription
        }}</label>
      </div>
      <div>
        <ng-container *ngIf="!loading">
          <label class="card-value">
            {{ calendarDescription }}
          </label>
        </ng-container>
        <div *ngIf="loading">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              background: 'no-repeat rgb(227 228 230 / 33%)',
              height: '18px',
              width: '150px'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<mat-tab-group class="tab-bar">
  <mat-tab [label]="loc.tabNames.nonWorkingDays" i18n-label>
    <app-non-working-days
      [calendarId]="this.calendarId"
      tableStyle="tableDetails"
      fieldsType="action"
    ></app-non-working-days>
  </mat-tab>
</mat-tab-group>

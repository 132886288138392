import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { AttributeGroupAllowed } from 'src/app/models/attributes/attributeAllowed';
import { BaseResponse } from 'src/app/models/responses.model';

@Component({
  selector: 'app-associate-service-mode-attribute-group',
  templateUrl: './associate-service-mode-attribute-group.component.html',
  styleUrls: ['./associate-service-mode-attribute-group.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class AssociateServiceModeAttributeGroupComponent implements OnInit {

  loading = false;
  firstFormGroup!: UntypedFormGroup;
  attributes: AttributeGroupAllowed[] = [];

  associatedAttributeGroups: AttributeGroupAllowed[] = [];
  serviceTemplate = ''
  serviceModeId = ''
  serviceModeDescription = ''




  constructor(
    public dialogRef: MatDialogRef<AssociateServiceModeAttributeGroupComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public snackbarService: SnackbarService,
    private attributesService: AttributesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.serviceTemplate = this.data.serviceTemplateId;
    this.serviceModeId = this.data.serviceModeId;
    this.serviceModeDescription = this.data.serviceModeDescription;
    this.associatedAttributeGroups = this.data.attributeGroups;
    this.firstFormGroup = this._formBuilder.group({
      group: ['', Validators.required],
    });

    this.getAttributeGroups();
  }

  getAttributeGroups(){
    this.loading = true;
    this.attributesService.getAttributesGroupAllowed().subscribe(
      (res: BaseResponse<AttributeGroupAllowed[]>) => {

        if(res.statusRS?.code == "0"){
          let attributesCompleteList = res.messageRS as unknown as AttributeGroupAllowed[]
          this.attributes = attributesCompleteList.filter(
            attr => attr.serviceTemplateId == this.serviceTemplate &&
            !this.associatedAttributeGroups.some(
              assoc =>
                assoc.attributeCategoryId == attr.attributeCategoryId &&
                assoc.serviceTemplateId == attr.serviceTemplateId &&
                assoc.attributeGroup == attr.attributeGroup &&
                assoc.operationId == attr.operationId
              )
          );
          if(this.attributes.length == 0){
            this.snackbarService.openErrorSnackbar(this.loc.errors.noAttributeGroupsToAssociate)
          }
        } else {
          this.snackbarService.openErrorSnackbar(this.loc.errors.loadingServiceTemplate)
        }
        this.loading = false;

      }, (err) => {
        this.snackbarService.openErrorSnackbar(this.loc.errors.loadingServiceTemplate)
      }
    )
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  create() {
    this.loading = true;

    this.attributesService.associateServiceModeAttributeGroup(this.serviceModeId, this.firstFormGroup.get('group')?.value.id).subscribe(
      (res: BaseResponse<void>) => {
        setTimeout(() => {
          this.loading = false;
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else if(res.statusRS?.code == "-91865"){
            this.exit("duplicate")
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }


}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { NonWorkingDay } from 'src/app/models/calendar/nonWorkingDay.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class NonWorkingDaysService {
  readonly rootURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  get(calendarId: string): Observable<BaseResponse<NonWorkingDay[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
        calendarId: calendarId,
      },
    };
    return this.http.get<BaseResponse<NonWorkingDay[]>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/calendar/calendar/x/list-non-working-days',
      HTTP_OPTIONS
    );
  }

  create(
    nonWorkingDay: NonWorkingDay
  ): Observable<BaseResponse<NonWorkingDay>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = nonWorkingDay;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.post<BaseResponse<NonWorkingDay>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/calendar/calendar/x/create-non-working-day',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  editDescription(
    calendarId: string,
    monthId: string,
    dayNumber: string,
    description: string
  ): Observable<BaseResponse<NonWorkingDay>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { monthId, dayNumber, description };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<NonWorkingDay>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/non-working-days/non-working-day/${calendarId}/update-description`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  editStatus(
    calendarId: string,
    monthId: string,
    dayNumber: string,
    status: string
  ): Observable<BaseResponse<NonWorkingDay>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { monthId, dayNumber, status };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<NonWorkingDay>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/non-working-days/non-working-day/${calendarId}/update-status`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  delete(
    calendar: string,
    monthId: string,
    dayNumber: string
  ): Observable<BaseResponse<NonWorkingDay>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
        monthId: monthId,
        dayNumber: dayNumber,
      },
    };
    return this.http.delete<BaseResponse<NonWorkingDay>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/calendar/calendar/${calendar}/delete-non-working-day`,
      HTTP_OPTIONS
    );
  }
}

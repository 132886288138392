<div class="general-operations-div">
  <div style="display: flex; margin-right: 15px; align-items: center">
    <button mat-fab class="page-icon-button blue-button-icon">
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon">smart_button</mat-icon>
    </button>
    <label class="page-title" i18n>{{ loc.routes.paymentButton }}</label>
  </div>
</div>

<mat-tab-group
  class="tab-bar"
  [(selectedIndex)]="selectedIndex"
  (selectedTabChange)="onTabChange()"
>
  <mat-tab [label]="loc.tabNames.paymentButtonType" i18n>
    <app-payment-button-types></app-payment-button-types>
  </mat-tab>

  <mat-tab [label]="loc.tabNames.paymentButtonFields" i18n>
    <app-payment-button-fields></app-payment-button-fields>
  </mat-tab>
</mat-tab-group>

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from 'src/app/models/notifications/notification-types.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-create-type',
  templateUrl: './create-type.component.html',
  styleUrls: ['./create-type.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class CreateTypeComponent implements OnInit {

  loading = false;
  firstFormGroup!: UntypedFormGroup;
  secondFormGroup!: UntypedFormGroup;
  applications:any[] = [];
  entities:any[] = [];
  methods:any[] = [];
  platforms:any[] = [];
  newNotificationType: NotificationType = new NotificationType();
  
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<CreateTypeComponent>,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      id: ['', [Validators.required, Validators.pattern('^[A-ZÀ-Ÿ_]+$')]],
      description: ['', Validators.required],
      status: [true, Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      application: ['', Validators.required],
      platform: ['', Validators.required],
      method: ['', Validators.required],
      entity: ['', Validators.required]
    });
    this.listApplications();
    this.listEntities();
    this.listMethods();
    this.listPlatforms();
  }

  listEntities(){
    this.notificationsService.getEntities().subscribe(
      (res) => {
        let response =
          res.messageRS as unknown as any[];
          this.entities = response;
      }
    );
  }
  listApplications(){
    this.notificationsService.getApplications().subscribe(
      (res) => {
        let response =
          res.messageRS as unknown as any[];
          this.applications = response;
      }
    );
  }
  listPlatforms(){
    this.notificationsService.getPlatforms().subscribe(
      (res) => {
        let response =
          res.messageRS as unknown as any[];
          this.platforms = response;
      }
    );
  }
  listMethods(){
    this.notificationsService.getMethods().subscribe(
      (res) => {
        let response =
          res.messageRS as unknown as any[];
          this.methods = response;
      }
    );
  }

  create(){
    this.newNotificationType.id = this.firstFormGroup.get('id')?.value;
    this.newNotificationType.description = this.firstFormGroup.get('description')?.value;
    this.newNotificationType.status = this.firstFormGroup.get('status')?.value;
    this.newNotificationType.application = this.secondFormGroup.get('application')?.value.id;
    this.newNotificationType.platform = this.secondFormGroup.get('platform')?.value.id;
    this.newNotificationType.method = this.secondFormGroup.get('method')?.value.id;
    this.newNotificationType.entity = this.secondFormGroup.get('entity')?.value.id;
    this.loading = true;
    this.notificationsService.createType(this.newNotificationType).subscribe(
        (res) => {
          setTimeout(() => {
            if(res.statusRS?.code === "0"){
              this.exit("created");
            } else {
              this.exit("error");
            }
          }, 200);
        }, (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if (err.statusCode !== 401) {
            this.exit('error');
          }
        }
      )
  }

  exit(message: string) {
    this.dialogRef.close(message);
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { StepsService } from 'src/app/services/steps/steps.service';

@Component({
  selector: 'app-create-data',
  templateUrl: './create-data.component.html',
  styleUrls: ['./create-data.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class CreateDataComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  stepId = "";
  metadataId = "";
  id = "";
  name = "";
  isHidden = "";
  checkout = "";
  options: string[] = [this.loc.common.no, this.loc.common.yes];
  isStepMetadata = true;


  constructor(
    public loc: LocalizationService,
    public dialogRef: MatDialogRef<CreateDataComponent>,
    private formBuilder: UntypedFormBuilder,
    private stepsService: StepsService,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit(): void {
    this.stepId = this.data.stepId;
    this.metadataId = this.data.metadataId;
    this.isStepMetadata = this.data.isStepMetadata;
    this.firstFormGroup = this.formBuilder.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      isHidden: ['', Validators.required],
      checkout: ['', Validators.required],
    });
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

  next(stepper: MatStepper): void {
    this.id = this.firstFormGroup.get('id')!!.value;
    this.name = this.firstFormGroup.get('name')!!.value;
    this.isHidden = (this.firstFormGroup.get('isHidden')!!.value === this.loc.common.yes)?"1":"0";
    this.checkout = (this.firstFormGroup.get('checkout')!!.value===this.loc.common.yes)?"1":"0";
    stepper.next();
  }

  create(){
    if(this.isStepMetadata){
      this.stepsService.createStepMetadataKeyValue(this.stepId, this.metadataId, this.id, this.name, this.isHidden, this.checkout).subscribe(
        (res: BaseResponse) => {
          setTimeout(() => {
            if(res.statusRS?.code === "0"){
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('create', this.loc.data, 'f')
              );
              this.exit('created');
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('create', this.loc.data)
              );
              this.exit('error');
            }
            this.loading = false;
          }, 200);
        }, (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if (err.statusCode !== 401) {
            this.loading = false;
            console.log(err)
            this.exit('error');
          }
        }
      )
    } else {
      let data = {
        key: this.id,
        keyDesc: this.name,
        isHidden: this.isHidden, 
        isHiddenCheckout: this.checkout
      }
      this.exit(JSON.stringify(data));
    }

  }

}

<div class="general-operations-div">
  <!-- <div style="display: flex;">
      <button mat-icon-button class="back" (click)="goBack()"  >
          <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>

  <div *ngIf="loading" style="
  width: 200px;
  position: absolute;
  margin-left: 300px;
  margin-top: 98px;
">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
    background: 'no-repeat rgb(227 228 230 / 33%)',
    height: '60px',
    width: '60px'
  }"></ngx-skeleton-loader>
  </div>

  <div *ngIf="loading" style="
  width: 200px;
  position: absolute;
  margin-left: 390px;
  margin-top: 108px;
">
      <ngx-skeleton-loader count="1" [theme]="{
    background: 'no-repeat rgb(227 228 230 / 33%)',
    height: '25px',
    width: '120px'
  }"></ngx-skeleton-loader>
  </div>

  <div *ngIf="loading" style="
  width: 200px;
  position: absolute;
  margin-left: 390px;
  margin-top: 143px;
">
      <ngx-skeleton-loader count="1" [theme]="{
    background: 'no-repeat rgb(227 228 230 / 33%)',
    height: '15px',
    width: '100px'
  }"></ngx-skeleton-loader>
  </div>
  
</div> -->
<div style="display: flex; width:100% ;justify-content:space-between;">
    <div class="upper-text-bank-details" >
    <button mat-icon-button class="back" (click)="goBack()"   >
      <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
    </button>
    <button mat-fab class="page-icon-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon">account_balance</mat-icon>
    </button>
    <ng-container *ngIf="!loading">
      <div class="title-container">
        <label class="title-mid-text">
          {{ bank?.bankName }}</label>
        <label class="id-text">
            {{ bank?.bankId }}</label>
      </div>
    </ng-container>
    </div>

    <div class="general-operations-buttons-div" *ngIf="!loading" style="display: flex;flex-direction: row;">
        <button mat-fab class="button green-color" (click)="edit()" i18n-matTooltip [matTooltip]="loc.attributes.edit">
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon margin-icon"> edit </mat-icon>
        </button>
      
        <button mat-fab class="button green-color" (click)="delete()" i18n-matTooltip [matTooltip]="loc.attributes.edit">
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon margin-icon"> delete </mat-icon>
        </button>
      </div>
    
    <div *ngIf="loading" class="title-container">
      <ngx-skeleton-loader count="1" [theme]="{
          background: 'no-repeat rgb(227 228 230 / 33%)',
          height: '20px',
          width: '150px'
        }"></ngx-skeleton-loader>
    </div>
  
  </div>
  
</div>
<div class="stacked-component" style="overflow-x: auto;">
<mat-card class="details-card">
  <div class="flex-container" >
      <div class="flex-container-vertical">
          <div>
              <label class="card-header" i18n>{{loc.detailsHeaders.bankId}}</label>
          </div>
          <div>
              <label *ngIf="!loading" class="card-value">{{bank?.bankId}}</label>
              <ng-container *ngIf="loading">
                  <ngx-skeleton-loader count="1" [theme]="{
                              background: 'no-repeat rgb(227 228 230 / 33%)',
                              height: '20px',
                              width: '100px'
                            }">
                  </ngx-skeleton-loader>
              </ng-container>
          </div>
      </div>
      <div class="flex-container-vertical">
          <div>
              <label class="card-header" i18n>{{loc.detailsHeaders.bankName}}</label>
          </div>
          <div>
              <label *ngIf="!loading" class="card-value">{{bank?.bankName}}</label>
              <ng-container *ngIf="loading">
                  <ngx-skeleton-loader count="1" [theme]="{
                              background: 'no-repeat rgb(227 228 230 / 33%)',
                              height: '20px',
                              width: '100px'
                            }">
                  </ngx-skeleton-loader>
              </ng-container>
          </div>
      </div>
      <div class="flex-container-vertical">
          <div>
              <label class="card-header" i18n>{{loc.detailsHeaders.bankAddress}}</label>
          </div>
          <div>
              <label *ngIf="!loading" class="card-value" >{{emptyField(bank?.bankAddress)}}</label>
              <ng-container *ngIf="loading">
                  <ngx-skeleton-loader count="1" [theme]="{
                              background: 'no-repeat rgb(227 228 230 / 33%)',
                              height: '20px',
                              width: '100px'
                            }">
                  </ngx-skeleton-loader>
              </ng-container>
          </div>
      </div>
      <div class="flex-container-vertical">
          <div>
              <label class="card-header" i18n>{{loc.detailsHeaders.abaNumber}}</label>
          </div>
          <div>
              <label *ngIf="!loading" class="card-value">{{emptyField(bank?.abaNumber)}}</label>
              <ng-container *ngIf="loading">
                  <ngx-skeleton-loader count="1" [theme]="{
                              background: 'no-repeat rgb(227 228 230 / 33%)',
                              height: '20px',
                              width: '100px'
                            }">
                  </ngx-skeleton-loader>
              </ng-container>
          </div>
      </div>
      <div class="flex-container-vertical">
          <div>
              <label class="card-header" i18n>{{loc.detailsHeaders.swiftCode}}</label>
          </div>
          <div>
              <div class="card-value" *ngIf="!loading"
                  [ngStyle]="{'color': 'green'}">
                  </div>
                <label *ngIf="!loading" class="card-value">{{bank?.swiftCode}}</label>
              <ng-container *ngIf="loading">
                  <ngx-skeleton-loader count="1" [theme]="{
                              background: 'no-repeat rgb(227 228 230 / 33%)',
                              height: '20px',
                              width: '100px'
                            }">
                  </ngx-skeleton-loader>
              </ng-container>
          </div>
      </div>
      <div class="flex-container-vertical">
          <div>
              <label class="card-header" i18n>{{loc.detailsHeaders.country}}</label>
          </div>
          <div>
              <label *ngIf="!loading" class="card-value">{{bank?.bankCountry}}</label>
              <ng-container *ngIf="loading">
                  <ngx-skeleton-loader count="1" [theme]="{
                              background: 'no-repeat rgb(227 228 230 / 33%)',
                              height: '20px',
                              width: '100px'
                            }">
                  </ngx-skeleton-loader>
              </ng-container>
          </div>
      </div>
      <div class="flex-container-vertical">
          <div>
              <label class="card-header" i18n>{{loc.detailsHeaders.creationDate}}</label>
          </div>
          <div>
              <label *ngIf="!loading" class="card-value">{{formatDate(bank?.creationDate)}}</label>
              <ng-container *ngIf="loading">
                  <ngx-skeleton-loader count="1" [theme]="{
                              background: 'no-repeat rgb(227 228 230 / 33%)',
                              height: '20px',
                              width: '100px'
                            }">
                  </ngx-skeleton-loader>
              </ng-container>
          </div>
      </div>
      <div class="flex-container-vertical">
          <div>
              <label class="card-header" > {{loc.detailsHeaders.updateDate}}</label>
          </div>
          <div>
              <label *ngIf="!loading" class="card-value">{{formatDate(bank?.lastUpdate)}}</label>
              <ng-container *ngIf="loading">
                  <ngx-skeleton-loader count="1" [theme]="{
                              background: 'no-repeat rgb(227 228 230 / 33%)',
                              height: '20px',
                              width: '100px'
                            }">
                  </ngx-skeleton-loader>
              </ng-container>
          </div>
      </div>
      
  </div>
</mat-card>



      </div>

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceTemplateAgentType } from 'src/app/models/agents/service-template-agent-type.model';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { ImageUploaderService } from 'src/app/services/image-uploader/image-uploader.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { UsersService } from 'src/app/services/users/users.service';
import { environment } from 'src/environments/environment';

declare var require: any;
const sha1 = require('sha1');

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.css'],
  host: {
    class: "confirmation-dialog-component"
  }

})
export class EditTemplateComponent implements OnInit {
  loading = false;
  allowed = false;
  image?: string;
  selectedFile: File = new File([], '');
  firstFormGroup!: UntypedFormGroup;
  _status: string = ''
  cloudinary = {
    config: environment.cloudinary,
  };

  template: ServiceTemplateAgentType = {};

  constructor(
    public loc: LocalizationService,
    public dialogRef: MatDialogRef<EditTemplateComponent>,
    private imageUploaderService: ImageUploaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private userService: UsersService,
    private snackbarService: SnackbarService,

  ) { }

  ngOnInit(): void {
    this.allowed = this.data.allowed;
    this.firstFormGroup = this.formBuilder.group({
      description: ['', Validators.required],
      status: ['', Validators.required],
    });
    if(this.data.status == 1){
      this._status = 'ACTIVE'
    } else {
      this._status = 'INACTIVE';
    }
    this.firstFormGroup.setValue({
      description: this.data.description,
      status: this._status,
    })
    this.image = this.data.imageId;
    this.template.imageId = this.data.imageId;
  }

  onFileSelect($event: any): void {
    this.selectedFile = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = (_event) => {
      this.image = reader.result?.toString() || '';
    };
  }

  saveImg(){;
    this.loading = true;
    let id = this.data.id;
    if (this.selectedFile.name !== '') {
      const data = new FormData();
      const timestamp = Math.floor(Date.now() / 1000);
      const signature = sha1(
        'public_id=' + id
        +'&timestamp=' +
          timestamp.toString() +
          '&upload_preset=' +
          this.cloudinary.config.upload_preset +
          this.cloudinary.config.api_secret
      );

      data.append('file', this.selectedFile);
      data.append('upload_preset', this.cloudinary.config.upload_preset);
      data.append('cloud_name', this.cloudinary.config.cloud_name);
      data.append('api_key', this.cloudinary.config.api_key);
      data.append('api_secret', this.cloudinary.config.api_secret);
      data.append('secure', this.cloudinary.config.secure.toString());
      if (
        id !== undefined
      ) {
        data.append(
          'public_id',
          id
        );
      }
      data.append('signature', signature);
      data.append('timestamp', timestamp.toString());
      this.loading = false;
      this.imageUploaderService.uploadImage(data).subscribe((response) => {
        if (
          response &&
          id !== undefined
        ) {
          this.template.imageId = response.secure_url;
        }
      });
    } else {
      this.template.imageId = this.data.imageId;
    }
    setTimeout(() => {
      this.editTemplate();
    }, 2000);

  }

  editTemplate(){
    this.loading = true;
    let state = this.firstFormGroup.get('status')!!.value;
    if(state === 'ACTIVE'){
      this.template.status = '1';
    } else {
      this.template.status = '0';
    }
    this.template.description = this.firstFormGroup.get('description')!!.value;
    this.template.agentType = this.data.agentType;
    this.template.templateId = this.data.id;
    let editTemplate = this.allowed?this.userService.editServiceTemplateAllowed(this.template):this.userService.editServiceTemplate(this.template);
    editTemplate.subscribe({
     next: (res: BaseResponse) => {
        setTimeout(() => {
          this.loading = false;
          if (res.statusRS?.code === '0') {
            this.exit('updated');
          } else {
            this.exit(res.statusRS?.code as string);
          }
        }, 200);
      },
      error: (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        console.log(err);
        if (err.status !== 401) {
          this.exit('error');
        }
      }
  })
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }


}

<ng-container *ngIf="!loading">
  <ng-container *ngIf="nonWorkingDaysDataSource.data.length > 0">
    <form class="filter-wrapper">
      <mat-form-field appearance="fill" class="large-filter">
        <mat-label i18n>{{ loc.filters.month }}</mat-label>
        <mat-icon matSuffix style="font-size: 20px; color: grey">
          search
        </mat-icon>
        <input
          type="text"
          aria-label="Number"
          matInput
          [formControl]="myControl"
          [matAutocomplete]="autoMonth"
          (keyup)="addFilter($event, 'monthName')"
        />
        <mat-autocomplete #autoMonth="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredNames | async"
            [value]="option"
            (click)="addFilter(option, 'monthName')"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" class="large-filter">
        <mat-label i18n>{{ loc.filters.day }}</mat-label>
        <mat-icon matSuffix style="font-size: 20px; color: grey">
          search
        </mat-icon>
        <input
          type="text"
          aria-label="Number"
          matInput
          [formControl]="myControlDayNumber"
          [matAutocomplete]="autoDay"
          (keyup)="addFilter($event, 'dayNumber')"
        />
        <mat-autocomplete #autoDay="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredNumbers | async"
            [value]="option"
            (click)="addFilter(option, 'dayNumber')"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" class="large-filter">
        <mat-label i18n>{{ loc.filters.description }}</mat-label>
        <mat-icon matSuffix style="font-size: 20px; color: grey">
          search
        </mat-icon>
        <input
          type="text"
          aria-label="Number"
          matInput
          [formControl]="myControlDescription"
          [matAutocomplete]="autoDescription"
          (keyup)="addFilter($event, 'description')"
        />
        <mat-autocomplete #autoDescription="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredDescriptions | async"
            [value]="option"
            (click)="addFilter(option, 'monthName')"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
    <div [class]="tableStyle">
      <mat-card style="padding: 0px; border-radius: 10px">
        <mat-card
          style="overflow-y: auto; padding: 0px; box-shadow: none !important"
        >
          <table
            mat-table
            scrollY="true"
            [dataSource]="nonWorkingDaysDataSource"
            matSort
            class="table"
          >
            <ng-container matColumnDef="monthName">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="hide-arrow"
                i18n
              >
                {{ this.loc.tableHeaders.month }}
              </th>
              <td mat-cell *matCellDef="let element" class="bold-table-item">
                {{ element.monthName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="dayNumber">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="hide-arrow"
                i18n
              >
                {{ this.loc.tableHeaders.day }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dayNumber }}
              </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="hide-arrow"
                i18n
              >
                {{ this.loc.tableHeaders.description }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.description }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th
                style="text-align: center"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                i18n
              >
                {{ this.loc.tableHeaders.status }}
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-icon
                  [style.color]="
                    element.status == '1'
                      ? 'var(--green-color)'
                      : 'var(--menu-item-grey)'
                  "
                  class="menu-item-icon list-status-icon"
                  style="vertical-align: bottom"
                  >check_circle</mat-icon
                >
              </td>
              <td mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th
                mat-header-cell
                *matHeaderCellDef
                style="text-align: center"
                i18n
              >
                {{ this.loc.tableHeaders.actions }}
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: center">
                <button
                  mat-icon-button
                  (click)="edit(element)"
                  i18n-matTooltip
                  [matTooltip]="loc.nonWorkingDays.edit"
                >
                  <mat-icon fontSet="material-icons-outlined" class="black-icon"
                    >edit</mat-icon
                  >
                </button>

                <button
                  mat-icon-button
                  (click)="delete(element)"
                  i18n-matTooltip
                  [matTooltip]="loc.nonWorkingDays.delete"
                >
                  <mat-icon fontSet="material-icons-outlined" class="black-icon"
                    >delete</mat-icon
                  >
                </button>
              </td>
            </ng-container>

            <ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr
                class="row"
                mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></tr>
            </ng-container>
          </table>
        </mat-card>
        <button mat-fab (click)="create()" class="add-button">
          <mat-icon
            class="action-button-icon"
            [matTooltip]="loc.nonWorkingDays.create"
            >add</mat-icon
          >
        </button>
      </mat-card>
    </div>
    <div class="paginator-wrapper">
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        (page)="pageEvents($event)"
      >
      </mat-paginator>
      <ng-container *ngIf="nonWorkingDaysDataSource.data.length === 1">
        <label class="count" i18n>
          {{ nonWorkingDaysDataSource.data.length }}
          {{ loc.nonWorkingDays.singular.toLowerCase() }}
        </label>
      </ng-container>
      <ng-container *ngIf="nonWorkingDaysDataSource.data.length > 1">
        <label class="count" i18n>
          {{ nonWorkingDaysDataSource.data.length }}
          {{ loc.nonWorkingDays.plural.toLowerCase() }}
        </label>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="nonWorkingDaysDataSource.data.length === 0">
    <div [class]="tableStyle">
      <div class="empty-list">
        <div>
          <img
            class="empty-img"
            src="assets/img/listado-calendario-posteos.png"
          />
        </div>
        <div class="empty-lbl">
          <label i18n>
            {{ loc.nonWorkingDays.empty }}
          </label>
        </div>
        <button mat-flat-button (click)="create()" class="add-button-empty">
          {{ loc.common.create }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="loading">
  <form class="filter-wrapper">
    <mat-form-field appearance="fill" class="large-filter">
      <mat-label i18n>{{ loc.filters.id }}</mat-label>
      <mat-icon matSuffix style="font-size: 20px; color: grey">
        search
      </mat-icon>
      <input
        type="text"
        aria-label="Number"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="autoMonth"
        (keyup)="addFilter($event, 'monthName')"
      />
      <mat-autocomplete #autoMonth="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredNames | async"
          [value]="option"
          (click)="addFilter(option, 'monthName')"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <div [class]="tableStyle">
    <mat-card style="padding: 0px; border-radius: 10px">
      <mat-card
        style="
          min-height: 300px;
          overflow-y: auto;
          padding: 0px;
          box-shadow: none !important;
        "
      >
        <table
          mat-table
          scrollY="true"
          [dataSource]="loadingDataSource"
          matSort
          class="table"
        >
          <ng-container matColumnDef="monthName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="hide-arrow"
              i18n
            >
              {{ loc.tableHeaders.month }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  background: 'no-repeat rgb(227 228 230 / 33%)',
                  height: '15px',
                  width: '95%'
                }"
              >
              </ngx-skeleton-loader>
            </td>
          </ng-container>

          <ng-container matColumnDef="dayNumber">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="hide-arrow"
              i18n
            >
              {{ loc.tableHeaders.day }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  background: 'no-repeat rgb(227 228 230 / 33%)',
                  height: '15px',
                  width: '95%'
                }"
              >
              </ngx-skeleton-loader>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="hide-arrow"
              i18n
            >
              {{ loc.tableHeaders.description }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  background: 'no-repeat rgb(227 228 230 / 33%)',
                  height: '15px',
                  width: '95%'
                }"
              >
              </ngx-skeleton-loader>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
              {{ this.loc.tableHeaders.status }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  background: 'no-repeat rgb(227 228 230 / 33%)',
                  height: '15px',
                  width: '95%'
                }"
              >
              </ngx-skeleton-loader>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="hide-arrow"
              i18n
            >
              {{ loc.tableHeaders.actions }}
            </th>
            <td mat-cell *matCellDef="let element">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  background: 'no-repeat rgb(227 228 230 / 33%)',
                  height: '15px',
                  width: '95%'
                }"
              >
              </ngx-skeleton-loader>
            </td>
          </ng-container>
          <ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              class="row"
              mat-row
              *matRowDef="let row; columns: displayedColumns"
            ></tr>
          </ng-container>
        </table>
      </mat-card>
    </mat-card>
  </div>
  <div class="paginator-wrapper-loader">
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        background: 'no-repeat rgb(227 228 230 / 33%)',
        height: '70%',
        width: '250px'
      }"
      style="
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      "
    >
    </ngx-skeleton-loader>

    <ngx-skeleton-loader
      count="1"
      [theme]="{
        background: 'no-repeat rgb(227 228 230 / 33%)',
        height: '70%',
        width: '250px'
      }"
      style="
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
      "
    >
    </ngx-skeleton-loader>
  </div>
</ng-container>

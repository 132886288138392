import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { BaseTableStyle, Column, TableFilter, TableStyle } from '../table/table.model';

@Component({
  selector: 'app-loading-table',
  templateUrl: './loading-table.component.html',
  styleUrls: ['./loading-table.component.css'],
  host: { class: 'component' }
})
export class LoadingTableComponent implements OnInit, OnChanges {

  loadingDataSource = new MatTableDataSource<string>();

  @Input() style: TableStyle = {
    row: {
      "height": "50px",
      "font-size": "14px",
    },
    header: {
      "height": "50px",
      "font-size": "14px",
    }
  }

  baseStyle: BaseTableStyle = {
    row: {
      "height": "50px",
      "font-size": "14px",
    },
    header: {
      "height": "75px",
      "font-size": "14px",
    },
    cell: {
      "padding-top": "5px !important",
      "padding-bottom": "5px !important"
    }
  }

  @Input() title: string = "default"

  @Input('tableClass')
  tableStyle = 'tableResponsive';

  @Input()
  columns: Column[] = []

  @Input()
  filters: TableFilter[] = []

  displayedColumns: string[] = []

  rowStyle = '';
  headerStyle = '';
  cellStyle = '';

  constructor(
    public loc: LocalizationService
  ) { }

  ngOnInit(): void {
    this.loadingDataSource.data = ['', '', '', '', ''];
    this.displayedColumns = this.columns.map((col) => col.key);
    this.setStyles();
  }

  ngOnChanges(): void {
    this.displayedColumns = this.columns.map((col) => col.key);
  }


  setStyles() {
    this.rowStyle = this.style.row ? this.getStyleString(this.style.row) : this.getStyleString(this.baseStyle.row);
    this.headerStyle = this.style.header ? this.getStyleString(this.style.header) : this.getStyleString(this.baseStyle.header);
    this.cellStyle = this.style.cell ? this.getStyleString(this.style.cell) : this.getStyleString(this.baseStyle.cell);
  }

  getStyleString(style: any): string {
    return Object.keys(style).map(key => `${key}: ${style[key]}`).join(';');
  }

}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { ActivatedRoute } from '@angular/router';
import { AttributeAllowed } from 'src/app/models/attributes/attributeAllowed';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { UpdateAttributesAllowedComponent } from '../update-attributes-allowed/update-attributes-allowed.component';



@Component({
  selector: 'app-attribute-group-allowed-details',
  templateUrl: './attribute-group-allowed-details.component.html',
  styleUrls: ['./attribute-group-allowed-details.component.css'],
  host:{
    class: 'component'
  }
})
export class AttributeGroupAllowedDetailsComponent implements OnInit {

  groupId = '';
  categoryId = '';
  groupDescription = '';
  serviceTemplateId = '';
  operationId = '';
  loading = false;
  attributesAllowed: AttributeAllowed[] = [];
  constructor(
    public location: Location,
    public loc : LocalizationService,
    public route: ActivatedRoute,
    public attributesService: AttributesService,
    private logoutDialogService: LogoutDialogService,
    public snackbarService: SnackbarService,
    private dialogService: DialogService,

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.groupId = params['groupId'];
      this.categoryId = params['categoryId'];
      this.groupDescription = params['groupDescription'];
      this.serviceTemplateId = params['serviceTemplateId'];
      this.operationId = params['operationId'];
    });
  }

  goBack(): void {
    this.location.back();
    let state = JSON.parse(localStorage.getItem('state')!);
    state.mediums = {};
    localStorage.setItem('state', JSON.stringify(state));
  }

  saveChanges() {
    if(this.attributesAllowed.length > 0){
      const dialogRef = this.dialogService.openDialog(UpdateAttributesAllowedComponent, {},"x-small");
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'update') {
            this.attributesService.updateAttributeAllowed(this.attributesAllowed, this.groupId).subscribe(
              {
                next: (res: BaseResponse) => {
                      if(res.statusRS?.code == '0'){
                        this.snackbarService.openSuccessSnackbarWithNoReload(
                          this.loc.successMessage("update",this.loc.attributes)
                        );
                        this.loading = false;
                      } else {
                        this.snackbarService.openErrorSnackbar(
                          this.loc.genericError("update", this.loc.attributes)
                        );
                      }
                }, error: (err: any) => {
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError("update", this.loc.attributes)
                  );
                }
              }
            )
          }
        });
    }
  }

  refresh(attributesUpdated: any){
    this.attributesAllowed = attributesUpdated;
  }
}

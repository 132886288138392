import { Component, HostListener, Input, OnInit } from '@angular/core';
import { LocalizationService } from '../services/localization/localization.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.css'],
  host: {
    class:'component'
  }
})
export class IntegrationsComponent implements OnInit {
  constructor(public loc: LocalizationService) {}
  selectedIndex = 0;


  ngOnInit(): void {
    let state = JSON.parse(localStorage.getItem('state')!);
    this.selectedIndex = state?.integrations?.tab || 0

  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state.integrations = {
      tab: this.selectedIndex
    };
    localStorage.setItem('state', JSON.stringify(state));
  }

}

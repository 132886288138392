import { Component, OnInit } from '@angular/core';
import { AccountIds } from 'src/app/models/accountIds/account-ids.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateAccountIdComponent } from '../create-account-id/create-account-id.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-account-ids',
  templateUrl: './account-ids.component.html',
  styleUrls: ['./account-ids.component.css'],
  host: { class: 'component' },
})

export class AccountIdsComponent implements OnInit {
  loading: boolean = true;
  data : TableData[] = []
  constructor(
    public loc: LocalizationService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private accountsService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loadAccountIds();
  }
  loadAccountIds() {
    this.loading = true;
    this.accountsService.getAccountIds().subscribe({
      next: (data: BaseResponse<AccountIds[]>) => {
        this.data = data.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (_err: any) => {},
    });
  }

  emptyState:EmptyState = {
    image: 'assets/img/identificador-cuentas.png',
    message: this.loc.accountIds.empty,
    buttons: [
      {
        text: this.loc.accountIds.create,
        action: () => this.create(),
      }
    ]
  }

  columns: Column[] = [
    {
     key: 'id',
     title: this.loc.tableHeaders.id,
     sortable: true, 
    },
    {
      key: 'name',
      title: this.loc.tableHeaders.name,
      sortable: true,
    },
    {
      key: 'externalId',
      title: this.loc.tableHeaders.externalId,
      sortable: true,
    }
  ]

  transformers = {}

  paginatorInfo = {
    singular: this.loc.accountIds.singular,
    plural: this.loc.accountIds.plural,
  }


  filters:TableFilter[] = [
    {
      key: 'id',
      label: this.loc.tableHeaders.id,
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      key: 'name',
      label: this.loc.tableHeaders.name,
      type: 'text',
      placeholder: this.loc.tableHeaders.name,
    }
  ]


  actions: Action[] = [
    {
      icon: 'edit',
      tooltip: this.loc.accountIds.edit,
      action: (row: any) => this.editAccountId(row),
    },
    {
      icon: 'delete',
      tooltip: this.loc.accountIds.delete,
      action: (row: any) => this.deleteAccountId(row),
    }
  ]



  createButton:CreateButton = {
    action: () => this.create(),
    tooltip: this.loc.accountIds.create,
  }








  // CRUD
  // OPERATIONS

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateAccountIdComponent,
      this.data.map((item) => item.id),
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.accountIds, 'femenine')
        );
        this.loadAccountIds();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.accountIds)
        );
        this.loadAccountIds();
      }
      if (result === 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.accountIdAlreadyExists
        );
      }
    });
  }

  editAccountIdAction(row: any, result: any) {
    let newName = result.find((x: any) => x.label === this.loc.tableHeaders.name).value
    let newExternalId = result.find((x: any) => x.label === this.loc.tableHeaders.externalId).value
    let newAccount = {...row, name: newName, externalId: newExternalId}
    this.accountsService.editAccountId(newAccount).subscribe({
      next: (_response: BaseResponse<AccountIds>) => {
        this.loading = false;
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.accountIds, 'femenine')
        );
        this.loadAccountIds();
      },
    }),
      (_error: any) => {
        this.loading = false;
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.accountIds)
        );
      };
  }

  editAccountId(row: AccountIds) {
    let data: EditModalData = {
      title: this.loc.accountIds.edit,
      icon: 'domain',
      fields: [{label: this.loc.tableHeaders.name, value: row.name, errorMsg: this.loc.accountIds.errorName, isCombo: false, validators: [Validators.required]},
        {label: this.loc.tableHeaders.externalId, value: row.externalId, isCombo: false}]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAccountIdAction(row, result)
      }
    });
  }

  deleteAccountId(row: AccountIds) {
    let data: DeleteModalData = {
      title: this.loc.accountIds.delete,
      icon: 'domain',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.accountIds),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.accountsService.deleteAccountId(row.id).subscribe({
          next: (res: BaseResponse<AccountIds>) => {
            this.loading = true;
            switch (res.statusRS?.code) {
              case '0':
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage(
                    'delete',
                    this.loc.accountIds,
                    'femenine'
                  )
                );
                this.loadAccountIds();
                break;
              default:
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.accountIds)
                );
                break;
            }
            this.loading = false;
          },
          error: (err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.accountIds)
            );
          },
        });
      }
    });
  }

}

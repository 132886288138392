import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class PostingSchemesService {
  readonly baseURL = environment.apiUrl; 
  
  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  getPostingSchemes(): Observable<any> {
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/posting-scheme/x/list-posting-schemes'
    );
  }

  deletePostingScheme(postingSchemeId: string): Observable<any> {
    return this.http.delete(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting-scheme/${postingSchemeId}/delete-posting-scheme`
    );
  }

  editPostingScheme(postingSchemeId: string, newDescription: string): Observable<any> {
    const baseRequest: BaseRequest = new BaseRequest();
    let message = {
      description: newDescription
    }
    baseRequest.messageRQ = message;
    return this.http.put(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting-scheme/${postingSchemeId}/edit-posting-scheme`,
        baseRequest
    );
  }

  getPostings(): Observable<any> {
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/list-postings'
    );
  }

  getDisaggregations(): Observable<any> {
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregations/x/list-disaggregations'
    );
  }

  editPosting(postingId: string, newDescription: string): Observable<any> {
    const baseRequest: BaseRequest = new BaseRequest();
    let message = {
      id: postingId,
      description: newDescription
    }
    baseRequest.messageRQ = message;
    return this.http.put(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/update-posting`,
        baseRequest
    );
  }

  deletePosting(postingId: string): Observable<any> {
    return this.http.delete(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/${postingId}/delete-posting`
    );
  }

  createPostingScheme(postingSchemeId: string, description: string, desagregationId: string, postingId: string){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      postingSchemeId, description, desagregationId, postingId
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/posting-scheme/x/create-posting-scheme', baseRequest
    );
  }

  createPosting(id: string, description: string){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id, description
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/create-posting', baseRequest
    );
  }

  editDisaggregation(id: string, newDescription: string): Observable<any> {
    const baseRequest: BaseRequest = new BaseRequest();
    let message = {
      id,
      description: newDescription
    }
    baseRequest.messageRQ = message;
    return this.http.put(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregation/X/update-disaggregation`,
        baseRequest
    );
  }

  deleteDisaggregation(id: string): Observable<any> {
    return this.http.delete(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregation/${id}/delete-disaggregation`
    );
  }

  createDisaggregation(id: string, description: string){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id, description
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregation/X/create-disaggregation', baseRequest
    );
  }

  getDisaggregationSteps(id: string): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregation/${id}/list-disaggregation-steps`
    );
  }

  getPostingSteps(id: string): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/${id}/list-posting-steps`
    );
  }
  
  getDisaggregationStepTemplates(): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregation/X/list-disaggregation-step-templates`
    );
  }

  getPostingStepTemplates(): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/list-step-templates`
    );
  }

  createDisaggregationSteps(message: {
    disaggregationId: string,
    steps: {
      ordinal: number,
      stepTemplateId: string,
      baseOrdinal: string
    }[]
  }){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = message;
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregation/X/create-disaggregation-step', baseRequest
    );
  }

  createPostingSteps(message: {
    postingId: string,
    steps: {
      ordinal: number,
      stepTemplateId: string,
      isReverse: string,
      group: string,
      errorGroup: string
    }[]
  }){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = message;
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/create-posting-steps', baseRequest
    );
  }

  getPostingGroups(): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/list-posting-groups`
    );
  }

  createPostingGroup(id: string, description: string){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id, description
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/create-posting-group', baseRequest
    );
  }

  editPostingStepTemplate(id: string, newDescription: string): Observable<any> {
    const baseRequest: BaseRequest = new BaseRequest();
    let message = {
      id,
      description: newDescription
    }
    baseRequest.messageRQ = message;
    return this.http.put(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/update-posting-step-template`,
        baseRequest
    );
  }

  deletePostingStepTemplate(id: string): Observable<any> {
    return this.http.delete(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/${id}/delete-posting-step-template`
    );
  }

  editDisaggregationStepTemplate(id: string, newDescription: string): Observable<any> {
    const baseRequest: BaseRequest = new BaseRequest();
    let message = {
      id,
      description: newDescription
    }
    baseRequest.messageRQ = message;
    return this.http.put(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregation/X/update-disaggregation-step-template`,
        baseRequest
    );
  }

  deleteDisaggregationStepTemplate(id: string): Observable<any> {
    return this.http.delete(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregation/${id}/delete-disaggregation-step-template`
    );
  }

  getSubjects(): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting-scheme/x/list-subjects`
    );
  }

  getSubjectSigns(): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting-scheme/x/list-subject-signs`
    );
  }

  getSigns(): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting-scheme/x/list-posting-signs`
    );
  }

  getSettlementModes(): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/settlement/X/list-settlement-modes`
    );
  }

  getOperations(): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/operation/X/list-operations`
    );
  }

  getSuboperations(): Observable<any> {
    return this.http.get(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/suboperation/x/list-suboperations`
    );
  }

  createDisaggregationStepTemplate(id: string, description: string, operation: string, suboperation: string, subject: string, settlementMode: string){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id, description, operation, suboperation, subject, settlementMode
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/disaggregation/X/create-disaggregation-step-template', baseRequest
    );
  }

  createPostingStepTemplate(id: string, description: string, operation: string, suboperation: string, subject: string, sign: string, isDeferredSettlement: number){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id, description, operation, suboperation, subject, sign, isDeferredSettlement
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/create-posting-step-template', baseRequest
    );
  }

  getPostingFlows(type: string): Observable<any>{
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
       type
      }
    };
    return this.http.get(
      this.baseURL + 
      'RSAdaptorFE/api/v3/own-channels/administratives/flow/0/list',
      HTTP_OPTIONS
    );
  }

  getPostingDetails(postingId: string, ordinal: number){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
       ordinal
      }
    };
    return this.http.get(
      this.baseURL + 
      `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/${postingId}/details`,
      HTTP_OPTIONS
    );
  }

  editPostingStepFlow(postingId: string, ordinal: number, flow: any ){
    const baseRequest: BaseRequest = new BaseRequest();
    let message = {
      postingId,
      ordinal,
      flowType: flow.flowType,
      newFlowId: flow.newFlowId,
      newFlowVersion: flow.newFlowVersion,
      newFlowExternalEntity: flow.newFlowExternalEntity
    }
    baseRequest.messageRQ = message;
    return this.http.put(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/posting-schemes/posting/x/update-posting-step-flow`,
        baseRequest
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { OperationGroup } from 'src/app/models/operation-group/operation-group.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { OperationGroupService } from 'src/app/services/operation-group/operation-group.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateOperationGroupComponent } from './create-operation-group/create-operation-group.component';
import { Action, Column, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';

@Component({
  selector: 'app-operation-groups',
  templateUrl: './operation-groups.component.html',
  styleUrls: ['./operation-groups.component.css'],
  host: {
    class: 'component',
  },
})
export class OperationGroupsComponent implements OnInit {
  loading: boolean = true;
  data: TableData[] = []

  constructor(
    public loc: LocalizationService,
    private operationGroupService: OperationGroupService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.loadOperationGroups();;
  }

  loadOperationGroups() {
    this.loading = true;
    this.operationGroupService.get().subscribe({
      next: (data: BaseResponse<OperationGroup[]>) => {
        this.data = data.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (_err: any) => {

      },
    });
  }


  emptyState: EmptyState = {
    image: 'assets/img/esquema-posteos.png',
    message: this.loc.operationGroups.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      }
    ]
  }

  columns : Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
      sortable: true,
    }
  ]


  paginatorInfo = this.loc.operationGroups;

  filters :TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      placeholder: this.loc.tableHeaders.id,
      type: 'text'
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      placeholder: this.loc.tableHeaders.description,
      type: 'text'
    }
  ]


  actions : Action[]  = [
    {
      icon: 'edit',
      action: (element: any) => this.edit(element),
      tooltip: this.loc.operationGroups.edit,
    },
    {
      icon: 'delete',
      action: (element: any) => this.delete(element),
      tooltip: this.loc.operationGroups.delete,
    }
  ]

  createButton = { 
    tooltip: this.loc.operationGroups.create,
    action: () => this.create()
  }


  //CRUD

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateOperationGroupComponent,
      null,
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      switch (result) {
        case 'created':
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage(
              'create',
              this.loc.operationGroups,
              'femenine'
            )
          );
          this.loadOperationGroups();
          break;
        case 'error':
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('create', this.loc.operationGroups)
          );
          this.loadOperationGroups();
          break;
        case 'duplicate':
          this.snackbarService.openErrorSnackbar(
            this.loc.errors.accountIdAlreadyExists
          );
          break;
      }
    });
  }

  edit(row: OperationGroup) {
    let data: EditModalData = {
      title: this.loc.operationGroups.edit,
      icon: 'signpost',
      fields: [
        { label: this.loc.tableHeaders.description, 
          value: row.description!!, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAccountId(row, result)
      }
    });
  }

  editAccountId(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
    this.operationGroupService.edit(row.id, newDescription).subscribe({
      next: (_response: BaseResponse<OperationGroup>) => {
        this.loading = false;
        if(_response.statusRS.code === '0'){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('update', this.loc.operationGroups)
          );
          this.loadOperationGroups();
        }else{
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.operationGroups)
          );
        }
      },
    }),
      (_error: any) => {
        this.loading = false;
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.operationGroups)
        );
      };
  }

  delete(row: OperationGroup) {
    let data: DeleteModalData = {
      title: this.loc.operationGroups.delete,
      icon: 'group_work',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.operationGroups),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.operationGroupService.delete(row.id).subscribe({
          next: (res: BaseResponse<OperationGroup>) => {
            this.loading = true;
            switch (res.statusRS?.code) {
              case '0':
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage(
                    'delete',
                    this.loc.operationGroups,
                    'm'
                  )
                );
                this.loading = false;
                this.loadOperationGroups();
                break;
              case '-91792':
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.operationGroupDoesNotExist
                );
                this.loading = false;
                this.loadOperationGroups();
                break;
              case '-91793':
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.operationGroupsHasOperations
                );
                this.loading = false;
                this.loadOperationGroups();
                break;
              default:
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.operationGroups)
                );
                this.loading = false;
                this.loadOperationGroups();
                break;
            }
          },
          error: (err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.operationGroups)
            );
          },
        });
      }
    });
  }

}

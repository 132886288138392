<div class="general-operations-div">
    <div style="display: flex; margin-right: 15px;align-items: center;">
        <button  mat-fab class="page-icon-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">notifications</mat-icon>
        </button>
            <label class="page-title"  i18n>{{loc.routes.notifications}}</label>
    </div>
</div>

<mat-tab-group
  class="tab-bar"
  [(selectedIndex)]="selectedIndex"
  (selectedTabChange)="onTabChange()"
  >
    <mat-tab  [label]="loc.tabNames.applications" i18n-label>
      <app-list-applications class="component"> </app-list-applications>
    </mat-tab>
    <mat-tab  [label]="loc.tabNames.platforms" i18n-label>
      <app-list-platforms class="component"> </app-list-platforms>
    </mat-tab>
    <mat-tab  [label]="loc.tabNames.entity" i18n-label>
      <app-list-entities class="component"> </app-list-entities>
    </mat-tab>
    <mat-tab  [label]="loc.tabNames.types" i18n-label>
      <app-list-types class="component"> </app-list-types>
    </mat-tab>
</mat-tab-group>

<!-- elegir label, action result, description-->
<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
</div>

<div class="title">
    <div class="inner-title-container">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">paid</mat-icon>
            <mat-icon class="action-button-icon-add">add</mat-icon>
        </button>
        <label class="dialog-title" i18n>{{loc.serviceModes.create}}</label>
    </div>
    <button class="exit-button" (click)="exit('not created')"  >
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>
<mat-horizontal-stepper [ngClass]="'three-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
[linear]="true" #stepper appStepper [step] = "stepper.selectedIndex" class="horizontal-stepper stepper">
  <ng-template matStepperIcon="done" let-index="index">
    <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
    <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">add_a_photo</mat-icon>
    <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 2">check_box</mat-icon>
  </ng-template>

  <ng-template matStepperIcon="edit" let-index="index">
    <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
    <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">add_a_photo</mat-icon>
    <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 2">check_box</mat-icon>    
  </ng-template>>

  <ng-template matStepperIcon="number" let-index="index">
    <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
    <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">add_a_photo</mat-icon>
    <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 2">check_box</mat-icon>
  </ng-template>




        <mat-step [stepControl]="firstFormGroup" [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
            <form [formGroup]="firstFormGroup" class="form-step">
                <div class="data-box">
                <div class="create-row">
                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.tableHeaders.id}}</mat-label>
                        <input matInput formControlName="id" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.tableHeaders.description}}</mat-label>
                        <input matInput formControlName="description" required>
                    </mat-form-field>

                    
                </div>
                <div class="create-row-align-start">

                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.extensionPoints.serviceTemplate}}</mat-label>
                        <mat-select formControlName="template"> 
                            <mat-option *ngFor="let template of templates" [value]="template" i18n>
                                {{template.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    
                </div>
                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperNext>
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>

        <mat-step [editable]="true">
            <ng-template matStepLabel i18n>{{ loc.stepTitle.style }}</ng-template>
            <div class="form-step">
              <div class="data-box">
                <div style="display: inline-block">
                  <div class="image-box">
                    <div>
                      <mat-icon *ngIf="!image" class="default-img">add_a_photo</mat-icon>
       
                      <img
                        *ngIf="image"
                        [src]="image"
                        alt="img_user"
                        class="image"
                        (error)="image = ''"
                      />
                    </div>
                  </div>
        
                  <button
                    mat-fab
                    class="image-button button green-color"
                    (click)="hiddenfileinput.click()"
                    i18n-matTooltip
                    [matTooltip]="loc.common.addPhoto"
                  >
                    <mat-icon fontSet="material-icons-outlined"> camera_alt </mat-icon>
                  </button>
        
                  <input
                    name="image"
                    type="file"
                    style="display: none"
                    accept="image/*"
                    (change)="onFileSelect($event)"
                    #hiddenfileinput
                  />
                </div>
              </div>
              <div class="dialog-nav-buttons">
                <button mat-fab class="button green-button" matStepperPrevious>
                  <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                </button>
                <button
                  mat-fab
                  class="button green-button"
                  matStepperNext
                 
                >
                  <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </div>
          </mat-step>
      

        <mat-step [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
            <div class="form-step">
                <div class="verify-large">
                    <div class="flex-container-vertical flex-container-vertical-modal">
                        <div>
                            <label class="card-name" i18n>{{loc.serviceModes.new}}</label>
                        </div>
                        
                            <div class="details-package-large details-flex">
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.id}}</div>
                                    <div class="card-value">{{firstFormGroup.get('id')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.description}}</div>
                                    <div class="card-value">{{firstFormGroup.get('description')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.serviceTemplate}}</div>
                                    <div class="card-value">{{firstFormGroup.get('template')?.value.description}}</div>
                                </div>
                                
                            </div>
                    </div>

                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious  >
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-fab class="button green-button" (click)="saveImage()"  >
                        <mat-icon class="stepper-item-icon" fontSet="material-icons-outlined">save</mat-icon>
                    </button>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>


import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { Observable } from 'rxjs';
import { BaseResponse } from '../../models/responses.model';
import { BranchOperation } from '../../models/branch/branchOperations.model';
import { BaseRequest } from '../../models/base/base-request.model';
import { BranchOperationRole } from 'src/app/models/branch/BranchOperationRole.model';

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  readonly rootURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getBranchOperations(): Observable<BaseResponse<BranchOperation[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get<BaseResponse<BranchOperation[]>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/branches/branch-operation/x/list',
      HTTP_OPTIONS
    );
  }

  getBranchOperationRoles(): Observable<BaseResponse<BranchOperationRole[]>> {
    return this.http.get<BaseResponse<BranchOperationRole[]>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/branches/operation-role/0/list-branch-operation-roles'
    );
  }

  activateBranchOperation(
    currentStatus: string,
    branchOperationId: string
  ): Observable<BaseResponse<BranchOperation>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };

    let urlDeactivate = `RSAdaptorFE/api/v1/paycorr/branches/branch-operation/${branchOperationId}/deactivate`;
    let urlActivate = `RSAdaptorFE/api/v1/paycorr/branches/branch-operation/${branchOperationId}/activate`;
    let url = currentStatus === 'ACTIVE' ? urlDeactivate : urlActivate;

    url = this.rootURL + url;
    return this.http.put<BaseResponse<BranchOperation>>(
      url,
      null,
      HTTP_OPTIONS
    );
  }

  createBranchOperation(
    branchOperation: BranchOperation
  ): Observable<BaseResponse<BranchOperation>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = branchOperation;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.post<BaseResponse<BranchOperation>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/branches/branch-operation/x/create',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  createBranchOperationRole(
    branchOperation: BranchOperationRole
  ): Observable<BaseResponse<any>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = branchOperation;
    return this.http.post<BaseResponse<any>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/branches/operation-role/x/create-branch-operation-role',
      baseRequest
    );
  }

  editBranchOperation(
    branchOperation: BranchOperation
  ): Observable<BaseResponse<BranchOperation>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { description: branchOperation.description };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };

    return this.http.put<BaseResponse<BranchOperation>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/branches/branch-operation/${branchOperation.operationId}/update`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  editBranchOperationRole(
    operationId: string,
    originUserType: string,
    destinationUserType: string,
    newOriginUserType: string,
    newDestinationUserType: string
  ): Observable<BaseResponse<any>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      operationId,
      originUserType,
      destinationUserType,
      newOriginUserType,
      newDestinationUserType,
    };

    return this.http.put<BaseResponse<any>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/branches/operation-role/x/update-branch-operation-role`,
      baseRequest
    );
  }

  deleteBranchOperation(id: string): Observable<BaseResponse<BranchOperation>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.delete<BaseResponse<BranchOperation>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/branches/branch-operation/${id}/delete`,
      HTTP_OPTIONS
    );
  }

  deleteBranchOperationRole(
    role: BranchOperationRole
  ): Observable<BaseResponse<any>> {
    return this.http.delete<BaseResponse<any>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/branches/operation-role/${role.operationId}/delete-branch-operation-role?originUserType=${role.originUserType}&destinationUserType=${role.destinationUserType}`
    );
  }
}

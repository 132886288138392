import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AccountIds } from 'src/app/models/accountIds/account-ids.model';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  readonly rootURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getAccountIds(): Observable<BaseResponse<AccountIds[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get<BaseResponse<AccountIds[]>>(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/accounts/account/x/list',
      HTTP_OPTIONS
    );
  }

  createAccountId(accountId: AccountIds): Observable<BaseResponse<AccountIds>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = accountId;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.post<BaseResponse<AccountIds>>(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/accounts/account/x/create',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  editAccountId(accountId: AccountIds): Observable<BaseResponse<AccountIds>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = accountId;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<AccountIds>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/accounts/account/${accountId.id}/update-account`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  deleteAccountId(id: string): Observable<BaseResponse<AccountIds>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.delete<BaseResponse<AccountIds>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/accounts/account/${id}/delete-account`,
      HTTP_OPTIONS
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { Posting } from 'src/app/models/posting-schemes/Posting.model';
import { Disaggregation } from 'src/app/models/posting-schemes/Disaggregation.model';
import { MatSelectChange } from '@angular/material/select';
import { DisaggregationStepTemplate } from 'src/app/models/posting-schemes/DisaggregationStepTemplate.model';
import { DisaggregationStep } from 'src/app/models/posting-schemes/DisaggregationStep.model';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-create-disaggregation-step',
  templateUrl: './create-disaggregation-step.component.html',
  styleUrls: ['./create-disaggregation-step.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateDisaggregationStepComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  stepTemplates: DisaggregationStepTemplate[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateDisaggregationStepComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private postingSchemesService: PostingSchemesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {

    this.loadDisaggregationStepTemplates();
    this.firstFormGroup = this._formBuilder.group({
      stepTemplate: ['', Validators.required],
      baseOrdinal: [''],
      subject: ['', Validators.required],
      operation: ['', Validators.required],
      suboperation: ['', Validators.required],
      settlementMode: ['', Validators.required],
    });

    this.firstFormGroup.get('subject')?.disable()
    this.firstFormGroup.get('operation')?.disable()
    this.firstFormGroup.get('suboperation')?.disable()
    this.firstFormGroup.get('settlementMode')?.disable()


  }

  loadDisaggregationStepTemplates(){
    //this.loading = true;
    this.postingSchemesService
    .getDisaggregationStepTemplates()
    .subscribe(
      (res: BaseResponse) => {
        let response = res.messageRS as any as any[];
        
        this.stepTemplates = response;
        //this.loading = true;
        
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  changeSelectionTemplate(event: MatSelectChange) {
    let stepTemplate = this.stepTemplates.find((temp) => temp.id == event.value.id)
    this.firstFormGroup.patchValue({
      subject: stepTemplate?.subjectDescription,
      operation: stepTemplate?.operationDescription,
      suboperation: stepTemplate?.suboperationDescription,
      settlementMode: stepTemplate?.settlementModeDescription
    })
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  next(stepper: MatStepper){
    let stepTemplateSelected = this.stepTemplates.find((temp) => temp.id == this.firstFormGroup.get('stepTemplate')?.value.id)
    let existsOpSubop = this.data.steps.some((step: DisaggregationStep) => step.operation == stepTemplateSelected?.operation && step.suboperation == stepTemplateSelected?.suboperation && step.subject == stepTemplateSelected?.subject)
    let validBaseOrdinal = true;
    let baseOrdinal = this.firstFormGroup.get('baseOrdinal')?.value
    if (baseOrdinal !== "" && baseOrdinal !== null){
      validBaseOrdinal = baseOrdinal >=1 && baseOrdinal <= this.data.steps.length
    }
    if(existsOpSubop){
      this.snackbarService.openErrorSnackbar(this.loc.errors.disaggregationLineAlreadyExists)
    } else if(!validBaseOrdinal){
      this.snackbarService.openErrorSnackbar(this.loc.errors.disaggregationBaseOrdinalIncorrect)
    } else {
      stepper.next();
    }
  }

  create() {
    this.loading = true;
    let newStep = {
      ordinal: this.data.steps.length + 1,
      stepTemplateId: this.firstFormGroup.get('stepTemplate')?.value.id,
      baseOrdinal: this.firstFormGroup.get('baseOrdinal')?.value || ""
    }
    this.data.steps =  this.data.steps.map((step: DisaggregationStep) => {
      return {
        ordinal: parseInt(step.ordinal!),
        stepTemplateId: step.stepTemplateId,
        baseOrdinal: step.baseOrdinal
      }
    })
    
    this.data.steps.push(newStep)
    this.loading = true;
    this.postingSchemesService.createDisaggregationSteps(this.data).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          this.loading = false;
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit(res.statusRS?.code as string);
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }

}

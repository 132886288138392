import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';


@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css']
})
export class PageTitleComponent implements OnInit {

  @Input()
  loading: boolean = false;

  @Input()
  withBackButton: boolean = false;

  @Input()
  icon: string = '';

  @Input()
  image: string = '';

  @Input()
  title: string = '';

  @Input()
  subtitle?: string;

  @Input()
  pageState: string = ''

  showIcon = false
  
  constructor(
    public location: Location,
  ) { }

  ngOnInit(): void {
    console.log(this.subtitle)
  }

  goBack(): void {
    this.location.back();
    if(this.pageState){
      let state = JSON.parse(localStorage.getItem('state')!);
      state[this.pageState] = {};
      localStorage.setItem('state', JSON.stringify(state));

    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { StepsService } from 'src/app/services/steps/steps.service';
import { Location } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Step } from 'src/app/models/step/step.model';

@Component({
  selector: 'app-map-output-metadata',
  templateUrl: './map-output-metadata.component.html',
  styleUrls: ['./map-output-metadata.component.css'],
  host: {
    class: 'component',
  }
})
export class MapOutputMetadataComponent implements OnInit {
  loading = false;
  stepId = '';
  apiId = '';
  outputId = '';
  stepName = '';
  apiName = '';

  constructor(
    public loc: LocalizationService,
    public location: Location,
    public route: ActivatedRoute,
    private stepsService: StepsService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.stepId = params['stepId'];
      this.outputId = params['outputId'];
      this.stepName = params['stepName'];
      this.apiName = params['apiName'];
      this.apiId = params['apiId'];      
    });

  }

  goBack(): void {
    this.location.back();
    let state = JSON.parse(localStorage.getItem('state')!);
    state.mediums = {};
    localStorage.setItem('state', JSON.stringify(state));
  }

}

<div class="form-step">
    <div class="verify-large">
        <div class="flex-container-vertical flex-container-vertical-modal verify-no-scroll">
            <ng-container *ngFor="let group of verifiedGroup">
                <div>
                    <label class="card-name" i18n>{{ capitalizeFirstLetter(group.title) }}</label>
                </div>
                
                <ng-container [ngSwitch]="group.type" class="verify">
                    <div *ngSwitchCase="'form'" class="details-package-large">
                        <div *ngIf="!group.descriptions" class="div-descriptions">
                            <div *ngFor="let element of group.elements" class="verify-vertical">
                                <div class="card-header" i18n>{{ capitalizeFirstLetter(element) }}</div>
                                <div class="card-value">
                                    {{ displayInfo(element) }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="group.descriptions" class="div-descriptions">
                            <div *ngFor="let descrip of group.descriptions; let i = index" class="verify-vertical">
                                <div class="card-header" i18n>{{ capitalizeFirstLetter(descrip) }}</div>
                                <div class="card-value">
                                    {{ displayInfo(group.elements[i]) }}
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div *ngSwitchCase="'style'" class="details-package-large">
                        <div *ngFor="let element of group.elements" style="padding-left: 25px; margin: 8px">
                            <div style="
                              height: 100px;
                              width: 100px;
                              border-radius: 100%;
                              background-color: #d9d9d9;
                              display: flex;
                              flex-direction: column;
                              justify-content: center;
                              align-items: center;
                            ">
                                <mat-icon fontSet="material-symbols-outlined" *ngIf="!verifiedItemsDictionary[element]"
                                    class="default-img-revision">add_a_photo</mat-icon>
                                <img *ngIf="verifiedItemsDictionary[element]" [src]="verifiedItemsDictionary[element]" alt="img_user" class="image"
                                    (error)="verifiedItemsDictionary[element] = ''" />
                            </div>
                        </div>
                    </div>
                </ng-container>

            </ng-container>
        </div>
    </div>

    <div class="dialog-nav-buttons verify-buttons">
        <button mat-fab class="button green-button" matStepperPrevious>
            <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-fab class="button green-button" (click)="save()">
            <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon">save</mat-icon>
        </button>
    </div>

</div>
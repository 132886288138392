<mat-horizontal-stepper [ngClass]="(show2Step ? 'two-step last-edited-step-' : 'three-step last-edited-step-' )+ stepper.selectedIndex" labelPosition="bottom"
    [linear]="true" #stepper appStepper [step]="stepper.selectedIndex" class="horizontal-stepper"
    *ngIf="!loading"
    >

    <ng-template matStepperIcon="done" let-index="index">
        <ng-container *ngFor="let step of steps">
            <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="step.idx == index">{{step.icon}}</mat-icon>
        </ng-container>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == steps.length">check_box</mat-icon>
    </ng-template>

    <ng-template  matStepperIcon="edit" let-index="index">
        <ng-container *ngFor="let step of steps">
            <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="step.idx == index">{{step.icon}}</mat-icon>
        </ng-container>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == steps.length">check_box</mat-icon>
    </ng-template>

    <ng-template  matStepperIcon="number" let-index="index">
        <ng-container *ngFor="let step of steps">
            <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="step.idx == index">{{step.icon}}</mat-icon>
        </ng-container>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == steps.length">check_box</mat-icon>
    </ng-template>   

    <mat-step *ngFor="let step of steps; let i=index;">
        <ng-container [ngSwitch]="step.type" >
            <ng-template matStepLabel i18n>{{ step.title.toUpperCase() }}</ng-template>
            <app-form-step *ngSwitchCase="'form'" [stepper]="stepper" [inStep]="step" (outputEvent)="loadFormValues($event)"></app-form-step>
            <app-style-step *ngSwitchCase="'style'" [inStep]="step" (outputEvent)="loadStyleValues($event)" (fileSelect)="fileSelected($event)"></app-style-step>
            <app-list-step *ngSwitchCase="'list'"></app-list-step>
        </ng-container>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel i18n>{{ loc.stepTitle.verify.toUpperCase() }}</ng-template>
        <app-verify-step [verifiedGroup] = "verify" [verifiedItemsDictionary] = "verifiedItemsDictionary" (saveEvent)="save()" ></app-verify-step>
    </mat-step>
    
</mat-horizontal-stepper>
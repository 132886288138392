<ng-container *ngIf="!loading">
    <ng-container *ngIf="fieldsDataSource.data.length > 0">

      <div [class]="tableStyle">
        <mat-card style="padding: 0px; border-radius: 10px;">
          <mat-card style="
              overflow-y: auto;
              padding: 0px;
              box-shadow: none !important;">
            <table class="table" mat-table scrollY="true" [dataSource]="fieldsDataSource" matSort id="devices-table">
              <ng-container matColumnDef="ordinal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.ordinal}}
                </th>
                <td mat-cell *matCellDef="let element" class="bold-table-item">
                  {{ element.ordinal }}
                </td>
              </ng-container>

              <ng-container matColumnDef="stepTemplateId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.stepTemplate}}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.stepTemplateDescription }}
                </td>
              </ng-container>

              <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.subject}}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.stepTemplateDescription }}
                </td>
              </ng-container>

              <ng-container matColumnDef="postingSign">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.postingSign}}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.postingSignDescription }}
                </td>
              </ng-container>

              <ng-container matColumnDef="operation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.operation}}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.operationDescription }}
                </td>
              </ng-container>

              <ng-container matColumnDef="suboperation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.suboperation}}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.suboperationDescription }}
                </td>
              </ng-container>

              <ng-container matColumnDef="group">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.group }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.groupDescription || "-" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="errorGroup">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.errorGroup}}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.errorGroupDescription }}
                </td>
              </ng-container>

              <ng-container matColumnDef="isReverse">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.reverse}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon [style.color]="element.isReverse === 'false' ? 'var(--menu-item-grey)' : 'var(--green-color)'"
                  class="menu-item-icon list-status-icon" style="vertical-align: bottom;">check_circle</mat-icon>

                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;" i18n>
                  {{loc.tableHeaders.actions}}
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <ng-container>
                    <button mat-icon-button (click)="moveDown(element)" i18n-matTooltip [matTooltip]="loc.common.moveDown" [disabled]="!showDownArrow(element)">
                        <mat-icon fontSet="material-icons-outlined" class="black-icon">arrow_downward</mat-icon>
                      </button>
                      
                      <button mat-icon-button (click)="moveUp(element)" i18n-matTooltip [matTooltip]="loc.common.moveUp" [disabled]="!showUpArrow(element)">
                        <mat-icon fontSet="material-icons-outlined" class="black-icon">arrow_upward</mat-icon>
                      </button>

                      <button mat-icon-button (click)="goToFlows(element)" i18n-matTooltip [matTooltip]="loc.flows.plural">
                        <mat-icon fontset="material-icons-outlined" class="black-icon">
                          more_horiz
                        </mat-icon>
                      </button>

                    <button mat-icon-button (click)="delete(element)" i18n-matTooltip [matTooltip]="loc.step.delete">
                      <mat-icon class="table-item-icon black-icon">
                        delete_outline
                      </mat-icon>
                    </button>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="fieldsDisplayedColumns; sticky: true"></tr>
              <tr class="row" mat-row *matRowDef="let row; columns: fieldsDisplayedColumns"
                (click)="onActionClicked(row)"></tr>
            </table>
          </mat-card>
          <button mat-fab class="add-button" (click)="create()" i18n-matTooltip [matTooltip]="loc.step.create">
            <mat-icon>add</mat-icon>
          </button>
        </mat-card>
      </div>
      <div class="paginator-wrapper">

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
        style="background: transparent;"
        [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="pageEvents($event)"
        ></mat-paginator>

      <ng-container *ngIf="fieldsDataSource.data.length === 1">
        <label class="count" i18n>
          {{ fieldsDataSource.data.length }} {{loc.step.singular.toLowerCase()}}
        </label>
      </ng-container>
      <ng-container *ngIf="fieldsDataSource.data.length > 1">
        <label class="count" i18n>
          {{ fieldsDataSource.data.length }} {{loc.step.plural.toLowerCase()}}
        </label>
      </ng-container>
    </div>
    </ng-container>

    <div class="empty-list"
        *ngIf="fieldsDataSource.data.length === 0">
        <div><img class="empty-img" src="assets/img/esquema-posteos.png" /></div>
        <div class="empty-lbl">
            <label i18n>
                {{loc.step.empty}}
            </label>
        </div>
        <button mat-flat-button class="add-button-empty" (click)="create()" i18n>Crear</button>
    </div>

  </ng-container>

  <ng-container *ngIf="loading">
    <div [class]="tableStyle" >
      <mat-card style="padding: 0px; border-radius: 10px;">
        <mat-card style="
        overflow-y: auto;
        padding: 0px;
        box-shadow: none !important;">
        <table class="table" mat-table scrollY="true" [dataSource]="loadingDataSource" matSort id="devices-table">
            <ng-container matColumnDef="ordinal">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                {{loc.tableHeaders.ordinal}}
              </th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader count="1" [theme]="{
                    background: 'no-repeat rgb(227 228 230 / 33%)',
                    height: '15px',
                    width: '95%'
                  }"></ngx-skeleton-loader>
              </td>
            </ng-container>

            <ng-container matColumnDef="stepTemplateId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                {{loc.tableHeaders.stepTemplate}}
              </th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader count="1" [theme]="{
                    background: 'no-repeat rgb(227 228 230 / 33%)',
                    height: '15px',
                    width: '95%'
                  }"></ngx-skeleton-loader>
              </td>
            </ng-container>

            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.subject}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader count="1" [theme]="{
                      background: 'no-repeat rgb(227 228 230 / 33%)',
                      height: '15px',
                      width: '95%'
                    }"></ngx-skeleton-loader>
                </td>
              </ng-container>

              <ng-container matColumnDef="postingSign">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.postingSign}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader count="1" [theme]="{
                      background: 'no-repeat rgb(227 228 230 / 33%)',
                      height: '15px',
                      width: '95%'
                    }"></ngx-skeleton-loader>
                </td>
              </ng-container>

              <ng-container matColumnDef="operation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.operation}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader count="1" [theme]="{
                      background: 'no-repeat rgb(227 228 230 / 33%)',
                      height: '15px',
                      width: '95%'
                    }"></ngx-skeleton-loader>
                </td>
              </ng-container>
              <ng-container matColumnDef="suboperation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.suboperation}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader count="1" [theme]="{
                      background: 'no-repeat rgb(227 228 230 / 33%)',
                      height: '15px',
                      width: '95%'
                    }"></ngx-skeleton-loader>
                </td>
              </ng-container>

              <ng-container matColumnDef="group">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.group}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader count="1" [theme]="{
                      background: 'no-repeat rgb(227 228 230 / 33%)',
                      height: '15px',
                      width: '95%'
                    }"></ngx-skeleton-loader>
                </td>
              </ng-container>

              <ng-container matColumnDef="errorGroup">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.errorGroup}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader count="1" [theme]="{
                      background: 'no-repeat rgb(227 228 230 / 33%)',
                      height: '15px',
                      width: '95%'
                    }"></ngx-skeleton-loader>
                </td>
              </ng-container>

              <ng-container matColumnDef="isReverse">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n>
                  {{loc.tableHeaders.reverse}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <ngx-skeleton-loader count="1" [theme]="{
                      background: 'no-repeat rgb(227 228 230 / 33%)',
                      height: '15px',
                      width: '95%'
                    }"></ngx-skeleton-loader>
                </td>
              </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef style="text-align: center;" i18n>
                {{loc.tableHeaders.actions}}
              </th>
              <td mat-cell *matCellDef="let element" style="text-align: center;">
                <ngx-skeleton-loader count="1"
                  [theme]="{'background': 'no-repeat rgb(227 228 230 / 33%)', height:'15px', width:'95%'}">
                </ngx-skeleton-loader>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="fieldsDisplayedColumns; sticky: true"></tr>
            <tr class="row" mat-row *matRowDef="let row; columns: fieldsDisplayedColumns"></tr>
          </table>
          </mat-card>
        </mat-card>
      </div>
      <div class="paginator-wrapper-loader">
        <ngx-skeleton-loader count="1" [theme]="{
          background: 'no-repeat rgb(227 228 230 / 33%)',
          height: '70%',
          width: '250px'
        }" style="width: 100%; height: 50%;display: flex; justify-content: flex-end;align-items: flex-end;">
        </ngx-skeleton-loader>

        <ngx-skeleton-loader count="1" [theme]="{
            background: 'no-repeat rgb(227 228 230 / 33%)',
            height: '70%',
            width: '250px'
          }" style = "width: 100%; height: 50%;display: flex; justify-content: flex-start;align-items: flex-end;" >
        </ngx-skeleton-loader>
      </div>
  </ng-container>

import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';

@Component({
  selector: 'app-associate-fields-to-action',
  templateUrl: './associate-fields-to-action.component.html',
  styleUrls: ['./associate-fields-to-action.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class AssociateFieldsToActionComponent implements OnInit {

  loading=false;
  serviceId = '';
  operationId = '';
  postingSchemes: any[] = [];

  selectionParameters = new SelectionModel<any>(true, []); //ver docs
  parametersDataSource = new MatTableDataSource();
  displayedColumns = ['select', 'description'];

  constructor(
    public dialogRef: MatDialogRef<AssociateFieldsToActionComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    private extensionPointsService: ExtensionPointsService,
    public loc : LocalizationService
    ) { }

  ngOnInit(): void {
    this.loadParameters();
  }

  loadParameters(){ //load full list from api, select the selected parameters from data received as input
    this.loading = true;
    this.extensionPointsService.getFields()
    .subscribe((res: BaseResponse) => {
      if(res.statusRS?.code === "0"){
        this.parametersDataSource.data = res.messageRS!['fields'] as any as any[];
        this.selectionParameters = new SelectionModel<any>(true, res.messageRS!['fields'].filter( (x: any) => this.data.fields.some((y: any) => x.fieldId == y.fieldId) ));
       }
      this.loading = false;
    }, 
    (err) => {

    })
  }

  create(){
    let message = {
      djId: this.data.action.digitalJourney,
      tpId: this.data.action.touchPointId,
      actionId: this.data.action.actionId,
      stageId: this.data.action.stageId,
      actionEntity: this.data.action.actionEntity,
      fields: this.selectionParameters.selected
    }
    this.loading = true;
    this.extensionPointsService
      .setFieldsToTemplate(message)
      .subscribe((res: BaseResponse) => {
            setTimeout(() => {
              if(res.statusRS?.code === "0"){
                this.exit("created");
              } else if(res.statusRS?.code === "-91444"){
                this.exit("not updated");
              } else {
                this.exit("error");
              }
              this.loading = false;

            }, 200)
          }
            ,
            (err) => {
              console.log(err);
            }
          )
        
  }

  exit(message: string): void{
    this.dialogRef.close(message);
  }

  something(row: any){
    this.selectionParameters.toggle(row);
  }

}

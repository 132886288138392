import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgentComponent } from './agent/agent.component';
import { BankDetailsComponent } from './banks/bank-details/bank-details/bank-details.component';
import { BanksComponent } from './banks/banks.component';
import { CalendarDetailsComponent } from './calendars/calendar-details/calendar-details.component';
import { CalendarsComponent } from './calendars/calendars.component';
import { CountriesComponent } from './countries/countries.component';
import { ActionDetailsComponent } from './extension-points/action-details/action-details.component';
import { ExtensionPointsComponent } from './extension-points/extension-points.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login/login.component';
import { MainComponent } from './main/main.component';
import { NotificationssComponent } from './notificationss/notificationss.component';
import { PaymentButtonDetailsComponent } from './payment-button/payment-button-details/payment-button-details.component';
import { PaymentButtonComponent } from './payment-button/payment-button.component';
import { TransactionTemplatesComponent } from './transaction-templates/transaction-templates.component';
import { DisaggregationDetailsComponent } from './transactions/disaggregation/disaggregation-details/disaggregation-details.component';
import { PostingDetailsComponent } from './transactions/posting/posting-details/posting-details.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { UsersComponent } from './users/users.component';
import { CountryDetailsComponent } from './countries/country-details/country-details.component';
import { DigitalServicesComponent } from './digital-services/digital-services.component';
import { AttributeGroupAllowedDetailsComponent } from './digital-services/attribute-group-allowed-details/attribute-group-allowed-details.component';
import { CurrencyComponent } from './currency/currency.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { IntegrationDetailsComponent } from './integrations/integration-details/integration-details.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { ServiceModeDetailsComponent } from './digital-services/service-mode-details/service-mode-details.component';
import { CurrencyDetailsComponent } from './currency/currency-details/currency-details.component';
import { BranchComponent } from './branch/branch.component';
import { IntegrationTypeDetailsComponent } from './integrations/integration-type-details/integration-type-details.component';
import { AgentTypeDetailsComponent } from './agent/agent-type-details/agent-type-details.component';
import { ApisComponent } from './apis/apis.component';
import { ApiDetailsComponent } from './apis/api-details/api-details/api-details.component';
import { MetadataComponent } from './metadata/metadata.component';
import { StepDetailsComponent } from './metadata/steps/steps-details/step-details/step-details.component';
import { MapOutputMetadataComponent } from './metadata/steps/steps-details/map-output-metadata/map-output-metadata.component';
import { PostingFlowsComponent } from './transactions/posting/posting-flows/posting-flows.component';
import { MetadataDetailsComponent } from './metadata/metadata-details/metadata-details.component';
import { MetadataFieldsDetailsComponent } from './metadata/metadata-fields/metadata-fields-details/metadata-fields-details.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', component: MainComponent, canActivate: [AuthGuard], children: [
    { path: 'agent', component: AgentComponent},
    { path: 'country', component: CountriesComponent},
    { path: 'currency', component: CurrencyComponent},
    { path: 'bank', component: BanksComponent },
    { path: 'branch', component: BranchComponent },
    { path: 'notification', component: NotificationssComponent},
    { path: 'transaction', component: TransactionsComponent},
    { path: 'disaggregation-details', component: DisaggregationDetailsComponent},
    { path: 'posting-details', component: PostingDetailsComponent},
    { path: 'transaction-template', component: TransactionTemplatesComponent},
    { path: 'calendar', component: CalendarsComponent},
    { path: 'user', component: UsersComponent},
    { path: 'extension-point', component: ExtensionPointsComponent},
    { path: 'action-details', component: ActionDetailsComponent},
    { path: 'bank-details', component: BankDetailsComponent},
    { path: 'country-details', component: CountryDetailsComponent},
    { path: 'bank-details', component: BankDetailsComponent},
    { path: 'payment-button', component: PaymentButtonComponent},
    { path: 'payment-button-details', component: PaymentButtonDetailsComponent},
    { path: 'service', component: DigitalServicesComponent },
    { path: 'attribute-group-details', component: AttributeGroupAllowedDetailsComponent},
    { path: 'calendar-details', component: CalendarDetailsComponent},
    { path: 'integrations', component: IntegrationsComponent},
    { path: 'integrations/details', component: IntegrationDetailsComponent },
    { path: 'campaigns', component: CampaignsComponent },
    { path: 'api', component: ApisComponent },
    { path: 'api/api-details', component: ApiDetailsComponent },
    { path: 'attribute-group-details', component: AttributeGroupAllowedDetailsComponent, canActivate: [AuthGuard] },
    { path: 'service-mode-details', component: ServiceModeDetailsComponent, canActivate: [AuthGuard] },
    { path: 'calendar-details', component: CalendarDetailsComponent, canActivate: [AuthGuard] },
    { path: 'currency-details', component: CurrencyDetailsComponent, canActivate: [AuthGuard] },
    { path: 'integrations/integration-type-details', component: IntegrationTypeDetailsComponent },
    { path: 'currency-details', component: CurrencyDetailsComponent, canActivate: [AuthGuard] },
    { path: 'agent/details', component: AgentTypeDetailsComponent, canActivate: [AuthGuard] },
    { path: 'metadata', component: MetadataComponent},
    { path: 'step-details', component: StepDetailsComponent},
    { path: 'step-details/output-details', component: MapOutputMetadataComponent},
    { path: 'posting-details/flows', component: PostingFlowsComponent },
    { path: 'step-details/metadata-details', component: MetadataDetailsComponent },
    { path: 'metadata/field-details', component: MetadataFieldsDetailsComponent }


  ]},
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

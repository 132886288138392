import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AttributeAllowed, AttributeGroupAllowed } from 'src/app/models/attributes/attributeAllowed';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';
import { BaseResponse } from 'src/app/models/responses.model';
import { AttributeCategorie } from 'src/app/models/attributes/attributeCategorie';
import { ServiceMode } from 'src/app/models/attributes/ServiceMode.model';

@Injectable({
  providedIn: 'root',
})
export class AttributesService {
  readonly baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getAttributesAllowed(
    serviceTemplate: string,
    operationId: string,
    attributeGroup: string
  ) {
    const HTTP_OPTIONS = {
      params: {
        attributeGroupId: attributeGroup,
        serviceTemplate: serviceTemplate,
        operation: operationId,
      },
    };
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/services/attributes/X/attributes-allowed',
      HTTP_OPTIONS
    );
  }

  getAttributesGroupAllowed() {
    const HTTP_OPTIONS = {};
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/services/attribute-group/X/get',
      HTTP_OPTIONS
    ) as Observable<BaseResponse<AttributeGroupAllowed[]>>;
  }

  getServiceModeAttributeGroupsAllowed(serviceMode: string){
    const HTTP_OPTIONS = {
    };
    return this.http.get(
      this.baseURL + `RSAdaptorFE/api/v1/paycorr/services/service-mode/${serviceMode}/get-attribute-groups`,
      HTTP_OPTIONS
    ) as Observable<BaseResponse<AttributeGroupAllowed[]>>; 
  }


  getAttributeCategories(){
    const HTTP_OPTIONS = {
    };
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/services/attributes/X/get-attributes-categories',
      HTTP_OPTIONS
    ) as Observable<BaseResponse<AttributeCategorie[]>>;
  }

  deleteAttributeGroupAllowed(attributeGroupId: string) {
    return this.http.delete(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/services/attribute-group/' +
        attributeGroupId +
        '/delete'
    ) as Observable<BaseResponse<void>>;
  }

  deleteServiceModeAttributeGroupAllowed(serviceMode: string, attributeGroupId: string){
    return this.http.delete(
      this.baseURL + `RSAdaptorFE/api/v1/paycorr/services/service-mode-group/X/delete?modeId=${serviceMode}&attributeGroupId=${attributeGroupId}`
    ) as Observable<BaseResponse<void>>; 
  }

  updateAttributeAllowed(attributes: AttributeAllowed[], attributeGroupId: string){
    const baseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      attributeGroupId: attributeGroupId,
      attributes: attributes,
    };
    return this.http.put(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/services/attributes/X/update-attribute-allowed`,
      baseRequest
    );
  }

  getGroupsPTS(categoryId: string, serviceId: string, operation: string) {
    const HTTP_OPTIONS = {
      params: {
        categoryId,
        serviceId,
        operation,
      },
    };
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/services/pts-attrib-group/X/get',
      HTTP_OPTIONS
    ) as Observable<BaseResponse<any>>;
  }
  createAttributeGroupAllowed(
    attributeCategoryId: string,
    attributeGroup: string,
    serviceTemplateId: string,
    operationId: string,
    description: string
  ) {
    const baseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      attributeCategoryId,
      attributeGroup,
      serviceTemplateId,
      operationId,
      description
    };
    return this.http.post(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/services/attribute-group/x/create`,
      baseRequest
    ) as Observable<BaseResponse<void>>;
  }

  getServiceModes(){
    return this.http.get(
      this.baseURL + 'RSAdaptorFE/api/v1/paycorr/services/service-mode/X/get'
    ) as Observable<BaseResponse<ServiceMode>>; 
  }

  updateServiceMode(modeId: string, serviceTemplateId: string, description: string){
    const baseRequest = new BaseRequest();
      baseRequest.messageRQ = {
        modeId, serviceTemplateId, description
      };
      return this.http.put(
        this.baseURL + `RSAdaptorFE/api/v1/paycorr/services/service-mode/X/update-description` ,
        baseRequest
      )
  }

  deleteServiceMode(serviceModeId: string, serviceTemplateId: string){
    return this.http.delete(
      this.baseURL + `RSAdaptorFE/api/v1/paycorr/services/service-mode/X/delete?modeId=${serviceModeId}&serviceTemplateId=${serviceTemplateId}`
    );
  }

  createServiceMode(modeId: string, description: string, serviceTemplateId: string){
    const baseRequest = new BaseRequest();
      baseRequest.messageRQ = {
        modeId,
        serviceTemplateId,
        description
      };
      return this.http.post(
        this.baseURL + `RSAdaptorFE/api/v1/paycorr/services/service-mode/X/create` ,
        baseRequest
      ) as Observable<BaseResponse<void>>
  }

  associateServiceModeAttributeGroup(modeId: string, attributeGroupId: string){
    const baseRequest = new BaseRequest();
      baseRequest.messageRQ = {
        modeId,
        attributeGroupId
      };
      return this.http.post(
        this.baseURL + `RSAdaptorFE/api/v1/paycorr/services/service-mode-group/X/create` ,
        baseRequest
      ) as Observable<BaseResponse<void>>
  }
  
}

import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EditModalComponent } from '../edit-modal/edit-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateModalData, Step, VerifyGroup } from 'src/app/models/stepper/step-modal';

@Component({
  selector: 'app-create-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.css'],
  host: {
    style: "height: 100%",
  },
})
export class CreateModalComponent implements OnInit, OnChanges {
  
  steps : Step[] = [];
  verify : VerifyGroup[] = []
  @Input() modalData!: CreateModalData;
  @Output() saveEvent = new EventEmitter<{ [key: string]: any }>();
  @Output() fileSelectEvent = new EventEmitter<any>();
  
  loading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<EditModalComponent>,
  ) { 
   
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
  }

  ngOnInit(): void {
    this.steps = this.modalData.steps;
    this.verify = this.modalData.verify;
    this.loading = false;  
  }

  exit(message: any) {
    this.dialogRef.close(message);
  }

  save(verifiedItemsDictionary: { [key: string]: any }) {
    this.saveEvent.emit(verifiedItemsDictionary);
  }

  fileSelect(event:any){
    this.fileSelectEvent.emit(event);
  }
}

<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
  </div>
  <div class="confirm-title">
    <div class="confirm-title-inner-div">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon-user">account_balance</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon-edit">mode_edit</mat-icon>
      </button>
      <label class="dialog-title" i18n>{{loc.banks.edit}}</label>
    </div>
    <button class="exit-button" (click)="exit('not updated')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
  
  <div class="edit-first-row">
    <form [formGroup]="firstFormGroup" class="horizontal-stepper actions-stepper form-step">
        <div class="data-box">
            <div class="create-row">
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.bankId}}</mat-label>
                    <input matInput  name="bankId" formControlName="bankId" [(ngModel)] = "data.bankId" readonly >
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.bankName}}</mat-label>
                    <input matInput name="bankName" formControlName="bankName"  [(ngModel)]="data.bankName" >
                </mat-form-field>

            </div>
            <div class="create-row">
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.bankAddress}}</mat-label>
                    <input matInput name="bankAddress" formControlName="bankAddress" [(ngModel)]="data.bankAddress" >
                </mat-form-field>


                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.country}}</mat-label>
                    <mat-select formControlName="country" [ngModel]="bankCountry">
                        <mat-select-trigger>
                            <div class="country-div">
                              <mat-label class="selected-country-lbl"
                                >{{ firstFormGroup.get("country")?.value?.countryName }}
                              </mat-label>
                            </div>
                            <img
                              [src]="firstFormGroup.get('country')?.value?.image"
                              class="selected-country-flag"
                            />
                        </mat-select-trigger>
                        
                        
                        
                        
                        <mat-option [value]="country" *ngFor="let country of countries"
                        >
                        {{ country.countryName }}
                        <img [src]="country.image"  class="country-flag" />    
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="create-row">
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.abaNumber}}</mat-label>
                    <input matInput name="abaNumber" formControlName="abaNumber" [(ngModel)]="data.abaNumber" >
                </mat-form-field>


                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.swiftCode}}</mat-label>
                    <input matInput name="swiftCode" formControlName="swiftCode" [(ngModel)]="data.swiftCode">
                </mat-form-field>

                
            </div>
        </div>
    </form>
  </div>
  <div class="confirm-buttons">
  <button mat-fab class="button green-color" [disabled] ='firstFormGroup.invalid'  (click)="modify()"  >
      <mat-icon fontSet="material-icons-outlined">save</mat-icon>
    </button>
  </div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import { Country } from 'src/app/models/country/country.model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { ApisService } from 'src/app/services/apis/apis.service';
import { API } from 'src/app/models/api/api.model';
import { ShowStructureComponent } from '../../showStructure/show-structure/show-structure.component';

@Component({
  selector: 'app-api-details',
  templateUrl: './api-details.component.html',
  styleUrls: ['./api-details.component.css'],
  host: {
    class: 'details-component'
  }
})
export class ApiDetailsComponent implements OnInit {

  loading = true;
  name = '';
  unfiltredAPIs: any[] = [];
  api: API | undefined;
  dataSource = new MatTableDataSource();
  apiId = '';
  action: any;
  loadingDataSource = new MatTableDataSource<string>();
  selectedIndex = 0;

  showIcon = false;
  body = '';
  constructor(
    private dateFormatter: DateFormatterService,
    public route: ActivatedRoute,
    public location: Location,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    private apisService: ApisService,
    public loc : LocalizationService
  ) {}

  ngOnInit(): void {
    this.loadingDataSource.data = ['', '', '', '', ''];
    this.route.queryParams.subscribe((params) => {
      this.apiId = params['apiId'];

    });
    this.getAPIs();
  }

  dontShowIcon(){
    this.showIcon = true
  }


  showStructure(): void {
    const dialogRef = this.dialogService.openDialog(
      ShowStructureComponent,
      {
        body: this.body,
      },
      'large'
    );
  }


  getAPIs(): void {
    this.apisService.loadAPIs().subscribe(
        {
          next: (res:any ) => {
            if (res.statusRS.code === '0'){
            this.unfiltredAPIs = res.messageRS;
            this.api = this.unfiltredAPIs.find(x => x.apiId == this.apiId);
            console.log(this.api);
            this.body = this.api?.body!;
            this.loading = false;
            }else{
              this.logoutDialogService.openLogoutDialog(res.statusRS.description);
            }
          },
          error: (err) => {
            this.logoutDialogService.openLogoutDialog(err);
          }

        }

      );
  }

  emptyField (text : string | undefined): string {
    if (text === '' || text === undefined)
      return "-";
    return text;
  }


  formatDate(date?: string): string {
    if (date) return this.dateFormatter.formatDate(date.substring(0, 9), 'dd/MM/yyyy');
    else return '';
  }
  goBack(): void {

    this.location.back();
    let state = JSON.parse(localStorage.getItem('state')!);
    state.mediums = {};
    localStorage.setItem('state', JSON.stringify(state));
  }

}

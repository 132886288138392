import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceTemplateAgentType } from 'src/app/models/agents/service-template-agent-type.model';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { UsersService } from 'src/app/services/users/users.service';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { Action, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { CreateTemplateComponent } from '../create-template/create-template.component';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { EditTemplateComponent } from '../edit-template/edit-template.component';

@Component({
  selector: 'app-list-templates',
  templateUrl: './list-templates.component.html',
  styleUrls: ['./list-templates.component.css'],
  host: {
    class: 'component'
  }
})
export class ListTemplatesComponent implements OnInit {
  loading = true;
  agentTypeId: string = ''
  templates: ServiceTemplateAgentType[] = [];
  allTemplates: ServiceTemplateAgentType[] = [];

  constructor(
    public loc: LocalizationService,
    public dialogService: DialogService,
    private htmlGenerator: SafeHTMLGeneratorService,
    private route: ActivatedRoute,
    private userService: UsersService,
    private snackbarService: SnackbarService,


  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.agentTypeId = params['id']
      this.loadDetails();
      this.getTemplates();
    });
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-services.png',
    message: this.loc.template.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.createTemplate(),
      },
    ],

  };

  data: TableData[] = [];

  columns: Column[] = [
    {
      key: 'templateId',
      title: this.loc.tableHeaders.id,
      centered: false,
      sortable: true,
    },
    {
      key: 'description',
      title: this.loc.tableHeaders.description,
      centered: false,
      sortable: false,
    },
    {
      key: 'status',
      title: this.loc.tableHeaders.status,
      centered: false, //con centered: true se va demasiado para la derecha
      sortable: true,
    },
  ];

  actions: Action[] = [
    {
      icon: 'edit',
      tooltip: this.loc.template.edit,
      action: (row: ServiceTemplateAgentType) => this.editTemplate(row),
    },
    {
      icon: 'delete',
      tooltip: this.loc.template.delete,
      action: (row: ServiceTemplateAgentType) => this.deleteTemplate(row),
    },
  ];  

  createButton: CreateButton = {
    tooltip: this.loc.template.create,
    action: () => this.createTemplate(),
  };

  
  createTemplate(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateTemplateComponent,
      { agentTypeId: this.agentTypeId ,
      templates:this.allTemplates.filter(template =>{ return !this.templates.some(x => x.templateId === template.templateId)}) },
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.template)
        );
        this.loading = true;
        this.loadDetails();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.template)
        );
      }
    });
  }

  editTemplate(row: ServiceTemplateAgentType): void{
    const dialogRef = this.dialogService.openDialog(
      EditTemplateComponent,
      { id: row.templateId,
        description: row.description,
        agentType: this.agentTypeId,
        status: row.status,
        image: row.imageId,
        allowed: true,
        
      },
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'updated') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.template)
        );
        this.loadDetails();

        this.loading = true;
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.template)
        );
      }
    });
  }
  
  deleteTemplate(row: ServiceTemplateAgentType): void{
    let data: DeleteModalData = {
      title: this.loc.template.delete,
      icon: 'domain',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.template),
    };

    const dialogRef = this.dialogService.openDialog(DeleteModalComponent, data, 'x-small');

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        this.userService
      .deleteServiceTemplate(row.templateId!!, this.agentTypeId!!)
      .subscribe(
        {
          next: (res: BaseResponse) => {
            this.loading = false;
            if (res.statusRS?.code === '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.template)
              );
              this.loadDetails();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.template)
              );
            }
          },
          error: (err) => {
            this.loading = false;
            console.log(err);
            if (err.status !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.template)
              );
            }
          }
        }
      );
      }
    });
  }


  transformers: TableTransformer = {
    status: (value: string) =>
      this.htmlGenerator.statusIcon(value === '1' ? true : false),
    value: (value: string) => value || "-",
    image: (value: any) => {
      return this.htmlGenerator.imageOrDefaultIcon(value, 'domain', 'integration image');}
  };
  
  paginatorInfo: PaginatorInfo = this.loc.template;

  filters: TableFilter[] = [
    {
      key: 'id',
      label: this.loc.tableHeaders.id,
      placeholder: this.loc.tableHeaders.id,
      type: 'text',
    },
    {
      key: 'description',
      label: this.loc.tableHeaders.description,
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    },
  ];



  getTemplates(): void {
    this.loading = true;
    this.userService.getServiceTemplates().subscribe( 
      {
        next: (res: BaseResponse) => {
          if (res.statusRS?.code === '0') {
            this.allTemplates = res.messageRS as unknown as any;
           

            this.loading = false;
          }
      },
       error: (err: any) => {
        console.log(err);
      }
      }
    )
  }


  loadDetails(): void {
    this.loading = true;
    this.userService.getServiceTemplatesAllowed(this.agentTypeId).subscribe( 
      {
        next: (res: BaseResponse) => {
          if (res.statusRS?.code === '0') {
            this.templates = res.messageRS as unknown as any;
            console.log(this.templates)
            this.data = this.templates as any as TableData[]
            this.loading = false;
          }
      },
       error: (err: any) => {
        console.log(err);
      }
      }
    )
  }


}
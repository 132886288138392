<!-- elegir label, action result, description-->
<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
</div>

<div class="title">
    <div class="inner-title-container">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">hub</mat-icon>
            <mat-icon class="action-button-icon-add">add</mat-icon>
        </button>
        <label class="dialog-title" i18n>{{loc.postingSchemes.create}}</label>
    </div>
    <button class="exit-button" (click)="exit('not created')"  >
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>
    <mat-horizontal-stepper class="horizontal-stepper field-create-stepper" [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
        [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex">
        <ng-template matStepperIcon="done" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="edit" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="number" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
        </ng-template>
        <mat-step [stepControl]="firstFormGroup" [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
            <form [formGroup]="firstFormGroup" class="form-step">
                <div class="data-box">
                <div class="create-row">
                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label i18n>{{loc.fields.id}}</mat-label>
                        <input matInput formControlName="name" required>
                    </mat-form-field>

                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label i18n>{{loc.fields.description}}</mat-label>
                        <input matInput formControlName="description" required>
                    </mat-form-field>
                </div>
                <div class="create-row">
                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label i18n>{{loc.fields.disaggregation}}</mat-label>
                        <mat-select required formControlName="disaggregationId">
                            <mat-option *ngFor="let disagg of disaggregations" [value]="disagg" i18n>{{disagg.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label i18n>{{loc.fields.posting}}</mat-label>
                        <mat-select required formControlName="postingId">
                            <mat-option *ngFor="let posting of postings" [value]="posting" i18n>{{posting.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperNext>
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>
        <mat-step [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
            <div class="form-step">
                <div class="verify-large">
                    <div class="flex-container-vertical flex-container-vertical-modal">
                        <div>
                            <label class="card-name" i18n>{{loc.postingSchemes.new}}</label>
                        </div>
                        
                            <div class="details-package-large details-flex">
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.id}}</div>
                                    <div class="card-value">{{firstFormGroup.get('name')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.description}}</div>
                                    <div class="card-value">{{firstFormGroup.get('description')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.disaggregation}}</div>
                                    <div class="card-value">{{firstFormGroup.get('disaggregationId')?.value.description}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.posting}}</div>
                                    <div class="card-value">{{firstFormGroup.get('postingId')?.value.description}}</div>
                                </div>
                                
                            </div>
                    </div>

                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious  >
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-fab class="button green-button" (click)="create()"  >
                        <mat-icon class="stepper-item-icon" fontSet="material-icons-outlined">save</mat-icon>
                    </button>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>


import { Component, OnInit } from '@angular/core';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { MetadataField } from 'src/app/models/metadata/metadata-field/metadataField.model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { Action, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { CreateMetadataFieldComponent } from '../create-metadata-field/create-metadata-field.component';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Validators } from '@angular/forms';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { MetadataType } from 'src/app/models/metadata/metadata-type/metadataType.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-metadata-fields',
  templateUrl: './list-metadata-fields.component.html',
  styleUrls: ['./list-metadata-fields.component.css'],
  host: {
    class: 'component',
  }
})
export class ListMetadataFieldsComponent implements OnInit {
  loading = true;
  metadataTypes: any[] = [];

  constructor(
    public loc: LocalizationService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    private metadataService: MetadataService,
    private dateFormatter: DateFormatterService,
    private htmlGenerator: SafeHTMLGeneratorService,
    private router: Router,

  ) {}

  ngOnInit(): void {
    this.listMetadataFields();
    this.getMetadataTypes();
  }

  data: TableData[] = [];

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.metadataField.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.createMetadataField(),
      },
    ],
  };

  columns: Column[] = [
    { title: this.loc.tableHeaders.id, 
      key: 'id', 
      sortable: true 
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.type,
      key: 'type',
      sortable: true 
    },
    {
      title: this.loc.tableHeaders.isMandatory,
      key: 'isMandatory',
      sortable: true 
    },
    {
      title: this.loc.tableHeaders.isHidden,
      key: 'isHidden',
      sortable: true 
    },
    {
      title: this.loc.tableHeaders.creationDate,
      key: 'creationDate',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.updateDate,
      key: 'changeDate',
      sortable: true 
    },
    {
      title: this.loc.fields.checkout,
      key: 'checkout',
      sortable: true 
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.metadataField;

  filters: TableFilter[] = [
    {
      key: 'id',
      label: this.loc.tableHeaders.id,
      placeholder: this.loc.tableHeaders.id,
      type: 'text',
    },
    {
      key: 'description',
      label: this.loc.tableHeaders.description,
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    },
    {
      key: 'type',
      label: this.loc.tableHeaders.type,
      placeholder: this.loc.tableHeaders.type,
      type: 'text',
    },
  ];

  actions: Action[] = [
    {
      icon: 'more_horiz',
      tooltip: this.loc.common.details,
      action: (row) => {
        this.details(row);
      },
      condition: (row) => row.type === 'LIST',
    },
    {
      icon: 'edit',
      tooltip: this.loc.metadataField.edit,
      action: (row: MetadataField) => {
        this.editMetadataField(row);
      },
    },
    {
      icon: 'delete',
      tooltip: this.loc.metadataField.delete,
      action: (row: MetadataField) => {
        this.deleteMetadataField(row);
      },
    },
  ];

  createButton: CreateButton = {
    tooltip: this.loc.metadataField.create,
    action: () => this.createMetadataField(),
  };

  transformers: TableTransformer = {
    creationDate: (value: string) => this.formatDate(value),
    changeDate: (value: string) => this.formatDate(value),
    isMandatory: (value: number) =>
    this.htmlGenerator.statusIcon(value == 1),
    isEditable: (value: number) =>
    this.htmlGenerator.statusIcon(value == 1),
    isHidden: (value: number) =>
    this.htmlGenerator.statusIcon(value == 1),
    checkout: (value: number) =>
    this.htmlGenerator.statusIcon(value == 1),
  };

  formatDate(date: string, format?: string): string {
    return this.dateFormatter.formatDate(date, format ? format : 'dd/MM/yyyy');
  }

  listMetadataFields(){
    this.loading = true
    this.metadataService.listMetadataFields().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.data = res.messageRS as any as TableData[];
          this.data.sort((a, b) => (a.id > b.id) ? 1 : -1);
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  createMetadataField(){
    const dialogRef = this.dialogService.openDialog(
      CreateMetadataFieldComponent,
      {},
      'large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.listMetadataFields();
    });
  }

  editMetadataField(row: any){
    let data: EditModalData = {
      title: this.loc.metadataField.edit,
      icon: 'psychology',
      fields: [
        {label: this.loc.tableHeaders.description, value: row.description},
        {label: this.loc.tableHeaders.type, value: row.type, validators: [Validators.required], comboValues: this.metadataTypes, isCombo: true},
        {label: this.loc.tableHeaders.controlType, value: row.controlType, validators: [Validators.required]},
        {label: this.loc.tableHeaders.defaultValue, value: row.defaultValue},
        {label: this.loc.tableHeaders.isMandatory, value: row.isMandatory, validators: [Validators.required], comboValues:  [{key: "1", value: this.loc.common.yes},{key: "0", value: this.loc.common.no}], isCombo: true},
        {label: this.loc.tableHeaders.isEditable, value: row.isEditable, validators: [Validators.required],  comboValues:  [{key: "1", value: this.loc.common.yes},{key: "0", value: this.loc.common.no}], isCombo: true},
        {label: this.loc.tableHeaders.isHidden, value: row.isHidden, validators: [Validators.required],  comboValues:  [{key: "1", value: this.loc.common.yes},{key: "0", value: this.loc.common.no}], isCombo: true},
        {label: this.loc.fields.datasource, value: row.datasource, validators: [Validators.required]},
        {label: this.loc.fields.datasourceData, value: row.datasourceData, validators: [Validators.required]},
        {label: this.loc.tableHeaders.validation, value: row.validation},
        {label: this.loc.fields.checkout, value: row.checkout, validators: [Validators.required],  comboValues:  [{key: "1", value: this.loc.common.yes},{key: "0", value: this.loc.common.no}], isCombo: true},

      ],
      class: 'meta-field'
    }
    const dialogRef = this.dialogService.openDialog(EditModalComponent, data, "large");

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value;
        let newType = result.find((x: any) => x.label === this.loc.tableHeaders.type).value;
        let newControlType = result.find((x: any) => x.label === this.loc.tableHeaders.controlType).value;
        let defaultValue = result.find((x: any) => x.label === this.loc.tableHeaders.defaultValue).value;
        let newMandatory = result.find((x: any) => x.label === this.loc.tableHeaders.isMandatory).value;
        let newEditable = result.find((x: any) => x.label === this.loc.tableHeaders.isEditable).value;
        let newHidden = result.find((x: any) => x.label === this.loc.tableHeaders.isHidden).value;
        let newDatasource = result.find((x: any) => x.label === this.loc.fields.datasource).value;
        let newDatasourceData = result.find((x: any) => x.label === this.loc.fields.datasourceData).value;
        let newValidation = result.find((x: any) => x.label === this.loc.tableHeaders.validation).value;
        let newCheckout = result.find((x: any) => x.label === this.loc.fields.checkout).value;


        let metaField: MetadataField = {...row, description: newDescription, type: newType, controlType: newControlType, defaultValue: defaultValue, isMandatory: newMandatory,
          isEditable: newEditable, isHidden: newHidden, datasource: newDatasource, datasourceData: newDatasourceData, validation: newValidation, checkout: newCheckout};

          this.metadataService.editMetadataField(metaField).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('update', this.loc.metadataField)
              );
              this.listMetadataFields();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.metadataField)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.metadataField)
            );
          },
        })
      }
    });
  }

  getMetadataTypes(){
    this.loading = true;
    this.metadataService.listMetadatTypes().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.metadataTypes = res.messageRS as unknown as MetadataType[];
          this.metadataTypes = this.metadataTypes.map(metaType => ({
            key: metaType.id,
            value: metaType.id
          }));
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }


  deleteMetadataField(row: any){
    let data: DeleteModalData = {
      title: this.loc.metadataField.delete,
      icon: 'psychology',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.metadataField),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === 'delete') {
        this.loading = true;
        this.metadataService.deleteMetadataField(row.id).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.metadataField)
              );
              this.listMetadataFields();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.metadataField)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.metadataField)
            );
          },
        });
      }
    });
  }

  details(row: any){
    this.router.navigate(['/metadata/field-details'], {
      queryParams: {
        id: row.id
      },
      queryParamsHandling: 'merge',
    });
  }

}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { IntegrationTypeParameters } from 'src/app/models/integration/integrationTypeParameters.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Action, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { IntegrationType } from 'src/app/models/integration/integrationType.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { CreateTypeParameterComponent } from '../create-type-parameter/create-type-parameter.component';

@Component({
  selector: 'app-list-type-parameters',
  templateUrl: './list-type-parameters.component.html',
  styleUrls: ['./list-type-parameters.component.css'],
  host: {
    class: 'component'
  }
})
export class ListTypeParametersComponent implements OnInit, OnChanges {
  @Input() loading = true;

  integrationType: IntegrationType = {};
  integrationTypeParameters: IntegrationTypeParameters[] = [];  
  _parameters: any[] = [];

  

  constructor(
    public loc: LocalizationService,
    public dialogService: DialogService,
    private route: ActivatedRoute,
    private integrationsService: IntegrationsService,
    private snackbarService: SnackbarService,
    private htmlGenerator: SafeHTMLGeneratorService,

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.integrationType.id = params.id;
    });
    this.loadParameters();

  }

  ngOnChanges(): void {
    this.data = this.integrationTypeParameters as any as TableData[]
  }

  loadParameters(){
    this.loading = true;
    this.integrationsService.getParametersByIntegrationType(this.integrationType.id).subscribe((res: BaseResponse) => {
      if(res.statusRS?.code === '0'){
        this.integrationTypeParameters = [];
        this._parameters = res.messageRS as unknown as any;
        for(let i = 0 ; i<this._parameters.at(0).length ; i++){
          this.integrationTypeParameters.push(this._parameters.at(0).at(i));
        }
        this.loading = false;
        this.data = this.integrationTypeParameters as any as TableData[]
      }
    },
    (err: any) => {
      console.log(err);
    }
    );
  }
  
  emptyState: EmptyState = {
    image: 'assets/img/empty-services.png',
    message: this.loc.parameters.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.createParameter(),
      },
    ],
  };

  data: TableData[] = [];

  columns: Column[] = [
    {
      key: 'parameterId',
      title: this.loc.tableHeaders.id,
      centered: false,
      sortable: true,
    },
    {
      key: 'categoryId',
      title: this.loc.tableHeaders.category,
      centered: false,
      sortable: true,
    },
    {
      key: 'parameterDescription',
      title: this.loc.tableHeaders.description,
      centered: false,
      sortable: true,
    },
    {
      key: 'defaultValue',
      title: this.loc.tableHeaders.defaultValue,
      centered: false,
      sortable: true,
    },
    {
      key: 'isMandatory',
      title: this.loc.tableHeaders.isMandatory,
      centered: true,
      sortable: false,
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.parameters;

  filters: TableFilter[] = [
    {
      key: 'parameterId',
      label: this.loc.tableHeaders.id,
      placeholder: this.loc.tableHeaders.id,
      type: 'text',
    },
    {
      key: 'categoryId',
      label: this.loc.tableHeaders.category,
      placeholder: this.loc.tableHeaders.category,
      type: 'text',
    },
    {
      key: 'parameterDescription',
      label: this.loc.tableHeaders.description,
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    },
    {
      key: 'isMandatory',
      label: this.loc.tableHeaders.isMandatory,
      placeholder: this.loc.tableHeaders.isMandatory,
      type: 'select',
      options: [
        {
          value: 'true',
          label: this.loc.common.yes
        },
        {
          value: 'false',
          label: this.loc.common.no
        },
        {
          value: '',
          label: this.loc.common.both
        }
      ]
    },
  ];
  
  actions: Action[] = [
    {
      icon: 'edit',
      tooltip: this.loc.parameters.edit,
      action: (row: IntegrationTypeParameters) => this.editParameter(row),
    },
    {
      icon: 'delete',
      tooltip: this.loc.parameters.delete,
      action: (row: IntegrationTypeParameters) => this.deleteParameter(row),
    },
  ];  

  createButton: CreateButton = {
    tooltip: this.loc.parameters.create,
    action: () => this.createParameter(),
  };
  
  transformers: TableTransformer = {
    isMandatory: (value: string) =>
      this.htmlGenerator.statusIcon(value === 'true' ? true : false),
    value: (value: string) => value || "-"
  };

  createParameter(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateTypeParameterComponent,
      { integrationTypeId: this.integrationType.id },
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.parameters)
        );
        this.loading = true;
        this.loadParameters();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.parameters)
        );
      }
    });
  }

  deleteParameter(row: IntegrationTypeParameters): void{
    let data: DeleteModalData = {
      title: this.loc.parameters.delete,
      icon: 'extension',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.parameters),
    };
    const dialogRef = this.dialogService.openDialog(DeleteModalComponent, data, 'x-small');
    dialogRef.afterClosed().subscribe((result) => {
      if(result === 'delete'){
        this.loading = true;
        this.integrationsService.deleteIntegrationTypeParameter(row.categoryId!! , row.parameterId!!)
          .subscribe({
            next: (res: BaseResponse) => {
              this.loading = false;
              if (res.statusRS?.code === '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.parameters)
                );
                this.loadParameters();
              } else if(res.statusRS?.code === '-91950'){
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.parameterIsBeingUsed
                );
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.parameters)
                );
              }
            },
            error: (err) => {
              this.loading = false;
              console.log(err);
              if (err.status !== 401) {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.parameters)
                );
              }
            }
          })
      }
    })

  }

  editParameter(row: IntegrationTypeParameters): void{
    let data: EditModalData = {
      title: row.parameterId!,
      icon: 'extension',
      fields: [
        {label: this.loc.tableHeaders.defaultValue, 
          value: row.defaultValue!!, 
          errorMsg: this.loc.parameters.errorEditParameterValue
        },
        {
          label: this.loc.tableHeaders.description, 
          value: row.parameterDescription!!, 
          errorMsg: this.loc.parameters.errorEditParameterValue, 
          validators: [Validators.required]}
      ]
    }

    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyParameter(row, result);
      }
    });
  }

  modifyParameter(row: any, result: any){
    let newDescrip = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
    let newDefValue = result.find((x: any) => x.label === this.loc.tableHeaders.defaultValue).value

    let newRow = { ...row, parameterDescription: newDescrip, defaultValue: newDefValue }
    console.log(newRow)
    this.integrationsService.editIntegrationTypeParameter(newRow, row.categoryId, row.parameterId)
    .subscribe(
      {
        next: (res: BaseResponse) => {
          this.loading = false;
          if (res.statusRS?.code === '0') {
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('update', this.loc.parameters)
            );
            this.loadParameters();
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.parameters)
            );
          }
        },
        error:(err) => {
          this.loading = false;
          console.log(err);
          if (err.status !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.parameters)
              );
            }
          }
        }
        );
  }


}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { WorkingDay } from 'src/app/models/calendar/workingDay.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { WorkingDaysService } from 'src/app/services/workingDays/working-days.service';

@Component({
  selector: 'app-working-days',
  templateUrl: './working-days.component.html',
  styleUrls: ['./working-days.component.css'],
  host: {
    class: 'dialog-component',
  },
})
export class WorkingDaysComponent implements OnInit {
  loading = false;
  loadingBundle = true;
  calendarId: string = '';

  workingDaysDataSource = new MatTableDataSource<WorkingDay>();
  displayedColumns: string[] = ['status', 'dayName'];
  displayedColumnsVerify: string[] = ['status', 'dayName'];

  showErrorMessage = false;
  errorMsg = '';

  constructor(
    public dialogRef: MatDialogRef<WorkingDaysComponent>,
    private workingDaysService: WorkingDaysService,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.calendarId = this.data;
    this.loadWorkingDays();
  }

  loadWorkingDays(): void {
    this.workingDaysService.get(this.calendarId).subscribe({
      next: (res: BaseResponse<WorkingDay[]>) => {
        this.workingDaysDataSource.data = res.messageRS;
        this.loading = false;
      },
      error: (_err: any) => {
        this.loading = false;
        this.exit('error');
      },
    });
  }

  editWorkingDays(): void {
    this.workingDaysService
      .edit(this.calendarId, this.workingDaysDataSource.data)
      .subscribe({
        next: (_res: BaseResponse<WorkingDay[]>) => {
          this.exit('success');
        },
        error: (_err: any) => {
          this.exit('error');
        },
      });
  }

  changeStatus(workingDay: WorkingDay): void {
    workingDay.status = !workingDay.status;
  }

  exit(message: any): void {
    this.dialogRef.close(message);
  }

  next(stepper: MatStepper): void {
    stepper.next();
  }

  getDayName(day: string): string {
    return (this.loc.days as any)[day.toLowerCase()];
  }
}

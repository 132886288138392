<div class="dark" *ngIf="loading"> <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;"></mat-spinner></div>

<div class="title">
    <div class="inner-title-container">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon class="action-button-icon-user" fontSet="material-icons-outlined">vpn_key</mat-icon>
        </button>
        <p class="dialog-title" i18n>{{loc.profile.changePassword}}</p>
    </div>    
    <button class="exit-button" (click)="exit('not updated')" >
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>

    <form class="form-step">
        <div class="data-box" style="margin-top:36px;">
            <div class="create-row">
                <mat-form-field appearance="fill" class="basic-data-input">
                    <mat-label i18n>{{loc.fields.currentPassword}}</mat-label>
                    <input matInput name="oldPassword" type="password" required [(ngModel)]="oldPassword" (input)="onSearchChange()"/>
                </mat-form-field>
            </div>
            <div class="create-row">
                <mat-form-field appearance="fill" class="basic-data-input">
                    <mat-label i18n>{{loc.fields.newPassword}}</mat-label>
                    <input matInput name="newPassword" type="password" required [(ngModel)]="newPassword" (input)="onSearchChange()"/>
                </mat-form-field>
            </div>  
            <div class="create-row">
                <mat-form-field appearance="fill" class="basic-data-input">
                    <mat-label i18n>{{loc.fields.confimPassword}}</mat-label>
                    <input matInput name="newPasswordRep" type="password" required [(ngModel)]="newPasswordRep" (input)="onSearchChange()"/>
                </mat-form-field>
            </div>
        </div>
        <ng-container *ngIf="showError">
            <div class="error-msg">
                <div class="error-icon">
                    <mat-icon fontSet="material-symbols-outlined">
                        error
                    </mat-icon>
                </div>
                <label i18n>
                    {{errorMessage}}
                </label>
            </div>
        </ng-container>
        <div class="dialog-nav-buttons">
            <button mat-fab class="button green-button" (click)="save()"  >
                <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon">save</mat-icon>
            </button>
        </div>
    </form>

    

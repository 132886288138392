import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreatePlatformComponent } from '../create-platform/create-platform.component';
import { Action, CreateButton, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';

@Component({
  selector: 'app-list-platforms',
  templateUrl: './list-platforms.component.html',
  styleUrls: ['./list-platforms.component.css'],
})
export class ListPlatformsComponent implements OnInit {


  loading = false;
  data: TableData[] = [];
  
  constructor(
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    private notificationsService: NotificationsService,
    public loc: LocalizationService
  ) {}


  ngOnInit(): void {
    this.getplatforms();
  }

  getplatforms() {
    this.loading = true;
    this.notificationsService.getPlatforms().subscribe({
      next: (res) => {
        this.data = res.messageRS as TableData[];
        this.loading = false;
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  }

  emptyState = {
    image: 'assets/img/platforms.png',
    message: this.loc.platforms.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      },
    ],
  };

  columns = [
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
    },
  ];

  actions: Action[] = [
    {
      icon: 'edit',
      tooltip: this.loc.platforms.edit,
      action: (element: any) => this.edit(element),
    },
    {
      icon: 'delete',
      tooltip: this.loc.platforms.delete,
      action: (element: any) => this.delete(element),
    },
  ];
  

  paginatorInfo = this.loc.platforms

  filters : TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    },
  ] 

  createButton : CreateButton = {
    tooltip: this.loc.platforms.create,
    action: () => this.create(),
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreatePlatformComponent,
      {},
      'medium-large'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.platforms, 'f')
        );
        this.getplatforms();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.errorCreatingPlatform
        );
      }
    });
  }

  edit(element: any) {
    let data: EditModalData = {
      title: this.loc.platforms.edit,
      icon: 'notifications',
      fields: [
        { label: this.loc.fields.description, 
          value: element.description, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        }]
    }

    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAction(element, result)
      }
    });
  }

  editAction(row: any, result: any){
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value

    this.notificationsService.editPlatform(row.id, newDescription).subscribe(
      {
        next: (res) => {
          this.loading = false;
          if(res.statusRS?.code === "0"){
             this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.platforms, 'f')
        );
        this.getplatforms();
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.errorCreatingPlatform
            );
          }
        }, 
        error: (err) => {
          this.loading = false;
          this.logoutDialogService.openLogoutDialog(err);
          if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.errorCreatingPlatform
            );
          }
        }
      }
    )
  }

  deleteAction(element: any){
    this.loading = true;
    this.notificationsService.deletePlatform(element.id).subscribe(
       {
        next: (res) => {
          setTimeout(() => {
            if(res.statusRS?.code === "0"){
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.platforms, 'f')
              );
              this.getplatforms();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.platforms)
              );
            }
          }, 200);
        }, 
        error: (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          this.logoutDialogService.openLogoutDialog(err);
          if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.platforms)
            );
          }
        }
       }
      )
    }

  delete(element: any) {
    let data: DeleteModalData = {
      title: this.loc.platforms.delete,
      icon: 'notifications',
      confirmDeleteMessage: this.loc.platforms.confirmDeletePlatform,
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        this.deleteAction(element)
      }
    });
  }

}
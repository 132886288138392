import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { DisaggregationStepTemplate } from 'src/app/models/posting-schemes/DisaggregationStepTemplate.model';

import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateDisaggregationStepTemplateComponent } from '../create-disaggregation-step-template/create-disaggregation-step-template.component';
import { TableData, Column, EmptyState, TableFilter, Action, CreateButton } from 'src/app/utils/table/table.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-list-disaggregation-step-templates',
  templateUrl: './list-disaggregation-step-templates.component.html',
  styleUrls: ['./list-disaggregation-step-templates.component.css'],
  host: {
    class: 'component',
  },
})
export class ListDisaggregationStepTemplatesComponent implements OnInit {

  constructor(
    public postingSchemesService: PostingSchemesService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}


  ngOnInit(): void {
    this.loadTemplates();
  }

  loading = false;
  data: TableData[] = [];

  columns: Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
    },
    {
      title: this.loc.tableHeaders.subject,
      key: 'subject',
    },
    {
      title: this.loc.tableHeaders.operation,
      key: 'operation',
    },
    {
      title: this.loc.tableHeaders.suboperation,
      key: 'suboperation',
    }
  ]

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.disaggregation.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create()
      }
    ]
  }

  paginatorInfo = this.loc.disaggregation

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    }
  ]


  actions: Action[] = [
    {
      icon: 'edit',
      tooltip: this.loc.disaggregation.edit,
      action: (element: DisaggregationStepTemplate) => this.edit(element)
    },
    {
      icon: 'delete',
      tooltip: this.loc.disaggregation.delete,
      action: (element: DisaggregationStepTemplate) => this.delete(element)
    }
  ]

  loadTemplates() {
    this.loading = true;
    this.postingSchemesService.getDisaggregationStepTemplates().subscribe(
      (res: BaseResponse) => {
        this.data = res.messageRS as any as TableData[]; 
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  createButton : CreateButton = {
    tooltip: this.loc.disaggregation.create,
    action: () => this.create()
  }


  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateDisaggregationStepTemplateComponent,
      {},
      'large'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.template)
        );
        this.loadTemplates();
      } else if (result && result !== 'not created') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.template)
        );
      }
    });
  }

  edit(element: DisaggregationStepTemplate) {
    let data: EditModalData = {
      title: this.loc.template.edit,
      icon: 'signpost',
      fields: [
        { label: this.loc.tableHeaders.description, 
          value: element.description!!, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== 'not updated' && result) {
        this.loading = true;
        this.editAction(element, result)
      }
    });
  }

  editAction(row: any, result: any){
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
    this.postingSchemesService
    .editDisaggregationStepTemplate(row.id, newDescription)
    .subscribe({
      next: (res: BaseResponse) => {
        this.loading = false;
        if (res.statusRS?.code == '0') {
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('update', this.loc.template)
          );
          this.loadTemplates();
        } else {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.template)
          );
        }
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  }

  delete(element: DisaggregationStepTemplate) {
    let dataModal: DeleteModalData = {
      title: this.loc.template.delete,
      icon: 'signpost',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.template),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      dataModal,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.postingSchemesService
          .deleteDisaggregationStepTemplate(element.id!)
          .subscribe(
            (res: BaseResponse) => {
              if (res.statusRS?.code === '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.template)
                );
                this.loadTemplates();
              } else if (res.statusRS?.code === '-91684') {
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.templateIsUsed
                );
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.template)
                );
              }
            },
            (err) => {
              this.logoutDialogService.openLogoutDialog(err);
            }
          );
      }
    });
  }
}

<div class="general-operations-div">
    <div style="display: flex">
      <button mat-icon-button class="back" (click)="goBack()">
        <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>
      <button mat-fab class="page-icon-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon"
          >psychology</mat-icon
        >
       
      </button>
      <ng-container *ngIf="!loading">
        <div class="title-container">
          <label class="title-mid-text"> {{ metadata?.description}}</label>
        </div>
      </ng-container>
    </div>
    <div *ngIf="(!loading)" style="display: flex; flex-direction: row-reverse; gap: 0.5rem">
      <ng-container>
        <button
          mat-fab
          class="button green-color"
          (click)="deleteMetadata()"
          i18n-matTooltip
          [matTooltip]="isInput? loc.inputMetadata.delete : loc.outputMetadata.delete"
        >
          <mat-icon
            class="action-button-icon margin-icon"
            fontSet="material-icons-outlined"
          >
            delete
          </mat-icon>
        </button>
      </ng-container>
      <ng-container>
        <button
          mat-fab
          class="button green-color"
          (click)="editMetadata()"
          i18n-matTooltip
          [matTooltip]="isInput? loc.inputMetadata.edit : loc.outputMetadata.edit"
        >
          <mat-icon
            class="action-button-icon margin-icon"
            fontSet="material-icons-outlined"
          >
            edit
          </mat-icon>
        </button>
      </ng-container>
      <ng-container>
        <button
          mat-fab
          class="button green-color"
          (click)="showDatasourceData()"
          i18n-matTooltip
          [matTooltip]="loc.fields.datasourceData"
        >
          <mat-icon
            class="action-button-icon margin-icon"
            fontSet="material-icons-outlined"
          >
            code
          </mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
  
  
  
  <mat-card class="details-card">
    <div class="flex-container">
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.id
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.id }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.type
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.type | titlecase }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.controlType
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.controlType | titlecase}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.defaultValue
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.defaultValue ? metadata?.defaultValue : '-'}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.validation
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.validation? metadata?.validation : '-'}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.fields.hidden
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.isHidden === 1 ? loc.common.yes : loc.common.no}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.fields.isMandatory
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.isMandatory === 1 ? loc.common.yes : loc.common.no}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.fields.isEditable
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.isEditable === 1 ? loc.common.yes : loc.common.no }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.fields.datasource
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.dataSource? (metadata?.dataSource == 'STATIC' ? loc.common.static : loc.common.dynamic) : '-'}}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.fields.checkout
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ metadata?.checkout === '1' ? loc.common.yes : loc.common.no }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
      </div>
    </div>
  </mat-card>
  
  <mat-tab-group class="tab-bar"     
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChange()">
  
    <mat-tab [label]="loc.data.plural" i18n-label>
      <app-list-metadata-details [stepId]="stepId" [metadataId]="metadata?.id" (notifyParent)="receiveNewDatasourceData($event)" [updateDataSourceData]="notifyUpdateToChild" [controlType]="metadata!.controlType"></app-list-metadata-details>
    </mat-tab>

  </mat-tab-group>
  
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Campaign } from 'src/app/models/campaign/campaign.model';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { TokenService } from '../token/token.service';
import { BaseRequest } from 'src/app/models/base/base-request.model';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  readonly rootURL = environment.apiUrl;
  constructor(
    private tokenService: TokenService,
    private http: HttpClient,
    private baseService: BaseService
  ) {}

  getCampaigns() {
    
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        }),
        withCredentials: false,
      };
      return this.http.get(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/campaigns/campaign/0/list-campaigns',
        HTTP_OPTIONS
      );
    } 


  createCampaign(campaign: Campaign) {
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = campaign;

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };
      return this.http.post(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/campaigns/campaign/0/create-campaign',
        baseRequest,
        HTTP_OPTIONS
      );
    }


  deleteCampaign(campaign: string) {
    var baseRequest: BaseRequest = new BaseRequest();

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };

      return this.http.put(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/campaigns/campaign/' +
          encodeURI(campaign) +
          '/delete-campaign',
        baseRequest,
        HTTP_OPTIONS
      );
    } 


  editCampaign(campaign: Campaign) {
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = campaign;

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };

      return this.http.put(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/campaigns/campaign/x/edit-campaign',
        baseRequest,
        HTTP_OPTIONS
      );
    } 

}

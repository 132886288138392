import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CreateServiceModeComponent } from '../digital-services/create-service-mode/create-service-mode.component';
import { ServiceMode } from '../models/attributes/ServiceMode.model';
import { DeleteModalData } from '../models/delete-modal/delete-modal-data.model';
import { ServiceTemplate } from '../models/digital-services/ServiceTemplate.model';
import { EditModalData } from '../models/edit-modal/edit-modal-data.model';
import { AttributesService } from '../services/attributes/attributes.service';
import { DialogService } from '../services/dialog/dialog.service';
import { ExtensionPointsService } from '../services/extension-points/extension-points.service';
import { LocalizationService } from '../services/localization/localization.service';
import { SnackbarService } from '../services/snackbar/snackbar.service';
import { DeleteModalComponent } from '../utils/delete-modal/delete-modal.component';
import { EditModalComponent } from '../utils/edit-modal/edit-modal.component';
import { EmptyState, TableData, Column, PaginatorInfo, TableTransformer, TableFilter, CreateButton, onRowClick, Action } from '../utils/table/table.model';
import { User } from '../models/user.model';
import { UsersService } from '../services/users/users.service';
import { CreateServiceTemplateComponent } from './create-service-template/create-service-template.component';
import { EditTemplateComponent } from '../agent/edit-template/edit-template.component';
import { ServiceTemplateAgentType } from '../models/agents/service-template-agent-type.model';
import { SafeHTMLGeneratorService } from '../utils/safeHTML/safe-htmlgenerator.service';

@Component({
  selector: 'app-service-templates',
  templateUrl: './service-templates.component.html',
  styleUrls: ['./service-templates.component.css'],
  host:{
    class:'component'
  }
})
export class ServiceTemplatesComponent implements OnInit {

  loading: boolean = true;
  
  serviceTemplates: ServiceTemplate[] = [];

  constructor(
    public loc: LocalizationService,
    public usersService: UsersService,
    public attributesService: AttributesService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private htmlGenerator: SafeHTMLGeneratorService,
    public router: Router
  ) {}

  emptyState: EmptyState = {
    image: 'assets/img/tipos-agentes.png',
    message: this.loc.serviceTemplates.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      },
    ],
  };

  data: TableData[] = [];

  columns: Column[] = [
    {
      key: 'imageId',
      title: '',
      centered: true,
      sortable: false,
    },
    {
      key: 'templateId',
      title: this.loc.tableHeaders.id,
      sortable: true,
    },
    {
      key: 'description',
      title: this.loc.tableHeaders.description,
      sortable: true,
    },
    {
      key: 'frameBankingTemplateId',
      title: this.loc.tableHeaders.frameTemplate,
      sortable: true,
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.serviceTemplates;
  transformers: TableTransformer = {
    imageId: (value: any) => {
      return this.htmlGenerator.imageOrDefaultIcon(value, 'domain', 'integration image');}
  };
  
  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'templateId',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    },
    {
      label: this.loc.tableHeaders.frameTemplate,
      key: 'frameBankingTemplateId',
      type: 'text',
      placeholder: this.loc.tableHeaders.frameTemplate
      
    },
  ];
  actions: Action[] = [
   
    {
      tooltip: this.loc.serviceTemplates.edit,
      icon: 'edit',
      action: (row) => this.editTemplate(row),
    },
    {
      tooltip: this.loc.serviceTemplates.delete,
      icon: 'delete',
      action: (row) => this.delete(row),
    },
  ];
  createButton: CreateButton = {
    tooltip: this.loc.serviceTemplates.create,
    action: () => this.create(),
  };

  ngOnInit(): void {
    this.loadServiceTemplates();
    this.usersService.getFrameTemplates().subscribe({});
  
  }

 

  loadServiceTemplates() {
    this.usersService.getServiceTemplates().subscribe({
      next: (res: any) => {
        this.serviceTemplates = res.messageRS as any as ServiceTemplate[];
        this.data = this.serviceTemplates as unknown as TableData[];
        this.loading = false;
    
      },
    });
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateServiceTemplateComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.serviceTemplates, 'm')
        );
        this.loadServiceTemplates();

      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.serviceTemplates)
        );
      }
      if (result === 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.serviceModeAlreadyExist
        );
      }
    });
  }

  editTemplate(row: ServiceTemplateAgentType): void{
    console.log(row,"ROW");
    const dialogRef = this.dialogService.openDialog(
      EditTemplateComponent,
      { id: row.templateId,
        description: row.description,
        status: row.status,
        imageId: row.imageId,
        allowed: false,
        
      },
      'medium'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'updated') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.template)
        );
        this.loadServiceTemplates();

        this.loading = true;
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.template)
        );
      }
    });
  }
  

  delete(row:any) {
    let data: DeleteModalData = {
      title: this.loc.serviceTemplates.delete,
      icon: 'paid',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.serviceTemplates),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.usersService
          .deleteServiceTemplate(row.templateId)
          .subscribe({
            next: (res: any) => {
              this.loading = true;
              switch (res.statusRS?.code) {
                case '0':
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage(
                      'delete',
                      this.loc.serviceTemplates,
                      'm'
                    )
                  );
                  this.loadServiceTemplates();
                  break;
                default:
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError('delete', this.loc.serviceTemplates)
                  );
                  break;
              }
              this.loading = false;
            },
            error: (_err: any) => {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.serviceTemplates)
              );
            },
          });
      }
    });
  }






  //FILTERS
}

<!-- elegir label, action result, description-->
<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
</div>

<div class="title">
    <div class="inner-title-container">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">signpost</mat-icon>
            <mat-icon class="action-button-icon-add">add</mat-icon>
        </button>
        <label class="dialog-title" i18n>{{loc.step.create}}</label>
    </div>
    <button class="exit-button" (click)="exit('not created')"  >
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>
    <mat-horizontal-stepper class="horizontal-stepper field-create-stepper" [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
        [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex">
        <ng-template matStepperIcon="done" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">account_tree</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf=" this.firstFormGroup.get('isReverse')?.value && index == 2">account_tree</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == (this.firstFormGroup.get('isReverse')?.value ? 3 : 2)">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="edit" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">account_tree</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf=" this.firstFormGroup.get('isReverse')?.value && index == 2">account_tree</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == (this.firstFormGroup.get('isReverse')?.value ? 3 : 2)">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="number" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">account_tree</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="this.firstFormGroup.get('isReverse')?.value && index == 2">account_tree</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == (this.firstFormGroup.get('isReverse')?.value ? 3 : 2)">check_box</mat-icon>
        </ng-template>

        <mat-step [stepControl]="firstFormGroup" [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
            <form [formGroup]="firstFormGroup" class="form-step">
                <div class="data-box">
                <div class="create-row">
                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label i18n>{{loc.fields.template}}</mat-label>
                        <mat-select required formControlName="stepTemplate" (selectionChange)="changeSelectionTemplate($event)">
                            <mat-option *ngFor="let step of stepTemplates" [value]="step" i18n>{{step.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label i18n>{{loc.fields.isReverse}}</mat-label>
                        <mat-select required formControlName="isReverse">
                            <mat-option [value]="true" i18n>
                                {{loc.common.yes}}
                            </mat-option>
                            <mat-option [value]="false" i18n>
                                {{loc.common.no}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    
                </div>

                <div class="create-row">
                    <div class="group-input-container">
                        <mat-form-field class="group-input" appearance="fill">
                            <mat-label i18n>{{loc.fields.group}}</mat-label>
                            <mat-select required formControlName="group">
                                <mat-option *ngFor="let group of groups" [value]="group" i18n>{{group.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-fab class="add-button" (click)="addGroup()" i18n-matTooltip [matTooltip]="loc.flows.addGroup">
                            <mat-icon>add</mat-icon>
                        </button>

                    </div>

                    <div class="group-input-container">
                        <mat-form-field class="group-input" appearance="fill">
                            <mat-label i18n>{{loc.fields.errorGroup}}</mat-label>
                            <mat-select required formControlName="errorGroup">
                                <mat-option *ngFor="let group of errorGroups" [value]="group" i18n>{{group.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-fab class="add-button" (click)="addGroup()" i18n-matTooltip [matTooltip]="loc.flows.add">
                            <mat-icon>add</mat-icon>
                        </button>

                    </div>
                </div> 

                <div class="create-row">

                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label i18n>{{loc.fields.postingSign}}</mat-label>
                        <mat-select required formControlName="postingSign">
                            <mat-option *ngFor="let sign of signs" [value]="sign" i18n>{{sign.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label i18n>{{loc.fields.subject}}</mat-label>
                        <input matInput formControlName="subject" required>
                    </mat-form-field>
                    
                    
                </div>

                <div class="create-row">
                    
                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label i18n>{{loc.fields.operation}}</mat-label>
                        <input matInput formControlName="operation" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.suboperation}}</mat-label>
                        <input matInput formControlName="suboperation" required>
                    </mat-form-field>
                </div>

                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" (click)="next(stepper)">
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>
        
        <mat-step [stepControl]="secondFormGroup" [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.flows}}</ng-template>
            <form [formGroup]="secondFormGroup" class="form-step">
                <div class="data-box">
                    <div class="create-row">
                        <mat-form-field style="width: 475px;" appearance="fill">
                            <mat-label i18n>{{loc.fields.messageOnline}}</mat-label>
                            <mat-select required formControlName="messageOnlineFlow" matSelectFilter>
                                <mat-option *ngFor="let flow of messageOnlineFlows" [value]="flow" i18n>{{flow.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="create-row">
                        <mat-form-field style="width: 475px" appearance="fill">
                            <mat-label i18n>{{loc.fields.dispatchOnline}}</mat-label>
                            <mat-select required formControlName="dispatchOnlineFlow" matSelectFilter>
                                <mat-option *ngFor="let flow of dispatchOnlineFlows" [value]="flow" i18n>{{flow.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="create-row">
                        <mat-form-field style="width: 475px;" appearance="fill">
                            <mat-label i18n>{{loc.fields.respParseOnline}}</mat-label>
                            <mat-select required formControlName="respParseOnlineFlow">
                                <mat-option *ngFor="let flow of respParseOnlineFlows" [value]="flow" i18n>{{flow.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious>
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                      </button>
                    <button mat-fab class="button green-button" (click)="next(stepper)">
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>

        <mat-step *ngIf="firstFormGroup.get('isReverse')?.value" [stepControl]="thirdFormGroup" [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.reverseFlows}}</ng-template>
            <form [formGroup]="thirdFormGroup" class="form-step">
                <div class="data-box">
                    <div class="create-row">
                        <mat-form-field style="width: 475px;" appearance="fill">
                            <mat-label i18n>{{loc.fields.messageOnline}}</mat-label>
                            <mat-select required formControlName="reverseMessageOnlineFlow" matSelectFilter>
                                <mat-option *ngFor="let flow of messageOnlineFlows" [value]="flow" i18n>{{flow.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="create-row">
                        <mat-form-field style="width: 475px" appearance="fill">
                            <mat-label i18n>{{loc.fields.dispatchOnline}}</mat-label>
                            <mat-select required formControlName="reverseDispatchOnlineFlow" matSelectFilter>
                                <mat-option *ngFor="let flow of dispatchOnlineFlows" [value]="flow" i18n>{{flow.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="create-row">
                        <mat-form-field style="width: 475px;" appearance="fill">
                            <mat-label i18n>{{loc.fields.respParseOnline}}</mat-label>
                            <mat-select required formControlName="reverseRespParseOnlineFlow">
                                <mat-option *ngFor="let flow of respParseOnlineFlows" [value]="flow" i18n>{{flow.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious>
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                      </button>
                    <button mat-fab class="button green-button" (click)="next(stepper)">
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>

        <mat-step [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
            <div class="form-step">
                <div class="verify-large">
                    <div class="flex-container-vertical flex-container-vertical-modal">
                        <div>
                            <label class="card-name" i18n>{{loc.step.new}}</label>
                        </div>
                        
                            <div class="details-package-large details-grid">
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.template}}</div>
                                    <div class="card-value">{{firstFormGroup.get('stepTemplate')?.value.description}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.isReverse}}</div>
                                    <div class="card-value">{{firstFormGroup.get('isReverse')?.value == true ? loc.common.yes : loc.common.no}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.group}}</div>
                                    <div class="card-value">{{firstFormGroup.get('group')?.value?.description}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.errorGroup}}</div>
                                    <div class="card-value">{{firstFormGroup.get('errorGroup')?.value?.description}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.postingSign}}</div>
                                    <div class="card-value">{{firstFormGroup.get('postingSign')?.value.description}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.subject}}</div>
                                    <div class="card-value">{{firstFormGroup.get('subject')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.operation}}</div>
                                    <div class="card-value">{{firstFormGroup.get('operation')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.suboperation}}</div>
                                    <div class="card-value">{{firstFormGroup.get('suboperation')?.value}}</div>
                                </div>
                            </div>
                            <div>
                                <label class="card-name" i18n>{{loc.flows.new}}</label>
                            </div>
                            <div class="details-package-large second-grid">
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.messageOnline}}</div>
                                    <div class="card-value" style="max-width: 541px !important;">{{secondFormGroup.get('messageOnlineFlow')?.value.flowId}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.dispatchOnline}}</div>
                                    <div class="card-value" style="max-width: 541px !important;">{{secondFormGroup.get('dispatchOnlineFlow')?.value.flowId}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.respParseOnline}}</div>
                                    <div class="card-value" style="max-width: 541px !important;">{{secondFormGroup.get('respParseOnlineFlow')?.value.flowId}}</div>
                                </div>
                            </div>
                            <div *ngIf="firstFormGroup.get('isReverse')?.value">
                                <label class="card-name" i18n>{{loc.flows.newReverse}}</label>
                            </div>
                            <div *ngIf="firstFormGroup.get('isReverse')?.value" class="details-package-large second-grid">
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.messageOnline}}</div>
                                    <div class="card-value" style="max-width: 541px !important;">{{thirdFormGroup.get('reverseMessageOnlineFlow')?.value.flowId}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.dispatchOnline}}</div>
                                    <div class="card-value" style="max-width: 541px !important;">{{thirdFormGroup.get('reverseDispatchOnlineFlow')?.value.flowId}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.respParseOnline}}</div>
                                    <div class="card-value" style="max-width: 541px !important;">{{thirdFormGroup.get('reverseRespParseOnlineFlow')?.value.flowId}}</div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious  >
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-fab class="button green-button" (click)="create()"  >
                        <mat-icon class="stepper-item-icon" fontSet="material-icons-outlined">save</mat-icon>
                    </button>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>


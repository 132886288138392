import { ValidatorFn } from "@angular/forms";

export type Step = FormStep | StyleStep | ListStep;

export type FormStep = {
    title: string;
    icon: string;
    type: 'form';
    form: StepInput[];
}

export type StyleStep = {
    title: string;
    icon: string;
    type: 'style';
    key: string;
}

export type ListStep = {
    title: string;
    icon: string;
    type: 'list';
    list: any[];
}

export type VerifyData = {
    key: string;
    value: any;
}

export type StepInput = StepInputText | StepInputNumeric | StepInputSelect | StepInputTime | StepInputDate | StepInputTextarea

export type StepInputText = {
    type: 'text',
    key: string,
    label: string,
    example?: string,
    validators?: ValidatorFn[],
    error?: string,
    width?: string,
    height?: string,
}

export type StepInputNumeric = {
    type: 'numeric',
    key: string,
    label: string,
    validators?: ValidatorFn[],
    error?: string,
    width?: string,
    height?: string,
}

export type StepInputTime = {
    type: 'time',
    key: string,
    label: string,
    validators?: ValidatorFn[],
    width?: string,
    height?: string,
}

export type StepInputSelect = {
    type: 'select',
    key: string,
    label: string,
    options: Option[],
    validators?: ValidatorFn[],
    loadOptionsForNextSelect?: LoadOptionsForNextSelect;
    loadTemplateData?: LoadTemplateData;
    nextSelectKey?: string;
    width?: string,
    height?: string,
}

export type StepInputDate = {
    type: 'date',
    key: string,
    label: string,
    validators?: ValidatorFn[],
    width?: string,
    height?: string,
}

export type StepInputTextarea = {
    type: 'textarea',
    key: string,
    label: string,
    validators?: ValidatorFn[], 
    width?: string,
    height?: string,
}

export type LoadOptionsForNextSelect = <T>(selectedDataFromSelect: T) => Option[]

export type LoadTemplateData = <T>(selectedDataFromSelect: any) =>  Promise<myPair[]>

export type Option = {
    value: any,
    description: string
    image?: string
}

export type  myPair = [string,string];
export interface CreateModalData {
    title: string;
    icon: string;
    steps: Step[];
    verify: VerifyGroup[];
    size: 'x-small' | 'small' | 'medium' | 'medium-large' | 'large';
}

export type VerifyGroup = {
    title: string;
    type: 'form' | 'style';
    elements : string [];
    descriptions?: string[];
}

export function removeStepInputFromSteps(key: string, steps: Step[]): void {
    for (const step of steps) {
        if (step.type === 'form') {
            step.form = step.form.filter((input) => input.key !== key);
        }
    }
}

export function  removeElementFromVerifyGroups(groups: VerifyGroup[], elementToRemove: string): VerifyGroup[] {
    return groups.map(group => {
      const index = group.elements.indexOf(elementToRemove);
      if (index !== -1) {
        group.elements.splice(index, 1);
      }
      return group;
    });
}

export function transformListToOptions(
    inputList: any[],
    keyName: string,
    valueName: string,
    imageName?: string
): Option[] {
    return inputList.map((item) => {
        const option: Option = {
            value: item[keyName],
            description: item[valueName],
        };

        if (imageName && item[imageName]) {
            option.image = item[imageName];
        }

        return option;
    });
}

export function setOptions(stepNum : number, key: string, values: Option[], modalData: CreateModalData ){
    let step = (modalData.steps[stepNum] as any)
    let form = step.form.find((x: { key: string }) => x.key == key)
    if (form) {
        form.options = values;
    } else {
        // ERROR
    }

}

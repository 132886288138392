import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ServiceTemplateAgentType } from 'src/app/models/agents/service-template-agent-type.model';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { ImageUploaderService } from 'src/app/services/image-uploader/image-uploader.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { UsersService } from 'src/app/services/users/users.service';
import { environment } from 'src/environments/environment';


declare var require: any;
const sha1 = require('sha1');

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css'],
  host: {
    class: 'dialog-component',
  },
})
export class CreateTemplateComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  newTemplate: ServiceTemplateAgentType = {};
  agentTypeId: string = '';
  templates:any[] = [];
  _status: string[] = [this.loc.common.active, this.loc.common.inactive];
  image?: string;
  selectedFile: File = new File([], '');

  cloudinary = {
    config: environment.cloudinary,
 };

  
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loc: LocalizationService,
    private userService: UsersService,
    private imageUploaderService: ImageUploaderService,
    public snackbarService: SnackbarService,

  ) { }

  ngOnInit(): void {
    this.templates = this.data.templates;
    this.firstFormGroup = this.formBuilder.group({
      id: ['', [Validators.required, ]],
      description: ['', Validators.required],
      status: ['', [Validators.required]],
    });
    this.agentTypeId = this.data.agentTypeId;
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

  next(stepper: MatStepper): void {
    this.newTemplate = {
      templateId: this.firstFormGroup.get('id')!.value,
      description: this.firstFormGroup.get('description')!!.value,
      status: this.firstFormGroup.get('status')!!.value,
    };
    stepper.next();
  }

  createTemplate(): void {
    this.loading = true;
    let state = this.firstFormGroup.get('status')!!.value;
    if(state === this.loc.common.active){
      state = 1;
    } else {
      state = 0;
    }
    this.newTemplate.status = state;
    this.newTemplate.agentType = this.agentTypeId;
    this.userService.createServiceTemplateAllowed(this.newTemplate).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          this.loading = false;
          if (res.statusRS?.code === '0') {
            this.exit('created');
          } else if(res.statusRS?.code === '-91957'){
            this.snackbarService.openErrorSnackbar(this.loc.errors.templateAlreadyExists);
          } else {
            this.exit(res.statusRS?.code as string);
          }
        }, 200);
      },
      (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        console.log(err);
        if (err.status !== 401) {
          this.exit('error');
        }
      }
    )
  }


  onFileSelect($event: any): void {
    this.selectedFile = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = (_event) => {
      this.image = reader.result?.toString() || '';
    };
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
  
  


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { ServiceTemplateAgentType } from 'src/app/models/agents/service-template-agent-type.model';

@Component({
  selector: 'app-agent-type-details',
  templateUrl: './agent-type-details.component.html',
  styleUrls: ['./agent-type-details.component.css'],
  host: {
    class: 'component',
  },
})
export class AgentTypeDetailsComponent implements OnInit {
  loading: boolean = true;

  agentTypeId: string = ''
  agentTypeDescription: string = ''

  templates: ServiceTemplateAgentType[] = [];

  constructor(
    public loc: LocalizationService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UsersService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.agentTypeId = params['id']
      this.agentTypeDescription = params['description']
      this.loadDetails();
    });
  }

  goBack(): void {
    this.router.navigate(['/agent']);
  }

  loadDetails(): void {
    this.loading = true;
    this.userService.getServiceTemplatesAllowed(this.agentTypeId).subscribe(
      {
       next: (res: BaseResponse) => {
          if (res.statusRS?.code === '0') {
            let parameters: any[] = [];
            parameters = res.messageRS as unknown as any;
            if(parameters.length > 0){
              for(let i = 0 ; i<parameters.at(0).length ; i++){
                this.templates.push(parameters.at(0).at(i));
              }
            }
            this.loading = false;
          }
      },
      error: (err: any) => {
        console.log(err);
      }
      }
    )
  }

}

import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CreateBankComponent } from 'src/app/banks/create-bank/create-bank/create-bank.component';
import { Bank } from 'src/app/models/Banks/bank.model';
import { PaymentButtonFields } from 'src/app/models/payment-buttons/paymentButtonFields.model';
import { PaymentButtonTypeFields } from 'src/app/models/payment-buttons/paymentButtonTypeFields.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { PaymentButtonService } from 'src/app/services/payment-button/payment-button.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-payment-button-type-fields',
  templateUrl: './create-payment-button-type-fields.component.html',
  styleUrls: ['./create-payment-button-type-fields.component.css'],
  host: {
    class: 'dialog-component',
  },
})
export class CreatePaymentButtonTypeFieldsComponent implements OnInit {
  loading = true;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  actions: any[] = [];
  stageId = '';
  ids = [];
  paymentButtonFields: PaymentButtonFields[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateBankComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private paymentButtonService: PaymentButtonService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      buttonField: ['', Validators.required],
      mandatoryField: ['', Validators.required],
    });
    this.loadPaymentButtonFields();
    this.loading = false;
  }

  loadPaymentButtonFields() {
    this.loading = true;
    this.paymentButtonService.getFields().subscribe({
      next: (data: BaseResponse<PaymentButtonFields[]>) => {
        this.paymentButtonFields = data.messageRS;
      },
      error: (err: any) => {
        this.loading = false;
      },
    });
  }

  create() {
    this.loading = true;
    let paymentButtonType: PaymentButtonTypeFields = {
      id: this.data,
      fieldFk: this.firstFormGroup.get('buttonField')!.value.id,
      isMandatory:
        this.firstFormGroup.get('mandatoryField')!.value ===
        this.loc.isMandatory.mandatory
          ? 1
          : 0,
    };
    this.paymentButtonService.createTypeField(paymentButtonType).subscribe({
      next: (res: BaseResponse<PaymentButtonTypeFields>) => {
        this.loading = false;
        switch (res.statusRS?.code) {
          case '0':
            this.exit('created');
            break;
          case '-91745':
            this.exit('duplicate');
            break;
          default:
            this.exit('error');
            break;
        }
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      },
    });
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }
  emptyField(text: string | undefined): string {
    if (text === '' || text === undefined) return '-';
    return text;
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

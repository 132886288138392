import { Component, OnInit } from '@angular/core';
import { BanksService } from '../services/banks/banks.service';
import { DialogService } from '../services/dialog/dialog.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../services/snackbar/snackbar.service';
import { LocalizationService } from '../services/localization/localization.service';
import { LogoutDialogService } from '../services/logout-confirmation/logout-dialog.service';
import { Bank } from '../models/Banks/bank.model';
import { DeleteModalData } from '../models/delete-modal/delete-modal-data.model';
import { CreateBankComponent } from './create-bank/create-bank/create-bank.component';
import { EditBankComponent } from './edit-bank/edit-bank.component';
import { BaseResponse } from '../models/responses.model';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from '../utils/table/table.model';
import { DeleteModalComponent } from '../utils/delete-modal/delete-modal.component';

@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.css'],
  host: {
    class: 'component',
  },
})
export class BanksComponent implements OnInit {
  loading = true;
  data: TableData[] = [];
  
  constructor(
    public banksService: BanksService,
    public dialogService: DialogService,
    public router: Router,
    public snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.loadBanks();
  }

  loadBanks(): void {
    this.banksService.getBanks().subscribe({
      next: (res: BaseResponse<Bank[]>) => {
        this.data = res.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  }


  emptyState : EmptyState = {
    image: 'assets/img/listado-bancos.png',
    message: this.loc.banks.emptyState,
    buttons: [
      {
        text: this.loc.banks.createBank,
        action: () => this.create(),
      }
    ]
  }

  columns : Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'bankId',
    },
    {
      title: this.loc.tableHeaders.name,
      key: 'bankName',
    },
    {
      title: this.loc.tableHeaders.country,
      key: 'bankCountry',
    },
  ]

  paginatorInfo = this.loc.banks 

  filters : TableFilter[] = [
    {
      label: this.loc.tableHeaders.name,
      key: 'bankName',
      type: 'text',
      placeholder: this.loc.tableHeaders.name,
    },
    {
      label: this.loc.tableHeaders.country,
      key: 'bankCountry',
      type: 'text',
      placeholder: this.loc.tableHeaders.country,
    },
    {
      label: this.loc.tableHeaders.id,
      key: 'bankId',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    }
  ]

  actions: Action[] = [
    {
      icon: 'more_horiz',
      action: (row: Bank) => this.goToDetails(row),
      tooltip: this.loc.common.details
    },
    {
      icon: 'edit',
      action: (row: Bank) => this.edit(row),
      tooltip: this.loc.banks.edit
    },
    {
      icon: 'delete',
      action: (row: Bank) => this.delete(row),
      tooltip: this.loc.banks.delete
    }
  ]


  createButton: CreateButton = {
    tooltip: this.loc.banks.createBank,
    action: () => {
      this.create();
    }
  }

  onRowClick = {
    action: (row:Bank) => {this.goToDetails(row)}
  }

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateBankComponent,
      this.data as Bank[],
      'large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.banks)
        );
        this.loadBanks();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.banks)
        );
        this.loadBanks();
      }
    });
  }

  edit(row: Bank): void {
    const dialogRef = this.dialogService.openDialog(
      EditBankComponent,
      row,
      'medium'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'updated') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.banks)
        );
        this.loadBanks();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.banks)
        );
      }
    })      
  }

  delete(row:Bank): void {
    let data: DeleteModalData = {
      title: this.loc.banks.delete,
      icon: 'account_balance',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.banks),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.banksService.deleteBank(row).subscribe({
          next: (res: BaseResponse<Bank>) => {
            this.loading = true;

            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.banks)
              );
              this.loadBanks();
            } else if (res.statusRS?.code == '-91627') {
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.deleteBank
              );
            } else if (res.statusRS?.code == '-91628') {
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.bankUsedInSegment
              );
            } else if (res.statusRS?.code == '-91629') {
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.bankUsedByAgent
              );
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.banks)
              );
            }
            this.loading = false;
          },
          error: (_err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.banks)
            );
          },
        });
      }
    });
  }

  goToDetails(row: Bank): void {
    this.router.navigate(['bank-details'], {
        queryParams: {
          bankId: row.bankId,
        },
        queryParamsHandling: 'merge',
      });
  }
}
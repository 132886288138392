import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { SettlementMode } from 'src/app/models/settlement-modes/settlement-mode.model';


@Injectable({
  providedIn: 'root'
})
export class SettlementModesService {

  readonly baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient, 
    private tokenService: TokenService
  ) {}

  getSettlementModes() {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };
    return this.http.get(
      this.baseUrl + 'RSAdaptorFE/api/v1/paycorr/posting-schemes/settlement/X/list-settlement-modes',
      HTTP_OPTIONS
    );
  }

  createSettlementMode(settlementMode: SettlementMode) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id: settlementMode.id,
      hour: settlementMode.hour,
      dayAfterExecution: settlementMode.dayAfterExecution,
      description: settlementMode.description,
    };

    return this.http.post(
      this.baseUrl +
        'RSAdaptorFE/api/v1/paycorr/settlement-modes/settlement/x/create',
        baseRequest,
        HTTP_OPTIONS
    );
  }

  editSettlementMode(settId: string, descrip: string){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
        settlementId: settId,
        description: descrip
      }
    };

    return this.http.put(
      this.baseUrl +
        'RSAdaptorFE/api/v1/paycorr/settlement-modes/settlement/x/update',undefined,
        HTTP_OPTIONS
    );
  }

  deleteSettlementMode(id: string){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
        settlementId: id,
      }
    };
    return this.http.delete(
      this.baseUrl +
        'RSAdaptorFE/api/v1/paycorr/settlement-modes/settlement/x/delete',
         HTTP_OPTIONS
    );
  }
}

<div class="general-operations-div">
    <div style="display: flex;">
      <button mat-icon-button class="back" (click)="goBack()"   >
        <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>
      <button mat-fab class="page-icon-button blue-button-icon">
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon">paid</mat-icon>
      </button>
      <ng-container *ngIf="!loading">
        <div class="title-container">
          <label class="title-mid-text">
            {{ groupDescription }}</label>
          <label class="id-text">
              {{ groupId }}</label>
        </div>
      </ng-container>
      <div *ngIf="loading" class="title-container">
        <ngx-skeleton-loader count="1" [theme]="{
            background: 'no-repeat rgb(227 228 230 / 33%)',
            height: '20px',
            width: '150px'
          }"></ngx-skeleton-loader>
      </div>

    </div>
    <div class="general-operations-buttons-div" *ngIf="!loading">
        <button mat-fab class="button green-color" (click)="saveChanges()" i18n-matTooltip [matTooltip]="loc.attributes.saveChanges">
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon margin-icon"> save </mat-icon>
        </button>
      </div>
  </div>



  <mat-card class="details-card" >
    <div class="flex-container">
      <div class="flex-container-vertical">
        <div>
            <label class="card-header" i18n>{{loc.detailsHeaders.category}}</label>
        </div>
          <div>
            <ng-container *ngIf="!loading">
              <label class="card-value">
                {{ categoryId | titlecase }}
              </label>
            </ng-container>
            <div *ngIf="loading">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  background: 'no-repeat rgb(227 228 230 / 33%)',
                  height: '18px',
                  width: '150px'
                }"
              ></ngx-skeleton-loader>
            </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
            <label class="card-header" i18n>{{loc.detailsHeaders.service}}</label>
        </div>
          <div>
            <ng-container *ngIf="!loading">
              <label class="card-value">
                {{ serviceTemplateId }}
              </label>
            </ng-container>
            <div *ngIf="loading">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  background: 'no-repeat rgb(227 228 230 / 33%)',
                  height: '18px',
                  width: '150px'
                }"
              ></ngx-skeleton-loader>
            </div>
        </div>
      </div>
      <div class="flex-container-vertical" *ngIf="operationId">
        <div>
          <label class="card-header" i18n>{{loc.detailsHeaders.operation}}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ operationId }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-tab-group class="tab-bar">
    <mat-tab [label]="loc.tabNames.attributesAllowed" i18n-label>
      <app-attribute-allowed-list tableStyle = "tableDetails" fieldsType="action" (attributesChange)="refresh($event)"></app-attribute-allowed-list>
    </mat-tab>
  </mat-tab-group>

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { ActivatedRoute } from '@angular/router';
import { AttributeAllowed } from 'src/app/models/attributes/attributeAllowed';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { UpdateAttributesAllowedComponent } from '../update-attributes-allowed/update-attributes-allowed.component';
import { DetailCard } from 'src/app/models/DetailCard.model';


@Component({
  selector: 'app-service-mode-details',
  templateUrl: './service-mode-details.component.html',
  styleUrls: ['./service-mode-details.component.css'],
  host: {
    class: 'component'
  }
})
export class ServiceModeDetailsComponent implements OnInit {
  id = '';
  description = '';
  serviceTemplateId = '';
  
  loading = false;
  attributesAllowed: AttributeAllowed[] = [];

  cards: DetailCard[] = [];

  constructor(
    public location: Location,
    public loc : LocalizationService,
    public route: ActivatedRoute,
    public attributesService: AttributesService,
    private logoutDialogService: LogoutDialogService,
    public snackbarService: SnackbarService,
    private dialogService: DialogService,

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params['modeId']
      this.description = params['modeDesc']
      this.serviceTemplateId = params['serviceTemplateId']
      
      this.cards = [
        {
          title: this.loc.detailsHeaders.idNumber,
          description: this.id
        },
        {
          title: this.loc.detailsHeaders.description,
          description: this.description
        },
        {
          title: this.loc.detailsHeaders.serviceTemplate,
          description: this.serviceTemplateId
        },
      ]
    });
  }

  goBack(): void {
    this.location.back();
    let state = JSON.parse(localStorage.getItem('state')!);
    state.mediums = {};
    localStorage.setItem('state', JSON.stringify(state));
  }
}


import { Component,  Input,  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { IdentificationType } from 'src/app/models/country/identification-type.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateIdentificationTypeComponent } from './create-identification-type/create-identification-type.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
@Component({
  selector: 'app-country-identification-types',
  templateUrl: './country-identification-types.component.html',
  host: {
    class: 'component',
  },
  styleUrls: ['./country-identification-types.component.css'],
})
export class CountryIdentificationTypesComponent implements OnInit {
  @Input() tableStyle = 'tableResponsive';
  @Input() countryId = '';

  loading = true;
  data: TableData[] = [];

  constructor(
    public countriesService: CountriesService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) { }

  ngOnInit(): void {
    this.loadIdentificationTypes();
  }

  loadIdentificationTypes() {
    this.loading = true;
    this.countriesService.getIdentificationTypes(this.countryId).subscribe(
      (res: BaseResponse) => {
        this.data = res.messageRS as any as TableData[];
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-identification-types.png',
    message: this.loc.identificationTypes.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      }
    ]
  }

  columns: Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'identificationTypeId',
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
    },
  ]

  paginatorInfo = this.loc.identificationTypes

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'identificationTypeId',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    }
  ]

  actions: Action[] = [
    {
      icon: 'edit',
      action: (row: IdentificationType) => this.edit(row),
      tooltip: this.loc.identificationTypes.edit
    },
    {
      icon: 'delete',
      action: (row: IdentificationType) => this.delete(row),
      tooltip: this.loc.identificationTypes.delete
    }
  ]

  createButton: CreateButton = {
    tooltip: this.loc.identificationTypes.create,
    action: () => {
      this.create();
    }
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateIdentificationTypeComponent,
      this.countryId,
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.identificationTypes)
        );
        this.loadIdentificationTypes();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.identificationTypes)
        );
        this.loadIdentificationTypes();
      }
    });
  }

  edit(element: IdentificationType) {
    let data: EditModalData = {
      title: this.loc.identificationTypes.edit,
      icon: 'language',
      fields: [
        {label: this.loc.fields.description, 
          value: element.description!!, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required]
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyAction(element, result)
      }
    });
  }

  modifyAction(row: any, result: any){
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value
    let newIdentificationType : IdentificationType = {
      countryId: row.countryId,
      identificationTypeId: row.identificationTypeId,
      description: newDescription
    }

    this.countriesService.editIdentificationType(newIdentificationType).subscribe(
     { 
      next: (res: BaseResponse) => {
        this.loading = false;
        if(res.statusRS?.code === "0"){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('update', this.loc.identificationTypes)
          );
          this.loadIdentificationTypes();
        } else {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.identificationTypes)
          );
        }
      },error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.identificationTypes)
          );
        }
      }
    }
    )

  }

  delete(element: IdentificationType) {
    let dataModal: DeleteModalData = {
      title: this.loc.identificationTypes.delete,
      icon: 'language',
      confirmDeleteMessage: this.loc.confirmDelete(
        this.loc.identificationTypes
      ),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      dataModal,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.countriesService
          .deleteIdentificationType(
            element.identificationTypeId!,
            element.countryId!
          )
          .subscribe(
            (res: BaseResponse) => {
              if (res.statusRS?.code === '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage(
                    'delete',
                    this.loc.identificationTypes
                  )
                );
                this.loadIdentificationTypes();
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.identificationTypes)
                );
              }
            },
            (err) => {
              this.logoutDialogService.openLogoutDialog(err);
            }
          );
      }
    });
  }
}

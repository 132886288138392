import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Action } from '../models/extension-points/Action.model';
import { DialogService } from '../services/dialog/dialog.service';
import { ExtensionPointsService } from '../services/extension-points/extension-points.service';
import { LocalizationService } from '../services/localization/localization.service';
import { LogoutDialogService } from '../services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from '../services/snackbar/snackbar.service';
import { MatSelectChange } from '@angular/material/select';
import { CreateActionComponent } from './create-action/create-action.component';
import { DeleteModalComponent } from '../utils/delete-modal/delete-modal.component';
import { DeleteModalData } from '../models/delete-modal/delete-modal-data.model';
import { BaseResponse } from '../models/responses.model';
import { ServiceTemplate } from '../models/digital-services/ServiceTemplate.model';
import { addFilter, customFilterPredicate, filter, pipeForFilters } from '../utils/Filters/Filters';
import { stringSort } from '../utils/Filters/Sorts';
import { EditModalData } from '../models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from '../utils/edit-modal/edit-modal.component';

@Component({
  selector: 'app-extension-points',
  templateUrl: './extension-points.component.html',
  styleUrls: ['./extension-points.component.css'],
  host: {
    class: 'component',
  },
})
export class ExtensionPointsComponent implements OnInit {
  dataSource = new MatTableDataSource();
  loadingDataSource = new MatTableDataSource<string>();
  loading = true;
  displayedColumns: string[] = ['id', 'digitalJourney', 'name', 'actions'];
  tableStyle = 'tableResponsive';

  serviceTemplateControl = new UntypedFormControl();
  navegateToBranchDetails = true;

  option = 'DELETE';
  filters: any = {
    digitalJourney:
      JSON.parse(localStorage.getItem('state')!)?.actions?.digitalJourney || '',
  };
  branchMaxDevice = 0;
  branchAddress = '';

  serviceTemplates: any[] = [];

  actions: Action[] = [];
  operations: string[] = [];

  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]> = new Observable<string[]>();
  filteredBranchOptions: Observable<Action[]> = new Observable<Action[]>();
  selectedIndex = 0;
  pageIndex = 0;
  pageSize = 5;

  constructor(
    public extensionPointsService: ExtensionPointsService,
    public dialogService: DialogService,
    public router: Router,
    public snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {
    let state = JSON.parse(localStorage.getItem('state')!);
    if (state.extensionPoints) {
      this.selectedIndex = state.extensionPoints.tab;
    }
  }

  @ViewChild(MatPaginator, { static: false }) set paginator(
    value: MatPaginator
  ) {
    this.loc.translateMatPaginator(value);

    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false }) set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  ngAfterContentInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = customFilterPredicate;
  }

  ngOnInit(): void {
    this.loadingDataSource.data = ['', '', '', '', ''];

    this.loadActions();
    this.loadServiceTemplates();
    let state = JSON.parse(localStorage.getItem('stateAdmin')!);
    this.pageIndex = state?.actions?.pageIndex || 0;
    this.pageSize = state?.actions?.pageSize || 5;
    this.myControl.setValue(state?.actions?.digitalJourney || '');
    this.filters['digitalJourney'] = state?.actions?.digitalJourney || '';
    this.dataSource.filter = JSON.stringify(this.filters);
  }

  pageEvents(event: any) {
    let state = JSON.parse(localStorage.getItem('stateAdmin')!);
    if (!state.actions) {
      state.digitalJourney = {};
    }
    state.digitalJourney['pageIndex'] = event.pageIndex;
    state.digitalJourney['pageSize'] = event.pageSize;
    localStorage.setItem('stateAdmin', JSON.stringify(state));
  }

  changeSelectionTemplate(event: MatSelectChange) {
    this.extensionPointsService.getOperationsByTemplate(event.value).subscribe({
      next: (res: BaseResponse<ServiceTemplate[]>) => {
        let templateOperations = (res.messageRS as any as any[]).map(
          (elem) => elem.serviceTemp
        );

        this.dataSource.data = this.actions.filter((action) =>
          templateOperations.includes(action.digitalJourney)
        );

        this.operations = templateOperations;
        this.callFilters();
      },
    });
  }

  loadServiceTemplates() {
    this.extensionPointsService.getServiceTemplates().subscribe({
      next: (res: BaseResponse<ServiceTemplate[]>) => {
        this.serviceTemplates = res.messageRS;
      },
    });
  }

  loadActions(): void {
    this.extensionPointsService.getActions().subscribe(
      (res: BaseResponse<Action>) => {
        this.actions = res.messageRS as unknown as Action[]; //Array<Branch>;
        this.dataSource.data = this.actions;
        this.actions.forEach((action) => {
          if (action.digitalJourney) {
            this.operations.push(action.digitalJourney);
          }
        });
        this.dataSource.sort = this.sort;
        this.callFilters();
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  addFilter(event: any, column: string): void {
    addFilter(event, column, 'actions', this.filters, this.dataSource);
  }

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateActionComponent,
      this.actions,
      'large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.extensionPoints)
        );
        this.loadActions();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.extensionPoints)
        );
        this.loadActions();
      }
    });
  }

  edit(): void {
    this.navegateToBranchDetails = false;
    this.option = 'EDIT';
  }

  delete(): void {
    this.navegateToBranchDetails = false;
    this.option = 'DELETE';
  }

  modifyAction(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value
    let newRow = {...row, actionDescription: newDescription}
    this.extensionPointsService
      .updateAction(newRow)
      .subscribe(
        {
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code === '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('update', this.loc.extensionPointsActions)
              );
              this.loadActions();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.extensionPointsActions)
              );
            }
          },
          error: (err) => {
            this.loading = false;
            this.logoutDialogService.openLogoutDialog(err);
            if (err.statusCode !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.extensionPointsActions)
              );
            }
          }
        }
      );
  }

  onRowClicked(row: any): void {
    if (this.navegateToBranchDetails) {
      this.router.navigate(['action-details'], {
        queryParams: {
          operationId: row.digitalJourney,
          actionId: row.actionId,
          touchPointId: row.touchPointId,
        },
        queryParamsHandling: 'merge',
      });
    } else {
      if (this.option === 'EDIT') {
        let data: EditModalData = {
          title: this.loc.extensionPointsActions.edit,
          icon: 'hub',
          fields: [
            { label: this.loc.fields.description, 
              value: row.actionDescription, 
              errorMsg: this.loc.errors.emptyDescription, 
              validators: [Validators.required],
            }
          ]
        };
        const dialogRef = this.dialogService.openDialog(
          EditModalComponent,
          data,
          'x-small'
        );
        dialogRef.afterClosed().subscribe((result) => {
          if (result && result !== 'not updated') {
            this.loading = true;
            this.modifyAction(row, result)
          }
          this.navegateToBranchDetails = true;
        });
      } else {
        let data: DeleteModalData = {
          title: this.loc.extensionPoints.deleteAction,
          icon: 'hub',
          confirmDeleteMessage: this.loc.confirmDelete(
            this.loc.extensionPointsActions
          ),
        };
        const dialogRef = this.dialogService.openDialog(
          DeleteModalComponent,
          data,
          'x-small'
        );
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'delete') {
            this.extensionPointsService.deleteAction(row).subscribe({
              next: (res: BaseResponse<void>) => {
                this.loading = true;

                if (res.statusRS?.code == '0') {
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage(
                      'delete',
                      this.loc.extensionPointsActions
                    )
                  );
                  this.loadActions();
                } else if (res.statusRS?.code == '-91618') {
                  this.snackbarService.openErrorSnackbar(
                    this.loc.errors.errorActionUsed
                  );
                } else {
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError(
                      'delete',
                      this.loc.extensionPointsActions
                    )
                  );
                }
                this.loading = false;
              },
              error: (err) => {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError(
                    'delete',
                    this.loc.extensionPointsActions
                  )
                );
              },
            });
          }

          this.navegateToBranchDetails = true;
        });
      }
    }
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state.extensionPoints = {
      tab: this.selectedIndex
    };
    localStorage.setItem('state', JSON.stringify(state));
  }

  private callFilters(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      pipeForFilters(filter(this.operations).bind(this), stringSort)
    );
  }
}

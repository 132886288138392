import { Component, OnInit } from '@angular/core';
import { PaymentButtonFields } from 'src/app/models/payment-buttons/paymentButtonFields.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { PaymentButtonService } from 'src/app/services/payment-button/payment-button.service';
import { CreatePaymentButtonFieldsComponent } from './create-payment-button-fields/create-payment-button-fields.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';

@Component({
  selector: 'app-payment-button-fields',
  templateUrl: './payment-button-fields.component.html',
  styleUrls: ['./payment-button-fields.component.css'],
  host: { class: 'component' },
})
export class PaymentButtonFieldsComponent implements OnInit {
  loading: boolean = true;
  constructor(
    public loc: LocalizationService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private paymentButtonService: PaymentButtonService
  ) {}

  ngOnInit(): void {
    this.loadFields();
  }

  data : TableData[] =[]

  emptyState: EmptyState = {
    image: 'assets/img/empty-payment-button.png',
    message: this.loc.paymentButtonFields.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => {
          this.create();
        }
      }
    ]
  }

  columns : Column[] = [
    {
      key: 'id',
      title: this.loc.tableHeaders.id,
    },
    {
      key: 'name',
      title: this.loc.tableHeaders.name,
    }
  ]

  paginatorInfo = this.loc.paymentButtonFields

  filters : TableFilter[] = [
    {
      key: 'id',
      label: this.loc.tableHeaders.id,
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      key: 'name',
      label: this.loc.tableHeaders.name,
      type: 'text',
      placeholder: this.loc.tableHeaders.name,
    }]

    actions: Action[] = [
      {
        icon: 'edit',
        tooltip: this.loc.paymentButtonFields.edit,
        action: (row: any) => {
          this.editField(row);
        }
      },
      {
        icon: 'delete',
        tooltip: this.loc.paymentButtonFields.delete,
        action: (row: any) => {
          this.delete(row);
        }
      }
    ]

    createButton: CreateButton = {
      tooltip: this.loc.paymentButtonFields.create,
      action: () => {
        this.create();
      }
    }

  loadFields() {
    this.loading = true;
    this.paymentButtonService.getFields().subscribe({
      next: (data: BaseResponse<PaymentButtonFields[]>) => {
        this.data = data.messageRS as TableData[];
        this.loading = false;
      },
      error: (err: any) => {},
    });
  }

  // CRUD OPERATIONS

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreatePaymentButtonFieldsComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage(
            'create',
            this.loc.paymentButtonFields,

          )
        );
        this.loadFields();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.paymentButtonFields)
        );
        this.loadFields();
      }
      if (result === 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.paymentButtonFieldsAlreadyExists
        );
      }
    });
  }

  editField(row: any) {
    let data: EditModalData = {
      title: this.loc.paymentButtonFields.edit,
      icon: 'smart_button',
      fields: [
        { label: this.loc.fields.name,
          value: row.name,
          errorMsg: this.loc.paymentButtonFields.errorEditName,
          validators: [Validators.required],
        }]
    }

    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result  && result !== 'not updated') {
        this.loading = true;
        this.editAction(row, result)
      }
    });
  }

  editAction(row: any, result: any) {
    let newName = result.find((x: any) => x.label === this.loc.fields.name).value

    this.paymentButtonService.editField(row.id, newName).subscribe({
      next: (_response: BaseResponse<PaymentButtonFields>) => {
        if(_response.statusRS.code === '0'){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage(
              'update',
              this.loc.paymentButtonFields,
            )
          );
          this.loadFields();
        }else{
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.paymentButtonFields)
          );
        }
      },
      error: (_error: any) => {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.paymentButtonFields)
        );
      },
    });
  }

  delete(row: any) {
    let data: DeleteModalData = {
      title: this.loc.paymentButtonFields.delete,
      icon: 'smart_button',
      confirmDeleteMessage: this.loc.confirmDelete(
        this.loc.paymentButtonFields
      ),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.paymentButtonService.deleteField(row.id).subscribe({
          next: (res: BaseResponse<any>) => {
            this.loading = true;
            switch (res.statusRS?.code) {
              case '0':
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage(
                    'delete',
                    this.loc.paymentButtonFields,

                  )
                );
                this.loadFields();
                break;
              case '-91788':
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.paymentButtonFieldsExistingRelationships
                );
                this.loadFields();
                break;
              default:
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.paymentButtonFields)
                );
                break;
            }
            this.loading = false;
          },
          error: (err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.paymentButtonFields)
            );
          },
        });
      }
    });
  }

}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';
import { HttpClient } from '@angular/common/http';
import { Bank } from 'src/app/models/Banks/bank.model';
import { BaseRequest } from '../../models/base/base-request.model';
import { BaseResponse } from '../../models/responses.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BanksService {
  readonly baseURL = environment.apiUrl;

  constructor(private tokenService: TokenService, private http: HttpClient) {}

  getBanks(bankId: string = ''): Observable<BaseResponse<Bank[]>> {
    const HTTP_OPTIONS = {
      params: {
        bankId,
      },
    };
    return this.http.get<BaseResponse<Bank[]>>(
      this.baseURL + 'RSAdaptorFE/api/v1/paycorr/banks/bank/x/get-banks',
      HTTP_OPTIONS
    );
  }

  createBank(bank: Bank) {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = bank;
    return this.http.post(
      this.baseURL + 'RSAdaptorFE/api/v1/paycorr/banks/bank/x/create-bank',
      baseRequest
    );
  }

  updateBank(bank: Bank) {
    const baseRequest = new BaseRequest();
    baseRequest.messageRQ = bank;
    return this.http.put(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/banks/bankId/${bank.bankId}/update-bank`,
      baseRequest
    );
  }

  deleteBank(bank: Bank): Observable<BaseResponse<Bank>> {
    return this.http.delete<BaseResponse<Bank>>(
      this.baseURL +
        `RSAdaptorFE/api/v1/paycorr/banks/bankId/${bank.bankId}/delete-bank`
    );
  }

  getCountries() {
    return this.http.get(
      this.baseURL + 'RSAdaptorFE/api/v1/paycorr/shifts/country/x/get-countries'
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MetadataField } from 'src/app/models/metadata/metadata-field/metadataField.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { ShowStructureComponent } from 'src/app/apis/showStructure/show-structure/show-structure.component';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-metadata-fields-details',
  templateUrl: './metadata-fields-details.component.html',
  styleUrls: ['./metadata-fields-details.component.css'],
  host: {
    class: 'component',
  }
})
export class MetadataFieldsDetailsComponent implements OnInit {
  loading = true;
  metadataField: MetadataField = new MetadataField;

  constructor(
    public location: Location,
    public loc: LocalizationService,
    private route: ActivatedRoute,
    private metadataService: MetadataService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.metadataField.id = params['id']
      this.getMetadataField();
    });
  }

  getMetadataField(){
    this.loading = true
    this.metadataService.getMetadataField(this.metadataField.id!).subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.metadataField = res.messageRS![0] as any as MetadataField;
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  goBack(){
    this.location.back();
  }

  showDatasourceData(){
    var dialogRef;
    try {
      dialogRef = this.dialogService.openDialog(
        ShowStructureComponent,
        {
          body: JSON.parse(this.metadataField?.datasourceStruct!),
          title: this.loc.fields.datasourceData,
          editable: true
        },
        'large'
      );
    }
    catch {
      dialogRef = this.dialogService.openDialog(
        ShowStructureComponent,
        {
          body: this.metadataField?.datasourceStruct!,
          title: this.loc.fields.datasourceData,
          editable: true
        },
        'large'
      );
    }
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        try {
          let res = JSON.parse(result)
          let newMeta = {
            id: this.metadataField.id,
            type: this.metadataField.type,
            datasourceData: res,
            controlType: this.metadataField.controlType,
            checkout: this.metadataField.checkout,
            defaultValue: this.metadataField.defaultValue,
            description: this.metadataField.description,
            isEditable: this.metadataField.isEditable,
            isHidden: this.metadataField.isHidden,
            isMandatory: this.metadataField.isMandatory,
            validation: this.metadataField.validation
          }

          this.metadataService.editMetadataField(newMeta).subscribe({
            next: (res: any) => {
              this.loading = false;
              if (res.statusRS?.code == '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('update', this.loc.metadataField)
                );
                this.metadataField = newMeta;
                this.metadataField.datasourceStruct = newMeta.datasourceData
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('update', this.loc.metadataField)
                );
              }
            },
            error: (err) => {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.metadataField)
              );
            },

          })
          
      } catch (error) {
        this.loading = false;
          this.snackbarService.openErrorSnackbar(this.loc.errors.invalidFormat);
      }
      }
    });
  }

  manageDatasourceDataModification(message: string) {
    this.getMetadataField();
  }
}

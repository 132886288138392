import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  constructor(private router: Router, public loc: LocalizationService) {}

  menuItems: {
    id: string;
    icon: string;
    route?: string;
    isExpanded?: boolean; // Agrego isExpanded para controlar la expansión de submenús
    subMenuItems?: {
      id: string;
      icon: string;
      route: string;
    }[];
  }[] = [
    {
      id: 'agents',
      icon: 'domain',
      route: 'null',
      isExpanded: false,
      subMenuItems: [
        {
          id: 'atribs',
          icon: 'null',
          route: 'agent',
        },
        {
          id: 'branches',
          icon: 'null',
          route: 'branch',
        },
        {
          id: "campaigns",
          icon: 'null',
          route: "campaigns"
        },
        {
          id: "integrations",
          icon: 'null',
          route: "integrations"
        },
        {
          id: 'paymentButton',
          icon: 'null',
          route: 'payment-button',
        },
        {
          id: 'users',
          icon: 'null',
          route: 'user'
        },
      ],
    },
    {
      id: 'countries',
      icon: 'language',
      route: 'null',
      isExpanded: false,
      subMenuItems: [
        {
          id: 'atribs',
          icon: 'null',
          route: 'country',
        },
        {
          id: 'calendars',
          icon: 'null',
          route: 'calendar',
        },
        {
          id: 'currencies',
          icon: 'null',
          route: 'currency',
        },
      ],
    },
    {
      id: 'banks',
      icon: 'account_balance',
      route: 'bank',
    },
    {
      id: 'notifications',
      icon: 'notifications',
      route: 'notification',
    },
    // {
    //   id: 'transactionTemplates',
    //   icon: 'signpost',
    //   route: 'transaction-template',
    // },
    {
      id: 'transactions',
      icon: 'swap_horiz',
      route: 'null',
      isExpanded: false,
      subMenuItems: [
        {
          id: 'disaggregation',
          icon: 'null',
          route: 'transaction-template',
        },
        {
          id: 'posting',
          icon: 'null',
          route: 'transaction-template',
        },
        {
          id: 'postingSchemes',
          icon: 'null',
          route: 'postingScheme',
        },
        {
          id: 'adjustmentReasons',
          icon: 'null',
          route: 'transaction',
        },
      ],
    },
    {
      id: 'extensionPoints',
      icon: 'hub',
      route: 'extension-point',
    },
    {
      id: "services",
      icon: "paid",
      route: 'null',
      isExpanded: false,
      subMenuItems: [
        {
          id: 'atribs',
          icon: 'null',
          route: 'service',
        },
        {
          id: "api",
          icon: "null",
          route: "api"
        },
        {
          id: "metadata",
          icon: "null",
          route: "metadata"
        },
      ],
    },
  ];


  getRouteName(route: string) {
    const data = (this.loc.routes as unknown as any)[route];
    return data;
  }

  toggleSubMenu(item: any) {
    if (item.subMenuItems) {
      item.isExpanded = !item.isExpanded;
    } else if (item.route) {
      this.router.navigate([item.route]);
    }
  }



  selectedItem = '/agent';
  ngOnInit(): void {}
}

import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {ActivatedRoute} from '@angular/router';
import {Action} from 'src/app/models/extension-points/Action.model';
import {BaseResponse} from 'src/app/models/responses.model';
import {DialogService} from 'src/app/services/dialog/dialog.service';
import {ExtensionPointsService} from 'src/app/services/extension-points/extension-points.service';
import {LocalizationService} from 'src/app/services/localization/localization.service';
import {LogoutDialogService} from 'src/app/services/logout-confirmation/logout-dialog.service';
import {SnackbarService} from 'src/app/services/snackbar/snackbar.service';


@Component({
  selector: 'app-create-action',
  templateUrl: './create-action.component.html',
  styleUrls: ['./create-action.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateActionComponent implements OnInit {

  loading = false;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  operations: any[] = [];
  actions: any[] = [];
  stageId = '';


  constructor(
    public dialogRef: MatDialogRef<CreateActionComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Action[],
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private extensionPointsService: ExtensionPointsService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
    });

    this.firstFormGroup = this._formBuilder.group({
      template: ['', Validators.required],
      operation: ['', Validators.required],
      action: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.getServiceTemplates();
  }

  getServiceTemplates() {
    this.extensionPointsService.getServiceTemplates().subscribe(
      (res: BaseResponse<any[]>) => {
        this.templates = res.messageRS as unknown as any[];
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }

  changeSelectionTemplate(event: MatSelectChange) {
    console.log("EVENT", event.value)
    this.extensionPointsService.getOperationsByTemplate(event.value).subscribe({
      next: (res: BaseResponse<any[]>) => {
        let templateOperations = res.messageRS.map(elem => elem.serviceTemp);

        this.operations = templateOperations;
        this.actions = [];

      }
    })
  }

  changeSelectionOperation(event: MatSelectChange) {
    this.extensionPointsService.getOperationActions(event.value).subscribe(
      {
        next: (res: BaseResponse<Action[]>) => {
          this.actions = res.messageRS;
          let operationActionsAlreadyCreated = this.data.filter(action => action.digitalJourney == event.value);
          this.actions = this.actions.filter(action => !operationActionsAlreadyCreated.some(actionCreated => actionCreated.actionId == action.actionId && actionCreated.actionEntity == action.actionEntity && actionCreated.digitalJourney == action.digitalJourney && actionCreated.touchPointId == action.touchPointId && actionCreated.stageId == action.stageId))
          if (this.actions.length == 0) {
            this.snackbarService.openErrorSnackbar(this.loc.errors.noActionsForOperation)
          }
        }
      }
    )
    //call get actions of operation
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  create() {
    this.loading = true;
    let actionSelected = this.firstFormGroup.get('action')!.value;
    let newAction = {
      digitalJourney: actionSelected.digitalJourney,
      touchPointId: actionSelected.touchPointId,
      actionId: actionSelected.actionId,
      actionEntity: actionSelected.actionEntity,
      stageId: actionSelected.stageId,
      actionDescription: this.firstFormGroup.get('description')?.value,

    };

    this.loading = true;
    this.extensionPointsService.createAction(newAction).subscribe(
      (res: BaseResponse<void>) => {
        setTimeout(() => {
          if (res.statusRS?.code === "0") {
            this.exit("created");
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }


}

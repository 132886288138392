import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BanksService } from 'src/app/services/banks/banks.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import { Bank } from 'src/app/models/Banks/bank.model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { BaseResponse } from '../../../models/responses.model';
import { TableData } from 'src/app/utils/table/table.model';
import { EditBankComponent } from '../../edit-bank/edit-bank.component';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css'],
  host: {
    class: 'component'
  }
})
export class BankDetailsComponent implements OnInit {
  loading = true;
  name = '';
  bank: Bank | undefined;
  dataSource = new MatTableDataSource();
  bankId = '';
  action: any;
  loadingDataSource = new MatTableDataSource<string>();
  data: TableData[] = [];

  constructor(
    private banksService: BanksService,
    private dateFormatter: DateFormatterService,
    public route: ActivatedRoute,
    public location: Location,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    private bankService: BanksService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.loadingDataSource.data = ['', '', '', '', ''];
    this.route.queryParams.subscribe(params => {
      this.bankId = params['bankId'];
    });
    this.getBank();
  }

  getBank(): void {
    this.bankService.getBanks(this.bankId).subscribe({
      next: (res: BaseResponse<Bank[]>) => {
        this.bank = res.messageRS![0];
        this.loading = false;
      },
      error: err => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    });
  }

  emptyField(text: string | undefined): string {
    if (text === '' || text === undefined) return '-';
    return text;
  }

  formatDate(date?: string): string {
    if (date) return this.dateFormatter.formatDate(date, 'dd/MM/yyyy');
    else return '';
  }
  goBack(): void {
    this.location.back();
    let state = JSON.parse(localStorage.getItem('state')!);
    state.mediums = {};
    localStorage.setItem('state', JSON.stringify(state));
  }

  loadBanks(): void {
    this.banksService.getBanks().subscribe({
      next: (res: BaseResponse<Bank[]>) => {
        this.data = (res.messageRS as any) as TableData[];
        this.loading = false;
      },
      error: err => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    });
  }

  edit(): void {
    const dialogRef = this.dialogService.openDialog(
      EditBankComponent,
      {
        abaNumber: this.bank?.abaNumber,
        bankAddress: this.bank?.bankAddress,
        bankId: this.bankId,
        bankName: this.bank?.bankName,
        swiftCode: this.bank?.swiftCode,
        bankCountry: this.bank?.bankCountry
      },
      'medium'
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'not updated' && result !== 'error') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.banks)
        );
        this.bank!.abaNumber = result.abaNumber;
        this.loadBanks();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.banks)
        );
      }
    });
  }

  delete(): void {
    let data: DeleteModalData = {
      title: this.loc.banks.delete,
      icon: 'account_balance',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.banks)
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete') {
        this.banksService
          .deleteBank({
            abaNumber: this.bank?.abaNumber,
            bankAddress: this.bank?.bankAddress,
            bankId: this.bankId,
            bankName: this.bank?.bankName,
            swiftCode: this.bank?.swiftCode,
            bankCountry: this.bank?.bankCountry
          })
          .subscribe({
            next: (res: BaseResponse<Bank>) => {
              this.loading = true;

              if (res.statusRS?.code == '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.banks)
                );
                this.loadBanks();
              } else if (res.statusRS?.code == '-91627') {
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.deleteBank
                );
              } else if (res.statusRS?.code == '-91628') {
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.bankUsedInSegment
                );
              } else if (res.statusRS?.code == '-91629') {
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.bankUsedByAgent
                );
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.banks)
                );
              }
              this.loading = false;
            },
            error: (_err: any) => {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.banks)
              );
            }
          });
      }
    });
  }
}

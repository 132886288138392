import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../services/localization/localization.service';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css'],
  host: {
    class: 'component'
  }
})
export class AgentComponent implements OnInit {

  constructor(
    public loc : LocalizationService
  ) { 
    let state = JSON.parse(localStorage.getItem('state')!);
    this.selectedIndex = state?.agent?.tab || 0
  }
    selectedIndex = 0;

  ngOnInit(): void {
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state.agent = {
      tab: this.selectedIndex
    };
    localStorage.setItem('state', JSON.stringify(state));
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { PostingStepTemplate } from 'src/app/models/posting-schemes/PostingStepTemplate.model';
import { PostingStep } from 'src/app/models/posting-schemes/PostingStep.model';
import { Group } from 'src/app/models/posting-schemes/Group.model';
import { CreatePostingGroupComponent } from '../create-posting-group/create-posting-group.component';

@Component({
  selector: 'app-create-posting-step',
  templateUrl: './create-posting-step.component.html',
  styleUrls: ['./create-posting-step.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreatePostingStepComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  stepTemplates: PostingStepTemplate[] = [];

  groups: Group[] = [];
  errorGroups: Group[] = [];
  signs: any[] = [];

  groupId = ''
  errorGroupId = ''

  dispatchOnlineFlows: any[] = [];
  respParseOnlineFlows: any[] = [];
  messageOnlineFlows: any[] = [];
  secondFormGroup!: UntypedFormGroup;
  thirdFormGroup!: UntypedFormGroup;



  constructor(
    public dialogRef: MatDialogRef<CreatePostingStepComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private postingSchemesService: PostingSchemesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.loadGroups();
    this.loadPostingStepTemplates();
    this.loadSigns();
    this.firstFormGroup = this._formBuilder.group({
      stepTemplate: ['', Validators.required],
      isReverse: ['', Validators.required],
      subject: ['', Validators.required],
      operation: ['', Validators.required],
      suboperation: ['', Validators.required],
      postingSign: ['', Validators.required],
      group: ['', Validators.required],
      errorGroup: ['', Validators.required],
    });

    this.firstFormGroup.get('subject')?.disable()
    this.firstFormGroup.get('operation')?.disable()
    this.firstFormGroup.get('suboperation')?.disable()

    this.secondFormGroup = this._formBuilder.group({
      dispatchOnlineFlow: ['', Validators.required],
      respParseOnlineFlow: ['', Validators.required],
      messageOnlineFlow: ['', Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
      reverseDispatchOnlineFlow: ['', Validators.required],
      reverseRespParseOnlineFlow: ['', Validators.required],
      reverseMessageOnlineFlow: ['', Validators.required],
    });
    this.dispatchOnlineFlows = this.data.dispatchOnlineFlows;
    this.messageOnlineFlows = this.data.messageOnlineFlows;
    this.respParseOnlineFlows = this.data.respParseOnlineFlows;
  }

  loadGroups(){
    this.postingSchemesService
    .getPostingGroups()
    .subscribe(
      (res: BaseResponse) => {
        let response = res.messageRS as any as Group[];
        
        this.groups = response
        this.errorGroups = response.filter((group) => group.id != "NORMAL");

        if(this.groupId){
          this.firstFormGroup.patchValue({
            group: this.groups.find((group) => group.id == this.groupId)
          })
        } 
        if(this.errorGroupId){
          this.firstFormGroup.patchValue({
            errorGroup: this.errorGroups.find((group) => group.id == this.errorGroupId)
          })
        }                
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  loadSigns(){
    this.postingSchemesService
    .getSigns()
    .subscribe(
      (res: BaseResponse) => {
        let response = res.messageRS as any as any[];
        
        this.signs = response;
        //this.loading = true;
        
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  loadPostingStepTemplates(){
    //this.loading = true;
    this.postingSchemesService
    .getPostingStepTemplates()
    .subscribe(
      (res: BaseResponse) => {
        let response = res.messageRS as any as any[];
        
        this.stepTemplates = response;
        //this.loading = true;
        
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  changeSelectionTemplate(event: MatSelectChange) {
    let stepTemplate = this.stepTemplates.find((temp) => temp.id == event.value.id)
    this.firstFormGroup.patchValue({
      subject: stepTemplate?.subjectDescription,
      operation: stepTemplate?.operationDescription,
      suboperation: stepTemplate?.suboperationDescription
    })
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  next(stepper: MatStepper){    
      stepper.next();    
  }

  create() {
    this.loading = true;
    let flows: any[] = [];
    flows.push(this.secondFormGroup.get('dispatchOnlineFlow')?.value);
    flows.push(this.secondFormGroup.get('respParseOnlineFlow')?.value);
    flows.push(this.secondFormGroup.get('messageOnlineFlow')?.value);
    flows = flows.map( item =>( {
      flowId: item.flowId,
      flowVersion: item.flowVersion,
      flowEntity: item.flowEntity,
      flowType: item.flowType,
    }))

    let isReverse = this.firstFormGroup.get('isReverse')?.value
    this.data.steps =  this.data.steps.map((step: PostingStep) => {
      return {
        ordinal: parseInt(step.ordinal!),
        stepTemplateId: step.stepTemplateId,
        isReverse: step.isReverse,
        postingSign: step.postingSign,
        group: step.group,
        errorGroup: step.errorGroup
      }
    })

    let newStep = {
      ordinal: this.data.steps.length + 1,
      stepTemplateId: this.firstFormGroup.get('stepTemplate')?.value.id,
      isReverse: "false",
      postingSign: this.firstFormGroup.get('postingSign')?.value.id,
      group: this.firstFormGroup.get('group')?.value.id,
      errorGroup: this.firstFormGroup.get('errorGroup')?.value.id,
      flows: flows,
    }
    this.data.steps.push(newStep)

    if(isReverse){
      let reverseFlows: any[] = [];
      reverseFlows.push(this.thirdFormGroup.get('reverseDispatchOnlineFlow')?.value);
      reverseFlows.push(this.thirdFormGroup.get('reverseRespParseOnlineFlow')?.value);
      reverseFlows.push(this.thirdFormGroup.get('reverseMessageOnlineFlow')?.value);
      let newStepReverse = {
        ordinal: this.data.steps.length + 1,
        stepTemplateId: this.firstFormGroup.get('stepTemplate')?.value.id,
        isReverse: "true",
        postingSign: this.firstFormGroup.get('postingSign')?.value.id == "C" ? "D": this.firstFormGroup.get('postingSign')?.value.id == "D" ? "C" : this.firstFormGroup.get('postingSign')?.value.id,
        group: this.firstFormGroup.get('errorGroup')?.value.id,
        errorGroup: 'NONE',
        flows: reverseFlows,
      }
      this.data.steps.push(newStepReverse)
    }
    delete this.data.dispatchOnlineFlows;
    delete this.data.messageOnlineFlows;
    delete this.data.respParseOnlineFlows;
    delete this.data.flows;

    this.loading = true;
    this.postingSchemesService.createPostingSteps(this.data).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          this.loading = false;
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit(res.statusRS?.code as string);
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }

  addGroup(){
    const dialogRef = this.dialogService.openDialog(CreatePostingGroupComponent, {},      
      "small");
  
      dialogRef.afterClosed().subscribe((result) => {
        if(!result){
          return;
        }
        if (result.includes('created')) {
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage("add",this.loc.flows)
          );        
          this.loadGroups();
        } else if (result === 'error') {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError("add",this.loc.flows)
          );          
        }
        
      });
  }


}

<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; top: 50%; left: 50%;transform: translate(-50%, -50%);"></mat-spinner>
</div>

<div class="confirm-title">
  <div class="confirm-title-inner-div">
      <button mat-fab class="confirm-dialog-button blue-button-icon" *ngIf="imageProfile === '' else hasImage">
          <mat-icon class="action-button-icon" fontSet="material-icons-outlined">extension</mat-icon>
          <mat-icon class="action-button-icon-edit">mode_edit</mat-icon>
      </button>
      <ng-template #hasImage>
          <div class="image-container">
              <img [src]="imageProfile" alt="img_user" class="image2" (error)="imageProfile = '' ">
              <mat-icon class="action-button-icon-edit">mode_edit</mat-icon>
          </div>
      </ng-template>
      <div class="dialog-title"><label i18n>{{loc.integrations.edit}}</label></div>
  </div>
  <button class="exit-button" (click)="dialogRef.close('not updated')">
      <mat-icon class="exit-icon">close</mat-icon>
  </button>
</div>


<div class="create-row">
    <form [formGroup]="firstFormGroup" class="two-column-form">
        <div>
            <mat-form-field appearance="fill">
                <mat-label i18n>{{loc.fields.status}}</mat-label>
                    <mat-select formControlName="status" required>
                        <mat-option value="ACTIVE">{{loc.common.active}}</mat-option>
                        <mat-option value="INACTIVE">{{loc.common.inactive}}</mat-option>
                    </mat-select>
            </mat-form-field>
            <div class="edit-integration-description">
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.description}}</mat-label>
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="description"
                    cdkAutosizeMinRows="7" cdkAutosizeMaxRows="7" required>
                    </textarea>
                </mat-form-field>
            </div>
        </div>
        <div>
            <div >
                <div class="image-box">
                    <mat-icon *ngIf="!imageProfile" class="default-img">add_a_photo</mat-icon>
                    <img *ngIf="imageProfile" [src]="imageProfile" alt="img_user" class="image" (error)="imageProfile = '' ">
                </div>

                <button mat-fab class="button image-button" (click)="hiddenfileinput.click()" i18n-matmatTooltip [matTooltip]="loc.common.changePhoto">
                    <mat-icon fontSet="material-icons-outlined"> camera_alt </mat-icon>
                </button>
            </div>
            <input type="file" style="display: none;" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput>
        </div>
    </form>
</div>

<div class="confirm-buttons">
    <button mat-fab class="button green-color" (click)="saveImg()" [disabled]="firstFormGroup!!.get('integrationId')!!.value === '' || firstFormGroup!!.get('description')!!.value === '' ">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
    </button>
</div>

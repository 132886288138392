import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Integration } from 'src/app/models/integration/integration.model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateIntegrationComponent } from '../create-integration/create-integration.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import {
  Action,
  Column,
  CreateButton,
  EmptyState,
  PaginatorInfo,
  TableData,
  TableFilter,
  TableTransformer,
} from 'src/app/utils/table/table.model';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Validators } from '@angular/forms';
import { EditIntegrationComponent } from '../edit-integration/edit-integration.component';


@Component({
  selector: 'app-list-integrations',
  templateUrl: './list-integrations.component.html',
  styleUrls: ['./list-integrations.component.css'],
  host: {
    class: 'component',
  },
})
export class ListIntegrationsComponent implements OnInit {
  @Output() loaded = new EventEmitter<string>();
  @Output() integrationUpdate = new EventEmitter<any>();

  loading = true;
  @Input()
  reloadObserver!: Subject<boolean>;

  constructor(
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private integrationsService: IntegrationsService,
    private router: Router,
    private dateFormatter: DateFormatterService,
    public loc: LocalizationService,
    private htmlGenerator: SafeHTMLGeneratorService
  ) {}

  ngOnInit(): void {
    this.getAllIntegrations();
    if (this.reloadObserver) {
      this.reloadObserver.subscribe((response) => {
        this.getAllIntegrations();
      });
    }
  }

  data: TableData[] = [];
  emptyState: EmptyState = {
    image: 'assets/img/empty-integrations.png',
    message: this.loc.integrations.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.createIntegration(),
      },
    ],
  };
  columns: Column[] = [
    {
      title: '',
      key: 'image',
      sortable: true,
    },
    { title: this.loc.tableHeaders.id, key: 'integrationId', sortable: true },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.integration,
      key: 'integrationTypeDesc',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.status,
      key: 'status',
      centered: true,
    },
    {
      title: this.loc.tableHeaders.creationDate,
      key: 'creationDate',
      sortable: true,
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.integrations;

  filters: TableFilter[] = [
    {
      key: 'integrationId',
      label: this.loc.tableHeaders.id,
      placeholder: this.loc.tableHeaders.id,
      type: 'text',
    },
    {
      key: 'description',
      label: this.loc.tableHeaders.description,
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    },
    {
      key: 'integrationTypeDesc',
      label: this.loc.tableHeaders.integrationType,
      placeholder: this.loc.tableHeaders.integrationType,
      type: 'text',
    },
    {
      key: 'creationDate',
      label: this.loc.tableHeaders.creationDate,
      placeholder: this.loc.tableHeaders.creationDate,
      type: 'date',
    },
  ];

  createButton: CreateButton = {
    tooltip: this.loc.integrations.create,
    action: () => this.createIntegration(),
  };

  actions: Action[] = [
    {
      icon: 'more_horiz',
      tooltip: this.loc.common.details,
      hidden: (row: Integration) => row.integrationType !== 'CORE' && row.integrationType !== 'EMAIL' && row.integrationType !== 'CARD_READER' ,
      action: (row: Integration) => {
        this.details(row);
      },
    },
    {
      icon: 'edit',
      tooltip: this.loc.integrations.edit,
      action: (row: Integration) => {
        this.editIntegration(row);
      },
    },
    {
      icon: 'delete',
      tooltip: this.loc.integrations.delete,
      action: (row: Integration) => {
        this.deleteIntegration(row);
      },
    },
  ];

  transformers: TableTransformer = {
    creationDate: (value: string) => this.formatDate(value),
    status: (value: string) =>
      this.htmlGenerator.statusIcon(value === 'ACTIVE'),
    image: (value: any) => {
      return this.htmlGenerator.imageOrDefaultIcon(value, 'extension', 'integration image');
    }
  };

  getAllIntegrations(): void {
    this.loading = true
    this.integrationsService.getIntegrationsList(false).subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.data = res.messageRS as any as TableData[];
        } else {
          // TODO HANDLE ERROR
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  createIntegration(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateIntegrationComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.integrations, 'f')
        );
        this.getAllIntegrations();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.integrations)
        );
      } else if (result === 'invalid') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.integrationWithSameIdAlreadyExists
        );
      }
    });
  }

  modifyIntegration(row: any, result: any): void {
    let newStatus = result.find((x: any) => x.label === this.loc.tableHeaders.status).value
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value

    let newIntegration ={ ...row, status: newStatus, description: newDescription}
    this.integrationsService.editIntegration(newIntegration);
  }

  editIntegration(row: any){
    console.log(row)
    const data = {
      integrationId: row.integrationId,
      integrationType: row.integrationType,
      description: row.description,
      status: row.status,
      image: row.image
    }
    const dialogRef = this.dialogService.openDialog(EditIntegrationComponent, data, 'medium');
    dialogRef.afterClosed().subscribe((result) => {
      if(result === 'error'){
        this.snackbarService.openErrorSnackbar(this.loc.genericError('update', this.loc.integrations));
      } else if(result && result !== 'not updated') {
        let integrationEdited = this.data.find(x => x.integrationId == row.integrationId) as any as Integration
        integrationEdited.description = result.description
        this.integrationUpdate.emit();
        this.getAllIntegrations();
      }
    });
  }

  details(row: any): void {
    if (row.parameters.length > 0) {
      this.router.navigate(['integrations/details'], {
        queryParams: {
          integrationId: row.integrationId,
        },
      });
    }
  }

  deleteIntegrationAction(row: any): void {
    this.integrationsService
      .deleteIntegration(row.integrationId)
      .subscribe(
        {
          next: (res: BaseResponse) => {
            setTimeout(() => {
              this.loading = false;
              if (res.statusRS?.code === '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.integrations, 'f')
                );
                this.getAllIntegrations();
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.integrations)
                );
              }
            }, 200);
          },
          error: (err) => {
            setTimeout(() => {
              this.loading = false;
            }, 200);
            console.log(err);
            if (err.status !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.integrations)
              );
            }
          }
        }
      );
  }

  deleteIntegration(row: Integration): void {
    const data: DeleteModalData = {
      title: this.loc.integrations.delete,
      icon: 'extension',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.integrations),
    };

    const dialogRef = this.dialogService.openDialog(DeleteModalComponent, data, 'x-small');

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
       this.loading = true;
       this.deleteIntegrationAction(row)
      }
    });
  }

  formatDate(date: string, format?: string): string {
    return this.dateFormatter.formatDate(date, format ? format : 'dd/MM/yyyy');
  }

  onRowClick = {
    action: (row:Integration) => {this.details(row)}
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { addFilter, customFilterPredicate, pipeForFilters, filter} from '../Filters/Filters';
import { Action, ActionButton, BaseTableStyle, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableStyle, TableTransformer, onRowClick } from './table.model';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { stringSort } from '../Filters/Sorts';
import * as moment from 'moment';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  host:{
    class: 'paycorr-table'
  }
})
export class TableComponent implements OnInit, OnChanges {
  loading: boolean = true;
  @Input() title: string = "default"
  @Input('tableClass') tableStyle: string = 'tableResponsive'
  @Input() columns: Column[] = [];
  @Input() data: TableData[] = [];
  @Input() transformers: TableTransformer = {};
  @Input() emptyState: EmptyState = {
    image: 'https://picsum.photos/200',
    message: 'test',
    buttons: []
  }
  @Input() paginatorInfo: PaginatorInfo = {
    singular: 'item',
    plural: 'items'
  }
  @Input() style: TableStyle = {
    row: {
      "height": "50px",
      "font-size": "14px",
    },
    header: {
      "height": "50px",
      "font-size": "14px",
    }
  }

  @Input() actionButtons?: ActionButton[] = undefined

  @Input() filters: TableFilter[] = [];

  filterValues: {
    [key: string]: string[]
  } = {};

  simpleFilters : {
    [key: string]: string
  } = {};

  baseStyle: BaseTableStyle = {
    row: {
      "height": "50px",
      "font-size": "14px",
    },
    header: {
      "height": "75px",
      "font-size": "14px",
    },
    cell: {
      "padding-top": "5px !important",
      "padding-bottom": "5px !important"
    }
  }

  @Input() createButton?: CreateButton;
  @Input() onRowClick?: onRowClick;
  @Input() actions?: Action[] | null = null;
  @Input() actionColumnName?: string;

  dataSource = new MatTableDataSource<any>();

  actionsEnabled: boolean = false;
  displayColumns: string[] = [];
  columnsNoActions: string[] = [];

  pageIndex = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 20]

  dateFilters: {
    [key: string]: {
      start?: string,
      end?: string
    }
  } = {};

  pageEvents(event: any): void {
    const state = JSON.parse(localStorage.getItem('state') || '{}');
    if (state[this.title]) {
      state[this.title].pageIndex = event.pageIndex;
      state[this.title].pageSize = event.pageSize;
    } else {
      state[this.title] = {
        pageIndex: event.pageIndex,
        pageSize: event.pageSize
      }
    }
    localStorage.setItem('state', JSON.stringify(state));
  }

  rowStyle = '';
  headerStyle = '';
  cellStyle = '';

  filteredOptions: {
    [key: string]: Observable<string[]>
  } = {};

  filterControls: {
    [key: string]: UntypedFormControl
  } = {};

  dateFilterControls: {
    [key: string]: {
      start: UntypedFormControl,
      end: UntypedFormControl
    }
  } = {};

  constructor(
    public loc: LocalizationService,
    ) {

  }

  ngOnInit(): void {
    this.setUp();
  }



  loadFilters(): void {
    const state = JSON.parse(localStorage.getItem('state') || '{}');
    for (let f of Object.values(this.filters || {})) {
      if(f.type === 'date'){
        this.dateFilterControls[f.key] = {
          start: new UntypedFormControl(),
          end: new UntypedFormControl()
        }

      }
      if(f.type === 'text'){
        this.filterValues[f.key] = [];
        this.filterControls[f.key] = new UntypedFormControl();
        this.filteredOptions[f.key] = this.filterControls[f.key].valueChanges.pipe(
          pipeForFilters(filter(this.filterValues[f.key]), stringSort)
        );
      }

      if(f.type === 'select'){
        this.filterValues[f.key] = [];
        this.filterControls[f.key] = new UntypedFormControl();
        this.filteredOptions[f.key] = this.filterControls[f.key].valueChanges.pipe(
          pipeForFilters(filter(this.filterValues[f.key]), stringSort)
        );
      }


      if (state && state[this.title] && state[this.title][f.key]) {
        if(typeof state[this.title][f.key] === 'string'){
          this.simpleFilters[f.key] = state[this.title][f.key];
          this.filterControls[f.key].setValue(state[this.title][f.key]);
        }else{
          if(state[this.title][f.key].start){
            this.dateFilters[f.key] = this.dateFilters[f.key] || {};
            this.dateFilters[f.key].start = state[this.title][f.key].start;
            this.dateFilterControls[f.key].start.setValue(state[this.title][f.key].start);
          }
          if(state[this.title][f.key].end){
            this.dateFilters[f.key] = this.dateFilters[f.key] || {};
            this.dateFilters[f.key].end = state[this.title][f.key].end;
            this.dateFilterControls[f.key].end.setValue(state[this.title][f.key].end);
          }

        }
      }

      if(f.type === 'date'){
        this.dateFilters[f.key] = {
          start: '',
          end: ''
        }
      }


    }
    this.dataSource.filter = JSON.stringify(this.simpleFilters);
    this.loadFilterAutoCompleteData();
  }

  loadFilterAutoCompleteData(): void{
    this.data.forEach((element: any) => {
        for(let filter of Object.values(this.filters || {})){
          if(filter.type == 'text'){
            if(!this.filterValues[filter.key].includes(element[filter.key])){
              this.filterValues[filter.key].push(element[filter.key]);
            }
          }
        }
    });
  }


  addFilter(event: any, column: string): void {
    addFilter(event, column, this.title, this.simpleFilters, this.dataSource);
  }

  loadColumns(): void {
    this.actionsEnabled = this.actions ? true : false;
    this.columns = this.columns.filter(column => column.key !== 'actions');
    this.displayColumns = this.columns.map(column => column.key);
    this.columnsNoActions = this.columns.map(column => column.key);
    if (this.actionsEnabled) {
      this.displayColumns.push('actions');
    }
    this.dataSource.data = this.data;
  }


  setStyles() {
    this.rowStyle = this.style.row ? this.getStyleString(this.style.row) : this.getStyleString(this.baseStyle.row);
    this.headerStyle = this.style.header ? this.getStyleString(this.style.header) : this.getStyleString(this.baseStyle.header);
    this.cellStyle = this.style.cell ? this.getStyleString(this.style.cell) : this.getStyleString(this.baseStyle.cell);
  }

  getStyleString(style: any): string {
    return Object.keys(style).map(key => `${key}: ${style[key]}`).join(';');
  }

  handleActionClicked(a: Action, element: any, event: any): void {
    if(a.hidden && a.hidden(element)) return;
    if(a.disabled && a.disabled(element)) return;
    event.stopPropagation();
    a.action(element);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setUp();
  }

  @ViewChild(MatPaginator, { static: false }) set paginator(
    value: MatPaginator
  ) {
    this.loc.translateMatPaginator(value);
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }

  }

  @ViewChild(MatSort, { static: false }) set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }

  }
  resetPaginator(): void {
    this.dataSource.sortData = this.sortData();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = customFilterPredicate;
  }

  setUp(){
    this.loading = true;
    this.loadColumns();
    this.loadFilters();
    this.resetPaginator();
    this.setStyles();
    this.loading = false;
  }

  dateChanged(event: any, time:'start'|'end' , column: string): void {

    if(time === 'start'){
      this.dateFilters[column].start = moment.isDate(event.value) ? moment.utc(event.value).format('YYYY-MM-DD') : undefined;
      this.dateFilters[column].end = undefined;
    } else {
      this.dateFilters[column].end = moment.isDate(event.value) ? moment.utc(event.value).format('YYYY-MM-DD') : undefined;
    }
    const state = JSON.parse(localStorage.getItem('state') || '{}');

    if(this.dateFilters[column].start && this.dateFilters[column].end){
      if (state[this.title]) {
        state[this.title][column] = this.dateFilters[column];
      }
    }
    addFilter(this.dateFilters[column], column, this.title, this.simpleFilters, this.dataSource, 'date');

  }

  sortData() {
    const sortFunction =  (items: TableData[], sort: MatSort): TableData[] =>  {
      if (!sort.active || sort.direction === '') {
        return items;
      }
      let column = sort.active;
      if(this.columns.find(c => c.key === column)?.sortingFunction){
        const fn = this.columns.find(c => c.key === column)?.sortingFunction!;
        return items.sort((a, b) => {
          return sort.direction === 'asc' ? fn(a[column], b[column]) : fn(b[column], a[column]);
        })
      }else{
        return items.sort((a, b) => {
          return sort.direction === 'asc' ? a[column] > b[column] ? 1 : -1 : a[column] < b[column] ? 1 : -1;
        });
      }
    }
   return sortFunction;
  }

}


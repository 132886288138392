import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SettlementMode } from 'src/app/models/settlement-modes/settlement-mode.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SettlementModesService } from 'src/app/services/settlement-modes/settlement-modes.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { CreateModalData, Step, VerifyGroup } from 'src/app/models/stepper/step-modal';

@Component({
  selector: 'app-create-settlement-mode',
  templateUrl: './create-settlement-mode.component.html',
  styleUrls: ['./create-settlement-mode.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateSettlementModeComponent implements OnInit {
  loading = false;

  steps: Step[] = [
    {
      title: this.loc.stepTitle.basicData,
      icon: 'dynamic_feed',
      type: 'form',
      form: [
        {
          key: 'id',
          type: 'text',
          label: this.loc.fields.identification,
          validators: [Validators.required],
        },
        {
          key: 'hour',
          type: 'time',
          label: this.loc.fields.hour,
          validators: [Validators.required],
        },
        {
          key: 'day',
          type: 'numeric',
          label: this.loc.fields.day,
          error: this.loc.errors.numberMustBePositive,
          validators: [Validators.required, Validators.pattern('^[1-9][0-9]*$')],
        },
        {
          key: 'description',
          type: 'text',
          label: 'Descripción',
          validators: [Validators.required],
        },
      ],
    },
  ];

  verify: VerifyGroup[] = [
    {
      title: this.loc.stepTitle.basicData,
      type: 'form',
      elements: ['id', 'hour', 'day', 'description',]
    },
  ];

  modalData: CreateModalData = {
    title: this.loc.settlementMode.create,
    icon: 'paid',
    steps: this.steps,
    verify: this.verify,
    size: 'medium-large',
  }

  constructor(
    public dialogRef: MatDialogRef<CreateSettlementModeComponent>,
    public loc: LocalizationService,
    private formBuilder: UntypedFormBuilder,
    private settlementModeService: SettlementModesService,

  ) { }

  ngOnInit(): void {
  }

   exit(created: string) {
    this.dialogRef.close(created);
  }

  save(verifiedItemsDictionary: { [key: string]: any }) {
    this.setupVerify(verifiedItemsDictionary);
  }

  setupVerify(verifiedItemsDictionary: { [key: string]: any }) {
    let newSettlementMode: SettlementMode =  {
      id: verifiedItemsDictionary['id'],
      hour:  verifiedItemsDictionary['hour'],
      dayAfterExecution: verifiedItemsDictionary['day'],
      description: verifiedItemsDictionary['description']
    }
    this.settlementModeService.createSettlementMode(newSettlementMode).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit(res.statusRS?.code as string);
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }
}


<div class="general-operations-div">
    <div style="display: flex">
      <button mat-icon-button class="back" (click)="goBack()">
        <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>
      <ng-container *ngIf="!loading">
        <div class="title-container">
          <label class="title-mid-text"> {{ outputId }}</label>
        </div>
      </ng-container>
    </div>
  </div>
  <mat-card class="details-card">
    <div class="flex-container">
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.step.singular
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ stepName }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{
            loc.detailsHeaders.api
          }}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ apiName }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
  
  <app-list-mapped-api-responses [stepId]="stepId" [apiId]="apiId" [outputId]="outputId"></app-list-mapped-api-responses>
  
<ng-container *ngIf="!loading">
    <app-table
      [emptyState]="emptyState"
      [data]="data"
      [columns]="columns"
      [paginatorInfo]="paginatorInfo"
      [title]="'managers'"
      [filters]="filters"
      [actions]="actions"
      [createButton]="createButton"
      [transformers]="transformer"
      [style]="style"
      >
  </app-table>
</ng-container>

<ng-container *ngIf="loading">
  <app-loading-table 
    [title]="'integrations'" 
    [columns]="columns" 
    [filters]="filters"
    >
  </app-loading-table>
</ng-container>

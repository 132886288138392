import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { StepsService } from 'src/app/services/steps/steps.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Step } from 'src/app/models/step/step.model';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ActivateModalData } from 'src/app/models/activate-modal/activate-modal-data.model';
import { ActivateModalComponent } from 'src/app/utils/activate-modal/activate-modal.component';
import { ApisService } from 'src/app/services/apis/apis.service';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';

@Component({
  selector: 'app-step-details',
  templateUrl: './step-details.component.html',
  styleUrls: ['./step-details.component.css'],
  host: {
    class: 'component'
  }
})
export class StepDetailsComponent implements OnInit {
  loading = false;
  selectedIndex = 0;
  stepId?: string;
  step?: Step;
  apiName = '';
  apiId = '';
  isExecution = false;

  icon?: string;

  constructor(
    public loc: LocalizationService,
    public location: Location,
    public route: ActivatedRoute,
    private stepsService: StepsService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private apisService: ApisService,
    public router: Router
  ) {
    this.route.queryParams.subscribe(params => {
      this.stepId = params['stepId'];
    this.getStepDetails();

    });
    
  }

  ngOnInit(): void {
    let state = JSON.parse(localStorage.getItem('state')!);
    this.selectedIndex = state.metadata.tab;
  }

  goBack(): void {
    this.router.navigate(['metadata'], {
      queryParams: {}
    });
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state.metadata = {
      tab: this.selectedIndex
    };
    localStorage.setItem('state', JSON.stringify(state));
  }

  getStepDetails() {
    this.loading = true;
    this.stepsService.getStepDetails(this.stepId!).subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.step = (res.messageRS as any) as Step;
          if (this.step!.type === 'EXECUTION') {
            this.isExecution = true;
          }
          this.icon = this.step.icon;
          this.apisService.getAPIDetails(this.step!.api!).subscribe(
            (res: BaseResponse) => {
              if (res.statusRS?.code === '0') {
                this.apiName = res.messageRS!.name;
                this.apiId = res.messageRS!.apiId;
              }
            },
            err => {
              this.loading = false;
              console.log(err);
            }
          );
        }
      },
      err => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  activateStep() {
    let dataModal: ActivateModalData = {
      title: this.loc.step.activate,
      icon: 'psychology',
      confirmActivateMessage: this.loc.confirmActivate(this.loc.step)
    };
    const dialogRef = this.dialogService.openDialog(
      ActivateModalComponent,
      dataModal,
      'x-small'
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'not activated') {
        this.loading = true;
        this.stepsService
          .activateStep(this.stepId!)
          .subscribe((res: BaseResponse) => {
            setTimeout(() => {
              if (res.statusRS?.code === '0') {
                this.getStepDetails();
              } else if (res.statusRS?.code === '-92035') {
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.stepNeedsMappings
                );
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('update', this.loc.step)
                );
              }
              this.loading = false;
            }, 200);
          });
      }
    });
  }
}

<ng-container *ngIf="!loading">
    <app-table 
      [emptyState]="emptyState" 
      [data]="data" 
      [columns]="columns" 
      [paginatorInfo]="paginatorInfo"
      [title]="'service operation mediums'" 
      [filters]="filters" 
      [actions]="actions" 
      [createButton]="createButton">
    </app-table>
  </ng-container>
  
  <ng-container *ngIf="loading">
    <app-loading-table 
      [title]="'service operation mediums'" 
      [columns]="columns" 
      [filters]="filters">
    </app-loading-table>
  </ng-container>
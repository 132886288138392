import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DisaggregationStep } from 'src/app/models/posting-schemes/DisaggregationStep.model';
import { PostingStep } from 'src/app/models/posting-schemes/PostingStep.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreatePostingStepComponent } from '../create-posting-step/create-posting-step.component';
import {
  addFilter,
  customFilterPredicate,
  filter,
  pipeForFilters,
} from '../../../utils/Filters/Filters';
import { stringSort } from '../../../utils/Filters/Sorts';

@Component({
  selector: 'app-list-posting-steps',
  templateUrl: './list-posting-steps.component.html',
  styleUrls: ['./list-posting-steps.component.css'],
  host: {
    class: 'component',
  },
})
export class ListPostingStepsComponent implements OnInit {
  @Input() tableStyle = 'tableResponsive';
  @Input() id = '';

  fieldsDataSource = new MatTableDataSource();
  loadingDataSource = new MatTableDataSource<string>();
  fieldsDisplayedColumns: string[] = [
    'ordinal',
    'stepTemplateId',
    'subject',
    'operation',
    'suboperation',
    'postingSign',
    'group',
    'errorGroup',
    'isReverse',
    'actions',
  ];
  filteredOptions: Observable<string[]> = new Observable<string[]>();
  filteredOptionsDesc: Observable<string[]> = new Observable<string[]>();
  myControl = new UntypedFormControl();
  myControlDesc = new UntypedFormControl();
  filters: any = {
    description:
      JSON.parse(localStorage.getItem('state')!)?.transPosting?.description ||
      '',
    id: JSON.parse(localStorage.getItem('state')!)?.transPosting?.id || '',
  };
  loading = true;
  fieldsNames: string[] = [];
  fieldsDescriptions: string[] = [];

  steps: PostingStep[] = [];

  pageSize = 5;
  pageIndex = 0;

  dispatchOnlineFlows: any[] = [];
  respParseOnlineFlows: any[] = [];
  messageOnlineFlows: any[] = [];

  constructor(
    public postingSchemesService: PostingSchemesService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  @ViewChild(MatPaginator, { static: false }) set paginator(
    value: MatPaginator
  ) {
    this.loc.translateMatPaginator(value);
    if (this.fieldsDataSource) {
      this.fieldsDataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false }) set sort(value: MatSort) {
    if (this.fieldsDataSource) {
      this.fieldsDataSource.sort = value;
    }
  }

  ngOnInit(): void {
    this.loadingDataSource.data = ['', '', '', '', ''];
    let state = JSON.parse(localStorage.getItem('state')!);
    this.myControl.setValue(state?.transPosting?.id || '');
    this.filters['id'] = state?.transPosting?.id || '';
    this.myControlDesc.setValue(state?.transPosting?.description || '');
    this.filters['description'] = state?.transPosting?.description || '';
    this.loadPostingSteps();
    this.loadPostingFlows();
  }

  pageEvents(event: any) {
    let state = JSON.parse(localStorage.getItem('state')!);
    if (!state.transPosting) {
      state.transPosting = {};
    }
    state.transPosting['pageIndex'] = event.pageIndex;
    state.transPosting['pageSize'] = event.pageSize;
    localStorage.setItem('state', JSON.stringify(state));
  }

  loadPostingSteps(): void {
    this.loading = true;
    this.postingSchemesService.getPostingSteps(this.id).subscribe(
      (res: BaseResponse) => {
        let response = res.messageRS as any as PostingStep[];

        this.steps = response;

        this.fieldsDataSource.data = this.steps;
        this.fieldsNames = this.steps.map((step) => step.stepTemplateId!);
        this.fieldsDescriptions = this.steps.map((step) => step.subject!);
        this.filteredOptions = this.myControl.valueChanges.pipe(
          pipeForFilters(filter(this.fieldsNames).bind(this), stringSort)
        );
        this.filteredOptionsDesc = this.myControlDesc.valueChanges.pipe(
          pipeForFilters(filter(this.fieldsDescriptions).bind(this), stringSort)
        );
        this.loading = false;
        this.setSortAndPaginator();
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }
  addFilter(event: any, column: string) {
    addFilter(
      event,
      column,
      'transPosting',
      this.filters,
      this.fieldsDataSource
    );
  }

  onActionClicked(row: any): void {}

  setSortAndPaginator(): void {
    this.fieldsDataSource.sort = this.sort;
    this.fieldsDataSource.filterPredicate = customFilterPredicate;
    this.fieldsDataSource.paginator = this.paginator;
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreatePostingStepComponent,
      {
        postingId: this.id,
        steps: this.steps,
        dispatchOnlineFlows: this.dispatchOnlineFlows,
        messageOnlineFlows: this.messageOnlineFlows,
        respParseOnlineFlows: this.respParseOnlineFlows

      },
      'large'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.step)
        );
        this.loadPostingSteps();
      } else if (result === '-91691') {
        this.snackbarService.openErrorSnackbar(this.loc.errors.postingUsed);
      } else if (result === '-91690') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.step)
        );
      }
    });
  }

  delete(element: PostingStep) {
    let dataModal: DeleteModalData = {
      title: this.loc.step.delete,
      icon: 'signpost',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.step),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      dataModal,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        let stepToDelete = this.steps.find((x) => x.ordinal == element.ordinal);
        let stepIndex = this.steps.indexOf(stepToDelete!);

        let newSteps: any[] = [];
        this.steps.forEach((x: any, index: number) => {
          if (index < stepIndex) {
            newSteps.push(x);
          } else if (index > stepIndex) {
            x.ordinal = (parseInt(x.ordinal) - 1).toString();
            newSteps.push(x);
          }
        });
        let message = {
          postingId: this.id,
          steps: newSteps.map((step: PostingStep) => {
            return {
              ordinal: parseInt(step.ordinal!),
              stepTemplateId: step.stepTemplateId!,
              isReverse: step.isReverse!,
              postingSign: step.postingSign,
              group: step.group!,
              errorGroup: step.errorGroup!,
            };
          }),
        };
        this.postingSchemesService.createPostingSteps(message).subscribe(
          (res: BaseResponse) => {
            if (res.statusRS?.code === '0') {
              this.loadPostingSteps();
            } else if (res.statusRS?.code === '-91691') {
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.postingUsed
              );
              this.loading = false;
            } else if (res.statusRS?.code === '-91690') {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('create', this.loc.step)
              );
              this.loading = false;
            }
          },
          (err) => {
            this.logoutDialogService.openLogoutDialog(err);
          }
        );
      }
    });
  }

  moveUp(element: DisaggregationStep): void {
    this.loading = true;
    const index = this.steps.indexOf(element);
    if (index === 0) {
      // can not move higher
      return;
    }

    const swap = this.steps[index - 1];
    swap.ordinal = (parseInt(swap.ordinal!) + 1).toString();
    element.ordinal = (parseInt(element.ordinal!) - 1).toString();
    this.steps[index - 1] = element;
    this.steps[index] = swap;
    this.steps.sort((a: any, b: any) => (a.ordinal!! < b.ordinal!! ? -1 : 1));
    let messageToSend = {
      postingId: this.id,
      steps: this.steps.map((step) => {
        return {
          ordinal: parseInt(step.ordinal!)!,
          stepTemplateId: step.stepTemplateId!,
          isReverse: step.isReverse!,
          postingSign: step.postingSign,
          group: step.group!,
          errorGroup: step.errorGroup!,
        };
      }),
    };
    this.postingSchemesService.createPostingSteps(messageToSend).subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loadPostingSteps();
        } else if (res.statusRS?.code === '-91691') {
          this.snackbarService.openErrorSnackbar(this.loc.errors.postingUsed);
          this.loading = false;
        } else if (res.statusRS?.code === '-91690') {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('create', this.loc.step)
          );
          this.loading = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  moveDown(element: any): void {
    this.loading = true;
    const index = this.steps.indexOf(element);
    if (index === this.steps.length - 1) {
      // can not move down
      return;
    }

    const swap = this.steps[index + 1];
    swap.ordinal = (parseInt(swap.ordinal!) - 1).toString();
    element.ordinal = (parseInt(element.ordinal!) + 1).toString();
    this.steps[index + 1] = element;
    this.steps[index] = swap;
    this.steps.sort((a: any, b: any) => (a.ordinal!! < b.ordinal!! ? -1 : 1));
    let messageToSend = {
      postingId: this.id,
      steps: this.steps.map((step) => {
        return {
          ordinal: parseInt(step.ordinal!)!,
          stepTemplateId: step.stepTemplateId!,
          isReverse: step.isReverse!,
          postingSign: step.postingSign,
          group: step.group!,
          errorGroup: step.errorGroup!,
        };
      }),
    };
    this.postingSchemesService.createPostingSteps(messageToSend).subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loadPostingSteps();
        } else if (res.statusRS?.code === '-91691') {
          this.snackbarService.openErrorSnackbar(this.loc.errors.postingUsed);
          this.loading = false;
        } else if (res.statusRS?.code === '-91690') {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('create', this.loc.step)
          );
          this.loading = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  showUpArrow(element: any) {
    const index = this.steps.indexOf(element);
    return index >= 1;
  }

  showDownArrow(element: any) {
    const index = this.steps.indexOf(element);
    return this.steps.length !== index + 1;
  }

  loadPostingFlows(){
    this.loading = true;
    this.postingSchemesService
    .getPostingFlows('DISPATCH_ONLINE')
    .subscribe(
      (res: BaseResponse) => {
        this.dispatchOnlineFlows = res.messageRS as unknown as any[];
        this.dispatchOnlineFlows = this.dispatchOnlineFlows.map( item => ({
          flowId: item.f1Mens01650171,
          flowVersion: item.f1Vers01650171,
          flowEntity: item.f1EnEx11800171,
          flowType: item.MSGTYPE0171,
          description: item.DESCRI0171,
        }));
        this.dispatchOnlineFlows = this.dispatchOnlineFlows.sort((a:any, b:any) => (a.description! < b.description!) ? -1 : 1);
        this.loading = false;
      },
      (err) => {
        console.log(err)
      }
    );
    this.loading = true;
    this.postingSchemesService
    .getPostingFlows('RESP_PARSE_ONLINE')
    .subscribe(
      (res: BaseResponse) => {
        this.respParseOnlineFlows = res.messageRS as unknown as any[];
        this.respParseOnlineFlows = this.respParseOnlineFlows.map( item => ({
          flowId: item.f1Mens01650171,
          flowVersion: item.f1Vers01650171,
          flowEntity: item.f1EnEx11800171,
          flowType: item.MSGTYPE0171,
          description: item.DESCRI0171,
        }));
        this.respParseOnlineFlows = this.respParseOnlineFlows.sort((a:any, b:any) => (a.description! < b.description!) ? -1 : 1);
        this.loading = false;
      },
      (err) => {
        console.log(err)
      }
    );
    this.loading = true;
    this.postingSchemesService
    .getPostingFlows('MESSAGE_ONLINE')
    .subscribe(
      (res: BaseResponse) => {
        this.messageOnlineFlows = res.messageRS as unknown as any[];
        this.messageOnlineFlows = this.messageOnlineFlows.map( item => ({
          flowId: item.f1Mens01650171,
          flowVersion: item.f1Vers01650171,
          flowEntity: item.f1EnEx11800171,
          flowType: item.MSGTYPE0171,
          description: item.DESCRI0171,
        }));
        this.messageOnlineFlows = this.messageOnlineFlows.sort((a:any, b:any) => (a.description! < b.description!) ? -1 : 1);
        this.loading = false;
      },
      (err) => {
        console.log(err)
      }
    );
  }

  goToFlows(row: any){
    this.router.navigate(['posting-details/flows'], {
      queryParams: {
        postingId: row.postingId,
        ordinal: row.ordinal
      },
      queryParamsHandling: 'merge',
    });

  }
  
}

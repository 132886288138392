import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntegrationParameters } from 'src/app/models/integration/integration.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateParameterComponent } from '../create-parameter/create-parameter.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import {
  Action,
  Column,
  CreateButton,
  EmptyState,
  PaginatorInfo,
  TableData,
  TableFilter,
  TableStyle,
  TableTransformer,
} from 'src/app/utils/table/table.model';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';

@Component({
  selector: 'app-list-parameters',
  templateUrl: './list-parameters.component.html',
  styleUrls: ['./list-parameters.component.css'],
})
export class ListParametersComponent implements OnInit, OnChanges {
  @Input() parameters: IntegrationParameters[] = [];
  @Input() loading = true;

  integrationId = '';
  keys: any[] = [];

  constructor(
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private snackbarService: SnackbarService,
    private integrationsService: IntegrationsService,
    public loc: LocalizationService,
    private htmlGenerator: SafeHTMLGeneratorService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.integrationId = params.integrationId;
    });
  }

  ngOnChanges(): void {
    this.data = this.parameters as any as TableData[]
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-services.png',
    message: this.loc.parameters.empty,
    buttons: [],
  };

  data: TableData[] = [];

  columns: Column[] = [
    {
      key: 'key',
      title: this.loc.tableHeaders.id,
      centered: false,
      sortable: true,
    },
    {
      key: 'description',
      title: this.loc.tableHeaders.description,
      centered: false,
      sortable: true,
    },
    {
      key: 'categoryId',
      title: this.loc.tableHeaders.category,
      centered: false,
      sortable: true,
    },
    {
      key: 'value',
      title: this.loc.tableHeaders.value,
      centered: false,
      sortable: true,
    },
    {
      key: 'isMandatory',
      title: this.loc.tableHeaders.isMandatory,
      centered: true,
      sortable: false,
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.parameters;
  filters: TableFilter[] = [
    {
      key: 'key',
      label: this.loc.tableHeaders.id,
      placeholder: this.loc.tableHeaders.key,
      type: 'text',
    },
    {
      key: 'description',
      label: this.loc.tableHeaders.description,
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    },
    {
      key: 'categoryDescription',
      label: this.loc.tableHeaders.category,
      placeholder: this.loc.tableHeaders.category,
      type: 'text',
    },
   
    {
      key: 'isMandatory',
      label: this.loc.tableHeaders.isMandatory,
      placeholder: this.loc.tableHeaders.isMandatory,
      type: 'select',
      options: [
        {
          value: 'true',
          label: this.loc.common.yes
        },
        {
          value: 'false',
          label: this.loc.common.no
        },
        {
          value: '',
          label: this.loc.common.both
        }
      ]
    },
    
  ];

  actions: Action[] = [
    {
      icon: 'edit',
      tooltip: this.loc.parameters.edit,
      action: (row: IntegrationParameters) => this.editParameter(row),
    },
    {
      icon: 'delete',
      tooltip: this.loc.parameters.delete,
      action: (row: IntegrationParameters) => this.deleteParameter(row),
    },
  ];

  createButton: CreateButton = {
    tooltip: this.loc.parameters.create,
    action: () => this.createParameter(),
  };

  transformers: TableTransformer = {
    isMandatory: (value: string) =>
      this.htmlGenerator.statusIcon(value === 'true' ? true : false),
    value: (value: string) => value || "-"
  };

  deleteParameter(row: IntegrationParameters): void {
    let data: DeleteModalData = {
      title: this.loc.parameters.delete,
      icon: 'extension',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.parameters),
    };

    const dialogRef = this.dialogService.openDialog(DeleteModalComponent, data, 'x-small');

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        this.integrationsService
      .deleteIntegrationParameter(this.integrationId, row.key!!, row.categoryId!!)
      .subscribe(
        {
          next: (res: BaseResponse) => {
            this.loading = false;
            if (res.statusRS?.code === '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.parameters)
              );
              this.reloadParameters();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.parameters)
              );
            }
          },
          error: (err) => {
            this.loading = false;
            console.log(err);
            if (err.status !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.parameters)
              );
            }
          }
        }
      );
      }
    });
  }


  editParameter(row: IntegrationParameters): void {
    let data: EditModalData = {
      title: this.loc.parameters.edit,
      icon: 'extension',
      fields: [
        {label: this.loc.fields.value, value: row.value!!, errorMsg: this.loc.parameters.errorEditParameterValue, validators: [Validators.required]}
      ]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyParameter(row, result)
      }
    });
  }

  modifyParameter(row: any, result: any) {
    let newValue = result.find((x: any) => x.label === this.loc.tableHeaders.value).value
    let newRow = { ...row, value: newValue }
    this.integrationsService
      .editIntegrationParameter(newRow, this.integrationId)
        .subscribe(
        {
          next: (res: BaseResponse) => {
            this.loading = false;
            if (res.statusRS?.code === '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('update', this.loc.parameters)
              );
              this.reloadParameters();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.parameters)
              );
            }
          },
          error:(err) => {
            this.loading = false;
            console.log(err);
            if (err.status !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.parameters)
              );
            }
          }
        }
        );
  }

  createParameter(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateParameterComponent,
      { integrationId: this.integrationId },
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.parameters)
        );
        this.loading = true;
        this.reloadParameters();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.parameters)
        );
      }
    });
  }

  reloadParameters(): void {
    this.loading = true
    this.integrationsService
      .getIntegrationDetails(this.integrationId)
      .subscribe({
        next: (res: BaseResponse) => {
          if (res.statusRS?.code === '0') {
            const integrations = res.messageRS as unknown as any;
            this.parameters = integrations.parameters;
            this.data = this.parameters as any as TableData[]
            this.loading = false;
          } else {
            // TODO HANDLE ERROR
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';
import { Country } from 'src/app/models/country/country.model';
import { IdentificationType } from 'src/app/models/country/identification-type.model';
import { Tax } from 'src/app/models/taxes/tax.model';
import { BaseRequest } from '../../models/base/base-request.model';
import { CountryCurrency } from '../../models/countryCurrency/countryCurrency';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/models/responses.model';
import { Currency } from 'src/app/models/Currency/currency';
@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  readonly rootURL = environment.apiUrl;
  constructor(
  private http: HttpClient,
  private tokenService: TokenService) { }


  getCountries(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
      return this.http.get(
        this.rootURL + 'RSAdaptorFE/api/v1/paycorr/shifts/country/0/get-countries',
        HTTP_OPTIONS
      ) as Observable<BaseResponse<Country[]>>;
  }

  getCurrencies(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
      return this.http.get(
        this.rootURL + 'RSAdaptorFE/api/v1/paycorr/currencies/currency/x/list-currencies',
        HTTP_OPTIONS
      ) as Observable<BaseResponse<Currency[]>>;
  }

  createCurrency(currency:Currency){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = currency;
    return this.http.post(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/currencies/currency/x/create-currency',
      baseRequest
    ) as Observable<BaseResponse<void>>;
  }

  createCountry(country:Country){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = country;
    return this.http.post(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/shifts/country/x/create-country',
      baseRequest
    )
  }

  editCountry(country:Country){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = country;
    return this.http.put(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/shifts/country/' + country.countryId + '/edit-country',
      baseRequest
    )
  }

  deleteCountry (countryId: string){
    return this.http.delete(
      this.rootURL + `RSAdaptorFE/api/v1/paycorr/shifts/country/` + countryId + `/delete-country`
    ) as Observable<BaseResponse<void>>;

  }

  deleteCurrency (code: string){
    return this.http.delete(
      this.rootURL + `RSAdaptorFE/api/v1/paycorr/currencies/currency/` + code + `/delete-currency`
    ) as Observable<BaseResponse<void>>;

  }

  editCurrencySymbol(code: string, symbol: string){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      symbol
    };
    return this.http.put(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/currencies/currency/' + code + '/update-symbol',
      baseRequest
    )
  }

  editCurrencyDescription(code: string, description: string){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      description
    };
    return this.http.put(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/currencies/currency/' + code + '/update-description',
      baseRequest
    )
  }

  getTaxes(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
      return this.http.get(
        this.rootURL + 'RSAdaptorFE/api/v1/paycorr/taxes/tax/X/get',
        HTTP_OPTIONS
      ) as Observable<BaseResponse<Tax[]>>;
  }

  getIdentificationTypes(country: string){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
        country
      }
    };
      return this.http.get(
        this.rootURL + 'RSAdaptorFE/api/v1/paycorr/users/identification-type/X/get-identification-types',
        HTTP_OPTIONS
      );
  }

  deleteIdentificationType(identificationTypeId:string, countryId: string){
    const HTTP_OPTIONS = {
      params: {
        identificationTypeId,
        countryId
      }
    };
    return this.http.delete(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/users/identification-type/0/delete-identification-type',HTTP_OPTIONS
    )
  }

  editIdentificationType(identificationType:IdentificationType){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = identificationType;
    return this.http.put(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/users/identification-type/x/update-identification-type',
      baseRequest
    )
  }

  createIdentificationType(identificationType:IdentificationType){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = identificationType;
    return this.http.post(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/users/identification-type/x/create-identification-type',
      baseRequest
    )
  }

  createTax(tax: Tax){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = tax;
    return this.http.post(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/taxes/tax/x/create',
      baseRequest
    ) as Observable<BaseResponse<void>>;
  }

  updateTaxDescription(body: any){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = body;
    return this.http.put(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/taxes/tax/x/update-description',
      baseRequest
    )
  }
  updateTaxValue(body: any){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = body;
    return this.http.put(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/taxes/tax/x/update-value',
      baseRequest
    )
  }
  deleteTax(id: string, version: string, country: string){
    const HTTP_OPTIONS = {
      params: {
        id,
        version,
        country
      }
    };
    return this.http.delete(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/taxes/tax/0/delete',HTTP_OPTIONS
    ) as Observable<BaseResponse<void>>;
  }

  addCurrency (currencyId:string, countryData :CountryCurrency ){
    const baseRequest = new BaseRequest();
    baseRequest.messageRQ = countryData;
    return this.http.post (this.rootURL + `RSAdaptorFE/api/v1/paycorr/currencies/currency/`+ currencyId + `/assign-country`,
    baseRequest) as Observable<BaseResponse<void>>;
  }

  listCountryCurrencies (countryId :string ) {
    const HTTP_OPTIONS = {
      params : {
        countryId
      }
    }
    return this.http.get(this.rootURL + 'RSAdaptorFE/api/v1/paycorr/currencies/currency/x/list-country-currency',
    HTTP_OPTIONS ) as Observable<BaseResponse<CountryCurrency[]>>;
  }

  deleteCountryCurrency (currencyId : string, countryId: string){
    const HTTP_OPTIONS = {
      params : {
        countryId
      }
    }
    return this.http.delete( this.rootURL + `RSAdaptorFE/api/v1/paycorr/currencies/currency/`+ currencyId + `/delete-country-currency`, HTTP_OPTIONS
    ) as Observable<BaseResponse<void>>;

  }

  updateCountryCurrency (currencyId : string, countryData: CountryCurrency) {
    const baseRequest = new BaseRequest();
    baseRequest.messageRQ = countryData;
    return this.http.put( this.rootURL + `RSAdaptorFE/api/v1/paycorr/currencies/currency/` + currencyId + `/update-country-currency`,
    baseRequest );

  }

  getMissingCountryFlag(countryNameOrId: string, type: string){
    countryNameOrId = countryNameOrId.toLowerCase();
    const imagePath = 'assets/img/' + countryNameOrId;

    if (type === 'svg') {
      return imagePath + '.svg';
    } else {
      return imagePath + '.png';
    }
  }

}


export const environment = {
  production: true,
  apiUrl : 'https://paycorr.biz/',
  cloudinary: {
    cloud_name: 'paycorrcloud',
    upload_preset: 'paycorrPreset',
    api_key: '997834364654234',
    api_secret: 'lmaDI1EdRdujq6iVYjyNJVxaiw8',
    secure: true,
  }
};

import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { Subject } from 'rxjs';
import { Action, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { IntegrationType } from 'src/app/models/integration/integrationType.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateIntegrationTypeComponent } from '../create-integration-type/create-integration-type.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-list-integration-types',
  templateUrl: './list-integration-types.component.html',
  styleUrls: ['./list-integration-types.component.css'],
  host: {
    class: 'component',
  }
})
export class ListIntegrationTypesComponent implements OnInit {
  @Output()
  loaded = new EventEmitter<string>();

  loading = true;

  @Input()
  reloadObserver!: Subject<boolean>;

  constructor(
    private integrationsService: IntegrationsService,
    private dialogService: DialogService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService,
    private snackbarService: SnackbarService,
    private dateFormatter: DateFormatterService,
    private htmlGenerator: SafeHTMLGeneratorService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getAllIntegrationTypes();
    if (this.reloadObserver) {
      this.reloadObserver.subscribe((response) => {
        this.getAllIntegrationTypes();
      });
    }
  }

  getAllIntegrationTypes(): void {
    this.loading = true;
    this.integrationsService.getIntegrationTypes().subscribe(
      {
        next: (data: BaseResponse) => {
        this.data = data.messageRS as unknown as TableData[];
        this.loading = false;
      },
      error: (err: any) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    }
    );
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-integrations.png',
    message: this.loc.integrations.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.createIntegrationType(),
      },
    ],
  };
  
  createIntegrationType(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateIntegrationTypeComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.integrations, 'f')
        );
        this.getAllIntegrationTypes();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.integrations)
        );
      } else if (result === 'invalid') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.integrationTypeWithSameIdAlreadyExists
        );
      }
    });
  }
  
  columns: Column[] = [
    { title: this.loc.tableHeaders.id, key: 'id', sortable: true },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
      sortable: true,
    }
  ];

  paginatorInfo: PaginatorInfo = this.loc.integrations;
  
  data: TableData[] = [];

  filters: TableFilter[] = [
    {
      key: 'id',
      label: this.loc.tableHeaders.id,
      placeholder: this.loc.tableHeaders.id,
      type: 'text',
    },
    {
      key: 'description',
      label: this.loc.tableHeaders.description,
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    }
  ];

  createButton: CreateButton = {
    tooltip: this.loc.integrationTypes.create,
    action: () => this.createIntegrationType(),
  }


  actions: Action[] = [
    {
      icon: 'more_horiz',
      tooltip: this.loc.common.details,
      action: (row: IntegrationType) => {
        this.details(row);
      },
    },
    {
      icon: 'edit',
      tooltip: this.loc.integrationTypes.edit,
      action: (row: IntegrationType) => {
        this.editIntegrationType(row);
      },
    },
    {
      icon: 'delete',
      tooltip: this.loc.integrationTypes.delete,
      action: (row: IntegrationType) => {
        this.deleteIntegrationType(row);
      },
    },
  ];

  transformers: TableTransformer = {
    creationDate: (value: string) => this.formatDate(value),
    status: (value: string) =>
      this.htmlGenerator.statusIcon(value === 'ACTIVE'),
  };

  formatDate(date: string, format?: string): string {
    return this.dateFormatter.formatDate(date, format ? format : 'dd/MM/yyyy');
  }

  details(row: any): void {
      this.router.navigate(['integrations/integration-type-details'], {
        queryParams: {
          id: row.id,
        },
      });
  }


  editIntegrationType(row: any){
    let data: EditModalData = {
      title: this.loc.integrationTypes.edit,
      icon: 'extension',
      fields: [{label: this.loc.tableHeaders.description, 
                value: row.description, 
                errorMsg: this.loc.errors.emptyDescription, 
                validators: [Validators.required]}
              ]
    }
    const dialogRef = this.dialogService.openDialog(EditModalComponent, data, 'x-small');
    dialogRef.afterClosed().subscribe((result) => {
      if(result && result !== 'not updated'){
        this.loading = true;
        this.modifyIntegrationType(row, result);
      }
    }); 
  }

  modifyIntegrationType(row: any, result: any): void {
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
    let newIntegrationType = {...row, description: newDescription}
    this.integrationsService.editIntegrationType(newIntegrationType).subscribe(
      {
        next: (res: BaseResponse) => {
          setTimeout(() => {
            this.loading = false;
            if(res.statusRS?.code === '0'){
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('update', this.loc.integrationTypes, 'f')
              );
              this.getAllIntegrationTypes();
            }
            else if (res.statusRS?.code === "-91926"){
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.invalidIntegrationTypeId
              );
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.integrationTypes)
              );
            }
          }, 200);
        },
        error: (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if(err.status !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.integrations)
            );
          }
        }
      }
    );
  }

  deleteIntegrationType(row: IntegrationType): void {
      const data: DeleteModalData = {
        title: this.loc.integrationTypes.delete,
        icon: 'extension',
        confirmDeleteMessage: this.loc.confirmDelete(this.loc.integrationTypes),
      };

      const dialogRef = this.dialogService.openDialog(DeleteModalComponent, data, 'x-small');

      dialogRef.afterClosed().subscribe((result) => {
        if(result === 'delete'){
          this.loading = true;
          this.deleteIntegrationTypeAction(row)
        }
      });
  }

  deleteIntegrationTypeAction(row: any): void {
    this.integrationsService
      .deleteIntegrationType(row.id)
      .subscribe(
        {
          next: (res: BaseResponse) => {
            setTimeout(() => {
              this.loading = false;
              if(res.statusRS?.code === '0'){
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.integrationTypes, 'f')
                );
                this.getAllIntegrationTypes();
              } else if(res.statusRS?.code === '-91928'){
                this.snackbarService.openErrorSnackbar(this.loc.errors.invalidIntegrationTypeId)
              }else if(res.statusRS?.code === '-91969'){
                this.snackbarService.openErrorSnackbar(this.loc.errors.integrationTypeBeingUsed)
              }  
              else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.integrationTypes)
                );
              }
            }, 200);
          },
          error: (err) => {
            setTimeout(() => {
              this.loading = false;
            }, 200);
            console.log(err);

            if(err.status !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.integrationTypes)
              );
            }
          }
        }
      );
  }


  onRowClick = {
    action: (row:IntegrationType) => {this.details(row)}
  }

}
<div class="general-operations-div">
    <div style="display: flex; margin-right: 15px;align-items: center;">
      <button mat-fab class="page-icon-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon">settings</mat-icon>
      </button>
      <label class="page-title" i18n>{{loc.routes.api}}</label>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <app-table
      [emptyState]="emptyState"
      [data]="data"
      [columns]="columns"
      [paginatorInfo]="paginatorInfo"
      [title]="'country'"
      [filters]="filters"
      [actions]="actions"
      [createButton]="createButton"
      [onRowClick]="onRowClick"
      >
    </app-table>
  </ng-container>
  
  <ng-container *ngIf="loading">
    <app-loading-table
      [title]="'country'"
      [columns]="columns"
      [filters]="filters">
    </app-loading-table>
  </ng-container>
  
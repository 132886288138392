import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.css'],
  host: {
    class:'component'
  }
})
export class MetadataComponent implements OnInit {
  selectedIndex = 0;


  constructor(
    public loc: LocalizationService,
  ) { }

  ngOnInit(): void {
    let state = JSON.parse(localStorage.getItem('state')!);
    this.selectedIndex = state?.metadata?.tab || 0
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state.metadata = {
      tab: this.selectedIndex
    };
    localStorage.setItem('state', JSON.stringify(state));
  }
}

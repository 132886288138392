<div class="dark" *ngIf="loading">
  <mat-spinner
    diameter="70"
    style="
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -35px;
      margin-top: -35px;
    "
  >
  </mat-spinner>
</div>

<div class="title">
  <div class="inner-title-container">
    <button mat-fab class="confirm-dialog-button blue-button-icon">
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon"
        >calendar_month</mat-icon
      >
      <mat-icon class="action-button-icon-add">add</mat-icon>
    </button>
    <label class="dialog-title" i18n>{{ loc.nonWorkingDays.create }}</label>
  </div>
  <button class="exit-button" (click)="exit('not created')">
    <mat-icon matSuffix class="exit-icon">close</mat-icon>
  </button>
</div>
<mat-horizontal-stepper
  class="horizontal-stepper actions-stepper"
  [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex"
  labelPosition="bottom"
  [linear]="true"
  #stepper
  appStepper
  [step]="stepper.selectedIndex"
>
  <ng-template matStepperIcon="done" let-index="index">
    <mat-icon
      class="stepper-icon"
      fontSet="material-icons-outlined"
      *ngIf="index == 0"
      >description</mat-icon
    >
    <mat-icon
      class="stepper-icon"
      fontSet="material-icons-outlined"
      *ngIf="index == 1"
      >check_box</mat-icon
    >
  </ng-template>

  <ng-template matStepperIcon="edit" let-index="index">
    <mat-icon
      class="stepper-icon"
      fontSet="material-icons-outlined"
      *ngIf="index == 0"
      >description</mat-icon
    >
    <mat-icon
      class="stepper-icon"
      fontSet="material-icons-outlined"
      *ngIf="index == 1"
      >check_box</mat-icon
    >
  </ng-template>

  <ng-template matStepperIcon="number" let-index="index">
    <mat-icon
      class="stepper-icon"
      fontSet="material-icons-outlined"
      *ngIf="index == 0"
      >description</mat-icon
    >
    <mat-icon
      class="stepper-icon"
      fontSet="material-icons-outlined"
      *ngIf="index == 1"
      >check_box</mat-icon
    >
  </ng-template>

  <mat-step [stepControl]="firstFormGroup" [editable]="true">
    <ng-template matStepLabel i18n>{{ loc.stepTitle.basicData }}</ng-template>
    <form [formGroup]="firstFormGroup" class="form-step">
      <div class="data-box">
        <div class="create-row">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{ loc.fields.description }}</mat-label>
            <input
              matInput
              name="description"
              formControlName="description"
              required
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>{{
              loc.fields.month
            }}</mat-label>
            <mat-select
              matInput
              name="monthId"
              formControlName="monthId"
              required
            >
            <mat-option [value]='{name: this.loc.months.january, value: 1 }'>{{this.loc.months.january}}</mat-option>
            <mat-option [value]='{name: this.loc.months.february, value: 2 }'>{{this.loc.months.february}}</mat-option>
            <mat-option [value]='{name: this.loc.months.march, value: 3 }'>{{this.loc.months.march}}</mat-option>
            <mat-option [value]='{name: this.loc.months.april, value: 4 }'>{{this.loc.months.april}}</mat-option>
            <mat-option [value]='{name: this.loc.months.may, value: 5 }'>{{this.loc.months.may}}</mat-option>
            <mat-option [value]='{name: this.loc.months.june, value: 6 }'>{{this.loc.months.june}}</mat-option>
            <mat-option [value]='{name: this.loc.months.july, value: 7 }'>{{this.loc.months.july}}</mat-option>
            <mat-option [value]='{name: this.loc.months.august, value: 8 }'>{{this.loc.months.august}}</mat-option>
            <mat-option [value]='{name: this.loc.months.september, value: 9 }'>{{this.loc.months.september}}</mat-option>
            <mat-option [value]='{name: this.loc.months.october, value: 10 }'>{{this.loc.months.october}}</mat-option>
            <mat-option [value]='{name: this.loc.months.november, value: 11 }'>{{this.loc.months.november}}</mat-option>
            <mat-option [value]='{name: this.loc.months.december, value: 12 }'>{{this.loc.months.december}}</mat-option>

          </mat-select>
          </mat-form-field>
        </div>

        <div class="create-row-align-start">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{ loc.fields.day }}</mat-label>
            <input
              matInput
              name="dayNumber"
              formControlName="dayNumber"
              type="number"
              max="31"
              min="1"
              required
            />
          </mat-form-field>

        </div>


      </div>
      <div class="dialog-nav-buttons">
        <button mat-fab class="button green-button" matStepperNext>
          <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step [editable]="true">
    <ng-template matStepLabel i18n>{{ loc.stepTitle.verify }}</ng-template>
    <div class="form-step">
      <div class="verify-large">
        <div class="flex-container-vertical flex-container-vertical-modal">
          <div>
            <label class="card-name" i18n>{{ loc.nonWorkingDays.singular }}</label>
          </div>
          <div class="details-package-large details-flex">
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.description }}</div>
              <div class="card-value">
                {{ firstFormGroup.get("description")?.value }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.month }}</div>
              <div class="card-value">
                {{ firstFormGroup.get("monthId")?.value.name }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.day }}</div>
              <div class="card-value">
                {{ firstFormGroup.get("dayNumber")?.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-nav-buttons">
        <button mat-fab class="button green-button" matStepperPrevious>
          <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-fab class="button green-button" (click)="create()">
          <mat-icon class="stepper-item-icon" fontSet="material-icons-outlined"
            >save</mat-icon
          >
        </button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>

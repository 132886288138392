<div class="general-operations-div">
    <div style="display: flex;">
      <button mat-icon-button class="back" (click)="goBack()"   >
        <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>
      <button mat-fab class="page-icon-button blue-button-icon">
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon">hub</mat-icon>
      </button>
      <ng-container *ngIf="!loading">
        <div class="title-container">
          <label class="title-mid-text">
            {{ name }}</label>
          <label class="id-text">
              {{ action.actionId }}</label>
        </div>
      </ng-container>
      <div *ngIf="loading" class="title-container">
        <ngx-skeleton-loader count="1" [theme]="{
            background: 'no-repeat rgb(227 228 230 / 33%)',
            height: '20px',
            width: '150px'
          }"></ngx-skeleton-loader>
      </div>
    
    </div>
  
    <!-- <div *ngIf="!loading" style="display: flex;">
      
    </div>
    <div *ngIf="loading">
      <ngx-skeleton-loader count="1" [theme]="{
          background: 'no-repeat rgb(227 228 230 / 33%)',
          height: '25px',
          width: '150px'
        }"></ngx-skeleton-loader>
    </div> -->
  </div>
  
  
  
  <mat-card class="details-card" >
    <div class="flex-container"> 

      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{loc.detailsHeaders.operation}}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ operationId }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>


    </div>
  </mat-card>
  
  <mat-tab-group class="tab-bar">
    <mat-tab [label]="loc.extensionPoints.title" i18n-label>
        <app-extension-points-list [action]="action" [tableStyle]="'tableDetails'" [actionEntity]="action?.actionEntity"></app-extension-points-list>
    </mat-tab>

    <mat-tab [label]="loc.tabNames.parameters" i18n-label>
      <app-list-fields tableStyle = "tableDetails" [action]="action" fieldsType="action"></app-list-fields>
    </mat-tab>
  </mat-tab-group>
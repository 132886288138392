import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DialogService } from './services/dialog/dialog.service';
import { LocalizationService } from './services/localization/localization.service';
import { SessionsService } from './services/sessions/sessions.service';
import { TokenService } from './services/token/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  

}


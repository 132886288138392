import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService } from '../services/localization/localization.service';
import { SessionsService } from '../services/sessions/sessions.service';
import { TokenService } from '../services/token/token.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent {


  
  showHead = false;
  title = 'paycorr-console';
  
  constructor(
    public sessionsService: SessionsService,
    public tokenService: TokenService,
    public router: Router,
    public loc: LocalizationService
) {
  const locale = localStorage.getItem('locale');
    if (locale && locale == 'es' || locale == 'en') {
      this.loc.currentLocale = locale;
    }
} 
 

  isLoginPage(){
    return window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] === "login";
  }

  clearState(){
    let state = JSON.parse(localStorage.getItem('state')!);
    state = {};
    localStorage.setItem('state', JSON.stringify(state));
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Step, VerifyGroup } from 'src/app/models/stepper/step-modal';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-horizontal-stepper',
  templateUrl: './horizontal-stepper.component.html',
  styleUrls: ['./horizontal-stepper.component.css']
})
export class HorizontalStepperComponent implements OnInit {

  @Input() steps: (Step & { idx?: number })[] = [];
  @Input() verify: VerifyGroup[] = [];
  @Output() eventSave = new EventEmitter<{ [key: string]: string }>();
  @Output() onFileSelect = new EventEmitter<any>();
  loading = true;
  verifiedItemsDictionary: { [key: string]: any } = {};

  formsGroup: UntypedFormGroup[] = [];

  show2Step = false;



  constructor(
    public loc: LocalizationService,
  ) { }

  ngOnInit(): void {
    this.steps = this.steps.map((step, idx) => ({ ...step, idx }));
    this.loading = false;
   // this.show2Step = this.steps.length+1 < 3;
   this.show2Step = true;

  }

  loadFormValues(formGroup: UntypedFormGroup) {
    for (let controlName in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(controlName)) {
        let value = formGroup.controls[controlName].value
        let controlValue = value.value ? value : value.toString();
        this.verifiedItemsDictionary[controlName] = controlValue;
      }
    }
  }

  loadStyleValues(image: { [x: string] : string}) {
    for (const i in image) {
      if (image.hasOwnProperty(i)) {
        this.verifiedItemsDictionary[i] = image[i];
      }
    }
  }

  save() {
    this.eventSave.emit(this.verifiedItemsDictionary);
  }

  fileSelected(event:any){
    this.onFileSelect.emit(event);
  }

}

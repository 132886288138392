import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Integration, IntegrationParameters } from 'src/app/models/integration/integration.model';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { TokenService } from '../token/token.service';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { IntegrationType } from 'src/app/models/integration/integrationType.model';
import { IntegrationTypeParameters } from 'src/app/models/integration/integrationTypeParameters.model';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  
  readonly rootURL = environment.apiUrl;
  constructor(
    private tokenService: TokenService,
    private http: HttpClient,
    private baseService: BaseService
  ) {}

  getIntegrationsList(onlyActive: boolean, integrationType?: string) {
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        }),
        params: {
          onlyActive: onlyActive + '',
          integrationType: integrationType ? integrationType : ''
        },
        withCredentials: false,
      };
      return this.http.get(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/X/list-integrations',
        HTTP_OPTIONS
      );
    } 

  editIntegration(integration: Integration) {
    console.log(integration)
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      description: integration.description,
      integrationType: integration.integrationType,
      status: integration.status,
      image: integration.image
    };
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        }),
        withCredentials: false,
      };
      return this.http.put(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/' +
          integration.integrationId +
          '/update-integration',
        baseRequest,
        HTTP_OPTIONS
      );
    } 

  editIntegrationParameter(parameter: IntegrationParameters, integrationId: string){
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      categoryId: parameter.categoryId,
      parameterId: parameter.key,
      parameterDesc: parameter.description,
      value: parameter.value
    };
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        }),
        withCredentials: false,
      };
      return this.http.put(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/' +
          integrationId +
          '/update-integration-parameter',
        baseRequest,
        HTTP_OPTIONS
      );
    }

    editIntegrationType(integrationType: IntegrationType){
      var baseRequest: BaseRequest = new BaseRequest();
      baseRequest.messageRQ = {
        description: integrationType.description,
      };
        const HTTP_OPTIONS = {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.tokenService.getToken(),
          }),
          withCredentials: false,
        };
        return this.http.put(
          this.rootURL + 
            'RSAdaptorFE/api/v1/paycorr/integrations/integrations/' +
            integrationType.id +
            '/update-integration-type',
            baseRequest,
            HTTP_OPTIONS
          );
    }

    editIntegrationTypeParameter(parameters: IntegrationTypeParameters, category: string, parameterId: string){
      var baseRequest: BaseRequest = new BaseRequest();
      baseRequest.messageRQ = {
        category_id: category,
        parameter_id: parameterId,
        default_value: parameters.defaultValue,
        parameter_description: parameters.parameterDescription,
        isMandatory: parameters.isMandatory
      };
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        }),
        withCredentials: false,
      };
      return this.http.put(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/X/update-integration-type-parameters',
        baseRequest,
        HTTP_OPTIONS
      );
    }

  deleteIntegration(integrationId: string) {
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
          'Content-Type': 'application/json',
        }),
        withCredentials: false,
      };
      return this.http.delete(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/' +
          integrationId +
          '/delete-integration',
        HTTP_OPTIONS
      );
  } 

  deleteIntegrationType(integrationTypeId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false,
    };
    return this.http.delete(
      this.rootURL + 
        'RSAdaptorFE/api/v1/paycorr/integrations/integrations/' +
        integrationTypeId +
        '/delete-integration-type',
      HTTP_OPTIONS
    );
  }

  deleteIntegrationParameter(integrationId: string, parameterId: string, categoryId: string) {
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        }),
        params: {
          categoryId: categoryId,
          parameterId: parameterId
        },
        withCredentials: false,
      };
      return this.http.delete(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/' +
          integrationId +
          '/delete-integration-parameter',
        HTTP_OPTIONS
      );
  }

  deleteIntegrationTypeParameter(category: string, parameterId: string){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };
    return this.http.delete(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/integrations/integration/TEST/delete-integration-type-parameter?categoryId=' + 
        category +
        '&parameterId='+
        parameterId,
      HTTP_OPTIONS
    );
  }

  createIntegration(description: string, id: string, type: string, image: string) {
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      integrationId: id,
      description,
      integrationType: type,
      image
    };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json',
      }),
    };

      return this.http.post(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/id/create-integration',
        baseRequest,
        HTTP_OPTIONS
      );
    } 

  createIntegrationType(id: string, description: string){
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id: id,
      description
    };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(
      this.rootURL + 
        'RSAdaptorFE/api/v1/paycorr/integrations/integrations/ID/create-integration-type',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  createIntegrationParameter(newParameter: IntegrationParameters, integrationId: string) {
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      categoryId: newParameter.categoryId,
      parameterId: newParameter.key,
      parameterValue: newParameter.value,
      parameterDesc: newParameter.description,
    };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json',
      }),
    };

      return this.http.post(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/'+
          integrationId
          +'/create-integration-parameter',
        baseRequest,
        HTTP_OPTIONS
      );
    } 

  createIntegrationTypeParameter(newParameter: IntegrationTypeParameters, integrationTypeId: string){
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      category_id: newParameter.categoryId,
      parameter_id: newParameter.parameterId,
      default_value: newParameter.defaultValue,
      parameter_description: newParameter.parameterDescription,        
      isMandatory: newParameter.isMandatory
    };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/integrations/integrations/'+integrationTypeId+'/create-integration-type-parameter',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  getIntegrationDetails(integrationId?: string) {
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        }),
      };
      return this.http.get(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/'+
          integrationId +'/integration-details',
        HTTP_OPTIONS
      );
  }

  getIntegrationTypeDetails(integrationTypeId?: string){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get(
      this.rootURL +
      'RSAdaptorFE/api/v1/paycorr/integrations/integration/'+
      integrationTypeId +
      '/get-integration-type',
      HTTP_OPTIONS
    );
  }

  getParametersByIntegrationType(id?: string){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/integrations/integration/'+
        id +
        '/get-parameters-by-integration-type',
      HTTP_OPTIONS
    );
  }

  getIntegrationTypes() {
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        }),
      };
      return this.http.get(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/X/get-integration-types',
        HTTP_OPTIONS
      );
  }

  getParametersCategories() {
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        }),
      };
      return this.http.get(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/integrations/integration/X/get-integration-categories',
        HTTP_OPTIONS
      );
    } 

 
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { OperationGroup } from 'src/app/models/operation-group/operation-group.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class OperationGroupService {
  readonly rootURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  get(): Observable<BaseResponse<OperationGroup[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get<BaseResponse<OperationGroup[]>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/services/group/x/list-operation-groups',
      HTTP_OPTIONS
    );
  }

  create(description: string): Observable<BaseResponse<OperationGroup>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { description: description };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.post<BaseResponse<OperationGroup>>(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/operation-groups/operation-group/x/create-group',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  edit(
    id: string,
    description: string
  ): Observable<BaseResponse<OperationGroup>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { description: description };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<OperationGroup>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/operation-groups/operation-group/${id}/update-group`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  delete(id: string): Observable<BaseResponse<OperationGroup>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.delete<BaseResponse<OperationGroup>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/operation-groups/operation-group/${id}/delete-group`,
      HTTP_OPTIONS
    );
  }
}

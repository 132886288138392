<div class="title-main-page-container">
    <div style="display: flex; margin-right: 15px; align-items: center">
      <button mat-fab class="main-icon-button blue-button-icon">
          <mat-icon fontSet="material-icons-outlined" class="material-icons">psychology</mat-icon>
      </button>
      <label class="page-title" i18n>{{loc.tableHeaders.metadata}}</label>
    </div>
  </div>
  
  <mat-tab-group 
    class="tab-bar"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChange()"
  >
  
  <mat-tab [label]="loc.tabNames.metadataTypes" i18n>
    <app-list-metadata-types></app-list-metadata-types>
  </mat-tab>
  
  <mat-tab [label]="loc.tabNames.metadataFields" i18n> 
    <app-list-metadata-fields></app-list-metadata-fields>
  </mat-tab>

  <mat-tab [label]="loc.tabNames.steps" i18n> 
    <app-list-steps></app-list-steps>
  </mat-tab>
  
  </mat-tab-group>
  
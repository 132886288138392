import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { Posting } from 'src/app/models/posting-schemes/Posting.model';
import { Disaggregation } from 'src/app/models/posting-schemes/Disaggregation.model';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-create-posting-step-template',
  templateUrl: './create-posting-step-template.component.html',
  styleUrls: ['./create-posting-step-template.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreatePostingStepTemplateComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  postings: Posting[] = [];
  disaggregations: Disaggregation[] = [];
  subjects: any[] = [];
  operations: any[] = [];
  suboperations: any[] = [];
  suboperationsFilter: any[] = [];
  settlementModes: any[] = [];

  stageId = '';

  isDeferred = 0;

  constructor(
    public dialogRef: MatDialogRef<CreatePostingStepTemplateComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private postingSchemesService: PostingSchemesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.loadSubjects()
    this.loadOperations()
    this.loadSuboperations()
    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      operation: ['', Validators.required],
      suboperation: ['', Validators.required],
      subject: ['', Validators.required],
    });

  }

  loadSubjects(){
    this.postingSchemesService.getSubjectSigns()
    .subscribe({
      next: (res: BaseResponse) => {
        let response = res.messageRS as any as any[];
        this.subjects = response;
        //this.loading = true;
        
      }, error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
      },
      
    );
  }

  loadOperations(){
    this.postingSchemesService.getOperations()
    .subscribe({
      next: (res: BaseResponse) => {
        let response = res.messageRS as any as any[];
        this.operations = response;
        //this.loading = true;
        
      }, error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
      },
      
    );
  }

  loadSuboperations(){
    this.postingSchemesService.getSuboperations()
    .subscribe({
      next: (res: BaseResponse) => {
        let response = res.messageRS as any as any[];
        this.suboperations = response;
        //this.loading = true;
        
      }, error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
      },
      
    );
  }

  changeSelectionOperation(event: MatSelectChange) {
    this.firstFormGroup.patchValue({
      suboperation: ''
    })
    this.suboperationsFilter = this.suboperations.filter((subop) => subop.operationId == event.value.operationId)
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  create() {
    this.loading = true;
    let name = this.firstFormGroup.get('name')!.value;
    let description = this.firstFormGroup.get('description')?.value;
    let operation = this.firstFormGroup.get('operation')?.value.operationId;
    let suboperation = this.firstFormGroup.get('suboperation')?.value.suboperationId;
    let subject = this.firstFormGroup.get('subject')?.value.subjectId;
    let sign = this.firstFormGroup.get('subject')?.value.postingSignId;
    let isDeferredSettlement = this.isDeferred;
    
    this.postingSchemesService.createPostingStepTemplate(name, description, operation, suboperation, subject, sign, isDeferredSettlement).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit(res.statusRS?.code as string);
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }

  checkDeferred(){
    if(this.isDeferred === 0){
      this.isDeferred = 1;
    } else {
      this.isDeferred = 0;
    }
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

import { Component, OnInit } from '@angular/core';
import { DialogService } from '../services/dialog/dialog.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../services/snackbar/snackbar.service';
import { LocalizationService } from '../services/localization/localization.service';
import { CountriesService } from '../services/countries/countries.service';
import { LogoutDialogService } from '../services/logout-confirmation/logout-dialog.service';
import { DeleteModalComponent } from '../utils/delete-modal/delete-modal.component';
import { DeleteModalData } from '../models/delete-modal/delete-modal-data.model';
import { BaseResponse } from '../models/responses.model';
import { Currency } from '../models/Currency/currency';
import { CreateCurrencyComponent } from './create-currency/create-currency.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter, onRowClick } from '../utils/table/table.model';
import { EditModalComponent } from '../utils/edit-modal/edit-modal.component';
import { EditModalData } from '../models/edit-modal/edit-modal-data.model';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css'],
  host: {
    class: 'component',
  },
})
export class CurrencyComponent implements OnInit {
  loading = true;
  oldSymbol = '';
  oldDescription = '';

  constructor(
    public dialogService: DialogService,
    public router: Router,
    public snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService,
    public countriesService: CountriesService
  ) {}
  ngOnInit(): void {
    this.loadCurrencies(); 
  }

  data :TableData[] = []

  loadCurrencies(): void {
    this.loading = true;
    this.countriesService.getCurrencies().subscribe({
      next: (res: BaseResponse<Currency[]>) => {
        this.data = res.messageRS as any as TableData[]
        this.loading = false;

      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-countries.png',
    message: this.loc.currency.empty,
    buttons: [
      {
        text: this.loc.currency.create,
        action: () => {
          this.create();
        }
      }
    ]
  }


  columns : Column[] = [
      {
        key: 'code',
        title: this.loc.tableHeaders.name
      },
      {
        key: 'isoCode',
        title: this.loc.tableHeaders.isoCode
      },
      {
        key: 'description',
        title: this.loc.tableHeaders.description
      },
      {
        key: 'symbol',
        title: this.loc.tableHeaders.symbol
      },
  ]

  transformers = {}

  paginatorInfo = {
    singular: this.loc.currency.singular,
    plural: this.loc.currency.plural
  }

  filters: TableFilter[] = [
    {
      key: 'code',
      label: this.loc.tableHeaders.name,
      type: 'text',
      placeholder: this.loc.tableHeaders.name,
    }
  ]

  actions : Action[] = [
    {
      icon: 'more_horiz',
      tooltip: this.loc.common.details,
      action: (row: Currency) => {
        this.details(row)
      }
    },
    {
      icon: 'edit',
      action: (row: Currency) => {
        this.edit(row);
      },
      tooltip: this.loc.currency.edit
    },
    {
      icon: 'delete',
      action: (row: Currency) => {
        this.delete(row);
      },
      tooltip : this.loc.currency.delete
    },
  ]

  createButton: CreateButton = {
    tooltip: this.loc.currency.create,
    action: () => {
      this.create();
    }
  }

  onRowClick:onRowClick = {
    action: (row: Currency) => {
      this.details(row)
    }
  }

  details(row: any){
    this.router.navigate(['currency-details'], {
      queryParams: {
        currencyCode: row.code,
      },
      queryParamsHandling: 'merge',
    });
  }

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateCurrencyComponent,
      {},
      'large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.currency, 'f')
        );
        this.loadCurrencies();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.currency)
        );
        this.loadCurrencies();
      }
    });
  }

  edit(row: Currency): void {
    let data: EditModalData = {
      title: this.loc.currency.edit,
      icon: 'paid',
      fields: [
        {label: this.loc.fields.symbol, 
          value: row.symbol, 
          errorMsg: this.loc.currency.editErrorSymbol, 
          validators: [Validators.required]
        }, 
        {
          label: this.loc.fields.description, 
          value: row.description, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required]
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyAction(row, result);
      }
    });
  }

  modifyAction(row: any, result: any){
    this.oldSymbol = row.symbol;
    this.oldDescription = row.description;
    let newSymbol = result.find((x: any) => x.label === this.loc.tableHeaders.symbol).value
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value

    if(newDescription == this.oldDescription && newSymbol == this.oldSymbol){
      this.loading = false;
      this.snackbarService.openSuccessSnackbarWithNoReload(
        this.loc.successMessage('update', this.loc.currency, 'f')
      );
      return
    }

    if(newDescription !== this.oldDescription){
      this.countriesService.editCurrencyDescription(row.code, newDescription).subscribe(
        { 
         next: (res: any) => {
          this.loading = false;
          if(res.statusRS?.code === "0"){
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('update', this.loc.currency, 'f')
            );
            this.loadCurrencies();
          } else {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.currency)
          );
          }
         },error: (err) => {
          this.loading = false;
           this.logoutDialogService.openLogoutDialog(err);
           if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.currency)
            );
           }
         }
       }
       )
    }else{
      this.loading = false;
    }

    if(newSymbol !== this.oldSymbol){
      this.countriesService.editCurrencySymbol(row.code, newSymbol).subscribe(
        { 
         next: (res: any) => {
           this.loading = false;
            if(res.statusRS?.code === "0"){
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('update', this.loc.currency, 'f')
            );
            this.loadCurrencies();
            } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.currency)
            );
            }
         },error: (err) => {
           this.loading = false;
           this.logoutDialogService.openLogoutDialog(err);
           if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.currency)
            );
           }
         }
       }
       )
    }else{
      this.loading = false;
    }
  }

  delete(row: Currency): void {
    let data: DeleteModalData = {
      title: this.loc.currency.deleteCurrency,
      icon: 'paid',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.currency),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.countriesService.deleteCurrency(row.code).subscribe({
          next: (res: BaseResponse<void>) => {
            switch (res.statusRS?.code) {
              case '0':
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.currency, 'f')
                );
                this.loadCurrencies();
                break;
              case '-91866':
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.currencyInUse
                );
                break;
              default:
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.currency)
                );
                this.loadCurrencies();
                break;
            }
          },
          error: (_err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.currency)
            );
          },
        });
      }
    });
  }
}

import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { MetadataField } from 'src/app/models/metadata/metadata-field/metadataField.model';
import { MetadataType } from 'src/app/models/metadata/metadata-type/metadataType.model';
import { CreateModalData, Option, Step, VerifyGroup, setOptions, transformListToOptions } from 'src/app/models/stepper/step-modal';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-metadata-field',
  templateUrl: './create-metadata-field.component.html',
  styleUrls: ['./create-metadata-field.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateMetadataFieldComponent implements OnInit {
  loading = false;
  metadataTypes: any[] = [];
  boolOptions: any[] = [
    {
        value: '1',
        description: this.loc.common.yes
    },
    {
        value: '0',
        description: this.loc.common.no
    }
];

controlTypes: any[] = [
  {
    value: 'TEXT',
    description: 'TEXT'
  },
  {
    value: 'LIST',
    description: 'LIST'
  },
  {
    value: 'COMBOBOX',
    description: 'COMBOBOX'
  },
  {
    value: 'CHECKBOX',
    description: 'CHECKBOX'
  },
  {
    value: 'SLIDER',
    description: 'SLIDER'
  },
  {
    value: 'OTP',
    description: 'OTP'
  },
]

dataSources: any[] = [
  {
    value: 'STATIC',
    description: this.loc.common.static
  },
  {
    value: 'DYNAMIC',
    description: this.loc.common.dynamic
  }
]



  steps: Step[] = [
    {
      title: this.loc.stepTitle.basicData,
      icon: 'dynamic_feed',
      type: 'form',
      form: [
        {
          key: 'id',
          type: 'text',
          label: this.loc.fields.identification,
          validators: [Validators.required],
        },
        {
          key: 'description',
          type: 'text',
          label: this.loc.fields.description,
          validators: [Validators.required],
        },
        {
          key: 'type',
          type: 'select',
          label: this.loc.fields.type,
          validators: [Validators.required],
          options: [],
        },
        {
          key: 'controlType',
          type: 'select',
          label: this.loc.fields.controlType,
          validators: [Validators.required],
          options: [],
        },
        {
          key: 'defaultValue',
          type: 'text',
          label: this.loc.fields.defaultValue,
        },
        {
          key: 'isMandatory',
          type: 'select',
          label: this.loc.fields.isMandatory,
          validators: [Validators.required],
          options: [],
        },
        {
          key: 'isHidden',
          type: 'select',
          label: this.loc.fields.isHidden,
          validators: [Validators.required],
          options: [],
        },
        {
          key: 'isEditable',
          type: 'select',
          label: this.loc.fields.isEditable,
          validators: [Validators.required],
          options: [],
        },
        {
          key: 'datasource',
          type: 'select',
          label: this.loc.fields.datasource,
          validators: [Validators.required],
          options: []
        },
        {
          key: 'validation',
          type: 'text',
          label: this.loc.fields.validation,
        },
        {
          key: 'datasourceData',
          type: 'textarea',
          label: this.loc.fields.datasourceData,
          validators: [Validators.required],
          height: '16px',
        },
        {
          key: 'checkout',
          type: 'select',
          label: this.loc.fields.checkout,
          options: [],
        },
      ],
    },
  ];

  verify: VerifyGroup[] = [
    {
      title: this.loc.stepTitle.basicData,
      type: 'form',
      elements: ['id', 'description', 'type', 'controlType', 'defaultValue', 'isMandatory','isHidden', 'isEditable', 'datasource', 
        'datasourceData', 'validation', 'checkout'],
      descriptions: [this.loc.fields.identification, this.loc.fields.description, this.loc.fields.type, this.loc.fields.controlType,
        this.loc.fields.defaultValue, this.loc.fields.isMandatory, this.loc.fields.isHidden, this.loc.fields.isEditable, this.loc.fields.datasource,
        this.loc.fields.datasourceData, this.loc.fields.validation, this.loc.fields.checkout]
    },
  ];

  modalData: CreateModalData = {
    title: this.loc.metadataField.create,
    icon: 'psychology',
    steps: this.steps,
    verify: this.verify,
    size: 'large',
  }

  constructor(
    public loc: LocalizationService,
    public dialogRef: DialogRef,
    private snackbarService: SnackbarService,
    private metadataService: MetadataService,
  ) { }

  ngOnInit(): void {
    this.setCombos();
  }

  setCombos(): void {
    this.loading = true;
    this.metadataService.listMetadatTypes().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.metadataTypes = res.messageRS as unknown as MetadataType[];
          this.metadataTypes = transformListToOptions(this.metadataTypes, 'id', 'id');
          setOptions(0, "type", this.metadataTypes, this.modalData);

          this.boolOptions = transformListToOptions(this.boolOptions, 'value', 'description');
          setOptions(0, "isMandatory", this.boolOptions, this.modalData);
          setOptions(0, "isHidden", this.boolOptions, this.modalData);
          setOptions(0, "isEditable", this.boolOptions, this.modalData);
          setOptions(0, "checkout", this.boolOptions, this.modalData);

          this.controlTypes = transformListToOptions(this.controlTypes, 'value', 'description');
          setOptions(0, "controlType", this.controlTypes, this.modalData);

          this.dataSources = transformListToOptions(this.dataSources, 'value', 'description');
          setOptions(0, "datasource", this.dataSources, this.modalData);

          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  

  save(verifiedItemsDictionary: { [key: string]: any }) {
    this.setupVerify(verifiedItemsDictionary);
  }

  setupVerify(verifiedItemsDictionary: { [key: string]: any }) {
    this.loading = true;
    let newMetadataField: any =  {
      id: verifiedItemsDictionary['id'],
      description: verifiedItemsDictionary['description'],
      type: verifiedItemsDictionary['type'].value,
      controlType: verifiedItemsDictionary['controlType'].value,
      defaultValue: verifiedItemsDictionary['defaultValue'],
      isMandatory: verifiedItemsDictionary['isMandatory'].value,
      isEditable: verifiedItemsDictionary['isEditable'].value,
      isHidden: verifiedItemsDictionary['isHidden'].value,
      datasource: verifiedItemsDictionary['datasource'].value,
      datasourceData: verifiedItemsDictionary['datasourceData'] ? JSON.parse(verifiedItemsDictionary['datasourceData']) : verifiedItemsDictionary['datasourceData'],
      validation: verifiedItemsDictionary['validation'],
      checkout: verifiedItemsDictionary['checkout'].value,
    }

    this.metadataService.createMetadataField(newMetadataField).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('create', this.loc.metadataType, 'f')
            );
            this.exit('created');
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('create', this.loc.metadataType)
            );
            this.exit('error');
          }
          this.loading = false;
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (err.statusCode !== 401) {
          this.loading = false;
          console.log(err)
          this.exit('error');
        }
      }
    )
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

}

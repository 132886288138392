import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appStepper]'
})
export class StepperDirective implements OnChanges, AfterViewInit {

  @Input('step') currentStep:number = 0;
  private steps:HTMLCollection;
  private stepsLabels:HTMLCollection;
  constructor(el:ElementRef) { 
    this.steps = el.nativeElement!.getElementsByClassName("mat-step-icon")
    this.stepsLabels = el.nativeElement!.getElementsByClassName("mat-step-text-label");    
  }

  ngAfterViewInit(){
    this.updateClasses(0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateClasses(changes['currentStep'].currentValue);
  }


  updateClasses(currentStep:number){
    for(let i = 0 ; i < this.steps.length ; i++){
      this.steps[i].removeAttribute('style');
      this.stepsLabels[i].removeAttribute('style');
      if(i < currentStep){
        this.steps[i].setAttribute('style',"background-color : var(--green-color) !important;");
        this.stepsLabels[i].setAttribute('style',"color : var(--green-color) !important;");
      }
      if(i == currentStep){
        this.steps[i].setAttribute('style',"background-color : var(--green-color-dark) !important;");
        this.stepsLabels[i].setAttribute('style',"color : var(--green-color-dark) !important;");
      }
    }
  }


}

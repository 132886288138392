import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-create-platform',
  templateUrl: './create-platform.component.html',
  styleUrls: ['./create-platform.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class CreatePlatformComponent implements OnInit {

  loading = false;
  firstFormGroup!: UntypedFormGroup;
  
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private notificationsService: NotificationsService,
    private logoutDialogService: LogoutDialogService,
    public dialogRef: MatDialogRef<CreatePlatformComponent>,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      id: ['', [Validators.required, Validators.pattern('^[A-ZÀ-Ÿ_]+$')]],
      description: ['', Validators.required]
    });
  }

  create(){
    this.loading = true;
    this.notificationsService.createPlatform(this.firstFormGroup.get('id')?.value, this.firstFormGroup.get('description')?.value).subscribe(
        (res) => {
          setTimeout(() => {
            if(res.statusRS?.code === "0"){
              this.exit("created");
            } else {
              this.exit("error");
            }
          }, 200);
        }, (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          this.logoutDialogService.openLogoutDialog(err);
          if (err.statusCode !== 401) {
            this.exit('error');
          }
        }
      )
  }

  exit(message: string) {
    this.dialogRef.close(message);
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

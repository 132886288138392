<div class="general-operations-div">
    <div style="display: flex;" [ngStyle]="{'align-items': (subtitle ? 'unset': 'center')}">
      <button mat-icon-button class="back" *ngIf="withBackButton" (click)="goBack()"   >
        <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>
      <img src="{{ image }}" class="image-zoom" *ngIf="!showIcon"
            (error)="showIcon = true" />
      <button mat-fab class="page-icon-button blue-button-icon" *ngIf="showIcon">
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon">{{icon}}</mat-icon>
      </button>
      <ng-container *ngIf="!loading">

        <ng-container *ngIf="subtitle">
            <div class="title-container">
              <label class="title-mid-text">
                {{ title }}</label>
              <label class="id-text">
                  {{ subtitle }}</label>
            </div>
        </ng-container>

        <ng-container *ngIf="!subtitle">
            <label class="page-title"  i18n>{{title}}</label>
        </ng-container>

      </ng-container>
      <div *ngIf="loading" class="title-container">
        <ngx-skeleton-loader count="1" [theme]="{
            background: 'no-repeat rgb(227 228 230 / 33%)',
            height: '20px',
            width: '150px'
          }"></ngx-skeleton-loader>
      </div>
    
    </div>
    
  </div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { Location } from '@angular/common';
import { BaseResponse } from 'src/app/models/responses.model';
import { Action } from 'src/app/models/extension-points/Action.model';

@Component({
  selector: 'app-action-details',
  templateUrl: './action-details.component.html',
  styleUrls: ['./action-details.component.css'],
  host: {
    class: 'component'
  }
})
export class ActionDetailsComponent implements OnInit {

  loading = true;
  name = '';
  serviceId = '';
  operationId = '';
  touchPointId = '';
  actionId = '';

  action: any;

  constructor(
    public route: ActivatedRoute,
    public location: Location,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    private extensionPointsService: ExtensionPointsService,
    public loc : LocalizationService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.operationId = params['operationId'];
      this.touchPointId = params['touchPointId'];
      this.actionId = params['actionId'];
    });
    this.getAction();
  }



  getAction(): void {
    this.extensionPointsService
      .getActions(this.operationId)
      .subscribe(
        {
          next: (res: BaseResponse<Action>) => {
            
            let actions: any[] =
              res.messageRS as unknown as any;
            this.action = actions.find((x) => x.touchPointId === this.touchPointId && x.actionId === this.actionId);
            this.name = this.action?.actionDescription;
  
            this.loading = false;
          },
          error: (err) => {
            this.logoutDialogService.openLogoutDialog(err);
          }

        }

      );
  }

  goBack(): void {
    this.location.back();
    let state = JSON.parse(localStorage.getItem('state')!);
    state.mediums = {};
    localStorage.setItem('state', JSON.stringify(state));
  }

}

<div class="all">
  <div class="dark" *ngIf="loading">
      <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
      </mat-spinner>
  </div>

  <div class="confirm-title">
      <div class="confirm-title-inner-div">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon-user">{{modalData.icon}}</mat-icon>
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon-edit">add</mat-icon>
        </button>
        <label class="dialog-title" i18n>{{modalData.title}}</label>
      </div>
      <button class="exit-button" (click)="exit('not created')">
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
      </button>
  </div>

  <app-horizontal-stepper [steps]="steps" [verify]="verify" (eventSave)="save($event)" (onFileSelect)="fileSelect($event)" *ngIf="!loading"></app-horizontal-stepper>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-disaggregation-details',
  templateUrl: './disaggregation-details.component.html',
  styleUrls: ['./disaggregation-details.component.css'],
  host: {
    class: 'component'
  }
})
export class DisaggregationDetailsComponent implements OnInit {

  loading = false;
  id = ''
  description = ''

  constructor(
    private route: ActivatedRoute, 
    public location: Location,
    public loc: LocalizationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      this.description = params['description'];
    });
  }

  goBack() {
    this.location.back();
  }

}

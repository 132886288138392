import { Component, Input, OnInit } from '@angular/core';
import { CurrencyDenomination } from 'src/app/models/denominations/currency-denomination.model';
import { Denomination } from 'src/app/models/denominations/denomination.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DenominationsService } from 'src/app/services/denominations/denominations.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateDenominationComponent } from '../create-denomination/create-denomination.component';
import {
  EmptyState,
  TableData,
  Column,
  PaginatorInfo,
  TableTransformer,
  TableFilter,
  CreateButton,
  Action,
} from 'src/app/utils/table/table.model';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-denominations-list',
  templateUrl: './denominations-list.component.html',
  styleUrls: ['./denominations-list.component.css'],
  host: { class: 'component' },
})
export class DenominationsListComponent implements OnInit {
  @Input() currencyCode = '';
  loading: boolean = true;
  constructor(
    public loc: LocalizationService,
    private denominationsService: DenominationsService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private htmlGen: SafeHTMLGeneratorService
  ) {}

  ngOnInit(): void {
    this.loadDenominations();
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-denominations.png',
    message: this.loc.denominations.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      },
    ],
  };

  data: TableData[] = [];
  columns: Column[] = [
    {
      key: 'name',
      title: this.loc.tableHeaders.name,
      sortable: true,
    },
    {
      key: 'id',
      title: this.loc.tableHeaders.id,
      sortable: true,
    },
    {
      key: 'type',
      title: this.loc.tableHeaders.type,
      sortable: true,
    },
    {
      key: 'valueDescription',
      title: this.loc.tableHeaders.value,
      sortable: true,
    },
    {
      key: 'status',
      title: this.loc.tableHeaders.status,
      centered: true,
    },
  ];
  paginatorInfo: PaginatorInfo = this.loc.denominations;
  transformers: TableTransformer = {
    status: (value: string) => {
      return this.htmlGen.statusIcon(value == '1');
    },
  };
  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.name,
      key: 'denominationName',
      type: 'text',
      placeholder: this.loc.tableHeaders.name,
    },
  ];
  actions: Action[] = [
    {
      tooltip: this.loc.denominations.edit,
      icon: 'edit',
      action: (row) => this.edit(row),
    },
    {
      tooltip: this.loc.denominations.disable,
      icon: 'toggle_on',
      action: (row) => this.toggleState(row),
      condition: (row) => row.status == '1',
    },
    {
      tooltip: this.loc.denominations.enable,
      icon: 'toggle_off',
      action: (row) => this.toggleState(row),
      condition: (row) => row.status != '1',
    },
  ];
  createButton: CreateButton = {
    tooltip: this.loc.denominations.create,
    action: () => this.create(),
  };

  loadDenominations() {
    this.denominationsService
      .getCurrencyDenominations(this.currencyCode)
      .subscribe({
        next: (data: BaseResponse<CurrencyDenomination[]>) => {
          this.data = data.messageRS as any as TableData[];
          this.loading = false;
        },
        error: (_err: any) => {},
      });
  }

  edit(row: CurrencyDenomination) {
    let data: EditModalData = {
      title: this.loc.denominations.edit,
      icon: 'all_inbox',
      fields: [
        {
          label: this.loc.fields.name,
          value: row.name,
          errorMsg: this.loc.denominations.errorEditName,
          validators: [Validators.required],
        },
      ],
    };
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAction(row, result);
      }
    });
  }

  editAction(row: any, result: any) {
    let newDenominationName = result.find(
      (x: any) => x.label === this.loc.fields.name
    ).value;

    const denomination: Denomination = {
      denominationId: row.id,
      denominationName: newDenominationName,
      denominationType: row.type,
    };

    this.denominationsService.editName(row.currencyId, denomination).subscribe({
      next: (_response: BaseResponse<Denomination>) => {
        this.loading = false;
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.denominations)
        );
        this.loadDenominations();
      },
      error: (_error: any) => {
        this.loading = false;
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.denominations)
        );
      },
    });
  }

  toggleState(row: CurrencyDenomination) {
    const denomination: Denomination = {
      denominationId: row.id,
      denominationType: row.type,
      denominationName: row.name,
    };

    this.denominationsService
      .toggleState(this.currencyCode, denomination)
      .subscribe({
        next: (response: BaseResponse<Denomination>) => {
          if (response.statusRS.code === '0') {
            this.loadDenominations();
          }
        },
        error: (_error: any) => {},
      });
  }

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateDenominationComponent,
      { currencyCode: this.currencyCode },
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.denominations)
        );
        this.loadDenominations();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.denominations)
        );
        this.loadDenominations();
      } else if (result == 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('duplicate', this.loc.denominations)
        );
      }
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Country } from 'src/app/models/country/country.model';
import { Currency } from 'src/app/models/Currency/currency';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CountriesService } from '../../services/countries/countries.service';
import { CountryCurrency } from 'src/app/models/countryCurrency/countryCurrency';
import { BaseResponse } from 'src/app/models/responses.model';

@Component({
  selector: 'app-create-currency',
  templateUrl: './create-currency.component.html',
  styleUrls: ['./create-currency.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateCurrencyComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  currencies: any[] =[];


  constructor(
    public dialogRef: MatDialogRef<CreateCurrencyComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private countriesService: CountriesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      code:['', [Validators.required, Validators.maxLength(3)]],
      isoCode:['', Validators.required],
      description: ['', Validators.required],
      symbol: ['', Validators.required],
    });
  }

  loadCountryCurrencies() {
    this.loading = true;
    this.countriesService.listCountryCurrencies(this.data.countryId).subscribe({
      next: (res: BaseResponse<CountryCurrency[]>) => {
        this.currencies = res.messageRS;
        this.loading = false;
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    });
  }


  create (){
    this.loading = true;
    let newCurrency: Currency = {
      code: this.firstFormGroup.get('code')!.value,
      isoCode: this.firstFormGroup.get('isoCode')!.value,
      description: this.firstFormGroup.get('description')!.value,
      symbol: this.firstFormGroup.get('symbol')!.value,
    }

    this.countriesService.createCurrency(newCurrency).subscribe(
      (res: BaseResponse<void>) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }
  exit(created: string){
    this.dialogRef.close(created);
  }
}

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Integration } from 'src/app/models/integration/integration.model';
import { IntegrationType } from 'src/app/models/integration/integrationType.model';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { ImageUploaderService } from 'src/app/services/image-uploader/image-uploader.service';


@Component({
  selector: 'app-create-integration',
  templateUrl: './create-integration.component.html',
  styleUrls: ['./create-integration.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class CreateIntegrationComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  integration: Integration = {
    parameters: [],
  }
  description = '';
  id = '';
  type = '';
  integrations: Integration[] = [];
  integrationsTypes: IntegrationType[] = [];
  imageProfile: string = '';
  selectedFile: File = new File([], '');



  constructor(
    private formBuilder: UntypedFormBuilder,
    private integrationService: IntegrationsService,
    public dialogRef: MatDialogRef<CreateIntegrationComponent>,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService,
    private imageUploader: ImageUploaderService,
  ) {}

  ngOnInit(): void { 
    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      id: ['', [Validators.required, Validators.pattern('^[A-ZÀ-Ÿ]+$')]],
      type: ['', Validators.required],
    });
    this.listIntegrationsTypes();
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

  next(): void  {
    this.description = this.firstFormGroup.get('name')!!.value;
    this.id = this.firstFormGroup.get('id')!!.value;
    this.type = this.firstFormGroup.get('type')!!.value.id;
  }

  createIntegration(): void {
    this.loading = true;
    this.integrationService
      .createIntegration(this.description, this.id, this.type, this.imageProfile)
      .subscribe(
        (res: BaseResponse) => {
          setTimeout(() => {
            this.loading = false;
            if (res.statusRS?.code === '0') {
              this.exit('created');
            }
            else {
              this.exit('invalid');
            }
          }, 200);
        },
        (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if (err.status !== 401) {
            this.exit('error');
          }
        }
      );
  }

  listIntegrationsTypes(): void {
    this.integrationService.getIntegrationTypes().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.integrationsTypes = res.messageRS as unknown as Array<any>;
        } else {
          //TODO HANDLE ERROR
          
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  onFileSelect($event: any): void {
    this.selectedFile = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = (_event) => {
      this.imageProfile = reader.result?.toString() || '';
    };
  }

  setupVerify(): void{
    this.integration.integrationId = this.firstFormGroup.get('id')?.value;
    this.integration.description = this.firstFormGroup.get('name')?.value;
    this.integration.status = this.firstFormGroup.get('type')?.value;
  }

  saveImage(){;
    this.loading = true;
    console.log(this.selectedFile.name)
    if(this.selectedFile.name !== ''){
      this.imageUploader.uploadImageToCloudinary(this.selectedFile, this.id)?.subscribe((response: any) => {
        if(response && this.id) {
          this.imageProfile = response.secure_url;
          this.createIntegration();
        }
      });
    } else {
      this.createIntegration();
    }
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

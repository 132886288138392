import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { IntegrationParameters } from 'src/app/models/integration/integration.model';
import { IntegrationType } from 'src/app/models/integration/integrationType.model';
import { IntegrationTypeParameters } from 'src/app/models/integration/integrationTypeParameters.model';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-integration-type-details',
  templateUrl: './integration-type-details.component.html',
  styleUrls: ['./integration-type-details.component.css'],
  host: {
    class: 'component',
  },
})
export class IntegrationTypeDetailsComponent implements OnInit {
  loading = true;

  integrationType: IntegrationType = {}

  integrationTypeParameters: IntegrationParameters[] = [];  

  constructor(
    public loc: LocalizationService,
    private router: Router,
    private route: ActivatedRoute,
    private integrationsService: IntegrationsService,

  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.integrationType.id = params.get('id')!;
    });
    this.loadParameters();
    this.loadIntegrationType();
  }

  loadIntegrationType(){
    this.loading = true;
    this.integrationsService.getIntegrationTypeDetails(this.integrationType.id).subscribe((res: BaseResponse) => {
      if(res.statusRS?.code === '0'){
        let inte: any[] = [];
        inte = res.messageRS as unknown as any;
        inte = inte[0];
        this.integrationType = inte[0];
        this.loading = false;
      }
    },
    (err: any) => {
      console.log(err);
    })
  }

 
  loadParameters(){
    this.loading = true;
    this.integrationsService.getParametersByIntegrationType(this.integrationType.id).subscribe((res: BaseResponse) => {
      if(res.statusRS?.code === '0'){
        let parameters: any[] = [];
        parameters = res.messageRS as unknown as any;
        for(let i = 0 ; i<parameters.at(0).length ; i++){
          this.integrationTypeParameters.push(parameters.at(0).at(i));
        }
        this.loading = false;
      }
    },
    (err: any) => {
      console.log(err);
    }
    );
  }

  goBack(){
    this.router.navigate(['/integrations']);
  }

}

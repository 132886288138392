<div class="dark" *ngIf="loading">
    <mat-spinner diameter="70" style="
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -35px;
        margin-top: -35px;
      "></mat-spinner>
</div>
<div class="title">
    <div class="inner-title-container">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon class="action-button-icon" 
   fontSet="material-icons-outlined" >extension</mat-icon>
        <mat-icon class="action-button-icon-add">add</mat-icon>
      </button>
      <div class="dialog-title"><label i18n>{{loc.parameters.create}}</label></div>
    </div>
    <button class="exit-button" (click)="exit('not created')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>

<mat-horizontal-stepper [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom" [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex" class="horizontal-stepper very-short-stepper">
    <ng-template matStepperIcon="done" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">check_box</mat-icon>
    </ng-template>
  
    <ng-template matStepperIcon="edit" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">check_box</mat-icon>
    </ng-template>
  
    <ng-template matStepperIcon="number" let-index="index">
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">check_box</mat-icon>
    </ng-template>

    <mat-step [stepControl]="firstFormGroup" [editable]="true">
        <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
        <form [formGroup]="firstFormGroup" class="form-step">
            <div class="data-box">
              <div class="create-row">
                <mat-form-field appearance="fill">
                  <mat-label i18n>{{loc.tableHeaders.category}}</mat-label>
                  <mat-select formControlName="categoryId">
                    <mat-option *ngFor="let category of categoriesArray" [value]="category">
                      {{ category.description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label i18n>{{loc.tableHeaders.parameterId}}</mat-label>
                  <input matInput name="parameterId" formControlName="parameterId" required />
                </mat-form-field>
              </div>
              <div class="create-row">
                <mat-form-field appearance="fill">
                  <mat-label i18n>{{loc.tableHeaders.defaultValue}}</mat-label>
                  <input matInput name="defaultValue" formControlName="defaultValue" required />
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.tableHeaders.description}}</mat-label>
                    <input matInput name="description" formControlName="description" required />
                  </mat-form-field>
              </div>
              <div class="create-row-align-start">
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.tableHeaders.isMandatory}}</mat-label>
                    <mat-select formControlName="_isMandatory">
                      <mat-option *ngFor="let option of _isMandatory" [value]="option">
                        {{ option }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
              <div class="dialog-nav-buttons" >
                <button mat-fab class="button green-button" (click)="next(stepper)" matStepperNext [disabled]="!isFormValid()" >
                  <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </div>
          </form>
          
    </mat-step>
    <mat-step [editable]="true">
        <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
        <div class="form-step">
        <div class="verify-large">
          <div class="flex-container-vertical flex-container-vertical-modal">
            <div>
              <div class="details-package-large">
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{loc.tableHeaders.category}}</div>
                  <div class="card-value">{{ newParameter.categoryId }}</div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{loc.tableHeaders.parameterId}}</div>
                  <div class="card-value">{{ newParameter.parameterId }}</div>
                </div>
                <div class="verify-vertical">
                    <div class="card-header" i18n>{{loc.tableHeaders.defaultValue}}</div>
                    <div class="card-value">{{ newParameter.defaultValue }}</div>
                  </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{loc.tableHeaders.description}}</div>
                  <div class="card-value">{{ newParameter.parameterDescription }}</div>
                </div>
                <div class="verify-vertical">
                    <div class="card-header" i18n>{{loc.tableHeaders.isMandatory}}</div>
                    <div class="card-value">{{ isMandat }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-nav-buttons" >
          <button mat-fab class="button green-button" matStepperPrevious   >
            <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
          </button>
          <button mat-fab class="button green-button" (click)="createTypeParameter()"  >
            <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon">save</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>
</mat-horizontal-stepper>
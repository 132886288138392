import { Component, OnInit } from '@angular/core';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter, TableStyle, TableTransformer } from 'src/app/utils/table/table.model';
import { CreateUserComponent } from '../create-user/create-user.component';
import { User } from '../../models/user.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { UsersService } from 'src/app/services/users/users.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
@Component({
  selector: 'app-list-managers',
  templateUrl: './list-managers.component.html',
  styleUrls: ['./list-managers.component.css'],
  host: {
    class: 'component',
  },
})
export class ListManagersComponent implements OnInit {
  loading = true;
  data: TableData[] = [];

  constructor(
    public userService: UsersService,
    public dialogService: DialogService,
    public router: Router,
    public snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService,
    private htmlGen: SafeHTMLGeneratorService
  ) { }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    this.userService.getManagers().subscribe(
      (res: BaseResponse) => {
        this.data = res.messageRS as unknown as TableData[];
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  emptyState: EmptyState = {
    image: 'assets/img/usuarios-manager.png',
    message: this.loc.users.emptyState,
    buttons: [
      {
        text: this.loc.users.create,
        action: () => this.create(),
      }
    ]
  }


  style: TableStyle = {
    row: {
      "min-height": '50px',
    },
  }

  columns: Column[] = [
    
    {
      title: this.loc.tableHeaders.id,
      key: 'userId',
      sortable: true
    },
    {
      title: this.loc.tableHeaders.name,
      key: 'username',
      sortable: true
    },
    {
      title: this.loc.tableHeaders.email,
      key: 'email',
      sortable: true
    },
    {
      title: this.loc.tableHeaders.nationality,
      key: 'nationality',
      sortable: true
    },
    {
      title: this.loc.tableHeaders.type,
      key: 'userType',
      sortable: true
    },
  ]

  transformer: TableTransformer = {
    imageProfile: (value) => {
      return this.htmlGen.imageOrDefaultIcon(value, 'person', 'profile image')
    }
  }

  paginatorInfo = this.loc.users

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.name,
      key: 'username',
      type: 'text',
      placeholder: this.loc.tableHeaders.name,
    },
    {
      label: this.loc.tableHeaders.type,
      key: 'userType',
      type: 'text',
      placeholder: this.loc.tableHeaders.type,
    }
  ]

  actions: Action[] = [
    {
      icon: 'delete',
      action: (row: User) => this.delete(row),
      tooltip: this.loc.users.delete
    }
  ]

  createButton: CreateButton = {
    tooltip: this.loc.users.createUser,
    action: () => {
      this.create();
    }
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateUserComponent,
      {},
      'large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.users)
        );
        this.loadUsers();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.users)
        );
      }
    });
  }

  delete(row: User): void {
    let data: DeleteModalData = {
      title: this.loc.users.delete,
      icon: 'person',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.users),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
   
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'deleted') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage(
            'delete',
            this.loc.users
          )
        );
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('delete', this.loc.users)
        );
      }
      this.loadUsers();
    });
  }
}

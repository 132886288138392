<!-- elegir label, action result, description-->
<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
</div>

<div class="title">
    <div class="inner-title-container">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">hub</mat-icon>
            <mat-icon class="action-button-icon-add">add</mat-icon>
        </button>
        <label class="dialog-title" i18n>{{loc.extensionPointsFields.chooseParams}}</label>
    </div>
    <button class="exit-button" (click)="exit('not created')"  >
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>
    <mat-horizontal-stepper class="horizontal-stepper parameters-stepper" [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
        [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex">
        <ng-template matStepperIcon="done" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="edit" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="number" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
        </ng-template>
        <mat-step  [editable]="true">
            <ng-template matStepLabel i18n>{{loc.extensionPointsFields.chooseParams.toUpperCase()}}</ng-template>
            <form  class="form-step">
                <div class="data-box">
                    <table mat-table [dataSource]="parametersDataSource" class="add-parameters-table" *ngIf="!loading">
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef></th>
                          <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? something(row) : null"
                              [checked]="selectionParameters.isSelected(row)"></mat-checkbox>
                          </td>
                        </ng-container>
                
                        <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef i18n>{{loc.extensionPointsFields.title}}</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.description }}
                          </td>
                        </ng-container>
                
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"
                          (click)="something(row)"></tr>
                      </table>
                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperNext>
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>
        <mat-step [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
            <div class="form-step">
                <div class="verify-large">
                    <div class="flex-container-vertical flex-container-vertical-modal">
                        <div>
                            <label class="card-name" i18n>{{loc.extensionPointsFields.chosenParams}}</label>
                        </div>
                        
                        <div class="details-package-large">
                            <div class="verify-vertical">
                                <ng-container *ngIf="selectionParameters.selected.length == 0">
                                    <div class="verify-list">
                                      {{ loc.none() }}
                                    </div>

                                </ng-container>
                                <ng-container *ngIf="selectionParameters.selected.length > 0">
                                    <div class="verify-list" *ngFor="let param of selectionParameters.selected">
                                      {{ param.description }}
                                    </div>

                                </ng-container>
                            </div>
                          </div>
                        
                    </div>

                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious  >
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-fab class="button green-button" (click)="create()"  >
                        <mat-icon class="stepper-item-icon" fontSet="material-icons-outlined">save</mat-icon>
                    </button>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>


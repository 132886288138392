<div class="dark" *ngIf="loading">
  <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
  </mat-spinner>
</div>

<div class="confirm-title">
  <div class="confirm-title-inner-div">
    <button mat-fab class="confirm-dialog-button blue-button-icon">
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon-user">{{data.icon}}</mat-icon>
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon-edit">mode_edit</mat-icon>
    </button>
    <label class="dialog-title" i18n>{{data.title}}</label>
  </div>
  <button class="exit-button" (click)="exit('not updated')">
    <mat-icon matSuffix class="exit-icon">close</mat-icon>
  </button>
</div>

<form class="form" [formGroup]="firstFormGroup">
  <div class="edit-modal-content">
    <div
      [ngClass]="{'edit-modal-inside-content-one-field': data.fields.length === 1, 'edit-modal-inside-content': data.fields.length > 1 }">
      <mat-form-field appearance="fill" *ngFor="let field of data.fields; let i = index" class="grid-item"
        [matTooltip]="field.errorMsg ? field.errorMsg : ''" matTooltipPosition="above" matTooltipClass="error-tooltip"
        [matTooltipDisabled]="!firstFormGroup.controls[field.label].invalid">
        <mat-label i18n>{{field.label}}</mat-label>
        <input matInput name="field.label" *ngIf="!field.isCombo" [formControlName]="field.label"
          [readonly]="field.disabled" (input)="handleAction(field)" [type]="field.type ? field.type : 'text'">
        <mat-select name="field.label" *ngIf="field.isCombo" [formControlName]="field.label">
          <mat-option *ngFor="let combo of field.comboValues" [value]="combo.key" i18n>{{combo.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>

<div class="confirm-buttons">
  <button mat-fab class="button green-color" (click)="modify()" [disabled]="disable()">
    <mat-icon fontSet="material-icons-outlined">save</mat-icon>
  </button>
</div> 
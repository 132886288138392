import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../services/localization/localization.service';

@Component({
  selector: 'app-calendars',
  templateUrl: './calendars.component.html',
  styleUrls: ['./calendars.component.css'],
  host: {
    class: 'component',
  },
})
export class CalendarsComponent implements OnInit {
  selectedIndex = 0;

  constructor(public loc: LocalizationService) {}

  ngOnInit(): void {
    let state = JSON.parse(localStorage.getItem('state')!);
    this.selectedIndex = parseInt(state?.calendars as string) || 0;
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')! as string);
    if (!state.calendars) {
      state.calendars = {};
    }
    state.calendars = this.selectedIndex;
    localStorage.setItem('state', JSON.stringify(state));
  }
}
import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../services/localization/localization.service';

@Component({
  selector: 'app-digital-services',
  templateUrl: './digital-services.component.html',
  styleUrls: ['./digital-services.component.css'],
  host: {
    class: 'component'
  }
})
export class DigitalServicesComponent implements OnInit {

  selectedIndex = 0;
  
  constructor(
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    let state = JSON.parse(localStorage.getItem('state')!);
    this.selectedIndex = state.services?.tab || 0;
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    if(!state.services){
      state.services = {}
    }
    state.services.tab = this.selectedIndex
    
    localStorage.setItem('state', JSON.stringify(state));

  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { WorkingDay } from 'src/app/models/calendar/workingDay.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class WorkingDaysService {
  readonly rootURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  get(calendarId: string): Observable<BaseResponse<WorkingDay[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
        calendarId: calendarId,
      },
    };
    return this.http.get<BaseResponse<WorkingDay[]>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/calendar/calendar/${calendarId}/list-working-days`,
      HTTP_OPTIONS
    );
  }

  edit(
    calendarId: string,
    workingDays: WorkingDay[]
  ): Observable<BaseResponse<WorkingDay[]>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { days: workingDays };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<WorkingDay[]>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/calendar/calendar/${calendarId}/update-working-days`,
      baseRequest,
      HTTP_OPTIONS
    );
  }
}

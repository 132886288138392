import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { ServiceTemplate } from 'src/app/models/digital-services/ServiceTemplate.model';
import { DigitalService } from 'src/app/models/digital-services/digitalService';
import { Medium } from 'src/app/models/mediums/medium.model';
import { Operation } from 'src/app/models/operation/operation.model';
import { DigitalServicesService } from 'src/app/services/digitalServices/digital-services.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { MediumsService } from 'src/app/services/mediums/mediums.service';
import { ServiceOperationMediumsService } from 'src/app/services/serviceOperationMediums/service-operation-mediums.service';
import { MatSelectChange } from '@angular/material/select';
import { ServiceOperationMedium } from 'src/app/models/digital-services/ServiceOperationMedium';

@Component({
  selector: 'app-create-digital-service-operation-medium',
  templateUrl: './create-digital-service-operation-medium.component.html',
  styleUrls: ['./create-digital-service-operation-medium.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateDigitalServiceOperationMediumComponent implements OnInit {

  loading = false;
  firstFormGroup!: UntypedFormGroup;
  template: ServiceTemplate[] = [];

  digitalServices: Array<DigitalService> = [];
  operations: Array<Operation> = [];
  mediums: Array<Medium> = [];

  constructor(
    public dialogRef: MatDialogRef<CreateDigitalServiceOperationMediumComponent>,
    private _formBuilder: UntypedFormBuilder,
    private serviceOperationMediumsService: ServiceOperationMediumsService,
    private logoutDialogService: LogoutDialogService,
    private digitalServicesService: DigitalServicesService,
    private mediumsService: MediumsService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.firstFormGroup = this._formBuilder.group({
      service: ['', Validators.required],
      operation: ['', Validators.required],
      medium: ['', Validators.required],
    });
    this.loadServices();
    this.loadMediums();
  }

  loadServices() {
    this.digitalServicesService.getDigitalServices().subscribe(
      (res: BaseResponse) => {
        this.digitalServices = res.messageRS as unknown as Array<DigitalService>;
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  loadOperations(digitalService: DigitalService) {
    this.operations = digitalService.operations;
  }

  loadMediums() {
    this.mediumsService.getMediums().subscribe(
      (res: BaseResponse) => {
        this.mediums = res.messageRS as unknown as Array<Medium>;
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  changeSelectionDigitalService(event: MatSelectChange) {
    this.firstFormGroup.patchValue({
      operation: ''
    })
    this.operations = this.digitalServices.filter((service) => service.id == event.value.id)[0].operations;
  }

  create() {
    let serviceOperationMedium: ServiceOperationMedium = {
      serviceId: this.firstFormGroup.get('service')?.value.id,
      operationId: this.firstFormGroup.get('operation')?.value.id,
      mediumId: this.firstFormGroup.get('medium')?.value.mediumId
    };
    this.loading = true;
    this.serviceOperationMediumsService.createServiceOperationMedium(serviceOperationMedium)
    .subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if (res.statusRS?.code === "0")
            this.exit("created");
          if(res.statusRS?.code === "-91931")
            this.exit("invalid");
            if(res.statusRS?.code === "-91930")
            this.exit("error");
          this.loading = false;
        }, 200);
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Calendar } from "src/app/models/calendar/calendar.model";
import { BaseResponse } from "src/app/models/responses.model";
import { CalendarsService } from "src/app/services/calendars/calendars.service";
import { DialogService } from "src/app/services/dialog/dialog.service";
import { LocalizationService } from "src/app/services/localization/localization.service";
import { LogoutDialogService } from "src/app/services/logout-confirmation/logout-dialog.service";
import { SnackbarService } from "src/app/services/snackbar/snackbar.service";
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from "src/app/utils/table/table.model";
import { CreateCalendarComponent } from "../create-calendar/create-calendar.component";
import { DeleteModalData } from "src/app/models/delete-modal/delete-modal-data.model";
import { DeleteModalComponent } from "src/app/utils/delete-modal/delete-modal.component";
import { Validators } from "@angular/forms";
import { EditModalData } from "src/app/models/edit-modal/edit-modal-data.model";
import { EditModalComponent } from "src/app/utils/edit-modal/edit-modal.component";

@Component({
  selector: 'app-list-calendars',
  templateUrl: './list-calendars.component.html',
  styleUrls: ['./list-calendars.component.css'],
  host: {
    class: 'component',
  },
})
export class ListCalendarsComponent implements OnInit {
  loading = true;
  data: TableData[] = [];

  constructor(
    public loc: LocalizationService,
    private dialogService: DialogService,
    public snackbarService: SnackbarService,
    private calendarService: CalendarsService,
    private logoutDialogService: LogoutDialogService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.loadCalendars();
  };

  loadCalendars() {
    this.calendarService.getAll().subscribe({
      next: (res: BaseResponse<Calendar[]>) => {
        this.data = res.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  };

  emptyState : EmptyState = {
    image: 'assets/img/listado-calendario-posteos.png',
    message: this.loc.calendars.emptyState,
    buttons: [
      {
        text: this.loc.calendars.create,
        action: () => this.create(),
      }
    ]
  };

  columns : Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'calendarId',
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'calendarDescription',
    },
    {
      title: this.loc.tableHeaders.country,
      key: 'calendarCountry',
    },
  ];

  paginatorInfo = this.loc.calendars;

  filters : TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'calendarId',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'calendarDescription',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    },
    {
      label: this.loc.tableHeaders.country,
      key: 'calendarCountry',
      type: 'text',
      placeholder: this.loc.tableHeaders.country,
    },
    
  ]

  actions: Action[] = [
    {
      icon: 'more_horiz',
      tooltip: this.loc.common.details,
      action: (row: Calendar) => {
        this.goToDetails(row)
      }
    },
    {
      icon: 'edit',
      action: (row: Calendar) => this.edit(row),
      tooltip: this.loc.calendars.edit
    },
    {
      icon: 'delete',
      action: (row: Calendar) => this.delete(row),
      tooltip: this.loc.calendars.delete
    }
  ]

  createButton: CreateButton = {
    tooltip: this.loc.calendars.create,
    action: () => {
      this.create();
    }
  }

  onRowClick = {
    action: (row:Calendar) => {this.goToDetails(row)}
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateCalendarComponent,
      this.data as unknown as Calendar[],
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.calendars, 'm')
        );
        this.loadCalendars();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.calendars)
        );
        this.loadCalendars();
      }
      if (result === 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.calendarAlreadyExists
        );
      }
    });
  }

  edit(row: Calendar) {
    let data: EditModalData = {
      title: this.loc.calendars.edit,
      icon: 'calendar_month',
      fields: [
        { label: this.loc.fields.description, 
          value: row.calendarDescription, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') { 
        this.loading = true;
        this.editAction(row,result)
      }
    });
  }

  editAction(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value

    this.calendarService.edit(row.calendarId, newDescription).subscribe({
      next: (_response: BaseResponse<Calendar>) => {
        this.loading = false;
        if(_response.statusRS.code === '0'){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('update', this.loc.calendars)
          );
          this.loadCalendars();
        }else{
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.calendars)
          );
        }
        
      },
    }),
      (_error: any) => {
        this.loading = false;
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.calendars)
        );
      };
  }

  delete(row: Calendar) {
    let data: DeleteModalData = {
      title: this.loc.calendars.delete,
      icon: 'calendar_month',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.calendars),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.calendarService.delete(row.calendarId).subscribe({
          next: (res: BaseResponse<Calendar>) => {
            this.loading = true;
            switch (res.statusRS?.code) {
              case '0':
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.calendars, 'm')
                );
                this.loadCalendars();
                break;
              case '-91817':
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.calendarInUse
                );
                this.loadCalendars();
                break;
              default:
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.calendars)
                );
                break;
            }
            this.loading = false;
          },
          error: (_err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.calendars)
            );
          },
        });
      }
    });
  }

  goToDetails(row: any): void  {
      this.router.navigate(['calendar-details'], {
        queryParams: {
          calendarId: row.calendarId,
          calendarDescription: row.calendarDescription,
        },
        queryParamsHandling: 'merge',
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../services/localization/localization.service';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  host: {
    class: 'component',
  },
})
export class UsersComponent implements OnInit {
  selectedIndex = 0;

  constructor(
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    let state = JSON.parse(localStorage.getItem('state')!);
    this.selectedIndex = state?.users?.tab || 0
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state.users = {
      tab: this.selectedIndex
    };
    localStorage.setItem('state', JSON.stringify(state));
  }
}

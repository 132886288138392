<div class="form-step">
    <div class="data-box center">
        <div style="display: inline-block">
            <div class="image-box">
                <div>
                    <mat-icon fontSet="material-symbols-outlined" *ngIf="!imageProfile"
                        class="default-img">add_a_photo</mat-icon>
                    <img *ngIf="imageProfile" [src]="imageProfile" alt="img_user" class="image"
                        (error)="imageProfile = ''" />
                </div>
            </div>

            <button mat-fab class="image-button button green-color" (click)="hiddenfileinput.click()" i18n-matTooltip
                [matTooltip]="loc.common.addPhoto">
                <mat-icon class="flip-horizontal" fontSet="material-icons-outlined">add_a_photo</mat-icon>
            </button>

            <input type="file" style="display: none" accept="image/*" (change)="onFileSelect($event)"
                #hiddenfileinput />
        </div>
    </div>

    <div class="dialog-nav-buttons">
        <button *ngIf="step.idx! > 0" mat-fab class="button green-button" matStepperPrevious>
            <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-fab class="button green-button" (click)="next()" matStepperNext>
            <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>
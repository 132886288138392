import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateBankComponent } from '../../../banks/create-bank/create-bank/create-bank.component';
import { Bank } from '../../../models/Banks/bank.model';
import { DialogService } from '../../../services/dialog/dialog.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from '../../../services/accounts/accounts.service';
import { LogoutDialogService } from '../../../services/logout-confirmation/logout-dialog.service';
import { LocalizationService } from '../../../services/localization/localization.service';
import { AccountIds } from '../../../models/accountIds/account-ids.model';
import { BaseResponse } from '../../../models/responses.model';
import { PaymentButtonFields } from '../../../models/payment-buttons/paymentButtonFields.model';
import { BranchOperation } from '../../../models/branch/branchOperations.model';
import { BranchService } from '../../../services/branch/branch.service';

@Component({
  selector: 'app-create-branch',
  templateUrl: './create-branch.component.html',
  styleUrls: ['./create-branch.component.css'],
  host: {
    class: 'dialog-component',
  },
})
export class CreateBranchComponent implements OnInit {
  loading = true;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  actions: any[] = [];
  accounts: AccountIds[] = [];

  device = { id: 'DEVICE', description: 'Device' };
  branch = { id: 'BRANCH', description: 'Branch' };

  constructor(
    public dialogRef: MatDialogRef<CreateBankComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Bank[],
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private branchService: BranchService,
    private accountService: AccountsService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      operationId: ['', Validators.required],
      ordererAccountId: ['', Validators.required],
      recipientAccountId: ['', Validators.required],
      description: ['', Validators.required],
      recipientEntityId: ['', Validators.required],
      ordererEntityId: ['', Validators.required],
      allowsDenominations: [''],
    });
    this.loadAccounts();
    this.loading = false;
  }

  loadAccounts() {
    this.loading = true;
    this.accountService.getAccountIds().subscribe({
      next: (data: BaseResponse<AccountIds[]>) => {
        this.accounts = data.messageRS;
      },
      error: (_err: any) => {
        this.loading = false;
      },
    });
  }

  create() {
    this.loading = true;

    let branchOperation: BranchOperation = {
      operationId: this.firstFormGroup.get('operationId')!.value,
      ordererAccountId: this.firstFormGroup.get('ordererAccountId')!.value.id,
      recipientAccountId:
        this.firstFormGroup.get('recipientAccountId')!.value.id,
      description: this.firstFormGroup.get('description')!.value,
      ordererEntityId: this.firstFormGroup.get('ordererEntityId')!.value.id,
      recipientEntityId: this.firstFormGroup.get('recipientEntityId')!.value.id,
      allowDenominations: this.firstFormGroup.get('allowsDenominations')!.value === this.loc.common.yes ? 'true' : 'false'
    };

    this.branchService.createBranchOperation(branchOperation).subscribe({
      next: (res: BaseResponse<BranchOperation>) => {
        this.loading = false;
        switch (res.statusRS?.code) {
          case '0':
            this.exit('created');
            break;
          case '-91850':
            this.exit('duplicate');
            break;
          default:
            this.exit('error');
            break;
        }
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      },
    });
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
  

  exit(created: string) {
    this.dialogRef.close(created);
  }
}

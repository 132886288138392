import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormControl,
  ValidatorFn,
  Validators,
  AbstractControl
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { ExternalEntity } from 'src/app/models/Frame/ExternalEntity.model';
import { FrameAPI } from 'src/app/models/Frame/FrameAPI.model';
import { GroupName } from 'src/app/models/Frame/GroupName.model';
import { API } from 'src/app/models/api/api.model';
import { ApisService } from 'src/app/services/apis/apis.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-create-api',
  templateUrl: './create-api.component.html',
  styleUrls: ['./create-api.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateApiComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateApiComponent>,
    private _formBuilder: UntypedFormBuilder,
    private apiService: ApisService,
    public loc: LocalizationService
  ) {}
  loading = false;
  body = '{}';
  externalEntity = '';
  invalidJSON = false;
  groupName = '';
  externalEntities: ExternalEntity[] = [];
  groupNames: GroupName[] = [];
  frameAPIs: FrameAPI[] = [];
  firstFormGroup!: UntypedFormGroup;
  secondFormGroup!: UntypedFormGroup;
  showErrorMessage = false;
  errorMsg = this.loc.errors.noApisForExternalEntity;

  ngOnInit(): void {
    console.log(this.data);
    this.firstFormGroup = this._formBuilder.group({
      apiId: ['', Validators.required],
      apiName: ['', Validators.required],
      externalEntity: ['', Validators.required],
      groupName: ['', Validators.required],
      frameAPIs: ['', Validators.required],
      path: [''],
      version: [''],
      method: ['']
    });
    this.secondFormGroup = this._formBuilder.group({
      body: ['', [Validators.required, this.jsonValidator()]]
    });

    this.loadEntites();
    this.loadGroupNames();
  }

  jsonValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        JSON.parse(control.value);
        return null; // Retorna null si el JSON es válido
      } catch (error) {
        return { invalidJson: true }; // Retorna un objeto con la clave "invalidJson" si el JSON es inválido
      }
    };
  }

  loadGroupNames() {
    this.loading = true;
    this.apiService.getGroupApis().subscribe({
      next: (res: any) => {
        if (res.statusRS.code === '0') {
          this.groupNames = (res.messageRS as any) as GroupName[];
          this.loading = false;
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  loadEntites() {
    this.loading = true;
    this.apiService.getEntities().subscribe({
      next: (res: any) => {
        if (res.statusRS.code === '0') {
          this.externalEntities = (res.messageRS as any) as ExternalEntity[];

          this.loading = false;
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  loadFrameAPIs(data: string, type: string) {
    this.firstFormGroup.get('path')?.setValue('');
    this.firstFormGroup.get('method')?.setValue('');
    this.firstFormGroup.get('version')?.setValue('');
    switch (type) {
      case 'entity':
        this.externalEntity = data;
        break;
      case 'group':
        this.groupName = data;
        break;
    }
    if (this.frameAPIs.length === 0) {
      this.showErrorMessage = true;
    } else {
      this.showErrorMessage = false;
    }
    if (this.externalEntity != '' && this.groupName != '') {
      this.apiService
        .getFrameAPIs(this.externalEntity, this.groupName)
        .subscribe({
          next: (res: any) => {
            if (res.statusRS.code === '0') {
              this.frameAPIs = (res.messageRS as any) as FrameAPI[];
            }
          },
          error: (err: any) => {
            console.log(err);
          }
        });
    }
  }

  create() {
    this.loading = true;
    let path: string = this.firstFormGroup.get('frameAPIs')?.value.path;
    let version: string = this.firstFormGroup.get('frameAPIs')?.value.version;
    let operation: string = this.firstFormGroup.get('frameAPIs')?.value
      .restOperation;
    let method: string = this.firstFormGroup.get('frameAPIs')?.value.method;
    let api: API = {
      apiId: this.firstFormGroup.controls['apiId'].value,
      name: this.firstFormGroup.controls['apiName'].value,
      path: path,
      version: version,
      operation: operation,
      method: method,
      body: this.body
    };
    this.apiService.createAPI(api).subscribe({
      next: (res: any) => {
        if (res.statusRS.code === '0') {
          this.exit('created');
        }
      },
      error: (err: any) => {
        console.log(err);
        this.exit('error');
      }
    });
  }

  getFrameDate(apiFrame: FrameAPI) {
    this.firstFormGroup.get('path')?.setValue(apiFrame.path);
    this.firstFormGroup.get('version')?.setValue(apiFrame.version);
    this.firstFormGroup.get('method')?.setValue(apiFrame.method);
  }

  next(stepper: MatStepper) {
    console.log(this.secondFormGroup.get('body'), 'body');
    if (this.secondFormGroup.get('body')?.hasError('invalidJson')) {
      this.invalidJSON = true;
    } else {
      this.invalidJSON = false;
      stepper.next();
    }
  }
}

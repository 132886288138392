import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(public _snackBar: MatSnackBar) {}

  openSuccessSnackbar(message: string) {
    this._snackBar
      .open(message, '', {
        duration: 2000,
        panelClass: ['green-snackbar'],
        verticalPosition: 'top',
      })
      .afterDismissed()
      .subscribe(() => {
        window.location.reload();
      });
  }

  openSuccessSnackbarWithNoReload(message: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['green-snackbar'],
      verticalPosition: 'top'
    });
  }

  openErrorSnackbar(message: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['red-snackbar'],
      verticalPosition: 'top',
    });
  }
}

<div class="general-operations-div">
  <div style="display: flex; margin-right: 15px;align-items: center;">
    <button  mat-fab class="page-icon-button blue-button-icon">
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon">store</mat-icon>
    </button>
    <label class="page-title"  i18n>{{loc.routes.branches}}</label>
  </div>
</div>

<mat-tab-group
  class="tab-bar"
  [(selectedIndex)]="selectedIndex"
  (selectedTabChange)="onTabChange();"
>
  <mat-tab [label] = loc.tabNames.branchOperations i18n>
    <app-list-branch-operations></app-list-branch-operations>
  </mat-tab>

  <mat-tab [label] = loc.tabNames.branchOperationRoles i18n>
    <app-operation-role></app-operation-role>
  </mat-tab>

</mat-tab-group>

<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
  </div>
  <div class="confirm-title">
    <div class="confirm-title-inner-div">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon-user">psychology</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon-edit">mode_edit</mat-icon>
      </button>
      <label class="dialog-title" i18n>{{loc.apis.edit}}</label>
    </div>
    <button class="exit-button" (click)="exit('not updated')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
  
  <div class="edit-first-row edit-step-component">
    <form [formGroup]="firstFormGroup" class="horizontal-stepper actions-stepper form-step edit-step-component" >
        <div class="data-box">
            <div class="create-row">
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{ loc.tableHeaders.id }}</mat-label>
                      <mat-select formControlName="apiId">
                          <mat-option *ngFor="let api of APIs" [value]="api.apiId">{{
                            api.apiId
                          }}</mat-option>
                        </mat-select>
                  </mat-form-field>


                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.name}}</mat-label>
                    <input matInput name="name" formControlName="name"  [(ngModel)]="selectedAPI.name" readonly>
                </mat-form-field>
            </div>
            <div class="create-row">
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.path}}</mat-label>
                    <input matInput  name="path" formControlName="path" [(ngModel)]="selectedAPI.path" readonly>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.operation}}</mat-label>
                    <input matInput name="operation" formControlName="operation"  [(ngModel)]="selectedAPI.operation" readonly >
                </mat-form-field>
            </div>
            <div class="create-row">
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.version}}</mat-label>
                    <input matInput  name="version" formControlName="version" [(ngModel)]="selectedAPI.version" readonly >
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.method}}</mat-label>
                    <input matInput name="method" formControlName="method"  [(ngModel)]="selectedAPI.method" readonly >
                </mat-form-field>
            </div>
            <div class="create-row body">
                <mat-form-field appearance="fill" [style.verticalAlign]="'top'" class="body">
                    <mat-label i18n>{{ loc.tableHeaders.body }}</mat-label>
                    <textarea
                      matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      formControlName="body"
                      cdkAutosizeMinRows="5"
                      cdkAutosizeMaxRows="5"
                      [value]="body"
                      readonly
                    ></textarea>
                </mat-form-field>
            </div>
                
        </div>
    </form>
  </div>
  <div class="confirm-buttons">
    <button mat-fab class="button green-color" (click)="edit()" [disabled]="disable()">
      <mat-icon fontSet="material-icons-outlined">save</mat-icon>
    </button>
  </div>
  
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { TokenService } from '../token/token.service'; 
import { GlobalConstants } from '../../models/global.constants';
import { Login } from '../../models/login.model';
import { BaseRequest, createRequest } from '../../models/requests.model';



@Injectable({
  providedIn: 'root',
})
export class SessionsService {
  readonly rootURL = environment.apiUrl;
  redirectUrl = '';

  private emitChangeSource = new Subject<any>();
  changeEmmited = this.emitChangeSource.asObservable();

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  login(loginData: Login): Observable<any> {
    const OAUTH_CLIENT = loginData.username;
    const OAUTH_SECRET = loginData.password;

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + btoa(OAUTH_CLIENT + ':' + OAUTH_SECRET),
        pChannel: GlobalConstants.HeaderPChannelValue,
      }),
      responseType: 'text' as 'json',
      observe: 'response' as 'body',
    };

    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('username', loginData.username ? loginData.username : '')
      .set('password', loginData.password ? loginData.password : '')
      .set('grant_type', 'client_credentials')
      .set('userDomain', 'OWN');

    return this.http
      .post<any>(this.rootURL + 'adaptorOAS/auth/login', body, HTTP_OPTIONS)
      .pipe(
        tap((res: {}) => {})
      );
  }

  logout() {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': GlobalConstants.HeaderContentTypeJson,
        pChannel: GlobalConstants.HeaderPChannelValue,
      }),
    };
    return this.http.post(this.rootURL + 'adaptorOAS/auth/logout', {}, HTTP_OPTIONS);
  }

  updatePassword(oldPassword: string, newPassword: string) {
    let body = `NewPassword=${newPassword}&password=${oldPassword}`;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/x-www-form-urlencoded',
        pChannel: GlobalConstants.HeaderPChannelValue,
      }),
    };
    return this.http.post(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/auth/password/X/change-password',
      body,
      HTTP_OPTIONS
    );
  }

  updatePasswordOAS(oldPassword: string, newPassword: string) {
    const token = this.tokenService.getToken();
    let body = {
      NewPassword: newPassword,
      password: oldPassword,
      token: token,
    };
    
    var baseRequest: BaseRequest = createRequest(body);

    baseRequest.messageRQ = body;
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          'Content-Type': GlobalConstants.HeaderContentTypeJson,
          'pChannel': GlobalConstants.HeaderPChannelValue
        })
      };
      
    return this.http.post(this.rootURL + 'RSAdaptorFE/api/v1/paycorr/auth/password/X/change-password', baseRequest, HTTP_OPTIONS);
  }

  generateRecoveryOTP(username: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': GlobalConstants.HeaderContentTypeJson,
        pChannel: GlobalConstants.HeaderPChannelValue,
      }),
    };
    return this.http.get(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/auth/username/' + username + '/generate-recovery-otp',
      HTTP_OPTIONS
    );
  }


  validateRecoveryOTP(user: string, password: string, otp: string){
    let body = {
      newPassword: password,
      otp: otp,
    };
    var baseRequest: BaseRequest = createRequest(body);
    baseRequest.messageRQ = body;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': GlobalConstants.HeaderContentTypeJson,
        pChannel: GlobalConstants.HeaderPChannelValue,
      }),
    };
    return this.http.post(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/auth/username/'+ user+ '/validate-recovery-otp',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  recoverPasswordOTP(user:string, password:string, otp:string) {
    let body = {
      "user": user,
      "userDomain": GlobalConstants.UserDomain,
      "newPassword": password,
      "otp": otp
    };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': GlobalConstants.HeaderContentTypeJson,
        'pChannel': GlobalConstants.HeaderPChannelValue
      })
    };
    return this.http.post(this.rootURL + 'adaptorOAS/auth/recoveryPassword/otp', body, HTTP_OPTIONS);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BehaviorSubjectGetUserService {
  private bulma = new BehaviorSubject<string>('');
  private bulma2 = new BehaviorSubject<string>('');
  constructor() {}

  bulma$ = this.bulma.asObservable();
  bulma2$ = this.bulma2.asObservable();

  enviar(name: string, surname: string) {
    this.bulma.next(name);
    this.bulma2.next(surname);
  }
}

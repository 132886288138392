<div class="general-operations-div">
  <div style="display: flex">
    <button mat-icon-button class="back" (click)="goBack()">
      <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
    </button>
    <button mat-fab class="page-icon-button blue-button-icon">
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon"
        >smart_button</mat-icon
      >
    </button>
    <ng-container *ngIf="!loading">
      <div class="title-container">
        <label class="title-mid-text">
          {{buttonType}}</label>
      </div>
    </ng-container>
  </div>
</div>

<mat-card class="details-card">
  <div class="flex-container">
    <div class="flex-container-vertical">
      <div>
        <label class="card-header" i18n>{{
          loc.detailsHeaders.paymentButtonTypeDescription
        }}</label>
      </div>
      <div>
        <ng-container *ngIf="!loading">
          <label class="card-value">
            {{ buttonTypeDesc }}
          </label>
        </ng-container>
        <div *ngIf="loading">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              background: 'no-repeat rgb(227 228 230 / 33%)',
              height: '18px',
              width: '150px'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<mat-tab-group class="tab-bar">
  <mat-tab [label]="loc.tabNames.paymentButtonTypeFields" i18n-label>
    <app-payment-button-type-fields
      [buttonType]='this.buttonType'
      tableStyle="tableDetails"
      fieldsType="action"
    ></app-payment-button-type-fields>
  </mat-tab>
</mat-tab-group>

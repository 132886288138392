import { Component, OnInit } from '@angular/core';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { ServiceOperationMediumsService } from 'src/app/services/serviceOperationMediums/service-operation-mediums.service';
import { Action, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { CreateDigitalServiceOperationMediumComponent } from './create-digital-service-operation-medium/create-digital-service-operation-medium.component';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { StatusRS } from '../../models/responses.model';

@Component({
  selector: 'app-digital-service-operation-mediums',
  templateUrl: './digital-service-operation-mediums.component.html',
  styleUrls: ['./digital-service-operation-mediums.component.css'],
  host: { class: 'component' },
})
export class DigitalServiceOperationMediumsComponent implements OnInit {
  
  loading = true;

  constructor(
    public loc: LocalizationService,
    public serviceOperationMediumsService: ServiceOperationMediumsService,
    private logoutDialogService: LogoutDialogService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.loadServiceOperationMediums();
  }

  private loadServiceOperationMediums(){
    this.serviceOperationMediumsService.getServiceOperationMediums().subscribe(
      (res: BaseResponse) => {
        this.data = res.messageRS as unknown as TableData[];
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  emptyState: EmptyState = {
    image: "assets/img/empty-services.png",
    message: this.loc.serviceOperationMediums.empty,
    buttons: [
      {
      text: this.loc.common.create,
      action: () => this.create()
      },
    ]
  };

  data: TableData[] = [];
  
  columns: Column[] = [
    {
      key: 'serviceDescription',
      title: this.loc.tableHeaders.service,
      sortable: true,
    },
    {
      key: 'operationDescription',
      title: this.loc.tableHeaders.operation,
      sortable: true,
    },
    {
      key: 'mediumDescription',
      title: this.loc.tableHeaders.medium,
      sortable: true,
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.serviceOperationMediums
  transformers: TableTransformer = {}

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.service,
      key: 'serviceDescription',
      type: 'text',
      placeholder: this.loc.tableHeaders.service,
    },
    {
      label: this.loc.tableHeaders.operation,
      key: 'operationDescription',
      type: 'text',
      placeholder: this.loc.tableHeaders.operation,
    },
    {
      label: this.loc.tableHeaders.medium,
      key: 'mediumDescription',
      type: 'text',
      placeholder: this.loc.tableHeaders.medium,
    }
  ];

  actions: Action[] = [
    {
      tooltip: this.loc.serviceOperationMediums.delete,
      icon: 'delete',
      action: (row) => this.delete(row)
    }
  ];

  createButton: CreateButton = {
    tooltip: this.loc.serviceOperationMediums.create,
    action: () => this.create()
  };

  create(){
    const dialogRef = this.dialogService.openDialog(
      CreateDigitalServiceOperationMediumComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.serviceOperationMediums, 'm')
        );
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.serviceOperationMediums)
        );
      }
      if (result === 'invalid') {
        this.snackbarService.openErrorSnackbar(
          'El medio de operación de servicio ya existe'
        );
      }
      this.loadServiceOperationMediums();
    });
  }

  delete(row: any){
    let data: DeleteModalData = {
      title: this.loc.serviceModes.delete,
      icon: 'paid',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.serviceOperationMediums),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.serviceOperationMediumsService.deleteServiceOperationMedium(
          {
            serviceId: row.serviceId,
            operationId: row.operationId,
            mediumId: row.mediumId
          }
        ).subscribe(
            (res: BaseResponse) => {
              this.loading = true;
              switch (res.statusRS?.code){
                case '0':
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage(
                      'delete',
                      this.loc.serviceOperationMediums,
                      'm'
                    )
                  );
                  this.loadServiceOperationMediums();
                  break;
                default:
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError('delete', this.loc.serviceOperationMediums)
                  );
                  break;
              }
              this.loading = false;
            }, (err) => {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.serviceOperationMediums)
              );
          }
        );
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { BaseResponse } from 'src/app/models/responses.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { BranchOperationRole } from 'src/app/models/branch/BranchOperationRole.model';
import { CreateOperationRoleComponent } from './create-operation-role/create-operation-role.component';
import { UsersService } from 'src/app/services/users/users.service';
import { UserType } from 'src/app/models/users/UserType.model';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditFieldData } from 'src/app/models/edit-modal/edit-field-data';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';

@Component({
  selector: 'app-operation-role',
  templateUrl: './operation-role.component.html',
  styleUrls: ['./operation-role.component.css'],
  host: { class: 'component' },
})
export class OperationRoleComponent implements OnInit {
  loading: boolean = true;
  userTypes : UserType[] = [];

  constructor(
    public loc: LocalizationService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private branchService: BranchService,
    private usersService: UsersService,
    private logoutDialogService: LogoutDialogService
  ) {}

  ngOnInit(): void {
    this.loadBranchOperationRoles();
    this.loadUserTypes()
  }


  emptyState: EmptyState = {
    image: 'assets/img/empty-agent.png',
    message: this.loc.branchOperationRoles.empty,
    buttons: [
      {
        action: () => this.create(),
        text: this.loc.branchOperationRoles.create,
      }
    ]
  }

  data : TableData[] = []

  columns: Column[] = [
    {
      key: 'operationId',
      title: this.loc.tableHeaders.operationId,
    },
    {
      key: 'description',
      title: this.loc.tableHeaders.description,
    },
    {
      key: 'originUserType',
      title: this.loc.tableHeaders.originUserType,
    },
    {
      key: 'destinationUserType',
      title: this.loc.tableHeaders.destinationUserType,
    }
  ]

  transformers = {}

  paginatorInfo = {
    singular: this.loc.branchOperationRoles.singular,
    plural: this.loc.branchOperationRoles.plural,
  }

  filters : TableFilter[] = [
    {
      key: 'operationId',
      label: this.loc.tableHeaders.operationId,
      type: 'text',
      placeholder: this.loc.tableHeaders.operationId,
    },
    {
      key: 'description',
      label: this.loc.tableHeaders.description,
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    },
    {
      key: 'originUserType',
      label: this.loc.tableHeaders.originUserType,
      type: 'text',
      placeholder: this.loc.tableHeaders.originUserType,
      class: 'date-filter'
    },
    {
      key: 'destinationUserType',
      label: this.loc.tableHeaders.destinationUserType,
      type: 'text',
      placeholder: this.loc.tableHeaders.destinationUserType,
      class: 'date-filter'
    }
  ]

  actions: Action[] = [
    {
      icon: 'edit',
      action: (row: any) => this.edit(row),
      tooltip: this.loc.branchOperationRoles.edit,
    },
    {
      icon: 'delete',
      action: (row: any) => this.delete(row),
      tooltip: this.loc.branchOperationRoles.delete,
    }
  ]

  createButton: CreateButton = {
    action: () => this.create(),
    tooltip: this.loc.branchOperationRoles.create,
  }

  loadBranchOperationRoles() {
    this.loading = true;
    this.branchService.getBranchOperationRoles().subscribe({
      next: (data: BaseResponse<BranchOperationRole[]>) => {
        this.data = data.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (_err: any) => {},
    });
  }

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateOperationRoleComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.branchOperationRoles, 'f')
        );
        this.loadBranchOperationRoles();
      } else if (result === 'error' || result == '-91870') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.branchOperationRoles)
        );
        this.loadBranchOperationRoles();
      } else if (result == '-91869') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.operationRoleAlreadyExist
        );
      }
    });
  }

  loadUserTypes(){
    this.usersService
    .getUserTypes()
    .subscribe(
      (res: BaseResponse<UserType[]>) => {
        let response = res.messageRS as any as UserType[];
        this.userTypes = response;
      },
      (err: any) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  modifyAction(row: any, result: any){
    let originUserType = row.originUserType
    let destinationUserType = row.destinationUserType
    let newOriginUserType = result.find((x: EditFieldData) => x.label === this.loc.fields.originUserType).value
    let newDestinationUserType = result.find((x: EditFieldData) => x.label === this.loc.fields.destinationUserType).value

    if(newOriginUserType !== originUserType
       || newDestinationUserType !== destinationUserType){
        this.branchService.editBranchOperationRole(row.operationId, originUserType, destinationUserType, newOriginUserType, newDestinationUserType).subscribe(
          {
            next: (res: BaseResponse<any>) => {
              this.loading = false;
              if(res.statusRS?.code === "0"){
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('update', this.loc.branchOperationRoles)
                );
                this.loadBranchOperationRoles();
              } else if(res.statusRS?.code === "-91872"){
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.operationRoleAlreadyExist
                );
              }else{
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('update', this.loc.branchOperationRoles)
                );
              }
            },error: (err) => {
              this.loading = false;
              if (err.statusCode !== 401) {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('update', this.loc.branchOperationRoles)
                );
              }else{
                this.logoutDialogService.openLogoutDialog(err);
              }
            }
          }
          )
    }else{
      this.loading = false;
      this.snackbarService.openSuccessSnackbarWithNoReload(
        this.loc.successMessage('update', this.loc.branchOperationRoles)
      );
    }
  }

  edit(row: BranchOperationRole) {
    const mappedUserTypes = this.userTypes.map(userType => ({
      key: userType.id,
      value: userType.description
    }));
    let data: EditModalData = {
      title: this.loc.branchOperationRoles.edit,
      icon: 'store',
      fields: [
        { label: this.loc.fields.id,
          value: row.operationId,
          errorMsg: this.loc.errors.emptyDescription,
          validators: [Validators.required],
          disabled: true
        },
        { label: this.loc.fields.originUserType,
          value: this.userTypes.find(usertype => usertype.id == row.originUserType)?.id!!,
          errorMsg: this.loc.errors.emptyDescription,
          comboValues: mappedUserTypes,
          isCombo: true
        },
        { label: this.loc.fields.destinationUserType,
          value: this.userTypes.find(usertype => usertype.id == row.destinationUserType)?.id!!,
          errorMsg: this.loc.errors.emptyDescription,
          comboValues: mappedUserTypes,
          isCombo: true
        },
      ]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyAction(row, result)
      }
    });
  }

  delete(row: BranchOperationRole): void {
    let data: DeleteModalData = {
      title: this.loc.branchOperationRoles.delete,
      icon: 'store',
      confirmDeleteMessage: this.loc.confirmDelete(
        this.loc.branchOperationRoles
      ),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.branchService.deleteBranchOperationRole(row).subscribe({
          next: (res: BaseResponse<any>) => {
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.branchOperationRoles)
              );
              this.loadBranchOperationRoles();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.branchOperationRoles)
              );
              this.loadBranchOperationRoles();
            }
          },
          error: (_err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.branchOperationRoles)
            );
          },
        });
      }
    });
  }

}

<ng-container *ngIf="!loading">
    <ng-container *ngIf="dataSource.data.length> 0" >
        <div class="filter-action-button">
        <form class="filter-form" *ngIf="filters && filters.length> 0">
            <ng-container *ngFor="let filter of filters">
                <mat-form-field  appearance="fill" *ngIf="filter.type === 'text'"
                    [class]="filter.class ? filter.class : 'large-filter'">
                    <mat-label i18n>{{filter.label}}</mat-label>
                    <mat-icon matSuffix style="font-size: 20px; color: grey;">
                        search
                    </mat-icon>
                    <input type="text" aria-label="Number" matInput
                        [formControl]="filterControls[filter.key]"
                        [matAutocomplete]="auto"
                        (keyup)="addFilter($event, filter.key)"
                        [placeholder]="filter.placeholder" />
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of
                            filteredOptions[filter.key] | async" [value]="option"
                            (click)="addFilter(option, filter.key)">
                            {{ option }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="fill" [class]="filter.class ? filter.class : 'large-filter'"  *ngIf="filter.type === 'select'">
                    <mat-label i18n>{{filter.label}}</mat-label>
                    <mat-select (selectionChange)="addFilter($event, filter.key)" [formControl]="filterControls[filter.key]">
                        <mat-option *ngFor="let option of filter.options" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" [class]="filter.class ? filter.class : 'date-filter'"  *ngIf="filter.type === 'date'">
                    <mat-label i18n>{{filter.label}}</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate (dateChange)="dateChanged($event,'start', filter.key)" [formControl]="dateFilterControls[filter.key].start"/>
                          <input matEndDate (dateChange)="dateChanged($event,'end', filter.key)" [formControl]="dateFilterControls[filter.key].end"/>
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </ng-container>
        </form>

        <div class="action-buttons" *ngIf="actionButtons && actionButtons.length> 0">
            <button *ngFor="let b of actionButtons" (click)="b.action()" [matTooltip]="b.tooltip" mat-fab class="download-button" >
                <mat-icon>{{b.icon}}</mat-icon>
            </button>
        </div>


    </div>
        <div [class]="tableStyle">
            <mat-card style="padding: 0px; border-radius: 10px;">
                <mat-card style="overflow-y: auto; padding: 0px; box-shadow:
                    none !important;">
                    <table mat-table scrollY="true" [dataSource]="dataSource"
                        matSort [class]="'table ' + title">
                        <ng-container *ngFor="let column of columns"
                            [matColumnDef]="column.key">
                            <!-- add mat-sort-header if column.sortable -->
                            <ng-container *ngIf="column.sortable">
                                <th mat-header-cell *matHeaderCellDef [ngClass]="{'centered': column.centered || false}" mat-sort-header class="hide-arrow" >
                                    {{column.title}}
                                </th>
                            </ng-container>
                            <ng-container *ngIf="!column.sortable">
                                <th mat-header-cell *matHeaderCellDef [ngClass]="{'centered': column.centered || false}"
                                    class="hide-arrow"
                                    [innerHTML]="column.title">
                                </th>
                            </ng-container>
                            <td mat-cell *matCellDef="let element" [ngClass]="{'centered': column.centered || false, 'bold': column.bold || false}"
                                class="table-item" [style]="cellStyle"
                                [innerHTML]="(transformers[column.key] ?
                                transformers[column.key](element[column.key]) :
                                element[column.key]) | safeHtml">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <ng-container *ngIf="!actionColumnName"><th mat-header-cell *matHeaderCellDef
                                class="hide-arrow" i18n class="centered">{{loc.tableHeaders.actions}}</th></ng-container>

                                <ng-container *ngIf="actionColumnName">
                                    <th mat-header-cell *matHeaderCellDef class="hide-arrow centered" i18n>{{actionColumnName}}</th>
                                  </ng-container>

                            <td mat-cell *matCellDef="let element"
                                class="table-icons" [style]="cellStyle" style="min-width: fit-content">
                                <div class="centered" style="min-width: max-content">
                                    <ng-container *ngFor="let action of
                                        actions">
                                        <ng-container *ngIf="action.condition ? action.condition(element) : true">
                                            <button mat-icon-button
                                                [style.visibility]="action.hidden ? action.hidden(element) ? 'hidden' : 'visible' : 'visible'"
                                                (click)="handleActionClicked(action,element, $event)"
                                                [matTooltip]="action.tooltip"
                                                [matTooltipDisabled]="action.tooltip === ''"
                                                [disabled]="action.disabled ? action.disabled(element) : false"
                                                [class]="action.class ? ('table-button '+ action.class) : 'table-button'">
                                                <mat-icon
                                                    fontSet="material-icons-outlined"
                                                    class="black-icon">{{action.icon}}</mat-icon>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </td>


                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayColumns;
                            sticky: true"
                            [style]="headerStyle"></tr>
                        <ng-container *ngIf="onRowClick">
                            <tr mat-row *matRowDef="let row; columns:
                                displayColumns;"
                                (click)="onRowClick.action(row)"
                                [style]="rowStyle" [style.cursor]="'pointer'"></tr>
                        </ng-container>
                        <ng-container *ngIf="!onRowClick">
                            <tr mat-row *matRowDef="let row; columns:
                                displayColumns;"
                                [style]="rowStyle">
                            </tr>
                        </ng-container>
                    </table>
                </mat-card>
                <button *ngIf="createButton" mat-fab class="add-button"
                    (click)="createButton.action()" i18n-matTooltip
                    [matTooltip]="createButton.tooltip">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-card>
        </div>
        <div class="paginator-wrapper">
            <mat-paginator [pageSizeOptions]="pageSizeOptions"
                [pageSize]="pageSize" [pageIndex]="pageIndex"
                showFirstLastButtons (page)="pageEvents($event)"
                aria-label="Select page"
                style="background-color: transparent;"></mat-paginator>
            <ng-container *ngIf="dataSource.data.length === 1">
                <label class="count" i18n>
                    {{ dataSource.data.length }}
                    {{paginatorInfo.singular.toLowerCase()}}
                </label>
            </ng-container>
            <ng-container *ngIf="dataSource.data.length> 1">
                <label class="count" i18n>
                    {{ dataSource.data.length }}
                    {{paginatorInfo.plural.toLowerCase()}}
                </label>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="dataSource.data.length === 0">
        <div class="empty-list">
            <div><img class="empty-img" [src]="emptyState.image" /></div>
            <div class="empty-lbl">
                <label i18n>
                    {{emptyState.message}}
                </label>
            </div>
            <button *ngFor="let button of emptyState.buttons" mat-raised-button
                (click)="button.action()" class="add-button-empty"
                [style]="button.style">
                {{button.text}}
            </button>
        </div>
    </ng-container>
</ng-container>

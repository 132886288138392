import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { Posting } from 'src/app/models/posting-schemes/Posting.model';
import { Disaggregation } from 'src/app/models/posting-schemes/Disaggregation.model';
import { UsersService } from 'src/app/services/users/users.service';
import { UserType } from 'src/app/models/users/UserType.model';
import { BranchService } from 'src/app/services/branch/branch.service';
import { BranchOperation } from 'src/app/models/branch/branchOperations.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { BranchOperationRole } from 'src/app/models/branch/BranchOperationRole.model';

@Component({
  selector: 'app-create-operation-role',
  templateUrl: './create-operation-role.component.html',
  styleUrls: ['./create-operation-role.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateOperationRoleComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  operations: BranchOperation[] = [];
  userTypes: UserType[] = [];
  stageId = '';


  constructor(
    public dialogRef: MatDialogRef<CreateOperationRoleComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private usersService: UsersService,
    private branchService: BranchService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.loadOperations()
    this.loadUserTypes();
    this.firstFormGroup = this._formBuilder.group({
      operationId: ['', Validators.required],
      origin: ['', Validators.required],
      destination: ['', Validators.required],
    });

  }

  loadUserTypes(){
    this.usersService
    .getUserTypes()
    .subscribe(
      (res: BaseResponse<any>) => {
        let response = res.messageRS as any as UserType[];
        this.userTypes = response;
      },
      (err: any) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  loadOperations(): void {
    this.branchService
      .getBranchOperations()
      .subscribe(
        (res: BaseResponse<BranchOperation[]>) => {
          let response = res.messageRS as any as BranchOperation[];
          this.operations = response;          
        },
        (err) => {
          this.logoutDialogService.openLogoutDialog(err);
        }
      );
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  create() {
    this.loading = true;
    let operationId = this.firstFormGroup.get('operationId')!.value.operationId;
    let origin = this.firstFormGroup.get('origin')?.value.id;
    let destination = this.firstFormGroup.get('destination')?.value.id;

    let role: BranchOperationRole = {
      operationId, originUserType: origin, destinationUserType: destination
    }

    this.loading = true;
    this.branchService.createBranchOperationRole(role).subscribe({
      next: (res: BaseResponse<any>) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit(res.statusRS?.code as string);
          }
          this.loading = false
        }, 200);
      }, error: (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    })
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class ShiftsService {
    readonly rootURL = environment.apiUrl;
    constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private baseService: BaseService
  ) {}

  getShifts(paramName: string, paramValue: string, agentId: string) {
    var params = new HttpParams();
    params = params.set(paramName, paramValue);
    params = params.set('agentId', agentId);
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: params,
    };
      return this.http.get(
        this.rootURL + 'RSAdaptorFE/api/v1/paycorr/shifts/shift/0/get-shifts',
        HTTP_OPTIONS
      );
    } 

  getCountries(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
      return this.http.get(
        this.rootURL + 'RSAdaptorFE/api/v1/paycorr/shifts/country/0/get-countries',
        HTTP_OPTIONS
      );
  }
  
}

import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const LAST_LOG_IN = 'last_log_in';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}
  getToken(): string {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return accessToken ? accessToken : '';
  }

  getRefreshToken(): string {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    return refreshToken ? refreshToken : '';
  }

  saveToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  decodeToken(token: string): string {
    return jwt_decode(token);
  }

  getPartyId(): string {
    let partyId = '';
    if (this.validToken()) {
      const token = this.getToken();
      const decoded = this.decodeToken(token);
      const jsonDecoded = JSON.parse(JSON.stringify(decoded));
      partyId = jsonDecoded.ptyid;
    }
    return partyId;
  }

  saveLastLogInDate(date: string): void {
    localStorage.setItem(LAST_LOG_IN, date);
  }

  getLastLogInDate(): string {
    const logInDate = localStorage.getItem(LAST_LOG_IN);
    let array;
    if (logInDate != null) {
      array = logInDate.split('T');
    }
    return array ? array[0]! + ' ' + array[1]! : '';
  }

  getUserId(): string {
    let userId = '';
    if (this.validToken()) {
      const token = this.getToken();
      const decoded = this.decodeToken(token);
      const jsonDecoded = JSON.parse(JSON.stringify(decoded));
      userId = jsonDecoded.sub;
    }
    return userId;
  }

  validToken(): boolean {
    let hasToken = false;
    // TODO VALIDATE TOKEN IS A JWT
    if (this.getToken()) {
      hasToken = true;
    }
    if (hasToken) {
      const decoded = this.decodeToken(this.getToken());
      const now = new Date();
      const date = new Date(JSON.parse(JSON.stringify(decoded)).exp * 1000);
      hasToken = date > now;
    }
    return hasToken;
  }

  isAdmin(extendedOutput: any): number {
    const extendedOutputJson = JSON.parse(extendedOutput);
    let response = 0;
    const userInfo = extendedOutputJson.messageRS?.userInfo;
    const userType = userInfo.userType;

    if(userType){
      console.log("userType",userType);
      if (userType === 'SettingsAdmin') {
        response = 0; //ok
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      } else {
        response = 1; //user is not admin
      }
    } else {
      response = 2;//extended output error
    }
    
    return response;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { PostingScheme } from 'src/app/models/posting-schemes/PostingScheme.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreatePostingSchemeComponent } from './create-posting-scheme/create-posting-scheme.component';
import { Action, Column, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-posting-schemes',
  templateUrl: './posting-schemes.component.html',
  styleUrls: ['./posting-schemes.component.css'],
  host: {
    class: 'component',
  },
})
export class PostingSchemesComponent implements OnInit {
  loading = true;
  
  constructor(
    public postingSchemesService: PostingSchemesService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.loadPostingSchemes();
  }

  data : TableData[] = []
  loadPostingSchemes(): void {
    this.loading = true;
    this.postingSchemesService.getPostingSchemes().subscribe({
      next: (res: BaseResponse) => {
        this.data = res.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  }


  emptyState: EmptyState = {
    image: 'assets/img/esquema-posteos.png',
    message: this.loc.postingSchemes.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      }
    ]
  }

  columns : Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'postingSchemeId',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
      sortable: true,
    }
  ]


  paginatorInfo = this.loc.postingSchemes;

  filters :TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'postingSchemeId',
      placeholder: this.loc.tableHeaders.id,
      type: 'text'
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      placeholder: this.loc.tableHeaders.description,
      type: 'text'
    }
  ]


  actions : Action[]  = [
    {
      icon: 'edit',
      action: (element: any) => this.edit(element),
      tooltip: this.loc.postingSchemes.edit,
    },
    {
      icon: 'delete',
      action: (element: any) => this.delete(element),
      tooltip: this.loc.postingSchemes.delete,
    }
  ]

  createButton = { 
    tooltip: this.loc.postingSchemes.create,
    action: () => this.create()
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreatePostingSchemeComponent,
      {},
      'large'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.extensionPointsFields)
        );
        this.loadPostingSchemes();
      }
    });
  }

  edit(element: PostingScheme) {
    let data: EditModalData = {
      title: this.loc.postingSchemes.edit,
      icon: 'signpost',
      fields: [
        { label: this.loc.tableHeaders.description, 
          value: element.description!!, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== 'not updated' && result) {
        this.loading = true;
        this.editAction(element, result)
      }
    });
  }

  editAction(row: any, result: any){
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
    this.postingSchemesService
    .editPostingScheme(row.postingSchemeId!, newDescription)
    .subscribe({
      next: (res: BaseResponse) => {
        this.loading = false;
        if(res.statusRS?.code === '0'){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('update', this.loc.postingSchemes)
          );
          this.loadPostingSchemes();
        }else{
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.postingSchemes)
          );
        }
      },
      error: (err) => {
        this.loading = false;
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.postingSchemes)
        );
        this.logoutDialogService.openLogoutDialog(err);
      },
    });

  }

  delete(element: any) {
    let dataModal: DeleteModalData = {
      title: this.loc.postingSchemes.delete,
      icon: 'signpost',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.postingSchemes),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      dataModal,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.postingSchemesService
          .deletePostingScheme(element.postingSchemeId)
          .subscribe(
            (res: BaseResponse) => {
              if (res.statusRS?.code === '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.postingSchemes)
                );
              } else if (res.statusRS?.code === '-91382') {
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.postingSchemeUsed
                );
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.postingSchemes)
                );
              }

              this.loadPostingSchemes();
            },
            (err) => {
              this.logoutDialogService.openLogoutDialog(err);
            }
          );
      }
    });
  }
}

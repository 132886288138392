import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { UsersService } from 'src/app/services/users/users.service';
import { Person } from 'src/app/models/Person/person.model';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { ImageUploaderService } from 'src/app/services/image-uploader/image-uploader.service';
import { MatStepper } from '@angular/material/stepper';
import { ShiftsService } from 'src/app/services/shifts/shifts.service';
import { Country } from 'src/app/models/country/country.model';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CountriesService } from 'src/app/services/countries/countries.service';


declare var require: any;
const sha1 = require('sha1');

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css'],
  host: {
    class: 'dialog-component',
  },
})
export class CreateUserComponent implements OnInit {
  loading: boolean = false;

  firstFormGroup!: UntypedFormGroup;
  secondFormGroup!: UntypedFormGroup;
  thirdFormGroup!: UntypedFormGroup;

  person: Person = new Person();
  selectedFile: File = new File([], '');
  imageProfile?: string;

  countries: Country[] = [];
  idTypes: any[] = [];

  managers: any = [this.loc.tableHeaders.managerBasic, this.loc.tableHeaders.managerAdvanced];

  cloudinary = {
     config: environment.cloudinary,
  };

  constructor(
    public dialogRef: MatDialogRef<CreateUserComponent>,
    public loc: LocalizationService,
    private formBuilder: UntypedFormBuilder,
    public userService: UsersService,
    private imageUploaderService: ImageUploaderService,
    private shiftsService: ShiftsService,
    public snackbarService: SnackbarService,
    private countriesService: CountriesService,

  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.firstFormGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      cellphone: ['', Validators.required],
      email: ['', Validators.required],
      nationality: ['', Validators.required],
      managerType: ['', Validators.required],
    });

    this.secondFormGroup = this.formBuilder.group({
      identificationType: ['', Validators.required],
      identificationNumber: ['', Validators.required],
      country: ['', Validators.required],
    });

    this.thirdFormGroup = this.formBuilder.group({
      imageProfile: [''],
      userAccount: ['', Validators.required],
    });
    this.loadCountries();
    this.loadIdTypes();
    this.setCombos();
  }

  createUser() {
    this.loading = true;
    this.person.identification.identificationType = this.secondFormGroup.get('identificationType')?.value;
    this.person.identification.identificationNumber = this.secondFormGroup.get('identificationNumber')?.value;
    this.person.identification.country = this.secondFormGroup.get('country')?.value.countryId;
    if(this.firstFormGroup.get('managerType')?.value == 'Advanced manager' || this.firstFormGroup.get('managerType')?.value == 'Manager avanzado'){
      this.person.user.userType = 'ManagerAdvanced'
    } else {
      this.person.user.userType = 'ManagerBasic'
    }
    this.person.user.userAccount = this.thirdFormGroup.get('userAccount')?.value
    this.person.firstName = this.firstFormGroup.get('firstName')?.value;
    this.person.lastName = this.firstFormGroup.get('lastName')?.value;
    this.person.cellphone = '+' + this.firstFormGroup.get('cellphone')?.value;
    this.person.email = this.firstFormGroup.get('email')?.value;
    this.person.nationality = this.firstFormGroup.get('nationality')?.value.countryId;
    this.userService.createManager(this.person).subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          setTimeout(() => {
            this.loading = false;
            this.exit('created');
          }, 200);
        } else if(res.statusRS?.code === '-1409') {
          this.snackbarService.openErrorSnackbar(this.loc.errors.userAlreadyExists);
          this.dialogRef.close();
        } else {
          this.exit('error');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  exit(message: any): void {
    this.dialogRef.close(message);
  }

  onFileSelect($event: any): void {
    this.selectedFile = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = (_event) => {
      this.imageProfile = reader.result?.toString();
    };
  }

  saveImg(): void {
    this.loading = true;
    console.log(this.selectedFile.name)
    if(this.selectedFile.name !== ''){
      let identificationNumber =  this.secondFormGroup.get('identificationNumber')?.value;
      this.imageUploaderService.uploadImageToCloudinary(this.selectedFile, identificationNumber)?.subscribe((response: any) => {
        if(response) {
          this.imageProfile = response.secure_url;
          this.person.user!.imageProfile = response.secure_url;
          this.createUser();
        }
      });
    } else {
      this.person.user!.imageProfile = '';
      this.createUser();
    }       
  }

  toVerify(stepper: MatStepper) {
    this.setupVerify();
    stepper.next();
  }

  setupVerify() {
    this.person.firstName = this.firstFormGroup.get('firstName')?.value;
    this.person.lastName = this.firstFormGroup.get('lastName')?.value;
    this.person.secundSurname = '';
    this.person.dateOfBirth = '';
    this.person.cellphone =
      '+' + this.firstFormGroup.get('cellphone')?.value;
    this.person.phone = '';
    this.person.alternativePhone = '';
    this.person.email = this.firstFormGroup.get('email')?.value;
    this.person.nationality =
      this.firstFormGroup.get('nationality')?.value.countryId;
    this.person.profession = '';
    this.person.externalId = '';
    this.person.maritalStatus = '';
    this.person.identification.identificationType =
      this.secondFormGroup.get('identificationType')?.value;
    this.person.identification.identificationNumber =
      this.secondFormGroup.get('identificationNumber')?.value;
    this.person.identification.country =
      this.secondFormGroup.get('country')?.value.countryId;
    this.person.user.userAccount =
      this.thirdFormGroup.get('userAccount')?.value;
    this.person.user.imageProfile = this.imageProfile
      ? this.imageProfile
      : '';
  }

  loadCountries(): void {
    this.shiftsService.getCountries().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code == '0') {
          this.countries = res.messageRS as unknown as Array<Country>;
          for(let c of this.countries){
          if(c.image === ''){
            c.image = this.getMissingCountryFlag(c.countryName, 'svg');
          }
        }
        } else {
          this.countries = [];
        }
        this.loading = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setCombos(): void {  
    this.secondFormGroup.get("country")?.valueChanges.subscribe((value) => {            
      this.loadIdTypes();      
    });
  }

  loadIdTypes() {
    this.userService.getIdTypes(this.secondFormGroup.get("country")?.value?.countryId).subscribe(
      (res: BaseResponse) => {
        this.idTypes = res.messageRS as unknown as Array<any>;
        this.loading = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getMissingCountryFlag(countryNameOrId: string, type: string) {
    return this.countriesService.getMissingCountryFlag(countryNameOrId, type);
  }

  getSelectedCountryAltFlag(): string {
    const selectedCountry = this.firstFormGroup.get('calendarCountry')?.value;
    if (selectedCountry) {
      return this.getMissingCountryFlag(selectedCountry.countryId, 'png');
    }
    return '';
  }

  
  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
  
}

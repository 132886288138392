<div class="general-operations-div">
    <div style="display: flex;">
      <button mat-icon-button class="back" safeClick (onSafeClick)="goBack()"  >
        <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>
      <ng-container *ngIf="!loading">
        <button mat-fab class="page-icon-button blue-button-icon">
          <mat-icon *ngIf="imageProfile === ''" fontSet="material-icons-outlined" class="action-button-icon">extension</mat-icon>
          <img  class="image" *ngIf="imageProfile != ''" [src]="imageProfile" alt="img_user" class="image" (error)="imageProfile = '' ">
        </button>
      </ng-container>
      <ng-container *ngIf="loading" >
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
            background: 'no-repeat rgb(227 228 230 / 33%)',
            height: '60px',
            width: '60px'
          }"></ngx-skeleton-loader>
      </ng-container>
  
      <ng-container *ngIf="loading">
        <div class="title-container">
        <ngx-skeleton-loader count="1" [theme]="{
            background: 'no-repeat rgb(227 228 230 / 33%)',
            height: '25px',
            width: '150px'
          }"></ngx-skeleton-loader>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading">
      <div class="title-container">
        <label class="title-mid-text">{{ integration.description }}</label>
        <label class="id-text">
          {{ integration.integrationId }}
        </label>
      </div>
      </ng-container>
    </div>

    <div *ngIf="!loading" class="general-operations-buttons-div">
      <button mat-fab class="button green-color" (click)="edit()" i18n-matTooltip [matTooltip]="loc.integrations.edit">
        <mat-icon fontSet="material-icons-outlined"> edit </mat-icon>
      </button>
      <button mat-fab class="button green-color" style="margin-right: 0;" (click)="delete()" i18n-matTooltip [matTooltip]="loc.integrations.delete">
        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
      </button>
    </div>
</div>



  <mat-card class="details-card" >
    <div class="flex-container">
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{loc.detailsHeaders.type}}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ integration.integrationTypeDesc }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{loc.detailsHeaders.status}}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ translateStatus(integration.status) }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="flex-container-vertical">
        <div>
          <label class="card-header" i18n>{{loc.detailsHeaders.creationDate}}</label>
        </div>
        <div>
          <ng-container *ngIf="!loading">
            <label class="card-value">
              {{ formatDate(integration.creationDate) }}
            </label>
          </ng-container>
          <div *ngIf="loading">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                background: 'no-repeat rgb(227 228 230 / 33%)',
                height: '18px',
                width: '150px'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
<mat-tab-group class="tab-bar">
    <mat-tab [label]="loc.tabNames.parameters" i18n-label>
        <app-list-parameters class="component" [parameters]="parameters" [loading] = "loading">  </app-list-parameters>
    </mat-tab>
</mat-tab-group>
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PaymentButtonFields } from 'src/app/models/payment-buttons/paymentButtonFields.model';
import { PaymentButtonTypes } from 'src/app/models/payment-buttons/paymentButtonTypes.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { PaymentButtonService } from 'src/app/services/payment-button/payment-button.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreatePaymentButtonFieldsComponent } from '../../payment-button-fields/create-payment-button-fields/create-payment-button-fields.component';

@Component({
  selector: 'app-create-payment-button-type',
  templateUrl: './create-payment-button-type.component.html',
  styleUrls: ['./create-payment-button-type.component.css'],
  host:{
    class: 'dialog-component',
  }
})
export class CreatePaymentButtonTypeComponent implements OnInit {

  loading = true;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  actions: any[] = [];
  stageId = '';

  constructor(
    public dialogRef: MatDialogRef<CreatePaymentButtonFieldsComponent>,
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private paymentButtonService: PaymentButtonService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      buttonTypeId: ['', Validators.required],
      buttonDescription: ['', Validators.required],
    });
    this.loading = false;
  }
  create() {
    this.loading = true;

    let paymentButtonType: PaymentButtonTypes = {
      id: this.firstFormGroup.get('buttonTypeId')!.value,
      description: this.firstFormGroup.get('buttonDescription')!.value,
    };

    this.paymentButtonService.createType(paymentButtonType).subscribe({
      next: (res: BaseResponse<PaymentButtonFields>) => {
        this.loading = false;
        switch (res.statusRS?.code) {
          case '0':
            this.exit('created');
            break;
          case '-91774':
            this.exit('duplicate');
            break;
          default:
            this.exit('error');
            break;
        }
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      },
    });
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }
  emptyField(text: string | undefined): string {
    if (text === '' || text === undefined) return '-';
    return text;
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../services/localization/localization.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css'],
  host: {
    class: 'component'
  }
})
export class TransactionsComponent implements OnInit {

  selectedIndex = 0;

  constructor(
    public loc: LocalizationService
  ) { }

  ngOnInit(): void {
    let state = JSON.parse(localStorage.getItem('state')!);
    this.selectedIndex = parseInt((state?.transactionTabs as string)) || 0;
  }

  onTabChange(){
    let state = JSON.parse(localStorage.getItem('state')! as string);
    if(!state.transactionTabs){
      state.transactionTabs = {};
    }
    state.transactionTabs = this.selectedIndex;
    localStorage.setItem('state', JSON.stringify(state));
  }

}

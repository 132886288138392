import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IntegrationType } from 'src/app/models/integration/integrationType.model';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-integration-type',
  templateUrl: './create-integration-type.component.html',
  styleUrls: ['./create-integration-type.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class CreateIntegrationTypeComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  id = '';
  description = '';
  integrationTypes: IntegrationType[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateIntegrationTypeComponent>,
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private _integrationService: IntegrationsService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      id: ['', [Validators.required, Validators.pattern('^[A-ZÀ-Ÿ_]+$')]],
      description: ['', Validators.required],
    });
    this.listIntegrationsTypes();
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

  next(): void  {
    this.id = this.firstFormGroup.get('id')!!.value.id;
    this.description = this.firstFormGroup.get('description')!!.value;
  }

  createIntegrationType(): void {
    this.loading = true;
    let id = this.firstFormGroup.get('id')!.value;
    let description = this.firstFormGroup.get('description')!.value;
    this._integrationService.
      createIntegrationType(id, description).subscribe(
        (res: BaseResponse) => {
          setTimeout(() => {
            this.loading = false;
            if(res.statusRS?.code === '0'){
              this.exit('created');
            } else if(res.statusRS?.code === '-91924'){
              this.snackbarService.openErrorSnackbar(this.loc.errors.integrationTypeIdAlreadyExists)
            }
            else {
              this.exit(res.statusRS?.code as string);
            }
          }, 200);
          },
          (err) => {
            setTimeout(() => {
              this.loading = false;
            }, 200);
            console.log(err);
            if(err.status !== 401) {
              this.exit('error');
            }
          }
      );
  }


  listIntegrationsTypes(): void {
    this._integrationService.getIntegrationTypes().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.integrationTypes = res.messageRS as unknown as Array<any>;
        } else {
          //TODO HANDLE ERROR
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  
  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { CreateModalData, Step, VerifyGroup } from 'src/app/models/stepper/step-modal';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { StepsService } from 'src/app/services/steps/steps.service';

@Component({
  selector: 'app-create-api-response',
  templateUrl: './create-api-response.component.html',
  styleUrls: ['./create-api-response.component.css'],
  host: {
    class: 'dialog-component'
  }
})

export class CreateApiResponseComponent implements OnInit {
  loading = false;
  @Input() stepId = '';
  @Input() apiId = '';
  @Input() outputId = '';
 
  steps: Step[] = [
    {
      title: this.loc.stepTitle.basicData,
      icon: 'dynamic_feed',
      type: 'form',
      form: [
        {
          key: 'field',
          type: 'text',
          label: this.loc.fields.field,
          validators: [Validators.required],
        },
        {
          key: 'fieldPath',
          type: 'text',
          label: this.loc.fields.fieldPath,
          validators: [Validators.required],
        },
        {
          key: 'iterationPath',
          type: 'text',
          label: this.loc.fields.iterationPath,
          validators: [Validators.required],
        },
      ],
    },
  ];

  verify: VerifyGroup[] = [
    {
      title: this.loc.stepTitle.basicData,
      type: 'form',
      elements: ['field', 'fieldPath', 'iterationPath'],
      descriptions: [this.loc.fields.field, this.loc.fields.fieldPath, this.loc.fields.iterationPath]
    },
  ];

  modalData: CreateModalData = {
    title: this.loc.apiResponseMap.create,
    icon: 'psychology',
    steps: this.steps,
    verify: this.verify,
    size: 'small',
  }
  
  constructor(
    public loc: LocalizationService,
    public dialogRef: MatDialogRef<CreateApiResponseComponent>,
    public stepsService: StepsService,
    public snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public info: any,

  ) { }

  ngOnInit(): void {
    this.stepId = this.info.stepId
    this.apiId = this.info.apiId
    this.outputId = this.info.outputId
  }

  save(verifiedItemsDictionary: { [key: string]: any }) {
    this.setupVerify(verifiedItemsDictionary);
  }

  setupVerify(verifiedItemsDictionary: { [key: string]: any }) {
    this.loading = true;
    let data: any =  {
      field: verifiedItemsDictionary['field'],
      fieldPath:  verifiedItemsDictionary['fieldPath'],
      iterationPath:  verifiedItemsDictionary['iterationPath'],
    }    
    this.stepsService.createApiResponseMapStepMetadataOut(this.stepId, this.apiId, this.outputId, data.field, data.fieldPath, data.iterationPath).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('create', this.loc.apiResponseMap, 'f')
            );
            this.exit('created');
          } else if(res.statusRS?.code == '-92012'){
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.newFieldIdIsBeingUsed
            );
            this.exit('error');
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('create', this.loc.apiResponseMap)
            );
            this.exit('error');
          }
          this.loading = false;
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (err.statusCode !== 401) {
          this.loading = false;
          console.log(err)
          this.exit('error');
        }
      }
    )
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

}

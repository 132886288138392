import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormatterService {
  constructor() {}

  formatDate(date: string | Date, format: string): string {
    const locale = 'en-US';
    var dateFormated = formatDate(date, format, locale);
    return dateFormated;
  }
}

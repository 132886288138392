import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { UserPreviewComponent } from './shared/user-preview/user-preview.component';
import { LoginComponent } from './login/login/login.component';
import { StepperDirective } from './directives/stepper.directive';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormsModule } from '@angular/forms';
import { MainComponent } from './main/main.component';
import { AgentComponent } from './agent/agent.component';
import { CountriesComponent } from './countries/countries.component';
import { BanksComponent } from './banks/banks.component';
import { NotificationssComponent } from './notificationss/notificationss.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { CalendarsComponent } from './calendars/calendars.component';
import { UsersComponent } from './users/users.component';
import { LogoutConfirmationComponent } from './logout-confirmation/logout-confirmation.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ExtensionPointsComponent } from './extension-points/extension-points.component';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { ActionDetailsComponent } from './extension-points/action-details/action-details.component';
import { ExtensionPointsListComponent } from './extension-points/extension-points-list/extension-points-list.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { CreateExtensionPointComponent } from './extension-points/create-extension-point/create-extension-point.component';
import { DeleteModalComponent } from './utils/delete-modal/delete-modal.component';
import { CreateActionComponent } from './extension-points/create-action/create-action.component';
import { ListFieldsComponent } from './extension-points/list-fields/list-fields.component';
import { CreateFieldComponent } from './extension-points/create-field/create-field.component';
import { AssociateFieldsToActionComponent } from './extension-points/associate-fields-to-action/associate-fields-to-action.component';
import { ListApplicationsComponent } from './notificationss/applications/list-applications/list-applications.component';
import { CreateApplicationComponent } from './notificationss/applications/create-application/create-application.component';

import { AgentTypesListComponent } from './agent/agent-types-list/agent-types-list.component';
import { ListPlatformsComponent } from './notificationss/platforms/list-platforms/list-platforms.component';
import { CreatePlatformComponent } from './notificationss/platforms/create-platform/create-platform.component';
import { ListEntitiesComponent } from './notificationss/entities/list-entities/list-entities.component';
import { CreateEntityComponent } from './notificationss/entities/create-entity/create-entity.component';
import { ListTypesComponent } from './notificationss/types/list-types/list-types.component';
import { CreateTypeComponent } from './notificationss/types/create-type/create-type.component';
import { AddFlowComponent } from './extension-points/add-flow/add-flow.component';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { TransactionTemplatesComponent } from './transaction-templates/transaction-templates.component';
import { PostingSchemesComponent } from './transactions/posting-schemes/posting-schemes.component';
import { EditModalComponent } from './utils/edit-modal/edit-modal.component';
import { PostingComponent } from './transactions/posting/posting.component';
import { CreatePostingSchemeComponent } from './transactions/posting-schemes/create-posting-scheme/create-posting-scheme.component';
import { CreatePostingComponent } from './transactions/posting/create-posting/create-posting.component';
import { DisaggregationComponent } from './transactions/disaggregation/disaggregation.component';
import { CreateDisaggregationComponent } from './transactions/disaggregation/create-disaggregation/create-disaggregation.component';
import { DisaggregationDetailsComponent } from './transactions/disaggregation/disaggregation-details/disaggregation-details.component';
import { ListDisaggregationStepsComponent } from './transactions/disaggregation/list-disaggregation-steps/list-disaggregation-steps.component';
import { CreateDisaggregationStepComponent } from './transactions/disaggregation/create-disaggregation-step/create-disaggregation-step.component';
import { PostingDetailsComponent } from './transactions/posting/posting-details/posting-details.component';
import { ListPostingStepsComponent } from './transactions/posting/list-posting-steps/list-posting-steps.component';
import { CreatePostingStepComponent } from './transactions/posting/create-posting-step/create-posting-step.component';
import { CreatePostingGroupComponent } from './transactions/posting/create-posting-group/create-posting-group.component';
import { ListPostingStepTemplatesComponent } from './transaction-templates/posting/list-posting-step-templates/list-posting-step-templates.component';
import { ListDisaggregationStepTemplatesComponent } from './transaction-templates/disaggregation/list-disaggregation-step-templates/list-disaggregation-step-templates.component';
import { CreateDisaggregationStepTemplateComponent } from './transaction-templates/disaggregation/create-disaggregation-step-template/create-disaggregation-step-template.component';
import { CreatePostingStepTemplateComponent } from './transaction-templates/posting/create-posting-step-template/create-posting-step-template.component';
import { CreateBankComponent } from './banks/create-bank/create-bank/create-bank.component';
import { EditBankComponent } from './banks/edit-bank/edit-bank.component';
import { BankDetailsComponent } from './banks/bank-details/bank-details/bank-details.component';
import { AccountIdsComponent } from './agent/account-ids/account-ids.component';
import { CreateAccountIdComponent } from './agent/create-account-id/create-account-id.component';
import { AddCountryComponent } from './countries/add-country/add-country.component';
import { CountryDetailsComponent } from './countries/country-details/country-details.component';
import { CountryIdentificationTypesComponent } from './countries/country-identification-types/country-identification-types.component';
import { CountryTaxesComponent } from './countries/country-taxes/country-taxes.component';
import { CreateTaxComponent } from './countries/country-taxes/create-tax/create-tax.component';
import { CreateIdentificationTypeComponent } from './countries/country-identification-types/create-identification-type/create-identification-type.component';
import { CountryCurrencyComponent } from './countries/country-currency/country-currency.component';
import { AddCountryCurrencyComponent } from './countries/country-currency/add-country-currency/add-country-currency.component';
import { PaymentButtonComponent } from './payment-button/payment-button.component';
import { PaymentButtonFieldsComponent } from './payment-button/payment-button-fields/payment-button-fields.component';
import { CreatePaymentButtonFieldsComponent } from './payment-button/payment-button-fields/create-payment-button-fields/create-payment-button-fields.component';
import { PaymentButtonTypesComponent } from './payment-button/payment-button-types/payment-button-types.component';
import { CreatePaymentButtonTypeFieldsComponent } from './payment-button/payment-button-type-field/create-payment-button-type-fields/create-payment-button-type-fields.component';
import { PaymentButtonDetailsComponent } from './payment-button/payment-button-details/payment-button-details.component';
import { PaymentButtonTypeFieldsComponent } from './payment-button/payment-button-type-field/payment-button-type-fields.component';
import { CreatePaymentButtonTypeComponent } from './payment-button/payment-button-types/create-payment-button-type/create-payment-button-type.component';
import { OperationGroupsComponent } from './transactions/operation-groups/operation-groups.component';
import { CreateOperationGroupComponent } from './transactions/operation-groups/create-operation-group/create-operation-group.component';
import { AdjustmentReasonsComponent } from './transactions/adjustment-reasons/adjustment-reasons.component';
import { CreateAdjustmentReasonComponent } from './transactions/adjustment-reasons/create-adjustment-reason/create-adjustment-reason.component';
import { DigitalServicesComponent } from './digital-services/digital-services.component';
import { AttributeGroupAllowedListComponent } from './digital-services/attribute-group-allowed-list/attribute-group-allowed-list.component';
import { CreateAttributeGroupAllowedComponent } from './digital-services/create-attribute-group-allowed/create-attribute-group-allowed.component';
import { AttributeGroupAllowedDetailsComponent } from './digital-services/attribute-group-allowed-details/attribute-group-allowed-details.component';
import { AttributeAllowedListComponent } from './digital-services/attribute-allowed-list/attribute-allowed-list.component';
import { UpdateAttributesAllowedComponent } from './digital-services/update-attributes-allowed/update-attributes-allowed.component';
import { ListCalendarsComponent } from './calendars/list-calendars/list-calendars.component';
import { CreateCalendarComponent } from './calendars/create-calendar/create-calendar.component';
import { CalendarDetailsComponent } from './calendars/calendar-details/calendar-details.component';
import { NonWorkingDaysComponent } from './calendars/non-working-days/non-working-days.component';
import { CreateNonWorkingDayComponent } from './calendars/non-working-days/create-non-working-day/create-non-working-day.component';
import { WorkingDaysComponent } from './calendars/working-days/working-days.component';
import { CurrencyComponent } from './currency/currency.component';
import { CreateCurrencyComponent } from './currency/create-currency/create-currency.component';
import { BranchComponent } from './branch/branch.component';
import { ListBranchOperationsComponent } from './branch/operations/list-branch-operations/list-branch-operations.component';
import { CreateBranchComponent } from './branch/operations/create-branch/create-branch.component';
import { ListUserTypesComponent } from './users/list-user-types/list-user-types.component';
import { CreateUserTypeComponent } from './users/create-user-type/create-user-type.component';
import { OperationRoleComponent } from './branch/operation-role/operation-role.component';
import { CreateOperationRoleComponent } from './branch/operation-role/create-operation-role/create-operation-role.component';
import { ListServiceModesComponent } from './digital-services/list-service-modes/list-service-modes.component';
import { ServiceModeDetailsComponent } from './digital-services/service-mode-details/service-mode-details.component';
import { DetailsCardComponent } from './details-card/details-card.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { CreateServiceModeComponent } from './digital-services/create-service-mode/create-service-mode.component';
import { AssociateServiceModeAttributeGroupComponent } from './digital-services/associate-service-mode-attribute-group/associate-service-mode-attribute-group.component';
import { CurrencyDetailsComponent } from './currency/currency-details/currency-details.component';
import { DenominationsListComponent } from './denominations/denominations-list/denominations-list.component';
import { CreateDenominationComponent } from './denominations/create-denomination/create-denomination.component';

import { IntegrationsComponent } from './integrations/integrations.component';
import { CreateIntegrationComponent } from './integrations/create-integration/create-integration.component';
import { IntegrationDetailsComponent } from './integrations/integration-details/integration-details.component';
import { CreateParameterComponent } from './integrations/create-parameter/create-parameter.component';
import { ListIntegrationsComponent } from './integrations/list-integrations/list-integrations.component';
import { ListParametersComponent } from './integrations/list-parameters/list-parameters.component';
import { ClickThrottleDirective } from './directives/click-throttle.directive';

import { CreateCampaignComponent } from './campaigns/create-campaign/create-campaign.component';
import { ListCampaignsComponent } from './campaigns/list-campaigns/list-campaigns.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { LoadingTableComponent } from './utils/loading-table/loading-table.component';
import { TableComponent } from './utils/table/table.component';
import { SafeHtmlPipe } from './pipes/safeHTML';
import { ListManagersComponent } from './users/list-managers/list-managers.component';
import { DigitalServiceOperationMediumsComponent } from './digital-services/digital-service-operation-mediums/digital-service-operation-mediums.component';
import { CreateDigitalServiceOperationMediumComponent } from './digital-services/digital-service-operation-mediums/create-digital-service-operation-medium/create-digital-service-operation-medium.component';
import { ListIntegrationTypesComponent } from './integrations/list-integration-types/list-integration-types.component';
import { CreateIntegrationTypeComponent } from './integrations/create-integration-type/create-integration-type.component';
import { EditIntegrationComponent } from './integrations/edit-integration/edit-integration.component';
import { Cloudinary as CloudinaryCore } from 'cloudinary-core';
import { IntegrationTypeDetailsComponent } from './integrations/integration-type-details/integration-type-details.component';
import { ListTypeParametersComponent } from './integrations/list-type-parameters/list-type-parameters.component';
import { CreateTypeParameterComponent } from './integrations/create-type-parameter/create-type-parameter.component';

export const cloudinary = {
  Cloudinary: CloudinaryCore,
};
import { AgentTypeDetailsComponent } from './agent/agent-type-details/agent-type-details.component';
import { ListTemplatesComponent } from './agent/list-templates/list-templates.component';
import { CreateTemplateComponent } from './agent/create-template/create-template.component';
import { EditTemplateComponent } from './agent/edit-template/edit-template.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';

import { NgOtpInputModule } from 'ng-otp-input';
import { SettlementModesComponent } from './digital-services/settlement-modes/settlement-modes.component';
import { CreateSettlementModeComponent } from './digital-services/settlement-modes/create-settlement-mode/create-settlement-mode.component';
import { CreateModalComponent } from './utils/create-modal/create-modal.component';
import { HorizontalStepperComponent } from './utils/stepper/horizontal-stepper/horizontal-stepper.component';
import { FormStepComponent } from './utils/stepper/step/form-step/form-step.component';
import { VerifyStepComponent } from './utils/stepper/step/verify-step/verify-step.component';
import { ListStepComponent } from './utils/stepper/step/list-step/list-step.component';
import { StyleStepComponent } from './utils/stepper/step/style-step/style-step.component';
import { ApisComponent } from './apis/apis.component';
import { CreateApiComponent } from './apis/create-api/create-api/create-api.component';
import { UpdateApiComponent } from './apis/update-api/update-api/update-api.component';

import { ApiDetailsComponent } from './apis/api-details/api-details/api-details.component';

import { ShowStructureComponent } from './apis/showStructure/show-structure/show-structure.component';
import { MetadataComponent } from './metadata/metadata.component';
import { ListMetadataFieldsComponent } from './metadata/metadata-fields/list-metadata-fields/list-metadata-fields.component';
import { ListMetadataTypesComponent } from './metadata/metadata-types/list-metadata-types/list-metadata-types.component';
import { CreateMetadataTypeComponent } from './metadata/metadata-types/create-metadata-type/create-metadata-type.component';
import { CreateMetadataFieldComponent } from './metadata/metadata-fields/create-metadata-field/create-metadata-field.component';
import { ListStepsComponent } from './metadata/steps/list-steps/list-steps.component';
import { StepDetailsComponent } from './metadata/steps/steps-details/step-details/step-details.component';
import { InputOutputMetadataComponent } from './metadata/steps/steps-details/input-output-metadata/input-output-metadata.component';
import { CreateInputOutputMetadataComponent } from './metadata/steps/steps-details/create-input-output-metadata/create-input-output-metadata.component';
import { CreateStepComponent } from './metadata/steps/create-step/create-step.component';
import { ActivateModalComponent } from './utils/activate-modal/activate-modal.component';
import { EditStepComponent } from './metadata/edit-step/edit-step.component';
import { MapOutputMetadataComponent } from './metadata/steps/steps-details/map-output-metadata/map-output-metadata.component';
import { ListMappedApiResponsesComponent } from './metadata/steps/steps-details/list-mapped-api-responses/list-mapped-api-responses.component';
import { CreateApiResponseComponent } from './metadata/steps/create-api-response/create-api-response.component';
import { PostingFlowsComponent } from './transactions/posting/posting-flows/posting-flows.component';
import { ServiceTemplatesComponent } from './service-templates/service-templates.component';
import { CreateServiceTemplateComponent } from './service-templates/create-service-template/create-service-template.component';
import { MetadataDetailsComponent } from './metadata/metadata-details/metadata-details.component';
import { ListMetadataDetailsComponent } from './metadata/list-metadata-details/list-metadata-details.component';
import { CreateDataComponent } from './metadata/create-data/create-data.component';
import { MetadataFieldsDetailsComponent } from './metadata/metadata-fields/metadata-fields-details/metadata-fields-details.component';
import { ListMetadataFieldsDatasourceDataComponent } from './metadata/metadata-fields/list-metadata-fields-datasource-data/list-metadata-fields-datasource-data.component';


@NgModule({
  declarations: [
    ListIntegrationTypesComponent,
    AppComponent,
    SidebarComponent,
    UserPreviewComponent,
    LoginComponent,
    ChangePasswordComponent,
    MainComponent,
    AgentComponent,
    CountriesComponent,
    BanksComponent,
    NotificationssComponent,
    TransactionsComponent,
    CalendarsComponent,
    UsersComponent,
    StepperDirective,
    LogoutConfirmationComponent,
    ExtensionPointsComponent,
    ActionDetailsComponent,
    ExtensionPointsListComponent,
    CreateUserComponent,
    CreateExtensionPointComponent,
    DeleteModalComponent,
    CreateActionComponent,
    ListFieldsComponent,
    CreateFieldComponent,
    AssociateFieldsToActionComponent,
    ListApplicationsComponent,
    CreateApplicationComponent,
    AgentTypesListComponent,
    ListPlatformsComponent,
    CreatePlatformComponent,
    ListEntitiesComponent,
    CreateEntityComponent,
    AddFlowComponent,
    ListTypesComponent,
    CreateTypeComponent,
    AddFlowComponent,
    TransactionTemplatesComponent,
    PostingSchemesComponent,
    EditModalComponent,
    PostingComponent,
    CreatePostingSchemeComponent,
    CreatePostingComponent,
    DisaggregationComponent,
    CreateDisaggregationComponent,
    DisaggregationDetailsComponent,
    ListDisaggregationStepsComponent,
    CreateDisaggregationStepComponent,
    PostingDetailsComponent,
    ListPostingStepsComponent,
    CreatePostingStepComponent,
    CreatePostingGroupComponent,
    ListPostingStepTemplatesComponent,
    ListDisaggregationStepTemplatesComponent,
    CreateDisaggregationStepTemplateComponent,
    CreatePostingStepTemplateComponent,
    CreateBankComponent,
    EditBankComponent,
    BankDetailsComponent,
    AccountIdsComponent,
    CreateAccountIdComponent,
    AddCountryComponent,
    CountryDetailsComponent,
    CountryIdentificationTypesComponent,
    CountryTaxesComponent,
    CountryCurrencyComponent,
    AddCountryCurrencyComponent,
    CreateTaxComponent,
    CreateIdentificationTypeComponent,
    PaymentButtonComponent,
    PaymentButtonFieldsComponent,
    CreatePaymentButtonFieldsComponent,
    PaymentButtonTypesComponent,
    PaymentButtonDetailsComponent,
    PaymentButtonTypeFieldsComponent,
    CreatePaymentButtonTypeFieldsComponent,
    CreatePaymentButtonTypeComponent,
    OperationGroupsComponent,
    CreateOperationGroupComponent,
    AdjustmentReasonsComponent,
    CreateAdjustmentReasonComponent,
    DigitalServicesComponent,
    AttributeGroupAllowedListComponent,
    CreateAttributeGroupAllowedComponent,
    AttributeGroupAllowedDetailsComponent,
    AttributeAllowedListComponent,
    UpdateAttributesAllowedComponent,
    ListCalendarsComponent,
    CreateCalendarComponent,
    CalendarDetailsComponent,
    NonWorkingDaysComponent,
    CreateNonWorkingDayComponent,
    WorkingDaysComponent,
    CurrencyComponent,
    CreateCurrencyComponent,
    BranchComponent,
    ListBranchOperationsComponent,
    CreateBranchComponent,
    ListUserTypesComponent,
    CreateUserTypeComponent,
    OperationRoleComponent,
    LoadingTableComponent,
    CreateOperationRoleComponent,
    IntegrationsComponent,
    CreateIntegrationComponent ,
    IntegrationDetailsComponent ,
    CreateParameterComponent ,
    ListIntegrationsComponent ,
    ListParametersComponent,
    ClickThrottleDirective,
    CreateCampaignComponent,
    ListCampaignsComponent,
    CampaignsComponent,
    CreateIntegrationTypeComponent,
    ListServiceModesComponent,
    ServiceModeDetailsComponent,
    DetailsCardComponent,
    PageTitleComponent,
    CreateServiceModeComponent,
    AssociateServiceModeAttributeGroupComponent,
    CurrencyDetailsComponent,
    DenominationsListComponent,
    CreateDenominationComponent,
    TableComponent,
    SafeHtmlPipe,
    ListManagersComponent,
    DigitalServiceOperationMediumsComponent,
    CreateDigitalServiceOperationMediumComponent,
    EditIntegrationComponent,
    IntegrationTypeDetailsComponent,
    ListTypeParametersComponent,
    CreateTypeParameterComponent,
    CreateDigitalServiceOperationMediumComponent,
    AgentTypeDetailsComponent,
    ListTemplatesComponent,
    CreateTemplateComponent,
    EditTemplateComponent,
    ForgotPasswordComponent,
    SettlementModesComponent,
    CreateSettlementModeComponent,
    CreateModalComponent,
    HorizontalStepperComponent,
    FormStepComponent,
    VerifyStepComponent,
    ListStepComponent,
    StyleStepComponent,
    ApisComponent,
    CreateApiComponent,
    UpdateApiComponent,
    ApiDetailsComponent,
    ShowStructureComponent,
    MetadataComponent,
    ListMetadataFieldsComponent,
    ListMetadataTypesComponent,
    CreateMetadataTypeComponent,
    CreateMetadataFieldComponent,
    ListStepsComponent,
    StepDetailsComponent,
    InputOutputMetadataComponent,
    CreateInputOutputMetadataComponent,
    CreateStepComponent,
    ActivateModalComponent,
    EditStepComponent,
    MapOutputMetadataComponent,
    ListMappedApiResponsesComponent,
    CreateApiResponseComponent,
    PostingFlowsComponent,
    ServiceTemplatesComponent,
    CreateServiceTemplateComponent,
    MetadataDetailsComponent,
    ListMetadataDetailsComponent,
    CreateDataComponent,
    MetadataFieldsDetailsComponent,
    ListMetadataFieldsDatasourceDataComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    CloudinaryModule.forRoot(cloudinary, {
      cloud_name: 'paycorrcloud',
      upload_preset: 'paycorrPreset',
      api_key: '997834364654234',
      api_secret: 'lmaDI1EdRdujq6iVYjyNJVxaiw8',
      secure: true,
    }),
    NgOtpInputModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  public openDialog(component: any, data: any, size:"x-small"|"small"|"medium"|"medium-large"|"large", disableClose: boolean = false, pannelClass?: string) {
     switch(size){
       //For logout and delete confirmations, max 1 input forms
        case "x-small":
          return this.dialog.open(component, {
            width: '450px',
            height: '270px',
            panelClass: pannelClass ?? 'confirmation-dialog',
            disableClose: disableClose,
            data: data,
          });
        case "small":
          return this.dialog.open(component, {
            width: '550px',
            height: '360px',
            maxWidth: '550px',
            maxHeight: '360px',
            panelClass: pannelClass??'confirmation-dialog',
            disableClose: disableClose,
            data: data,
          });
        case "medium":
          return this.dialog.open(component, {
            width: '600px',
            height: '500px',
            maxWidth: '600px',
            maxHeight: '500px',
            panelClass: pannelClass?? 'medium-dialog',
            disableClose: disableClose,
            data: data,
          });
        case "medium-large":
          return this.dialog.open(component, {
            width: '580px',
            height: '560px',
            maxWidth: '580px',
            maxHeight: '560px',
            panelClass: pannelClass??'medium-large-dialog',
            disableClose: disableClose,
            data: data,
          }); 
        case "large":
          return this.dialog.open(component, {
            width: '700px',
            height: '90vh',
            maxWidth: '700px',
            maxHeight: '750px',
            panelClass: pannelClass??'large-dialog',
            disableClose: disableClose,
            data: data,
          });
     }
  }

  public openCustomDialog(component:any, data:any, size:[number,number], customClass:string = "") {
    return this.dialog.open(component, {
      width: size[0] + 'px',
      height: size[1] + 'px',
      minWidth: size[0] + 'px',
      minHeight: size[1] + 'px',
      maxWidth: size[0] + 'px',
      maxHeight: size[1] + 'px',
      panelClass: customClass,
      data: data,
    });
  }

}

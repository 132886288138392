import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import {
  Integration,
  IntegrationParameters,
} from 'src/app/models/integration/integration.model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Validators } from '@angular/forms';
import { EditIntegrationComponent } from '../edit-integration/edit-integration.component';

@Component({
  selector: 'app-integration-details',
  templateUrl: './integration-details.component.html',
  styleUrls: ['./integration-details.component.css'],
  host: {
    class: 'component',
  },
})
export class IntegrationDetailsComponent implements OnInit {
  loading = true;
  loadingDataSource = new MatTableDataSource<string>();
  integration: Integration = {
    parameters: [],
  };
  imageProfile?: string;
  integrationDetails: any = {};
  parameters: IntegrationParameters[] = [];
  constructor(
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private dateFormatter: DateFormatterService,
    private integrationsService: IntegrationsService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.integration.integrationId = params.integrationId;
    });
    this.loadingDataSource.data = ['', '', '', ''];
    this.loadDetails();
  }

  loadDetails() {
    this.integrationsService
      .getIntegrationDetails(this.integration.integrationId)
      .subscribe(
        (res: BaseResponse) => {
          if (res.statusRS?.code === '0') {
            this.integrationDetails = res.messageRS as unknown as any;
            this.integration.description = this.integrationDetails.description;
            this.imageProfile = this.integrationDetails.image;
            this.integration.creationDate =
              this.integrationDetails.creationDate;
            this.integration.integrationType =
              this.integrationDetails.integrationType;
            this.integration.integrationTypeDesc =
              this.integrationDetails.integrationTypeDesc;
            this.integration.status = this.integrationDetails.status;
            this.parameters = this.integrationDetails.parameters;
            this.loading = false;
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  goBack(): void {
    this.router.navigate(['/integrations']);
  }

  formatDate(date?: string, format?: string): string {
    if (date) {
      return this.dateFormatter.formatDate(
        date,
        format ? format : 'dd/MM/yyyy'
      );
    } else return '';
  }

  translateStatus(status: any) {
    status === 'ACTIVE'
      ? (status = this.loc.common.active)
      : (status = this.loc.common.inactive);
    return status;
  }

  deleteIntegrationAction(): void {
    this.integrationsService
      .deleteIntegration(this.integration.integrationId!!)
      .subscribe(
        {
          next: (res: BaseResponse) => {
            setTimeout(() => {
              this.loading = false;
              if (res.statusRS?.code === '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.integrations, 'f')
                );
                this.goBack();
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('delete', this.loc.integrations)
                );
              }
            }, 200);
          },
          error: (err) => {
            setTimeout(() => {
              this.loading = false;
            }, 200);
            console.log(err);
            if (err.status !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.integrations)
              );
            }
          }
        }
      );
  }

  delete() {
    let data: DeleteModalData = {
      title: this.loc.integrations.delete,
      icon: 'extension',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.integrations),
    };
    const dialogRef = this.dialogService.openDialog(DeleteModalComponent,data ,"x-small")

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        this.deleteIntegrationAction()
    }
    });
  }

  edit(){
    let data = {
      integrationId: this.integration.integrationId,
      description: this.integration.description,
      status: this.integration.status,
      image: this.imageProfile
    }
    const dialogRef = this.dialogService.openDialog(EditIntegrationComponent, data, 'medium');
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.integration.status = result.status;
        this.integration.description = result.description;
        this.integration.image = result.image;
        this.loading = true;
        this.loadDetails();
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../services/localization/localization.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css'],
  host: {
    class: 'component',
  },
})
export class BranchComponent implements OnInit {
  constructor(public loc: LocalizationService) {}
  selectedIndex = 0;

  ngOnInit(): void {
    let state = JSON.parse(localStorage.getItem('state')!);
    if (state.branch) {
      this.selectedIndex = state.branch.tab;
    }
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state.branch = {
      tab: this.selectedIndex
    };
    localStorage.setItem('state', JSON.stringify(state));
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-currency-details',
  templateUrl: './currency-details.component.html',
  styleUrls: ['./currency-details.component.css'],
  host: { class: 'component' },
})
export class CurrencyDetailsComponent implements OnInit {

  selectedIndex = 0;
  currencyCode = '';
  
  constructor(
    public loc: LocalizationService,
    public route: ActivatedRoute,
  ) { }

   ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.currencyCode = params['currencyCode'];
    });
    // this.loadAttributesAllowed();
  }

  onTabChange() {}
}

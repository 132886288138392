<div class="general-operations-div">
    <div style="display: flex; margin-right: 15px;align-items: center;">
        <button mat-icon-button class="back" (click)="goBack()"   >
            <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
          </button>
        <button  mat-fab class="page-icon-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">signpost</mat-icon>
        </button>
            <label class="page-title"  i18n>{{loc.flows.plural}}</label>
    </div>
</div>

<ng-container *ngIf="!loading">
    <app-table
      [emptyState]="emptyState"
      [data]="data"
      [columns]="columns"
      [paginatorInfo]="paginatorInfo"
      [title]="'postings'"
      [filters]="filters"
      [actions]="actions"
      >
    </app-table>
  </ng-container>
  
  <ng-container *ngIf="loading">
    <app-loading-table
      [title]="'postings'"
      [columns]="columns"
      [filters]="filters">
    </app-loading-table>
  </ng-container>
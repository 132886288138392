import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import {
  FormStep,
  Step,
  StepInput,
  StepInputSelect,
  StepInputText,
  myPair
} from 'src/app/models/stepper/step-modal';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-form-step',
  templateUrl: './form-step.component.html',
  styleUrls: ['./form-step.component.css']
})
export class FormStepComponent implements OnInit {
  @Input() inStep!: Step & { idx?: number };
  step!: FormStep & { idx?: number };
  @Input() middleStep!: boolean;
  @Input() stepper: any;

  @Input() verifiedItemsDictionary!: { [key: string]: any };
  @Output() outputEvent = new EventEmitter<UntypedFormGroup>();
  @Output() saveEvent = new EventEmitter();

  formGroup!: UntypedFormGroup;

  selectInputs!: { [key: string]: Map<String, StepInputSelect> };

  loading = true;

  constructor(public loc: LocalizationService) {}

  ngOnInit(): void {
    this.step = this.inStep as FormStep & { idx?: number };
    this.generateStep();
    this.loading = false;
    console.log(this.stepper);
  }

  getInputOptions(i: number) {
    return (this.step.form[i] as StepInputSelect).options;
  }

  generateStep() {
    this.generateFormGroup();
    this.loading = false;
  }

  generateFormGroup() {
    this.formGroup = new UntypedFormGroup({});
    for (const input of this.step.form) {
      let control: UntypedFormControl;
      switch (input.type) {
        case 'text':
          control = new UntypedFormControl('', input.validators || []);
          break;
        case 'numeric':
          control = new UntypedFormControl('', input.validators || []);
          break;
        case 'select':
          control = new UntypedFormControl('', input.validators || []);
          break;
        case 'time':
          control = new UntypedFormControl('', input.validators || []);
          break;
        case 'date':
          control = new UntypedFormControl('', input.validators || []);
          break;
        case 'textarea':
          control = new UntypedFormControl('', input.validators || []);
          break;
      }
      this.formGroup.addControl(input.key, control);
    }
  }

  canNext(formGroup: UntypedFormGroup) {
    console.log(formGroup);
    return formGroup.valid;
  }

  next(stepper: MatStepper) {
    if (this.formGroup.valid) {
      this.outputEvent.emit(this.formGroup);
      stepper.next();
    } else {
      // Perform actions when the form is invalid, such as displaying error messages
    }
  }

  onSelectOption(selectedOption: any, nextSelectKey: string, i: number): void {
    this.loadOptionsForNextSelect(selectedOption.value, nextSelectKey, i);
  }

  onSelect(selectedOption: any, step: Step, i: number): void {
    this.loadTemplateData(selectedOption, step, i);
  }

  async loadTemplateData(selectedOption: any, step: Step, i: number) {
    try {
      this.formGroup;
      let templateData: myPair[] = await ((step as FormStep).form[
        i
      ] as StepInputSelect).loadTemplateData!(selectedOption);
      console.log(selectedOption);
      templateData.forEach(element => {
        this.formGroup.get(element[0])?.setValue(element[1]);
      });
      this.formGroup.get('metadataControlType')?.setValue('TEXT');
    } catch (error) {
      console.error(error);
    }
  }

  loadOptionsForNextSelect(
    selectedOption: any,
    nextSelectKey: string,
    actualIndexSelect: number
  ) {
    try {
      let nextIndexSelect = this.findStepInputSelectIndexByKey(nextSelectKey);
      if (
        (this.step.form[actualIndexSelect] as StepInputSelect)
          .loadOptionsForNextSelect
      ) {
        (this.step.form[nextIndexSelect] as StepInputSelect).options = (this
          .step.form[actualIndexSelect] as StepInputSelect)!
          .loadOptionsForNextSelect!(selectedOption)!;
      } else {
        throw new Error(
          'StepInputSelect does not have loadOptionsForNextSelect'
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  findStepInputSelectIndexByKey(keyToFind: string): number {
    if (this.step.form) {
      const index = this.step.form.findIndex(stepInput => {
        return stepInput.type === 'select' && stepInput.key === keyToFind;
      });
      if (index === -1) {
        throw new Error('StepInputSelect not found with the given key.');
      }
      return index;
    }
    throw new Error('FormStep is not defined.');
  }

  getNextSelectionKey(step: Step, i: number): string {
    return ((step! as FormStep).form[i] as StepInputSelect).nextSelectKey!;
  }

  disable(actualIndexSelect: number) {
    return (
      (this.step.form[actualIndexSelect]! as StepInputSelect).options.length ===
      0
    );
  }

  castToStepInputText(stepInput: StepInput): StepInputText {
    return stepInput as StepInputText;
  }
}

import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { CreateModalData, Step, VerifyGroup } from 'src/app/models/stepper/step-modal';
import { MetadataType } from 'src/app/models/metadata/metadata-type/metadataType.model';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DialogRef } from '@angular/cdk/dialog';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';


@Component({
  selector: 'app-create-metadata-type',
  templateUrl: './create-metadata-type.component.html',
  styleUrls: ['./create-metadata-type.component.css'],
  host: {
    class: 'dialog-component'
  }
})

export class CreateMetadataTypeComponent implements OnInit {
  loading = true;

  steps: Step[] = [
    {
      title: this.loc.stepTitle.basicData,
      icon: 'dynamic_feed',
      type: 'form',
      form: [
        {
          key: 'id',
          type: 'text',
          label: this.loc.fields.identification,
          validators: [Validators.required],
        },
        {
          key: 'metadataName',
          type: 'text',
          label: this.loc.fields.name,
          validators: [Validators.required],
        },
      ],
    },
  ];

  verify: VerifyGroup[] = [
    {
      title: this.loc.stepTitle.basicData,
      type: 'form',
      elements: ['id', 'metadataName'],
      descriptions: [this.loc.fields.identification, this.loc.fields.name]
    },
  ];

  modalData: CreateModalData = {
    title: this.loc.metadataType.create,
    icon: 'psychology',
    steps: this.steps,
    verify: this.verify,
    size: 'small',
  }

  constructor(
    public loc: LocalizationService,
    private metadataService: MetadataService,
    public dialogRef: DialogRef,
    private snackbarService: SnackbarService,

  ) { }

  ngOnInit(): void {
  }

  save(verifiedItemsDictionary: { [key: string]: any }) {
    this.setupVerify(verifiedItemsDictionary);
  }

  setupVerify(verifiedItemsDictionary: { [key: string]: any }) {
    this.loading = true;
    let newMetadataType: MetadataType =  {
      id: verifiedItemsDictionary['id'],
      metadataName:  verifiedItemsDictionary['metadataName'],
    }

    this.metadataService.createMetadataType(newMetadataType).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('create', this.loc.metadataType, 'f')
            );
            this.exit('created');
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('create', this.loc.metadataType)
            );
            this.exit('error');
          }
          this.loading = false;
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (err.statusCode !== 401) {
          this.loading = false;
          console.log(err)
          this.exit('error');
        }
      }
    )
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

}

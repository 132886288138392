<form [formGroup]="formGroup" class="form-step" (ngSubmit)="next(stepper)">
    <div class="form">
    <div class="data-box">

        <div *ngFor="let input of step.form; let i = index;" class="create-row">
            <div class="create-row">
                <ng-container *ngIf="i % 2 === 0">
                    <ng-container [ngSwitch]="step.form[i].type">

                        <mat-form-field *ngSwitchCase="'text'" appearance="fill" i18n-matTooltip [matTooltip]="castToStepInputText(step.form[i]).error!" matTooltipPosition="above"
                        matTooltipClass="error-tooltip" [matTooltipDisabled]="!formGroup.get(step.form[i].key)?.hasError('pattern')">
                            <mat-label i18n>{{ step.form[i].label }}</mat-label>
                            <input type="text" matInput [name]="step.form[i].key" [formControlName]="step.form[i].key"
                                 [placeholder]="castToStepInputText(step.form[i]).example!"/>
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'numeric'" appearance="fill" i18n-matTooltip [matTooltip]="castToStepInputText(step.form[i]).error!" matTooltipPosition="above"
                        matTooltipClass="error-tooltip" [matTooltipDisabled]="!formGroup.get(step.form[i].key)?.hasError('pattern')">
                            <mat-label i18n>{{ step.form[i].label }}</mat-label>
                            <input matInput [name]="step.form[i].key" [formControlName]="step.form[i].key" 
                            
                                type="number"/>
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'time'" appearance="fill">
                            <mat-label i18n>{{ step.form[i].label }}</mat-label>
                            <input matInput [name]="step.form[i].key" [formControlName]="step.form[i].key" type="time" />
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'select'" appearance="fill">
                            <mat-label i18n>{{ step.form[i].label }}</mat-label>
                            <mat-select [formControlName]="step.form[i].key" [disabled]="disable(i)"
                                (selectionChange)="getNextSelectionKey(step,i)? onSelectOption($event.value, getNextSelectionKey(step, i) , i ) : onSelect($event.value,step,i)">
                                <mat-select-trigger>
                                    <div class="select-div">
                                        <mat-label
                                            class="selected-lbl">{{formGroup.get(step.form[i].key)?.value.description}}
                                        </mat-label>
                                    </div>
                                    <img [src]="formGroup.get(step.form[i].key)?.value.image" class="selected-image" />
                                </mat-select-trigger>
                                <mat-option *ngFor="let element of getInputOptions(i)" [value]="element">
                                    {{ element.description }}
                                    <img *ngIf="element.image" [src]="element!.image" class="image" />
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'date'" appearance="fill">
                            <mat-label i18n>{{ step.form[i].label }}</mat-label>
                            <input matInput [name]="step.form[i].key" [formControlName]="step.form[i].key" type="date" />
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'textarea'" appearance="fill" [style.verticalAlign]="'top'" [style.max-height]="step.form[i].height">
                            <mat-label i18n>{{ step.form[i].label }}</mat-label>
                            <textarea
                            matInput
                            [formControlName]="step.form[i].key"
                            cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="5"
                            [style.max-height]="step.form[i].height"
                            [style.width]="step.form[i].width" 
                            ></textarea>
                        </mat-form-field>


                    </ng-container>
                </ng-container>

                <ng-container *ngIf="i % 2 === 0 && i + 1 < step.form.length">
                    <ng-container [ngSwitch]="step.form[i+1].type">

                        <mat-form-field *ngSwitchCase="'text'" appearance="fill">
                            <mat-label i18n>{{ step.form[i+1].label }}</mat-label>
                            <input type="text" matInput [name]="step.form[i+1].key"
                                [formControlName]="step.form[i+1].key"  [placeholder]="castToStepInputText(step.form[i+1]).example!" />
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'numeric'" appearance="fill" i18n-matTooltip [matTooltip]="castToStepInputText(step.form[i+1]).error!" matTooltipPosition="above"
                        matTooltipClass="error-tooltip" [matTooltipDisabled]="!formGroup.get(step.form[i+1].key)?.hasError('pattern')">
                            <mat-label i18n>{{ step.form[i+1].label }}</mat-label>
                            <input matInput [name]="step.form[i+1].key" [formControlName]="step.form[i+1].key" 
                                type="number"/>
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'time'" appearance="fill">
                            <mat-label i18n>{{ step.form[i+1].label }}</mat-label>
                            <input matInput [name]="step.form[i+1].key" [formControlName]="step.form[i+1].key" type="time" />
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'select'" appearance="fill">
                            <mat-label i18n>{{ step.form[i+1].label }}</mat-label>
                            <mat-select [formControlName]="step.form[i+1].key" [disabled]="disable(i+1)"
                                (selectionChange)="onSelectOption($event.value, getNextSelectionKey(step, i+1) , i+1 )">
                                <mat-select-trigger>
                                    <div class="select-div">
                                        <mat-label
                                            class="selected-lbl">{{formGroup.get(step.form[i+1].key)?.value.description}}
                                        </mat-label>
                                    </div>
                                    <img [src]="formGroup.get(step.form[i+1].key)?.value.image" class="selected-image" />
                                </mat-select-trigger>
                                <mat-option *ngFor="let element of getInputOptions(i+1)" [value]="element">
                                    {{ element.description }}
                                    <img *ngIf="element.image" [src]="element!.image" class="image" />
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'date'" appearance="fill">
                            <mat-label i18n>{{ step.form[i+1].label }}</mat-label>
                            <input matInput [name]="step.form[i+1].key" [formControlName]="step.form[i+1].key" type="date" />
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'textarea'" appearance="fill" [style.verticalAlign]="'top'" [style.width]="step.form[i+1].width" [style.height]="step.form[i+1].height">
                            <mat-label i18n>{{ step.form[i].label }}</mat-label>
                            <textarea
                            matInput
                            [formControlName]="step.form[i].key"
                            cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="5"
                            ></textarea>
                        </mat-form-field>

                    </ng-container>
                </ng-container>
            </div>
        </div>

    </div>
</div>

    <div class="dialog-nav-buttons">
        <button *ngIf="step.idx! > 0" mat-fab class="button green-button" matStepperPrevious>
            <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-fab class="button green-button" (click)="next(stepper)">
            <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
        </button>
    </div>

</form>
import { Identification } from "../Identifications/Identification.model";
import { UserManager } from "../users/userManager.model";

export class Person {
    firstName?: string;
    lastName?: string;
    secundSurname?: string;
    dateOfBirth?: string;
    cellphone?: string;
    phone?: string;
    alternativePhone?: string;
    email?: string;
    nationality?: string;
    profession?: string;
    externalId?: string;
    maritalStatus?: string;
    identification: Identification;
    user: UserManager;

    constructor(){
        this.identification = new Identification();
        this.user = new UserManager();
    }

}

<div class="general-operations-div">
    <div style="display: flex; margin-right: 15px;align-items: center;">
        <button  mat-fab class="page-icon-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">signpost</mat-icon>
        </button>
            <label class="page-title"  i18n>{{loc.routes.transactions}}</label>
    </div>
</div>

<mat-tab-group class="tab-bar"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChange();">

    <mat-tab [label]="loc.tabNames.disaggregations" i18n-label>
        <app-disaggregation tableStyle = "tableResponsive" ></app-disaggregation>
    </mat-tab>

    <mat-tab [label]="loc.tabNames.postings" i18n-label>
        <app-posting tableStyle = "tableResponsive" ></app-posting>
    </mat-tab>

    <mat-tab [label]="loc.tabNames.postingSchemes" i18n-label>
        <app-posting-schemes tableStyle = "tableResponsive"></app-posting-schemes>
    </mat-tab>

    <mat-tab [label]="loc.tabNames.operationGroups" i18n-label>
      <app-operation-groups tableStyle = "tableResponsive"></app-operation-groups>
    </mat-tab>
    <mat-tab [label]="loc.tabNames.adjustmentReasons" i18n-label>
      <app-adjustment-reasons tableStyle = "tableResponsive"></app-adjustment-reasons>
  </mat-tab>

</mat-tab-group>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { StepMetadata } from 'src/app/models/step/step.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ShowStructureComponent } from 'src/app/apis/showStructure/show-structure/show-structure.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Validators } from '@angular/forms';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { StepsService } from 'src/app/services/steps/steps.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { MetadataType } from 'src/app/models/metadata/metadata-type/metadataType.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { ListMetadataDetailsComponent } from '../list-metadata-details/list-metadata-details.component';

@Component({
  selector: 'app-metadata-details',
  templateUrl: './metadata-details.component.html',
  styleUrls: ['./metadata-details.component.css'],
  host: {
    class: 'component'
  }
})
export class MetadataDetailsComponent implements OnInit {
  loading = false;
  @ViewChild(ListMetadataDetailsComponent) listMetadataDetailsComponent!: ListMetadataDetailsComponent;
  notifyUpdateToChild? = "";

  selectedIndex = 0;
  isInput?: boolean;
  metadata?: StepMetadata;
  stepId = "";
  boolOptions: any[] = [{
      key: '1',
      value: this.loc.common.yes
    },
    {
      key: '0',
      value: this.loc.common.no
    }
  ];
  metadataTypes: any[] = [];
  controlTypes: any[] = [{key: 'TEXT', value: 'Text'},
                          {key: 'COMBOBOX', value: 'Combobox'},
                          {key: 'CHECKBOX', value: 'Checkbox'}, 
                          {key: 'SLIDER', value: 'Slider'}, 
                          {key: 'OTP', value: 'OTP'},
                          {key: 'LIST', value: 'List'}];

  datasource: any[] = [{key: 'STATIC', value: this.loc.common.static},
                        {key: 'DYNAMIC', value: this.loc.common.dynamic}];

  receivedMessage: string = '';


  sendUpdateToChild() {
    this.listMetadataDetailsComponent.receiveUpdateFromParent();
  }

  receiveNewDatasourceData(message: string) {
    this.receivedMessage = message;
    this.metadata!.dataSourceData = JSON.stringify(message);
  }

  constructor(
    public route: ActivatedRoute,
    public location: Location,
    public loc : LocalizationService,
    private dialogService: DialogService,
    private stepsService: StepsService,
    private snackbarService: SnackbarService,
    private metadataService: MetadataService,
    public router: Router,

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.stepId = params.stepId;
      this.isInput = params.isInput === 'true';
      this.metadata = {
        id: params.id,
        type: params.type,
        controlType: params.controlType,
        description: params.description,
        defaultValue: params.defaultValue,
        validation: params.validation,
        isHidden: params.isHidden,
        isMandatory: params.isMandatory,
        isEditable: params.isEditable,
        dataSource: params.dataSource,
        checkout: params.checkout,
        ordinal: params.ordinal
      }
    });
    this.listData()
  }

  goBack(): void {
    this.router.navigate(['step-details'], {
      queryParams: {
        stepId: this.stepId,
      },
    });
  }

  onTabChange() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state.steps = {
      tab: this.selectedIndex
    };
    localStorage.setItem('state', JSON.stringify(state));
  }

  showDatasourceData(){
    var dialogRef;
    try {
      dialogRef = this.dialogService.openDialog(
        ShowStructureComponent,
        {
          body: JSON.parse(JSON.parse(this.metadata?.dataSourceData!)),
          title: this.loc.fields.datasourceData,
          editable: true
        },
        'large'
      );
    }
    catch {
      dialogRef = this.dialogService.openDialog(
        ShowStructureComponent,
        {
          body: JSON.parse(this.metadata?.dataSourceData!),
          title: this.loc.fields.datasourceData,
          editable: true
        },
        'large'
      );
    }
    
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        console.log(result)
        this.loading = true;
        try {
          this.metadata!.dataSourceData = result;
          let newMetadata: any[] = [{
            metadataId: this.metadata!.id,
            metadataDescription: this.metadata!.description,
            metadataType: this.metadata!.type,
            metadataControlType: this.metadata!.controlType,
            metadataDefaultValue: this.metadata!.defaultValue,
            metadataIsMandatory: this.metadata!.isMandatory,
            metadataIsEditable: this.metadata!.isEditable,
            metadataIsHidden: this.metadata!.isHidden,
            metadataDatasource: this.metadata!.dataSource,
            metadataValidation: this.metadata!.validation,
            metadataCheckout: this.metadata!.checkout,
            metadataDatasourceData: JSON.parse(result),
            ordinal: this.metadata!.ordinal
          }] 
          this.stepsService.updateStepMetadata(this.stepId, newMetadata, this.isInput!).subscribe(
            (res: BaseResponse) => {
              setTimeout(async () => {
                if(res.statusRS?.code === "0"){
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage('update', this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
                  );
                  this.notifyUpdateToChild = this.metadata!.dataSourceData;
                } else {
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError('update', this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
                  );
                }
                this.loading = false;
              }, 200);
            }, (err) => {
              setTimeout(() => {
              }, 200);
              if (err.statusCode !== 401) {
                this.loading = false;
                console.log(err)
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('update',  this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
                );
              }
            }
          )
      } catch (error) {
        this.loading = false;
          this.snackbarService.openErrorSnackbar(this.loc.errors.invalidFormat);
      }
      }
    });
  
  }

  async editMetadata(){
    await this.loadFields();
    let data: EditModalData = {
      title: this.isInput? this.loc.inputMetadata.edit : this.loc.outputMetadata.edit,
      icon: 'psychology',
      fields: [
        {label: this.loc.tableHeaders.description, 
          value: this.metadata!.description, 
          validators: [Validators.required]
        },
        {label: this.loc.tableHeaders.type, 
          value: this.metadata!.type, 
          validators: [Validators.required],
          comboValues: this.metadataTypes,
          isCombo: true
        },
        {label: this.loc.tableHeaders.controlType, 
          value: this.metadata!.controlType, 
          validators: [Validators.required],
          comboValues: this.controlTypes,
          isCombo: true
        },
        {label: this.loc.tableHeaders.defaultValue, 
          value: this.metadata!.defaultValue, 
        },
        {label: this.loc.tableHeaders.isMandatory, 
          value: (this.metadata!.isMandatory).toString(), 
          validators: [Validators.required],
          comboValues: this.boolOptions,
          isCombo: true
        },
        {label: this.loc.tableHeaders.isHidden, 
          value: (this.metadata!.isHidden).toString(), 
          validators: [Validators.required],
          comboValues: this.boolOptions,
          isCombo: true
        },
        {label: this.loc.tableHeaders.isEditable, 
          value: (this.metadata!.isEditable).toString(), 
          validators: [Validators.required],
          comboValues: this.boolOptions,
          isCombo: true
        },
        {label: this.loc.fields.datasource, 
          value: this.metadata!.dataSource, 
          validators: [Validators.required],
          comboValues: this.datasource,
          isCombo: true
        },
        {label: this.loc.tableHeaders.validation, 
          value: this.metadata!.validation, 
        },
        {label: this.loc.fields.checkout, 
          value: this.metadata!.checkout, 
          validators: [Validators.required],
          comboValues: this.boolOptions,
          isCombo: true
        },
      ],
    }
    const dialogRef = this.dialogService.openCustomDialog(
      EditModalComponent,
      data,
      [650,700],
      "edit-metadata-dialog"
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value;
        let newType = result.find((x: any) => x.label === this.loc.tableHeaders.type).value;
        let newControlType = result.find((x: any) => x.label === this.loc.tableHeaders.controlType).value;
        let newDefValue = result.find((x: any) => x.label === this.loc.tableHeaders.defaultValue).value;
        let newMandatory = result.find((x: any) => x.label === this.loc.tableHeaders.isMandatory).value;
        let newEditable = result.find((x: any) => x.label === this.loc.tableHeaders.isEditable).value;
        let newHidden = result.find((x: any) => x.label === this.loc.tableHeaders.isHidden).value;
        let newDatasource = result.find((x: any) => x.label === this.loc.fields.datasource).value;
        let newValidation = result.find((x: any) => x.label === this.loc.tableHeaders.validation).value;
        let newCheckout = result.find((x: any) => x.label === this.loc.fields.checkout).value;
        
        let newMetadata: StepMetadata = {
          id: this.metadata!.id,
          description: newDescription,
          type: newType,
          controlType: newControlType,
          defaultValue: newDefValue,
          isMandatory: newMandatory,
          isEditable: newEditable,
          isHidden: newHidden,
          dataSource: newDatasource,
          validation: newValidation,
          checkout: newCheckout
        }        
        this.stepsService.updateStepMetadataByMetadataId(this.stepId!, newMetadata).subscribe(
          (res: BaseResponse) => {
            setTimeout(async () => {
              if(res.statusRS?.code === "0"){
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('update', this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
                );
                await this.updateData(newMetadata);
                
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('update', this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
                );
              }
              this.loading = false;
            }, 200);
          }, (err) => {
            setTimeout(() => {
            }, 200);
            if (err.statusCode !== 401) {
              this.loading = false;
              console.log(err)
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update',  this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
              );
            }
          }
        )

      }
    });
  }

  deleteMetadata(){
    let data: DeleteModalData = {
      title: this.isInput? this.loc.inputMetadata.delete : this.loc.outputMetadata.delete,
      icon: 'psychology',
      confirmDeleteMessage: this.loc.confirmDelete(this.isInput? this.loc.inputMetadata : this.loc.outputMetadata),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
       this.stepsService.deleteStepMetadataByMetadataId(this.stepId!, this.metadata!.id, this.isInput!).subscribe(
        (res: BaseResponse) => {
          setTimeout(() => {
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
              );
              this.goBack();
              this.loading = false;
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
              );
            this.loading = false;

            }
          }, 200);
        }, (err) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.isInput? this.loc.inputMetadata : this.loc.outputMetadata)
            );
          }
        }
        
      )
      }
    })
  }

  loadFields(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.metadataService.listMetadatTypes().subscribe(
        (res: BaseResponse) => {
          if (res.statusRS?.code === '0') {
            let metaTypes = res.messageRS as unknown as MetadataType[];
            this.metadataTypes = metaTypes.map(item => ({
              key: item.id,
              value: item.metadataName,
            }));
            this.loading = false;
            resolve(); 
          } else {
            this.loading = false;
            reject(new Error('Error al cargar los metadatos'));
          }
        },
        (err) => {
          this.loading = false;
          console.log(err);
          reject(err); 
        }
      );
    });
  }

  async updateData(newData: StepMetadata){
    this.loading = true;
    this.metadata = {
      id: this.metadata!.id,
      description: newData.description,
      type: newData.type,
      controlType: newData.controlType,
      defaultValue: newData.defaultValue,
      isMandatory: newData.isMandatory,
      isEditable: newData.isEditable,
      isHidden: newData.isHidden,
      dataSource: newData.dataSource,
      validation: newData.validation,
      checkout: newData.checkout,
      dataSourceData: this.metadata!.dataSourceData,
      ordinal: this.metadata!.ordinal
    }
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.set('type', this.metadata.type);
      urlParams.set('controlType', this.metadata.controlType);
      urlParams.set('description', this.metadata.description);
      urlParams.set('defaultValue', this.metadata.defaultValue);
      urlParams.set('validation', this.metadata.validation);
      urlParams.set('isHidden', (this.metadata.isHidden).toString());
      urlParams.set('isMandatory', (this.metadata.isMandatory).toString());
      urlParams.set('isEditable', (this.metadata.isEditable).toString());
      urlParams.set('dataSource', this.metadata.dataSource);
      urlParams.set('checkout', this.metadata.checkout);
      const nuevaURL = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState({}, '', nuevaURL);
  }

  listData(){
    this.loading = true
    this.stepsService.getStepMetadataKeyValue(this.stepId!, this.metadata!.id).subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.metadata!.dataSourceData = JSON.stringify(JSON.stringify(res.messageRS!.data));
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }
  
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Country } from 'src/app/models/country/country.model';
import { CountryCurrency } from 'src/app/models/countryCurrency/countryCurrency';
import { Currency } from 'src/app/models/Currency/currency';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CountriesService } from '../../../services/countries/countries.service';
import { BaseResponse } from 'src/app/models/responses.model';


@Component({
  selector: 'app-add-country-currency',
  templateUrl: './add-country-currency.component.html',
  styleUrls: ['./add-country-currency.component.css'],
  host:{
    class: 'dialog-component'
  }
})
export class AddCountryCurrencyComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  currencies: Currency[] =[];


  constructor(
    public dialogRef: MatDialogRef<AddCountryCurrencyComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      countryId: string;
      existingCurrencies: string[];
    },
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private countriesService: CountriesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      currency: ['',Validators.required],
      decimalNumber: ['',Validators.required],
      separator: ['',Validators.required]
    });
    this.countriesService.getCurrencies().subscribe({
      next: (res: BaseResponse<Currency[]>) => {
        const response = res.messageRS;
        this.currencies = response.filter((currency: Currency) => {
          return !this.data.existingCurrencies.includes(currency.code);
        });
      },
      error: (err) =>{
        this.logoutDialogService.openLogoutDialog(err);
      }
    })
  }

  exit(message:string){
    this.dialogRef.close(message);
  }

  create (){
    const currencyData: CountryCurrency = {
      countryId: this.data.countryId,
      decimalNumber: this.firstFormGroup.get('decimalNumber')!.value.toString(),
      decimalSeparator: this.firstFormGroup.get('separator')!.value,
      code: '',
      isoCode: '',
      description: '',
      symbol: '',
      creationDate: '',
      changeDate: ''
    };
    this.countriesService.addCurrency(
      this.firstFormGroup.get('currency')!.value?.code,
      currencyData
    ).subscribe({
      next: (res: BaseResponse<void>) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit("error");
          }
        }, 200);
      },error: (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    })

  }

}

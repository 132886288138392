import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CreateServiceModeComponent } from 'src/app/digital-services/create-service-mode/create-service-mode.component';
import { FrameTemplate } from 'src/app/models/FrameTemplates/frameTemplates.model';
import { ServiceTemplate } from 'src/app/models/digital-services/ServiceTemplate.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { ImageUploaderService } from 'src/app/services/image-uploader/image-uploader.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { UsersService } from 'src/app/services/users/users.service';
import { environment } from 'src/environments/environment';

declare var require: any;
const sha1 = require('sha1')


@Component({
  selector: 'app-create-service-template',
  templateUrl: './create-service-template.component.html',
  styleUrls: ['./create-service-template.component.css'],
  host:{
    class:'dialog-component'
  }
})


export class CreateServiceTemplateComponent implements OnInit {

  

  loading = false;
  firstFormGroup!: UntypedFormGroup;
  templates: FrameTemplate[] = [];
  image?: string;
  selectedFile: File = new File([], '');

  cloudinary = {
    config: environment.cloudinary,
 };


  constructor(
    public dialogRef: MatDialogRef<CreateServiceModeComponent>,
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private usersService : UsersService,
    public snackbarService: SnackbarService,
    private extensionPointsService: ExtensionPointsService,
    private attributesService: AttributesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService,
    private imageUploaderService: ImageUploaderService
  ) { }

  ngOnInit(): void {

    this.firstFormGroup = this._formBuilder.group({
      id: ['', Validators.required],
      template: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.getServiceTemplates();
  }

  getServiceTemplates(){
    this.loading = true;
    this.usersService.getFrameTemplates().subscribe(
      (res:any) => {
        this.loading = false;
        if(res.statusRS?.code == "0"){
          this.templates = res.messageRS as FrameTemplate[];
        } else {
          this.snackbarService.openErrorSnackbar(this.loc.errors.loadingServiceTemplate)
        }

      }, (err) => {
        this.snackbarService.openErrorSnackbar(this.loc.errors.loadingServiceTemplate)
      }
    )
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  create() {
    this.loading = true;

    this.usersService.createServiceTemplate(this.firstFormGroup.get('id')?.value, this.firstFormGroup.get('description')?.value, this.firstFormGroup.get('template')?.value.DSTemp,this.image).subscribe(
      (res:any) => {
        setTimeout(() => {
          this.loading = false;
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else if(res.statusRS?.code == "-91855"){
            this.exit("duplicate")
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }



  onFileSelect($event: any): void {
    this.selectedFile = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = (_event) => {
      this.image = reader.result?.toString() || '';
    };
  }

 

  saveImage(){
    this.loading = true;
    let id = this.firstFormGroup.get('id')!.value;
    if (this.selectedFile.name !== '') {
      const data = new FormData();
      const timestamp = Math.floor(Date.now() / 1000);
      const signature = sha1(
        'public_id=' + id
        +'&timestamp=' +
          timestamp.toString() +
          '&upload_preset=' +
          this.cloudinary.config.upload_preset +
          this.cloudinary.config.api_secret
      );

      data.append('file', this.selectedFile);
      data.append('upload_preset', this.cloudinary.config.upload_preset);
      data.append('cloud_name', this.cloudinary.config.cloud_name);
      data.append('api_key', this.cloudinary.config.api_key);
      data.append('api_secret', this.cloudinary.config.api_secret);
      data.append('secure', this.cloudinary.config.secure.toString());
      if (
        id !== undefined
      ) {
        data.append(
          'public_id',
          id
        );
      }
      data.append('signature', signature);
      data.append('timestamp', timestamp.toString());
      this.imageUploaderService.uploadImage(data).subscribe((response) => {
        if (
          response &&
          id !== undefined
        ) {
          this.image = response.secure_url;
          this.create();
          this.loading = false;
          
         
        }
      });
     
    } else {
      this.image = 'https://res.cloudinary.com/paycorrcloud/image/upload/v1683739958/servicios/desembolso-03.png';
      setTimeout(() => {
        this.create();
        this.loading = false;
      }, 2000);
    }
   
   
  }


}


<div class="general-operations-div">
    <div style="display: flex;">
      <button mat-icon-button class="back" (click)="goBack()"   >
        <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>
      <ng-container *ngIf="!loading">
        <button mat-fab class="page-icon-button blue-button-icon">
          <mat-icon class="action-button-icon" fontSet="material-icons-outlined">signpost</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="!loading">
        <div class="title-container">
          <label class="title-mid-text">
            {{description}}</label>
            <label class="id-text">
             {{id}}
            </label>
        </div>
      </ng-container>
      <div *ngIf="loading" style="position: absolute; top: 0; margin-left: 40px">
        <ngx-skeleton-loader
          count="1"
          appearance="circle"
          [theme]="{
            background: 'no-repeat rgb(227 228 230 / 33%)',
            height: '60px',
            width: '60px'
          }"
        ></ngx-skeleton-loader>
        </div>
    </div>
  
  
  </div>

<mat-tab-group class="tab-bar">
    <mat-tab [label]="loc.tabNames.steps" i18n-label>
        <app-list-disaggregation-steps [id]="id" tableStyle = "tableResponsive"></app-list-disaggregation-steps>
      </mat-tab>
</mat-tab-group>
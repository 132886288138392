 <div class="title-box">
    <app-page-title [loading]="loading" [withBackButton]="true" [image]="''" icon="settings" [title]="api?.name!" [subtitle]="api?.apiId!"></app-page-title>

    <div>
    <button
    mat-fab
    class="button green-color last-button"
    fontSet="material-icons-outlined"
    (click)="showStructure()"
    i18n-matTooltip
    [matTooltip]="loc.apis.showBody"
    >
    <mat-icon>code</mat-icon>
    </button>
    </div>
</div>
    <mat-card class="details-card">
        <div class="flex-container">
            <div class="flex-container-vertical">
                <div>
                    <label class="card-header">{{loc.detailsHeaders.id}}</label>
                </div>
                <div>
                    <label *ngIf="!loading" class="card-value">{{api?.apiId}}</label>
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                        </ngx-skeleton-loader>
                    </ng-container>
                </div>
            </div>
            <div class="flex-container-vertical">
                <div>
                    <label class="card-header">{{loc.detailsHeaders.name}}</label>
                </div>
                    <div>
                        <label *ngIf="!loading" class="card-value">{{api?.name}}</label>
                        <ng-container *ngIf="loading">
                            <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                            </ngx-skeleton-loader>
                        </ng-container>
                    </div>
                </div>
                    <div class="flex-container-vertical">
                        <div>
                            <label class="card-header">{{loc.detailsHeaders.method}}</label>
                        </div>
                        <div>
                            <label *ngIf="!loading" class="card-value">{{api?.method}}</label>
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                                </ngx-skeleton-loader>
                            </ng-container>
                        </div>
                    </div>
                    <div class="flex-container-vertical">
                        <div>
                            <label class="card-header">{{loc.detailsHeaders.restOperation}}</label>
                        </div>
                        <div>
                            <div class="card-value" *ngIf="!loading" [ngStyle]="{'color': 'green'}">
                            </div>
                            <label *ngIf="!loading" class="card-value">{{api?.operation}}</label>
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                                </ngx-skeleton-loader>
                            </ng-container>
                        </div>
                    </div>
                    <div class="flex-container-vertical">
                        <div>
                            <label class="card-header">{{loc.detailsHeaders.path}}</label>
                        </div>
                        <div>
                            <label *ngIf="!loading" class="card-value">{{api?.path}}</label>
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                                </ngx-skeleton-loader>
                            </ng-container>
                        </div>
                    </div>
                    <div class="flex-container-vertical">
                        <div>
                            <label class="card-header">{{loc.detailsHeaders.version}}</label>
                        </div>
                        <div>
                            <label *ngIf="!loading" class="card-value">{{api?.version}}</label>
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                                </ngx-skeleton-loader>
                            </ng-container>
                        </div>
                    </div>
                </div>
    </mat-card>



import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { API } from 'src/app/models/api/api.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { ApisService } from 'src/app/services/apis/apis.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { StepsService } from 'src/app/services/steps/steps.service';

@Component({
  selector: 'app-edit-step',
  templateUrl: './edit-step.component.html',
  styleUrls: ['./edit-step.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class EditStepComponent implements OnInit {
  loading = true;
  stepId = '';
  APIs: API[] = [];
  selectedAPI: API = {};
  firstFormGroup!: UntypedFormGroup;
  body = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditStepComponent>,
    public loc: LocalizationService,
    private apisService: ApisService,
    private formBuilder: FormBuilder,
    private logoutDialogService: LogoutDialogService,
    private stepsService: StepsService
  ) {}

  ngOnInit(): void {
    this.selectedAPI = {
      apiId: this.data.api
    };
    this.stepId = this.data.stepId;
    this.firstFormGroup = this.formBuilder.group({
      apiId: ['', Validators.required],
      name: ['', Validators.required],
      path: ['', Validators.required],
      operation: ['', Validators.required],
      version: ['', Validators.required],
      method: ['', Validators.required],
      body: ['', Validators.required]
    });
    this.firstFormGroup.get('apiId')?.setValue(this.selectedAPI.apiId);

    this.getAPIs();
  }

  getAPIs() {
    this.apisService.loadAPIs().subscribe(
      (res: BaseResponse<API[]>) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.APIs = (res.messageRS as any) as API[];
          this.APIs.sort((a, b) => (a.apiId! > b.apiId! ? 1 : -1));
          this.selectedAPI = this.APIs.find(
            api => api.apiId === this.selectedAPI.apiId
          )!;
          this.body = JSON.stringify(this.selectedAPI.body);

          this.firstFormGroup.valueChanges.subscribe(value => {
            if (value) {
              const currentAPI = this.APIs.find(
                item => item.apiId === value.apiId
              );
              if (this.selectedAPI !== currentAPI) {
                this.selectedAPI = currentAPI!;
                this.firstFormGroup
                  .get('apiId')
                  ?.setValue(this.selectedAPI.apiId);
                this.firstFormGroup
                  .get('name')
                  ?.setValue(this.selectedAPI.name);
                this.firstFormGroup
                  .get('path')
                  ?.setValue(this.selectedAPI.path);
                this.firstFormGroup
                  .get('operation')
                  ?.setValue(this.selectedAPI.operation);
                this.firstFormGroup
                  .get('version')
                  ?.setValue(this.selectedAPI.version);
                this.firstFormGroup
                  .get('method')
                  ?.setValue(this.selectedAPI.method);
                this.firstFormGroup
                  .get('body')
                  ?.setValue(JSON.stringify(this.selectedAPI.body));
                this.body = JSON.stringify(this.selectedAPI.body);
              }
            }
          });
        }
      },
      err => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

  disable() {}

  edit() {
    this.loading = true;
    let apiId = this.firstFormGroup.get('apiId')!.value;

    this.stepsService.updateStepAPI(this.stepId, apiId).subscribe(
      (res: any) => {
        if (res.statusRS?.code === '0') {
          this.exit('updated');
        } else {
          this.exit('error');
        }
        this.loading = false;
      },
      err => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    );
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '../token/token.service';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class MediumsService {

  readonly baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private baseService: BaseService
  ) { }

  getMediums() {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };
    if (this.tokenService.validToken()) {
      return this.http.get(
        this.baseUrl +
        'RSAdaptorFE/api/v1/paycorr/mediums/medium/X/list-mediums',
        HTTP_OPTIONS
      );
    } else {
      return this.baseService.invalidToken();
    }
  }

}

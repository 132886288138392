import { Component, OnInit } from '@angular/core';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Action, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { MetadataType } from 'src/app/models/metadata/metadata-type/metadataType.model';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { CreateMetadataTypeComponent } from '../create-metadata-type/create-metadata-type.component';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-list-metadata-types',
  templateUrl: './list-metadata-types.component.html',
  styleUrls: ['./list-metadata-types.component.css'],
  host: {
    class: 'component',
  }
})
export class ListMetadataTypesComponent implements OnInit {
  loading  = true;

  constructor(
    public loc: LocalizationService,
    private metadataService: MetadataService,
    private dateFormatter: DateFormatterService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,

  ) {}

  ngOnInit(): void {
    this.listMetadataTypes();
  }

  data: TableData[] = [];

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.metadataType.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.createMetadataType(),
      },
    ],
  };

  columns: Column[] = [
    { title: this.loc.tableHeaders.id, 
      key: 'id', 
      sortable: true 
    },
    {
      title: this.loc.tableHeaders.name,
      key: 'metadataName',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.creationDate,
      key: 'creationDate',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.updateDate,
      key: 'updateDate',
      centered: true,
    }
  ];

  paginatorInfo: PaginatorInfo = this.loc.metadataType;

  filters: TableFilter[] = [
    {
      key: 'metadataName',
      label: this.loc.tableHeaders.name,
      placeholder: this.loc.tableHeaders.name,
      type: 'text',
    },
  ];

  actions: Action[] = [
    {
      icon: 'edit',
      tooltip: this.loc.metadataType.edit,
      action: (row: MetadataType) => {
        this.editMetadataType(row);
      },
    },
    {
      icon: 'delete',
      tooltip: this.loc.metadataType.delete,
      action: (row: MetadataType) => {
        this.deleteMetadataType(row);
      },
    },
  ];

  createButton: CreateButton = {
    tooltip: this.loc.metadataType.create,
    action: () => this.createMetadataType(),
  };

  transformers: TableTransformer = {
    creationDate: (value: string) => this.formatDate(value),
    updateDate: (value: string) => this.formatDate(value),
  };

  formatDate(date: string, format?: string): string {
    return this.dateFormatter.formatDate(date, format ? format : 'dd/MM/yyyy');
  }

  listMetadataTypes(){
    this.loading = true
    this.metadataService.listMetadatTypes().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.data = res.messageRS as any as TableData[];
          this.data.sort((a, b) => (a.id > b.id) ? 1 : -1);
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  createMetadataType(){
    const dialogRef = this.dialogService.openDialog(
      CreateMetadataTypeComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.listMetadataTypes();
    });
  }

  editMetadataType(row: any){
    let data: EditModalData = {
      title: this.loc.metadataType.edit,
      icon: 'psychology',
      fields: [
        {label: this.loc.tableHeaders.name, value: row.metadataName, validators: [Validators.required]},]
    }
    const dialogRef = this.dialogService.openDialog(EditModalComponent, data, "x-small");
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        let newName = result.find((x: any) => x.label === this.loc.tableHeaders.name).value
        let metaType = {...row, metadataName: newName}
        
        this.metadataService.editMetadataType(metaType).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('update', this.loc.metadataType)
              );
              this.listMetadataTypes();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.metadataType)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.metadataType)
            );
          },
        })
      }
    });
  }

  deleteMetadataType(row: any){
    let data: DeleteModalData = {
      title: this.loc.metadataType.delete,
      icon: 'psychology',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.metadataType),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === 'delete') {
        this.loading = true;
        this.metadataService.deleteMetadataType(row.id).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.metadataType)
              );
              this.listMetadataTypes();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.metadataType)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.metadataType)
            );
          },
        });
      }
    });

  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';
import { Step, StepMetadata } from 'src/app/models/step/step.model';
import { BaseRequest } from 'src/app/models/base/base-request.model';

@Injectable({
  providedIn: 'root'
})
export class StepsService {
  readonly rootURL = environment.apiUrl;

  constructor(private tokenService: TokenService, private http: HttpClient) {}

  listSteps() {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/step/x/get?status=&type=',
      HTTP_OPTIONS
    );
  }

  getStepDetails(stepId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      params:{
        stepId
      }
      ,
      withCredentials: false
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/step/x/details',
      HTTP_OPTIONS
    );
  }

  updateStepMetadata(
    stepId: string,
    metadata: any[] | StepMetadata,
    isInput: boolean
  ) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      isInputMetadata: isInput,
      metadata: metadata
    };

    return this.http.put(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/step/' +
        stepId +
        '/update-metadata',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  deleteStep(stepId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };
    return this.http.delete(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/step/' +
        stepId +
        '/delete',
      HTTP_OPTIONS
    );
  }

  createStep(step: Step) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      name: step.name,
      icon: step.icon,
      type: step.type,
      api: step.api,
      inputMetadata: [],
      outputMetadata: []
    };

    return this.http.post(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/step/' +
        step.stepId +
        '/create',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  getApis() {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };
    return this.http.get(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/apis/api/x/get',
      HTTP_OPTIONS
    );
  }

  getStepTypes() {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps-types/step/x/get-steps-types',
      HTTP_OPTIONS
    );
  }

  activateStep(stepId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };
    return this.http.put(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/step/' +
        stepId +
        '/activate',
      null,
      HTTP_OPTIONS
    );
  }

  updateStepAPI(stepId: string, apiId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      apiId: apiId
    };

    return this.http.put(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/step/' +
        stepId +
        '/update-api',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  getApiResponseMapStepMetadataOut(stepId: string, apiId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/step/api-map-metadata-out/x/get?apiId=' +
        apiId +
        '&stepId=' +
        stepId,
      HTTP_OPTIONS
    );
  }

  createApiResponseMapStepMetadataOut(
    stepId: string,
    apiId: string,
    outputId: string,
    field: string,
    fieldPath: string,
    iterationPath: string
  ) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      stepId: stepId,
      apiId: apiId,
      metadataOutId: outputId,
      field: field,
      fieldPath: fieldPath,
      iterationPath: iterationPath
    };

    return this.http.post(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/step/api/x/map-metadata-out',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  deleteApiResponseMapStepMetadataOut(
    apiId: string,
    stepId: string,
    metadataOutput: string,
    fieldId: string
  ) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      params: {
        apiId: apiId,
        stepId: stepId,
        metadataOutput: metadataOutput,
        fieldId: fieldId
      },
      withCredentials: false
    };
    return this.http.delete(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/step/api/x/delete-map-metadata-out',
      HTTP_OPTIONS
    );
  }

  editApiResponseMapStepMetadataOut(
    stepId: string,
    apiId: string,
    outputId: string,
    oldField: string,
    newField: string,
    fieldPath: string,
    iterationPath: string
  ) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      apiId: apiId,
      stepId: stepId,
      outputId: outputId,
      oldFieldId: oldField,
      newFieldId: newField,
      fieldPath: fieldPath,
      iterationPath: iterationPath
    };

    return this.http.put(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/step/api/x/update-map-metadata-out',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  createStepMetadataKeyValue(
    stepId: string,
    metadataId: string,
    key: string,
    description: string,
    isHidden: string,
    checkout: string
  ) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      stepId,
      metadataId,
      key,
      description,
      isHidden,
      checkout
    };

    return this.http.post(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/metadata/x/create-metadata-key-value',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  getStepMetadataKeyValue(stepId: string, metadataId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      params: {
        stepId,
        metadataId
      },
      withCredentials: false
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/metadata/x/get-metadata-key-value',
      HTTP_OPTIONS
    );
  }

  updateStepMetadataKeyValue(
    stepId: string,
    metadataId: string,
    oldKey: string,
    newKey: string,
    value: string,
    isHidden: string,
    checkout: string
  ) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      stepId,
      metadataId,
      oldKey,
      newKey,
      value,
      isHidden,
      checkout
    };

    return this.http.put(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/metadata/x/update-key-value',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  deleteStepMetadataKeyValue(stepId: string, metadataId: string, key: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      params: {
        stepId,
        metadataId,
        key
      },
      withCredentials: false
    };
    return this.http.delete(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/metadata/x/delete-key-value',
      HTTP_OPTIONS
    );
  }

  updateStepMetadataByMetadataId(stepId: string, metadata: StepMetadata) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      withCredentials: false
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      stepId,
      metadata
    };

    return this.http.put(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/metadata/x/update-one-metadata',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  deleteStepMetadataByMetadataId(
    stepId: string,
    metadataId: string,
    isInput: boolean
  ) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken()
      }),
      params: {
        stepId,
        metadataId,
        isInput
      },
      withCredentials: false
    };
    return this.http.delete(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/steps/metadata/x/delete-one-metadata',
      HTTP_OPTIONS
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributeAllowed } from 'src/app/models/attributes/attributeAllowed';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';

@Component({
  selector: 'app-update-attributes-allowed',
  templateUrl: './update-attributes-allowed.component.html',
  styleUrls: ['./update-attributes-allowed.component.css'],
  host: {
    class: 'confirmation-dialog-component'
  }
})
export class UpdateAttributesAllowedComponent implements OnInit {
  clicked = false;
  loading = false;
  attributesAllowed: AttributeAllowed[] = [];

  constructor(
    public attributesService: AttributesService,
    public dialogRef: MatDialogRef<UpdateAttributesAllowedComponent>,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  save(){
    this.exit('update');
  }

  exit(message: string) {
    this.dialogRef.close(message);
  }

}

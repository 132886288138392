import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';

@Component({
  selector: 'app-add-flow',
  templateUrl: './add-flow.component.html',
  styleUrls: ['./add-flow.component.css'],
  host: {
    class: "confirmation-dialog-component"
  }
})
export class AddFlowComponent implements OnInit {
  loading: boolean = false;

  control = new UntypedFormControl('', Validators.required)
  flows: any[] = [];

  constructor(
    private extensionPointsService: ExtensionPointsService,
    public dialogRef: MatDialogRef<AddFlowComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.getFlows();
  }

  getFlows(){
    //this.loading = true;
    this.extensionPointsService.getFlows().subscribe(
      (res: BaseResponse) => {
        this.flows = (res.messageRS!['flows'] as any[]).sort((x:any, y:any) => (x.f1Mens01650171! < y.f1Mens01650171!) ? -1 : 1);
      }, (err) => {
        if (err.status !== 401) {
          this.exit('error');
        }
      }
    )
  }

  exit(message: string) {
    this.dialogRef.close(message);
  }

  add(){
    this.loading = true;
    let flow = this.control.value;
    this.extensionPointsService.addFlow(flow.f1Mens01650171, flow.f1EnEx11800171, flow.f1Vers01650171).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit('created:' + flow.f1Mens01650171);
          } else {
            this.exit('error');
          }
        }, 200
        )
      }, (err) => {
        if (err.status !== 401) {
          this.exit('error');
        }
      }
    )
  }

}

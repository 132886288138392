<div class="general-operations-div">
  <div style="display: flex">
    <button mat-icon-button class="back" (click)="goBack()">
      <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
    </button>
    <button mat-fab class="page-icon-button blue-button-icon">
      <mat-icon *ngIf="!step?.icon" fontSet="material-icons-outlined" class="action-button-icon">psychology</mat-icon>
      <img *ngIf="step?.icon" src={{icon}}
        style="object-fit: cover; width: 41px; height: 41px; border-radius: 50%; background-color: #d2d2d2;  margin: -1px;" />
    </button>
    <ng-container *ngIf="!loading">
      <div class="title-container">
        <label class="title-mid-text"> {{ stepId }}</label>
      </div>
    </ng-container>
  </div>
  <div *ngIf="(!loading) && step!.status === '0'" style="display: flex; flex-direction: row-reverse;">
    <ng-container>
      <button mat-fab class="button green-color" (click)="activateStep()" i18n-matTooltip
        [matTooltip]="loc.step.activate">
        <mat-icon class="action-button-icon margin-icon" fontSet="material-icons-outlined">
          toggle_off
        </mat-icon>
      </button>
    </ng-container>
  </div>
</div>

<mat-card class="details-card">
  <div class="flex-container">
    <div class="flex-container-vertical">
      <div>
        <label class="card-header" i18n>{{
          loc.detailsHeaders.name
          }}</label>
      </div>
      <div>
        <ng-container *ngIf="!loading">
          <label class="card-value">
            {{ step!.name }}
          </label>
        </ng-container>
        <div *ngIf="loading">
          <ngx-skeleton-loader count="1" [theme]="{
              background: 'no-repeat rgb(227 228 230 / 33%)',
              height: '18px',
              width: '150px'
            }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="flex-container-vertical">
      <div>
        <label class="card-header" i18n>{{
          loc.detailsHeaders.type
          }}</label>
      </div>
      <div>
        <ng-container *ngIf="!loading">
          <label class="card-value">
            {{ step!.type }}
          </label>
        </ng-container>
        <div *ngIf="loading">
          <ngx-skeleton-loader count="1" [theme]="{
              background: 'no-repeat rgb(227 228 230 / 33%)',
              height: '18px',
              width: '150px'
            }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="flex-container-vertical">
      <div>
        <label class="card-header" i18n>{{
          loc.detailsHeaders.api
          }}</label>
      </div>
      <div>
        <ng-container *ngIf="!loading">
          <label class="card-value">
            {{ apiName }}
          </label>
        </ng-container>
        <div *ngIf="loading">
          <ngx-skeleton-loader count="1" [theme]="{
              background: 'no-repeat rgb(227 228 230 / 33%)',
              height: '18px',
              width: '150px'
            }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="flex-container-vertical">
      <div>
        <label class="card-header" i18n>{{
          loc.detailsHeaders.status
          }}</label>
      </div>
      <div>
        <ng-container *ngIf="!loading">
          <label class="card-value">
            {{ (step?.status === '1' ? loc.common.active : loc.common.inactive) }}
          </label>
        </ng-container>
        <div *ngIf="loading">
          <ngx-skeleton-loader count="1" [theme]="{
              background: 'no-repeat rgb(227 228 230 / 33%)',
              height: '18px',
              width: '150px'
            }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<mat-tab-group class="tab-bar" [(selectedIndex)]="selectedIndex" (selectedTabChange)="onTabChange()">

  <mat-tab [label]="loc.tabNames.inputMetadata" i18n-label>
    <app-input-output-metadata [input]="true" [stepId]="stepId"></app-input-output-metadata>
  </mat-tab>
  <mat-tab *ngIf="!isExecution" [label]="loc.tabNames.outputMetadata" i18n-label>
    <app-input-output-metadata [input]="false" [stepId]="stepId" [apiName]="apiName"
      [apiId]="apiId"></app-input-output-metadata>
  </mat-tab>
</mat-tab-group>

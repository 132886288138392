<app-page-title [loading]="loading" [withBackButton]="true" [image]="country?.image || ''" icon="language" [title]="country!.countryName" [subtitle]="country!.countryId"></app-page-title>


    <mat-card class="details-card">
        <div class="flex-container">
            <div class="flex-container-vertical">
                <div>
                    <label class="card-header">{{loc.detailsHeaders.countryId}}</label>
                </div>
                <div>
                    <label *ngIf="!loading" class="card-value">{{country?.countryId}}</label>
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                        </ngx-skeleton-loader>
                    </ng-container>
                </div>
            </div>
            <div class="flex-container-vertical">
                <div>
                    <label class="card-header">{{loc.detailsHeaders.countryName}}</label>
                </div>
                    <div>
                        <label *ngIf="!loading" class="card-value">{{country?.countryName}}</label>
                        <ng-container *ngIf="loading">
                            <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                            </ngx-skeleton-loader>
                        </ng-container>
                    </div>
                </div>
                    <div class="flex-container-vertical">
                        <div>
                            <label class="card-header">{{loc.detailsHeaders.IsoCode}}</label>
                        </div>
                        <div>
                            <label *ngIf="!loading" class="card-value">{{country?.countryIsoCode}}</label>
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                                </ngx-skeleton-loader>
                            </ng-container>
                        </div>
                    </div>
                    <div class="flex-container-vertical">
                        <div>
                            <label class="card-header">{{loc.detailsHeaders.phoneCode}}</label>
                        </div>
                        <div>
                            <div class="card-value" *ngIf="!loading" [ngStyle]="{'color': 'green'}">
                            </div>
                            <label *ngIf="!loading" class="card-value">{{country?.phoneCode}}</label>
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                                </ngx-skeleton-loader>
                            </ng-container>
                        </div>
                    </div>
                    <div class="flex-container-vertical">
                        <div>
                            <label class="card-header">{{loc.detailsHeaders.creationDate}}</label>
                        </div>
                        <div>
                            <label *ngIf="!loading" class="card-value">{{formatDate(country?.creationDate)}}</label>
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                                </ngx-skeleton-loader>
                            </ng-container>
                        </div>
                    </div>
                    <div class="flex-container-vertical">
                        <div>
                            <label class="card-header">{{loc.detailsHeaders.changeDate}}</label>
                        </div>
                        <div>
                            <label *ngIf="!loading" class="card-value">{{formatDate(country?.changeDate)}}</label>
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader count="1" [theme]="{
                                background: 'no-repeat rgb(227 228 230 / 33%)',
                                height: '20px',
                                width: '100px'
                              }">
                                </ngx-skeleton-loader>
                            </ng-container>
                        </div>
                    </div>
                </div>
    </mat-card>
<mat-tab-group
  class="tab-bar"
  [(selectedIndex)]="selectedIndex"
  >
    <mat-tab  [label]="loc.tabNames.identificationTypes" i18n-label>
      <app-country-identification-types class="component" tableStyle = "tableDetails" [countryId] ="countryId"> </app-country-identification-types>
    </mat-tab>
    <mat-tab  [label]="loc.tabNames.taxes" i18n-label>
      <app-country-taxes class="component" tableStyle = "tableDetails" [country] ="country"> </app-country-taxes>
    </mat-tab>
    <mat-tab [label] = "loc.tabNames.currency" i18n-label>
        <app-country-currency class = "component"  tableStyle = "tableDetails" [countryId] ="countryId" ></app-country-currency>
    </mat-tab>
</mat-tab-group>
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { UserType } from 'src/app/models/users/UserType.model';

import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { UsersService } from 'src/app/services/users/users.service';
import { CreateUserTypeComponent } from '../create-user-type/create-user-type.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-list-user-types',
  templateUrl: './list-user-types.component.html',
  styleUrls: ['./list-user-types.component.css'],
  host: {
    class: 'component'
  }
})
export class ListUserTypesComponent implements OnInit {
  loading = true;
  data: TableData[] = [];

  @Input() tableStyle = 'tableResponsive';

  constructor(
    public usersService: UsersService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) { }

  ngOnInit(): void {
    this.loadUserTypes()
  }

  loadUserTypes(): void {
    this.loading = true;
    this.usersService.getUserTypes().subscribe(
      (res: BaseResponse) => {
        this.data = res.messageRS as any as TableData[];
        this.loading = false;
      },
      (err: any) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  emptyState: EmptyState = {
    image: 'assets/img/usuarios-manager.png',
    message: this.loc.userType.emptyState,
    buttons: [
      {
        text: this.loc.userType.create,
        action: () => this.create(),
      }
    ]
  }

  columns: Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
      sortable: true
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
      sortable: true
    },
  ]

  paginatorInfo = this.loc.userType

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    }
  ]

  actions: Action[] = [
    {
      icon: 'edit',
      action: (row: UserType) => this.edit(row),
      tooltip: this.loc.userType.edit
    },
    {
      icon: 'delete',
      action: (row: UserType) => this.delete(row),
      tooltip: this.loc.userType.delete
    }
  ]

  createButton: CreateButton = {
    tooltip: this.loc.userType.create,
    action: () => {
      this.create();
    }
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateUserTypeComponent,
      {},
      'medium'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.userType)
        );
        this.loadUserTypes();
      } else if (result === '-91654') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.userTypeExist
        );
      } else if (result) {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.userType)
        );
      }
    });
  }

  edit(row: UserType) {
    let data: EditModalData = {
      title: this.loc.userType.edit,
      icon: 'people',
      fields: [
        { label: this.loc.tableHeaders.description, 
          value: row.description!!, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(EditModalComponent,
      data
      ,
      "x-small");

    dialogRef.afterClosed().subscribe((result) => {
     if (result !== 'not updated' && result) {
        this.loading = true;
        this.editAction(row, result)
      }
    });
  }

  editAction(row: any, result: any){
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
    let userTypeEdited: UserType = {
      id: row.id,
      description: newDescription
    }
    this.usersService.updateUserType(userTypeEdited).subscribe({
      next: (res: BaseResponse) => {
        this.loading = false;
        if(res.statusRS?.code === '0'){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage("update", this.loc.userType)
          );
          this.loadUserTypes();
        }else{
          this.snackbarService.openErrorSnackbar(this.loc.genericError("update", this.loc.userType));
        }
      
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
      }
    })
  }

  delete(row: UserType) {
    let dataModal: DeleteModalData = {
      title: this.loc.userType.delete,
      icon: 'people',
      confirmDeleteMessage: this.loc.confirmDelete(
        this.loc.userType
      ),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      dataModal,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.usersService
          .deleteUserType(row.id!)
          .subscribe(
            (res: BaseResponse) => {
              if (res.statusRS?.code === '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage(
                    'delete',
                    this.loc.userType
                  )
                );
              } else if (res.statusRS?.code === '-91665') {
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.userTypeDoesntExist
                );
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError(
                    'delete',
                    this.loc.userType
                  )
                );
              }

              this.loadUserTypes();
            },
            (err) => {
              this.logoutDialogService.openLogoutDialog(err);
            }
          );
      }
    });
  }
}

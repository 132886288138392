<!-- elegir label, action result, description-->
<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
</div>

<div class="title">
    <div class="inner-title-container">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">settings</mat-icon>
            <mat-icon class="action-button-icon-add">add</mat-icon>
        </button>
        <label class="dialog-title" i18n>{{loc.apis.create}}</label>
    </div>
    <button class="exit-button" (click)="exit('not created')"  >
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>
    <mat-horizontal-stepper class="horizontal-stepper actions-stepper" [ngClass]="'three-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
        [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex">
        <ng-template matStepperIcon="done" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">account_tree</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 2">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="edit" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">account_tree</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 2">check_box</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="number" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">account_tree</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 2">check_box</mat-icon>
        </ng-template>

        <mat-step [stepControl]="firstFormGroup" [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
            <form [formGroup]="firstFormGroup" class="form-step">
                <div class="data-box">
                <div class="create-row">
                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.id}}</mat-label>
                        <input matInput name="apiId" formControlName="apiId" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.name}}</mat-label>
                        <input matInput name="apiName" formControlName="apiName" required>
                    </mat-form-field>

                </div>
                <div class="create-row">
                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.externalEntity}}</mat-label>
                       
                            <mat-select formControlName="externalEntity"  (selectionChange)="loadFrameAPIs($event.value.name,'entity')">
                               
                                <mat-select-trigger>
                                    <div class="country-div">
                                      <mat-label class="selected-country-lbl"
                                        >{{ firstFormGroup.get("externalEntity")?.value?.name }}
                                      </mat-label>
                                    </div>
                                </mat-select-trigger>
                             
                                <mat-option *ngFor="let option of externalEntities" [value]="option">
                                {{ option.name }}
                              </mat-option>
                            </mat-select>
                        
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.groupName}}</mat-label>
                      
                            <mat-select formControlName="groupName" (selectionChange)="loadFrameAPIs($event.value.groupdesc0613,'group')">
                              <mat-option *ngFor="let option of groupNames" [value]="option">
                                {{ option.groupdesc0613 }}
                              </mat-option>
                            </mat-select>
                          
                    </mat-form-field>
                </div>
                <div class="create-row-align-start">
                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.frameAPIs}}</mat-label>
                      
                            <mat-select formControlName="frameAPIs"  (selectionChange)="getFrameDate($event.value)">
                              <mat-option *ngFor="let option of frameAPIs" [value]="option">
                                {{ option.description}}
                              </mat-option>
                            </mat-select>
                          
                    </mat-form-field>
                   
                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.path}}</mat-label>
                        <input matInput name="path" formControlName="path" readonly="true">
                    </mat-form-field>

                </div>
                <div class="create-row-align-start">
                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.method}}</mat-label>
                        <input matInput name="method" formControlName="method" readonly="true">
                    </mat-form-field>
                   
                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.version}}</mat-label>
                        <input matInput name="version" formControlName="version" readonly="true">
                    </mat-form-field>

                </div>
                </div>
                <div *ngIf="showErrorMessage">
                    <div class="error-msg">
                      <label>{{ errorMsg }}</label>
                    </div>
                  </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperNext>
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>


        <mat-step [stepControl]="secondFormGroup">
            <ng-template matStepLabel i18n>{{loc.stepTitle.addBody}}</ng-template>

            
            <form  [formGroup]="secondFormGroup" class="form-step">
                <mat-form-field  style="width: 450px;">
                    <textarea formControlName="body" class="text-area" matInput  style="font-size: 12px;" [(ngModel)]="body">{{body}}</textarea>
                </mat-form-field>
                <div class="error-msg" *ngIf="invalidJSON">
                    <label>{{loc.fields.invalidJSON}}</label>
                  </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious  >
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-fab class="button green-button" safeClick  (onSafeClick)="next(stepper)" >
                        <mat-icon  class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
               
            </form>
        </mat-step>


        <mat-step [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
            <div class="form-step">
                <div class="verify-large">
                    <div class="flex-container-vertical flex-container-vertical-modal">
                        <div>
                            <label class="card-name" i18n>{{loc.apis.newApi}}</label>
                        </div>

                            <div class="details-package-large details-flex">
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.id}}</div>
                                    <div class="card-value">{{firstFormGroup.get('apiId')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.name}}</div>
                                    <div class="card-value">{{firstFormGroup.get('apiName')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.externalEntity}}</div>
                                    <div class="card-value">{{firstFormGroup.get('externalEntity')?.value.name}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.groupName}}</div>
                                    <div class="card-value">{{firstFormGroup.get('groupName')?.value.groupdesc0613}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.frameAPIs}}</div>
                                    <div class="card-value">{{firstFormGroup.get('frameAPIs')?.value.description}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.path}}</div>
                                    <div class="card-value">{{firstFormGroup.get('path')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.method}}</div>
                                    <div class="card-value">{{firstFormGroup.get('method')?.value}}</div>
                                </div>
                                <div class="verify-vertical">
                                    <div class="card-header" i18n>{{loc.fields.version}}</div>
                                    <div class="card-value">{{firstFormGroup.get('version')?.value}}</div>
                                </div>

                            </div>
                    </div>

                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperPrevious  >
                        <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-fab class="button green-button" (click)="create()">
                        <mat-icon class="stepper-item-icon" fontSet="material-icons-outlined">save</mat-icon>
                    </button>
                </div>
            </div>
        </mat-step>


     

    </mat-horizontal-stepper>


import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CreateBankComponent } from 'src/app/banks/create-bank/create-bank/create-bank.component';
import { AccountIds } from 'src/app/models/accountIds/account-ids.model';
import { Bank } from 'src/app/models/Banks/bank.model';
import { CurrencyDenomination } from 'src/app/models/denominations/currency-denomination.model';
import { DenominationType } from 'src/app/models/denominations/denomination-type.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DenominationsService } from 'src/app/services/denominations/denominations.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-denomination',
  templateUrl: './create-denomination.component.html',
  styleUrls: ['./create-denomination.component.css'],
  host: { class: 'dialog-component' },
})
export class CreateDenominationComponent implements OnInit {
  loading = true;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  types: DenominationType[] = [];
  accounts: AccountIds[] = [];
  currencyCode: string = '';

  constructor(
    public dialogRef: MatDialogRef<CreateBankComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Bank[],
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private denominationsService: DenominationsService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      denominationId: ['', Validators.required],
      denominationType: ['', Validators.required],
      denominationName: ['', Validators.required],
      value: ['', Validators.required],
    });
    this.route.queryParamMap.subscribe((params) => {
      this.currencyCode = params.get('currencyCode')!;
    });
    this.loadTypes();
    this.loading = false;
  }

  loadTypes() {
    this.loading = true;
    this.denominationsService.getDenominationTypes().subscribe({
      next: (data: BaseResponse<DenominationType[]>) => {
        this.types = data.messageRS;
      },
      error: (_err: any) => {
        this.loading = false;
      },
    });
  }

  create() {
    this.loading = true;

    let branchOperation: CurrencyDenomination = {
      id: this.firstFormGroup.controls.denominationId.value,
      type: this.firstFormGroup.controls.denominationType.value.id,
      currencyCode: this.currencyCode,
      name: this.firstFormGroup.controls.denominationName.value,
      value: this.firstFormGroup.controls.value.value,
    };

    this.denominationsService.createDenomination(branchOperation).subscribe({
      next: (res: BaseResponse<CurrencyDenomination>) => {
        this.loading = false;
        switch (res.statusRS?.code) {
          case '0':
            this.exit('created');
            break;
          case '-91887':
            this.exit('duplicate');
            break;
          default:
            this.exit('error');
            break;
        }
      },
      error: (err) => {
        this.loading = false;
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      },
    });
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }
}

<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
  </div>
  <div class="confirm-title">
    <div class="confirm-title-inner-div">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon">air</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon-edit">add</mat-icon>
      </button>
    <div class="dialog-title"><label i18n>{{loc.step.newPostingGroup}}</label></div>
    </div>
    <button class="exit-button" (click)="exit('not updated')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
  

    <form class="form" [formGroup]="formGroup">
        <div class="create-row">
            <mat-form-field  appearance="fill">
              <mat-label i18n>{{loc.fields.id}}</mat-label>
              <input matInput formControlName="id">
           </mat-form-field>
        
           <mat-form-field  appearance="fill">
              <mat-label i18n>{{loc.fields.description}}</mat-label>
              <input matInput formControlName="description">
           </mat-form-field>

        </div>
     
    </form>
  
  
  <div class="confirm-buttons">
      <button mat-fab class="button green-color" (click)="add()" [disabled]="formGroup.invalid" >
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon">save</mat-icon>
    </button>
  </div>
  
  
  
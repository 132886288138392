<ng-container *ngIf="!loading">
    <app-table
      [emptyState]="emptyState"
      [data]="data"
      [columns]="columns"
      [transformers]="transformers"
      [paginatorInfo]="paginatorInfo"
      [title]="'branch-operations'"
      [filters]="filters"
      [actions]="actions"
      [createButton]="createButton"
      >
    </app-table>
  </ng-container>
  
  <ng-container *ngIf="loading">
    <app-loading-table
      [title]="'branch-operations'"
      [columns]="columns"
      [filters]="filters">
    </app-loading-table>
  </ng-container>
import { Component, OnInit } from '@angular/core';
import { Campaign } from 'src/app/models/campaign/campaign.model';
import { CampaignsService } from 'src/app/services/campaigns/campaigns.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateCampaignComponent } from '../create-campaign/create-campaign.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import {
  Action,
  Column,
  CreateButton,
  EmptyState,
  PaginatorInfo,
  TableData,
  TableFilter,
} from 'src/app/utils/table/table.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { FormGroup, Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';

@Component({
  selector: 'app-list-campaigns',
  templateUrl: './list-campaigns.component.html',
  styleUrls: ['./list-campaigns.component.css'],
})
export class ListCampaignsComponent implements OnInit {
  loading = true;
  fields: any[] = []
  campaignsIds: string[] = [];
  campaigns: Campaign[] = [];

  constructor(
    private campaignsService: CampaignsService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.listCampaigns();
  }

  emptyState: EmptyState = {
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.createCampaign(),
      },
    ],
    image: 'assets/img/empty-campaigns.png',
    message: this.loc.campaigns.emptyState,
  };

  data: TableData[] = [];

  columns: Column[] = [
    {
      key: 'campaignId',
      title: this.loc.tableHeaders.id,
      centered: false,
      sortable: true,
    },
    {
      key: 'description',
      title: this.loc.tableHeaders.description,
      centered: false,
      sortable: true,
    },
    {
      key: 'percentage',
      title: this.loc.tableHeaders.percentage,
      centered: false,
      sortable: true,
    },
    {
      key: 'agentShare',
      title: this.loc.tableHeaders.agentDistribution,
      centered: false,
      sortable: true,
    },
    {
      key: 'financialInstitutionShare',
      title: this.loc.tableHeaders.financialInstitutionDistribution,
      centered: false,
      sortable: true,
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.campaigns;

  filters: TableFilter[] = [
    {
      key: 'campaignId',
      label: this.loc.tableHeaders.id,
      placeholder: this.loc.tableHeaders.id,
      type: 'text',
    },
    {
      key: 'description',
      label: this.loc.tableHeaders.description,
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    },
    {
      key: 'percentage',
      label: this.loc.tableHeaders.percentage,
      placeholder: this.loc.tableHeaders.percentage,
      type: 'text',
    },
    {
      key: 'agentShare',
      label: this.loc.tableHeaders.agentDistribution,
      placeholder: this.loc.tableHeaders.agentDistribution,
      type: 'text',
    },
    {
      key: 'financialInstitutionShare',
      label: this.loc.tableHeaders.financialInstitutionDistribution,
      placeholder: this.loc.tableHeaders.financialInstitutionDistribution,
      type: 'text',
    },
  ];

  actions: Action[] = [
    {
      action: (row: Campaign) => this.editCampaign(row),
      icon: 'edit',
      tooltip: this.loc.campaigns.edit,
    },
    {
      action: (row: Campaign) => this.deleteCampaign(row),
      icon: 'delete',
      tooltip: this.loc.campaigns.delete,
    },
  ];

  createButton: CreateButton = {
    tooltip: this.loc.common.create,
    action: () => this.createCampaign(),
  };

  listCampaigns(): void {
    this.loading = true;
    this.campaignsService.getCampaigns().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.data = res.messageRS as unknown as Array<TableData>;
        } else {
          // TODO HANDLE ERROR
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  editCampaign(row: Campaign): void {
    this.fields = [
      { label: this.loc.tableHeaders.description,
        value: row.description,
        errorMsg: this.loc.errors.emptyDescription,
        validators: [Validators.required],
      },
      { label: this.loc.fields.discount,
        value: row.percentage,
        errorMsg: this.loc.campaigns.errorEditPercentage,
        validators: [Validators.required, Validators.min(1), Validators.max(100)],
        inputAction: (fg:FormGroup) => this.inputAction(fg),
        type: "number"
      },
      { label: this.loc.fields.agentDistribution,
        value: row.agentShare,
        errorMsg: this.loc.campaigns.errorEditAgentDistribution,
        validators: [Validators.required, Validators.min(0), Validators.max(100)],
        inputAction: (fg:FormGroup) => this.inputAction(fg),
        type: "number"
      },
      { label: this.loc.tableHeaders.financialInstitutionDistribution,
        value: row.financialInstitutionShare,
        errorMsg: this.loc.campaigns.errorEditFinInstPercentage,
        validators: [Validators.required, Validators.min(0), Validators.max(100)],
        inputAction: (fg:FormGroup) => this.inputAction(fg),
        type: "number"
      }]
    let data: EditModalData = {
      title: this.loc.campaigns.edit,
      icon: 'campaign',
      fields: this.fields
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyCampaign(row, result)
      }
    });
  }

  inputAction(formGroup: FormGroup){
    if(formGroup.get(this.loc.fields.discount)?.value > 100){
      formGroup.get(this.loc.fields.discount)?.setValue(0)
    }
    if(formGroup.get(this.loc.fields.discount)?.value < 0){
      formGroup.get(this.loc.fields.discount)?.setValue(1)
    }
    formGroup.get(this.loc.tableHeaders.financialInstitutionDistribution)?.setValue(100 - formGroup.get(this.loc.fields.agentDistribution)?.value);
  }

  modifyCampaign(row: any, result: any): void {
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value
    let newPercentage = result.find((x: any) => x.label === this.loc.fields.discount).value
    let newAgentPercentage = result.find((x: any) => x.label === this.loc.fields.agentDistribution).value
    let newFInstitutionPercentage = result.find((x: any) => x.label === this.loc.tableHeaders.financialInstitutionDistribution).value

    const campaign: Campaign = {
      campaignId: row.campaignId,
      description: newDescription,
      percentage: newPercentage,
      agentShare: newAgentPercentage,
      financialInstitutionShare: newFInstitutionPercentage,
    };
      this.campaignsService.editCampaign(campaign).subscribe(
        {
          next: (res: BaseResponse) => {
            this.loading = false;
            if (res.statusRS?.code === '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('update', this.loc.campaigns, 'f')
              );
              this.listCampaigns();
            }else{
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.campaigns)
              );
            }
        },
          error: (err) => {
            this.loading = false;
            console.log(err);
            if (err.status !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.campaigns)
              );
            }
          }
        }
      );
  }

  deleteCampaign(row: Campaign): void {
    let data: DeleteModalData = {
      title: this.loc.campaigns.delete,
      icon: 'campaign',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.campaigns),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        this.campaignsService.deleteCampaign(row.campaignId).subscribe({
          next: (res: BaseResponse) => {
            setTimeout(() => {
              this.loading = false;
              if (res.statusRS?.code === '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.campaigns)
                );
                this.listCampaigns();
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.campaignBeingUsed
                );
              }
            }, 200);
          },
          error: (err) => {
            setTimeout(() => {
              this.loading = false;
            }, 200);
            console.log(err);
            if (err.status !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.campaigns)
              );
            }
          },
        });
      }
    });
  }

  createCampaign(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateCampaignComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.campaigns)
        );
        this.listCampaigns();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.campaigns)
        );
      } else if (result === 'invalid') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.campaignAlreadyExists
        );
      }
    });
  }
}

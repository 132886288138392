import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { NotificationType } from 'src/app/models/notifications/notification-types.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  readonly baseURL = environment.apiUrl; 
  
  constructor(
    private http: HttpClient
  ) { }

  getApplications(): Observable<any> {
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/applications/application/x/get'
    );
  }

  createApplication(id: string, description: string): Observable<any>{
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id,
      description
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/applications/application/x/create',
      baseRequest
    );
  }

  deleteApplication(id: string): Observable<any>{
    return this.http.delete(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/applications/application/'+ id +'/delete'
    );
  }

  editApplication(id: string, description:string): Observable<any>{
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      description
    };
    return this.http.put(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/applications/application/'+ id +'/update-description',
        baseRequest
    );
  }

  getPlatforms(): Observable<any> {
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/platforms/platform/x/get'
    );
  }

  createPlatform(id: string, description: string): Observable<any>{
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id,
      description
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/platforms/platform/x/create',
      baseRequest
    );
  }

  deletePlatform(id: string): Observable<any>{
    return this.http.delete(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/platforms/platform/'+ id +'/delete'
    );
  }

  editPlatform(id: string, description:string): Observable<any>{
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      description
    };
    return this.http.put(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/platforms/platform/'+ id +'/update-description',
        baseRequest
    );
  }

  getEntities(): Observable<any> {
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-entity/x/get'
    );
  }

  createEntity(id: string, description: string): Observable<any>{
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id,
      description
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-entity/x/create',
      baseRequest
    );
  }
  deleteEntity(id: string): Observable<any>{
    return this.http.delete(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-entity/'+ id +'/delete'
    );
  }

  editEntity(id: string, description:string): Observable<any>{
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      description
    };
    return this.http.put(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-entity/'+ id +'/update-description',
        baseRequest
    );
  }

  getTypes(): Observable<any> {
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-type/x/get'
    );
  }

  deleteType(id: string, platform: string, application: string, method: string): Observable<any>{
    const HTTP_OPTIONS = {
      params: {
        platform,
        application,
        method
      }
    };
    return this.http.delete(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-type/'+ id +'/delete',
        HTTP_OPTIONS
    );
  }

  editTypeStatus(notificationType: NotificationType): Observable<any>{
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      isActive: notificationType.status,
      platform: notificationType.platform,
      application: notificationType.application,
      method: notificationType.method
    };
    return this.http.put(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-type/'+ notificationType.id +'/update-status',
        baseRequest
    );
  }

  editType(notificationType: NotificationType): Observable<any>{
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      description: notificationType.description,
      platform: notificationType.platform,
      application: notificationType.application,
      method: notificationType.method
    };
    return this.http.put(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-type/'+ notificationType.id +'/update-description',
        baseRequest
    );
  }

  createType(notificationType: NotificationType): Observable<any>{
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id: notificationType.id,
      description: notificationType.description,
      platform: notificationType.platform,
      application: notificationType.application,
      method: notificationType.method,
      entity: notificationType.entity,
      isActive:notificationType.status
    };
    return this.http.post(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-type/x/create',
      baseRequest
    );
  }

  getMethods(): Observable<any>{
    return this.http.get(
      this.baseURL +
        'RSAdaptorFE/api/v1/paycorr/notifications/notification-method/x/get'
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';

@Component({
  selector: 'app-create-posting-group',
  templateUrl: './create-posting-group.component.html',
  styleUrls: ['./create-posting-group.component.css'],
  host: {
    class: 'confirmation-dialog-component'
  }
})
export class CreatePostingGroupComponent implements OnInit {
  loading: boolean = false;

  formGroup!: UntypedFormGroup;

  constructor(
    private postingSchemesService: PostingSchemesService,
    public dialogRef: MatDialogRef<CreatePostingGroupComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      id: ['', Validators.required],
      description: ['', Validators.required]     
    });
     
  }

  exit(message: string) {
    this.dialogRef.close(message);
  }

  add(){
    this.loading = true;
    let id = this.formGroup.get('id')?.value;
    let description = this.formGroup.get('description')?.value;

    this.postingSchemesService.createPostingGroup(id, description).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit('created:' + id);
          } else {
            this.exit('error');
          }
        }, 200
        )
      }, (err) => {
        if (err.status !== 401) {
          this.exit('error');
        }
      }
    )
  }

}

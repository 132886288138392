<div class="dark" *ngIf="loading">
  <mat-spinner
    diameter="70"
    style="
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -35px;
      margin-top: -35px;
    "
  ></mat-spinner>
</div>
<div class="title">
  <div class="inner-title-container">
    <button mat-fab class="confirm-dialog-button blue-button-icon">
      <mat-icon class="action-button-icon" fontSet="material-icons-outlined"
        >people</mat-icon
      >
      <mat-icon class="action-button-icon-add">add</mat-icon>
    </button>
    <div class="dialog-title">
      <label i18n> {{ loc.users.create }} </label>
    </div>
  </div>

  <button class="exit-button" (click)="exit('not created')">
    <mat-icon class="exit-icon">close</mat-icon>
  </button>
</div>

<mat-horizontal-stepper
  class="horizontal-stepper very-short-stepper"
  [ngClass]="'five-step last-edited-step-' + stepper.selectedIndex"
  labelPosition="bottom"
  [linear]="true"
  #stepper
  appStepper
  [step]="stepper.selectedIndex"
>
  <ng-template matStepperIcon="done" let-index="index">
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 0"
      >description</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 1"
      >fingerprint</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 2"
      >add_a_photo</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 3"
      >check_box</mat-icon
    >
  </ng-template>

  <ng-template matStepperIcon="edit" let-index="index">
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 0"
      >description</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 1"
      >fingerprint</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 2"
      >add_a_photo</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 3"
      >check_box</mat-icon
    >
  </ng-template>

  <ng-template matStepperIcon="number" let-index="index">
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 0"
      >description</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 1"
      >fingerprint</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 2"
      >add_a_photo</mat-icon
    >
    <mat-icon
      fontSet="material-icons-outlined"
      class="stepper-icon"
      *ngIf="index == 3"
      >check_box</mat-icon
    >
  </ng-template>

  <mat-step [stepControl]="firstFormGroup" [editable]="true">
    <ng-template matStepLabel i18n>{{ loc.stepTitle.basicData }}</ng-template>
    <form [formGroup]="firstFormGroup" class="form-step">
      <div class="data-box">
        <div class="create-row" style="justify-content: flex-start">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{ loc.fields.name }}</mat-label>
            <input
              matInput
              name="firstName"
              formControlName="firstName"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n>{{ loc.fields.lastName }}</mat-label>
            <input
              matInput
              name="lastName"
              formControlName="lastName"
              required
            />
          </mat-form-field>
        </div>
        <div class="create-row" style="justify-content: flex-start">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{ loc.fields.cellphone }}</mat-label>
            <input
              matInput
              type="number"
              name="cellphone"
              formControlName="cellphone"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n>{{ loc.fields.email }}</mat-label>
            <input
              matInput
              type="email"
              name="email"
              formControlName="email"
              required
              ngModel
              email
            />
          </mat-form-field>
        </div>
        <div class="create-row" style="justify-content: flex-start">
          <div class="country-select-container">
            <mat-form-field appearance="fill" class="currency">
              <mat-label i18n>{{ loc.fields.nationality }}</mat-label>
              <mat-select formControlName="nationality">
                <mat-select-trigger>
                  <div class="country-div">
                    <mat-label class="selected-country-lbl"
                      >{{ firstFormGroup.get("nationality")?.value?.countryId }}
                    </mat-label>
                  </div>
                  <img
                    [src]="firstFormGroup.get('nationality')?.value?.image"
                    class="selected-country-flag"
                  />
                </mat-select-trigger>
                <mat-option [value]="country" *ngFor="let country of countries"
                  >{{ country.countryId }}
                  <img [src]="country.image" class="country-flag" />
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="manager-select-container">
            <mat-form-field appearance="fill" style="justify-content: flex-start">
              <mat-label i18n>{{ loc.tableHeaders.managerType }}</mat-label>
              <mat-select formControlName="managerType">
                <mat-option [value]="type" *ngFor="let type of managers">
                  {{ type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
        </div>
      </div>
      <div class="dialog-nav-buttons">
        <button
          mat-fab
          class="button green-button"
          style="margin-top: 60px"
          matStepperNext matStepperNext [disabled]="!isFormValid()"
        >
          <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup" [editable]="true">
    <ng-template matStepLabel i18n>{{
      loc.stepTitle.identification
    }}</ng-template>
    <form [formGroup]="secondFormGroup" class="form-step">
      <div class="data-box">
        <div class="create-row" style="justify-content: flex-start">
          <div class="country-select-container">
            <mat-form-field appearance="fill" class="currency">
              <mat-label i18n>{{ loc.fields.country }}</mat-label>
              <mat-select formControlName="country" required>
                <mat-select-trigger>
                  <div class="country-div">
                    <mat-label class="selected-country-lbl"
                      >{{ secondFormGroup.get("country")?.value?.countryName }}
                    </mat-label>
                  </div>
                  <img
                    [src]="secondFormGroup.get('country')?.value?.image"
                    class="selected-country-flag"
                  />
                </mat-select-trigger>
                <mat-option [value]="country" *ngFor="let country of countries"
                  >{{ country.countryName }}
                  <img [src]="country.image" class="country-flag" />
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill">
            <mat-label i18n>{{ loc.fields.idType }}</mat-label>
            <mat-select
              name="type"
              formControlName="identificationType"
              required
            >
              <mat-option
                *ngFor="let type of idTypes"
                [value]="type.identificationTypeId"
                >{{ type.description }}</mat-option
              >
            </mat-select>
          </mat-form-field>         
        </div>
        <div class="create-row" style="justify-content: flex-start">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{ loc.fields.identificationNumber }}</mat-label>
            <input
              matInput
              type="number"
              name="identificationNumber"
              formControlName="identificationNumber"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="dialog-nav-buttons">
        <button
          mat-fab
          class="button green-button"
          style="margin-top: 60px"
          matStepperPrevious 
        >
          <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button
          mat-fab
          class="button green-button"
          style="margin-top: 60px"
          matStepperNext
          matStepperNext [disabled]="!isFormValid()"
        >
          <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup" [editable]="true">
    <ng-template matStepLabel i18n>{{ loc.stepTitle.style }}</ng-template>
    <div [formGroup]="thirdFormGroup" class="form-step">
      <div class="data-box">
        <div style="display: inline-block">
          <div class="image-box">
            <div>
              <mat-icon *ngIf="!imageProfile" class="default-img"
                >add_a_photo</mat-icon
              >
              <img
                *ngIf="imageProfile"
                [src]="imageProfile"
                alt="img_user"
                class="image"
              />
            </div>
          </div>

          <button
            mat-fab
            class="image-button button green-color"
            (click)="hiddenfileinput.click()"
            i18n-matTooltip
            [matTooltip]="loc.common.addPhoto"
          >
            <mat-icon fontSet="material-icons-outlined"> camera_alt </mat-icon>
          </button>

          <input
            type="file"
            style="display: none"
            accept="image/*"
            (change)="onFileSelect($event)"
            #hiddenfileinput
          />
        </div>
      </div>
      <div>
        <div class="create-row" style="justify-content: flex-start">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{ loc.fields.user }}</mat-label>
            <input
              matInput
              name="userAccount"
              formControlName="userAccount"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="dialog-nav-buttons">
        <button mat-fab class="button green-button" matStepperPrevious>
          <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button
          mat-fab
          class="button green-button"
          matStepperNext
          (click)="setupVerify()"
          matStepperNext [disabled]="!isFormValid()"
        >
          <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step [editable]="true">
    <ng-template matStepLabel i18n>{{ loc.stepTitle.verify }}</ng-template>
    <div class="form-step">
      <div class="verify-large">
        <div class="flex-container-vertical flex-container-vertical-modal">
          <div>
            <label class="card-name" i18n>{{ loc.users.new }}</label>
          </div>
          <div class="details-package-large details-flex">
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.name }}</div>
              <div class="card-value">
                {{ firstFormGroup.get("firstName")?.value }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.lastName }}</div>
              <div class="card-value">
                {{ firstFormGroup.get("lastName")?.value }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.cellphone }}</div>
              <div class="card-value">
                {{ firstFormGroup.get("cellphone")?.value }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.email }}</div>
              <div class="card-value">
                {{ firstFormGroup.get("email")?.value }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.nationality }}</div>
              <div class="card-value">
                {{ firstFormGroup.get("nationality")?.value.countryId }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>
                {{ loc.fields.identificationType }}
              </div>
              <div class="card-value">
                {{ secondFormGroup.get("identificationType")?.value }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>
                {{ loc.fields.identificationNumber }}
              </div>
              <div class="card-value">
                {{ secondFormGroup.get("identificationNumber")?.value }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.country }}</div>
              <div class="card-value">
                {{ secondFormGroup.get("country")?.value.countryName }}
              </div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{ loc.fields.user }}</div>
              <div class="card-value">
                {{ thirdFormGroup.get("userAccount")?.value }}
              </div>
            </div>
          </div>

          <div>
            <label class="card-name" i18n>{{ loc.fields.style }}</label>
          </div>
          <div class="details-package-large">
            <div style="padding-left: 25px; margin: 8px">
              <div
                style="
                  height: 140px;
                  width: 140px;
                  border-radius: 50%;
                  background-color: #d3d3d345;
                  text-align: center;
                "
              >
                <mat-icon *ngIf="!imageProfile" class="default-img"
                  >add_a_photo</mat-icon
                >
                <img
                  *ngIf="imageProfile"
                  [src]="imageProfile"
                  alt="img_user"
                  class="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-nav-buttons">
        <button mat-fab class="button green-button" matStepperPrevious>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button mat-fab class="button green-button" (click)="saveImg()">
          <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon"
            >save</mat-icon
          >
        </button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { Location } from '@angular/common';
import { NonWorkingDay } from 'src/app/models/calendar/nonWorkingDay.model';
import { WorkingDaysComponent } from '../working-days/working-days.component';

@Component({
  selector: 'app-calendar-details',
  templateUrl: './calendar-details.component.html',
  styleUrls: ['./calendar-details.component.css'],
  host: {
    class: 'component',
  },
})
export class CalendarDetailsComponent implements OnInit {
  loading = true;
  name: string = '';

  calendarId: string = '';
  calendarDescription: string = '';

  nonWorkingDaysFieldsDataSource = new MatTableDataSource<NonWorkingDay>([]);
  myControl = new UntypedFormControl();
  filters: any = {
    id: JSON.parse(localStorage.getItem('state')!)?.nonWorkingDays?.id || '',
  };
  filteredOptions: Observable<string[]> = new Observable<string[]>();
  fieldsId: string[] = [];
  displayedColumns: string[] = [
    'id',
    'fieldId',
    'name',
    'isMandatory',
    'actions',
  ];
  loadingDataSource = new MatTableDataSource<string>();
  pageIndex: number = 0;
  pageSize: number = 5;
  @Input() tableStyle = 'tableResponsive';

  action: any;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public logoutDialogService: LogoutDialogService,
    public extensionPointsService: ExtensionPointsService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      (this.calendarId = params['calendarId']),
        (this.calendarDescription = params['calendarDescription']);
    });
    this.loading = false;
  }

  @ViewChild(MatPaginator, { static: false }) set paginator(
    value: MatPaginator
  ) {
    this.loc.translateMatPaginator(value);
    if (this.nonWorkingDaysFieldsDataSource) {
      this.nonWorkingDaysFieldsDataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false }) set sort(value: MatSort) {
    if (this.nonWorkingDaysFieldsDataSource) {
      this.nonWorkingDaysFieldsDataSource.sort = value;
    }
  }

  createWorkingDays(): void {
    const dialogRef = this.dialogService.openDialog(
      WorkingDaysComponent,
      this.calendarId,
      'large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.workingDays)
        );
      }
      else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.workingDays)
        );
      }
    });
  }

  goBack(): void {
    this.location.back();
    let state = JSON.parse(localStorage.getItem('state')!);
    state.mediums = {};
    localStorage.setItem('state', JSON.stringify(state));
  }
}

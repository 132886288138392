import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { MetadataField } from 'src/app/models/metadata/metadata-field/metadataField.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { Action, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { CreateDataComponent } from '../../create-data/create-data.component';

@Component({
  selector: 'app-list-metadata-fields-datasource-data',
  templateUrl: './list-metadata-fields-datasource-data.component.html',
  styleUrls: ['./list-metadata-fields-datasource-data.component.css'],
  host: {
    class: 'component',
  }
})
export class ListMetadataFieldsDatasourceDataComponent implements OnInit, OnChanges {
  loading = false;
  @Input() datasourceData: string | any = '';
  datasourceStruct?: any[];
  @Input() metadataField: MetadataField | any;
  boolOptions: any[] = [{
    key: 1,
    value: this.loc.common.yes
  },
  {
    key: 0,
    value: this.loc.common.no
  }
  ];
  @Output() datasourceDataChanged = new EventEmitter<string>();
  
  nofityDatasourceDataChanged() {
    this.datasourceDataChanged.emit('Reload data!');
  }

  constructor(
    public loc: LocalizationService,
    private htmlGenerator: SafeHTMLGeneratorService,
    public dialogService: DialogService,
    private metadataService: MetadataService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasourceData) {
      if(this.datasourceData){
        this.loading = false;
        try {
          this.datasourceStruct = JSON.parse(this.datasourceData)

        } catch {
          this.datasourceStruct = this.datasourceData
        }
        this.data = this.datasourceStruct as any as TableData[];
      }
    }
      if(changes.metadataField){
        this.loading = false;
        this.metadataField = this.metadataField;
      }
      if(!changes.datasourceData && !changes.metadataField){
        this.loading = true;
      }
    
  }

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.metadataField.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.createData(),
      },
    ],
  };

  data: TableData[] = [];

  columns: Column[] = [
    { title: this.loc.tableHeaders.identification, 
      key: 'key', 
      sortable: true 
    },
    { title: this.loc.tableHeaders.description, 
      key: 'keyDesc', 
      sortable: true 
    },
    {
      title: this.loc.tableHeaders.isHidden,
      key: 'isHidden',
      sortable: true,
    },
    {
      title: this.loc.fields.checkout,
      key: 'isHiddenCheckout',
      sortable: true,
    }
  ];

  actions: Action[] = [
    {
      icon: 'edit',
      tooltip: this.loc.step.edit,
      action: (row: any) => {
        this.editData(row);
      },
    },
    {
      icon: 'delete',
      tooltip: this.loc.step.delete,
      action: (row: any) => {
        this.deleteData(row);
      },
    },
  ];

  createButton: CreateButton = {
    tooltip: this.loc.step.create,
    action: () => this.createData(),
  };

  transformers: TableTransformer = {
    isHidden: (value: number) =>
      this.htmlGenerator.statusIcon(value == 1),
    isHiddenCheckout: (value: number) =>
      this.htmlGenerator.statusIcon(value == 1),
  };

  createData(){
    const dialogRef = this.dialogService.openDialog(
      CreateDataComponent,
      {
        isStepMetadata: false,
      },
      'medium-large'
    );
    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      if (result !== undefined && result !== 'error') {
          let newData = this.datasourceStruct;
          let data2 = JSON.parse(result)
          let aux = newData?.find(x=> x.key == data2.key);
          if(aux){
            this.loading = false;
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('create', this.loc.metadataField)
            );
          } else {
            newData?.push(data2)
            let auxMetadataField = {
              id: this.metadataField.id,
              description: this.metadataField.description,
              type: this.metadataField.type,
              controlType: this.metadataField.controlType,
              defaultValue: this.metadataField.defaultValue,
              isMandatory: this.metadataField.isMandatory,
              isEditable: this.metadataField.isEditable,
              isHidden: this.metadataField.isHidden,
              datasource: this.metadataField.datasource,
              datasourceData: newData,
              validation: this.metadataField.validation,
              checkout: this.metadataField.checkout
            }
            this.metadataService.editMetadataField(auxMetadataField).subscribe({
              next: (res: any) => {
                this.loading = false;
                if (res.statusRS?.code == '0') {
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage('create', this.loc.metadataField)
                  );
                  this.nofityDatasourceDataChanged()
                } else {
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError('create', this.loc.metadataField)
                  );
                }
              },
              error: (err) => {
                this.snackbarService.openErrorSnackbar(
                  this.loc.genericError('create', this.loc.metadataField)
                );
              },
  
            })
          }

        
      } else if (result === 'error') {
        this.loading = false;
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('create', this.loc.metadataField)
          );
        }
    }
  );
  }

  editData(row: any){
  let oldKey = row.key;
  let data: EditModalData = {
  title: this.loc.data.edit,
  icon: 'psychology',
  fields: [
    {
      label: this.loc.tableHeaders.identification,
      value: row.key,
      validators: [Validators.required]
    },
    {
      label: this.loc.tableHeaders.description,
      value: row.keyDesc,
      validators: [Validators.required]
    },
    {
      label: this.loc.tableHeaders.isHidden,
      value: row.isHidden,
      validators: [Validators.required],
      comboValues: this.boolOptions,
      isCombo: true
    },
    {
      label: this.loc.fields.checkout,
      value: row.isHiddenCheckout,
      comboValues: this.boolOptions,
      validators: [Validators.required],
      isCombo: true
    }
  ]
  };
  const dialogRef = this.dialogService.openDialog(
    EditModalComponent,
    data,
    'small',
    false,
    'edit-metadata-data'
  );
  dialogRef.afterClosed().subscribe(result => {
    if (result && result !== 'not updated') {
      this.loading = true;
      let key = result.find(
        (x: any) => x.label === this.loc.tableHeaders.identification
      ).value;
      let value = result.find(
        (x: any) => x.label === this.loc.tableHeaders.description
      ).value;
      let isHidden = result.find(
        (x: any) => x.label === this.loc.tableHeaders.isHidden
      ).value;
      let isHiddenCheckout = result.find(
        (x: any) => x.label === this.loc.fields.checkout
      ).value;
      let datasourceData: any = {
        key: key,
        keyDesc: value,
        isHidden: isHidden,
        isHiddenCheckout: isHiddenCheckout
      };

    let newData = datasourceData;
    let newDatasourceData = this.datasourceStruct!.filter(item => item.key !== oldKey);
    newDatasourceData.push(newData);
    let auxMetadataField =  {
      id: this.metadataField.id,
      description: this.metadataField.description,
      type: this.metadataField.type,
      controlType: this.metadataField.controlType,
      defaultValue: this.metadataField.defaultValue,
      isMandatory: this.metadataField.isMandatory,
      isEditable: this.metadataField.isEditable,
      isHidden: this.metadataField.isHidden,
      datasource: this.metadataField.datasource,
      datasourceData: newDatasourceData ,
      validation: this.metadataField.validation,
      checkout: this.metadataField.checkout,
    };
    this.metadataService.editMetadataField(auxMetadataField).subscribe({
      next: (res: any) => {
        this.loading = false;
        if (res.statusRS?.code == '0') {
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('update', this.loc.metadataField)
          );
          this.nofityDatasourceDataChanged()
        } else {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.metadataField)
          );
        }
      },
      error: (err) => {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.metadataField)
        );
      },

    })
  }});
  }


  deleteData(row: any){
    let data: DeleteModalData = {
      title: this.loc.metadataField.delete,
      icon: 'psychology',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.metadataField),
    };
    const dialogRef = this.dialogService.openDialog(DeleteModalComponent, data, 'x-small');
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        let newData = this.datasourceStruct!.filter(item => item.key !== row.key);
        let auxMetadataField = {
          id: this.metadataField.id,
          description: this.metadataField.description,
          type: this.metadataField.type,
          controlType: this.metadataField.controlType,
          defaultValue: this.metadataField.defaultValue,
          isMandatory: this.metadataField.isMandatory,
          isEditable: this.metadataField.isEditable,
          isHidden: this.metadataField.isHidden,
          datasource: this.metadataField.datasource,
          datasourceData: newData ? newData: [],
          validation: this.metadataField.validation,
          checkout: this.metadataField.checkout
        }
        this.metadataService.editMetadataField(auxMetadataField).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.metadataField)
              );
              this.nofityDatasourceDataChanged()
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.metadataField)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.metadataField)
            );
          },
    
        })
      }});
  }

}

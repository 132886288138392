import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Tax } from 'src/app/models/taxes/tax.model';
import { Country } from 'src/app/models/country/country.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CountriesService } from '../../../services/countries/countries.service';
import { BaseResponse } from 'src/app/models/responses.model';
import { CountryCurrency } from 'src/app/models/countryCurrency/countryCurrency';

@Component({
  selector: 'app-create-tax',
  templateUrl: './create-tax.component.html',
  styleUrls: ['./create-tax.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreateTaxComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  currencies: any[] =[];


  constructor(
    public dialogRef: MatDialogRef<CreateTaxComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Country,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private countriesService: CountriesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      id:['', Validators.required],
      value:['', Validators.required],
      description:['', Validators.required],
      currency:['', Validators.required],
    });
    this.listCountryCurrency();
  }

  listCountryCurrency(){
    this.countriesService.listCountryCurrencies(this.data.countryId!).subscribe(
      {
        next: (res: BaseResponse<CountryCurrency[]>) => {
          this.currencies = res.messageRS;
          if(this.currencies.length > 0){
            this.firstFormGroup.get('currency')!.setValue(this.currencies[0].code);
          }
          this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
      }
  })
}


  create (){
    this.loading = true;
    let newTax: Tax = {  
      country: this.data.countryId,
      currency: this.firstFormGroup.get('currency')!.value.code,
      version: "1",
      id: this.firstFormGroup.get('id')!.value,
      description: this.firstFormGroup.get('description')!.value,
      value: this.firstFormGroup.get('value')!.value,
    }
    
    this.countriesService.createTax(newTax).subscribe(
      (res: BaseResponse<void>) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }
  exit(created: string){
    this.dialogRef.close(created);
  }
  emptyField (text : string | undefined): string {
    if (text === '' || text === undefined)
      return "-";
    return text;
  }
}

<div class="dark" *ngIf="loading">
    <mat-spinner
      diameter="70"
      style="
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -35px;
        margin-top: -35px;
      "
    >
    </mat-spinner>
  </div>
  <div class="title">
    <div class="inner-title-container">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="material-icons">
          psychology
        </mat-icon>
        <mat-icon class="action-button-icon-add">add</mat-icon>
      </button>
      <p class="dialog-title" i18n>{{ isInput? loc.inputMetadata.create :  loc.outputMetadata.create}}</p>
    </div>
    <button class="exit-button" (click)="exit('not created')">
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
  
  <mat-horizontal-stepper
    class="create-metadata horizontal-stepper all"
    [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex"
    labelPosition="bottom"
    [linear]="isLinear"
    #stepper
    appStepper
    [step]="stepper.selectedIndex"
  >
    <ng-template matStepperIcon="done" let-index="index">
      <mat-icon
        fontSet="material-icons-outlined"
        class="stepper-icon"
        *ngIf="index == 0"
        >description</mat-icon
      >
      <mat-icon
        fontSet="material-icons-outlined"
        class="stepper-icon"
        *ngIf="index == 1"
        >check_box</mat-icon
      >
    </ng-template>
  
    <ng-template matStepperIcon="edit" let-index="index">
      <mat-icon
        fontSet="material-icons-outlined"
        class="stepper-icon"
        *ngIf="index == 0"
        >description</mat-icon
      >
      <mat-icon
        fontSet="material-icons-outlined"
        class="stepper-icon"
        *ngIf="index == 1"
        >check_box</mat-icon
      >
    </ng-template>
  
    <ng-template matStepperIcon="number" let-index="index">
      <mat-icon
        fontSet="material-icons-outlined"
        class="stepper-icon"
        *ngIf="index == 0"
        >description</mat-icon
      >
      <mat-icon
        fontSet="material-icons-outlined"
        class="stepper-icon"
        *ngIf="index == 1"
        >check_box</mat-icon
      >
    </ng-template>
    <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
      <ng-template matStepLabel i18n>{{ loc.stepTitle.basicData }}</ng-template>
      <form [formGroup]="firstFormGroup" class="form-step">
          <div class="central-div" style="width: 100%;">
          <div class="create-row">
           
            <mat-form-field appearance="fill">
              <mat-label i18n>{{ loc.fields.id }}</mat-label>
                <mat-select formControlName="id">
                    <mat-option *ngFor="let field of metadataFields" [value]="field.id">{{
                      field.description
                    }}</mat-option>
                  </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="fill">
              <mat-label i18n>{{ loc.fields.description }}</mat-label>
              <input
                matInput
                name="description"
                formControlName="description"
                required
              />
            </mat-form-field>
          </div>
          <div class="create-row">
            
            <mat-form-field appearance="fill">
              <mat-label i18n>{{ loc.fields.type }}</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let type of metadataTypes" [value]="type">{{
                  type.metadataName
                }}</mat-option>
              </mat-select>
            </mat-form-field>
           
            <mat-form-field appearance="fill">
              <mat-label i18n>{{ loc.fields.controlType }}</mat-label>
              <mat-select formControlName="controlType">
                <mat-option
                  *ngFor="let controlType of controlTypes"
                  [value]="controlType"
                  >{{ controlType }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="create-row">
            <mat-form-field appearance="fill">
              <mat-label class="nationality-lbl" i18n>{{
                loc.fields.defaultValue
              }}</mat-label>
              <input
                matInput
                name="defaultValue"
                formControlName="defaultValue"
              />
            </mat-form-field>
            <mat-form-field appearance="fill" [style.verticalAlign]="'top'">
              <mat-label i18n>{{ loc.fields.required }}</mat-label>
              <mat-select formControlName="isMandatory">
                <mat-option *ngFor="let option of options" [value]="option">{{
                  option
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="create-row">
            <mat-form-field appearance="fill">
              <mat-label class="nationality-lbl" i18n>{{
                loc.fields.editable
              }}</mat-label>
              <mat-select formControlName="isEditable">
                <mat-option *ngFor="let option of options" [value]="option">{{
                  option
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" [style.verticalAlign]="'top'">
              <mat-label i18n>{{ loc.fields.hidden }}</mat-label>
              <mat-select formControlName="isHidden">
                <mat-option *ngFor="let option of options" [value]="option">{{
                  option
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div class="create-row">
              <mat-form-field appearance="fill">
                <mat-label class="nationality-lbl" i18n>{{
                  loc.fields.datasource
                }}</mat-label>
                <mat-select formControlName="dataSource">
                  <mat-option
                    *ngFor="let option of datasource"
                    [value]="option"
                    >{{ option }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label i18n>{{ loc.fields.validation }}</mat-label>
                <input matInput name="validation" formControlName="validation" />
              </mat-form-field>
          </div>

          <div class="create-row">
            <mat-form-field appearance="fill" [style.verticalAlign]="'top'">
              <mat-label i18n>{{ loc.fields.checkout }}</mat-label>
              <mat-select formControlName="checkout">
                <mat-option *ngFor="let option of options" [value]="option">{{
                  option
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
  
        <div class="dialog-nav-buttons">
          <button mat-fab class="button green-button" matStepperNext (click)="next()">
            <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [editable]="isEditable">
      <ng-template matStepLabel i18n>{{ loc.stepTitle.verify }}</ng-template>
      <div class="form-step">
        <div class="verify-large">
          <div class="flex-container-vertical flex-container-vertical-modal verify-create" >
            <div>
              <div class="details-package-large details-grid">
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{ loc.fields.id }}</div>
                  <div class="card-value">{{ firstFormGroup.value.id }}</div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{ loc.fields.description }}</div>
                  <div class="card-value">
                    {{ firstFormGroup.value.description }}
                  </div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{ loc.fields.type }}</div>
                  <div class="card-value">
                    {{ firstFormGroup.value.type.metadataName }}
                  </div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{ loc.fields.controlType }}</div>
                  <div class="card-value">
                    {{ firstFormGroup.value.controlType }}
                  </div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>
                    {{ loc.fields.defaultValue }}
                  </div>
                  <div class="card-value">
                    {{ firstFormGroup.value.defaultValue }}
                  </div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{ loc.fields.required }}</div>
                  <div class="card-value">
                    {{ firstFormGroup.value.isMandatory }}
                  </div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{ loc.fields.editable }}</div>
                  <div class="card-value">
                    {{ firstFormGroup.value.isEditable }}
                  </div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{ loc.fields.hidden }}</div>
                  <div class="card-value">
                    {{ firstFormGroup.value.isHidden }}
                  </div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{ loc.fields.datasource }}</div>
                  <div class="card-value">
                    {{ firstFormGroup.value.dataSource }}
                  </div>
                </div>
                <div class="verify-vertical">
                  <div class="card-header" i18n>{{ loc.fields.checkout }}</div>
                  <div class="card-value">
                    {{ firstFormGroup.value.checkout }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-nav-buttons" style="margin-top: -43px;">
        <button mat-fab class="button green-button" matStepperPrevious>
          <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-fab class="button green-button" (click)="createMetadata()">
          <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon"
            >save</mat-icon
          >
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  

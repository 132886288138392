import { Component, Input, OnInit } from '@angular/core';
import { DetailCard } from '../models/DetailCard.model';

@Component({
  selector: 'app-details-card',
  templateUrl: './details-card.component.html',
  styleUrls: ['./details-card.component.css']
})
export class DetailsCardComponent implements OnInit {

  @Input()
  cards: DetailCard[] = []

  @Input()
  loading: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

}

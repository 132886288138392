import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Posting } from 'src/app/models/posting-schemes/Posting.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { Action, Column, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { Location } from '@angular/common';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Validators } from '@angular/forms';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-posting-flows',
  templateUrl: './posting-flows.component.html',
  styleUrls: ['./posting-flows.component.css'],
  host: {
    class: 'component',
  },
})
export class PostingFlowsComponent implements OnInit {
  loading = false;
  data: TableData[] = [];
  postingId = '';
  ordinal = 0;

  dispatchOnlineFlows: any[] = [];
  respParseOnlineFlows: any[] = [];
  messageOnlineFlows: any[] = [];

  dispatchOnlineComboValues: any[] = [];
  respParseOnlineComboValues: any[] = [];
  messageOnlineComboValues: any[] = [];

  constructor(
    public loc: LocalizationService,
    public location: Location,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private postingSchemesService: PostingSchemesService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.postingId = params['postingId'];
      this.ordinal = params['ordinal'];
    });
    this.listFlows();
    this.getPostingFlows();
  }

  columns: Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'flowId',
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'flowTypeDesc',
    },
    {
      title: this.loc.tableHeaders.type,
      key: 'flowType',
    },
    {
      title: this.loc.tableHeaders.version,
      key: 'flowVersion',
    },
    {
      title: this.loc.fields.externalEntity,
      key: 'externalEntity',
    },
  ];

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.flows.empty,
    buttons:[],
  };

  paginatorInfo = this.loc.flows;

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'flowId',
      placeholder: this.loc.tableHeaders.id,
      type: 'text',
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'flowTypeDesc',
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    },
    {
      label: this.loc.tableHeaders.type,
      key: 'flowType',
      placeholder: this.loc.tableHeaders.type,
      type: 'text',
    },
    {
      label: this.loc.fields.externalEntity,
      key: 'externalEntity',
      placeholder: this.loc.fields.externalEntity,
      type: 'text',
    },
    
  ];

  actions: Action[] = [
    {
      icon: 'edit',
      action: (row: Posting) => {
        this.editFlow(row);
      },
      tooltip: this.loc.flows.edit,
    },
  ];

  listFlows(){
    this.loading = true;
    this.postingSchemesService
    .getPostingDetails(this.postingId, this.ordinal)
    .subscribe(
      (res: BaseResponse) => {
        this.data = (res.messageRS?.flows).sort(((a: any, b: any) => (a.flowType!! < b.flowType!!) ? -1 : 1)) as any as TableData[];
        this.loading = false;
      },
      (err) => {
        console.log(err)
      }
    );
  }

  editFlow(row: any){
    let data: EditModalData = {
      title: '',
      icon: '',
      fields: []
    }
    let flowType = row.flowType;
    if(flowType === 'MESSAGE_FORMAT'){
      data = {
        title: this.loc.flows.edit,
        icon: 'signpost',
        fields: [
          { label: this.loc.fields.messageOnline, 
            value: row.flowId,
            errorMsg: this.loc.errors.emptyDescription, 
            validators: [Validators.required],
            comboValues: this.messageOnlineComboValues,
            isCombo: true
          },
        ]
      }
    } else if(flowType === 'DISPATCH_SYSTEM'){
      data = {
        title: this.loc.flows.edit,
        icon: 'signpost',
        fields: [
          { label: this.loc.fields.dispatchOnline, 
            value: row.flowId,
            errorMsg: this.loc.errors.emptyDescription, 
            validators: [Validators.required],
            comboValues: this.dispatchOnlineComboValues,
            isCombo: true
          },
        ]
      }
    } else if(flowType === 'RESPONSE_FORMAT'){
      data = {
        title: this.loc.flows.edit,
        icon: 'signpost',
        fields: [
          { label: this.loc.fields.respParseOnline, 
            value: row.flowId,
            errorMsg: this.loc.errors.emptyDescription, 
            validators: [Validators.required],
            comboValues: this.respParseOnlineComboValues,
            isCombo: true
          },
        ]
      }
    }
    
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') { 
        this.loading = true;
        let newFlowId = '';
        let newFlow: any = {};
        if(flowType === 'MESSAGE_FORMAT'){
          newFlowId = result.find((x: any) => x.label === this.loc.fields.messageOnline).value;
          newFlow = this.messageOnlineFlows.find((x: any) => x.DESCRI0171 === newFlowId);
        } else if(flowType === 'DISPATCH_SYSTEM'){
          newFlowId = result.find((x: any) => x.label === this.loc.fields.dispatchOnline).value;
          newFlow = this.dispatchOnlineFlows.find((x: any) => x.DESCRI0171 === newFlowId);

        } else if(flowType === 'RESPONSE_FORMAT'){
          newFlowId = result.find((x: any) => x.label === this.loc.fields.respParseOnline).value;
          newFlow = this.respParseOnlineFlows.find((x: any) => x.DESCRI0171 === newFlowId);
        }
        newFlow = {
          newFlowId : newFlow.DESCRI0171,
          newFlowVersion: newFlow.f1Vers01650171,
          newFlowExternalEntity: newFlow.f1EnEx11800171,
          flowType: flowType
        }
        this.postingSchemesService.editPostingStepFlow(this.postingId, this.ordinal, newFlow).subscribe({
          next: (res: BaseResponse) => {
            this.loading = false;
            if(res.statusRS?.code === '0'){
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage("update",this.loc.flows, "f")
              );
              this.listFlows();
            }else{
              this.snackbarService.openErrorSnackbar(this.loc.genericError("update", this.loc.flows));
            }
          },
          error: (err) => {
            this.loading = false;
            console.log(err);
          }
        })
      }
    });
  }

  goBack(){
    this.location.back();
  }

  getPostingFlows(){
    this.loading = true;
    this.postingSchemesService
    .getPostingFlows('DISPATCH_ONLINE')
    .subscribe(
      (res: BaseResponse) => {
        this.dispatchOnlineFlows = res.messageRS as unknown as any[];
        this.dispatchOnlineFlows = this.dispatchOnlineFlows.sort((a:any, b:any) => (a.DESCRI0171! < b.DESCRI0171!) ? -1 : 1);
        this.dispatchOnlineComboValues = this.dispatchOnlineFlows.map( item => ({
          key: item.f1Mens01650171,
          value: item.f1Mens01650171,
        }));
        this.loading = false;
      },
      (err) => {
        console.log(err)
      }
    );
    this.loading = true;
    this.postingSchemesService
    .getPostingFlows('RESP_PARSE_ONLINE')
    .subscribe(
      (res: BaseResponse) => {
        this.respParseOnlineFlows = res.messageRS as unknown as any[];
        this.respParseOnlineFlows = this.respParseOnlineFlows.sort((a:any, b:any) => (a.DESCRI0171! < b.DESCRI0171!) ? -1 : 1);
        this.respParseOnlineComboValues = this.respParseOnlineFlows.map( item => ({
          key: item.f1Mens01650171,
          value: item.f1Mens01650171,
        }));
        this.loading = false;
      },
      (err) => {
        console.log(err)
      }
    );
    this.loading = true;
    this.postingSchemesService
    .getPostingFlows('MESSAGE_ONLINE')
    .subscribe(
      (res: BaseResponse) => {
        this.messageOnlineFlows = res.messageRS as unknown as any[];
        this.messageOnlineComboValues = this.messageOnlineFlows.map( item => ({
          key: item.f1Mens01650171,
          value: item.f1Mens01650171,
        }));
        this.messageOnlineFlows = this.messageOnlineFlows.sort((a:any, b:any) => (a.DESCRI0171! < b.DESCRI0171!) ? -1 : 1);
        this.loading = false;
      },
      (err) => {
        console.log(err)
      }
    );
  }


}

<div class="general-operations-div">
    <div style="display: flex; margin-right: 15px;align-items: center;">
        <button  mat-fab class="page-icon-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">hub</mat-icon>
        </button>
            <label class="page-title"  i18n>{{loc.routes.extensionPoints}}</label>
    </div>
</div>

<mat-tab-group class="tab-bar"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="onTabChange()">
    <mat-tab [label]="loc.tabNames.actions" i18n-label>
        <div class="component">
            <div class="filter-wrapper">

                    <mat-form-field appearance="fill" class="large-filter ">
                        <mat-label i18n>{{loc.extensionPoints.serviceTemplate}}</mat-label>
                        <mat-select name="deviceStatus" required (selectionChange)="changeSelectionTemplate($event)">
                            <mat-option *ngFor="let template of serviceTemplates" [value]="template.DSTemp" i18n>{{template.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="large-filter ">
                        <mat-label i18n>{{loc.tableHeaders.operation}}</mat-label>
                        <input matInput type="text" (keyup)="addFilter($event,'digitalJourney')" [formControl]="myControl"
                            [matAutocomplete]="autoBranch" />
                        <mat-icon matSuffix style="font-size: 20px; color: grey;">search</mat-icon>
                        <mat-autocomplete #autoBranch="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                                (click)="addFilter(option,'digitalJourney')">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>




                </div>
            <ng-container *ngIf="!loading">
                <div class="empty-list"
                    *ngIf="dataSource.data.length === 0">
                    <div><img class="empty-img" src="assets/img/puntos-extension.png" /></div>
                    <div class="empty-lbl">
                        <label i18n>
                            {{loc.extensionPointsActions.empty}}
                        </label>
                    </div>
                    <button mat-flat-button (click)="create()" class="add-button-empty">{{loc.common.create}}</button>
                </div>
                <ng-container *ngIf="dataSource.data.length > 0">

                    <div [class]="tableStyle">
                        <mat-card style="padding: 0px; border-radius: 10px;">
                            <mat-card style="overflow-y: auto; padding: 0px; box-shadow: none !important; border-radius: 10px;">
                                <table mat-table [dataSource]="dataSource" matSort id="branches-table" class="table">

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> {{loc.tableHeaders.id}} </th>
                                        <td mat-cell *matCellDef="let element" class="bold-table-item">
                                            {{element.actionId}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="digitalJourney">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> {{loc.tableHeaders.operation}} </th>
                                        <td mat-cell *matCellDef="let element">{{element.digitalJourney}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n> {{loc.tableHeaders.name}} </th>
                                        <td mat-cell *matCellDef="let element">{{element.actionDescription}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef style="text-align: center;" i18n>
                                          {{loc.tableHeaders.actions}}
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                                            <button mat-icon-button i18n-matTooltip [matTooltip]="loc.common.details">
                                                <mat-icon class="menu-item-icon" class="black-icon">more_horiz</mat-icon>
                                            </button>
                                            <button mat-icon-button (click)="edit()" i18n-matTooltip [matTooltip]="loc.extensionPoints.editAction">
                                                <mat-icon fontSet="material-icons-outlined" class="black-icon">edit</mat-icon>
                                            </button>
                                            <button mat-icon-button (click)="delete()" i18n-matTooltip [matTooltip]="loc.extensionPoints.deleteAction">
                                                <mat-icon class="menu-item-icon black-icon">delete_outline</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr class="row" mat-row *matRowDef="let row; columns: displayedColumns;"
                                        (click)="onRowClicked(row)">
                                    </tr>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                </table>
                            </mat-card>
                            <button mat-fab (click)="create()" class="add-button">
                                <mat-icon class="action-button-icon" [matTooltip]="loc.extensionPoints.createAction">add</mat-icon>
                            </button>
                        </mat-card>
                    </div>
                    <div class="paginator-wrapper">
                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                            style="background: transparent;"
                            [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="pageEvents($event)"></mat-paginator>
                        <ng-container *ngIf="dataSource.data.length === 1"><label class="count" i18n> {{dataSource.data.length}}
                                {{loc.extensionPoints.singularAction.toLowerCase()}}</label>
                        </ng-container>
                        <ng-container *ngIf="dataSource.data.length > 1"><label class="count" i18n> {{dataSource.data.length}}
                            {{loc.extensionPoints.pluralAction.toLowerCase()}}</label>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="loading">
                <div [class]="tableStyle">
                    <mat-card style="overflow-y: auto; padding: 0px; border-radius: 10px;">
                        <mat-card style="overflow-y: auto; padding: 0px; border-radius: 10px; box-shadow: none !important;">

                            <table mat-table [dataSource]="loadingDataSource" matSort id="branches-table" class="table">


                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> {{loc.tableHeaders.id}} </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ngx-skeleton-loader count="1"
                                            [theme]="{'background': 'no-repeat rgb(227 228 230 / 33%)', height:'15px', width:'95%'}">
                                        </ngx-skeleton-loader>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="digitalJourney">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> {{loc.tableHeaders.serviceType}} </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ngx-skeleton-loader count="1"
                                            [theme]="{'background': 'no-repeat rgb(227 228 230 / 33%)', height:'15px', width:'95%'}">
                                        </ngx-skeleton-loader>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" i18n> {{loc.tableHeaders.name}} </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ngx-skeleton-loader count="1"
                                            [theme]="{'background': 'no-repeat rgb(227 228 230 / 33%)', height:'15px', width:'95%'}">
                                        </ngx-skeleton-loader>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef style="text-align: center;" i18n>
                                      {{loc.tableHeaders.actions}}
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: center;">
                                      <ngx-skeleton-loader count="1"
                                        [theme]="{'background': 'no-repeat rgb(227 228 230 / 33%)', height:'15px', width:'95%'}">
                                      </ngx-skeleton-loader>
                                    </td>
                                  </ng-container>

                                <tr class="row" mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            </table>
                        </mat-card>
                    </mat-card>
                </div>
                <div class="paginator-wrapper-loader">
                    <ngx-skeleton-loader count="1" [theme]="{
                      background: 'no-repeat rgb(227 228 230 / 33%)',
                      height: '70%',
                      width: '250px'
                    }" style="width: 100%; height: 50%;display: flex; justify-content: flex-end;align-items: flex-end;">
                    </ngx-skeleton-loader>

                    <ngx-skeleton-loader count="1" [theme]="{
                        background: 'no-repeat rgb(227 228 230 / 33%)',
                        height: '70%',
                        width: '250px'
                        }" style="width: 100%; height: 50%;display: flex; justify-content: flex-start;align-items: center;">
                    </ngx-skeleton-loader>
                  </div>
            </ng-container>
        </div>
      </mat-tab>
      <mat-tab [label]="loc.tabNames.parameters" i18n-label>
          <app-list-fields tableStyle = "tableResponsive" fieldsType="global"></app-list-fields>
      </mat-tab>
</mat-tab-group>

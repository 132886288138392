 <ng-container>
        <div class="imglogo" style="display: flex; margin-top: 30px; justify-content: center;">
            <img class="logo" src="assets/img/logo.svg" />
            <div style="margin-left: 12px;">
                <p style="color: white;font-size: 21px;margin-bottom:
                    3px;margin-top: 25px;">
                    PAYCORR
                </p>
                <p style="color: var(--green-color);font-size: 18px;">
                   SETTINGS
                </p>
            </div>
        </div>

    <div class="side-bar">
      <mat-list role="list" class="menu">
        <div class="menu-item" *ngFor="let item of menuItems">
          <button mat-button class="menu-item-text" (click)="toggleSubMenu(item)" [ngClass]="{'expanded': item.isExpanded}">
            <div class="menu-item-div">
              <mat-icon class="menu-item-icon">{{item.icon}}</mat-icon>
              <span class="aligned-with-icon" i18n>{{getRouteName(item.id)}}</span>
              <mat-icon fontSet="material-icons-outlined" class="expand-icon" *ngIf="item.subMenuItems && item.isExpanded">
                expand_less
              </mat-icon>
              <mat-icon fontSet="material-icons-outlined" class="expand-icon" *ngIf="item.subMenuItems && !item.isExpanded">
                expand_more
              </mat-icon>
            </div>
          </button>
          <div class="sub-menu" [ngStyle]="{'display': item.isExpanded ? 'block' : 'none'}">
            <mat-list role="list">
              <button mat-button class="menu-item-text" [routerLink]="subItem.route" [routerLinkActive]="['is-active']"
                *ngFor="let subItem of item.subMenuItems">
                <div class="menu-item-div">
                  <mat-icon class="menu-item-icon">{{subItem.icon}}</mat-icon>
                  <span class="aligned-with-icon" i18n>{{getRouteName(subItem.id)}}</span>
                </div>
              </button>
            </mat-list>
          </div>
        </div>
      </mat-list>
    </div>
    <div style="background-color: var(--green-color);
        width: 125px; height: 4px; border-radius: 2px; align-self:
        flex-start;margin-left: 46px;">
    </div>

    <div class="authorship">
        <p class="rights-reserved"></p>
    </div>
</ng-container>

import {
  Component,
  Input,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { Action } from 'src/app/models/extension-points/Action.model';

import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { AssociateFieldsToActionComponent } from '../associate-fields-to-action/associate-fields-to-action.component';
import { CreateFieldComponent } from '../create-field/create-field.component';
import { EmptyState, TableData, Column, PaginatorInfo, TableTransformer, TableFilter, CreateButton } from 'src/app/utils/table/table.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';

@Component({
  selector: 'app-list-fields',
  templateUrl: './list-fields.component.html',
  styleUrls: ['./list-fields.component.css'],
  host: {
    class: 'component',
  },
})
export class ListFieldsComponent implements OnInit {
  @Input() tableStyle = 'tableResponsive';
  @Input() fieldsType: 'global' | 'action' = 'global';
  @Input() action: Action = {};

  loading = true;

  operationId = '';
  digitalServiceId = '';
  extensionPoints: any[] = [];

  actionId = '';
  touchPointId = '';


  constructor(
    public extensionPointsService: ExtensionPointsService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}



    emptyState: EmptyState = {
      image: 'assets/img/puntos-extension.png',
      message: this.loc.extensionPointsFields.empty,
      buttons: [{
        text: this.loc.common.create,
        action: () => this.create()
      }]
    };

    data: TableData[] = []
    columns: Column[] = [
      {
         key : 'fieldId',
         title : this.loc.tableHeaders.name,
         sortable : true,
      },
      {
          key : 'description',
          title : this.loc.tableHeaders.description,
          sortable : true,
      }
    ];
    paginatorInfo: PaginatorInfo = this.loc.extensionPointsFields
    transformers: TableTransformer = {}
    filters: TableFilter[] = [
      {
         label: this.loc.tableHeaders.name,
         key: 'fieldId',
         type: 'text',
         placeholder: this.loc.tableHeaders.name,
      },
      {
          label: this.loc.tableHeaders.description,
          key: 'description',
          type: 'text',
          placeholder: this.loc.tableHeaders.description,
      }
    ];


    actions : any = [
      {
        tooltip: this.loc.extensionPointsFields.edit,
        icon: 'edit',
        action: (row : any) => this.edit(row)
      },
      {
        tooltip: this.loc.extensionPointsFields.delete,
        icon: 'delete',
        action: (row : any) => this.delete(row)
      }
    ];

    createButton: CreateButton = {
      tooltip: this.loc.extensionPointsFields.create,
      action: () => this.create()
    };



  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.operationId = params['operationId'];
      this.touchPointId = params['touchPointId'];
      this.actionId = params['actionId'];
    });

    if (this.fieldsType == 'global') {
      this.loadGlobalFields();
    } else {
      this.actions = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['action']?.currentValue) {
      this.loadFields();
    }
  }

  loadFields() {
    if (this.fieldsType == 'global') {
      this.loadGlobalFields();
    } else {
      this.loadActionFields();
    }
  }

  loadGlobalFields(): void {
    this.loading = true;
    this.extensionPointsService.getFields().subscribe(
      (res: BaseResponse) => {
        let response = res.messageRS!['fields'];
        this.extensionPoints = response;
        this.data = this.extensionPoints as any as TableData[];
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  loadActionFields(): void {
    this.loading = true;
    this.extensionPointsService.getActionTemplateFields(this.action).subscribe(
      (res: BaseResponse) => {
        let response = res.messageRS!['fields'];
        this.extensionPoints = response;
        this.data = this.extensionPoints as any as TableData[];
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  create() {
    if (this.fieldsType == 'global') {
      const dialogRef = this.dialogService.openDialog(
        CreateFieldComponent,
        {},
        'medium'
      );

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'created') {
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('create', this.loc.extensionPointsFields)
          );
          this.loadFields();
        } else if (result === '-91615') {
          this.snackbarService.openErrorSnackbar(
            this.loc.errors.parameterAlreadyExists
          );
        } else if (result === '-91621') {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('create', this.loc.extensionPointsFields)
          );
        }
      });
    } else {
      const dialogRef = this.dialogService.openDialog(
        AssociateFieldsToActionComponent,
        {
          fields: this.extensionPoints,
          action: this.action,
        },
        'large'
      );

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'created') {
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('create', this.loc.extensionPointsFields)
          );
          this.loadFields();
        } else if (result === '-91615') {
          this.snackbarService.openErrorSnackbar(
            this.loc.errors.parameterAlreadyExists
          );
        } else if (result === '-91621') {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('create', this.loc.extensionPointsFields)
          );
        }
      });
    }
  }

  edit(element: any) {
    let data: EditModalData = {
      title: this.loc.parameters.edit,
      icon: 'hub',
      fields: [
        { label: this.loc.extensionPointsFields.edit,
          value: element.description,
          errorMsg: this.loc.errors.emptyDescription,
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyAction(element, result)
      }
    });
  }

  modifyAction(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.extensionPointsFields.edit).value
    let newRow = {...row, description: newDescription}
    this.extensionPointsService
      .updateField(newRow)
      .subscribe(
       {
        next: (res: BaseResponse) => {
          this.loading = false;
          if (res.statusRS?.code === '0') {
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('update', this.loc.extensionPointsFields)
            );
            this.loadFields();
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.extensionPointsFields)
            );
          }
        },
        error: (err) => {
          this.loading = false;
          this.logoutDialogService.openLogoutDialog(err);
          if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.extensionPointsFields)
            );
          }
        }
       }
      );
  }

  delete(element: any) {
    let dataModal: DeleteModalData = {
      title: this.loc.extensionPointsFields.delete,
      icon: 'hub',
      confirmDeleteMessage: this.loc.confirmDelete(
        this.loc.extensionPointsFields
      ),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      dataModal,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        if (this.fieldsType == 'global') {
          this.extensionPointsService
            .deleteParameter(element.fieldId)
            .subscribe(
              (res: BaseResponse) => {
                if (res.statusRS?.code === '0') {
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage(
                      'delete',
                      this.loc.extensionPointsFields
                    )
                  );
                } else if (res.statusRS?.code === '-91619') {
                  this.snackbarService.openErrorSnackbar(
                    this.loc.errors.parameterIsUsedByTemplate
                  );
                } else if (res.statusRS?.code === '-91620') {
                  this.snackbarService.openErrorSnackbar(
                    this.loc.errors.parameterIsUsedByInstance
                  );
                } else {
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError(
                      'delete',
                      this.loc.extensionPointsFields
                    )
                  );
                }

                this.loadFields();
              },
              (err) => {
                this.logoutDialogService.openLogoutDialog(err);
              }
            );
        } else {
        }
      }
    });
  }
}

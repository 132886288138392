<div class="general-operations-div">
  <div style="display: flex; margin-right: 15px;align-items: center;">
      <button  mat-fab class="page-icon-button blue-button-icon">
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon">calendar_month</mat-icon>
      </button>
          <label class="page-title"  i18n>{{loc.routes.calendars}}</label>
  </div>
</div>


<mat-tab-group class="tab-bar"
  [(selectedIndex)]="selectedIndex"
  (selectedTabChange)="onTabChange();">
  <mat-tab [label]="loc.tabNames.calendars" i18n-label>
      <app-list-calendars tableStyle = "tableResponsive"></app-list-calendars>
  </mat-tab>
</mat-tab-group>

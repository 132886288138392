import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { Posting } from 'src/app/models/posting-schemes/Posting.model';
import { PostingScheme } from 'src/app/models/posting-schemes/PostingScheme.model';

import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreatePostingComponent } from './create-posting/create-posting.component';
import {
  Action,
  Column,
  CreateButton,
  EmptyState,
  TableData,
  TableFilter,
} from 'src/app/utils/table/table.model';

@Component({
  selector: 'app-posting',
  templateUrl: './posting.component.html',
  styleUrls: ['./posting.component.css'],
  host: {
    class: 'component',
  },
})
export class PostingComponent implements OnInit {
  loading = true;
  data: TableData[] = [];
  constructor(
    public postingSchemesService: PostingSchemesService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  columns: Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
    },
  ];

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',
    message: this.loc.posting.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      },
    ],
  };

  paginatorInfo = this.loc.posting;

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      placeholder: this.loc.tableHeaders.description,
      type: 'text',
    },
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      placeholder: this.loc.tableHeaders.id,
      type: 'text',
    },
  ];

  actions: Action[] = [
    {
      icon: 'more_horiz',
      action: (row: Posting) => {
        this.goToDetails(row);
      },
      tooltip: this.loc.common.details,
    },
    {
      icon: 'edit',
      action: (row: Posting) => {
        this.editPosting(row);
      },
      tooltip: this.loc.postingSchemes.edit,
    },
    {
      icon: 'delete',
      action: (row: Posting) => {
        this.deletePosting(row);
      },
      tooltip: this.loc.postingSchemes.delete,
    },
  ];

  ngOnInit(): void {
    this.loadPostings();
  }

  pageEvents(event: any) {
    let state = JSON.parse(localStorage.getItem('state')!);
    if (!state.postingsList) {
      state.postingsList = {};
    }
    state.postingsList['pageIndex'] = event.pageIndex;
    state.postingsList['pageSize'] = event.pageSize;
    localStorage.setItem('state', JSON.stringify(state));
  }

  loadPostings(): void {
    this.loading = true;
    this.postingSchemesService.getPostings().subscribe(
      (res: BaseResponse) => {
        this.data = res.messageRS as any as TableData[];
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  createButton: CreateButton = {
    tooltip: this.loc.postingSchemes.create,
    action: () => this.create(),
  };

  goToDetails(row: Posting): void {
    this.router.navigate(['posting-details'], {
      queryParams: {
        id: row.id,
        description: row.description,
      },
      queryParamsHandling: 'merge',
    });
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreatePostingComponent,
      {},
      'medium'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.posting)
        );
        this.loadPostings();
      } else if (result === '-91697') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.postingAlreadyExists
        );
      } else if (result === '-91696') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.posting)
        );
      }
    });
  }

  editPosting(element: Posting) {
    let data: EditModalData = {
      title: this.loc.posting.edit,
      icon: 'signpost',
      fields: [
        {
          label: this.loc.tableHeaders.description,
          value: element.description!!,
          errorMsg: this.loc.errors.emptyDescription,
          validators: [Validators.required],
        },
      ],
    };
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== 'not updated' && result) {
        this.loading = true;
        this.editAction(element, result);
      }
    });
  }

  editAction(row: any, result: any) {
    let newDescription = result.find(
      (x: any) => x.label === this.loc.tableHeaders.description
    ).value;

    this.postingSchemesService.editPosting(row.id, newDescription).subscribe({
      next: (res: BaseResponse) => {
        this.loading = false;
        if (res.statusRS?.code === '0') {
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('update', this.loc.posting)
          );
          this.loadPostings();
        } else {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.posting)
          );
        }
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  }

  deletePosting(element: Posting) {
    let dataModal: DeleteModalData = {
      title: this.loc.posting.delete,
      icon: 'signpost',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.posting),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      dataModal,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.postingSchemesService.deletePosting(element.id!).subscribe(
          (res: BaseResponse) => {
            if (res.statusRS?.code === '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.posting)
              );
            } else if (res.statusRS?.code === '-91382') {
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.postingSchemeUsed
              );
            } else if (res.statusRS?.code === '-91837') {
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.postingUsedByPostingScheme
              );
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.posting)
              );
            }

            this.loadPostings();
          },
          (err) => {
            this.logoutDialogService.openLogoutDialog(err);
          }
        );
      }
    });
  }
}

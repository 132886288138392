import { Component, OnInit } from '@angular/core';
import { SettlementModesService } from 'src/app/services/settlement-modes/settlement-modes.service';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { SettlementMode } from 'src/app/models/settlement-modes/settlement-mode.model';
import { Action, Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer, onRowClick } from 'src/app/utils/table/table.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { Router } from '@angular/router';
import { CreateSettlementModeComponent } from './create-settlement-mode/create-settlement-mode.component';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';

@Component({
  selector: 'app-settlement-modes',
  templateUrl: './settlement-modes.component.html',
  styleUrls: ['./settlement-modes.component.css'],
  host: {
    class: 'component'
  }
})

export class SettlementModesComponent implements OnInit {
  loading = false;

  data: TableData[] = [];
  settlementModes: SettlementMode[] = [];


  constructor(
    public loc: LocalizationService,
    private settlementModesService: SettlementModesService,
    private router: Router,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,

  ) { }

  ngOnInit(): void {
    this.listSettlementModes();
  }

  listSettlementModes(){
    this.settlementModesService.getSettlementModes().subscribe({
      next: (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.data = ((res.messageRS!).sort((a:any, b:any) => (a.id! < b.id!) ? -1 : 1)) as any as TableData[];
        }
      },
      error: (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        console.log(err);
      },
    });
  }

  emptyState: EmptyState = {
    image: 'assets/img/tipos-agentes.png',
    message: this.loc.settlementMode.empty,
    buttons: [],
  };

  columns: Column[] = [
    {
      key: 'id',
      title: this.loc.tableHeaders.id,
      sortable: true,
    },
    {
      key: 'description',
      title: this.loc.tableHeaders.description,
      sortable: true,
    },
    {
      key: 'dayAfterExecution',
      title: this.loc.detailsHeaders.day,
      sortable: true,
    },
    {
      key: 'hour',
      title: this.loc.detailsHeaders.hour,
      sortable: true,
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.settlementMode;

  transformers: TableTransformer = {};

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    },
    {
      label: this.loc.tableHeaders.day,
      key: 'dayAfterExecution',
      type: 'text',
      placeholder: this.loc.tableHeaders.day,
    },
  ];

  actions: Action[] = [
    {
      tooltip: this.loc.settlementMode.edit,
      icon: 'edit',
      action: (row) => this.edit(row),
    },
    {
      tooltip: this.loc.settlementMode.delete,
      icon: 'delete',
      action: (row) => this.delete(row),
    },
  ];

  createButton: CreateButton = {
    tooltip: this.loc.settlementMode.create,
    action: () => this.create(),
  };

  details(row: SettlementMode) {
    this.router.navigate(['TODO'], {
      queryParams: {},
      queryParamsHandling: 'merge',
    });
  }

  onRowClick : onRowClick = {
    action: (row) => {
      this.details(row);
    }
  }

  create(){
    const dialogRef = this.dialogService.openDialog(
      CreateSettlementModeComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.settlementMode, 'f')
        );
        this.listSettlementModes();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.settlementMode)
        );
      } else if (result === 'invalid') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.settlementModeAlreadyExists
        );
      }
    });
  }

  edit(row: SettlementMode){
    let data: EditModalData = {
      title: this.loc.settlementMode.edit,
      icon: 'paid',
      fields: [
        {label: this.loc.tableHeaders.description, value: row.description, errorMsg: this.loc.errors.emptyDescription, validators: [Validators.required]},]
    }
    const dialogRef = this.dialogService.openDialog(EditModalComponent, data, "x-small");
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
        let settMode = {...row, description: newDescription}
        this.settlementModesService.editSettlementMode(settMode.id, settMode.description).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('update', this.loc.settlementMode)
              );
              this.listSettlementModes();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.settlementMode)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.settlementMode)
            );
          },
        });
      }
    });
  }

  delete(row: SettlementMode){
    let data: DeleteModalData = {
      title: this.loc.settlementMode.delete,
      icon: 'paid',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.settlementMode),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === 'delete') {
        this.loading = true;
        this.settlementModesService.deleteSettlementMode(row.id).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.settlementMode)
              );
              this.listSettlementModes();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.settlementMode)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.settlementMode)
            );
          },
        });
      }
    });

  }
}

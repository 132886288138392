<div class="all">
<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; top: 50%; left: 50%;transform: translate(-50%, -50%);"></mat-spinner>
</div>

<div class="confirm-title">
    <div class="confirm-title-inner-div">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon *ngIf="image === ''" class="action-button-icon" fontSet="material-icons-outlined">domain</mat-icon>
           
            <img class="image2" *ngIf="image != ''" [src]="image" alt="img_user" (error)="image = '' ">

            <mat-icon class="action-button-icon-edit">mode_edit</mat-icon>
        </button>
        <div class="dialog-title"><label i18n>{{loc.template.edit}}</label></div>
    </div>
    <button class="exit-button" (click)="dialogRef.close('not updated')">
        <mat-icon  class="exit-icon">close</mat-icon>
    </button>
</div>

<div class="create-row form">
    <form [formGroup]="firstFormGroup" class="two-column-form">
        <div>
            <mat-form-field appearance="fill" *ngIf="allowed">
                <mat-label i18n>{{loc.fields.status}}</mat-label> 
                    <mat-select formControlName="status" required> 
                        <mat-option value="ACTIVE">{{loc.common.active}}</mat-option>
                        <mat-option value="INACTIVE">{{loc.common.inactive}}</mat-option>
                    </mat-select>
            </mat-form-field>

            <div class="edit-template-description"  *ngIf="!allowed">
                <mat-form-field appearance="fill">
                    <mat-label i18n>{{loc.fields.description}}</mat-label>
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="description"
                    cdkAutosizeMinRows="7" cdkAutosizeMaxRows="7" required>
                    </textarea>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="!allowed">
            <div >
                <div class="image-box">
                    <mat-icon *ngIf="!image" class="default-img">add_a_photo</mat-icon>
                    <img *ngIf="image" [src]="image" alt="img_user" class="image" (error)="image = '' ">
                </div>

                <button mat-fab class="button image-button" (click)="hiddenfileinput.click()" i18n-matmatTooltip [matTooltip]="loc.common.changePhoto">
                    <mat-icon fontSet="material-icons-outlined"> camera_alt </mat-icon>
                </button>
            </div>
            <input type="file" style="display: none;" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput>
        </div>
    </form>
</div>

<div class="confirm-buttons save">
    <button mat-fab class="button green-color" (click)="saveImg()" [disabled]="firstFormGroup!!.get('description')!!.value === '' ">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
    </button>
</div>
</div>
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { LocalizationService } from '../../../services/localization/localization.service';
import { DialogService } from '../../../services/dialog/dialog.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { BaseResponse } from '../../../models/responses.model';
import { BranchOperation } from '../../../models/branch/branchOperations.model';
import { BranchService } from '../../../services/branch/branch.service';
import { DeleteModalData } from '../../../models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from '../../../utils/delete-modal/delete-modal.component';
import { CreateBranchComponent } from '../create-branch/create-branch.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';

@Component({
  selector: 'app-list-branch-operations',
  templateUrl: './list-branch-operations.component.html',
  styleUrls: ['./list-branch-operations.component.css'],
  host: { class: 'component' },
})
export class ListBranchOperationsComponent implements OnInit {
  loading: boolean = true;
  data : TableData[] = []
  constructor(
    public loc: LocalizationService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private branchService: BranchService,
    private htmlGen : SafeHTMLGeneratorService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.loadBranchOperations();
  }

  loadBranchOperations() {
    this.branchService.getBranchOperations().subscribe({
      next: (data: BaseResponse<BranchOperation[]>) => {
        this.data = data.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (_err: any) => {},
    });
  }

  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreateBranchComponent,
      {},
      'large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.branchOperations, 'f')
        );
        this.loadBranchOperations();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.branchOperations)
        );
        this.loadBranchOperations();
      }
    });
  }

  editAction(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.tableHeaders.description).value
    let newBranchOperation = {...row, description: newDescription}
    this.branchService.editBranchOperation(newBranchOperation).subscribe({
      next: (_response: BaseResponse<BranchOperation>) => {
        this.loading = false
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('update', this.loc.branchOperations, 'f')
        );
        this.loadBranchOperations();
      },
      error: (_error: any) => {
        this.loading = false
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.branchOperations)
        );
      },
    });
  }

  edit(row: BranchOperation) {
    let data: EditModalData = {
      title: this.loc.branchOperations.edit,
      icon: 'store',
      fields: [
        { label: this.loc.tableHeaders.description, 
          value: row.description, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required]}]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAction(row, result)
      }
    });
  }

  delete(row: BranchOperation): void {
    let data: DeleteModalData = {
      title: this.loc.branchOperations.delete,
      icon: 'store',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.branchOperations),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.branchService.deleteBranchOperation(row.operationId).subscribe({
          next: (res: BaseResponse<BranchOperation>) => {
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage(
                  'delete',
                  this.loc.branchOperations,
                  'f'
                )
              );
              this.loadBranchOperations();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.branchOperations)
              );
              this.loadBranchOperations();
            }
          },
          error: (_err: any) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.branchOperations)
            );
          },
        });
      }
    });
  }

  toggleStatus(row: BranchOperation) {
    this.branchService.activateBranchOperation(row.status!, row.operationId).subscribe({
      next: (res: BaseResponse<BranchOperation>) => {
        if (res.statusRS?.code == '0') {
          this.loadBranchOperations();
        } else {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.branchOperations)
          );
          this.loadBranchOperations();
        }
      }
    });
  }

  createButton: CreateButton = {
    action: () => this.create(),
    tooltip: this.loc.branchOperations.create,
  }

  emptyState : EmptyState = {
    image: 'assets/img/empty-agent.png',
    message: this.loc.branchOperations.empty,
    buttons: [
      {
        action: () => this.create(),
        text: this.loc.branchOperations.create,
      }
    ]
  }

  columns : Column[] = [
    {
      key:"operationId",
      title: this.loc.tableHeaders.id,
      sortable: true,
    },
    {
      key: "description",
      title: this.loc.tableHeaders.description,
      sortable: true,
    },
    {
      key: "ordererAccountId",
      title: this.loc.tableHeaders.ordererAccount,
      sortable: true,
    },
    {
      key: "recipientAccountId",
      title: this.loc.tableHeaders.recipientAccount,
      sortable: true,
    },
    {
      key: "ordererEntityDescription",
      title: this.loc.tableHeaders.ordererEntity,
      sortable: true,
    },
    {
      key:"recipientEntityDescription",
      title: this.loc.tableHeaders.recipientEntity,
      sortable: true,
    },
    {
      key: "status",
      title: this.loc.tableHeaders.status,
      centered: true,
    },
  ]


  transformers = {
    status: (value: string) => {
      return this.htmlGen.statusIcon(value == "ACTIVE")
    }
  }

  paginatorInfo = {
    singular: this.loc.branchOperations.singular,
    plural: this.loc.branchOperations.plural,
  }

  filters: TableFilter[] = [
    {
      key: "description",
      label: this.loc.tableHeaders.description,
      type: "text",
      placeholder: this.loc.tableHeaders.description,
    },
    {
      key: "ordererAccountId",
      label: this.loc.tableHeaders.ordererAccount,
      type: "text",
      placeholder: this.loc.tableHeaders.ordererAccount,
    },
    {
      key: "recipientAccountId",
      label: this.loc.tableHeaders.recipientAccount,
      type: "text",
      placeholder: this.loc.tableHeaders.recipientAccount,
    }
  ]


  actions: Action[] = [
    {
      tooltip: this.loc.branchOperations.toggleStatus,
      icon: "toggle_on",
      action: (row: BranchOperation) => this.toggleStatus(row),
      condition: (row: BranchOperation) => row.status == "ACTIVE"
    },
    {
      tooltip: this.loc.branchOperations.toggleStatus,
      icon: "toggle_off",
      action: (row: BranchOperation) => this.toggleStatus(row),
      condition: (row: BranchOperation) => row.status == "INACTIVE"
    },
    {
      tooltip: this.loc.branchOperations.edit,
      icon: "edit",
      action: (row: BranchOperation) => this.edit(row),
    },
    {
      tooltip: this.loc.branchOperations.delete,
      icon: "delete",
      action: (row: BranchOperation) => this.delete(row),
    }
  ]


}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { PaymentButtonFields } from 'src/app/models/payment-buttons/paymentButtonFields.model';
import { PaymentButtonTypeFields } from 'src/app/models/payment-buttons/paymentButtonTypeFields.model';
import { PaymentButtonTypes } from 'src/app/models/payment-buttons/paymentButtonTypes.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentButtonService {
  readonly rootURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getTypes(): Observable<BaseResponse<PaymentButtonTypes[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get<BaseResponse<PaymentButtonTypes[]>>(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/payment-button/types/x/list',
      HTTP_OPTIONS
    );
  }

  createType(
    field: PaymentButtonTypes
  ): Observable<BaseResponse<PaymentButtonTypes>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = field;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.post<BaseResponse<PaymentButtonTypes>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/payment-buttons/payment-button/x/create-button-type',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  editType(
    id: string,
    description: string
  ): Observable<BaseResponse<PaymentButtonTypes>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { description: description };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<PaymentButtonTypes>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/payment-buttons/payment-button/${id}/update-type`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  deleteType(id: string): Observable<BaseResponse<PaymentButtonTypes>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.delete<BaseResponse<PaymentButtonTypes>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/payment-buttons/payment-button/${id}/delete-type`,
      HTTP_OPTIONS
    );
  }

  // FIELDS

  getFields(): Observable<BaseResponse<PaymentButtonFields[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get<BaseResponse<PaymentButtonFields[]>>(
      this.rootURL +
        'RSAdaptorFE/api/v1.0/paycorr/payment-buttons/payment-button/x/list',
      HTTP_OPTIONS
    );
  }

  createField(
    field: PaymentButtonFields
  ): Observable<BaseResponse<PaymentButtonFields>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = field;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.post<BaseResponse<PaymentButtonFields>>(
      this.rootURL +
        'RSAdaptorFE/api/v1.0/paycorr/payment-buttons/payment-button/x/create',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  editField(
    id: string,
    name: string
  ): Observable<BaseResponse<PaymentButtonFields>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { name: name };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<PaymentButtonFields>>(
      this.rootURL +
        `RSAdaptorFE/api/v1.0/paycorr/payment-buttons/payment-button/${id}/update`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  deleteField(id: string): Observable<BaseResponse<PaymentButtonFields>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.delete<BaseResponse<PaymentButtonFields>>(
      this.rootURL +
        `RSAdaptorFE/api/v1.0/paycorr/payment-buttons/payment-button/${id}/delete`,
      HTTP_OPTIONS
    );
  }

  //Payment button type fields
  getTypeFields(
    idQuery: string
  ): Observable<BaseResponse<PaymentButtonTypeFields[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
        fieldFk: idQuery,
      },
    };

    return this.http.get<BaseResponse<PaymentButtonTypeFields[]>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/payment-button-types/payment-button-type/x/list',
      HTTP_OPTIONS
    );
  }

  createTypeField(
    field: PaymentButtonTypeFields
  ): Observable<BaseResponse<PaymentButtonTypeFields>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = field;
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.post<BaseResponse<PaymentButtonTypeFields>>(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/payment-button-types/payment-button-type/x/create',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  editTypeField(
    id: string,
    fieldFk: string,
    isMandatory: number
  ): Observable<BaseResponse<PaymentButtonTypeFields>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { fieldFk: fieldFk, isMandatory: isMandatory };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<PaymentButtonTypeFields>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/payment-button-types/payment-button-type/${id}/update`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  deleteTypeField(
    id: string,
    fieldFk: string
  ): Observable<BaseResponse<PaymentButtonFields>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
        fieldFk: fieldFk,
      },
    };
    return this.http.delete<BaseResponse<PaymentButtonFields>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/payment-button-types/payment-button-type/${id}/delete`,
      HTTP_OPTIONS
    );
  }
}

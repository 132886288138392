import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { StepsService } from 'src/app/services/steps/steps.service';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { CreateApiResponseComponent } from '../../create-api-response/create-api-response.component';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';

@Component({
  selector: 'app-list-mapped-api-responses',
  templateUrl: './list-mapped-api-responses.component.html',
  styleUrls: ['./list-mapped-api-responses.component.css'],
  host: {
    class: 'component',
  },
})
export class ListMappedApiResponsesComponent implements OnInit {
  @Input() stepId = '';
  @Input() apiId = '';
  @Input() outputId = '';


  loading = false;
  

  constructor(
    public route: ActivatedRoute,
    public loc: LocalizationService,
    public snackbarService: SnackbarService,
    public dialogService: DialogService,
    private stepsService: StepsService,
    
  ) { 
    
  }

  ngOnInit(): void {
    this.getData();
  }
  
  data: TableData[] = [];

  emptyState: EmptyState = {
    image: 'assets/img/empty-metadata.png',//TODO: CHANGE?
    message: this.loc.errors.emptyMappings,
    buttons:  [
      {
        text: this.loc.common.create,
        action: () => this.createApiResponse(),
      },
    ]
  };

  columns: Column[] = [
    {
      title: this.loc.tableHeaders.fieldId,
      key: 'fieldId',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.fieldPath,
      key: 'fieldPath',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.iterationPath,
      key: 'iterationPath',
      sortable: true,
    },
  ];
  
  filters: TableFilter[] = [
    {
      key: 'fieldId',
      label: this.loc.tableHeaders.fieldId,
      placeholder: this.loc.tableHeaders.fieldId,
      type: 'text',
    },
    {
      key: 'fieldPath',
      label: this.loc.tableHeaders.fieldPath,
      placeholder: this.loc.tableHeaders.fieldPath,
      type: 'text',
    },    {
      key: 'iterationPath',
      label: this.loc.tableHeaders.iterationPath,
      placeholder: this.loc.tableHeaders.iterationPath,
      type: 'text',
    },
  ];

  createButton: CreateButton = {
    action: () => this.createApiResponse(),
    tooltip: this.loc.apiResponseMap.create,
  }

  getData(){
    this.stepsService.getApiResponseMapStepMetadataOut(this.stepId, this.apiId).subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.data = res.messageRS as any as TableData[];
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }


  createApiResponse(){
    const dialogRef = this.dialogService.openDialog(
      CreateApiResponseComponent,
      {
        apiId: this.apiId,
        stepId: this.stepId,
        outputId: this.outputId
      },
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if(result === 'created'){
        this.getData();
      }
    });
  }

  actions : Action[] = [
    {
      tooltip: this.loc.apiResponseMap.edit,
      icon: 'edit',
      action: (row: any) => this.edit(row),
    },
    {
      icon: 'delete',
      tooltip: this.loc.apiResponseMap.delete,
      action: (row: any) => this.delete(row),
    },
  ]

  delete(row: any){
    let data: DeleteModalData = {
      title: this.loc.apiResponseMap.delete,
      icon: 'psychology',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.apiResponseMap),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result === 'delete') {
        this.loading = true;
        this.stepsService.deleteApiResponseMapStepMetadataOut(this.apiId, this.stepId, this.outputId, row.fieldId).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.apiResponseMap)
              );
              this.getData();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.apiResponseMap)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('delete', this.loc.apiResponseMap)
            );
          },
        });
      }
    });
  }

  edit(row: any){
    let data: EditModalData = {
      title: this.loc.apiResponseMap.edit,
      icon: 'psychology',
      fields: [
        {label: this.loc.tableHeaders.fieldId, value: row.fieldId},
        {label: this.loc.tableHeaders.fieldPath, value: row.fieldPath, validators: [Validators.required]},
        {label: this.loc.tableHeaders.iterationPath, value: row.iterationPath, validators: [Validators.required]},
      ]
    }
    const dialogRef = this.dialogService.openDialog(EditModalComponent, data, "small");

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        let newFieldId = result.find((x: any) => x.label === this.loc.tableHeaders.fieldId).value;
        let newfieldPath = result.find((x: any) => x.label === this.loc.tableHeaders.fieldPath).value;
        let newIterationPath = result.find((x: any) => x.label === this.loc.tableHeaders.iterationPath).value;
        
        
          this.stepsService.editApiResponseMapStepMetadataOut(this.stepId, this.apiId, this.outputId, row.fieldId, newFieldId, newfieldPath, newIterationPath).subscribe({
          next: (res: any) => {
            this.loading = false;
            if (res.statusRS?.code == '0') {
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('update', this.loc.apiResponseMap)
              );
              this.getData();
            } else if(res.statusRS?.code == '-91979'){
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.newFieldIdIsBeingUsed
              );
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('update', this.loc.apiResponseMap)
              );
            }
          },
          error: (err) => {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.apiResponseMap)
            );
          },
        })
      }
    });
  }
}

export interface BaseRequest{
    messageRQ: any;
    headerRQ: HeaderRQ;
    securityRQ: SecurityRQ;
}

export interface HeaderRQ {
    msgId: String;
    timestamp: String;
    channelRef: String;
    channelId: String;
    transactionType: String;
}

export interface SecurityRQ {
    channelId: String;
    hostId: String;
    profile: String;
    userId: String;
}

export const createRequest = <T>(messageRQ: T): BaseRequest => {
    return {
        messageRQ: messageRQ,
        headerRQ: {
            msgId: '',
            timestamp: Date(),
            channelRef: '123',
            channelId: '123',
            transactionType: '123',
        },
        securityRQ: {
            channelId: '',
            hostId: '',
            profile: '',
            userId: '',
        },
    };
}

import { map, OperatorFunction, pipe, startWith } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

export function pipeForFilters(
  filterDelegate: (value: any) => any[],
  sort: (v1: any, v2: any) => number
): OperatorFunction<any, string[]> {
  return pipe(
    startWith(''),
    map((value: any) => filterDelegate(value)),
    map((value: any) => {
      return value
        .filter((item: any, index: number) => {
          return value.indexOf(item) === index; //Removes duplicates
        })
        .sort()
        .filter(
          (item: any) => item !== '' && item !== null && item !== undefined
        );
    })
  );
}

export function filter(list: string[]) {
  return (value: string) => {
    const filterValue = value?.toLowerCase() || '';
    return list.filter((option) => option?.toLowerCase().includes(filterValue));
  };
}

export function customFilterPredicate(data: any, filter: string): boolean {
  const filtersS = JSON.parse(filter);
  let fitsThisFilter = true;
  for (const key in filtersS) {
    if(typeof filtersS[key] === 'string'){
      const text = data[key];
      fitsThisFilter &&=
      text?.toLocaleLowerCase()
      .includes(filtersS[key].trim().toLocaleLowerCase());
    }else{
      if(filtersS[key].start){
         const date = moment.utc(data[key]).toDate();
          const start =  moment.utc(filtersS[key].start).toDate();
          fitsThisFilter &&= date >= start;
      }
      if(filtersS[key].end){
        const date = moment.utc(data[key]).toDate();
        const end =  moment.utc(filtersS[key].end).toDate();
        fitsThisFilter &&= date <= end;
      }
    }
  }
  return fitsThisFilter;
}

export function addFilter(
  event: any,
  column: string,
  page: string,
  filters: any,
  dataSource: MatTableDataSource<any>,
  type: string = 'text'
): void {


  if(type == 'text'){
    const filterValue = (
      event.target
        ? event.target.value
        : event.value || event.value === ''
          ? event.value
          : event
    )!
      .trim()
      .toLocaleLowerCase();
      filters[column] = filterValue;
    }
  if(type == 'date'){
    filters[column] = event;
  }
    
    
  dataSource.filter = JSON.stringify(filters);
  let state = JSON.parse(localStorage.getItem('state') || '{}');
  if (!state[page]) {
    state[page] = {};
  }
  state[page][column] = (
    event.target
      ? event.target.value
      : event.value || event.value === ''
        ? event.value
        : event
  );
  localStorage.setItem('state', JSON.stringify(state));
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceMode } from 'src/app/models/attributes/ServiceMode.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { ServiceTemplate } from 'src/app/models/digital-services/ServiceTemplate.model';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

import { CreateServiceModeComponent } from '../create-service-mode/create-service-mode.component';
import {
  Action,
  Column,
  CreateButton,
  EmptyState,
  PaginatorInfo,
  TableData,
  TableFilter,
  TableTransformer,
  onRowClick,
} from 'src/app/utils/table/table.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-list-service-modes',
  templateUrl: './list-service-modes.component.html',
  styleUrls: ['./list-service-modes.component.css'],
  host: {
    class: 'component',
  },
})
export class ListServiceModesComponent implements OnInit {
  loading: boolean = true;
  serviceModes: ServiceMode[] = [];
  serviceTemplates: ServiceTemplate[] = [];

  constructor(
    public loc: LocalizationService,
    public extensionPointsService: ExtensionPointsService,
    public attributesService: AttributesService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    public router: Router
  ) {}

  emptyState: EmptyState = {
    image: 'assets/img/tipos-agentes.png',
    message: this.loc.serviceModes.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      },
    ],
  };

  data: TableData[] = [];

  columns: Column[] = [
    {
      key: 'id',
      title: this.loc.tableHeaders.id,
      sortable: true,
    },
    {
      key: 'description',
      title: this.loc.tableHeaders.description,
      sortable: true,
    },
    {
      key: 'serviceTemplateId',
      title: this.loc.tableHeaders.serviceTemplate,
      sortable: true,
    },
  ];

  paginatorInfo: PaginatorInfo = this.loc.serviceModes;
  transformers: TableTransformer = {};

  filters: TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    },
    {
      label: this.loc.tableHeaders.serviceTemplate,
      key: 'serviceTemplateId',
      type: 'select',
      placeholder: this.loc.tableHeaders.serviceTemplate,
      options: this.serviceTemplates.map((serviceTemplate) => {
        return {
          value: serviceTemplate.description,
          label: serviceTemplate.description,
        };
      }),
    },
  ];
  actions: Action[] = [
    {
      tooltip: this.loc.common.details,
      icon: 'more_horiz',
      action: (row) => this.details(row),
    },
    {
      tooltip: this.loc.serviceModes.edit,
      icon: 'edit',
      action: (row) => this.edit(row),
    },
    {
      tooltip: this.loc.serviceModes.delete,
      icon: 'delete',
      action: (row) => this.delete(row),
    },
  ];
  createButton: CreateButton = {
    tooltip: this.loc.serviceModes.create,
    action: () => this.create(),
  };

  ngOnInit(): void {
    this.loadServiceTemplates();
    this.loadServiceModes();
  }

  loadServiceModes() {
    this.loading = true;
    this.attributesService.getServiceModes().subscribe({
      next: (data) => {
        this.data = data.messageRS as any as TableData[];
        this.loading = false;
      },
      error: (_err: any) => {},
    });
  }

  loadServiceTemplates() {
    this.extensionPointsService.getServiceTemplates().subscribe({
      next: (res: any) => {
        this.serviceTemplates = res.messageRS as any as ServiceTemplate[];
        this.filters[2].options = [
          {
            value: '',
            label: '',
          },
          ...this.serviceTemplates.map((serviceTemplate) => {
            return {
              value: serviceTemplate.frameBankingTemplateId,
              label: serviceTemplate.description,
            };
          }),
        ];
      },
    });
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateServiceModeComponent,
      {},
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.serviceModes, 'm')
        );
        this.loadServiceModes();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.serviceModes)
        );
      }
      if (result === 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.serviceModeAlreadyExist
        );
      }
    });
  }

  edit(row: ServiceMode) {
    let data: EditModalData = {
      title: this.loc.serviceModes.edit,
      icon: 'people',
      fields: [
        { label: this.loc.tableHeaders.description,
          value: row.description!!,
          errorMsg: this.loc.errors.emptyDescription,
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
     if (result !== 'not updated' && result) {
        this.loading = true;
        this.editAction(row, result)
      }
    });
  }

  editAction(row: any, result: any){
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value

    this.attributesService
    .updateServiceMode(row.id, row.serviceTemplateId, newDescription)
    .subscribe({
      next: (res: any) => {
        if (res.statusRS?.code == '0') {
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage('update', this.loc.serviceModes)
          );
          this.loadServiceModes();
        } else {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.serviceModes)
          );
        }
      },
      error: (err) => {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.serviceModes)
        );
      },
    });
  }

  delete(row: ServiceMode) {
    let data: DeleteModalData = {
      title: this.loc.serviceModes.delete,
      icon: 'paid',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.serviceModes),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.attributesService
          .deleteServiceMode(row.id, row.serviceTemplateId)
          .subscribe({
            next: (res: any) => {
              this.loading = true;
              switch (res.statusRS?.code) {
                case '0':
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage(
                      'delete',
                      this.loc.serviceModes,
                      'm'
                    )
                  );
                  this.loadServiceModes();
                  break;
                default:
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError('delete', this.loc.serviceModes)
                  );
                  break;
              }
              this.loading = false;
            },
            error: (_err: any) => {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.serviceModes)
              );
            },
          });
      }
    });
  }

  onRowClick : onRowClick = {
    action: (row) => {
      this.details(row);
    }
  }


  details(row: ServiceMode) {
    this.router.navigate(['service-mode-details'], {
      queryParams: {
        modeId: row.id,
        modeDesc: row.description,
        serviceTemplateId: row.serviceTemplateId,
      },
      queryParamsHandling: 'merge',
    });
  }

  //FILTERS
}

<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
  </div>
  <div class="confirm-title">
    <div class="confirm-title-inner-div">
    <button mat-fab class="confirm-dialog-button blue-button-icon">
      <mat-icon class="action-button-icon-user" fontSet="material-icons-outlined" >paid</mat-icon>
      <mat-icon class="action-button-icon-edit" fontSet="material-icons-outlined" >edit</mat-icon>
    </button>
    <div class="dialog-title">
      <label i18n>{{loc.attributes.edit}}</label>
    </div>
    </div>
    <button class="exit-button" (click)="exit('not updated')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
  
  <div class="delete-paragraph">
    <label i18n>{{loc.confirmEdit(loc.attributes)}}</label>
  </div>
  
  <div class="confirm-buttons">   
    <button mat-fab class="button green-color" (click)="save(); clicked = true;" [disabled]="clicked"  >
      <mat-icon fontSet="material-icons-outlined">save</mat-icon>
    </button>
  </div>
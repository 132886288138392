<div class="general-operations-div">
  <div style="display: flex; margin-right: 15px; align-items: center">
    <button mat-fab class="page-icon-button blue-button-icon">
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon">people</mat-icon>
    </button>
    <label class="page-title" i18n>{{ loc.routes.users }}</label>
  </div>
</div>

<mat-tab-group 
  class="tab-bar"
  [(selectedIndex)]="selectedIndex"
  (selectedTabChange)="onTabChange()">

  <mat-tab [label]=loc.tabNames.userTypes>
    <app-list-user-types></app-list-user-types>
  </mat-tab>
  <mat-tab [label]=loc.tabNames.managers>
    <app-list-managers></app-list-managers>
  </mat-tab>

</mat-tab-group>
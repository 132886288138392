<!-- elegir label, action result, description-->
<div class="dark" *ngIf="loading">
  <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
  </mat-spinner>
</div>

<div class="title">
  <div class="inner-title-container">
    <button mat-fab class="confirm-dialog-button blue-button-icon">
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon">account_balance</mat-icon>
      <mat-icon class="action-button-icon-add">add</mat-icon>
    </button>
    <label class="dialog-title" i18n>{{loc.banks.create}}</label>
  </div>
  <button class="exit-button" (click)="exit('not created')">
    <mat-icon matSuffix class="exit-icon">close</mat-icon>
  </button>
</div>
<mat-horizontal-stepper class="horizontal-stepper actions-stepper"
  [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom" [linear]="true" #stepper
  appStepper [step]="stepper.selectedIndex">
  <ng-template matStepperIcon="done" let-index="index">
    <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
    <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
  </ng-template>

  <ng-template matStepperIcon="edit" let-index="index">
    <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
    <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
  </ng-template>

  <ng-template matStepperIcon="number" let-index="index">
    <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
    <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
  </ng-template>
  <mat-step [stepControl]="firstFormGroup" [editable]="true">
    <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
    <form [formGroup]="firstFormGroup" class="form-step">
      <div class="data-box">
        <div class="create-row">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{loc.fields.bankId}}</mat-label>
            <input matInput name="bankId" formControlName="bankId" required>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n>{{loc.fields.bankName}}</mat-label>
            <input matInput name="bankName" formControlName="bankName" required>
          </mat-form-field>

        </div>
        <div class="create-row">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{loc.fields.bankAddress}}</mat-label>
            <input matInput name="bankAddress" formControlName="bankAddress">
          </mat-form-field>


          <mat-form-field appearance="fill">
            <mat-label i18n>{{loc.fields.country}}</mat-label>
            <mat-select formControlName="country">
              <mat-select-trigger>
                <div class="country-div">
                  <mat-label class="selected-country-lbl">{{ firstFormGroup.get("country")?.value?.countryName }}
                  </mat-label>
                </div>
                <img [src]="firstFormGroup.get('country')?.value?.image" class="selected-country-flag" />
              </mat-select-trigger>
              <mat-option [value]="country" *ngFor="let country of countries">
                {{ country.countryName }}
                <img [src]="country.image" class="country-flag" />
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="create-row">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{loc.fields.abaNumber}}</mat-label>
            <input matInput name="abaNumber" formControlName="abaNumber">
          </mat-form-field>


          <mat-form-field appearance="fill">
            <mat-label i18n>{{loc.fields.swiftCode}}</mat-label>
            <input matInput name="swiftCode" formControlName="swiftCode" required>
          </mat-form-field>


        </div>
      </div>
      <div class="dialog-nav-buttons">
        <button mat-fab class="button green-button" matStepperNext [disabled]="!isFormValid()">
          <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [editable]="true">
    <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
    <div class="form-step">
      <div class="verify-large">
        <div class="flex-container-vertical flex-container-vertical-modal">
          <div>
            <label class="card-name" i18n>{{loc.banks.newBank}}</label>
          </div>

          <div class="details-package-large details-flex">
            <div class="verify-vertical">
              <div class="card-header" i18n>{{loc.fields.bankId}}</div>
              <div class="card-value">{{firstFormGroup.get('bankId')?.value}}</div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{loc.fields.bankName}}</div>
              <div class="card-value">{{firstFormGroup.get('bankName')?.value}}</div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{loc.fields.bankAddress}}</div>
              <div class="card-value">{{emptyField(firstFormGroup.get('bankAddress')?.value)}}</div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{loc.fields.abaNumber}}</div>
              <div class="card-value">{{emptyField(firstFormGroup.get('abaNumber')?.value)}}</div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{loc.fields.swiftCode}}</div>
              <div class="card-value">{{firstFormGroup.get('swiftCode')?.value}}</div>
            </div>
            <div class="verify-vertical">
              <div class="card-header" i18n>{{loc.fields.country}}</div>
              <div class="card-value">{{firstFormGroup.get('country')?.value?.countryName}}</div>
            </div>

          </div>
        </div>

      </div>
      <div class="dialog-nav-buttons">
        <button mat-fab class="button green-button" matStepperPrevious>
          <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-fab class="button green-button" (click)="create()">
          <mat-icon class="stepper-item-icon" fontSet="material-icons-outlined">save</mat-icon>
        </button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>

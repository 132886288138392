<div class="dark" *ngIf="loading">
  <mat-spinner diameter="70" style="
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -35px;
      margin-top: -35px;
    "></mat-spinner>
</div>
<div class="title">
  <div class="inner-title-container">
  <button mat-fab class="confirm-dialog-button blue-button-icon">
    <mat-icon class="action-button-icon" fontSet="material-icons-outlined">campaign</mat-icon>
    <mat-icon class="action-button-icon-add">add</mat-icon>
  </button>
  <div class="dialog-title"><label i18n>{{loc.campaigns.create}}</label></div>
  
  </div>
  <button class="exit-button" (click)="exit('not created')"  >
    <mat-icon matSuffix class="exit-icon">close</mat-icon>
  </button>
</div>

  <mat-horizontal-stepper class="horizontal-stepper" [ngClass]="'three-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
    [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex">
    <ng-template matStepperIcon="done" let-index="index">
      <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
      <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">safety_divider</mat-icon>
      <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 2">check_box</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="edit" let-index="index">
      <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
      <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">safety_divider</mat-icon>
      <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 2">check_box</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="number" let-index="index">
      <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 0">description</mat-icon>
      <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 1">safety_divider</mat-icon>
      <mat-icon fontSet="material-icons-outlined" class="stepper-icon" *ngIf="index == 2">check_box</mat-icon>
    </ng-template>
    <mat-step [stepControl]="firstFormGroup" [editable]="true">
      <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
        <form [formGroup]="firstFormGroup" class="form-step">
        <div class="data-box">
          <div class="create-row">
            <mat-form-field appearance="fill"
             i18n-matTooltip  [matTooltip]="loc.errors.campaignIdFormat"
              matTooltipPosition="above" 
              matTooltipClass="error-tooltip" 
              [matTooltipDisabled]="!firstFormGroup.get('id')?.hasError('pattern') || !firstFormGroup.get('id')!.touched">
              <mat-label i18n>{{loc.fields.identification}}</mat-label>
              <input matInput name="name" formControlName="id" required />
         
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label i18n>{{loc.fields.description}}</mat-label>
              <input matInput name="id" formControlName="description" required />
            </mat-form-field>
          </div>
          </div>
        <div class="dialog-nav-buttons">
          <button mat-fab class="button green-button" matStepperNext  matStepperNext [disabled]="!isFormValid()">
            <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" [editable]="true">
      <ng-template matStepLabel i18n>{{loc.stepTitle.distribution}}</ng-template>
      <form [formGroup]="secondFormGroup" class="form-step">
        <div class="data-box">
        <div class="create-row" style="justify-content:flex-start;">
          <mat-form-field appearance="fill" >
            <mat-label i18n>{{loc.fields.discount}}</mat-label>
            <input matInput name="percentageDiscount" type="number" formControlName="percentageDiscount" required>
          </mat-form-field>
        </div>
        <div class="create-row">
          <mat-form-field appearance="fill">
            <mat-label i18n>{{loc.fields.agentDistribution}}</mat-label>
            <input matInput type="number" name="agentDistribution" formControlName="agentDistribution" (input)="setRemaining('agentDistribution','financialEntityDistribution')" required/>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n>{{loc.fields.financialEntityDistribution}}</mat-label>
            <input matInput type="number" name="financialEntityDistribution"
              formControlName="financialEntityDistribution" 
              (input)="setRemaining('financialEntityDistribution','agentDistribution')" required/>
          </mat-form-field>
        </div>
      </div>
        <div class="dialog-nav-buttons">
          <button mat-fab class="button green-button" matStepperPrevious >
            <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
          </button>
          <button mat-fab class="button green-button" (click)="toVerify(stepper)"  matStepperNext [disabled]="!isFormValid()">
            <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [editable]="true">
      <ng-template matStepLabel i18n>{{loc.stepTitle.verify}}</ng-template>
      <div class="form-step">
      <div class="verify-large">
        <div class="flex-container-vertical flex-container-vertical-modal">
          <div>
            <label class="card-name" i18n>{{loc.campaigns.new}}</label>
          </div>
            <div class="details-package-large">
              <div class="verify-vertical">
                <div class="card-header" i18n>{{loc.fields.identification}}</div>
                <div class="card-value">{{ firstFormGroup!.get('id')!.value }}</div>
              </div>
              <div class="verify-vertical">
                <div class="card-header" i18n>{{loc.fields.description}}</div>
                <div class="card-value">{{ firstFormGroup!.get('description')!.value }}</div>
              </div>
            </div>
          <div>
            <label class="card-name" i18n>{{loc.stepTitle.distribution | titlecase}}</label>
          </div>
            <div class="details-package-large">
              <div class="verify-vertical">
                <div class="card-header" i18n>{{loc.fields.discount}}</div>
                <div class="card-value">{{ secondFormGroup!.get('percentageDiscount')!.value }}%</div>
              </div>
              <div class="verify-vertical">
                <div class="card-header" i18n>{{loc.fields.agentDistribution}}</div>
                <div class="card-value">{{ secondFormGroup!.get('agentDistribution')!.value }}%</div>
              </div>
              <br>
              <div class="verify-vertical">
                <div class="card-header" i18n>{{loc.fields.financialEntityDistribution}}</div>
                <div class="card-value">{{ secondFormGroup!.get('financialEntityDistribution')!.value }}%</div>
              </div>
            </div>
        </div>
      
      </div>
      <div class="dialog-nav-buttons">

        <button mat-fab class="button green-button" matStepperPrevious   >
          <mat-icon class="stepper-item-icon">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-fab class="button green-button" (click)="createCampaign()"  >
          <mat-icon fontSet="material-icons-outlined" class="stepper-item-icon">save</mat-icon>
        </button>
      </div>
    </div>
     
    </mat-step>
  </mat-horizontal-stepper>

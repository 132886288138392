<!-- elegir label, action result, description-->
<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
</div>

<div class="title">
    <div class="inner-title-container">
        <button mat-fab class="confirm-dialog-button blue-button-icon">
            <mat-icon fontSet="material-icons-outlined" class="action-button-icon">settings</mat-icon>
            <mat-icon class="action-button-icon-add">add</mat-icon>
        </button>
        <label class="dialog-title" i18n>{{loc.apis.update}}</label>
    </div>
    <button class="exit-button" (click)="exit('exit')"  >
        <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
</div>
    <mat-horizontal-stepper class="horizontal-stepper actions-stepper" [ngClass]="'two-step last-edited-step-' + stepper.selectedIndex" labelPosition="bottom"
        [linear]="true" #stepper appStepper [step] = "stepper.selectedIndex">
        <ng-template matStepperIcon="done" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
            
        </ng-template>

        <ng-template matStepperIcon="edit" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
           
        </ng-template>

        <ng-template matStepperIcon="number" let-index="index">
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 0">description</mat-icon>
            <mat-icon class="stepper-icon" fontSet="material-icons-outlined" *ngIf="index == 1">check_box</mat-icon>
           
        </ng-template>

        <mat-step [stepControl]="firstFormGroup" [editable]="true">
            <ng-template matStepLabel i18n>{{loc.stepTitle.basicData}}</ng-template>
            <form [formGroup]="firstFormGroup" class="form-step">
                <div class="data-box">
                <div class="create-row">
                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.id}}</mat-label>
                        <input matInput name="apiId" formControlName="apiId" readonly="true">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n>{{loc.fields.name}}</mat-label>
                        <input matInput name="apiName" formControlName="apiName" required>
                    </mat-form-field>

                </div>
               
                
                </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button" matStepperNext>
                        <mat-icon class="stepper-item-icon">keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </form>
        </mat-step>


        <mat-step [stepControl]="secondFormGroup">
            <ng-template matStepLabel i18n>{{loc.stepTitle.addBody}}</ng-template>

            
            <form  [formGroup]="secondFormGroup" class="form-step">
                <mat-form-field  style="width: 450px;">
                    <textarea formControlName="body" class="text-area" matInput  style="font-size: 12px;" [(ngModel)]="body">{{body}}</textarea>
                </mat-form-field>
                <div class="error-msg" *ngIf="invalidJSON">
                    <label>{{loc.fields.invalidJSON}}</label>
                  </div>
                <div class="dialog-nav-buttons">
                    <button mat-fab class="button green-button"  matStepperNext >
                        <mat-icon fontSet="material-icons-outlined" (click)="update()" class="stepper-item-icon">save</mat-icon>
                    </button>
                </div>
            </form>
           
        </mat-step>



     

    </mat-horizontal-stepper>


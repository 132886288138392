<div class="general-operations-div">
    <div style="display: flex;">
      <button mat-icon-button class="back" safeClick (onSafeClick)="goBack()"  >
        <mat-icon class="back-icon-button">keyboard_arrow_left</mat-icon>
      </button>
      <ng-container>
        <button mat-fab class="page-icon-button blue-button-icon">
          <mat-icon fontSet="material-icons-outlined" class="action-button-icon">extension</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="loading" >
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
            background: 'no-repeat rgb(227 228 230 / 33%)',
            height: '60px',
            width: '60px'
          }"></ngx-skeleton-loader>
      </ng-container>
  
      <ng-container *ngIf="loading">
        <div class="title-container">
        <ngx-skeleton-loader count="1" [theme]="{
            background: 'no-repeat rgb(227 228 230 / 33%)',
            height: '25px',
            width: '150px'
          }"></ngx-skeleton-loader>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading">
      <div class="title-container">
        <label class="title-mid-text">{{ integrationType.id }}</label>
        <label class="id-text">
          {{ integrationType.description }}
        </label>
      </div>
      </ng-container>
    </div>

    
</div>

<mat-tab-group class="tab-bar">
    <mat-tab *ngIf="!loading" [label]="loc.tabNames.parameters" i18n-label>
      <app-list-type-parameters class="component" [id]="integrationType.id" [loading] = "loading"></app-list-type-parameters>
    </mat-tab>
</mat-tab-group>

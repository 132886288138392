import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CreateBankComponent } from 'src/app/banks/create-bank/create-bank/create-bank.component';
import { AccountIds } from 'src/app/models/accountIds/account-ids.model';
import { Bank } from 'src/app/models/Banks/bank.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-account-id',
  templateUrl: './create-account-id.component.html',
  styleUrls: ['./create-account-id.component.css'],
  host: { class: 'dialog-component' },
})
export class CreateAccountIdComponent implements OnInit {
  loading = true;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  actions: any[] = [];
  stageId = '';
  ids = [];

  constructor(
    public dialogRef: MatDialogRef<CreateBankComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Bank[],
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private accountService: AccountsService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      accountId: ['', Validators.required],
      accountName: ['', Validators.required],
      externalId : ['', Validators.required],
    });
    this.loading = false;
  }
  create() {
    this.loading = true;

    let accountId: AccountIds = {
      id: this.firstFormGroup.get('accountId')!.value,
      name: this.firstFormGroup.get('accountName')!.value,
      externalId: this.firstFormGroup.get('externalId')!.value,
    };

    this.accountService.createAccountId(accountId).subscribe({
      next: (res: BaseResponse<AccountIds>) => {
        this.loading = false;
        switch (res.statusRS?.code) {
          case '0':
            this.exit('created');
            break;
          case '-91728':
            this.exit('duplicate');
            break;
          default:
            this.exit('error');
            break;
        }
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      },
    });
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }
  emptyField(text: string | undefined): string {
    if (text === '' || text === undefined) return '-';
    return text;
  }

  isFormValid(): boolean {
    return this.firstFormGroup.valid;
  }
  
}

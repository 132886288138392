import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteModalData } from '../../models/delete-modal/delete-modal-data.model';
import { LocalizationService } from '../../services/localization/localization.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css'],
  host: {
    class: 'confirmation-dialog-component'
  }
})
export class DeleteModalComponent implements OnInit {

  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteModalData,
    public dialog: MatDialog,
    public loc: LocalizationService
    ) { }


  ngOnInit(): void {
  }

  delete(){
    this.loading = true;
    setTimeout(
      () => {
        this.dialogRef.close('delete');
        this.loading = false;
      }, 200
    )
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }
}

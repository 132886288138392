import { 
  Component, 
  Inject, 
  OnInit } from '@angular/core';
import { 
  UntypedFormBuilder, 
  UntypedFormGroup, 
  Validators } from '@angular/forms';
import { 
  MAT_DIALOG_DATA, 
  MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Cloudinary } from '@cloudinary/angular-5.x';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Integration, IntegrationParameters } from 'src/app/models/integration/integration.model';
import { ImageUploaderService } from 'src/app/services/image-uploader/image-uploader.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';


const sha1 = require('sha1');

@Component({
  selector: 'app-edit-integration',
  templateUrl: './edit-integration.component.html',
  styleUrls: ['./edit-integration.component.css'],
  host: {
    "class": "component"
  }
})

export class EditIntegrationComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  imageProfile?: string;
  selectedFile: File = new File([], '');
  constructor(
    public integrationsService: IntegrationsService,
    public dialogRef: MatDialogRef<EditIntegrationComponent>,
    public formBuilder: UntypedFormBuilder,
    public loc: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private imageUploader: ImageUploaderService,
    private cloudinary: Cloudinary,
    private imageUploaderService: ImageUploaderService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      integrationId: ['', Validators.required],
      description: ['', Validators.required],
      status: ['', Validators.required],
    });
    this.firstFormGroup.setValue({
      integrationId: this.data.integrationId,
      description: this.data.description,
      status: this.data.status, 
    })
    this.imageProfile = this.data.image;
  }

  modifyIntegration(): void {
    this.loading = true;
    const integration: Integration = {
      integrationId: this.data.integrationId,
      integrationType: this.data.integrationType,
      description: this.firstFormGroup.get('description')?.value,
      status: this.firstFormGroup.get('status')?.value,
      image: this.imageProfile,
      parameters: [] 
    };
    this.integrationsService.editIntegration(integration).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          console.log(res)
          this.loading = false;
          if (res.statusRS?.code === '0') {
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage("update", this.loc.integrations, 'f')
            );
            const data = {
              integrationId: integration.integrationId,
              description: integration.description,
              status: integration.status,
              image: integration.image
            }
            this.dialogRef.close(data);
          }
          else if(res.statusRS?.code === "-91115"){
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.integrationMandatoryParameters
            );
            this.dialogRef.close();
          }
          else if(res.statusRS?.code === "-91114"){
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.needPostingScheme
            );
            this.dialogRef.close();

          }
          else {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('update', this.loc.integrations)
            );
          }
        }, 200);
      },
      (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if(err.status !== 401){
          this.dialogRef.close('error');
        }
      }
    );
  }

  onFileSelect($event: any): void {
    this.selectedFile = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = (_event) => {
      this.imageProfile = reader.result?.toString();
    };
  }

  saveImg(): void {
    const integrationId = this.data.integrationId;
    this.loading = true;
      if (this.selectedFile.name !== '') {
        const data = new FormData();
        const timestamp = Math.floor(Date.now() / 1000);
        const signature = sha1(
          'public_id=' +
            integrationId +
            '&timestamp=' +
            timestamp +
            '&upload_preset=' +
            this.cloudinary.config().upload_preset +
            this.cloudinary.config().api_secret
        );

        data.append('file', this.selectedFile);
        data.append('upload_preset', this.cloudinary.config().upload_preset);
        data.append('cloud_name', this.cloudinary.config().cloud_name);
        data.append('api_key', this.cloudinary.config().api_key);
        data.append('api_secret', this.cloudinary.config().api_secret);
        data.append('secure', this.cloudinary.config().secure);
        if (integrationId) {
          data.append('public_id', integrationId);
        }
        data.append('signature', signature);
        data.append('timestamp', timestamp.toString());

        this.imageUploaderService.uploadImage(data).subscribe((response) => {
          if (response && integrationId) {
            this.imageProfile = response.secure_url;
            this.modifyIntegration();
          }
        });
      } else {
        this.modifyIntegration();
      }
    }
    

}

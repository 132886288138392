import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, throttleTime } from 'rxjs';

@Directive({
  selector: '[safeClick]'
})
export class ClickThrottleDirective implements OnDestroy {
  @Input() timeConst = 1000;
  @Output() onSafeClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  constructor() {
    this.subscription = this.clicks
      .pipe(
        throttleTime(this.timeConst)
      )
      .subscribe((e) => this.onSafeClick.emit(e));
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { MatStepper } from '@angular/material/stepper';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { AddFlowComponent } from '../add-flow/add-flow.component';


@Component({
  selector: 'app-create-extension-point',
  templateUrl: './create-extension-point.component.html',
  styleUrls: ['./create-extension-point.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class CreateExtensionPointComponent implements OnInit {

  loading = false;
  firstFormGroup!: UntypedFormGroup;
  labels: any[] = [];
  stageId = '';
  blogic = '';
  serviceId = ''

  constructor(
    public dialogRef: MatDialogRef<CreateExtensionPointComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private extensionPointsService: ExtensionPointsService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
    });

    this.firstFormGroup = this._formBuilder.group({
      label: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.stageId = this.data.stageId;
    this.getBLogic(this.stageId);
    this.getLabels();   
  }

  getBLogic(stage: string){
    // this.extensionPointsService.getBusinessLogic(stage).subscribe(
    //   (res: BaseResponse) => {
    //     this.blogic = res.messageRS!.blogic;
    //   }, (err) => {
    //     setTimeout(() => {
    //       this.loading = false;
    //     }, 200);
    //     this.logoutDialogService.openLogoutDialog(err);
    //     if (err.statusCode !== 401) {
    //       this.exit('error');
    //     }
    //   }
    // )
  }

  getLabels(flowId: string = ""){
    this.extensionPointsService.getLabels().subscribe(
      (res: BaseResponse) => {
        this.labels = (res.messageRS!['labels'] as any[]).sort((x: any, y: any) => (x.labelId < y.labelId) ? 1 : -1);
        if(flowId){
          this.firstFormGroup.patchValue(
            {
              label: this.labels.find((x) => x.labelId === flowId)
            }
          )
        }
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }

  addFlow(){
    const dialogRef = this.dialogService.openDialog(AddFlowComponent, 
      {},
      "x-small");
  
      dialogRef.afterClosed().subscribe((result: string) => {
        if (result.includes('created')) {
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage("add",this.loc.flows)
          );
          let labelId = result.split(":")[1]
          this.getLabels(labelId);
        } else if (result === 'error') {
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError("add",this.loc.flows)
          );
          this.getLabels();
        }
        
      });
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  create() {
    this.loading = true;
    let rules = [...this.data.rules];
    let paycorrTemplateRules = rules    
    let newRule = {
      digitalJourney: this.data.digitalJourney,
      touchPointId: this.data.touchPointId,
      actionId: this.data.actionId,
      actionEntity: this.data.actionEntity,
      stageId: this.stageId,      
      order: paycorrTemplateRules.length+1,
      labelId: this.firstFormGroup.get('label')?.value.labelId,      
      description: this.firstFormGroup.get('description')?.value,
    };

    this.loading = true;
    this.extensionPointsService.createExtensionPoint(newRule).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit("error");
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }

}

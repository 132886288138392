import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { PostingSchemesService } from 'src/app/services/posting-schemes/posting-schemes.service';
import { Posting } from 'src/app/models/posting-schemes/Posting.model';
import { Disaggregation } from 'src/app/models/posting-schemes/Disaggregation.model';

@Component({
  selector: 'app-create-posting-scheme',
  templateUrl: './create-posting-scheme.component.html',
  styleUrls: ['./create-posting-scheme.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class CreatePostingSchemeComponent implements OnInit {
  loading = false;
  firstFormGroup!: UntypedFormGroup;
  postings: Posting[] = [];
  disaggregations: Disaggregation[] = [];
  stageId = '';


  constructor(
    public dialogRef: MatDialogRef<CreatePostingSchemeComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private postingSchemesService: PostingSchemesService,
    private logoutDialogService: LogoutDialogService,
    public loc : LocalizationService
  ) { }

  ngOnInit(): void {
    this.loadPostings()
    this.loadDisaggregations();
    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      disaggregationId: ['', Validators.required],
      postingId: ['', Validators.required],
    });

  }

  loadDisaggregations(){
    //this.loading = true;
    this.postingSchemesService
    .getDisaggregations()
    .subscribe(
      (res: BaseResponse) => {
        let response = res.messageRS as any as Disaggregation[];
        
        this.disaggregations = response;
        //this.loading = true;
        
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  loadPostings(): void {
    //this.loading = true;
    this.postingSchemesService
      .getPostings()
      .subscribe(
        (res: BaseResponse) => {
          let response = res.messageRS as any as Posting[];
          
          this.postings = response;
          //this.loading = true;
          
        },
        (err) => {
          this.logoutDialogService.openLogoutDialog(err);
        }
      );
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  create() {
    this.loading = true;
    let name = this.firstFormGroup.get('name')!.value;
    let description = this.firstFormGroup.get('description')?.value;
    let postingId = this.firstFormGroup.get('postingId')?.value.id;
    let disaggregationId = this.firstFormGroup.get('disaggregationId')?.value.id;


    this.loading = true;
    this.postingSchemesService.createPostingScheme(name, description, disaggregationId, postingId).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if(res.statusRS?.code === "0"){
            this.exit("created");
          } else {
            this.exit(res.statusRS?.code as string);
          }
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      }
    )
  }

}

<div class="title-main-page-container">
  <div style="display: flex; margin-right: 15px; align-items: center">
    <button mat-fab class="main-icon-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="material-icons">extension</mat-icon>
    </button>
    <label class="page-title" i18n>{{loc.integrations.title}}</label>
  </div>
</div>

<mat-tab-group 
  class="tab-bar"
  [(selectedIndex)]="selectedIndex"
  (selectedTabChange)="onTabChange()"
>

<mat-tab [label]="loc.tabNames.integrationType" i18n>
  <app-list-integration-types>  </app-list-integration-types>
</mat-tab>

<mat-tab [label]="loc.tabNames.integrations" i18n> 
  <app-list-integrations></app-list-integrations>
</mat-tab>



</mat-tab-group>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivateModalData } from 'src/app/models/activate-modal/activate-modal-data.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-activate-modal',
  templateUrl: './activate-modal.component.html',
  styleUrls: ['./activate-modal.component.css'],
  host: {
    class: 'confirmation-dialog-component'
  }
})
export class ActivateModalComponent implements OnInit {
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ActivateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ActivateModalData,
    public dialog: MatDialog,
    public loc: LocalizationService
  ) { }

  ngOnInit(): void {
  }

  activate(){
    this.loading = true;
    setTimeout(
      () => {
        this.dialogRef.close('activate');
        this.loading = false;
      }, 200
    )
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

}

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BehaviorSubjectGetUserService } from 'src/app/behavior-subject/behavior-subject-get-user.service';
import { LogoutConfirmationComponent } from 'src/app/logout-confirmation/logout-confirmation.component';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { BaseResponse } from 'src/app/models/responses.model';
import { User } from 'src/app/models/user.model';

import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { TokenService } from 'src/app/services/token/token.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-user-preview',
  templateUrl: './user-preview.component.html',
  styleUrls: ['./user-preview.component.css']
})
export class UserPreviewComponent implements OnInit {
  userFirstName = 'Administrator';
  userLastName = '';
  userType = '';
  imageProfile = '';
  loaded = false;
  @Input() account!: () => void;

  constructor(
    public usersService: UsersService,
    public tokenService: TokenService,
    private dialogService: DialogService,
    public behaviorSubject: BehaviorSubjectGetUserService,
    private logoutDialogService: LogoutDialogService,
    public router: Router,
    public loc: LocalizationService
  ) {}

  public refreshSubject: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.logUserData();
    this.behaviorSubject.bulma$.subscribe(user => (this.userFirstName = user));
    this.behaviorSubject.bulma2$.subscribe(user => (this.userLastName = user));
  }

  toNotifications(): void {
    this.refreshSubject.next(true);
    this.router.navigate(['/notifications']);
  }

  logUserData(): void {
    const partyId = this.tokenService.getPartyId();
    this.userType = JSON.parse(sessionStorage.getItem('userInfo') || '').userTypeDescription.toUpperCase() || '';

    this.usersService.getUserDataByPperson(partyId).subscribe(
      (res: BaseResponse<Array<User>>) => {
        const user = res.messageRS[0] as any;
        if (user) {
          this.userFirstName = user.firstName
            ? user.firstName
            : 'Administrator';
          this.userLastName = user.lastName ? user.lastName : '';
          this.imageProfile = user.imageProfile ? user.imageProfile : '';
        }
        this.loaded = true;
      },
      (err: any) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }
  clearState() {
    let state = JSON.parse(localStorage.getItem('state')!);
    state = {};
    localStorage.setItem('state', JSON.stringify(state));
  }
  logout(): void {
    const data = { exp: false };
    const dialogRef = this.dialogService.openDialog(
      LogoutConfirmationComponent,
      data,
      'x-small',
      false
    );
    dialogRef.afterClosed().subscribe(result => {});
    this.clearState();
  }
}

<div class="dark" *ngIf="loading">
    <mat-spinner diameter=70 style="position: absolute; left: 50%; top: 50%; margin-left: -35px; margin-top: -35px;">
    </mat-spinner>
  </div>
  <div class="confirm-title">
    <div class="confirm-title-inner-div">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon">air</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="action-button-icon-edit">add</mat-icon>
      </button>
    <div class="dialog-title"><label i18n>{{loc.flows.add}}</label></div>
    </div>
    <button class="exit-button" (click)="exit('not updated')"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
  
  <div class="create-row">
    <form class="form" style="width: 80%;">
      <mat-form-field style="width: 100%;" appearance="fill">
        <mat-label i18n>{{loc.flows.singular}}</mat-label>
        <mat-select [formControl]="control">
          <!-- <mat-form-field appearance="fill" class="large-filter">
            <mat-label i18n>Nombre</mat-label>
            <mat-icon matSuffix style="font-size: 20px; color: grey;">
              search
            </mat-icon>
            <input type="text" aria-label="Number" matInput  />

          </mat-form-field> -->
            <mat-option *ngFor="let flow of flows;" [value]="flow">
                {{ flow.f1Mens01650171 }}
            </mat-option>
        </mat-select>
     </mat-form-field>
    </form>
  </div>
  
  
  <div class="confirm-buttons">
      <button mat-fab class="button green-color" (click)="add()" [disabled]="false"  >
      <mat-icon fontSet="material-icons-outlined" class="action-button-icon">save</mat-icon>
    </button>
  </div>
  
  
  
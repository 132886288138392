import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';
import { API } from 'src/app/models/api/api.model';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/models/responses.model';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  readonly rootURL = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }


  loadAPIs() {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer' + this.tokenService.getToken(),
        withCredentials: 'true'
      })
    };
    return this.http.get(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/apis/api/x/get',
      HTTP_OPTIONS
    )  as Observable<BaseResponse<API[]>>;
  }


  getAPIDetails(apiId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer' + this.tokenService.getToken(),
        withCredentials: 'true'
      })
    };
    console.log(HTTP_OPTIONS)
    return this.http.get(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/apis/api/'+apiId+'/details',
      HTTP_OPTIONS
    )
  }


  deleteAPI(apiId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer' + this.tokenService.getToken(),
        withCredentials: 'true'
      }),
    }
    return this.http.delete(
      this.rootURL + `RSAdaptorFE/api/v1/paycorr/apis/api/${apiId}/delete`,
      HTTP_OPTIONS
    )
  }

  updateAPI(api:API){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer' + this.tokenService.getToken(),
        withCredentials: 'true'
      }),
  };
  const baseRequest: BaseRequest = new BaseRequest();
  baseRequest.messageRQ = api;
  return this.http.put(
    this.rootURL + `RSAdaptorFE/api/v1/paycorr/apis/api/x/update`,
    baseRequest,
    HTTP_OPTIONS
  )
} 
  createAPI(api:API){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer' + this.tokenService.getToken(),
        withCredentials: 'true'
      }),
  };
  const baseRequest: BaseRequest = new BaseRequest();
  baseRequest.messageRQ = api;
  return this.http.post(
    this.rootURL + `RSAdaptorFE/api/v1/paycorr/apis/api/x/create`,
    baseRequest,
    HTTP_OPTIONS
  )
}

  getFrameAPIs(entity: string='PayCorr', groupName: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer' + this.tokenService.getToken(),
        withCredentials: 'true'
      }),
      params: {
        externalEntity: entity,
        groupName: groupName
      }
    };

    return this.http.get(
      this.rootURL + 'RSAdaptorFE/api/v1/frameb/apis/api/x/get',
      HTTP_OPTIONS
    )
    }

    getEntities(){
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          Authorization: 'Bearer' + this.tokenService.getToken(),
          withCredentials: 'true' 
        }),
    }
    return this.http.get(
      this.rootURL + 'RSAdaptorFE/api/v1/frameb/apis/entities/x/get',
      HTTP_OPTIONS
    )
  }

  getGroupApis(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer' + this.tokenService.getToken(),
        withCredentials: 'true'
      }),
    }
    return this.http.get(
      this.rootURL + 'RSAdaptorFE/api/v1/frameb/apis/groups/x/get',
      HTTP_OPTIONS
    )
  }
  




}


import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CreateBankComponent } from 'src/app/banks/create-bank/create-bank/create-bank.component';
import { Bank } from 'src/app/models/Banks/bank.model';
import { Calendar } from 'src/app/models/calendar/calendar.model';
import { Country } from 'src/app/models/country/country.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { CalendarsService } from 'src/app/services/calendars/calendars.service';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-create-calendar',
  templateUrl: './create-calendar.component.html',
  styleUrls: ['./create-calendar.component.css'],
  host: { class: 'dialog-component' },
})
export class CreateCalendarComponent implements OnInit {
  loading = true;
  firstFormGroup!: UntypedFormGroup;
  templates: any[] = [];
  actions: any[] = [];
  stageId = '';
  ids = [];
  countries: Country[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateBankComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Bank[],
    public dialog: MatDialog,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public route: ActivatedRoute,
    private calendarService: CalendarsService,
    private countriesService: CountriesService,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      calendarId: ['', Validators.required],
      calendarDescription: ['', Validators.required],
      calendarCountry: ['', Validators.required],
    });
    this.loadCountries();
    this.loading = false;
  }

  loadCountries(){
    this.loading = true;
    this.countriesService.getCountries().subscribe({
      next: (data: BaseResponse<Country[]>) => {
        this.countries = data.messageRS;
        for(let c of this.countries){
          if(c.image === ''){
            c.image = this.getMissingCountryFlag(c.countryName, 'svg');
          }
        }
      },
      error: (_err: any) => {
        this.loading = false;
      },
    });
  }

  getMissingCountryFlag(countryNameOrId: string, type: string) {
    return this.countriesService.getMissingCountryFlag(countryNameOrId, type);
  }

  getSelectedCountryAltFlag(): string {
    const selectedCountry = this.firstFormGroup.get('calendarCountry')?.value;
    if (selectedCountry) {
      return this.getMissingCountryFlag(selectedCountry.countryId, 'png');
    }
    return '';
  }
  



  create() {
    this.loading = true;

    let calendar: Calendar = {
      calendarId: this.firstFormGroup.get('calendarId')!.value,
      calendarDescription: this.firstFormGroup.get('calendarDescription')!
        .value,
      calendarCountry: this.firstFormGroup.get('calendarCountry')!.value.countryId,
    };

    this.calendarService.create(calendar).subscribe({
      next: (res: BaseResponse<Calendar>) => {
        this.loading = false;
        switch (res.statusRS?.code) {
          case '0':
            this.exit('created');
            break;
          case '-91756':
            this.exit('duplicate');
            break;
          default:
            this.exit('error');
            break;
        }
      },
      error: (err) => {
        this.loading = false;
        this.logoutDialogService.openLogoutDialog(err);
        if (err.statusCode !== 401) {
          this.exit('error');
        }
      },
    });
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  emptyField(text: string | undefined): string {
    if (text === '' || text === undefined) return '-';
    return text;
  }
}

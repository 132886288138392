<!-- <ng-container *ngIf="!loading">
    <div class="filter-wrapper">
  <mat-form-field appearance="fill" class="large-filter ">
    <mat-label i18n>{{loc.tableHeaders.key}}</mat-label>
    <input matInput type="text" (keyup)="addFilter($event,'key')" [formControl]="myControl"
        [matAutocomplete]="autoBranch" />
    <mat-icon matSuffix style="font-size: 20px; color: grey;">search</mat-icon>
    <mat-autocomplete #autoBranch="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
            (click)="addFilter(option,'key')">
            {{option}}
        </mat-option>
    </mat-autocomplete>
    </mat-form-field>
    </div>
      <ng-container *ngIf="attributesAllowedDataSource.data.length > 0">
        <div [class]="tableStyle">
          <mat-card style="padding: 0px; border-radius: 10px">
            <mat-card
              style="overflow-y: auto; padding: 0px; box-shadow: none !important"
            >
              <table
                mat-table
                scrollY="true"
                [dataSource]="attributesAllowedDataSource"
                matSort
                class="table"
              >
                <ng-container matColumnDef="key">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="hide-arrow"
                    i18n
                  >
                    {{ this.loc.tableHeaders.key }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="bold-table-item">
                    {{ element.key }}
                  </td>
                </ng-container>
    
                <ng-container matColumnDef="value">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="hide-arrow"
                    i18n
                  >
                    {{ this.loc.tableHeaders.value }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.value }}
                  </td>
                </ng-container>
    
                <ng-container matColumnDef="actions">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="text-align: center"
                    i18n
                  >
                    {{ this.loc.tableHeaders.actions }}
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center">
                    <button
                      mat-icon-button
                      (click)="editValue(element)"
                      i18n-matTooltip
                      [matTooltip]="loc.attributes.edit"
                    >
                    <mat-icon fontSet="material-icons-outlined" class="black-icon">edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
    
                <ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    class="row"
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </ng-container>
              </table>
            </mat-card>
          </mat-card>
        </div>
        <div class="paginator-wrapper">
          <mat-paginator
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            (page)="pageEvents($event)"
          >
          </mat-paginator>
          <ng-container *ngIf="attributesAllowedDataSource.data.length === 1">
            <label class="count" i18n>
              {{ attributesAllowedDataSource.data.length }}
              {{ loc.attributes.singular.toLowerCase() }}
            </label>
          </ng-container>
          <ng-container *ngIf="attributesAllowedDataSource.data.length > 1">
            <label class="count" i18n>
              {{ attributesAllowedDataSource.data.length }}
              {{ loc.attributes.plural.toLowerCase() }}
            </label>
          </ng-container>
        </div>
      </ng-container>
      <div *ngIf="attributesAllowedDataSource.data.length == 0" class="empty-list">
        <div><img class="empty-img" src="assets/img/tipos-agentes.png" /></div>
        <div class="empty-lbl">
          <label i18n>
            {{ loc.attributes.empty }}
          </label>
        </div>
      </div>
    </ng-container>
    
    <ng-container *ngIf="loading">
      

    </ng-container> -->
    
<ng-container *ngIf="!loading">
  <app-table
    [emptyState]="emptyState"
    [data]="data"
    [columns]="columns"
    [paginatorInfo]="paginatorInfo"
    [transformers]="transformers"
    [title]="'attribute-allowed'"
    [filters]="filters"
    [actions]="actions"
    >
  </app-table>
</ng-container>

<ng-container *ngIf="loading">
  <app-loading-table
    [title]="'attribute-allowed'"
    [columns]="columns"
    [filters]="filters">
  </app-loading-table>
</ng-container>
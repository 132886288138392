<app-page-title [withBackButton]="true" icon="paid" [title]="currencyCode"></app-page-title>

<mat-tab-group
  class="tab-bar"
  [(selectedIndex)]="selectedIndex"
  (selectedTabChange)="onTabChange()"
>
  <mat-tab [label]="loc.tabNames.denominations" i18n>
    <app-denominations-list [currencyCode]="currencyCode"></app-denominations-list>
  </mat-tab>

</mat-tab-group>

import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { Action } from 'src/app/models/extension-points/Action.model';
import { Rule } from 'src/app/models/extension-points/rule.model';

import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ExtensionPointsService } from 'src/app/services/extension-points/extension-points.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateExtensionPointComponent } from '../create-extension-point/create-extension-point.component';
import { Column, CreateButton, EmptyState, PaginatorInfo, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';


@Component({
  selector: 'app-extension-points-list',
  templateUrl: './extension-points-list.component.html',
  styleUrls: ['./extension-points-list.component.css'],
  host: {
    class: 'component'
  }
})
export class ExtensionPointsListComponent implements OnInit {

  @Input() tableStyle = "tableResponsive"
  @Input() actionEntity = ""
  @Input() action: Action = {};

  loading = true;
  operationId = '';
  digitalServiceId = '';
  extensionPoints: Rule[] = [];

  actionId = '';
  touchPointId = '';

  pageSize = 5;
  pageIndex = 0;

  constructor(
    public extensionPointsService: ExtensionPointsService,
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    public loc: LocalizationService,
    private htmlGen: SafeHTMLGeneratorService
  ) { }


  emptyState: EmptyState = {
    image: 'assets/img/puntos-extension.png',
    message: this.loc.extensionPoints.empty,
    buttons: [{
      text: this.loc.common.create,
      action: () => this.create()
    }]
  };

  data: TableData[] = []
  columns: Column[] = [
    {
      key: 'order',
      title: this.loc.tableHeaders.ordinal,
      sortable: true,
    },
    {
      key: 'description',
      title: this.loc.tableHeaders.description,
      sortable: true,
    },
    {
      key: 'labelId',
      title: this.loc.tableHeaders.flow,
      sortable: true,
    },
    {
      key: 'enable',
      title: this.loc.tableHeaders.enabled,
      centered: true,
    },
    {
      key: 'actionResult',
      title: this.loc.tableHeaders.action,
      sortable: true,
    }
  ];
  paginatorInfo: PaginatorInfo = this.loc.extensionPoints
  transformers: TableTransformer = {
    enable: (value: any) => this.htmlGen.statusIcon(value == "1"),
    actionResult: (value: any) => {
      return value === "CANCEL" ? this.loc.extensionPoints.cancel : this.loc.extensionPoints.continue

    }
  }
  filters: TableFilter[] = [];
  actions = [
    {
      tooltip: this.loc.common.moveUp,
      icon: 'arrow_upward',
      action: (row: any) => this.moveUp(row),
      condition: (row: any) => row.isDefaultPayCorr == "1",
      disabled: (row: any) => !this.showUpArrow(row)
    },
    {
      tooltip: this.loc.common.moveDown,
      icon: 'arrow_downward',
      action: (row: any) => this.moveDown(row),
      condition: (row: any) => row.isDefaultPayCorr == "1",
      disabled: (row: any) => !this.showDownArrow(row)
    },
    {
      tooltip: this.loc.extensionPoints.edit,
      icon: 'edit',
      action: (row: any) => this.edit(row),
      condition: (row: any) => row.isDefaultPayCorr == "1"
    },
    {
      tooltip: this.loc.extensionPoints.delete,
      icon: 'delete',
      action: (row: any) => this.delete(row),
      condition: (row: any) => row.isDefaultPayCorr == "1"
    }
  ];
  createButton: CreateButton = {
    tooltip: this.loc.extensionPoints.create,
    action: () => this.create()
  };

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.operationId = params['operationId'];      
      this.touchPointId = params['touchPointId'];
      this.actionId = params['actionId'];
    });

    this.loadExtensionPoints();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['action'].currentValue && changes['actionEntity'].currentValue) {
      this.loadExtensionPoints();
    }
  }


  loadExtensionPoints(): void {
    this.loading = true;
    this.extensionPointsService
      .getExtensionPoints(this.operationId, this.touchPointId, this.actionId, this.actionEntity)
      .subscribe(
        (res) => {
          let response =
            res.messageRS as unknown as Rule[];
          this.extensionPoints = response;
          this.action.rules = this.extensionPoints;
          this.extensionPoints = this.extensionPoints.sort((a, b) => (a.order!! < b.order!!) ? -1 : 1)

          this.data = this.extensionPoints as any as TableData[];

          this.loading = false;
        },
        (err) => {
          this.logoutDialogService.openLogoutDialog(err);
        }
      );
  }

  create() {
    const dialogRef = this.dialogService.openDialog(CreateExtensionPointComponent,
      this.action,
      "large");

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage("create", this.loc.extensionPoints)
        );
        this.loadExtensionPoints();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(this.loc.errors.errorSortingExtensionPoint);
      }
    });
  }

  modifyAction(row: any, result: any) {
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value
    let newRow = { ...row, description: newDescription }
    this.extensionPointsService
      .updateExtensionPoint(newRow)
      .subscribe(
        {
          next: (res: BaseResponse) => {
            setTimeout(() => {
              this.loading = false;
              if (res.statusRS?.code === '0') {
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage("update", this.loc.extensionPoints)
                );
                this.loadExtensionPoints();
              } else {
                this.snackbarService.openErrorSnackbar(this.loc.errors.errorSortingExtensionPoint);
                this.loadExtensionPoints();
              }
            }, 200);
          },
          error: (err) => {
            this.loading = false;
            this.logoutDialogService.openLogoutDialog(err);
            if (err.statusCode !== 401) {
              this.snackbarService.openErrorSnackbar(this.loc.errors.errorSortingExtensionPoint);
              this.loadExtensionPoints();
            }
          }
        }
      );
  }

  edit(element: any) {
    let data: EditModalData = {
      title: this.loc.extensionPoints.edit,
      icon: 'hub',
      fields: [
        {
          label: this.loc.fields.description,
          value: element.description,
          errorMsg: this.loc.errors.emptyDescription,
          validators: [Validators.required],
        }
      ]
    };
    const dialogRef = this.dialogService.openDialog(EditModalComponent,
      data,
      "x-small");
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.modifyAction(element, result)
      }
    });
  }

  delete(element: any) {
    let dataModal: DeleteModalData = {
      title: this.loc.extensionPoints.delete,
      icon: 'hub',
      confirmDeleteMessage: this.loc.confirmDelete(this.loc.extensionPoints)
    }
    const dialogRef = this.dialogService.openDialog(DeleteModalComponent,
      dataModal,
      "x-small");

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        let rules = this.action.rules                                
        rules = rules!.filter(rule => rule.order !== element.order);        
        rules!.sort((a: any, b: any) => (a.order!! < b.order!!) ? -1 : 1);                
        rules?.forEach((x, index) => {
          rules![index].order = index + 1;
        })    
       
        let actionToSend = { ... this.action };
        actionToSend.rules = rules
        this.extensionPointsService.updateExtensionPointsTemplate(actionToSend)
          .subscribe(
            (res: BaseResponse) => {
              if (res.statusRS?.code === "0") {

                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage("delete", this.loc.extensionPoints)
                );
              } else {
                this.snackbarService.openErrorSnackbar(this.loc.errors.errorSortingExtensionPoint);
              }
              this.loadExtensionPoints();
            }, (err) => {
              this.logoutDialogService.openLogoutDialog(err);
            }
          )
      }
    });
  }

  moveUp(element: any): void {
    const index = this.action.rules!.indexOf(element);
    if (index === 0) {      
      return;
    }

    const swap = this.action.rules![index - 1];
    swap.order = parseInt(swap.order!.toString()) + 1;
    element.order = element.order - 1;

    this.action.rules![index - 1] = element;
    this.action.rules![index] = swap;

    this.loading = true;
    this.action.rules!.sort((a: any, b: any) => (a.order!! < b.order!!) ? -1 : 1);
    let actionToSend = { ... this.action };
    this.extensionPointsService.updateExtensionPointsTemplate(actionToSend)
      .subscribe(
        (res: BaseResponse) => {
          if (res.statusRS?.code === "0") {
          } else {
            this.snackbarService.openErrorSnackbar(this.loc.errors.errorSortingExtensionPoint);
          }
          this.loadExtensionPoints();
        }, (err) => {
          this.logoutDialogService.openLogoutDialog(err);
        }
      )
  }

  moveDown(element: any): void {
    const index = this.action.rules!.indexOf(element);
    const swap = this.action.rules![index + 1];
    swap.order = parseInt(swap.order!.toString()) - 1;
    element.order = parseInt(element.order) + 1;
    this.action.rules![index + 1] = element;
    this.action.rules![index] = swap;
    this.loading = true;
    this.action.rules!.sort((a: any, b: any) => (a.order!! < b.order!!) ? -1 : 1);
    let actionToSend = { ... this.action };
    this.extensionPointsService.updateExtensionPointsTemplate(actionToSend)
      .subscribe(
        (res: BaseResponse) => {
          if (res.statusRS?.code === "0") {

          } else {
            this.snackbarService.openErrorSnackbar(this.loc.errors.errorSortingExtensionPoint);
          }
          this.loadExtensionPoints();
        }, (err) => {
          this.logoutDialogService.openLogoutDialog(err);
        }
      )
  }

  showUpArrow(element: any) {
    const index = this.action.rules!.indexOf(element);
    return index >= 1
  }

  showDownArrow(element: any) {
    const index = this.action.rules!.indexOf(element);
    return this.action.rules!.length !== index + 1
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { LogoutDialogService } from '../services/logout-confirmation/logout-dialog.service';
import { BaseService } from '../services/base.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private logoutService:LogoutDialogService, private router:Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let tokenStr = localStorage.getItem('access_token')
      if(tokenStr){
        let token:{exp:number} = jwtDecode(tokenStr)
        if(token.exp > Date.now() / 1000){
          return true;
        }else{
          this.logoutService.openLogoutDialog({statusCode:401})
          return false
        }
      }
      this.router.navigate(['/login']);
      return false;
    }
}

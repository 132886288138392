<div class="confirm-title">
    <div class="confirm-title-inner-div">
      <button mat-fab class="confirm-dialog-button blue-button-icon">
        <mat-icon class="action-button-icon">logout</mat-icon>
      </button>
      <div class="dialog-title" *ngIf="!expired"><label i18n>{{loc.login.logout}}</label></div>
      <div class="dialog-title" *ngIf="expired"><label i18n>{{loc.login.expiredSession}}</label></div>
    </div>
  
    <button class="exit-button" *ngIf="!expired" (click)="exit()"  >
      <mat-icon matSuffix class="exit-icon">close</mat-icon>
    </button>
  </div>
    
    
    <div class="delete-paragraph">
        <ng-container *ngIf="!expired"><label i18n>{{loc.login.logoutConfirmation}}</label></ng-container>
        <ng-container *ngIf="expired"><label i18n>{{loc.login.logoutAndLogBackIn}}</label></ng-container>
    </div>
    
    <div class="confirm-buttons">
      <button mat-fab class="button green-color" (click)="logout()"  >
          <mat-icon >logout</mat-icon>
      </button>
    </div>
    
import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {BaseResponse} from 'src/app/models/base/base-response.model';
import {Step} from 'src/app/models/step/step.model';
import {ImageUploaderService} from 'src/app/services/image-uploader/image-uploader.service';
import {LocalizationService} from 'src/app/services/localization/localization.service';
import {SnackbarService} from 'src/app/services/snackbar/snackbar.service';
import {StepsService} from 'src/app/services/steps/steps.service';


@Component({
  selector: 'app-create-step',
  templateUrl: './create-step.component.html',
  styleUrls: ['./create-step.component.css'],
  host: {
    class: "dialog-component"
  }
})
export class CreateStepComponent implements OnInit {
  loading = false;
  apis: any[] = [];
  types: any[] = [];
  stepIcon: string = '';
  selectedFile: File = new File([], '');
  id = '';
  name = '';
  type = '';
  api = '';

  firstFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    name: ['', Validators.required],
    type: ['', [Validators.required]],
    api: [''],
  }, {validators: this.checkApiRequired()});

  constructor(
    public loc: LocalizationService,
    public dialogRef: MatDialogRef<CreateStepComponent>,
    public snackbarService: SnackbarService,
    private formBuilder: UntypedFormBuilder,
    private stepsService: StepsService,
    private imageUploader: ImageUploaderService,
  ) {
  }

  ngOnInit(): void {

    this.getApis();
    this.getTypes();
  }


  checkApiRequired() {
    return (_control: AbstractControl) => {
      const type = this.firstFormGroup?.value.type;
      if (type !== 'STATIC') {
        if (this.firstFormGroup?.value.api === '') {
          this.firstFormGroup?.controls.api.setErrors({apiRequired: true})
          return {apiRequired: true};
        } else {
          this.firstFormGroup?.controls.api.setErrors(null)
          return null;
        }
      } else {
        this.firstFormGroup?.controls.api.setErrors(null)
        return null;
      }
    };
  }

  exit(message: string): void {
    this.dialogRef.close(message);
  }

  createStep() {
    let step: Step = {
      stepId: this.id,
      name: this.name,
      type: this.type,
      api: this.api,
      icon: this.stepIcon,
    }
    this.stepsService.createStep(step).subscribe(
      (res: BaseResponse) => {
        setTimeout(() => {
          if (res.statusRS?.code === "0") {
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('create', this.loc.metadataType, 'f')
            );
            this.exit('created');
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.genericError('create', this.loc.metadataType)
            );
            this.exit('error');
          }
          this.loading = false;
        }, 200);
      }, (err) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (err.statusCode !== 401) {
          this.loading = false;
          console.log(err)
          this.exit('error');
        }
      }
    )
  }

  getApis() {
    this.loading = true
    this.stepsService.getApis().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.apis = res.messageRS as unknown as any[];
          console.log(this.apis)
          this.apis.sort((a, b) => (a.name > b.name) ? 1 : -1);
          this.apis.unshift({apiId: '', name: 'No API'})
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  getTypes() {
    this.loading = true
    this.stepsService.getStepTypes().subscribe(
      (res: BaseResponse) => {
        if (res.statusRS?.code === '0') {
          this.loading = false;
          this.types = res.messageRS as unknown as any[];
          this.types.sort((a, b) => (a.name > b.name) ? 1 : -1);
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  onFileSelect($event: any): void {
    this.selectedFile = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    reader.onload = (_event) => {
      this.stepIcon = reader.result?.toString() || '';
    };
  }

  saveImage() {
    ;
    this.loading = true;
    if (this.selectedFile.name !== '') {
      this.imageUploader.uploadImageToCloudinary(this.selectedFile, this.id)?.subscribe((response: any) => {
        if (response && this.id) {
          this.stepIcon = response.secure_url;
          this.createStep();
        }
      });
    } else {
      this.createStep();
    }
  }

  next(stepper: MatStepper): void {
    this.id = this.firstFormGroup.get('id')!!.value;
    this.name = this.firstFormGroup.get('name')!!.value;
    this.type = this.firstFormGroup.get('type')!!.value;
    this.api = this.firstFormGroup.get('api')!!.value;
    stepper.next();
  }


}

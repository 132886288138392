import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { MetadataType } from 'src/app/models/metadata/metadata-type/metadataType.model';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { MetadataField } from 'src/app/models/metadata/metadata-field/metadataField.model';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Observable, map } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  readonly rootURL = environment.apiUrl;

  constructor(
    private tokenService: TokenService,
    private http: HttpClient,
    
  ) { }

  listMetadatTypes(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1.0/paycorr/metadata_types/metadata_id/x/list-metadata-types',
      HTTP_OPTIONS
    );
  }

  createMetadataType(metadataType: MetadataType){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      id: metadataType.id,
      metadataName:  metadataType.metadataName,
    };

    return this.http.post(
      this.rootURL +
        'RSAdaptorFE/api/v1.0/paycorr/metadata_types/metadata_id/x/create-metadata-type',
        baseRequest,
        HTTP_OPTIONS
    );
  }

  editMetadataType(metadataType: MetadataType){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      metadataName:  metadataType.metadataName,
    };

    return this.http.put(
      this.rootURL +
        'RSAdaptorFE/api/v1.0/paycorr/metadata_types/metadata_id/'+metadataType.id+'/update-metadata-type',
        baseRequest,
        HTTP_OPTIONS
    );
  }

  deleteMetadataType(id: string){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };
    return this.http.delete(
      this.rootURL +
        'RSAdaptorFE/api/v1.0/paycorr/metadata_types/metadata_id/'+id+'/delete-metadata-type',
        HTTP_OPTIONS
    );
  }

  listMetadataFields(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/metadata-fields/metadata-fields/x/list-metadata-field',
      HTTP_OPTIONS
    );
  }

  createMetadataField(metadataField: any){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      Id: metadataField.id,
      Description: metadataField.description,
      Type: metadataField.type,
      ControlType: metadataField.controlType,
      DefaultValue: metadataField.defaultValue,
      IsMandatory: metadataField.isMandatory,
      IsEditable: metadataField.isEditable,
      IsHidden: metadataField.isHidden,
      Datasource: metadataField.datasource,
      DatasourceData: metadataField.datasourceData,
      Validation: metadataField.validation,
      Checkout: metadataField.checkout,
    };

    return this.http.post(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/metadata-fields/metadata-fields/x/create-metadata-fields',
        baseRequest,
        HTTP_OPTIONS
    );
  }

  editMetadataField(metadataField: any){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };

    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      description: metadataField.description,
      type: metadataField.type,
      controlType: metadataField.controlType,
      defaultValue: metadataField.defaultValue,
      isMandatory: metadataField.isMandatory,
      isEditable: metadataField.isEditable,
      isHidden: metadataField.isHidden,
      datasource: metadataField.datasource,
      datasourceData: metadataField.datasourceData,
      validation: metadataField.validation,
      checkout: metadataField.checkout
    };

    return this.http.put(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/metadata-fields/metadata-fields/'+metadataField.id+'/update-metadata-field',
        baseRequest,
        HTTP_OPTIONS
    );
  }

  deleteMetadataField(id: string){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      withCredentials: false,
    };
    return this.http.delete(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/metadata-fields/metadata-fields/'+id+'/delete-metadata-field',
        HTTP_OPTIONS
    );
  }

  getMetadataField(id: string){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params: {
        metadataId: id
      },
      withCredentials: false,
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/metadata-fields/metadata-fields/x/list-metadata-field',
      HTTP_OPTIONS
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateApplicationComponent } from '../create-application/create-application.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';

@Component({
  selector: 'app-list-applications',
  templateUrl: './list-applications.component.html',
  styleUrls: ['./list-applications.component.css'],
  host: {
    class: 'component',
  },
})
export class ListApplicationsComponent implements OnInit {
  loading = true
  constructor(
    public dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    private logoutDialogService: LogoutDialogService,
    private notificationsService: NotificationsService,
    public loc: LocalizationService
  ) {}


  ngOnInit(): void {
    this.getApplications();
  }

  data : TableData[] = []

  emptyState: EmptyState = {
    image: 'assets/img/apps.png',
    message: this.loc.applications.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      }
    ]
  }

  columns : Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
      sortable: true,
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
    }
  ]

  paginatorInfo = this.loc.applications

  filters:TableFilter[] = [
    {
      label: this.loc.tableHeaders.id,
      key: 'id',
      type: 'text',
      placeholder: this.loc.tableHeaders.id,
    },
    {
      label: this.loc.tableHeaders.description,
      key: 'description',
      type: 'text',
      placeholder: this.loc.tableHeaders.description,
    }
  ]

  actions: Action[] = [{
    icon: 'edit',
    tooltip: this.loc.applications.edit,
    action: (element) => this.edit(element)
  },
  {
    icon: 'delete',
    tooltip: this.loc.applications.delete,
    action: (element) => this.delete(element)
  }

]

createButton:CreateButton = {
  tooltip: this.loc.applications.create,
  action: () => this.create()
}

  getApplications() {
    this.loading = true;
    this.notificationsService.getApplications().subscribe({
      next: (res) => {
        this.data = res.messageRS as unknown as any[];
        this.loading = false;
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateApplicationComponent,
      {},
      'medium-large'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.applications, 'f')
        );
        this.getApplications();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.errorCreatingApplication
        );
      }
    });
  }

  edit(element: any) {
    let data: EditModalData = {
      title: this.loc.applications.edit,
      icon: 'notifications',
      fields: [
        { label: this.loc.fields.description, 
          value: element.description, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        }]
    }

    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAction(element, result)
      } 
    });
  }

  editAction(row: any, result: any){
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value

    this.notificationsService.editApplication(row.id, newDescription).subscribe(
      {
        next: (res) => {
          this.loading = false;
          if(res.statusRS?.code === "0"){
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('update', this.loc.applications, 'f')
            );
            this.getApplications();
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.errorCreatingApplication
            );
          }
        }, 
        error: (err) => {
          this.loading = false;
          this.logoutDialogService.openLogoutDialog(err);
          if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.errorCreatingApplication
            );
          }
        }
      }
    )
  }

  deleteAction(element: any){
    this.notificationsService.deleteApplication(element.id).subscribe(
        {
          next: (res) => {
            this.loading = false;
            if(res.statusRS?.code === "0"){
              this.snackbarService.openSuccessSnackbarWithNoReload(
                this.loc.successMessage('delete', this.loc.applications, 'f')
              );
              this.getApplications();
            } else {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.applications)
              );
            }
          }, 
          error: (err) => {
            setTimeout(() => {
              this.loading = false;
            }, 200);
            this.logoutDialogService.openLogoutDialog(err);
            if (err.statusCode !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError('delete', this.loc.applications)
              );
            }
          }
        }
      )
  }

  delete(element: any) {
    let data: DeleteModalData = {
      title: this.loc.applications.delete,
      icon: 'notifications',
      confirmDeleteMessage: this.loc.applications.confirmDeleteApplication,
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.loading = true;
        this.deleteAction(element)
      }
    });
  }

}

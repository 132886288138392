import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExternalEntity } from 'src/app/models/Frame/ExternalEntity.model';
import { FrameAPI } from 'src/app/models/Frame/FrameAPI.model';
import { GroupName } from 'src/app/models/Frame/GroupName.model';
import { API } from 'src/app/models/api/api.model';
import { ApisService } from 'src/app/services/apis/apis.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { CreateApiComponent } from '../../create-api/create-api/create-api.component';

@Component({
  selector: 'app-update-api',
  templateUrl: './update-api.component.html',
  styleUrls: ['./update-api.component.css'],
  host: {
    class: 'dialog-component'
  }
})
export class UpdateApiComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: API,
    private dialogRef: MatDialogRef<UpdateApiComponent>,
    private _formBuilder: UntypedFormBuilder,
    private apiService: ApisService,
    public loc: LocalizationService
  ) {}
  loading = false;
  body = '';
  externalEntity = '';
  groupName = '';
  externalEntities: ExternalEntity[] = [];
  groupNames: GroupName[] = [];
  frameAPIs: FrameAPI[] = [];
  firstFormGroup!: UntypedFormGroup;
  secondFormGroup!: UntypedFormGroup;
  invalidJSON = false;

  externalEntityUpdate: ExternalEntity = {} as ExternalEntity;
  groupNameUpdate: GroupName = {} as GroupName;
  frameAPIUpdate: FrameAPI = {} as FrameAPI;
  ngOnInit(): void {
    console.log(this.data);
    this.firstFormGroup = this._formBuilder.group({
      apiId: ['', Validators.required],
      apiName: ['', Validators.required]
    });

    this.firstFormGroup.get('apiId')?.setValue(this.data.apiId);
    this.firstFormGroup.get('apiName')?.setValue(this.data.name);

    this.secondFormGroup = this._formBuilder.group({
      body: ['', [Validators.required, this.jsonValidator()]]
    });
    //this.firstFormGroup.get('body')?.setValue(this.data.body);
    this.body = JSON.stringify(this.data.body, undefined, 4);
  }

  jsonValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        JSON.parse(control.value);
        return null; // Retorna null si el JSON es válido
      } catch (error) {
        return { invalidJson: true }; // Retorna un objeto con la clave "invalidJson" si el JSON es inválido
      }
    };
  }

  exit(created: string) {
    this.dialogRef.close(created);
  }

  update() {
    if (this.secondFormGroup.get('body')?.hasError('invalidJson')) {
      this.invalidJSON = true;
    } else {
      this.invalidJSON = false;
      console.log(JSON.parse(this.secondFormGroup.get('body')?.value), 'json');
      this.data.name = this.firstFormGroup.get('apiName')?.value;
      this.data.body = this.body;
      this.apiService.updateAPI(this.data).subscribe({
        next: (res: any) => {
          if (res.statusRS.code === '0') {
            this.exit('updated');
          } else {
            console.log(res.statusRS.description);
          }
        },
        error: (error: any) => {
          console.log(error);
          this.exit('error');
        }
      });
    }
  }
}

<mat-card class="details-card" >
    <div class="flex-container"> 
      <div class="flex-container-vertical" *ngFor="let card of cards">
        <div>
            <label class="card-header" i18n>{{card.title}}</label>
        </div>
          <div>
            <ng-container *ngIf="!loading">
              <label class="card-value">
                {{ card.description }}
              </label>
            </ng-container>
            <div *ngIf="loading">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  background: 'no-repeat rgb(227 228 230 / 33%)',
                  height: '18px',
                  width: '150px'
                }"
              ></ngx-skeleton-loader>
            </div>
        </div>
      </div>
    </div>
  </mat-card>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SafeHTMLGeneratorService {

  constructor() { }


  imageOrDefaultIcon(value: string, defaultIcon: string, alt: string): string {
    if(value && value !== ''){
      return `
      <img src="${value}" alt="${alt}"
        style="object-fit: cover; width: 40px; height: 40px; border-radius: 50%; background-color: #d2d2d2;  margin:auto;"
        onerror="this.onerror=null;this.src='assets/img/user_icon.png';"
        />`
    }else{
      return `
      <button class=" button green-color mat-fab mat-accent img-button" style="margin-right:unset;"  width="40px" height="40px">
        <span style="font-size: 20px;" class="material-icons"> ${defaultIcon} </span>
      </button>`
    }
  }
  
  statusIcon(isActive: boolean): string {
    return isActive ? `
          <span
          style="color: var(--green-color);"
          
          class="menu-item-icon list-status-icon material-icons" style="vertical-align: bottom;">check_circle
          </span>
        ` : 
       `
       <span
         style="color: var(--menu-item-grey);"
         class="menu-item-icon list-status-icon material-icons" style="vertical-align: bottom;">check_circle
       </span>
       ` 
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { PaymentButtonTypeFields } from 'src/app/models/payment-buttons/paymentButtonTypeFields.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { PaymentButtonService } from 'src/app/services/payment-button/payment-button.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreatePaymentButtonTypeFieldsComponent } from './create-payment-button-type-fields/create-payment-button-type-fields.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter, TableTransformer } from 'src/app/utils/table/table.model';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';

@Component({
  selector: 'app-payment-button-type-fields',
  templateUrl: './payment-button-type-fields.component.html',
  styleUrls: ['./payment-button-type-fields.component.css'],
  host: {
    class: 'component',
  },
})
export class PaymentButtonTypeFieldsComponent implements OnInit {
  loading: boolean = true;
  data : TableData[] = []
  @Input() buttonType!: string;
  columns: Column[] = [
    {
      title: this.loc.tableHeaders.fieldId,
      key: 'fieldFk',
    },
    {
      title: this.loc.tableHeaders.fieldName,
      key: 'fieldName',
    },
    {
      title: this.loc.tableHeaders.required,
      key: 'isMandatory',
      centered: true,
    },
  ]
  constructor(
    public loc: LocalizationService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private paymentButtonService: PaymentButtonService,
    private htmlGen : SafeHTMLGeneratorService
  ) {}

  ngOnInit(): void {
    this.loadTypeFieldsId();
  }

  loadTypeFieldsId() {
    this.loading = true;
    this.paymentButtonService.getTypeFields(this.buttonType).subscribe({
      next: (data: BaseResponse<PaymentButtonTypeFields[]>) => {
        this.data = data.messageRS  as any as TableData[]
        this.loading = false;
      },
      error: (_err: any) => {},
    });
  }


  emptyState: EmptyState = {
    image: 'assets/img/empty-payment-button.png',
    message: this.loc.paymentButtonTypeFields.empty,
    buttons: [
      {
        text: this.loc.common.create,
        action: () => this.create(),
      }]
  }

  paginatorInfo = this.loc.paymentButtonTypeFields

  filters : TableFilter[] = [
    {
      label: this.loc.tableHeaders.fieldId,
      key: 'fieldFk',
      type: 'text',
      placeholder: this.loc.tableHeaders.fieldId,
    },
    {
      label: this.loc.tableHeaders.fieldName,
      key: 'name',
      type: 'text',
      placeholder: this.loc.tableHeaders.fieldName,
    }
    
  ]

  transformer: TableTransformer = {
    isMandatory: (value: any) => {
      return this.htmlGen.statusIcon(value == "1")
    }
  }

  actions : Action [] = [
    {
      icon: 'edit',
      action: (row: any) => this.editField(row),
      tooltip: this.loc.paymentButtonTypeFields.edit,
    },
    {
      icon: 'delete',
      action: (row: any) => this.delete(row),
      tooltip: this.loc.paymentButtonTypeFields.delete,
    }
  ]


  createButton:CreateButton = {
    tooltip: this.loc.paymentButtonTypeFields.create,
    action: () => this.create()
  }

  

  // CRUD OPERATIONS
  create(): void {
    const dialogRef = this.dialogService.openDialog(
      CreatePaymentButtonTypeFieldsComponent,
      this.buttonType,
      'medium-large'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage(
            'create',
            this.loc.paymentButtonTypeFields,
            'feminine'
          )
        );
        this.loadTypeFieldsId();
      }
      if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('create', this.loc.paymentButtonTypeFields)
        );
        this.loadTypeFieldsId();
      }
      if (result === 'duplicate') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.paymentButtonTypeFieldAlreadyExists
        );
      }
    });
  }

  editField(row: any) {
    let data: EditModalData = {
      title: this.loc.paymentButtonTypeFields.edit,
      icon: 'smart_button',
      fields: [
        { label: this.loc.tableHeaders.isMandatory, 
          value: row.isMandatory, 
          errorMsg: this.loc.errors.emptyDescription,
          isCombo: true,
          comboValues: [{key: "1", value: this.loc.common.yes},{key: "0", value: this.loc.common.no}]
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true
        this.editPaymentButtonTypeFieldAction(row, result)
      }
    });
  }

  editPaymentButtonTypeFieldAction(row: any, result: any) {
    let newIsMandatory = result.find((x: any) => x.label === this.loc.tableHeaders.isMandatory).value

    this.paymentButtonService.editTypeField(row.id, row.fieldFk, newIsMandatory).subscribe({
      next: (_response: BaseResponse<{}>) => {
        this.loading = false;
        if(_response.statusRS.code === '0'){
          this.snackbarService.openSuccessSnackbarWithNoReload(
            this.loc.successMessage(
              'update',
              this.loc.paymentButtonTypeFields,
              'feminine'
            )
          );
          this.loadTypeFieldsId();
        }else{
          this.snackbarService.openErrorSnackbar(
            this.loc.genericError('update', this.loc.paymentButtonTypeFields)
          );
        }
      },
      error: (_error: any) => {
        this.loading = false;
        this.snackbarService.openErrorSnackbar(
          this.loc.genericError('update', this.loc.paymentButtonTypeFields)
        );
      },
    });
  }

  delete(row: any) {
    let data: DeleteModalData = {
      title: this.loc.paymentButtonTypeFields.delete,
      icon: 'smart_button',
      confirmDeleteMessage: this.loc.confirmDelete(
        this.loc.paymentButtonTypeFields
      ),
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.paymentButtonService
          .deleteTypeField(row.id, row.fieldFk)
          .subscribe({
            next: (res: BaseResponse<any>) => {
              this.loading = true;
              switch (res.statusRS?.code) {
                case '0':
                  this.snackbarService.openSuccessSnackbarWithNoReload(
                    this.loc.successMessage(
                      'delete',
                      this.loc.paymentButtonTypeFields,
                      'feminine'
                    )
                  );
                  this.loadTypeFieldsId();
                  break;
                default:
                  this.snackbarService.openErrorSnackbar(
                    this.loc.genericError(
                      'delete',
                      this.loc.paymentButtonTypeFields
                    )
                  );
                  break;
              }
              this.loading = false;
            },
            error: (_err: any) => {
              this.snackbarService.openErrorSnackbar(
                this.loc.genericError(
                  'delete',
                  this.loc.paymentButtonTypeFields
                )
              );
            },
          });
      }
    });
  }

}

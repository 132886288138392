import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { TokenService } from '../token/token.service';
import { BaseRequest } from '../../models/base/base-request.model';
import { Person } from 'src/app/models/Person/person.model';
import { UserType } from 'src/app/models/users/UserType.model';
import { ServiceTemplateAgentType } from 'src/app/models/agents/service-template-agent-type.model';
@Injectable({
  providedIn: 'root',
})
export class UsersService {
  readonly rootURL = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private baseService: BaseService
  ) { }




  getFrameTemplates(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      }
      return this.http.get(
        this.rootURL + 'RSAdaptorFE/api/v1/paycorr/services/template/x/list-frame-templates',
        HTTP_OPTIONS
      )
    }
  

  getAgentTypes():any {
    return this.http.get(this.rootURL + 'RSAdaptorFE/api/v1/paycorr/agent-types/agent-type/x/list-agent-types');
  }

  editAgentType(id: string, description: string) {
    const body = {
      headerRQ: {
        msgId: '',
        timestamp: '',
      },
      securityRQ: {
        userId: "",
        hostId: "", 
        channelId: "",
        profile: "",
      },
      messageRQ: {
        description: description,
      }
    }
    
    return this.http.put(
      this.rootURL + `RSAdaptorFE/api/v1/paycorr/agent-types/agent-type/${id}/update-description`,
      body
    )
  }


  getUserDataByPperson(partyId: string): any {

    return this.http.get(
      this.rootURL +
      'RSAdaptorFE/api/v1/paycorr/users/party/' +
      partyId +
      '/get-user-details',
    );
  }

  getSupervisorData(userId: string) {

    return this.http.get(
      this.rootURL +
      'RSAdaptorFE/api/v1/paycorr/users/user/' +
      userId +
      '/get-user-details',
    );
  }

  getUserDataByUserId(userId: string) {

    return this.http.get(
      this.rootURL +
      'RSAdaptorFE/api/v1/paycorr/users/user/' +
      userId +
      '/get-user-details',
    );
  }

  getManagers(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    if (this.tokenService.validToken()) {
      return this.http.get(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/users/user/x/get-managers',
        HTTP_OPTIONS
      );
    } else {
      return this.baseService.invalidToken();
    }
  }

  createManager(person: Person){
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      person
    }
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json',
      }),
    };
    if (this.tokenService.validToken()) {
      return this.http.post(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/users/user/x/create-manager',baseRequest,
        HTTP_OPTIONS
      );
    } else {
      return this.baseService.invalidToken();
    }
  }

  getIdTypes(country:string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
      params:{
        country
      }
    };
    return this.http.get(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/users/identification-type/0/get-identification-types',
      HTTP_OPTIONS
    );  
  }

  deleteUser(userId: string) {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      })
    };
    return this.http.delete(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/users/user/' +
          userId +
          '/delete-manager',
          HTTP_OPTIONS
      );
  }

  getUserTypes():any {
    return this.http.get(this.rootURL + 'RSAdaptorFE/api/v1/paycorr/users/type/x/get-user-types');
  }

  updateUserType(userType: UserType){
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      description: userType.description
    };
    return this.http.put(
      this.rootURL +
        'RSAdaptorFE/api/v1/paycorr/users/type/' + userType.id + '/update-user-type', baseRequest
    );
  }

  createUserType(usertype: UserType){
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = usertype
    
    if (this.tokenService.validToken()) {
      return this.http.post(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/users/type/x/create-user-type',baseRequest
      );
    } else {
      return this.baseService.invalidToken();
    }
  }

  deleteUserType(userTypeId: string) {
    return this.http.delete(
        this.rootURL +
          'RSAdaptorFE/api/v1/paycorr/users/type/' +
          userTypeId +
          '/delete-user-type'
      );
  }

  getServiceTemplatesAllowed(agentTypeId: string = ''){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get(
      this.rootURL +
      'RSAdaptorFE/api/v1/paycorr/services/service-template/x/list-service-templates-allowed?agentType=' +
      agentTypeId,
      HTTP_OPTIONS
    );
  }


  getServiceTemplates(){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get(
      this.rootURL +
      'RSAdaptorFE/api/v1/paycorr/services/service-template/x/list-service-templates',
      HTTP_OPTIONS
    );
  }




  createServiceTemplateAllowed(template: ServiceTemplateAgentType){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json',
      }),
    };
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      templateId: template.templateId,
      description: template.description,
      agentType: template.agentType,
      status: template.status,
      image: template.imageId,
    };
    return this.http.post(
      this.rootURL +
      'RSAdaptorFE/api/v1/paycorr/services/service-template/x/create-service-template-allowed',
      baseRequest
      ,HTTP_OPTIONS
    )
  }

  editServiceTemplate(template: any){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json',
      }),
    };
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      templateId: template.templateId,
      description: template.description,
      status: template.status,
      imageId: template.imageId,
    };
    return this.http.put(
      this.rootURL +
    'RSAdaptorFE/api/v1/paycorr/services/service-template/x/update-service-template',
    baseRequest
    ,HTTP_OPTIONS
  )
  }



  editServiceTemplateAllowed(template: ServiceTemplateAgentType){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
        'Content-Type': 'application/json',
      }),
    };
    var baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = {
      templateId: template.templateId,
      description: template.description,
      agentType: template.agentType,
      status: template.status,
      image: template.imageId,
    };
    return this.http.put(
      this.rootURL +
    'RSAdaptorFE/api/v1/paycorr/services/service-template/x/update-service-template-allowed',
    baseRequest
    ,HTTP_OPTIONS
  )

  }

  createServiceTemplate (id:string,description:string,frameTemplate:string,image:string=''){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      })
    }
    let body : BaseRequest = new BaseRequest();
    body.messageRQ = {
      templateId: id,
      frameBankingTemplateId: frameTemplate,
      description: description,
      imageId:image
    }
    return this.http.post(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/services/service-template/x/create-service-template',
      body,
      HTTP_OPTIONS
    )
  }

  deleteServiceTemplate(id: string, agentType: string=''){
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
       
      }),
      params: {
        templateId: id,
        agentType: agentType,
      }
    };
    let url = '';
    if (agentType == ''){
      url = 'RSAdaptorFE/api/v1/paycorr/services/service-template/x/delete-service-template'
    }else{
      url = 'RSAdaptorFE/api/v1/paycorr/services/service-template/x/delete-service-template-allowed'
    }
    return this.http.delete(
      this.rootURL + url,
      HTTP_OPTIONS
    )
  }



}

export class MetadataField {
    id?: string;
    description?: string;
    creationDate?: string;
    changeDate?: string;
    type?: string;
    controlType?: string;
    defaultValue?: string;
    isMandatory?: string;
    isEditable?: string;
    isHidden?: string;
    datasource?: string;
    datasourceData?: string;
    validation?: string;
    checkout?: string;
    datasourceStruct?: string
}

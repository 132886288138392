import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs';
import { AttributeAllowed } from 'src/app/models/attributes/attributeAllowed';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { AttributesService } from 'src/app/services/attributes/attributes.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { __values } from 'tslib';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EmptyState, TableData, Column, PaginatorInfo, TableTransformer, TableFilter, Action } from 'src/app/utils/table/table.model';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-attribute-allowed-list',
  templateUrl: './attribute-allowed-list.component.html',
  styleUrls: ['./attribute-allowed-list.component.css'],
  host:{
    class: 'component'
  }
})
export class AttributeAllowedListComponent implements OnInit {

  loading: boolean = true;
  
  @Output() attributesChange: EventEmitter<AttributeAllowed[]> = new EventEmitter();
  attributesAllowed: AttributeAllowed[] = [];
  serviceTemplates: any[] = [];
  keys: string[] = [];
  
  groupId = '';
  serviceTemplateId = '';
  operationId = '';

  @Input() tableStyle = 'tableResponsive';

  emptyState: EmptyState = {
    image: "assets/img/tipos-agentes.png",
    message: this.loc.attributes.empty,
    buttons: []
  };
  
  data: TableData[] = []
  columns: Column[] = [
    {
       key : 'key',
       title : this.loc.tableHeaders.key,
       sortable : true,
    },
    {
      key : 'value',
      title : this.loc.tableHeaders.value,
      sortable : true,
    }
  ];
  paginatorInfo: PaginatorInfo = this.loc.attributes
  transformers: TableTransformer = {}
  filters: TableFilter[] = [
    {
       label: this.loc.tableHeaders.key,
       key: 'key',
       type: 'text',
       placeholder: this.loc.tableHeaders.key,
    }
  ];
  actions: Action[] = [
    {
      tooltip: this.loc.attributes.edit,
      icon: 'edit',
      action: (row) => this.edit(row)
    }
  ];


  constructor(
    public loc: LocalizationService,
    public attributesService: AttributesService,
    private logoutDialogService: LogoutDialogService,
    private dialogService: DialogService,
    public snackbarService: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.groupId = params['groupId'];
      this.serviceTemplateId = params['serviceTemplateId'];
      this.operationId = params['operationId'];
    });
    this.loadAttributesAllowed();
  }

  loadAttributesAllowed(){
    this.loading = true;
    this.attributesService.getAttributesAllowed(this.serviceTemplateId, this.operationId, this.groupId).subscribe(
      (res: BaseResponse) => {
        this.attributesAllowed = res.messageRS as unknown as AttributeAllowed[];
        this.attributesAllowed.forEach((attribute) => {
          if(attribute.key && !this.keys.some(x => x === attribute.key)){
            this.keys.push(attribute.key);
          }
        } 
        );
        this.data = this.attributesAllowed as any as TableData[]
        
        this.attributesChange.emit(this.attributesAllowed);
        this.loading = false;
      },
      (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      }
    );
  }

  


   edit(row: any): void {
    let data: EditModalData = {
      title: this.loc.attributes.edit,
      icon: 'paid',
      fields: [
        {label: this.loc.tableHeaders.value, value: row.value, errorMsg: this.loc.errors.emptyDescription, validators: [Validators.required]},
             ]
    }
    const dialogRef = this.dialogService.openDialog(EditModalComponent, 
      data
      ,
      "x-small");
  
      dialogRef.afterClosed().subscribe((result) => {
        this.loading = true;
        if(result != 'not updated' && result){
          row.value = result[0].value
          this.attributesChange.emit(this.attributesAllowed);
        }
        this.loading = false;
      });
  }

}

export class NotificationType {
    id?: string;
    application?: string;
    applicationDescription?: string;
    platform?: string;
    platformDescription?: string;
    entity?: string;
    entityDescription?: string;
    method?: string;
    methodDescription?: string;
    status?: boolean;
    description?: string;
}
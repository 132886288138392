import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { LogoutDialogService } from 'src/app/services/logout-confirmation/logout-dialog.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { CreateTypeComponent } from '../create-type/create-type.component';
import { Action, Column, CreateButton, EmptyState, TableData, TableFilter } from 'src/app/utils/table/table.model';
import { SafeHTMLGeneratorService } from 'src/app/utils/safeHTML/safe-htmlgenerator.service';
import { DeleteModalData } from 'src/app/models/delete-modal/delete-modal-data.model';
import { DeleteModalComponent } from 'src/app/utils/delete-modal/delete-modal.component';
import { Validators } from '@angular/forms';
import { EditModalData } from 'src/app/models/edit-modal/edit-modal-data.model';
import { EditModalComponent } from 'src/app/utils/edit-modal/edit-modal.component';
import { NotificationType } from 'src/app/models/notifications/notification-types.model';

@Component({
  selector: 'app-list-types',
  templateUrl: './list-types.component.html',
  styleUrls: ['./list-types.component.css'],
})
export class ListTypesComponent implements OnInit {
  loading = false;
  data : TableData[] = []
  notificationType: NotificationType = new NotificationType();

  constructor(
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private logoutDialogService: LogoutDialogService,
    private notificationsService: NotificationsService,
    private loc: LocalizationService,
    private htmlGen : SafeHTMLGeneratorService
  ) {}

  ngOnInit(): void {
    this.getTypes();
  }


  emptyState: EmptyState = {
    image: 'assets/img/tipos-notificaciones.png',
    message: this.loc.types.empty,
    buttons: [
      {
        text: this.loc.types.create,
        action: () => {
          this.create();
        }
      }
    ]
  }

  columns : Column[] = [
    {
      title: this.loc.tableHeaders.id,
      key: 'id',
    },
    {
      title: this.loc.tableHeaders.application,
      key: 'application',
    },
    {
      title: this.loc.tableHeaders.platform,
      key: 'platform',
    },
    {
      title: this.loc.tableHeaders.entity,
      key: 'entity',
    },
    {
      title: this.loc.tableHeaders.method,
      key: 'method',
    },
    {
      title: this.loc.tableHeaders.status,
      key: 'status',
      centered: true,
    },
    {
      title: this.loc.tableHeaders.description,
      key: 'description',
    }
  ]


  paginatorInfo = this.loc.types

  transformers = {
    "status" : (value : any) => {
      return this.htmlGen.statusIcon(value === "ACTIVE");
    }
  }

  filters : TableFilter[] = [
    {
      placeholder: this.loc.tableHeaders.id,
      key: 'id',
      type: 'text',
      label: this.loc.tableHeaders.id
    }
  ]



  actions: Action[] = [
    {
      icon: "toggle_on",
      tooltip: this.loc.types.toggleStatus,
      action: (element: any) => {
        this.editStatus(element);
      },
      condition: (element: any) => {
        return element.status === "ACTIVE"
      }
    },
    {
      icon: "toggle_off",
      tooltip: this.loc.types.toggleStatus,
      action: (element: any) => {
        this.editStatus(element);
      },
      condition: (element: any) => {
        return element.status === "INACTIVE"
      }
    },
    {
      icon: 'edit',
      tooltip: this.loc.types.edit,
      action: (element: any) => {
        this.edit(element);
      }
    },
    {
      icon: 'delete',
      tooltip: this.loc.types.delete,
      action: (element: any) => {
        this.delete(element);
      }
    }
  ]

  createButton:CreateButton = {
    tooltip: this.loc.types.create,
    action: () => {
      this.create();
    }
  }

  getTypes() {
    this.loading = true;
    this.notificationsService.getTypes().subscribe({
      next: (res) => {
        this.data = res.messageRS as TableData[];
        this.loading = false;
      },
      error: (err) => {
        this.logoutDialogService.openLogoutDialog(err);
      },
    });
  }

  create() {
    const dialogRef = this.dialogService.openDialog(
      CreateTypeComponent,
      {},
      'large'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'created') {
        this.snackbarService.openSuccessSnackbarWithNoReload(
          this.loc.successMessage('create', this.loc.types)
        );
        this.getTypes();
      } else if (result === 'error') {
        this.snackbarService.openErrorSnackbar(
          this.loc.errors.errorCreatingType
        );
      }
    });
  }

  editAction(row: any, result: any){
    let newDescription = result.find((x: any) => x.label === this.loc.fields.description).value
    this.notificationType.application = row.application;
    this.notificationType.platform = row.platform;
    this.notificationType.method = row.method;
    this.notificationType.id = row.id;
    this.notificationType.description = newDescription;
    this.notificationsService.editType(this.notificationType).subscribe(
      {
        next: (res) => {
          if(res.statusRS?.code === "0"){
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('update', this.loc.types)
            );
            this.getTypes();
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.errorDeletingType
            );
          }
        }, 
        error: (err) => {
          this.loading = false;
          this.logoutDialogService.openLogoutDialog(err);
          if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.errorDeletingType
            );
          }
        }
      }
    )
  }

  edit(element: any) {
    let data: EditModalData = {
      title: this.loc.types.edit,
      icon: 'notifications',
      fields: [
        { label: this.loc.fields.description, 
          value: element.description, 
          errorMsg: this.loc.errors.emptyDescription, 
          validators: [Validators.required],
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editAction(element, result)
      } 
    });
  }

  editStatus(element: any) {
    let data: EditModalData = {
      title: this.loc.types.editStatus,
      icon: 'notifications',
      fields: [
        { label: this.loc.fields.status, 
          value: element.status,
          isCombo: true,
          comboValues: [{key: "ACTIVE", value: this.loc.common.active},{key: "INACTIVE", value: this.loc.common.inactive}]
        }]
    }
    const dialogRef = this.dialogService.openDialog(
      EditModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'not updated') {
        this.loading = true;
        this.editStatusAction(element, result)
      } 
    });
  }

  editStatusAction(row: any, result: any){
    let newStatus = result.find((x: any) => x.label === this.loc.fields.status).value
    this.notificationType.status = newStatus === 'ACTIVE' ? true : false
    this.notificationType.id = row.id
    this.notificationType.application = row.application
    this.notificationType.platform = row.platform,
    this.notificationType.method = row.method

    this.notificationsService.editTypeStatus(this.notificationType).subscribe(
      {
        next: (res) => {
          if(res.statusRS?.code === "0"){
            this.snackbarService.openSuccessSnackbarWithNoReload(
              this.loc.successMessage('update', this.loc.types)
            );
            this.getTypes();
          } else {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.errorDeletingType
            );
          }
        }, 
        error: (err) => {
          this.loading = false;
          this.logoutDialogService.openLogoutDialog(err);
          if (err.statusCode !== 401) {
            this.snackbarService.openErrorSnackbar(
              this.loc.errors.errorDeletingType
            );
          }
        }
      }
    )
  }

  deleteAction(row: any){
    this.loading = true;
    this.notificationsService.deleteType(row.id, row.platform, row.application, row.method).subscribe(
        {
          next: (res) => {
            setTimeout(() => {
              this.loading = false;
              if(res.statusRS?.code === "0"){
                this.snackbarService.openSuccessSnackbarWithNoReload(
                  this.loc.successMessage('delete', this.loc.types)
                );
                this.getTypes();
              } else {
                this.snackbarService.openErrorSnackbar(
                  this.loc.errors.errorDeletingType
                );
              }
            }, 200);
          }, 
          error: (err) => {
            this.loading = false;
            this.logoutDialogService.openLogoutDialog(err);
            if (err.statusCode !== 401) {
              this.snackbarService.openErrorSnackbar(
                this.loc.errors.errorDeletingType
              );
            }
          }
        }
      )
  }

  delete(element: any) {
    let data: DeleteModalData = {
      title: this.loc.types.delete,
      icon: 'notifications',
      confirmDeleteMessage: this.loc.types.confirmDeleteType,
    };
    const dialogRef = this.dialogService.openDialog(
      DeleteModalComponent,
      data,
      'x-small'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
       this.loading = true;
       this.deleteAction(element)
      }
    });
  }
}

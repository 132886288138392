import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdjustmentReasons } from 'src/app/models/adjustmentReasons/adjustmentReasons.model';
import { BaseRequest } from 'src/app/models/base/base-request.model';
import { BaseResponse } from 'src/app/models/responses.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class AdjustmentReasonsService {
  readonly rootURL = environment.apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getAdjustmentReasons(): Observable<BaseResponse<AdjustmentReasons[]>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.get<BaseResponse<AdjustmentReasons[]>>(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/transactions/adjustment-reasons/x/list-reasons',
      HTTP_OPTIONS
    );
  }

  create(description: string): Observable<BaseResponse<AdjustmentReasons>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { description: description}
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.post<BaseResponse<AdjustmentReasons>>(
      this.rootURL + 'RSAdaptorFE/api/v1/paycorr/transactions/adjustment-reasons/x/create-reason',
      baseRequest,
      HTTP_OPTIONS
    );
  }

  edit(id: string, description: string): Observable<BaseResponse<AdjustmentReasons>> {
    const baseRequest: BaseRequest = new BaseRequest();
    baseRequest.messageRQ = { description: description };
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.put<BaseResponse<AdjustmentReasons>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/transactions/adjustment-reasons/${id}/update-reason`,
      baseRequest,
      HTTP_OPTIONS
    );
  }

  delete(id: string): Observable<BaseResponse<AdjustmentReasons>> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getToken(),
      }),
    };
    return this.http.delete<BaseResponse<AdjustmentReasons>>(
      this.rootURL +
        `RSAdaptorFE/api/v1/paycorr/transactions/adjustment-reasons/${id}/delete-reason`,
      HTTP_OPTIONS
    );
  }
}

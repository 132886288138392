import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseResponse } from 'src/app/base/base-response.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SessionsService } from 'src/app/services/sessions/sessions.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  host: {
    "class":"dialog-component"
  }
})
export class ChangePasswordComponent implements OnInit {
  oldPassword: string = '';
  newPassword: string = '';
  newPasswordRep: string = '';

  errorMessage: string = '';
  showError: boolean = false;
  loading: boolean = false;

  needChangePassword: boolean = false;

  constructor(
    private sessionService: SessionsService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    public loc : LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.needChangePassword = this.data.needChangePassword;
  }

  save() {
    var validPassword = this.validateNewPassword();
    if (validPassword) {
      this.loading = true;
      if(this.needChangePassword){
        this.sessionService.updatePasswordOAS(this.oldPassword, this.newPassword)
        .subscribe(
          (res: BaseResponse) => {
            this.loading = false;
            this.exit(this.newPassword);
          },
          (err) => {
            this.loading = false;
            this.errorMessage = this.loc.errors.errorChangingPassword;
            this.showError = true;
            console.log(err);
          }
        );
      }else{
        this.sessionService
        .updatePassword(this.oldPassword, this.newPassword)
        .subscribe(
          (res: BaseResponse) => {
            setTimeout(() => {
              this.loading = false;
              if(res.statusRS?.description == "Invalid password"){
                this.errorMessage = this.loc.errors.currentPasswordIsEnteredIncorrectly;
                this.loading = false;
                this.showError = true;
              }
              else if(res.statusRS?.description == "Password has already been used"){
                this.errorMessage = this.loc.errors.newPasswordHasAlreadyBeenUsed;
                this.loading = false;
                this.showError = true;
              }
              else if(res.statusRS!.description == "OK"){
                this.exit('updated');
              }else{
                this.exit("error");
              }
            }, 200);
          },
          (err) => {
            this.loading = false;
            this.errorMessage = this.loc.errors.wrongPassword;
            this.showError = true;
          }
        );
      }
    } else {
      this.loading = false;
      this.showError = true;
    }
  }


  //TODO - LOCALIZE ERROR MESSAGE
  validateNewPassword(): boolean {
    var isValid: boolean = true;
    if (this.newPassword === '') {
      this.errorMessage = this.loc.errors.emptyPassword;
      isValid = false;
    } else if (this.newPassword !== this.newPasswordRep) {
      this.errorMessage = this.loc.errors.passwordsDontMatch;
      isValid = false;
    }
    return isValid;
  }

  exit(message: string) {
    this.dialogRef.close(message);
  }

  onSearchChange() {
    this.showError = false;
  }
}

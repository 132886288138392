<div class="user" *ngIf="!loaded">
  <ngx-skeleton-loader count="1" appearance="circle"
    [theme]="{'background': 'no-repeat #eff1f624','top':'-5px'}"></ngx-skeleton-loader>
  <div class="userData">
    <div style="width: 130px;">
      <ngx-skeleton-loader count="2"
        [theme]="{'background': 'no-repeat #eff1f624', height:'15px', 'margin-bottom':'5px'}"></ngx-skeleton-loader>
    </div>
  </div>
</div>

<div class="user" *ngIf="loaded">
  <div class="button-user-preview" style="cursor: pointer;" (click)="account()">
    <img *ngIf="imageProfile" src={{imageProfile}} alt="img_user" class="user-image" (error)="imageProfile = ''">
    <button mat-fab class="img-button" *ngIf="!imageProfile">
      <mat-icon>person</mat-icon>
    </button>
    <div class="userData">
      <div style="height: 15px;"><label class="userInfo" style="cursor: pointer;">{{userFirstName}}</label>
        <label class="userInfo" style="cursor: pointer;"> {{userLastName}}</label>
      </div>
      <div><label class="userType" style="cursor: pointer;">{{userType}}</label></div>
    </div>

  </div>

  <div style="display: flex;">

    <div style="width: 2px; margin-right: 10px; margin-left: 10px;"></div>

    <div class="button-user-preview" style="cursor: pointer;" (click)="logout()">
      <mat-icon class="menu-item-icon">logout</mat-icon>
      <span style="font-family: 'MontserratMedium'; font-size: 14px;">{{loc.profile.logout}}</span>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Step, StyleStep } from 'src/app/models/stepper/step-modal';
import { LocalizationService } from 'src/app/services/localization/localization.service';

@Component({
  selector: 'app-style-step',
  templateUrl: './style-step.component.html',
  styleUrls: ['./style-step.component.css']
})
export class StyleStepComponent implements OnInit {

  @Input() inStep!: (Step & { idx?: number });
  step!: (StyleStep & { idx?: number });
  @Output() outputEvent = new EventEmitter<{ [x: string] : string}>();
  @Output() fileSelect = new EventEmitter<any>();
  
  loading: boolean = true;
  selectedFile: File = new File([], '');
  imageProfile?: string;

  constructor(
    public loc: LocalizationService,
  ) { }

  ngOnInit(): void {
    this.step = this.inStep as (StyleStep & { idx?: number });
    this.loading = false;
  }

  onFileSelect(event: any): void {
    this.selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imageProfile = reader.result?.toString();
    };
    this.fileSelect.emit(event);
  }

  next() {
    if (this.imageProfile) {
      this.outputEvent.emit({[this.step.key]: this.imageProfile});
    } else {
      // Perform actions when the form is invalid, such as displaying error messages
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { BaseResponse } from 'src/app/models/base/base-response.model';
import { Login } from 'src/app/models/login.model';
import { LocalizationService } from 'src/app/services/localization/localization.service';
import { SessionsService } from 'src/app/services/sessions/sessions.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  host: {
    class: "component"
  }
})
export class ForgotPasswordComponent implements OnInit {
  loading = false;
  isLinear = true;
  isEditable = true;
  showError = false;
  errorMessage = this.loc.errors.usernameCodeOrPasswordIncorrect; 
  otp: string = '';
  newPassword: string = '';
  newPasswordRep: string = '';


  @ViewChild('stepper', { static: false }) stepper: MatStepper | undefined;
  firstFormGroup!: UntypedFormGroup;
  secondFormGroup!: UntypedFormGroup;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '-',
    inputStyles: {
      width: '50px',
      height: '50px',
      'font-family': 'MontserratBold',
      color: 'var(--light-blue)',
    },
  };

  constructor(
    public loc: LocalizationService,
    private _formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private sessionsService: SessionsService,


  ) { }

  ngOnInit(): void {
    this.firstFormGroup = new UntypedFormGroup({
      emailForm: new UntypedFormControl(),
    });
    this.firstFormGroup = this._formBuilder.group({
      emailForm: ['', Validators.required],
    });
    this.secondFormGroup = new UntypedFormGroup({});
    this.secondFormGroup = this._formBuilder.group({});
  }

  exit(message:string) {
    this.dialogRef.close(message);
  }

  generateOTP(stepper: MatStepper) {
    var email = this.firstFormGroup.value.emailForm;

    if (email !== '') {
      this.sessionsService.generateRecoveryOTP(email).subscribe(
        (res: BaseResponse) => {
          setTimeout(() => {
            if (res.statusRS?.code === '0') {
              this.showError = false;
              stepper.next();
            }
            else if (res.statusRS?.code === '-91037') {
              this.errorMessage = this.loc.errors.userNotExist;
              this.showError = true;
            }
          }, 200);
        },
        (err) => {
        }
      );
    }
  }

  onOtpChange($event: any) {
    this.otp = $event;
  }
  
  validateOTP() {
    if (this.otp.length === 6) {
      this.stepper?.next();
    }
  }

  hideError() {
    this.showError = false;
  }

  changePassword() {
    this.loading = true;
    var isValid = this.validateNewPassword();
    if (isValid) {
      var username = this.firstFormGroup.value.emailForm;
      this.sessionsService
        .recoverPasswordOTP(username, this.newPassword, this.otp)
        .subscribe(
          (res) => {
            var loginData: Login = {
              username: username,
              password: this.newPassword,
            };
            this.login(loginData);
          },
          (err) => {
            this.loading = false;
            this.showError = true;
          }
        );
    } else {
      this.loading = false;
      this.showError = true;
    }
  }

  login(loginData: Login) {
    this.dialogRef.close(loginData);
  }


  validateNewPassword(): boolean {
    var isValid: boolean = true;
    if (this.newPassword === '') {
      isValid = false;
    } else if (this.newPassword !== this.newPasswordRep) {
      isValid = false;
    }
    return isValid;
  }

}

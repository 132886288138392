import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, observable, of } from 'rxjs';
import { Cloudinary } from '@cloudinary/angular-5.x';

const sha1 = require('sha1');

@Injectable({
  providedIn: 'root',
})
export class ImageUploaderService {
  constructor(
    private http: HttpClient,
    private cloudinary: Cloudinary,
    ) {}

  uploadImage(vals: any): Observable<any> {
    let data = vals;

    return this.http.post(
      'https://api.cloudinary.com/v1_1/paycorrcloud/image/upload',
      data
    );
  }

  uploadImageToCloudinary(selectedFile: File, id: string){
    if(selectedFile.name !== ''){
      const data = new FormData();
      const timestamp = Math.floor(Date.now() / 1000);
      const signature = sha1(
        'public_id=' +
        id +
        '&timestamp=' +
        timestamp +
        '&upload_preset=' +
        this.cloudinary.config().upload_preset +
        this.cloudinary.config().api_secret
      );

      data.append('file', selectedFile);
      data.append('upload_preset', this.cloudinary.config().upload_preset);
      data.append('cloud_name', this.cloudinary.config().cloud_name);
      data.append('api_key', this.cloudinary.config().api_key);
      data.append('api_secret', this.cloudinary.config().api_secret);
      data.append('secure', this.cloudinary.config().secure);
      if(id){
        data.append('public_id', id);
      }
      data.append('signature', signature);
      data.append('timestamp', timestamp.toString());

      return this.http.post(
        'https://api.cloudinary.com/v1_1/paycorrcloud/image/upload',
        data
      )
    } else {
      return null;
    }
  }
}

import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  BasicLocatableObject,
  BasicLocatableObjectMultilanguage,
} from './basic-locatable-object.model';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  constructor() {}

  public currentLocale: 'es' | 'en' = 'es';

  public setLocale(locale: 'es' | 'en') {
    this.currentLocale = locale;
    localStorage.setItem('locale', locale);
  }

  public get routes() {
    return this._routes[this.currentLocale];
  }

  public get branchOperations() {
    return this._branchOperations[this.currentLocale];
  }

  public get apis(){
    return this._apis[this.currentLocale];
  }


  public get serviceTemplates(){
    return this._serviceTemplates[this.currentLocale];
  }

  private _serviceTemplates = {
    es:{
      title:'Templates de servicio',
      create:'Crear template de servicio',
      delete:'Eliminar template de servicio',
      edit:'Editar template de servicio',
      singular:'Template de servicio',
      plural:'Templates de servicio',
      singularPronoun:'El',
      empty:'No existen templates de servicio',

    },
    en:{
      title:'Service templates',
      create:'Create service template',
      delete:'Delete service template',
      edit:'Edit service template',
      singular:'Service template',
      plural:'Service templates',
      singularPronoun:'The',
      empty: 'There are no service templates',
    }
  }


  private _apis = {
    es: {
      showBody: 'Body',
      update: 'Actualizar API',
      newApi: 'Nueva API',
      addBody: 'Agregar Body',
      create: 'Crear API',
      delete: 'Eliminar API',
      edit: 'Editar API',
      empty: 'No existen APIs',
      singularPronoun: 'La',
      singular: 'API',
      plural: 'APIs',
      title: 'APIs',
    },
    en: {
      showBody: 'Body',
      update: 'Update API',
      newApi: 'New API',
      addBody: 'Add Body',
      create: 'Create API',
      delete: 'Delete API',
      edit: 'Edit API',
      empty: 'There are no APIs',
      singularPronoun: 'the',
      singular: 'API',
      plural: 'APIs',
      title: 'APIs',
    }
  }

  private _branchOperations: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Operaciones de sucursal',
      create: 'Crear operación de sucursal',
      delete: 'Eliminar operación de sucursal',
      edit: 'Editar operación de sucursal',
      singular: 'Operación de sucursal',
      plural: 'Operaciones de sucursal',
      singularPronoun: 'La',
      empty: 'No existen operaciones de sucursal',
      deactivate: 'Desactivar',
      activate: 'Activar',
    },
    en: {
      title: 'Branch operations',
      create: 'Create branch operation',
      delete: 'Delete branch operation',
      edit: 'Edit branch operation',
      singular: 'Branch operation',
      plural: 'Branch operations',
      singularPronoun: 'the',
      empty: 'There are no branch operations',
      deactivate: 'Deactivate',
      activate: 'Activate',
    },
  };

  public get branchOperationRoles() {
    return this._branchOperationRoles[this.currentLocale];
  }

  private _branchOperationRoles = {
    es: {
      title: 'Permisos de operaciones',
      create: 'Crear permiso de operación',
      delete: 'Eliminar permiso de operación',
      edit: 'Editar permiso de operación',
      singular: 'Permiso de operación',
      plural: 'Permisos de operación',
      singularPronoun: 'El',
      empty: 'No existen permisos de operación',
      new: 'Crear permiso de operación',
    },
    en: {
      title: 'Operation roles',
      create: 'Create operation role',
      delete: 'Delete operation role',
      edit: 'Edit operation role',
      singular: 'Operation role',
      plural: 'Operation roles',
      singularPronoun: 'The',
      empty: 'There are no operation roles',
      new: 'Create operation role',
    },
  };

  public get currency() {
    return this._currency[this.currentLocale];
  }

  private _currency: BasicLocatableObjectMultilanguage = {
    es: {
      noMore: 'No hay más monedas para agregar',
      edit: 'Editar moneda',
      separator: 'Separador decimal',
      decimalNumber: 'Número decimal',
      delete: 'Eliminar moneda',
      add: 'Agregar moneda',
      singular: 'Moneda',
      plural: 'Monedas',
      empty: 'No existen monedas asociadas',
      newCurrency: 'Nueva moneda',
      deleteCurrency: 'Eliminar moneda',
      singularPronoun: 'La',
      create: 'Crear moneda',
      editErrorSymbol: 'El símbolo no puede ser vacío',
      editErrorDecimalSeparator: 'El separador no puede ser vacío',
      editErrorDecimalNumber: 'El número de decimales no puede ser vacío',
    },
    en: {
      noMore: 'No more currencies to add',
      edit: 'Edit currency',
      separator: 'Decimal separator',
      decimalNumber: 'Decimal number',
      delete: 'Delete currency',
      add: 'Add currency',
      singular: 'Currency',
      plural: 'Currencies',
      empty: 'There are no associated currencies',
      newCurrency: 'New currency',
      deleteCurrency: 'Delete currency',
      singularPronoun: 'the',
      create: 'Create currency',
      editErrorSymbol: 'Symbol cannot be empty',
      editErrorDecimalSeparator: 'Decimal separator cannot be empty',
      editErrorDecimalNumber: 'Decimal number cannot be empty'
    },
  };
  public get banks() {
    return this._banks[this.currentLocale];
  }

  private _banks: BasicLocatableObjectMultilanguage = {
    es: {
      edit: 'Editar banco',
      newBank: 'Nuevo banco',
      create: 'Crear banco',
      banks: 'Bancos',
      empty: 'No existen bancos.',
      singular: 'Banco',
      country: 'Pais',
      delete: 'Eliminar banco',
      singularPronoun: 'El',
      plural: 'Bancos',
    },
    en: {
      edit: 'Edit bank',
      newBank: 'New bank',
      create: 'Create bank',
      banks: 'Banks',
      empty: 'There are no banks.',
      singular: 'Bank',
      country: 'Country',
      delete: 'Delete bank',
      singularPronoun: 'the',
      plural: 'Banks',
    },
  };

  private _routes = {
    es: {
      api: 'API',
      denominations: 'Denominaciones',
      agents: 'Agentes',
      branches: 'Sucursales',
      countries: 'Países',
      banks: 'Bancos',
      notifications: 'Notificaciones',
      transactions: 'Transacciones',
      calendars: 'Calendarios',
      extensionPoints: 'Puntos de extensión',
      postingSchemes: 'Esquemas de posteo',
      transactionTemplates: 'Templates',
      users: 'Usuarios',
      paymentButton: 'Botón de pago',
      services: 'Servicios',
      currencies: 'Monedas',
      integrations: 'Integraciones',
      campaigns: 'Campañas',
      metadata: 'Metadata',
      atribs: 'Atributos',
      disaggregation: 'Desagregación',
      posting: 'Posting',
      adjustmentReasons: 'Razones de ajuste',
    },
    en: {
      api: "API",
      denominations: 'Denominations',
      agents: 'Agents',
      branches: 'Branches',
      countries: 'Countries',
      banks: 'Banks',
      notifications: 'Notifications',
      transactions: 'Transactions',
      calendars: 'Calendars',
      extensionPoints: 'Extension points',
      postingSchemes: 'Posting schemes',
      transactionTemplates: 'Templates',
      users: 'Users',
      paymentButton: 'Payment button',
      services: 'Services',
      currencies: 'Currencies',
      integrations: 'Integrations',
      campaigns: 'Campaigns',
      metadata: 'Metadata',
      atribs: 'Attributes',
      disaggregation: 'Disaggregation',
      posting: 'Posting',
      adjustmentReasons: 'Adjustment reasons',
    },
  };

  public get common() {
    return this._common[this.currentLocale];
  }

  private _common = {
    es: {
      incompatibleWith: 'es incompatible con',
      new: 'Nuevo',
      commertialConditionsAndTemplate: 'Condiciones comerciales y template',
      close: 'Cerrar',
      yes: 'Sí',
      no: 'No',
      create: 'Crear',
      details: 'Detalles',
      active: 'Activo',
      inactive: 'Inactivo',
      addPhoto: 'Agregar foto',
      changePhoto: 'Cambiar foto',
      visible: 'Visible',
      notVisible: 'No visible',
      moveDown: 'Mover hacia abajo',
      moveUp: 'Mover hacia arriba',
      continue: 'Continuar',
      cancel: 'Cancelar',
      percentage: 'Porcentaje',
      amount: 'Monto',
      copyToClipboard: 'Copiar al portapapeles',
      static: 'Estático',
      dynamic: 'Dinámico',
      editable: 'Editable',
      notEditable: 'No editable',
      associate: 'Asociar',
      english: 'Inglés',
      spanish: 'Español',
      start: 'Inicio',
      end: 'Fin',
      today: 'Hoy',
      yesterday: 'Ayer',
      last7Days: 'Últimos 7 días',
      last30Days: 'Últimos 30 días',
      older: 'Anteriores',
      both: 'Ambos',
      view: 'Ver ',
      addIcon: 'Agregar icono',

    },
    en: {
      incompatibleWith: 'is incompatible with',
      new: 'New',
      commertialConditionsAndTemplate: 'Commercial conditions and template',
      close: 'Close',
      yes: 'Yes',
      no: 'No',
      create: 'Create',
      details: 'Details',
      active: 'Active',
      inactive: 'Inactive',
      addPhoto: 'Add photo',
      changePhoto: 'Change photo',
      visible: 'Visible',
      notVisible: 'Not visible',
      moveDown: 'moveDown',
      moveUp: 'moveUp',
      continue: 'Continue',
      cancel: 'Cancel',
      percentage: 'Percentage',
      amount: 'Amount',
      copyToClipboard: 'Copy to clipboard',
      static: 'Static',
      dynamic: 'Dynamic',
      editable: 'Editable',
      notEditable: 'Not editable',
      associate: 'Associate',
      english: 'English',
      spanish: 'Spanish',
      start: 'Start',
      end: 'End',
      today: 'Today',
      yesterday: 'Yesterday',
      last7Days: 'Last 7 days',
      last30Days: 'Last 30 days',
      older: 'Older',
      both: 'Both',
      view: 'View ',
      addIcon: 'Add icon',

    },
  };

  public get errors() {
    return this._errors[this.currentLocale];
  }
  private _errors = {
    es: {
      integrationTypeBeingUsed: 'El tipo de integración está siendo usado',
      idMustBeUpperCase: 'El id debe ser en mayúsculas',
      bankUsedByAgent:
        'No se puede eliminar el banco ya que está siendo usado por un agente',
      bankUsedInSegment:
        'No se puede eliminar el banco ya que está siendo usado en un segmento',
      deleteBank: 'Hubo un error al intentar eliminar el banco',
      wrongPassword: 'Contraseña incorrecta',
      emptyPassword: 'La contraseña no puede estar vacía',
      passwordsDontMatch: 'Las contraseñas no coinciden',
      serviceCategorySubcategoryAssociation:
        'El servicio debe tener categoría y subcategoría asociada',
      cantDeleteAgreementAssocatiedToActiveBundle:
        'No se pueden eliminar agreements de un bundle activo',
      versionIsBeingUsedAndCantBeModified:
        'Esta versión está siendo utilizada y no puede ser modificada',
      cantAddAgreementToActiveBundle:
        'No se pueden agregar agreements en un bundle activo',
      errorDeleteChannelUsed:
        'No se pueden eliminar canales de salida que están siendo usados por segmentos',
      invalidCreateChannel: 'Ya existe un canal con la misma identificación',
      segmentCannotDelete:
        'Este segmento está siendo utilizado y no puede ser eliminado',
      versionIsBeingUsedAndCantBeDeleted:
        'Esta versión está siendo utilizada y no puede ser eliminada',
      cantDeleteActiveBundleVersion:
        'Esta versión está Activa y no puede ser eliminada.',
      bundleIsBeingUsedAndCantDeactivate:
        'Este bundle está siendo utilizado y no puede ser desactivado.',
      bundleHasInactiveOperation: 'El bundle tiene una operación inactiva',
      errorCreatingNewBundleVersion: 'Error al crear nueva versión del bundle',
      versionAlreadyExists: 'La versión elegida ya existe para el bundle',
      mustSelectAtLeastOneBundle: 'Debe seleccionar al menos un bundle',
      idMustBeOfType: 'El Id debe ser de tipo NOMBRE-XXX',
      versionMustBeOfType:
        'La versión debe ser del tipo X.X.X y no puede comenzar con 0. e.j. 1.0.0',
      bundleWithSameIdAlreadyExists: 'Ya existe un bundle con el mismo Id',
      otherVersionAlreadyAssociatedToAgent:
        'Uno de los bundles seleccionados ya está asociado al agente con otra versión',
      bundleIsInactive: 'Uno de los bundles seleccionados está inactivo',
      campaignIdFormat:
        "El Id debe seguir el formato: 'CAMPAIGN-XXX' ej. CAMPAIGN-000",
      endAfterStart:
        'La fecha de finalización debe ser luego que la fecha de inicio',
      campaignBeingUsed:
        'Esta campaña está siendo utilizada y no puede ser eliminada',
      campaignAlreadyExists:
        'Ya existe una campaña con la misma identificación',
      categoryNameFormat:
        'Las categorías deben ser una única palabra que comienza con mayúscula y solo puede contener letras',
      serviceIsBeingUsedByBundleCantBeDeleted:
        'Este servicio está siendo utilizado por un bundle y no puede ser eliminado',
      toActivateOperationMustAssignCategoryAndSubcategory:
        'Para poder activar una operación debe asignarle una categoría y subcategoría',
      toActivateOperationMustAssignStructure:
        'Para poder activar una operación debe asignarle una estructura',
      toActivateOperationMustAssignMetadata:
        'Para poder activar una operación debe asignarle un metadata',
      toDeactivateOperationItMustNotBeInAnyBundle:
        'Para poder desactivar una operación no debe pertenecer en ningún bundle',
      toActivateOperationMustAssignPostingScheme:
        'Para poder activar una operación debe asignarle un esquema de posteo',
      toModifyPostingSchemeItMustNotBeInAnyBundle:
        'No se puede modificar el esquema de posteo de un servicio que está en un bundle activo y en uso',
      errorSortingExtensionPoint: 'Error reordenando punto de extensión',
      atLeastOneFee: 'Debe seleccionar al menos 1 comisión',
      percentageMustBeBetween0And100: 'El porcentaje debe estar entre 0 y 100',
      amountMustBeBetween0And9999999: 'El monto debe estar entre 0 y 9.999.999',
      amountFromMustBeLowerThanAmountTo:
        'El monto desde debe ser menor que el monto hasta',
      tiersCantShareRange: 'Los rangos de los niveles no pueden superponerse',
      atLeastOneTier: 'Debe seleccionar al menos 1 nivel de comisión',
      cantDeleteFeeSinceItsBeingUsed:
        'No se puede eliminar la comisión porque está siendo utilizada',
      cantDeleteServiceFeeSinceItsPartOfABundleThatIsInUse:
        'No se puede eliminar la comisión porque está siendo utilizada por un bundle activo',
      cantDeleteFeeSinceBundleIsAlreadyInUse:
        'No se puede eliminar la comisión porque está siendo utilizada por un bundle activo',
      valueMustBeLowerThanToValue:
        'El valor desde debe ser menor que el valor hasta',
      errorLogin: 'Error iniciando sesión',
      userIsNotAdmin: 'El usuario ingresado no es un admin',
      maxRetriesExceeded: 'Se superó el máximo de intentos',
      incorrectUsersAndPasswords: 'Usuario o contraseña incorrectos',
      usernameCodeOrPasswordIncorrect:
        'Usuario, código o contraseña incorrectos',
      mediumAlreadyExists: 'Ya existe un medio con la misma identificación',
      errorAssociatingMediumToCampaign: 'Error asociando medio a la campaña',
      mediumBegineUsed: 'Este medio está siendo utilizado',
      paymentMethodIdFormat:
        "El identificador solo puede contener mayúsculas y '-'",
      userAlreadyExists: 'El nombre de usuario ya existe',
      nameHasInvalidCharacters: 'El nombre contiene caracteres inválidos',
      usernameCannotHaveSpaces:
        'El nombre de usuario no puede contener espacios',
      serviceIsBeingUsedAndCantBeDeleted:
        'Este servicio está siendo utilizado y no puede ser eliminado',
      integrationIdMustBeASingleWordInUppercase:
        'El Id debe ser una única palabra en mayúscula',
      agentHasReachedTheLimitOfIntegrationsForThisType:
        'El agente ha alcanzado el límite de integraciones para este tipo',
      couldntAddAllIntegrations:
        'No se pudieron agregar todas las integraciones',
      integrationWithSameIdAlreadyExists:
        'Ya existe una integración con el mismo Id',
      integrationTypeWithSameIdAlreadyExists:
        'Ya existe un tipo de integración con el mismo Id',
      limitOnlyPossibleUpdateName:
        'Límite en uso, sólo es posible actualizar el nombre',
      mustSelectAtLeastOneLimit: 'Debe seleccionar al menos un límite',
      errorLoadingOperations: 'Error cargando operaciones',
      mustSelectAtLeastOneOperation: 'Debe seleccionar al menos una operación',
      minValueMustBeLowerThanMaxValue:
        'El valor mínimo debe ser menor que el valor máximo',
      limitMustHaveAtLeastOneOperations:
        'El límite debe tener una operación como mínimo',
      operationAlreadyInLimit: 'La operación ya está en el límite',
      limitBeingUsed: 'Este límite está en uso',
      denominationIsBeingUsed: 'Esta denominación está en uso',
      subcategoryNamePattern:
        'Las subcategorías deben comenzar con mayúscula y contener únicamente letras',
      channelWithIdAlreadyExists:
        'Ya existe un canal con la misma identificación',
      updatePasswordSuccess: 'Contraseña actualizada correctamente',
      ServiceMustHaveMetadataSet:
        'Error el servicio debe tener un conjunto de metadatos',
      serviceBeingUsedByAgreement:
        'Error el servicio está siendo usado por un acuerdo',
      cantUpdateFeeSinceItsInUse:
        'No se puede actualizar la comisión porque está siendo utilizada',
      updatePasswordError: 'Error actualizando contraseña',
      newPasswordHasAlreadyBeenUsed:
        'La nueva contraseña ya ha sido utilizada, ingrese una nueva',
      currentPasswordIsEnteredIncorrectly:
        'La contraseña actual esta mal ingresada',
      noActionsForOperation:
        'No hay acciones para crear de la operación seleccionada',
      errorDeletingAction: 'Error eliminando acción',
      errorActionUsed: 'La acción está siendo usada por un servicio',
      errorUpdatingEP: 'Error actualizando template de punto de extensión',
      parameterIsUsedByTemplate:
        'El parámetro está siendo usado por una acción',
      parameterIsUsedByInstance:
        'El parámetro está siendo usado por un servicio',
      parameterAlreadyExists: 'El nombre de parámetro elegido ya existe',
      errorCreatingApplication: 'Error creando aplicación',
      applicationIdFormat:
        "El identificador solo puede contener mayúsculas y '_'",
      errorCreatingPlatform: 'Error creando plataforma',
      errorCreatingType: 'Error creando tipo',
      errorCreatingEntity: 'Error creando entidad',
      errorEditingEntity: 'Error editando entidad',
      errorDeletingType: 'Error eliminando tipo',
      postingSchemeUsed:
        'El esquema de posteo está siendo usado por un servicio',
      postingAlreadyExists: 'El posting ya existe',
      disaggregationUsed: 'La desagregación está siendo usada por un servicio',
      disaggregationUsedByPostingScheme:
        'La desagregación está siendo usada por un esquema de posteo',
      disaggregationAlreadyExists: 'La desagregación ya existe',
      disaggregationLineAlreadyExists:
        'El template seleccionado tiene una operación, suboperación y sujeto que ya existen en la desagregación',
      disaggregationBaseOrdinalIncorrect:
        'El ordinal base elegido no corresponde con ningún ordinal existente',
      postingUsed: 'El posting está siendo usado por un servicio',
      postingUsedByPostingScheme:
        'El posting está siendo usado por un esquema de posteo',      
      templateIsUsed: 'El template está siendo usado',
      accountIdAlreadyExists: 'El id de cuenta ya existe',
      paymentButtonFieldsAlreadyExists: 'La id del campo del botón ya existe',
      paymentButtonTypeAlreadyExists: 'El tipo de botón ya existe',
      paymentButtonTypeFieldAlreadyExists:
        'El campo del tipo de botón ya existe',
      paymentButtonFieldsExistingRelationships:
        'El campo del botón tiene relaciones existentes',
      paymentButtonTypeHasRelationships:
        'El tipo de botón tiene relaciones existentes',
      operationGroupDoesNotExist: 'El grupo de operaciones no existe',
      operationGroupsHasOperations: 'El grupo de operaciones tiene operaciones',
      adjustmentReasonAlreadyExists: 'El motivo de ajuste ya existe',
      calendarAlreadyExists: 'El calendario ya existe',
      calendarInUse: 'El calendario está en uso',
      codeLengthInvalid: 'El código debe tener 3 caracteres como máximo',
      attributeGroupAlreadyExist: 'El grupo de atributos ya existe',
      userTypeExist: 'El tipo de usuario que quiere crear ya existe',
      userTypeDoesntExist: 'El tipo de usuario no existe',
      currencyInUse: 'La moneda está en uso',
      operationRoleAlreadyExist: 'El permiso de operación que quiere crear ya existe',
      integrationMandatoryParameters: 'Es necesario completar todos los parametros mandatorios',
      needPostingScheme: 'Es necesario completar los esquemas de posteo',
      emptyDescription: 'La descripción no puede ser vacía',
      loadingServiceTemplate: 'Error cargando templates de servicio',
      serviceModeAlreadyExist: 'Modo de servicio ya existe',
      serviceModeAttributeGroupAlreadyExist:
        'El grupo de atributos ya está asociado al modo de servicio',
      noAttributeGroupsToAssociate:
        'No hay grupos de atrributos que se le puedan asociar al modo de servicio',
      invalidIntegrationTypeId:
        'No existe un tipo de integración con ese id',
      integrationTypeIdAlreadyExists:
        'Ya existe un tipo de integración con ese id',
      parameterIsBeingUsed:
        'No se puede eliminar este parámetro ya que está en uso',
      typeParameterAlreadyExists:
        'Ya existe un parametro con ese Id de categoría y ese Id de parametro',
      templateAlreadyExists:
        'Ya existe un template con ese id y esa descripción',
      userNotExist:
      'El usuario no existe en el sistema',
      settlementModeAlreadyExists:
        'Ya existe ese modo de liquidación',
      numberMustBePositive: 'Ingrese un numero positivo',
      emptyMetadataInputs: 'No hay metadata entrante',
      emptyMetadataOutputs: 'No hay metadata saliente',
      emptyMappings: 'No hay mapeos',
      newFieldIdIsBeingUsed: 'El nuevo id del campo ya está en uso',
      noApisForExternalEntity: 'No hay APIs para la entidad externa seleccionada',
      nonWorkingDayAlreadyExist: 'El día no laborable ya existe',
      errorChangingPassword: 'Error cambiando contraseña',
      noData: 'No existen datos',
      invalidFormat: 'El formato de los datos ingresados es incorrecto',
      stepNeedsMappings: 'No se puede activar el paso, se requiere el mapeo entre la metadata saliente y la respuesta de la api',
    },

    en: {
      integrationTypeBeingUsed: 'Integration type is being used',
      idMustBeUpperCase: 'Id must be uppercase',
      bankUsedByAgent: 'Cant delete the bank. It is used by an agent',
      bankUsedInSegment: 'Cant  delete the bank. It is used in a segment',
      deleteBank: 'There was an error trying to delete the bank',
      wrongPassword: 'Wrong password',
      emptyPassword: 'Password cannot be empty',
      passwordsDontMatch: 'Passwords does not match',
      serviceCategorySubcategoryAssociation:
        'Service must have category and subcategory associated',
      cantDeleteAgreementAssocatiedToActiveBundle:
        'Cannot delete agreements of an active bundle',
      versionIsBeingUsedAndCantBeModified:
        'This version is being used and cannot be modified',
      cantAddAgreementToActiveBundle:
        'Cannot add agreements to an active bundle',
      errorDeleteChannelUsed:
        'Out channels that are being used by segments cannot be deleted',
      invalidCreateChannel: 'Already exists a channel with the same id',
      segmentCannotDelete: 'This segment is in use and cannot be deleted',
      versionIsBeingUsedAndCantBeDeleted:
        'This version is being used and cannot be deleted',
      cantDeleteActiveBundleVersion:
        'This version is active and cannot be deleted',
      bundleIsBeingUsedAndCantDeactivate:
        'This bundle is being used and cannot be deactivated',
      bundleHasInactiveOperation: 'The bundle has an inactive operation',
      errorCreatingNewBundleVersion: 'Error creating new bundle version',
      versionAlreadyExists: 'The chosen version already exists for the bundle',
      mustSelectAtLeastOneBundle: 'You must select at least one bundle',
      idMustBeOfType: 'Id must be of type NAME-XXX',
      versionMustBeOfType:
        'Version must be of type X.X.X and cannot start with 0. e.g. 1.0.0',
      bundleWithSameIdAlreadyExists:
        'There is already a bundle with the same Id',
      otherVersionAlreadyAssociatedToAgent:
        'One of the selected bundles is already associated to the agent with another version',
      bundleIsInactive: 'One of the selected bundles is inactive',
      campaignIdFormat:
        "Id must follow the format: 'CAMPAIGN-XXX' e.g. CAMPAIGN-000",
      endAfterStart: 'End date must be after start date',
      campaignBeingUsed: 'This campaign is being used and cannot be deleted',
      campaignAlreadyExists:
        'There is already a campaign with the same identification',
      categoryNameFormat:
        'Categories must be a single word that starts with a capital letter and can only contain letters',
      serviceIsBeingUsedByBundleCantBeDeleted:
        'This service is being used by a bundle and cannot be deleted',
      toActivateOperationMustAssignCategoryAndSubcategory:
        'To activate an operation you must assign it a category and subcategory',
      toActivateOperationMustAssignStructure:
        'To activate an operation you must assign it a structure',
      toActivateOperationMustAssignMetadata:
        'To activate an operation you must assign it a metadata',
      toDeactivateOperationItMustNotBeInAnyBundle:
        'To deactivate an operation it must not be in any bundle',
      toActivateOperationMustAssignPostingScheme:
        'To activate an operation you must assign it a posting scheme',
      toModifyPostingSchemeItMustNotBeInAnyBundle:
        'You cannot modify the posting scheme of a service that is in an active bundle and in use',
      errorSortingExtensionPoint: 'Error sorting extension point',
      atLeastOneFee: 'You must select at least 1 fee',
      percentageMustBeBetween0And100: 'Percentage must be between 0 and 100',
      amountMustBeBetween0And9999999: 'Value must be between 0 and 9.999.999',
      amountFromMustBeLowerThanAmountTo:
        'Value from must be lower than value to',
      tiersCantShareRange: 'Tiers cannot share range',
      atLeastOneTier: 'Tehre must be at least 1 tier',
      cantDeleteFeeSinceItsBeingUsed:
        'Cannot delete fee since it is being used',
      cantDeleteServiceFeeSinceItsPartOfABundleThatIsInUse:
        'Cannot delete service fee since it is part of a bundle that is in use',
      cantDeleteFeeSinceBundleIsAlreadyInUse:
        'Cannot delete fee since bundle is already in use',
      valueMustBeLowerThanToValue: 'Value from must be lower than value to',
      errorLogin: 'Error logging in',
      userIsNotAdmin: 'The user is not an admin',
      maxRetriesExceeded: 'Max retries exceeded',
      incorrectUsersAndPasswords: 'Incorrect user or password',
      usernameCodeOrPasswordIncorrect: 'Incorrect username, code or password',
      mediumAlreadyExists:
        'There is already a payment method with the same identification',
      errorAssociatingMediumToCampaign:
        'Error associating payment method to the campaign',
      mediumBegineUsed: 'This payment method is being used',
      paymentMethodIdFormat:
        "The identifier can only contain uppercase and '-'",
      userAlreadyExists: 'Username already exists',
      nameHasInvalidCharacters: 'Name has invalid characters',
      usernameCannotHaveSpaces: 'Username cannot have spaces',
      serviceIsBeingUsedAndCantBeDeleted:
        'This service is being used and cannot be deleted',
      integrationIdMustBeASingleWordInUppercase:
        'Id must be a single word in uppercase',
      agentHasReachedTheLimitOfIntegrationsForThisType:
        'Agent has reached the limit of integrations for this type',
      couldntAddAllIntegrations: 'Could not add all integrations',
      integrationWithSameIdAlreadyExists:
        'Integration with same id already exists',
      integrationTypeWithSameIdAlreadyExists:
        'Integration type with same id already exists',
      limitOnlyPossibleUpdateName:
        'Limit in use, it is only possible to update the name',
      mustSelectAtLeastOneLimit: 'You must select at least one limit',
      errorLoadingOperations: 'Error loading operations',
      mustSelectAtLeastOneOperation: 'You must select at least one operation',
      minValueMustBeLowerThanMaxValue: 'Min value must be lower than max value',
      limitMustHaveAtLeastOneOperations:
        'Limit must have at least one operation',
      operationAlreadyInLimit: 'Operation already in limit',
      limitBeingUsed: 'Limit is being used',
      denominationIsBeingUsed: 'Denomination is being used',
      subcategoryNamePattern:
        'Subcategories must start with a capital letter and contain only letters',
      channelWithIdAlreadyExists:
        'There is already a channel with the same identification',
      updatePasswordSuccess: 'Password updated successfully',
      ServiceMustHaveMetadataSet: 'Error service must have metadata set',
      serviceBeingUsedByAgreement:
        'Error the service is being used by an agreement',
      cantUpdateFeeSinceItsInUse: 'Cannot update fee since it is being used',
      updatePasswordError: 'Error updating password',
      newPasswordHasAlreadyBeenUsed:
        'The new password has already been used, please enter a new one',
      currentPasswordIsEnteredIncorrectly:
        'The current password is entered incorrectly',
      noActionsForOperation:
        'There are no actions to create for the selected operation',
      errorDeletingAction: 'Error deleting action',
      errorActionUsed: 'The action is being used by a service',
      errorUpdatingEP: 'Error updating extension point template',
      parameterIsUsedByTemplate: 'The parameter is being used by an action',
      parameterIsUsedByInstance: 'The parameter is being used by a service',
      parameterAlreadyExists: 'The parameter name already exists',
      errorCreatingApplication: 'Error creating application',
      applicationIdFormat: "The identifier can only contain uppercase and '_'",
      errorCreatingPlatform: 'Error creating platform',
      errorCreatingType: 'Error creating type',
      errorCreatingEntity: 'Error creating entidad',
      errorEditingEntity: 'Error editing entidad',
      errorDeletingType: 'Error deleting type',
      postingSchemeUsed: 'The posting scheme is being used by a service',
      postingAlreadyExists: 'Posting already exists',
      disaggregationUsed: 'The disaggregation is being used by a service',
      disaggregationUsedByPostingScheme:
        'The disaggregation is being used by a posting scheme',
      disaggregationAlreadyExists: 'Disaggregation already exists',
      disaggregationLineAlreadyExists:
        'The template selected has an operation, suboperation and subject that already exist in the disaggregation',
      disaggregationBaseOrdinalIncorrect:
        'The base ordinal chosen does not correspond to any existing ordinal',
      postingUsed: 'The posting is being used by a service',
      postingUsedByPostingScheme:
        'The posting is being used by a posting scheme',      
      templateIsUsed: 'The template is being used',
      accountIdAlreadyExists: 'The account id already exists',
      paymentButtonFieldsAlreadyExists: 'The payment button id already exists',
      paymentButtonTypeAlreadyExists: 'The payment button type already exists',
      paymentButtonTypeFieldAlreadyExists:
        'The payment button type field already exists',
      paymentButtonFieldsExistingRelationships:
        'The payment button field has existing relationships',
      paymentButtonTypeHasRelationships:
        'The payment button type has existing relationships',

      operationGroupDoesNotExist: 'The operation group does not exist',
      operationGroupsHasOperations: 'The operation group has operations',
      adjustmentReasonAlreadyExists: 'The adjustment reason already exists',
      calendarAlreadyExists: 'The calendar already exists',
      calendarInUse: 'The calendar is being used',
      codeLengthInvalid: 'Currency code must have maximum 3 characters',
      attributeGroupAlreadyExist: 'Attribute group already exists',
      userTypeExist: 'The user type already exist',
      userTypeDoesntExist: 'The user type does not exist',
      currencyInUse: 'The currency is being used',
      operationRoleAlreadyExist: 'Operation role already exist',
      integrationMandatoryParameters: 'Need completed mandatory parameters',
      needPostingScheme: 'Need completed posting parameters',
      emptyDescription: 'Description cannot be empty',
      loadingServiceTemplate: 'Error loading service templates',
      serviceModeAlreadyExist: 'Service Mode already exists',
      serviceModeAttributeGroupAlreadyExist:
        'Attribute group already associated to service mode',
      noAttributeGroupsToAssociate:
        'There are no attribute groups to associate to service mode',
      invalidIntegrationTypeId:
        'Invalid id: no integration type was found',
      integrationTypeIdAlreadyExists:
        'The id is being used by another integration type',
      parameterIsBeingUsed:
        'Cannot delete parameter: it is being used',
      typeParameterAlreadyExists:
        'This combination of category id and parameter id is already being used by a parameter',
      templateAlreadyExists:
        'Already exists a template with that id and description',
      userNotExist:
        'The user does not exist in the system',
      settlementModeAlreadyExists:
        'The settlement mode already exists',
      numberMustBePositive:
        'Insert a positive number',
      emptyMetadataInputs:
        'There are no metadata inputs',
      emptyMetadataOutputs:
        'There are no metadata outputs',
      emptyMappings:
        'There are no mappings',
      newFieldIdIsBeingUsed: 'The new field id is being used',
      noApisForExternalEntity:
        'There are no APIs for the selected external entity',
      nonWorkingDayAlreadyExist:
        'The non working day already exists',
        errorChangingPassword: 'Error cambiando contraseña',
        noData: 'There is no data to display',
        invalidFormat: 'The format of the data entered is incorrect',
      stepNeedsMappings: 'Cannot activate step, it needs the mappings between output metadata and api response',

    },
  };

  translateMatPaginator(paginator: MatPaginator) {
    if (paginator) {
      paginator._intl.itemsPerPageLabel =
        this._paginator[this.currentLocale].itemsPerPageLabel;
      paginator._intl.nextPageLabel =
        this._paginator[this.currentLocale].nextPageLabel;
      paginator._intl.previousPageLabel =
        this._paginator[this.currentLocale].previousPageLabel;
      paginator._intl.firstPageLabel =
        this._paginator[this.currentLocale].firstPageLabel;
      paginator._intl.lastPageLabel =
        this._paginator[this.currentLocale].lastPageLabel;
    }
  }

  private _paginator = {
    es: {
      itemsPerPageLabel: 'Items por página',
      nextPageLabel: 'Siguiente página',
      previousPageLabel: 'Página anterior',
      firstPageLabel: 'Primera página',
      lastPageLabel: 'Última página',
    },
    en: {
      itemsPerPageLabel: 'Items per page',
      nextPageLabel: 'Next page',
      previousPageLabel: 'Previous page',
      firstPageLabel: 'First page',
      lastPageLabel: 'Last page',
    },
  };

  public get login() {
    return this._login[this.currentLocale];
  }
  private _login = {
    es: {
      logout: 'Cerrar sesión',
      logoutConfirmation: '¿Está seguro de cerrar sesión?',
      login: 'Ingresar',
      agents: 'Agentes',
      notifications: 'Notificaciones',
      extensionPointsAndPostings: 'Extension Points y Postings',
      configuration: 'Configuración',
      forgotPassword: 'Olvidé mi contraseña',
      username: 'Usuario',
      password: 'Contraseña',
      expiredSession: 'Su sesión ha expirado',
      logoutAndLogBackIn: 'Cierre sesión y vuelva a ingresar',
    },
    en: {
      logout: 'Logout',
      logoutConfirmation: 'Are you sure you want to logout?',
      login: 'Login',
      agents: 'Agents',
      notifications: 'Notifications',
      extensionPointsAndPostings: 'Extension Points and Postings',
      configuration: 'Configuration',
      forgotPassword: 'Forgot my Password',
      username: 'Username',
      password: 'Password',
      expiredSession: 'Your session has expired',
      logoutAndLogBackIn: 'Logout and log back in',
    },
  };
  public get profile() {
    return this._profile[this.currentLocale];
  }
  private _profile = {
    es: {
      changeProfilePicture: 'Cambiar foto de perfil',
      changePassword: 'Cambiar contraseña',
      lastLogin: 'Último ingreso',
      logout: 'Cerrar sesión',
      login: 'Iniciar sesión',
      profile: 'Perfil',
    },
    en: {
      changeProfilePicture: 'Change profile picture',
      changePassword: 'Change password',
      lastLogin: 'Last login',
      logout: 'Logout',
      login: 'Login',
      profile: 'Profile',
    },
  };

  public get adjustmentReasons() {
    return this._adjustmentReasons[this.currentLocale];
  }
  private _adjustmentReasons: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear una razón de ajuste',
      edit: 'Editar una razón de ajuste',
      delete: 'Eliminar una razón de ajuste',
      singular: 'Razón de ajuste',
      plural: 'Razones de ajuste',
      empty: 'No hay razones de ajuste',
      singularPronoun: 'la',
    },
    en: {
      create: 'Create an adjustment reason',
      edit: 'Edit an adjustment reason',
      delete: 'Delete an adjustment reason',
      singular: 'Adjustment reason',
      plural: 'Adjustment reasons',
      empty: 'There are no adjustment reasons',
      singularPronoun: 'the',
    },
  };

  public get tableHeaders() {
    return this._tableHeaders[this.currentLocale];
  }
  private _tableHeaders = {
    es: {
      frameTemplate: 'Template de frame',
      frameId: 'Id de frame',
      path: 'Ruta',
      restOperation: 'Operación REST',
      recipientEntity: 'Entidad receptora',
      ordererEntity: 'Entidad ordenante',
      separator: 'Separador decimal',
      decimalNumber: 'Número decimal',
      name: 'Nombre',
      fantasyName: 'Nombre fantasía',
      agentType: 'Tipo de agente',
      service: 'Servicio',
      services: 'Servicios',
      mediums: 'Medios',
      administrators: 'Administradores',
      branches: 'Sucursales',
      actions: 'Acciones',
      id: 'Id',
      bank: 'Banco',
      createDate: 'Fecha de creación',
      updateDate: 'Fecha de modificación',
      channel: 'Canal',
      integration: 'Integración',
      integrationType: 'Tipo de integración',
      subcategory: 'Subcategoría',
      category: 'Categoría',
      associatedServices: 'Servicios asociados',
      operation: 'Operación',
      status: 'Estado',
      creationDate: 'Fecha de creación',
      serviceType: 'Tipo de servicio',
      operationGroup: 'Grupo de operación',
      controlType: 'Tipo de control',
      defaultValue: 'Valor por defecto',
      required: 'Mandatorio',
      editable: 'Editable',
      hidden: 'Oculto',
      identification: 'Identificación',
      description: 'Descripción',
      structure: 'Estructura',
      feeType: 'Amount/Percentage',
      currency: 'Moneda',
      amount: 'Monto',
      amountFrom: 'Monto desde',
      amountTo: 'Monto hasta',
      percentage: 'Porcentaje',
      entity: 'Entidad',
      frequency: 'Frecuencia',
      limitType: 'Tipo de límite',
      value: 'Valor',
      creationMode: 'Modo de creación',
      min: 'Mínimo',
      max: 'Máximo',
      agreementId: 'Id de acuerdo',
      businessChannel: 'Canal de negocio',
      agentDistribution: 'Dist agentes',
      financialInstitutionDistribution: 'Dist Inst Financiera',
      key: 'Clave',
      operationId: 'Id de operación',
      operationDescription: 'Descripción de operación',
      serviceId: 'Id de servicio',
      serviceDescription: 'Descripción de servicio',
      agreementDescription: 'Descripción de acuerdo',
      agreements: 'Acuerdos',
      bundle: 'Bundle',
      visibility: 'Visibilidad',
      supervisor: 'Supervisor',
      devices: 'Dispositivos',
      tellers: 'Operadores',
      address: 'Dirección',
      assocDate: 'Fecha de asociación',
      version: 'Versión',
      segment: 'Segmento',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de fin',
      priority: 'Prioridad',
      subcategories: 'Subcategorías',
      branch: 'Sucursal',
      postingScheme: 'Esquema de posteo',
      flow: 'Flujo',
      enabled: 'Habilitada',
      ordinal: 'Ordinal',
      action: 'Acción',
      agreement: 'Acuerdo',
      scheme: 'Esquema',
      amount_percentage: 'Monto/Porcentaje',
      type: 'Tipo',
      fee: 'Comisión',
      service_agreement: 'Servicio/Acuerdo',
      medium: 'Medio',
      transactionId: 'Id de transacción',
      transactionType: 'Tipo de transacción',
      device: 'Dispositivo',
      user: 'Usuario',
      availableBalance: 'Saldo disponible',
      numberOfInstallments: 'Cantidad de cuotas',
      accountType: 'Tipo de cuenta',
      accountNumber: 'Número de cuenta',
      role: 'Rol',
      paymentMedium: 'Medio de pago',
      posting: 'Posteo',
      disaggregation: 'Desagregación',
      openingBalance: 'Balance inicial',
      closingBalance: 'Balance final',
      currentBalance: 'Balance actual',
      openingDate: 'Fecha de apertura',
      usage: 'Uso',
      country: 'País',
      email: 'Email',
      nationality: 'Nacionalidad',
      application: 'Aplicación',
      platform: 'Plataforma',
      method: 'Método',
      stepTemplate: 'Template',
      subject: 'Sujeto',
      suboperation: 'Suboperación',
      baseOrdinal: 'Ordinal base',
      settlementMode: 'Modo de liquidación',
      group: 'Grupo',
      errorGroup: 'Grupo de error',
      reverse: 'Reverso',
      postingSign: 'Signo de posting',
      isoCode: 'Código ISO',
      symbol: 'Símbolo',
      fieldId: 'Id del campo',
      isMandatory: 'Mandatorio',
      fieldName: 'Nombre del campo',
      month: 'Mes',
      day: 'Día',
      externalId: 'Id externo',
      ordererAccount: 'Cuenta ordenante',
      recipientAccount: 'Cuenta beneficiaria',
      originUserType: 'Usuario cuenta origen',
      destinationUserType: 'Usuario cuenta destino',
      serviceTemplate: 'Template de servicio',
      mediumId: 'Id de medio',
      mediumDescription: 'Descripcion de medio',
      categoryId: 'Id de la categoría',
      parameterId: 'Id del parámetro',
      parameterDescription: 'Descripción del parámetro',
      businessUnit: 'Unidad de negocio',
      managerType: 'Tipo de manager',
      managerBasic: 'Manager básico',
      managerAdvanced: 'Manager avanzado',
      metadata: 'Metadata',
      isEditable: 'Editable',
      isHidden: 'Oculto',
      validation: 'Validación',
      api: 'API',
      apiId: 'API id',
      body: 'Body',
      fieldPath: 'Ruta del campo',
      iterationPath: 'Ruta de la iteración',
    },
    en: {
      frameTemplate: 'Frame template',
      frameId: 'Frame id',
      path: 'Path',
      restOperation: 'REST operation',
      recipientEntity: 'Recipient entity',
      ordererEntity: 'Orderer entity',
      separator: 'Decimal separator',
      decimalNumber: 'Decimal number',
      name: 'Name',
      fantasyName: 'Fantasy name',
      agentType: 'Agent Type',
      service: 'Service',
      services: 'Services',
      mediums: 'Payment Methods',
      administrators: 'Administrators',
      branches: 'Branches',
      actions: 'Actions',
      id: 'Id',
      bank: 'Bank',
      creteDate: 'Creation date',
      updateDate: 'Last update',
      channel: 'Channel',
      integration: 'Integration',
      integrationType: 'Integration type',
      subcategory: 'Subcategory',
      category: 'Category',
      associatedServices: 'Associated Services',
      operation: 'Operation',
      status: 'Status',
      creationDate: 'Creation Date',
      serviceType: 'Service Type',
      operationGroup: 'Operation group',
      identification: 'Identification',
      description: 'Description',
      structure: 'Structure',
      feeType: 'Amount/Percentage',
      currency: 'Currency',
      amount: 'Amount',
      amountFrom: 'Amount From',
      amountTo: 'Amount To',
      percentage: 'Percentage',
      entity: 'Entity',
      frequency: 'Frequency',
      limitType: 'Limit Type',
      value: 'Value',
      creationMode: 'Creation Mode',
      min: 'Min',
      max: 'Max',
      agreementId: 'Agreement Id',
      businessChannel: 'Business Channel',
      agentDistribution: 'Agent Dist.',
      financialInstitutionDistribution: 'Financial Inst. Dist.',
      key: 'Key',
      operationId: 'Operation Id',
      operationDescription: 'Operation Description',
      serviceId: 'Service Id',
      serviceDescription: 'Service Description',
      agreementDescription: 'Agreement Description',
      agreements: 'Agreements',
      bundle: 'Bundle',
      visibility: 'Visibility',
      supervisor: 'Supervisor',
      devices: 'Devices',
      tellers: 'Tellers',
      address: 'Address',
      assocDate: 'Association Date',
      version: 'Version',
      segment: 'Segment',
      startDate: 'Start Date',
      endDate: 'End Date',
      priority: 'Priority',
      subcategories: 'Subcategories',
      branch: 'Branch',
      postingScheme: 'Posting Scheme',
      flow: 'Flow',
      enabled: 'Enabled',
      ordinal: 'Ordinal',
      action: 'Action',
      agreement: 'Agreement',
      scheme: 'Scheme',
      amount_percentage: 'Amount/Percentage',
      type: 'Type',
      fee: 'Fee',
      service_agreement: 'Service/Agreement',
      medium: 'Payment method',
      transactionId: 'Transaction Id',
      transactionType: 'Transaction Type',
      device: 'Device',
      user: 'User',
      availableBalance: 'Available Balance',
      numberOfInstallments: 'Number of Installments',
      accountType: 'Account Type',
      accountNumber: 'Account Number',
      required: 'Required',
      editable: 'Editable',
      hidden: 'Hidden',
      controlType: 'Control Type',
      defaultValue: 'Default Value',
      role: 'Role',
      paymentMedium: 'Payment Method',
      posting: 'Posting',
      disaggregation: 'Disaggregation',
      openingBalance: 'Opening Balance',
      closingBalance: 'Closing Balance',
      currentBalance: 'Current Balance',
      openingDate: 'Opening Date',
      usage: 'Usage',
      country: 'Country',
      email: 'Email',
      nationality: 'Nationality',
      application: 'Application',
      platform: 'Platform',
      method: 'Method',
      stepTemplate: 'Template',
      subject: 'Subject',
      suboperation: 'Suboperation',
      baseOrdinal: 'Base Ordinal',
      settlementMode: 'Settlement Mode',
      group: 'Group',
      errorGroup: 'Error group',
      reverse: 'Reverse',
      postingSign: 'Posting sign',
      isoCode: 'ISO code',
      symbol: 'Symbol',
      fieldId: 'Field Id',
      isMandatory: 'Is mandatory',
      fieldName: 'Field name',
      month: 'Month',
      day: 'Day',
      externalId: 'External Id',
      ordererAccount: 'Orderer Account',
      recipientAccount: 'Recipient Account',
      originUserType: 'Origin user type',
      destinationUserType: 'Destination user type',
      serviceTemplate: 'Service template',
      mediumId: 'Medium id',
      mediumDescription: 'Medium description',
      categoryId: 'Category id',
      parameterId: 'Parameter id',
      parameterDescription: 'Parameter description',
      businessUnit: 'Business unit',
      managerType: 'Manager type',
      managerBasic: 'Basic manager',
      managerAdvanced: 'Advanced manager',
      metadata: 'Metadata',
      isEditable: 'Editable',
      isHidden: 'Hidden',
      validation: 'Validation',
      api: 'API',
      apiId: 'API id',
      body: 'Body',
      fieldPath: 'Field path',
      iterationPath: 'Iteration path',
    },
  };

  public get status() {
    return this._status[this.currentLocale];
  }

  private _status = {
    es: {
      active: 'Activo',
      inactive: 'Inactivo',
    },
    en: {
      active: 'Active',
      inactive: 'Inactive',
    },
  };

  public get isMandatory() {
    return this._isMandatory[this.currentLocale];
  }

  private _isMandatory = {
    es: {
      mandatory: 'Sí',
      notMandatory: 'No',
    },
    en: {
      mandatory: 'Yes',
      notMandatory: 'No',
    },
  };

  public get detailsHeaders() {
    return this._detailsHeaders[this.currentLocale];
  }
  private _detailsHeaders = {
    es: {
      restOperation: 'Operación REST',
      method: 'Método',
      path: 'Ruta',
      id: 'Id',
      name: 'Nombre',
      creationDate: 'Fecha de creación',
      bankId: 'Id',
      updateDate: 'Última actualización',
      abaNumber: 'Número ABA',
      swiftCode: 'Código swift',
      bankName: 'Nombre del banco',
      bankAddress: 'Dirección',
      country: 'País',
      phone: 'Teléfono',
      currency: 'Moneda',
      calendar: 'Calendario',
      type: 'Tipo',
      segment: 'Segmento',
      businessChannel: 'Canal de negocios',
      postingScheme: 'Esquema de posteo',
      subcategory: 'Subcategoría',
      category: 'Categoría',
      operation: 'Operación',
      status: 'Estado',
      serviceType: 'Tipo de servicio',
      modificationDate: 'Fecha de modificación',
      maxDevices: 'Cantidad máxima de dispositivos',
      shiftType: 'Tipo de turno',
      version: 'Versión',
      description: 'Descripción',
      agentType: 'Tipo de agente',
      startDate: 'Fecha de inicio',
      includeStartDate: 'Incluir fecha de inicio',
      endDate: 'Fecha de fin',
      includeEndDate: 'Incluir fecha de fin',
      priority: 'Prioridad',
      branch: 'Sucursal',
      digitalServiceTemplate: 'Template de servicio digital',
      serviceTemplate: 'Template de servicio',
      service: 'Servicio',
      structure: 'Estructura',
      scheme: 'Esquema',
      lastModified: 'Última modificación',
      serviceDescription: 'Descripción de servicio',
      accountType: 'Tipo de cuenta',
      accountNumber: 'Número de cuenta',
      role: 'Rol',
      idType: 'Tipo de identificación',
      idNumber: 'Número de identificación',
      availableBalance: 'Saldo disponible',
      transactionId: 'Id de transacción',
      device: 'Dispositivo',
      user: 'Usuario',
      amount: 'Monto',
      postingDate: 'Fecha de posteo',
      valueDate: 'Fecha de valor',
      reference: 'Referencia',
      reason: 'Motivo',
      closingBalance: 'Balance final',
      openingBalance: 'Balance inicial',
      currentBalance: 'Balance actual',
      openingDate: 'Fecha de apertura',
      entity: 'Entidad',
      limitType: 'Tipo de límite',
      value: 'Valor',
      creationMode: 'Modo de creación',
      frequency: 'Frecuencia',
      usage: 'Utilización',
      countryId: 'Id del país',
      countryName: 'Nombre del país',
      IsoCode: 'Código ISO',
      phoneCode: 'Código de teléfono',
      changeDate: 'Fecha de cambio',
      paymentButtonType: 'Tipo de botón de pago',
      paymentButtonTypeDescription: 'Descripción del tipo de botón de pago',
      calendarDescription: 'Descripción de calendario',
      hour: 'Hora',
      day: 'Día',
      api: 'API',
      controlType: 'Tipo de control',
      defaultValue: 'Valor por defecto',
      validation: 'Validación',

    },
    en: {
      restOperation: 'REST operation',
      method: 'Method',
      path: 'Path',
      id: 'Id',
      name: 'Name',
      creationDate: 'Creation Date',
      country: 'Country',
      bankId: 'Id',
      updateDate: 'Last update',
      abaNumber: 'ABA number',
      swiftCode: 'Swift code',
      bankName: 'Bank name',
      bankAddress: 'Address',
      phone: 'Phone',
      currency: 'Currency',
      calendar: 'Calendar',
      type: 'Type',
      segment: 'Segment',
      businessChannel: 'Business Channel',
      postingScheme: 'Posting Scheme',
      subcategory: 'Subcategory',
      category: 'Category',
      operation: 'Operation',
      status: 'Status',
      serviceType: 'Service Type',
      modificationDate: 'Modification date',
      maxDevices: 'Max. Devices',
      shiftType: 'Shift Type',
      version: 'Version',
      description: 'Description',
      agentType: 'Agent Type',
      startDate: 'Start Date',
      includeStartDate: 'Include Start Date',
      endDate: 'End Date',
      includeEndDate: 'Include End Date',
      priority: 'Priority',
      branch: 'Branch',
      digitalServiceTemplate: 'Digital Service Template',
      serviceTemplate: 'Service Template',
      service: 'Service',
      structure: 'Structure',
      scheme: 'Scheme',
      lastModified: 'Last Modified',
      serviceDescription: 'Service Description',
      accountType: 'Account Type',
      accountNumber: 'Account Number',
      role: 'Role',
      idType: 'Identification Type',
      idNumber: 'Identification Number',
      availableBalance: 'Available Balance',
      transactionId: 'Transaction Id',
      device: 'Device',
      user: 'User',
      amount: 'Amount',
      postingDate: 'Posting Date',
      valueDate: 'Value Date',
      reference: 'Reference',
      reason: 'Reason',
      closingBalance: 'Closing Balance',
      openingBalance: 'Opening Balance',
      currentBalance: 'Current Balance',
      openingDate: 'Opening Date',
      entity: 'Entity',
      limitType: 'Limit Type',
      value: 'Value',
      creationMode: 'Creation Mode',
      frequency: 'Frequency',
      usage: 'Usage',
      countryId: 'Country Id',
      countryName: 'Country name',
      IsoCode: 'ISO code',
      phoneCode: 'Phone code',
      changeDate: 'Change date',
      paymentButtonType: 'Payment Button Type',
      paymentButtonTypeDescription: 'Payment Button Type Description',
      calendarDescription: 'Calendar description',
      hour: 'Hour',
      day: 'Día',
      api: 'API',
      controlType: 'Control type',
      defaultValue: 'Default value',
      validation: 'Validation',

    },
  };

  public get template() {
    return this._template[this.currentLocale];
  }
  private _template: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Templates',
      create: 'Crear template',
      delete: 'Eliminar template',
      edit: 'Editar template',
      singular: 'Template',
      plural: 'Templates',
      singularPronoun: 'El',
      empty: 'No existen templates',
      new: 'Nuevo template',
    },
    en: {
      title: 'Templates',
      create: 'Create template',
      delete: 'Delete template',
      edit: 'Edit template',
      singular: 'Template',
      plural: 'Templates',
      singularPronoun: 'the',
      empty: 'There are no templates',
      new: 'New template',
    },
  };

  public get step() {
    return this._step[this.currentLocale];
  }
  private _step = {
    es: {
      title: 'Pasos',
      create: 'Crear paso',
      delete: 'Eliminar paso',
      edit: 'Editar paso',
      singular: 'Paso',
      plural: 'Pasos',
      singularPronoun: 'El',
      empty: 'No existen pasos',
      new: 'Nuevo paso',
      newPostingGroup: 'Nuevo grupo',
      activate: 'Activar paso',
    },
    en: {
      title: 'Steps',
      create: 'Create step',
      delete: 'Delete step',
      edit: 'Edit step',
      singular: 'Step',
      plural: 'Steps',
      singularPronoun: 'The',
      empty: 'There are no steps',
      new: 'New step',
      newPostingGroup: 'New group',
      activate: 'Activate step',

    },
  };

  public get data() {
    return this._data[this.currentLocale];
  }
  private _data = {
    es: {
      title: 'Datos',
      create: 'Crear dato',
      delete: 'Eliminar dato',
      edit: 'Editar dato',
      singular: 'Dato',
      plural: 'Datos',
      singularPronoun: 'El',
      empty: 'No existen datos',
      new: 'Nuevo dato',
    },
    en: {
      title: 'Data',
      create: 'Create data',
      delete: 'Delete data',
      edit: 'Edit data',
      singular: 'Data',
      plural: 'Data',
      singularPronoun: 'The',
      empty: 'There is no data',
      new: 'New data',
    },
  };

  public get disaggregation() {
    return this._disaggregation[this.currentLocale];
  }
  private _disaggregation: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Desagregaciones',
      create: 'Crear desagregación',
      delete: 'Eliminar desagregación',
      edit: 'Editar desagregación',
      singular: 'Desagregación',
      plural: 'Desagregaciones',
      singularPronoun: 'La',
      empty: 'No existen desagregaciones',
      new: 'Nueva desagregación',
    },
    en: {
      title: 'Disaggregations',
      create: 'Create disaggregation',
      delete: 'Delete disaggregation',
      edit: 'Edit disaggregation',
      singular: 'Disaggregation',
      plural: 'Disaggregations',
      singularPronoun: 'the',
      empty: 'There are no disaggregations',
      new: 'New disaggregation',
    },
  };

  public get posting() {
    return this._posting[this.currentLocale];
  }
  private _posting: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Postings',
      create: 'Crear posting',
      delete: 'Eliminar posting',
      edit: 'Editar posting',
      singular: 'Posting',
      plural: 'Postings',
      singularPronoun: 'El',
      empty: 'No existen postings',
      new: 'Nuevo posting',
    },
    en: {
      title: 'Postings',
      create: 'Create posting',
      delete: 'Delete posting',
      edit: 'Edit posting',
      singular: 'Posting',
      plural: 'Postings',
      singularPronoun: 'the',
      empty: 'There are no postings',
      new: 'New posting',
    },
  };

  public get postingSchemes() {
    return this._postingSchemes[this.currentLocale];
  }
  private _postingSchemes: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Esquemas de posteo',
      create: 'Crear esquema de posteo',
      delete: 'Eliminar esquema de posteo',
      edit: 'Editar esquema de posteo',
      singular: 'Esquema de posteo',
      plural: 'Esquemas de posteo',
      singularPronoun: 'El',
      empty: 'No existen esquemas de posteo',
      new: 'Nuevo esquema de posteo',
    },
    en: {
      title: 'Posting schemes',
      create: 'Create posting scheme',
      delete: 'Delete posting scheme',
      edit: 'Edit posting scheme',
      singular: 'Posting scheme',
      plural: 'Posting schemes',
      singularPronoun: 'the',
      empty: 'There are no posting schemes',
      new: 'New posting scheme',
    },
  };

  public get extensionPoints() {
    return this._extensionPoints[this.currentLocale];
  }
  private _extensionPoints: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Puntos de extensión',
      create: 'Crear punto de extensión',
      delete: 'Eliminar punto de extensión',
      edit: 'Editar punto de extensión',
      singular: 'Punto de extensión',
      plural: 'Puntos de extensión',
      singularAction: 'Punto de extensión',
      pluralAction: 'Puntos de extensión',
      createAction: 'Crear acción',
      serviceTemplate: 'Template de servicio',
      singularPronoun: 'El',
      empty: 'No existen puntos de extensión',
      new: 'Nuevo punto de extensión',
      continue: 'Continuar',
      editAction: 'Editar acción',
      deleteAction: 'Eliminar acción',
      action: 'Acción',
      newAction: 'Nueva acción',
      cancel: 'Cancelar',
    },
    en: {
      title: 'Extension points',
      create: 'Create extension point',
      delete: 'Delete extension point',
      edit: 'Edit extension point',
      singular: 'Extension point',
      plural: 'Extension points',
      singularAction: 'Extension point',
      pluralAction: 'Extension points',
      createAction: 'Create action',
      serviceTemplate: 'Service template',
      singularPronoun: 'the',
      empty: 'There are no extension points',
      new: 'new Extension point',
      continue: 'Continue',
      editAction: 'Edit action',
      deleteAction: 'Delete action',
      action: 'Action',
      newAction: 'New action',
      cancel: 'Cancel',
    },
  };

  public get extensionPointsActions() {
    return this._extensionPointsActions[this.currentLocale];
  }

  private _extensionPointsActions: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Acciones',
      create: 'Crear acción',
      delete: 'Eliminar acción',
      edit: 'Editar acción',
      singular: 'Acción',
      plural: 'Acciones',
      singularPronoun: 'La',
      empty: 'No existen acciones',
      new: 'Nueva acción',
      continue: 'Continuar',
    },
    en: {
      title: 'Action',
      create: 'Create action',
      delete: 'Delete action',
      edit: 'Edit action',
      singular: 'Action',
      plural: 'Actions',
      singularPronoun: 'the',
      empty: 'There are no actions',
      new: 'New action',
      continue: 'Continue',
    },
  };

  public get extensionPointsFields() {
    return this._extensionPointsFields[this.currentLocale];
  }

  private _extensionPointsFields: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Parámetros',
      create: 'Crear parámetro',
      delete: 'Eliminar parámetro',
      edit: 'Editar parámetro',
      singular: 'Parámetro',
      plural: 'Parámetros',
      singularPronoun: 'El',
      empty: 'No existen parámetros',
      new: 'Nuevo parámetro',
      continue: 'Continuar',
      chooseParams: 'Elegir parámetros',
      chosenParams: 'Parámetros elegidos',
    },
    en: {
      title: 'Parameters',
      create: 'Create parameter',
      delete: 'Delete parameter',
      edit: 'Edit parameter',
      singular: 'Parameter',
      plural: 'Parameters',
      singularPronoun: 'the',
      empty: 'There are no parameters',
      new: 'New parameter',
      continue: 'Continue',
      chooseParams: 'Choose parameters',
      chosenParams: 'Chosen parameters',
    },
  };

  public get applications() {
    return this._applications[this.currentLocale];
  }
  private _applications: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Aplicaciones',
      create: 'Crear aplicación',
      delete: 'Eliminar aplicación',
      edit: 'Editar aplicación',
      singular: 'Aplicación',
      plural: 'Aplicaciones',
      empty: 'No existen aplicaciones',
      new: 'Nueva aplicación',
      confirmDeleteApplication:
        '¿Está seguro que quieres eliminar la aplicación?',
      singularPronoun: 'La',
    },
    en: {
      title: 'Application',
      create: 'Create application',
      delete: 'Delete application',
      edit: 'Edit application',
      singular: 'Application',
      plural: 'Applications',
      empty: 'There are no applications',
      new: 'New application',
      confirmDeleteApplication:
        'Are you sure you want to delete the application?',
      singularPronoun: 'the',
    },
  };

  public get platforms() {
    return this._platforms[this.currentLocale];
  }
  private _platforms: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Plataformas',
      create: 'Crear plataforma',
      delete: 'Eliminar plataforma',
      edit: 'Editar plataforma',
      singular: 'Plataforma',
      plural: 'Plataformas',
      empty: 'No existen plataformas',
      new: 'Nueva plataforma',
      confirmDeletePlatform: '¿Está seguro que quieres eliminar la plataforma?',
      singularPronoun: 'La',
    },
    en: {
      title: 'Platform',
      create: 'Create platform',
      delete: 'Delete platform',
      edit: 'Edit platform',
      singular: 'Platform',
      plural: 'Platforms',
      empty: 'There are no platforms',
      new: 'New platform',
      confirmDeletePlatform: 'Are you sure you want to delete the platform?',
      singularPronoun: 'the',
    },
  };

  public get entity() {
    return this._entity[this.currentLocale];
  }
  private _entity: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Entidades',
      create: 'Crear entidad',
      delete: 'Eliminar entidad',
      edit: 'Editar entidad',
      singular: 'Entidad',
      plural: 'Entidades',
      empty: 'No existen entidades',
      new: 'Nueva entidad',
      confirmDeleteEntity: '¿Está seguro que quieres eliminar la entidad?',
      singularPronoun: 'La',
    },
    en: {
      title: 'Entity',
      create: 'Create entity',
      delete: 'Delete entity',
      edit: 'Edit entity',
      singular: 'Entity',
      plural: 'Entities',
      empty: 'There are no entity',
      new: 'New entity',
      confirmDeleteEntity: 'Are you sure you want to delete the entity?',
      singularPronoun: 'the',
    },
  };

  public get types() {
    return this._types[this.currentLocale];
  }
  private _types: BasicLocatableObjectMultilanguage = {
    es: {

      title: 'Tipos',
      create: 'Crear tipo',
      delete: 'Eliminar tipo',
      editStatus: 'Editar estado de tipo',
      edit: 'Editar tipo',
      singular: 'Tipo',
      plural: 'Tipos',
      empty: 'No existen tipos',
      new: 'Nuevo tipo',
      confirmDeleteType: '¿Está seguro que quieres eliminar el tipo?',
      singularPronoun: 'El',
    },
    en: {
      title: 'Types',
      create: 'Create type',
      delete: 'Delete type',
      editStatus: 'Edit type status',
      edit: 'Edit type',
      singular: 'Type',
      plural: 'Types',
      empty: 'There are no type',
      new: 'New type',
      confirmDeleteType: 'Are you sure you want to delete the type?',
      singularPronoun: 'the',
    },
  };

  public get users() {
    return this._users[this.currentLocale];
  }

  private _users: BasicLocatableObjectMultilanguage = {
    es: {
      singular: 'Usuario',
      plural: 'Usuarios',
      singularPronoun: 'el',
      create: 'Crear Usuario',
      new: 'Nuevo Usuario',
      delete: 'Borrar Usuario',
      empty: 'No hay managers registrados',
      edit: 'Editar usuario',
    },
    en: {
      singular: 'User',
      plural: 'Users',
      singularPronoun: 'the',
      create: 'Create user',
      new: 'New User',
      delete: 'Delete User',
      empty: 'No hay managers registrados',
      edit: 'Edit user',
    },
  };

  public get fields() {
    return this._fields[this.currentLocale];
  }
  private _fields = {
    es: {
      invalidJSON: "JSON inválido",

      method: 'Método',
      path: 'Ruta',
      externalEntity: 'Entidad externa',
      groupName: 'Nombre del grupo',
      frameAPIs: 'APIs de frame',
      recipientEntity: 'Entidad receptora',
      ordererEntity: 'Entidad ordenante',
      user: 'Usuario',
      name: 'Nombre',
      lastName: 'Apellido',
      email: 'Correo',
      password: 'Contraseña',
      agentType: 'Tipo de agente',
      confirmPassword: 'Confirmar contraseña',
      nationality: 'Nacionalidad',
      currentPassword: 'Contraseña actual',
      confimPassword: 'Confirmar contraseña',
      newPassword: 'Nueva contraseña',
      fantasyName: 'Nombre fantasía',
      country: 'País',
      segment: 'Segmento',
      defaultCurrency: 'Moneda por defecto',
      phone: 'Teléfono',
      address: 'Dirección',
      city: 'Ciudad',
      state: 'Estado',
      zipCode: 'Código postal',
      type: 'Tipo',
      calendar: 'Calendario',
      digitalServiceTemplate: 'Template de servicio digital',
      serviceTemplate: 'Template de servicio',
      status: 'Estado',
      coreBanking: 'Core bancario',
      cardReader: 'Card Reader',
      adjustmentMode: 'Modo de ajuste',
      id: 'Id',
      description: 'Descripción',
      version: 'Versión',
      internalProduct: 'Producto interno',
      coreProduct: 'Producto core',
      linkAgreements: 'Vincular acuerdos',
      identification: 'Identificación',
      bank: 'Banco',
      channel: 'Canal',
      integration: 'Integración',
      digitalfinancialService: 'Servicio financiero digital',
      operation: 'Operación',
      businessChannel: 'Canal de negocio',
      visibility: 'Visibilidad',
      ptsChannel: 'Canal PTS',
      newChannel: '+ Crear nuevo canal',
      style: 'Imagen',
      color: 'Color',
      termsAndConditions: 'Términos y condiciones',
      versions: 'Versiones',
      discount: 'Porcentaje de descuento',
      agentDistribution: 'Distribución de agente',
      financialEntityDistribution: 'Distribución de entidad financiera',
      campaign: 'Campaña',
      startDate: 'Fecha de inicio',
      includeStartDate: 'Incluir fecha de inicio',
      endDate: 'Fecha de fin',
      includeEndDate: 'Incluir fecha de fin',
      priority: 'Prioridad',
      medium: 'Medio de pago',
      operationGroup: 'Grupo de operación',
      PTSService: 'Servicio PTS',
      operationStatus: 'Estado de operación',
      flow: 'Flujo',
      actionOnError: 'Acción en caso de error',
      enable: 'Habilitar',
      chosenParameters: 'Parámetros elegidos',
      currency: 'Moneda',
      scheme: 'Esquema',
      amountFrom: 'Monto desde',
      amountTo: 'Monto hasta',
      structure: 'Estructura',
      amount: 'Monto',
      value: 'Valor',
      bankId: 'Id',
      abaNumber: 'Número ABA',
      swiftCode: 'Código swift',
      bankAddress: 'Dirección',
      bankName: 'Nombre del banco',
      numberOfInstallments: 'Cantidad de cuotas',
      account: 'Cuenta',
      branch: 'Sucursal',
      servicePayment: 'Pago de servicio',
      service: 'Servicio',
      branchAndAccount: 'Cuenta y Sucursal',
      virtualBranch: 'Sucursal virtual',
      paymentPlan: 'Plan de pagos',
      username: 'Usuario',
      idType: 'Tipo de identificación',
      idNumber: 'Número de identificación',
      category: 'Categoría',
      subcategory: 'Subcategoría',
      controlType: 'Tipo de control',
      defaultValue: 'Valor por defecto',
      required: 'Mandatorio',
      editable: 'Editable',
      hidden: 'Oculto',
      datasource: 'Origen de los datos',
      datasourceData: 'Datos del origen de los datos',
      integrationType: 'Tipo de integración',
      limit: 'Límite',
      entity: 'Entidad',
      frequency: 'Frecuencia',
      creationMode: 'Modo de creación',
      configuration: 'Configuración',
      selectedOperations: 'Operaciones seleccionadas',
      minValue: 'Valor mínimo',
      maxValue: 'Valor máximo',
      language: 'Idioma',
      secundSurname: 'Segundo apellido',
      dateOfBirth: 'Fecha de nacimiento',
      alternativePhone: 'Teléfono alternativo',
      cellphone: 'Teléfono móvil',
      profession: 'Profesión',
      externalId: 'Id externo',
      identificationType: 'Tipo de identificación',
      identificationNumber: 'Número de identificación',
      posting: 'Posting',
      disaggregation: 'Desagregación',
      subject: 'Sujeto',
      settlementMode: 'Modo de liquidación',
      suboperation: 'Suboperación',
      template: 'Template',
      baseOrdinal: 'Ordinal base',
      group: 'Grupo',
      errorGroup: 'Grupo de error',
      isReverse: 'Reverso',
      postingGroupType: 'Tipo de grupo',
      postingSign: 'Signo de posting',
      accountId: 'Id de cuenta',
      accountName: 'Nombre de cuenta',
      IsoCode: 'Código ISO',
      phoneCode: 'Código de teléfono',
      image: 'Imagen',
      decimalNumber: 'Número decimal',
      separator: 'Separador decimal',
      paymentButtonFieldName: 'Nombre del campo',
      paymentButtonFieldId: 'Id del campo',
      paymentButtonTypeFieldName: 'Id tipo de campo',
      paymentButtonType: 'Tipo de campo',
      paymentButtonTypeFieldId: 'Id del tipo de campo',
      paymentButtonFieldDesc: 'Descripción del campo',
      month: 'Mes',
      day: 'Día',
      symbol: 'Símbolo',
      recipientAccount: 'Cuenta beneficiaria',
      ordererAccount: 'Cuenta ordenante',
      branchOperation: 'Operación de sucursal',
      originUserType: 'Tipo de usuario de origen',
      destinationUserType: 'Tipo de usuario de destino',
      allowDenominations: 'Permite denominaciones',
      businessUnit: 'Unidad de negocio',
      hour: 'Hora',
      creationDate: 'Fecha de creación',
      updateDate: 'Ultima actualización',
      isMandatory: 'Mandatorio',
      isHidden: 'Oculto',
      isEditable: 'Editable',
      validation: 'Validación',
      field: 'Campo',
      fieldPath: 'Ruta del campo',
      iterationPath: 'Ruta de la iteración',
      deferredSettlement: 'Modo de liquidación diferido',
      dispatchOnline: 'Sistema de envío',
      messageOnline: 'Construcción del mensaje',
      respParseOnline: 'Parseo de respuesta',
      checkout: 'Visibilidad en el carrito',
    },
    en: {
      invalidJSON: 'Invalid JSON',
      path: 'Path',
      method: 'Method',
      externalEntity: 'External entity',
      groupName: 'Group name',
      frameAPIs: 'Frame APIs',
      recipientEntity: 'Recipient entity',
      ordererEntity: 'Orderer entity',
      bankName: 'Bank name',
      user: 'Username',
      name: 'Name',
      lastName: 'Last Name',
      email: 'Email',
      password: 'Password',
      agentType: 'Agent Type',
      confirmPassword: 'Confirm Password',
      nationality: 'Nationality',
      currentPassword: 'Current Password',
      confimPassword: 'Confirm Password',
      newPassword: 'New password',
      fantasyName: 'Fantasy name',
      country: 'Country',
      segment: 'Segment',
      bankId: 'Bank id',
      abaNumber: 'ABA number',
      swiftCode: 'Swift code',
      bankAddress: 'Address',
      defaultCurrency: 'Default Curreny',
      phone: 'Phone',
      address: 'Address',
      city: 'City',
      state: 'State',
      zipCode: 'Zip Code',
      type: 'Type',
      calendar: 'Calendar',
      digitalServiceTemplate: 'Digital Service Template',
      serviceTemplate: 'Service Template',
      status: 'Status',
      coreBanking: 'Core Banking',
      cardReader: 'Card Reader',
      adjustmentMode: 'Adjustment Mode',
      id: 'Id',
      description: 'Description',
      version: 'Version',
      internalProduct: 'Internal Product',
      coreProduct: 'Core Product',
      linkAgreements: 'Link Agreements',
      identification: 'Identification',
      bank: 'Bank',
      channel: 'Channel',
      integration: 'Integration',
      digitalfinancialService: 'Digital Financial Service',
      operation: 'Operation',
      businessChannel: 'Business Channel',
      visibility: 'Visibility',
      ptsChannel: 'PTS Channel',
      newChannel: '+ Create new channel',
      style: 'Image',
      color: 'Color',
      termsAndConditions: 'Terms and Conditions',
      versions: 'Versions',
      discount: 'Discount percentage',
      agentDistribution: 'Agent Distribution',
      financialEntityDistribution: 'Financial Entity Distribution',
      campaign: 'Campaign',
      startDate: 'Start Date',
      includeStartDate: 'Include Start Date',
      endDate: 'End Date',
      includeEndDate: 'Include End Date',
      priority: 'Priority',
      medium: 'Payment Method',
      operationGroup: 'Operation Group',
      PTSService: 'PTS Service',
      operationStatus: 'Operation Status',
      flow: 'Flow',
      actionOnError: 'Action on error',
      enable: 'Enable',
      chosenParameters: 'Chosen parameters',
      currency: 'Currency',
      scheme: 'Scheme',
      amountFrom: 'Amount from',
      amountTo: 'Amount to',
      structure: 'Structure',
      amount: 'Amount',
      value: 'Value',
      numberOfInstallments: 'Number of installments',
      account: 'Account',
      branch: 'Branch',
      servicePayment: 'Service Payment',
      service: 'Service',
      branchAndAccount: 'Branch and Account',
      virtualBranch: 'Virtual Branch',
      paymentPlan: 'Payment plan',
      username: 'Username',
      idType: 'Id Type',
      idNumber: 'Id Number',
      category: 'Category',
      subcategory: 'Subcategory',
      controlType: 'Control Type',
      defaultValue: 'Default Value',
      required: 'Required',
      editable: 'Editable',
      hidden: 'Hidden',
      datasource: 'Datasource',
      datasourceData: 'Datasource data',
      integrationType: 'Integration Type',
      limit: 'Limit',
      entity: 'Entity',
      frequency: 'Frequency',
      creationMode: 'Creation Mode',
      configuration: 'Configuration',
      selectedOperations: 'Selected Operations',
      minValue: 'Min Value',
      maxValue: 'Max Value',
      language: 'Language',
      secundSurname: 'Secund surname',
      dateOfBirth: 'Date of Birth',
      alternativePhone: 'alternative phone',
      cellphone: 'Cellphone',
      profession: 'Profession',
      externalId: 'External Id',
      identificationType: 'Identification Type',
      identificationNumber: 'Identification Number',
      posting: 'Posting',
      disaggregation: 'Disaggregation',
      subject: 'Subject',
      settlementMode: 'Settlement mode',
      suboperation: 'Suboperation',
      template: 'Template',
      baseOrdinal: 'Base Ordinal',
      group: 'Group',
      errorGroup: 'Error group',
      isReverse: 'Reverse',
      postingGroupType: 'Group type',
      postingSign: 'Posting sign',
      accountId: 'Account id',
      accountName: 'Account name',
      IsoCode: 'ISO code',
      phoneCode: 'Phone code',
      image: 'Image',
      decimalNumber: 'Decimal number',
      separator: 'Decimal separator',
      paymentButtonFieldName: 'Field name',
      paymentButtonFieldId: 'Field id',
      paymentButtonTypeFieldName: 'Field type name',
      paymentButtonTypeFieldId: 'Field type id',
      paymentButtonFieldDesc: 'Field description',
      paymentButtonType: 'Field type',
      month: 'Month',
      day: 'Day',
      symbol: 'Symbol',
      recipientAccount: 'Recipient account',
      ordererAccount: 'Orderer account',
      branchOperation: 'Branch operation',
      originUserType: 'Origin user type',
      destinationUserType: 'Destination user type',
      allowDenominations: 'Allows denominations',
      businessUnit: 'Business unit',
      hour: 'Hour',
      creationDate: 'Creation date',
      updateDate: 'Las update',
      isMandatory: 'Is mandatory',
      isHidden: 'Is hidden',
      isEditable: 'Editable',
      validation: 'Validation',
      field: 'Field',
      fieldPath: 'Field path',
      iterationPath: 'Iteration path',
      deferredSettlement: 'Deferred settlement mode',
      dispatchOnline: 'Dispatch online',
      messageOnline: 'Message online',
      respParseOnline: 'Response parse online',
      checkout: 'Checkout',
    },
  };

  public get stepTitle() {
    return this._stepTitle[this.currentLocale];
  }

  private _stepTitle = {
    es: {
      addBody: 'AGREGAR BODY',
      basicData: 'DATOS \nBÁSICOS',
      integrations: 'INTEGRACIONES',
      bundles: 'BUNDLES',
      commercialConditions: 'CONDICIONES \nCOMERCIALES',
      verify: 'VERIFICAR',
      associateAgreements: 'VINCULAR ACUERDOS',
      additionalAttributes: 'ATRIBUTOS ADICIONALES',
      style: 'AGREGAR IMAGEN',
      branches: 'SUCURSALES',
      mediums: 'MEDIOS DE \nPAGO',
      distribution: 'DISTRIBUCIÓN',
      metadata: 'METADATA',
      mediumMetadata: 'METADATA DEL MEDIO DE PAGO',
      qrcode: 'CÓDIGO QR',
      params: 'PARÁMETROS',
      serviceFees: 'COMISIONES DE SERVICIO',
      agreementFees: 'COMISIONES DE ACUERDO',
      structure: 'ESTRUCTURA',
      email: 'INGRESAR \nCORREO',
      code: 'INGRESAR \nCÓDIGO',
      password: 'ESTABLECER \nCONTRASEÑA',
      entityLimit: 'LÍMITES DE ENTIDAD',
      operationLimit: 'LÍMITES DE OPERACIÓN',
      operations: 'OPERACIONES',
      amountData: 'DATOS DE \nMONTO',
      configuration: 'CONFIGURACIÓN',
      minAndMax: 'MÍNIMO Y MÁXIMO',
      username: 'USUARIO',
      identification: 'IDENTIDAD',
      category: 'CATEGORIA',
      group: 'GRUPO',
      state: 'ESTADO',
      enable: 'HABILITAR',
      icon: 'AGREGAR ICONO',
      flows: 'DEFINIR FLUJOS',
      reverseFlows: 'DEFINIR FLUJOS REVERSO',

    },
    en: {
      addBody: 'ADD BODY',
      basicData: 'BASIC \nDATA',
      integrations: 'INTEGRATIONS',
      bundles: 'BUNDLES',
      commercialConditions: 'COMMERCIAL \nCONDITIONS',
      verify: 'VERIFY',
      associateAgreements: 'LINK AGREEMENTS',
      additionalAttributes: 'ADDITIONAL ATTRIBUTES',
      style: 'ADD A PHOTO',
      branches: 'BRANCHES',
      mediums: 'PAYMENT \nMETHODS',
      distribution: 'DISTRIBUTION',
      metadata: 'METADATA',
      mediumMetadata: 'PAYMENT METHOD METADATA',
      qrcode: 'QR CODE',
      params: 'PARAMS',
      serviceFees: 'SERVICE FEES',
      agreementFees: 'AGREEMENT FEES',
      structure: 'STRUCTURE',
      email: 'ENTER \nEMAIL',
      code: 'ENTER \nCODE',
      password: 'SET \nPASSWORD',
      entityLimit: 'ENTITY LIMITS',
      operationLimit: 'OPERATION LIMITS',
      operations: 'OPERATIONS',
      amountData: 'AMOUNT \nDATA',
      configuration: 'CONFIGURATION',
      minAndMax: 'MINIMUM AND MAXIMUM',
      username: 'USERNAME',
      identification: 'IDENTITY',
      category: 'CATEGORY',
      group: 'GROUP',
      state: 'STATE',
      enable: 'ENABLE',
      icon: 'ADD ICON',
      flows: 'DEFINE FLOWS',
      reverseFlows: 'DEFINE FLOWS REVERSE',

    },
  };

  public get flows() {
    return this._flows[this.currentLocale];
  }
  private _flows: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Flujos',
      singular: 'Flujo',
      plural: 'Flujos',
      add: 'Agregar flujo',
      addGroup: 'Agregar grupo',
      create: 'Crear flujo',
      delete: 'Eliminar flujo',
      edit: 'Editar flujo',
      update: 'Actualizar flujo',
      empty: 'No hay flujos',
      singularPronoun: 'el',
      new: 'Nuevos flujos',
      newReverse: 'Nuevos flujos del reverso',

    },
    en: {
      title: 'Flows',
      singular: 'Flow',
      plural: 'Flows',
      add: 'Add flow',
      addGroup: 'Add group',
      create: 'Create flow',
      delete: 'Delete flow',
      edit: 'Edit flow',
      empty: 'No flows',
      singularPronoun: 'the',
      new: 'New flows',
      newReverse: 'New reverse flows',

    },
  };

  public genericError(
    type:
      | 'create'
      | 'update'
      | 'delete'
      | 'associate'
      | 'dissociate'
      | 'add'
      | 'list'
      | 'duplicate',
    element: BasicLocatableObject
  ) {
    switch (type) {
      case 'create':
        if (this.currentLocale == 'es') {
          return `Error creando ${element.singular.toLowerCase()}`;
        } else {
          return `Error creating ${element.singular.toLowerCase()}`;
        }
      case 'update':
        if (this.currentLocale == 'es') {
          return `Error actualizando ${element.singular.toLowerCase()}`;
        } else {
          return `Error updating ${element.singular.toLowerCase()}`;
        }
      case 'delete':
        if (this.currentLocale == 'es') {
          return `Error eliminando ${element.singular.toLowerCase()}`;
        } else {
          return `Error deleting ${element.singular.toLowerCase()}`;
        }
      case 'associate':
        if (this.currentLocale == 'es') {
          return `Error asociando ${element.singular.toLowerCase()}`;
        } else {
          return `Error associating ${element.singular.toLowerCase()}`;
        }
      case 'dissociate':
        if (this.currentLocale == 'es') {
          return `Error desasociando ${element.singular.toLowerCase()}`;
        } else {
          return `Error dissociating ${element.singular.toLowerCase()}`;
        }
      case 'add':
        if (this.currentLocale == 'es') {
          return `Error agregando ${element.singular.toLowerCase()}`;
        } else {
          return `Error adding ${element.singular.toLowerCase()}`;
        }
      case 'list':
        if (this.currentLocale == 'es') {
          return `Error listando ${element.plural.toLowerCase()}`;
        } else {
          return `Error listing ${element.plural.toLowerCase()}`;
        }
      case 'duplicate':
        if (this.currentLocale == 'es') {
          return `Error creando ${element.singularPronoun.toLowerCase()}  ${element.singular.toLowerCase()} porque ya existe`;
        } else {
          return `Error creating ${element.singular.toLowerCase()} becuase it already exists`;
        }
    }
  }

  public get tabNames() {
    return this._tabNames[this.currentLocale];
  }

  private _tabNames = {
    es: {
      serviceTemplates: 'Templates de servicio',
      applications: 'Aplicaciones',
      platforms: 'Plataformas',
      entity: 'Entidades',
      methods: 'Metodos',
      types: 'Tipos',
      bundles: 'Bundles',
      accounts: 'Cuentas',
      mediums: 'Medios',
      services: 'Servicios',
      servicePayment: 'Pago de Servicios',
      entityLimits: 'Límites de Entidad',
      operationLimits: 'Límites de Operación',
      agreementFees: 'Comisiones de Acuerdo',
      serviceFees: 'Comisiones de Servicio',
      paymentButtons: 'Botones de Pago',
      campaigns: 'Campañas',
      integrations: 'Integraciones',
      administradores: 'Administradores',
      branches: 'Sucursales',
      fees: 'Comisiones',
      users: 'Usuarios',
      devices: 'Dispositivos',
      transactions: 'Transacciones',
      limits: 'Límites',
      shifts: 'Turnos',
      agreements: 'Acuerdos',
      subcategories: 'Subcategorías',
      operations: 'Operaciones',
      metadata: 'Metadata',
      actions: 'Acciones',
      tiers: 'Niveles',
      associatedServices: 'Servicios asociados',
      parameters: 'Parámetros',
      template: 'Template',
      out: 'Salida',
      paymentPlans: 'Planes de Pago',
      postingSchemes: 'Esquemas de posteo',
      disaggregations: 'Desagregaciones',
      postings: 'Posteos',
      postingStepTemplates: 'Templates de posting',
      disaggregationStepTemplates: 'Templates de desagregación',
      denominations: 'Denominaciones',
      agentTypes: 'Tipos de Agente',
      steps: 'Pasos',
      accountIds: 'Identificación de cuentas',
      taxes: 'Impuestos',
      identificationTypes: 'Tipos de identificación',
      currency: 'Moneda',
      paymentButtonFields: 'Campos de botón de pago',
      paymentButtonTypeFields: 'Campos de tipo de botón de pago',
      paymentButtonType: 'Tipo de botón de pago',
      operationGroups: 'Grupos de operaciones',
      adjustmentReasons: 'Razones de ajuste',
      attributesAllowed: 'Atributos permitidos',
      attributesGroupAllowed: 'Grupo de atributos permitidos',
      calendars: 'Calendarios',
      nonWorkingDays: 'Días no laborables',
      branchOperations: 'Operaciones de sucursal',
      managers: 'Managers',
      userTypes: 'Tipos de usuario',
      branchOperationRoles: 'Permisos de operaciones',
      serviceModes: 'Modos de servicio',
      attributeGroups: 'Grupos de atributos',
      serviceOperationMediums: 'Medios de Operación de Servicio',
      integrationType: 'Tipo de integración',
      integrationTypeFields: 'Campos de tipo de integración',
      templates: 'Templates',
      settlementModes: 'Modos de liquidación',
      metadataTypes: 'Tipos de metadata',
      metadataFields: 'Campos de metadata',
      inputMetadata: 'Metadata entrante',
      outputMetadata: 'Metadata saliente',
      outputMetadataMap: 'mapeo de metadata saliente',

    },
    en: {
      serviceTemplates: 'Service templates',
      bundles: 'Bundles',
      accounts: 'Accounts',
      mediums: 'Payment methods',
      services: 'Services',
      servicePayment: 'Service Payment',
      entityLimits: 'Entity Limits',
      operationLimits: 'Operation Limits',
      agreementFees: 'Agreement Fees',
      serviceFees: 'Service Fees',
      paymentButtons: 'Payment Buttons',
      campaigns: 'Campaigns',
      integrations: 'Integrations',
      administradores: 'Administrators',
      branches: 'Branches',
      fees: 'Fees',
      users: 'Users',
      devices: 'Devices',
      transactions: 'Transactions',
      limits: 'Limits',
      shifts: 'Shifts',
      agreements: 'Agreements',
      subcategories: 'Subcategories',
      operations: 'Operations',
      metadata: 'Metadata',
      actions: 'Actions',
      tiers: 'Tiers',
      associatedServices: 'Associated services',
      parameters: 'Parameters',
      template: 'Template',
      out: 'Out',
      paymentPlans: 'Payment Plans',
      denominations: 'Denominations',
      postingSchemes: 'Posting schemes',
      disaggregations: 'Disaggregations',
      postings: 'Postings',
      postingStepTemplates: 'Templates de posting',
      disaggregationStepTemplates: 'Templates de desagregación',
      agentTypes: 'Agent Types',
      entity: 'Entities',
      methods: 'Methods',
      types: 'Types',
      applications: 'Applications',
      platforms: 'Platforms',
      steps: 'Steps',
      accountIds: `Accounts identifications`,
      taxes: 'Taxes',
      identificationTypes: 'Identification types',
      currency: 'Currency',
      paymentButtonFields: 'Payment button fields',
      paymentButtonTypeFields: 'Payment button type fields',
      paymentButtonType: 'Payment button type',
      operationGroups: 'Operation groups',
      adjustmentReasons: 'Adjustment reasons',
      attributesAllowed: 'Attributes allowed',
      attributesGroupAllowed: 'Attribute groups allowed',
      calendars: 'Calendars',
      nonWorkingDays: 'Non working days',
      branchOperations: 'Branch operations',
      managers: 'Managers',
      userTypes: 'User types',
      branchOperationRoles: 'Branch operation roles',
      serviceModes: 'Service modes',
      attributeGroups: 'Attribute groups',
      serviceOperationMediums: 'Service operation mediums',
      integrationType: 'Integration type',
      integrationTypeFields: 'Integration type fields',
      templates: 'Templates',
      settlementModes: 'Settlement modes',
      metadataTypes: 'Metadata types',
      metadataFields: 'Metadata fields',
      inputMetadata: 'Input metadata',
      outputMetadata: 'Output metadata',
      outputMetadataMap: 'output metadata maps',

    },
  };

  public successMessage(
    type:
      | 'create'
      | 'update'
      | 'delete'
      | 'associate'
      | 'dissociate'
      | 'add'
      | 'confirmed'
      | 'rejected',
    element: { singular: string },
    genedered = 'm'
  ) {
    switch (type) {
      case 'create':
        if (this.currentLocale == 'es') {
          if (genedered == 'm') {
            return `${element.singular} creado exitosamente`;
          } else {
            return `${element.singular} creada exitosamente`;
          }
        } else {
          return `Successfully created ${element.singular.toLowerCase()}`;
        }
      case 'update':
        if (this.currentLocale == 'es') {
          if (genedered == 'm') {
            return `${element.singular} actualizado exitosamente`;
          } else {
            return `${element.singular} actualizada exitosamente`;
          }
        } else {
          return `Successfully updated ${element.singular.toLowerCase()}`;
        }
      case 'delete':
        if (this.currentLocale == 'es') {
          if (genedered == 'm') {
            return `${element.singular} eliminado exitosamente`;
          } else {
            return `${element.singular} eliminada exitosamente`;
          }
        } else {
          return `Successfully deleted ${element.singular.toLowerCase()}`;
        }
      case 'associate':
        if (this.currentLocale == 'es') {
          if (genedered == 'm') {
            return `${element.singular} asociado exitosamente`;
          } else {
            return `${element.singular} asociada exitosamente`;
          }
        } else {
          return `Successfully associated ${element.singular.toLowerCase()}`;
        }
      case 'dissociate':
        if (this.currentLocale == 'es') {
          if (genedered == 'm') {
            return `${element.singular} desasociado exitosamente`;
          } else {
            return `${element.singular} desasociada exitosamente`;
          }
        } else {
          return `Successfully dissociated ${element.singular.toLowerCase()}`;
        }
      case 'add':
        if (this.currentLocale == 'es') {
          if (genedered == 'm') {
            return `${element.singular} agregado exitosamente`;
          } else {
            return `${element.singular} agregada exitosamente`;
          }
        } else {
          return `Successfully added ${element.singular.toLowerCase()}`;
        }
      case 'confirmed':
        if (this.currentLocale == 'es') {
          if (genedered == 'm') {
            return `${element.singular} confirmado exitosamente`;
          } else {
            return `${element.singular} confirmada exitosamente`;
          }
        } else {
          return `Successfully confirmed ${element.singular.toLowerCase()}`;
        }
      case 'rejected':
        if (this.currentLocale == 'es') {
          if (genedered == 'm') {
            return `${element.singular} rechazado exitosamente`;
          } else {
            return `${element.singular} rechazada exitosamente`;
          }
        } else {
          return `Successfully rejected ${element.singular.toLowerCase()}`;
        }
    }
  }

  public none(element: 'm' | 'f' = 'm') {
    if (this.currentLocale == 'es') {
      if (element == 'm') {
        return `Ninguno`;
      } else {
        return `Ninguna`;
      }
    } else {
      return `None`;
    }
  }

  public get filters() {
    return this._filters[this.currentLocale];
  }
  private _filters = {
    es: {
      recipientEntity: 'Entidad receptora',
      ordererEntity: 'Entidad ordenante',
      name: 'Nombre',
      fantasyName: 'Nombre fantasía',
      version: 'Versión',
      category: 'Categoría',
      subcategory: 'Subcategoría',
      bundleId: 'Bundle Id',
      service: 'Servicio',
      status: 'Estado',
      period: 'Período',
      supervisor: 'Supervisor',
      id: 'Id',
      agentType: 'Tipo de agente',
      description: 'Descripción',
      branch: 'Sucursal',
      structure: 'Estructura',
      medium: 'Medio',
      transaction: 'Transacción',
      type: 'Tipo',
      device: 'Dispositivo',
      user: 'Usuario',
      role: 'Rol',
      key: 'Clave',
      country: 'País',
      month: 'Mes',
      day: 'Día',
      externalId: 'Id externo',
      ordererAccount: 'Cuenta ordenante',
      recipientAccount: 'Cuenta beneficiaria',
      originUserType: 'Usuario cuenta origen',
      destinationUserType: 'Usuario cuenta destino',
      serviceTemplate: 'Template de servicio',
      operation: 'Operación',
    },
    en: {
      name: 'Name',
      recipientEntity: 'Recipient entity',
      ordererEntity: 'Orderer entity',
      fantasyName: 'Fantasy name',
      version: 'Version',
      category: 'Category',
      subcategory: 'Subcategory',
      bundleId: 'Bundle Id',
      service: 'Service',
      status: 'Status',
      period: 'Period',
      supervisor: 'Supervisor',
      id: 'Id',
      agentType: 'Agent Type',
      description: 'Description',
      branch: 'Branch',
      structure: 'Structure',
      medium: 'Payment method',
      transaction: 'Transaction',
      type: 'Type',
      device: 'Device',
      user: 'User',
      role: 'Role',
      key: 'Key',
      country: 'Country',
      month: 'Month',
      day: 'Day',
      externalId: 'External Id',
      ordererAccount: 'Orderer account',
      recipientAccount: 'Recipient account',
      originUserType: 'Origin user type',
      destinationUserType: 'Destination user type',
      serviceTemplate: 'Service template',
      operation: 'Operation',
    },
  };

  public confirmDelete(element: {
    singular: string;
    plural: string;
    singularPronoun?: string;
  }) {
    if (this.currentLocale == 'es') {
      return `¿Estás seguro que quieres eliminar ${element.singularPronoun?.toLocaleLowerCase()} ${element.singular.toLowerCase()}?`;
    } else {
      return `Are you sure you want to delete the ${element.singular.toLowerCase()}?`;
    }
  }

  public get workingDays() {
    return this._workingDays[this.currentLocale];
  }

  private _workingDays: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Días laborales',
      singular: 'Día laboral',
      plural: 'Días laborales',
      singularPronoun: 'el',
      create: 'Crear día laboral',
      edit: 'Editar día laboral',
      empty: 'No hay días laborales',
      goTo: 'Ir a días laborales',
      define: 'Definir días laborales',
      delete: 'Eliminar día laboral',
    },
    en: {
      title: 'Working days',
      singular: 'Working day',
      plural: 'Working days',
      singularPronoun: 'the',
      create: 'Create working day',
      edit: 'Edit working day',
      empty: 'There are no working days',
      goTo: 'Go to working days',
      define: 'Define working days',
      delete: 'Delete working day',
    },
  };

  public get agentTypes() {
    return this._agentTypes[this.currentLocale];
  }

  private _agentTypes: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Tipos de Agente',
      singular: 'Tipo de Agente',
      plural: 'Tipos de Agente',
      singularPronoun: 'el',
      create: 'Crear tipo de agente',
      edit: 'Editar tipo de agente',
      empty: 'No hay tipos de agente',
      delete: 'Eliminar tipo de agente',
    },
    en: {
      title: 'Agent Types',
      singular: 'Agent Type',
      plural: 'Agent Types',
      singularPronoun: 'the',
      create: 'Create agent type',
      edit: 'Edit agent type',
      empty: 'There are no agent types',
      delete: 'Delete agent type',
    },
  };

  public get serviceOperationMediums(){
    return this._serviceOperationMediums[this.currentLocale];
  }

  private _serviceOperationMediums: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Medios de operación de servicio ',
      singular: 'Medio de operación de servicio',
      plural: 'Medios de operación de servicio',
      singularPronoun: 'el',
      create: 'Crear medio de operación de servicio',
      edit: 'Editar medio de operación de servicio',
      empty: 'No hay medios de operación de servicio',
      delete: 'Eliminar medio de operación de servicio',
      new: 'Nuevo medio de operacion de servicio'
    },
    en: {
      title: 'Service Operation Mediums',
      singular: 'Service Operation Medium',
      plural: 'Service Operation Mediums',
      singularPronoun: 'the',
      create: 'Create service operation medium',
      edit: 'Edit service operation medium',
      empty: 'There are no service operation mediums',
      delete: 'Delete service operation medium',
      new: 'New service operation medium'
    },
  }

  public get nonWorkingDays() {
    return this._nonWorkingDays[this.currentLocale];
  }

  private _nonWorkingDays: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Días no laborables',
      singular: 'Día no laborable',
      plural: 'Días no laborables',
      singularPronoun: 'el',
      create: 'Crear día no laborable',
      edit: 'Editar día no laborable',
      delete: 'Eliminar día no laborable',
      empty: 'No hay días no laborables',
    },
    en: {
      title: 'Non working days',
      singular: 'Non working day',
      plural: 'Non working days',
      singularPronoun: 'the',
      create: 'Create non working day',
      edit: 'Edit non working day',
      delete: 'Delete non working day',
      empty: 'There are no non working days',
    },
  };

  public get calendars() {
    return this._calendars[this.currentLocale];
  }

  private _calendars: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Calendarios',
      singular: 'Calendario',
      plural: 'Calendarios',
      singularPronoun: 'el',
      create: 'Crear calendario',
      edit: 'Editar calendario',
      empty: 'No hay calendarios',
      delete: 'Eliminar calendario',
    },
    en: {
      title: 'Calendars',
      singular: 'Calendar',
      plural: 'Calendars',
      singularPronoun: 'the',
      create: 'Create calendar',
      edit: 'Edit calendar',
      empty: 'There are no calendars',
      delete: 'Delete calendar',
    },
  };

  public get operationGroups() {
    return this._operationGroups[this.currentLocale];
  }

  private _operationGroups: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Grupos de Operación',
      singular: 'Grupo de Operación',
      plural: 'Grupos de Operación',
      singularPronoun: 'el',
      create: 'Crear un grupo de operaciones',
      edit: 'Editar un grupo de operaciones',
      delete: 'Eliminar un grupo de operaciones',
      empty: 'No hay grupos de operaciones',
    },
    en: {
      title: 'Operation Groups',
      singular: 'Operation Group',
      plural: 'Operation Groups',
      singularPronoun: 'the',
      create: 'Create an operation group',
      edit: 'Edit operation groups',
      delete: 'Delete operation group',
      empty: 'There are no operation groups',
    },
  };

  public get paymentButtonFields() {
    return this._paymentButtonFields[this.currentLocale];
  }

  private _paymentButtonFields: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear un campo de botón de pago',
      edit: 'Editar campo de botón de pago',
      delete: 'Eliminar campo de botón de pago',
      singular: 'Campo de botón de pago',
      plural: 'Campos de botón de pago',
      empty: 'No hay campos de botón de pago',
      singularPronoun: 'El',
      errorEditName: 'El nombre no puede ser vacío',
    },
    en: {
      create: 'Create a payment button field',
      edit: 'Edit payment button field',
      delete: 'Delete payment button field',
      singular: 'Payment button field',
      plural: 'Payment button fields',
      empty: 'There are no payment button fields',
      singularPronoun: 'the',
      errorEditName: 'Name cannot be empty',
    },
  };

  public get paymentButtonType() {
    return this._paymentButtonType[this.currentLocale];
  }

  private _paymentButtonType: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear un tipo de botón de pago',
      edit: 'Editar tipo de botón de pago',
      delete: 'Eliminar tipo de botón de pago',
      singular: 'Tipo de botón de pago',
      plural: 'Tipos de botón de pago',
      empty: 'No hay tipos de botón de pago',
      singularPronoun: 'El',
    },
    en: {
      create: 'Create a payment button type',
      edit: 'Edit payment button type',
      delete: 'Delete payment button type',
      singular: 'Payment button type',
      plural: 'Payment button type',
      empty: 'There are no payment button types',
      singularPronoun: 'the',
    },
  };

  public get paymentButtonTypeFields() {
    return this._paymentButtonTypeFields[this.currentLocale];
  }

  private _paymentButtonTypeFields: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear un campo de tipo de botón de pago',
      edit: 'Editar campo de tipo de botón de pago',
      delete: 'Eliminar campo de tipo de botón de pago',
      singular: 'Campo de tipo de botón de pago',
      plural: 'Campos de tipo de botón de pago',
      empty: 'No hay campos de tipo de botón de pago',
      singularPronoun: 'El',
    },
    en: {
      create: 'Create a payment button type field',
      edit: 'Edit payment button type field',
      delete: 'Delete payment button type field',
      singular: 'Payment button type field',
      plural: 'Payment button type fields',
      empty: 'There are no payment button type fields',
      singularPronoun: 'the',
    },
  };

  public get days() {
    return this._days[this.currentLocale];
  }

  private _days = {
    es: {
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo',
    },
    en: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
  };

  public get months() {
    return this._months[this.currentLocale];
  }

  private _months = {
    es: {
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre',
    },
    en: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
  };

  public get accountIds() {
    return this._accountIds[this.currentLocale];
  }

  private _accountIds: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear identificación de cuenta',
      edit: 'Editar identificación de cuenta',
      delete: 'Eliminar identificación de cuenta',
      singular: 'Identificación de cuenta',
      plural: 'identificaciones de cuenta',
      empty: 'No hay identificaciones de cuenta',
      singularPronoun: 'la',
      errorName: 'El nombre no puede ser vacío',
    },
    en: {
      create: 'Create account identification',
      edit: 'Edit account identification',
      delete: 'Delete account identification',
      singular: 'Account identification',
      plural: 'Account identifications',
      empty: 'There are no account identifications',
      singularPronoun: 'the',
      errorName: 'Name cannot be empty',
    },
  };

  public get countries() {
    return this._countries[this.currentLocale];
  }

  private _countries: BasicLocatableObjectMultilanguage = {
    es: {
      edit: 'Editar país',
      newCountry: 'Nuevo país',
      addCountry: 'Agregar país',
      empty: 'No existen países.',
      singular: 'País',
      deleteCountry: 'Eliminar país',
      singularPronoun: 'El',
      plural: 'Países',
      create: 'Agregar país',
      delete: 'Eliminar país',
      errorEditName: 'El nombre no puede ser vacío',
      errorEditIsoCode: 'El código ISO no puede ser vacío',
      errorEditImage: 'La imagen no puede ser vacía',
      errorEditPhone: 'El código de teléfono no puede ser vacío',
    },
    en: {
      edit: 'Edit country',
      newCountry: 'New country',
      empty: 'There are no countries.',
      singular: 'Country',
      singularPronoun: 'the',
      plural: 'Countries',
      create: 'Create country',
      delete: 'Delete country',
      errorEditName: 'Name cannot be empty',
      errorEditIsoCode: 'ISO Code cannot be empty',
      errorEditImage: 'Image cannot be empty',
      errorEditPhone: 'Phone code cannot be empty',
    },
  };

  public get attributesGroup() {
    return this._attributesGroup[this.currentLocale];
  }
  private _attributesGroup: BasicLocatableObjectMultilanguage = {
    es: {
      delete: 'Eliminar grupo de atributos',
      create: 'Crear grupo de atributos',
      new: 'Nuevo grupo de atributos',
      add: 'Agregar grupo de atributos',
      empty: 'No existen grupos de atributos.',
      singular: 'Grupo de atributos',
      singularPronoun: 'El',
      plural: 'Grupos de atributos',
      edit: 'Editar grupo de atributos',
      dissociate: 'Desasociar grupo de atributos',
      associate: 'Asociar grupo de atributos',
    },
    en: {
      delete: 'Delete attribute group',
      create: 'Create attribute group',
      new: 'New attribute group',
      add: 'Create attribute group',
      empty: 'There are no attribute groups.',
      singular: 'Attribute Group',
      singularPronoun: 'the',
      plural: 'Attribute Groups',
      edit: 'Edit attribute group',
      dissociate: 'Dissociate attributes group',
      associate: 'Associate attributes group',
    },
  };

  public get attributes() {
    return this._attributes[this.currentLocale];
  }
  private _attributes: BasicLocatableObjectMultilanguage = {
    es: {
      delete: 'Eliminar atributo',
      saveChanges: 'Guardar cambios',
      create: 'Crear atributo',
      edit: 'Editar atributo',
      new: 'Nuevo atributo',
      add: 'Agregar atributo',
      empty: 'No existen atributos.',
      singular: 'Atributo',
      singularPronoun: 'El',
      plural: 'Atributos',
    },
    en: {
      delete: 'Delete attribute',
      saveChanges: 'Save changes',
      create: 'Create attribute',
      edit: 'Edit attribute',
      new: 'New attribute',
      add: 'Create attribute',
      empty: 'There are no attributes.',
      singular: 'Attribute',
      singularPronoun: 'the',
      plural: 'Attributes',
    },
  };

  public get identificationTypes() {
    return this._identificationTypes[this.currentLocale];
  }

  private _identificationTypes: BasicLocatableObjectMultilanguage = {
    es: {
      edit: 'Editar tipo de identificación',
      newIdentificationType: 'Nuevo tipo de identificación',
      create: 'Agregar tipo de identificación',
      empty: 'No existen tipos de identificación.',
      singular: 'Tipo de identificación',
      delete: 'Eliminar tipo de identificación',
      singularPronoun: 'El',
      plural: 'tipos de identificación',
    },
    en: {
      edit: 'Edit identification type',
      newIdentificationType: 'New identification type',
      create: 'Create identification type',
      empty: 'There are no identification types.',
      singular: 'Identification type',
      delete: 'Delete identification type',
      singularPronoun: 'the',
      plural: 'identification types',
    },
  };

  public get taxes() {
    return this._taxes[this.currentLocale];
  }

  private _taxes: BasicLocatableObjectMultilanguage = {
    es: {
      edit: 'Editar impuesto',
      newTax: 'Nuevo impuesto',
      create: 'Agregar impuesto',
      empty: 'No existen impuestos.',
      singular: 'Impuesto',
      delete: 'Eliminar impuesto',
      singularPronoun: 'El',
      plural: 'Impuestos',
      errorEditValue: 'El valor no puede ser vacío',
    },
    en: {
      edit: 'Edit tax',
      newTax: 'New tax',
      create: 'Create tax',
      empty: 'There are no taxes.',
      singular: 'Tax',
      delete: 'Delete tax',
      singularPronoun: 'the',
      plural: 'Taxes',
      errorEditValue: 'Value cannot be empty',
    },
  };
  public confirmEdit(element: {
    singular: string;
    plural: string;
    singularPronoun?: string;
  }) {
    if (this.currentLocale == 'es') {
      return `¿Estás seguro que quieres guardar los cambios?`;
    } else {
      return `Are you sure you want to save changes?`;
    }
  }

  public get userType() {
    return this._userType[this.currentLocale];
  }
  private _userType: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Tipo de usuario',
      create: 'Crear tipo de usuario',
      delete: 'Eliminar tipo de usuario',
      edit: 'Editar tipo de usuario',
      singular: 'Tipo de usuario',
      plural: 'Tipos de usuario',
      singularPronoun: 'El',
      empty: 'No existen tipos de usuario',
      new: 'Nuevo tipo de usuario',
    },
    en: {
      title: 'User Type',
      create: 'Create user type',
      delete: 'Delete user type',
      edit: 'Edit user type',
      singular: 'User type',
      plural: 'User types',
      singularPronoun: 'the',
      empty: 'There are no user types',
      new: 'New user type',
    },
  };

  public get integrations() {
    return this._integrations[this.currentLocale];
  }

  public get integrationTypes(){
    return this._integrationTypes[this.currentLocale];
  }



  private _integrations : BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Integraciones',
      create: 'Crear integración',
      associate: 'Asociar integración',
      delete: 'Eliminar integración',
      edit: 'Editar integración',
      singular: 'Integración',
      plural: 'Integraciones',
      singularPronoun: 'La',
      none: 'Ninguna',
      empty: 'No hay integraciones',
      emptyAssociate: 'No hay integraciones para asociar',
    new: 'Nueva integración',
  },
  en: {
    title: 'Integrations',
    create: 'Create integration',
    associate: 'Associate integration',
    delete: 'Delete integration',
    edit: 'Edit integration',
    singular: 'Integration',
    plural: 'Integrations',
    none: 'None',
    empty: 'No integrations',
    emptyAssociate: 'No integrations to associate',
    new: 'New integration',
    singularPronoun: 'the',
  },
};

private _parameters : BasicLocatableObjectMultilanguage = {
  es: {
    title: 'Parámetros',
    singular: 'Parámetro',
    plural: 'Parámetros',
    singularPronoun: 'El',
    create: 'Crear Parámetro',
    edit: 'Editar Parámetro',
    delete: 'Eliminar Parámetro',
    empty: 'No hay parámetros',
    new: 'Nuevo parámetro',
    errorEditParameterValue: 'El valor no puede ser vacío',
  },
  en: {
    title: 'Parameters',
    singular: 'Parameter',
    plural: 'Parameters',
    singularPronoun: 'the',
    create: 'Create Parameter',
    edit: 'Edit Parameter',
    delete: 'Delete Parameter',
    empty: 'No parameters',
    new: 'New parameter',
    errorEditParameterValue: 'Value cannot be empty',
  },
};

public get parameters() {
  return this._parameters[this.currentLocale];
}

private _campaigns : BasicLocatableObjectMultilanguage = {
  es: {
    title: 'Campañas',
    create: 'Crear campaña',
    delete: 'Eliminar campaña',
    edit: 'Editar campaña',
    plural: 'Campañas',
    singular: 'Campaña',
    singularPronoun: 'La',
    empty: 'No hay campañas',
    addBranch: 'Agregar sucursal a campaña',
    addMedium: 'Asociar medio a campaña',
    emptyMediums: 'No hay medios de pago para asociar',
    confirmDeleteAgentCampaign:
      '¿Está seguro que quieres eliminar la campaña del agente?',
    new: 'Nueva campaña',
    errorEditPercentage: 'El porcentaje debe estar entre 1 y 100',
    errorEditAgentDistribution: 'El porcentaje del agente debe estar entre 0 y 100',
    errorEditFinInstPercentage: 'El porcentaje de la institución financiera debe estar entre 0 y 100'
  },
  en: {
    title: 'Campaigns',
    create: 'Create campaign',
    delete: 'Delete campaign',
    edit: 'Edit campaign',
    plural: 'Campaigns',
    singular: 'Campaign',
    empty: 'No campaigns',
    addBranch: 'Add branch to campaign',
    addMedium: 'Associate medium to campaign',
    emptyMediums: 'No mediums to associate',
    confirmDeleteAgentCampaign:
      'Are you sure you want to delete the campaign from the agent?',
    new: 'New campaign',
    singularPronoun: 'the',
    errorEditPercentage: 'Percentage must be between 1 and 100',
    errorEditAgentDistribution: 'Agent percentage must be between 0 and 100',
    errorEditFinInstPercentage: 'Financial institution percentage must be between 0 and 100'
  },
};

public get campaigns() {
  return this._campaigns[this.currentLocale];
}

  public get serviceModes() {
    return this._serviceMode[this.currentLocale];
  }

  private _serviceMode: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear modo de servicio ',
      edit: 'Editar modo de servicio',
      delete: 'Eliminar modo de servicio',
      singular: 'Modo de servicio',
      plural: 'Modos de servicio',
      empty: 'No existen modos de servicio',
      singularPronoun: 'El',
      associateAttributeGroup: 'Asociar grupo de atributo',
    },
    en: {
      create: 'Create service mode',
      edit: 'Edit service mode',
      delete: 'Delete service mode',
      singular: 'Service mode',
      plural: 'Service modes',
      empty: 'There are no service modes',
      singularPronoun: 'the',
      associateAttributeGroup: 'Associate attribute group',
    },
  };

  public get denominations() {
    return this._denominations[this.currentLocale];
  }

  private _denominations: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear denominación',
      edit: 'Editar denominación',
      delete: 'Eliminar denominación',
      singular: 'Denominación',
      plural: 'Denominaciones',
      empty: 'No existen denominaciones',
      singularPronoun: 'La',
      enable: 'Habilitar',
      disable: 'Deshabilitar',
      errorEditName: 'El nombre no puede ser vacío',
    },
    en: {
      create: 'Create denomination',
      edit: 'Edit denomination',
      delete: 'Delete denomination',
      singular: 'Denomination',
      plural: 'Denominations',
      empty: 'There are no denominations',
      singularPronoun: 'the',
      enable: 'Enable',
      disable: 'Disable',
      errorEditName: 'Name cannot be empty',
    },
  };

  private _integrationTypes: BasicLocatableObjectMultilanguage = {
    es: {
      title: 'Tipos de integración',
      create: 'Crear tipo de integración',
      associate: 'Asociar tipo de integración',
      delete: 'Eliminar tipo de integración',
      edit: 'Editar tipo de integración',
      singular: 'Tipo de integración',
      plural: 'Tipos de integración',
      singularPronoun: 'El',
      none: 'Ningun',
      empty: 'No hay tipos de integración',
      emptyAssociate: 'No hay tipos de integración para asociar',
      new: 'Nuevo tipo de integración',
    },
    en: {
      title: 'Integration types',
      create: 'Create integration type',
      associate: 'Associate integration type',
      delete: 'Delete integration type',
      edit: 'Edit integration type',
      singular: 'Integration type',
      plural: 'Integration types',
      none: 'None',
      empty: 'No integration types',
      emptyAssociate: 'No integration types to associate',
      new: 'New integration type',
      singularPronoun: 'the',
    },
  };

  public get settlementMode() {
    return this._settlementMode[this.currentLocale];
  }

  private _settlementMode: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear modo de liquidación',
      edit: 'Editar modo de liquidación',
      delete: 'Eliminar modo de liquidación',
      singular: 'Modo de liquidación',
      plural: 'Modos de liquidación',
      empty: 'No existen modos de liquidación',
      singularPronoun: 'El',
    },
    en: {
      create: 'Create settlement mode',
      edit: 'Edit settlement mode',
      delete: 'Delete settlement mode',
      singular: 'Settlement mode',
      plural: 'Settlement modes',
      empty: 'There are no settlement modes',
      singularPronoun: 'the',
    },
  };


  public get metadataType() {
    return this._metadataType[this.currentLocale];
  }

  private _metadataType: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear tipo de metadata',
      edit: 'Editar tipo de metadata',
      delete: 'Eliminar tipo de metadata',
      singular: 'Tipo de metadata',
      plural: 'Tipos de metadata',
      empty: 'No existen tipos de metadata',
      singularPronoun: 'El',
    },
    en: {
      create: 'Create metadata type',
      edit: 'Edit metadata type',
      delete: 'Delete metadata type',
      singular: 'Metadata type',
      plural: 'Metadata types',
      empty: 'There are no metadata types',
      singularPronoun: 'the',
    },
  };

  public get metadataField() {
    return this._metadataField[this.currentLocale];
  }

  private _metadataField: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear campo de metadata',
      edit: 'Editar campo de metadata',
      delete: 'Eliminar campo de metadata',
      singular: 'Campo de metadata',
      plural: 'Campos de metadata',
      empty: 'No existen campos de metadata',
      singularPronoun: 'El',
    },
    en: {
      create: 'Create metadata field',
      edit: 'Edit metadata field',
      delete: 'Delete metadata field',
      singular: 'Metadata field',
      plural: 'Metadata fields',
      empty: 'There are no metadata fields',
      singularPronoun: 'the',
    },
  };

  public get inputMetadata() {
    return this._inputMetadata[this.currentLocale];
  }

  private _inputMetadata: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear metadata entrante',
      edit: 'Editar metadata entrante',
      delete: 'Eliminar metadata entrante',
      singular: 'Metadata entrante',
      plural: 'Metadatas entrantes',
      empty: 'No hay metadata entrante',
      singularPronoun: 'la',
    },
    en: {
      create: 'Create input metadata',
      edit: 'Edit input metadata',
      delete: 'Delete input metadata',
      singular: 'Input metadata',
      plural: 'Input metadatas',
      empty: 'There are no input metadatas',
      singularPronoun: 'the',
    },
  };

  public get outputMetadata() {
    return this._outputMetadata[this.currentLocale];
  }

  private _outputMetadata: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear metadata saliente',
      edit: 'Editar metadata saliente',
      delete: 'Eliminar metadata saliente',
      singular: 'Metadata saliente',
      plural: 'Metadatas saliente',
      empty: 'No hay metadata saliente',
      singularPronoun: 'la',
    },
    en: {
      create: 'Create output metadata',
      edit: 'Edit output metadata',
      delete: 'Delete output metadata',
      singular: 'Output metadata',
      plural: 'Output metadatas',
      empty: 'There are no output metadatas',
      singularPronoun: 'the',
    },
  };

  public confirmActivate(element: {
    singular: string;
    plural: string;
    singularPronoun?: string;
  }) {
    if (this.currentLocale == 'es') {
      return `¿Estás seguro que quieres activar ${element.singularPronoun?.toLocaleLowerCase()} ${element.singular.toLowerCase()}?`;
    } else {
      return `Are you sure you want to activate the ${element.singular.toLowerCase()}?`;
    }
  }

  public get apiResponseMap() {
    return this._apiResponseMap[this.currentLocale];
  }

  private _apiResponseMap: BasicLocatableObjectMultilanguage = {
    es: {
      create: 'Crear mapeo de respuesta de la API',
      edit: 'Editar mapeo de respuesta de la API',
      delete: 'Eliminar mapeo de respuesta de la API',
      singular: 'Mapeo de respuesta de la API',
      plural: 'Mapeos de respuesta de la API',
      empty: 'No hay mapeos de respuesta',
      singularPronoun: 'el',
    },
    en: {
      create: 'Create API response mapping',
      edit: 'Edit API response mapping',
      delete: 'Delete API response mapping',
      singular: 'API response mapping',
      plural: 'API response mapping',
      empty: 'There are no API response mappings',
      singularPronoun: 'the',
    },
  };


}
